import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/snackbarReducer";
import makeAPIRequest from "../../utils/makeAPIRequest";

const formatMembers = (usersList, usersId) => {
  let members = "";
  if (!usersList || usersList?.length === 0) return "";
  if (!usersId || usersId?.length === 0) return "";
  for (const userId of usersId) {
    const userFounded = usersList?.find((user) => user?.user_id == userId);
    if (userFounded && userFounded?.email) {
      members += `${members.length > 0 ? "\n" : ""}${userFounded.email}`;
    }
  }
  return members;
};

const GroupsListTableContent: React.FC<any> = (props) => {
  const {
    groups,
    setGroups,
    setEditInfos,
    setCreateGroupIsOpen,
  } = props;
  const dispatch = useDispatch();

  return (
    <>
      {props?.pages?.[props?.actualPage]?.map((group, index) => {
        return (
          <>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {group?.name ? group.name : ""}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre-line",
              }}
            >
              {formatMembers(props?.usersList, group?.users)}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre-line",
              }}
            >
              {group?.INSICertificate != undefined ? 'Oui' : 'Non'}
            </span>
            <Grid
              container
              direction="row"
              justifyContent='flex-end'
              wrap="nowrap"
              className="table-grid-text"
              gap="10px"
              alignItems='center'
            >
              <Grid item display="grid">
                <Tooltip title="Modifier">
                  <IconButton
                    style={{ margin: "0", padding: "0" }}
                    onClick={() => {
                      setEditInfos(group);
                      setCreateGroupIsOpen(true);
                    }}
                  >
                    <EditRounded
                      fontSize="small"
                      style={{ color: "#0B243C" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item display="grid">
                <Tooltip title="Supprimer">
                  <IconButton
                    onClick={() => {
                      makeAPIRequest(
                        'delete',
                        `/group/${group?._id}/delete`,
                        null,
                        'v3',
                      ).then((res) => {
                        let groupsTmp = [...groups];
                        const indexToDelete = groupsTmp?.findIndex((g) => g?._id === group?._id);
                        if (indexToDelete !== -1) {
                          delete groupsTmp[indexToDelete];
                          groupsTmp = groupsTmp?.filter((g) => g != undefined);
                          setGroups(groupsTmp);
                        }
                        dispatch(openSnackbar({
                          message: `Le groupe ${group?.name} a été supprimé avec succès`,
                          duration: 3000,
                          type: 'success',
                        }))
                      }).catch((err) => {
                        dispatch(openSnackbar({
                          message: `Impossible de supprimer ce groupe`,
                          duration: 3000,
                          type: 'error',
                        }))
                      })
                    }}
                    style={{
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <ClearRounded
                      fontSize="small"
                      style={{ color: "#FF3100" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        );
      })}
    </>
  );
};

export default GroupsListTableContent;
