import { CircularProgress, Grid } from '@mui/material';
import * as React from 'react';
import DisableSendButtonItem from './DisableSendButtonItem';
import EnableSendButtonItem from './EnableSendButtonItem';
import ISendButtonItemProps from './ISendButtonItemProps';

const SendButtonItem = (props: ISendButtonItemProps) => {
  if (props.isLoading)
    return (
      <Grid container direction='column' wrap="nowrap" spacing='15px'>
        <Grid item display='grid'>
          <DisableSendButtonItem />
        </Grid>
        <Grid item display='grid'>
          <Grid container direction='row' display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress />
          </Grid>
        </Grid>
      </Grid>
    )
  else if (props.isValidToSend())
    return (<EnableSendButtonItem {...props} />)
  else
    return (<DisableSendButtonItem />)
}

SendButtonItem.defaultProps = {
  isLoading: false,
}

export default SendButtonItem;
