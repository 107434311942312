import makeAPIRequest from "../utils/makeAPIRequest";

const getAll = () => {
  return makeAPIRequest('get', "/conseils/default_conseils", null, 'v3');
};

const getAllRecent = () => {
  return makeAPIRequest('get', "/conseils/last_search_conseils");
};

const getAdmin = () =>{
  return makeAPIRequest('get', "/admin/conseils");
}

const search = (data) => {
  return makeAPIRequest('post', "/conseils/search", data, `v3`);
}

const getMessages = () => {
  return makeAPIRequest('get', "/conseils/sended_conseils", null, 'v3');
}

const get = (id: any) => {
  return makeAPIRequest('get', `/patient/${id}`);
};

const get_user = (id: any) => {
  return makeAPIRequest('get', `/user/${id}`);
};

const update_user = (id, data) => {
  return makeAPIRequest('post', `/user/${id}/update`,data);
}

const create = (data) => {
  return (makeAPIRequest('post', '/conseil/create', data));
};

const send = (data) => {
  return (makeAPIRequest('post', `/conseils/send`, data, 'v2'));
};

const sendMulti = (data) => {
  return (makeAPIRequest('post', `/conseils/send_multi`, data, 'v2'));
};

const updateConseil = (data, id) => {
  return (makeAPIRequest('post', `/conseil/${id}/update`, data));
}

const update = (id: any, data) => {
  return makeAPIRequest('post', `/patient/${id}/update`, data);
};

const createPatient = (data) => {
  return makeAPIRequest('post', `/patient/create`, data)
}

const favorite = (id) => {
  return makeAPIRequest('post', `conseil/${id}/toggle_fav`, {}, 'v3').then((res) => {
    console.log(res);
  })
}

const remove = (id: any) => {
  return makeAPIRequest('delete', `/tutorials/${id}`);
};

const removeAll = () => {
  return makeAPIRequest('delete', `/tutorials`);
};

const findByTitle = (username: string) => {
  return makeAPIRequest('get', `/tutorials?username=${username}`);
};

const TutorialService = {
  getAll,
  getAllRecent,
  get,
  get_user,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  send,
  getMessages,
  favorite,
  search,
  sendMulti,
  createPatient,
  updateConseil,
  getAdmin,
  update_user
};

export default TutorialService;
