import FullPage from "./FullPage";
import * as React from "react";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import BoxTitle from "../components/Library/Box/BoxTitle";
import {
  ChevronLeftOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useHistory } from "react-router";
import useQuery from "../utils/hooks/useQuery";
import makeAPIRequest from "../utils/makeAPIRequest";
import AddButton from "../components/Library/Buttons/AddButton/AddButton";
import { useOpenSnackbar } from "./CreateOrEditChatbotLexPage";
import { QRCodeCanvas } from "qrcode.react";
import { genericGetFromTimestamp } from "../components/AdminGroups/CreateGroupPopup";
import { SMSRecapitulatif } from "./PatientQRCodePage";

export const MyQRCodeBig = ({ value }) => {
  const qrCodeParentRef = React.useRef(null);
  const [canvas, setCanvas] = React.useState<any>(undefined);
  const [base64, setBase64] = React.useState<string>(undefined);

  React.useEffect(() => {
    setCanvas(qrCodeParentRef.current?.children?.[0]);
  }, [qrCodeParentRef.current]);

  React.useEffect(() => {
    if (!canvas || base64) {
      return;
    }
    const dataURL = canvas?.toDataURL();
    if (dataURL) {
      setBase64(dataURL);
    }
  }, [canvas, base64]);

  return (
    <div
      ref={qrCodeParentRef}
      style={{ marginLeft: 20, marginRight: 20, marginBottom: 5, marginTop: 5 }}
    >
      {base64 ? (
        <a href={value} target="_blank" rel="noreferrer">
          <img src={base64} height="80px" width="80px" />
        </a>
      ) : (
        <QRCodeCanvas value={value} size={80} />
      )}
    </div>
  );
};

interface IUsedQRCode {
  phoneNumber: string;
  dateDeclenchement: string;
}

export interface IConseilQRCode {
  _id?: any;
  user_id: string;
  conseilId: string;
  update_date?: number;
  creation_date: number;
  useInfos?: IUsedQRCode;
  alreadyUsed: boolean;
}

const QRCodeContent: React.FC = () => {
  const conseilId = useQuery().get("conseilId");
  const snackbarUse = useOpenSnackbar();
  const [hideConseil, setHideConseil] = React.useState<boolean>(false);
  const [hideGenerateQR, setHideGenerateQR] = React.useState<boolean>(false);
  const [actualCodeQR, setActualCodeQR] =
    React.useState<IConseilQRCode>(undefined);
  const [conseilData, setConseilData] = React.useState(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string>(``);
  const [loading, setLoading] = React.useState({
    conseil: false,
    qrCode: false,
  });

  React.useEffect(() => {
    if (!conseilId) {
      setErrorMessage(`L'ID du conseil est manquant dans l'URL.`);
    } else {
      setLoading((x) => {
        return { ...x, conseil: true };
      });
      makeAPIRequest("get", `/conseil/${conseilId}`, null, "v3")
        .then((res) => {
          setConseilData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading((x) => {
            return { ...x, conseil: false };
          });
        });
    }
  }, [conseilId]);

  if (loading.conseil) {
    return (
      <Grid
        container
        direction="row"
        paddingTop="30px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (errorMessage) {
    return (
      <Grid container direction="column" wrap="nowrap" paddingTop={"20px"}>
        <span
          style={{
            fontFamily: `Poppins`,
            fontSize: 16,
            color: "#0B243C",
          }}
        >
          {errorMessage}
        </span>
      </Grid>
    );
  }
  return (
    <Grid container direction="column" wrap="nowrap" gap="10px">
      {!loading.conseil &&
        conseilData &&
        conseilData?.messages?.[0]?.length > 0 && (
          <>
            <Grid
              item
              display="grid"
              style={{
                paddingRight: "15px",
              }}
            >
              <Grid container direction="column" spacing="5px" marginTop="10px" wrap="nowrap">
                <Grid item display="grid">
                  <div
                    style={{
                      borderTop: "2px solid #E6EBF0",
                      borderRadius: "20px",
                    }}
                  />
                </Grid>
                <Grid item display="grid" alignSelf="flex-end">
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap="5px"
                  >
                    <Grid item display="grid">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          color: "#657273",
                          textAlign: "end",
                        }}
                      >
                        Génération du code QR
                      </span>
                    </Grid>
                    <Grid item display="grid">
                      <Tooltip title={hideGenerateQR ? `Afficher` : "Cacher"}>
                        <IconButton
                          style={{
                            margin: "0",
                            padding: "0",
                          }}
                          onClick={() => {
                            setHideGenerateQR((x) => !x);
                          }}
                        >
                          {hideGenerateQR ? (
                            <ExpandLess style={{ color: "#657273" }} />
                          ) : (
                            <ExpandMore style={{ color: "#657273" }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!hideGenerateQR && (
              <Grid
                item
                display="grid"
                justifyContent={"center"}
                marginTop="15px"
                marginBottom={actualCodeQR?._id ? "0px" : "15px"}
              >
                <AddButton
                  displayText={true}
                  text="Générer un code QR à usage unique"
                  displayIconCreate={false}
                  onClick={() => {
                    setLoading((x) => {
                      return { ...x, qrCode: true };
                    });
                    makeAPIRequest(
                      "post",
                      "/qrcode/generate",
                      {
                        conseilId: conseilId,
                      },
                      "v3"
                    )
                      .then((res) => {
                        setActualCodeQR(res?.data);
                        snackbarUse.success("Le code QR a bien été généré.");
                      })
                      .catch((err) => {
                        snackbarUse.error(
                          "Une erreur est survenue lors de la génération du code QR.",
                          err
                        );
                      })
                      .finally(() => {
                        setLoading((x) => {
                          return { ...x, qrCode: false };
                        });
                      });
                  }}
                />
              </Grid>
            )}
          </>
        )}
      {loading.qrCode ? (
        <Grid item display="grid" alignSelf={"center"} paddingTop="15px">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {actualCodeQR?._id && !hideGenerateQR && (
            <Grid item display="grid">
              <Grid
                container
                direction="column"
                wrap="nowrap"
                gap="10px"
                padding="20px"
              >
                <Grid item display="grid" alignSelf={"center"}>
                  <MyQRCodeBig
                    value={`${process.env.REACT_APP_WEB_APP_URL}/#/qr/${actualCodeQR?._id}`}
                  />
                </Grid>
                <Grid item display="grid" alignSelf={"center"}>
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      color: "#0B243C",
                    }}
                  >
                    Généré le{" "}
                    {genericGetFromTimestamp(
                      actualCodeQR.creation_date,
                      "dd/mm/yyyy à hh:min:ss"
                    )}
                  </span>
                </Grid>
                <Grid
                  item
                  display="grid"
                  alignSelf={"flex-start"}
                  marginTop="20px"
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      color: "#0B243C",
                      fontStyle: "italic",
                    }}
                  >
                    Après avoir scanné ce code QR, votre patient devra saisir
                    son numéro de téléphone ainsi que la date de déclenchement
                    pour programmer l'envoi de ce conseil.
                  </span>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
      <SMSRecapitulatif
        conseilData={conseilData}
        isLoading={loading.conseil}
        setConseilData={setConseilData}
      />
    </Grid>
  );
};

const QRCodeBox: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="1000px"
      width="95vw"
      minWidth="320px"
      style={{
        background: "rgb(247, 252, 252, 0.55)",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <BoxTitle first={"Créer"} second="un code QR" />
          </Grid>
          <Grid item display="grid" paddingTop="15px">
            <QRCodeContent />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ConseilQRCodePage: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <FullPage>
        <Grid container direction="column" padding="10px" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/home")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <QRCodeBox />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default ConseilQRCodePage;
