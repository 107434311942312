const getAccountsTypeOptions = () => {
  const options = [
    { value: 3, label: "Expiré" },
    { value: 0, label: "Test" },
    { value: 1, label: "Classique" },
    { value: 5, label: "Limité" },
    { value: 2, label: "Premium" },
    { value: 4, label: "Administrateur" },
  ];

  return options;
};

export default getAccountsTypeOptions;
