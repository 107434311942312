import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import BoxTitle from "../components/Library/Box/BoxTitle";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import useAppSelector from "../redux/useAppSelector";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import { nanoid } from "nanoid";
import { GenericSendButton } from "./ChatbotLexPage";
import { ButtonIconWithTooltip } from "../components/Questionnaire/PDF/CreateOrEditPDFQuestionnaire";
import { ClearRounded, LinkOutlined } from "@mui/icons-material";
import makeAPIRequest from "../utils/makeAPIRequest";

export const transformUrlToAbsolute = (urlBefore: string): string => {
  if (!urlBefore) {
    return "";
  }
  if (urlBefore.startsWith("http://") || urlBefore.startsWith("https://")) {
    return urlBefore;
  } else {
    return "//" + urlBefore;
  }
};

export const isUrl = (url: string): boolean => {
  const urlPattern =
    /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[\S\s]*)?$/;
  return urlPattern.test(url);
};


export interface IShortURL {
  _id?: string;
  creation_date?: number;
  longUrl?: string;
  shortUrl?: string;
  user_id?: string;
}

const LinksBox: React.FC<any> = (props) => {
  const [linkToShort, setLinkToShort] = React.useState<string>("");
  const [customLink, setCustomLink] = React.useState<string>("");
  const [defaultAlias, setDefaultAlias] = React.useState<string>(nanoid(8));
  const snackbarOpenUse = useOpenSnackbar();
  const [createLoading, setCreateLoading] = React.useState<boolean>(false);
  const [myUrls, setMyUrls] = React.useState<IShortURL[]>([]);
  const [firstMyUrlsLoad, setFirstMyUrlsLoad] = React.useState<boolean>(true);

  React.useEffect(() => {
    setFirstMyUrlsLoad(true);
    makeAPIRequest("get", "/shorturl/all_custom", null, "v3")
      .then((res) => {
        setMyUrls(res?.data);
      })
      .catch((err) => {
        snackbarOpenUse.error("Impossible de récupérer vos liens", err);
      })
      .finally(() => setFirstMyUrlsLoad(false));
  }, []);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="1360px"
      minWidth="600px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <BoxTitle first="Liens" second="personnalisés" />
      </Grid>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="8%"
        paddingRight="8%"
      >
        <Grid container direction="column" wrap="nowrap" gap="10px">
          <Grid item display="grid">
            <GenericTextInput
              fieldTitleInPlaceholderOnly={false}
              fieldTitle="Lien à minimiser"
              placeholder="https://www.exemple.com"
              isMandatory={true}
              id="link-to-short"
              value={linkToShort}
              onChange={(e) => {
                setLinkToShort(e?.target?.value);
              }}
            />
          </Grid>
          <Grid item display="grid">
            <GenericTextInput
              fieldTitleInPlaceholderOnly={false}
              fieldTitle="Alias personnalisé"
              placeholder={defaultAlias + " (par défaut)"}
              isMandatory={false}
              id="link-to-short"
              value={customLink}
              onChange={(e) => {
                setCustomLink(e?.target?.value);
              }}
            />
          </Grid>
          <Grid item display="grid" marginTop="20px" justifyContent={"center"}>
            <Grid container direction="row" gap="5px" wrap="nowrap">
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    color: "#0B243C",
                    fontSize: "16px",
                    wordBreak: "break-all",
                    textAlign: "center",
                  }}
                >
                  {`${process.env.REACT_APP_SHORT_URL}/${
                    customLink ? customLink : defaultAlias
                  }`}
                </span>
              </Grid>
              <Grid item display="grid">
                <ButtonIconWithTooltip
                  Icon={
                    <LinkOutlined
                      style={{
                        color: "#0B243C",
                        fontSize: "20px",
                      }}
                    />
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_SHORT_URL}/${
                        customLink ? customLink : defaultAlias
                      }`
                    );
                    snackbarOpenUse.success(
                      "Lien copié dans le presse-papiers"
                    );
                  }}
                  tooltipText="Copier le lien"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid" justifyContent={"center"}>
            <GenericSendButton
              disablePaddingTop={true}
              errorMessages={{
                noLink: "Le lien à minimiser n'est pas renseigné",
                badUrl: "Le lien à minimiser est invalide",
              }}
              errorSelector={{
                noLink: "#link-to-short",
                badUrl: "#link-to-short",
              }}
              invalidConditions={{
                noLink: !linkToShort,
                badUrl: !isUrl(linkToShort),
              }}
              onSendClick={() => {
                setCreateLoading(true);
                makeAPIRequest(
                  "post",
                  "/shorturl/custom",
                  {
                    longUrl: linkToShort,
                    alias: customLink ? customLink : defaultAlias,
                  },
                  "v3"
                )
                  .then((res) => {
                    snackbarOpenUse.success(
                      "L'URL a été créé avec succès",
                      res
                    );
                    setDefaultAlias(nanoid(8));
                    setLinkToShort("");
                    setCustomLink("");
                    setMyUrls((x) => {
                      return [res?.data, ...x];
                    });
                  })
                  .catch((err) => {
                    snackbarOpenUse.error("Impossible de créer cet URL", err);
                  })
                  .finally(() => setCreateLoading(false));
                return;
              }}
              isLoading={createLoading}
              textButton="Créer"
            />
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                fontSize: "16px",
                color: "#0B243C",
                fontFamily: "Poppins",
                textAlign: "right",
                fontStyle: "italic",
              }}
            >
              {`* Ne pas utiliser pour les questionnaires`}
            </span>
          </Grid>
          <Grid item display="grid" marginTop="20px">
            <Grid container direction="column" wrap="nowrap" gap="10px">
              {firstMyUrlsLoad && (
                <Grid item display="grid" justifyContent={"center"}>
                  <CircularProgress
                    style={{
                      color: "#0B243C",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </Grid>
              )}
              {!firstMyUrlsLoad && myUrls.length > 0 && (
                <>
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        color: "#0B243C",
                      }}
                    >
                      {`Mes liens personnalisés (${myUrls.length})`}
                    </span>
                  </Grid>
                  <Grid item display="grid" marginTop="5px">
                    <Grid container direction="column" wrap="nowrap" gap="5px">
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="row"
                          gap="15px"
                          justifyContent={"space-between"}
                          wrap="nowrap"
                        >
                          <Grid
                            item
                            xs={5}
                            display="grid"
                            justifyContent={"flex-start"}
                          >
                            <span
                              style={{
                                color: "#8D9899",
                                fontSize: "14px",
                                fontFamily: "Poppins",
                                textAlign: "left",
                              }}
                            >{`Lien non minimisé`}</span>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            display="grid"
                            justifyContent={"flex-start"}
                          >
                            <span
                              style={{
                                color: "#8D9899",
                                fontSize: "14px",
                                fontFamily: "Poppins",
                                textAlign: "left",
                              }}
                            >{`Lien minimisé`}</span>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            display="grid"
                            justifyContent={"flex-end"}
                          >
                            <span
                              style={{
                                color: "#8D9899",
                                fontSize: "14px",
                                fontFamily: "Poppins",
                                textAlign: "right",
                              }}
                            >{`Actions`}</span>
                          </Grid>
                        </Grid>
                      </Grid>
                      {myUrls.map((url, index) => {
                        return (
                          <Grid
                            item
                            display="grid"
                            key={url?._id}
                            style={{
                              borderTop:
                                index === 0 ? "1px solid #8D9899" : undefined,
                              borderBottom:
                                index !== myUrls.length - 1
                                  ? "1px solid #E6EBF0"
                                  : undefined,
                              paddingTop: index === 0 ? "10px" : "5px",
                              paddingBottom: "5px",
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              gap="15px"
                              justifyContent={"space-between"}
                              wrap="nowrap"
                            >
                              <Grid
                                item
                                xs={5}
                                display="grid"
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                              >
                                <a
                                  href={transformUrlToAbsolute(url.longUrl)}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    fontSize: "14px",
                                    color: "#0B243C",
                                    fontFamily: "Poppins",
                                    wordBreak: "break-all",
                                    textAlign: "center",
                                  }}
                                >
                                  {url.longUrl}
                                </a>
                              </Grid>
                              <Grid item xs={5} display="grid">
                                <Grid
                                  container
                                  direction="row"
                                  gap="10px"
                                  wrap="nowrap"
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Grid item display="grid">
                                    <a
                                      href={transformUrlToAbsolute(
                                        url.shortUrl
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                        fontSize: "14px",
                                        color: "#0B243C",
                                        fontFamily: "Poppins",
                                        wordBreak: "break-all",
                                        textAlign: "center",
                                      }}
                                    >
                                      {url.shortUrl}
                                    </a>
                                  </Grid>
                                  <Grid item display="grid">
                                    <ButtonIconWithTooltip
                                      Icon={
                                        <LinkOutlined
                                          style={{
                                            color: "#0B243C",
                                            fontSize: "20px",
                                          }}
                                        />
                                      }
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          `${url.shortUrl}`
                                        );
                                        snackbarOpenUse.success(
                                          "Lien copié dans le presse-papiers"
                                        );
                                      }}
                                      tooltipText="Copier le lien"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                display="grid"
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                              >
                                <ButtonIconWithTooltip
                                  tooltipText="Supprimer"
                                  Icon={
                                    <ClearRounded
                                      fontSize="small"
                                      style={{ color: "#FF3100" }}
                                    />
                                  }
                                  onClick={() => {
                                    makeAPIRequest(
                                      "delete",
                                      `/shorturl/${url?._id}/delete`,
                                      null,
                                      "v3"
                                    )
                                      .then((res) => {
                                        snackbarOpenUse.success(
                                          "Le lien a bien été supprimé",
                                          res
                                        );
                                        setMyUrls((x) =>
                                          x.filter((y) => y?._id != url?._id)
                                        );
                                      })
                                      .catch((err) => {
                                        snackbarOpenUse.error(
                                          "Impossible de supprimer ce lien",
                                          err
                                        );
                                      });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const LinksPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="links" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="links" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
        >
          <LinksBox {...props} />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default LinksPage;
