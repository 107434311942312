import GroupCheckBoxField from "../CheckBox/GroupCheckBoxField";
import * as React from 'react';

interface IAdviceTypeField {
  adviceType: 'simple' | 'pack',
  onSimpleClick: any,
  onPackClick: any,
}

const AdviceTypeField = (props: IAdviceTypeField) => {
  const {
    adviceType,
    onSimpleClick,
    onPackClick,
  } = props;

  return (
    <GroupCheckBoxField
      title='Type'
      isMandatory={true}
      listCheckbox={
        [
          {
            text: 'Conseil unique',
            value: adviceType === 'simple',
            onClick: () => onSimpleClick(),
          },
          {
            text: 'Pack de conseils',
            value: adviceType === 'pack',
            onClick: () => onPackClick(),
          },
        ]
      } />
  )
};

export default AdviceTypeField;