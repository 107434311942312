import {
  AnalyticsRounded,
  DeleteRounded,
  EditRounded,
  PreviewRounded,
  ScheduleSendRounded,
  ShareRounded,
} from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import useAppSelector from "../../../redux/useAppSelector";
import { IForm } from "../../../types/FormTypes";
import FullField from "../../Fields/FullField";
import MySelect from "../../Library/Select/MySelect";
import { StatisticsPopup } from "./DisplayQuestionnaire";
import ShareQuestionnaire from "./ShareQuestionnaire";
import { GenericCard } from "../../../pages/QuestionnairePage";
import { useHistory } from "react-router";
import { openShare } from "../../../redux/shareQuestionnaireReducer";
import useAppDispatch from "../../../redux/useAppDispatch";
import { openSchedule } from "../../../redux/scheduleQuestionnaireReducer";
import { openSnackbar } from "../../../redux/snackbarReducer";
import CancellablePopup from "../../Library/Popups/CancellablePopup";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import { MoreOrLessButtons } from "../../../pages/ChatbotLexPage";
import { languageConverter, removeParenthesis } from "../../MesConseilsBox/TabsContainer/AdminTab/AdminTabContent";
import { MyQRCode, ViewsEye } from "../../LastConseilComponent";
import { LIST_CPTS } from "../EditQuestionnaire/QuestionnaireEdit";

interface IQuestionnaireContent {
  questionnaires: Array<IForm>;
  isLoading: boolean;
  setQuestionnaires: React.Dispatch<React.SetStateAction<Array<IForm>>>;
}

const QuestionnaireContent = (props: IQuestionnaireContent) => {
  const { questionnaires, isLoading, setQuestionnaires } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [filteredQuestionnaires, setFilteredQuestionnaires] = React.useState(
    []
  );
  const [maxItem, setMaxItem] = React.useState<number>(10);
  const [isDeletePopup, setIsDeletePopup] = React.useState<any>(undefined);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const specialities = useAppSelector((x) => x.languageReducer.displaySpec);
  const [filter, setFilter] = React.useState<{
    speciality: string;
    id: string;
    cptsName: string;
  }>({ speciality: undefined, id: undefined, cptsName: undefined });
  const isShareOpen = useAppSelector(
    (state) => state.shareQuestionnaireReducer.isOpen
  );
  const [openStatistics, setOpenStatistics] = React.useState<any>(undefined);
  const specialitiesNotFront = useAppSelector(
    (state) => state.languageReducer.specialities
  );

  const isValidSpeciality = (
    specialitiesInQuest: string[],
    displaySpecId: string
  ) => {
    if (!specialities || !specialitiesInQuest || !displaySpecId) {
      return false;
    }
    const founded = specialities.find((x) => x?.value == displaySpecId);
    if (!founded?.specialities) {
      return false;
    }
    for (const specialityInQuest of specialitiesInQuest) {
      const toIntegerSpe = parseInt(specialityInQuest);
      if (
        !isNaN(toIntegerSpe) &&
        founded?.specialities?.includes(toIntegerSpe)
      ) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    if (!questionnaires || !Array.isArray(questionnaires) || !specialities) {
      return;
    }
    let questionnairesTmp = [...questionnaires];
    if (filter.id) {
      questionnairesTmp = questionnairesTmp.filter((x) => x?.id === filter.id);
    }
    if (filter.cptsName) {
      questionnairesTmp = questionnairesTmp.filter(
        (x) =>
          x.cptsName == filter.cptsName
      );
    }
    if (filter.speciality) {
      questionnairesTmp = questionnairesTmp.filter(
        (x) =>
          Array.isArray(x?.specialities) &&
          isValidSpeciality(x?.specialities, filter.speciality)
      );
    }
    setFilteredQuestionnaires(questionnairesTmp);
  }, [filter, questionnaires, specialities]);

  return (
    <>
      {openStatistics && (
        <StatisticsPopup
          closePopup={() => setOpenStatistics(undefined)}
          formId={openStatistics?.id}
          formTitle={openStatistics?.title ? openStatistics.title : ""}
        />
      )}
      {isDeletePopup && (
        <CancellablePopup
          firstTitle="Mes"
          isLoading={isDeleteLoading}
          secondTitle="Questionnaires"
          messages={[
            `Voulez-vous vraiment supprimer le questionnaire '${isDeletePopup?.title}' ?`,
            "Toutes les réponses seront perdues",
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => {
            setIsDeletePopup(undefined);
            setIsDeleteLoading(false);
          }}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest(
              "post",
              "/questions/form/delete",
              { form_id: isDeletePopup?.id },
              "v3"
            )
              .then((res) => {
                dispatch(
                  openSnackbar({
                    message: `Le questionnaire '${isDeletePopup?.title}' a bien été supprimé`,
                    type: "success",
                    duration: 3000,
                  })
                );
                const questionnairesTmp = [...questionnaires];
                const indexToDelete = questionnairesTmp?.findIndex(
                  (q) => q?.id === isDeletePopup?.id
                );
                if (indexToDelete !== -1)
                  delete questionnairesTmp[indexToDelete];
                const newQuestionnaires = questionnairesTmp?.filter(
                  (element) => element != undefined
                );
                setIsDeleteLoading(false);
                setIsDeletePopup(undefined);
                setQuestionnaires(newQuestionnaires);
              })
              .catch((err) => {
                dispatch(
                  openSnackbar({
                    message:
                      err?.response?.data?.error &&
                      err?.response?.data?.error != "Internal Server Error"
                        ? err.response.data.error
                        : `Le suppression du questionnaire '${isDeletePopup?.title}' a échouée`,
                    type: "error",
                    duration: 3000,
                  })
                );
                setIsDeleteLoading(false);
                setIsDeletePopup(undefined);
              });
          }}
        />
      )}
      {isShareOpen && <ShareQuestionnaire />}
      <Grid container direction="column" gap="15px" wrap="nowrap">
        <Grid item display="grid">
          <Grid container direction="row" gap="10px" alignItems="center">
            <Grid item display="grid">
              <FullField title="Filtrer :" isMandatory={false}>
                <></>
              </FullField>
            </Grid>
            <Grid item xs display="grid">
              <MySelect
                noOptionsMessage={() => "Aucun résultat"}
                options={questionnaires.map((x) => {
                  if (x?.id && x?.title) {
                    return {
                      value: x.id,
                      label: x?.title,
                    };
                  }
                })}
                value={
                  questionnaires && filter?.id
                    ? {
                        label: questionnaires.find((x) => x.id === filter.id)
                          ?.title,
                        value: filter.id,
                      }
                    : undefined
                }
                placeholder="Titre du questionnaire"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, id: e?.value };
                  });
                }}
              />
            </Grid>
            <Grid item xs display="grid">
              <MySelect
                noOptionsMessage={() => "Aucun résultat"}
                options={specialities}
                value={
                  specialities && filter.speciality
                    ? (() => {
                        const obj = specialities.find(
                          (y) => y?.value === filter.speciality
                        );
                        if (obj && obj?.label) {
                          return obj;
                        }
                        return undefined;
                      })()
                    : undefined
                }
                placeholder="Spécialité"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, speciality: e?.value };
                  });
                }}
              />
            </Grid>
            <Grid item xs display="grid">
              <MySelect
                noOptionsMessage={() => "Aucun résultat"}
                options={LIST_CPTS.map((x) => {return ({label: x, value: x})})}
                value={
                  filter.cptsName
                    ? {
                      label: filter.cptsName,
                      value: filter.cptsName,
                    }
                    : undefined
                }
                placeholder="CPTS"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, cptsName: e?.value };
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {isLoading && questionnaires.length === 0 && (
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        {filteredQuestionnaires?.length > 0 ? (
          <>
            <Grid item display="grid">
              <Grid container direction="column" gap="10px" wrap="nowrap">
                {filteredQuestionnaires
                  .slice(0, maxItem)
                  .map((questionnaire: IForm, index) => {
                    const speciality = questionnaire?.cptsName
                    ? questionnaire.cptsName
                    : specialitiesNotFront?.length > 0 && questionnaire?.specialities?.length > 0
                    ? questionnaire?.specialities
                        .map((spe) =>
                          languageConverter.getSpecialityById(
                            spe as any,
                            specialitiesNotFront
                          )
                        )
                        .join(", ")
                    : ``;
                    return (
                      <Grid item key={questionnaire?.id} display="grid">
                        <GenericCard
                          textPin={`${questionnaire?.nbResponses} réponse${
                            questionnaire?.nbResponses > 1 ? "s" : ""
                          }`}
                          rightPin={speciality ? removeParenthesis(speciality) : undefined}
                          leftButtons={[
                            {
                              Icon: PreviewRounded,
                              title: "Voir les réponses",
                              onClick: () => {
                                history.push(
                                  `/questionnaire/${questionnaire?.id}/reponses`
                                );
                              },
                              color: "rgb(11, 36, 60)",
                            },
                            {
                              Icon: ShareRounded,
                              title: "Partager",
                              onClick: () => {
                                dispatch(
                                  openShare({
                                    questionnaireId: questionnaire?.id,
                                    title: questionnaire?.title,
                                    sharePublicStats: questionnaire?.sharePublicStats ? true : false,
                                  })
                                );
                              },
                              color: "rgb(11, 36, 60)",
                            },
                            {
                              Icon: ScheduleSendRounded,
                              title: "Programmer un envoi",
                              onClick: () => {
                                dispatch(
                                  openSchedule({
                                    questionnaireId: questionnaire?.id,
                                    title: questionnaire?.title,
                                  })
                                );
                              },
                              color: "rgb(11, 36, 60)",
                            },
                          ]}
                          rightButtons={[
                            {
                              Icon: AnalyticsRounded,
                              title: "Statistiques",
                              onClick: () => {
                                setOpenStatistics(questionnaire);
                              },
                              color: "rgb(11, 36, 60)",
                            },
                            {
                              Icon: EditRounded,
                              title: "Modifier",
                              onClick: () => {
                                history.push(
                                  `/edit_questionnaire/${questionnaire?.id}`
                                );
                              },
                              color: "rgb(11, 36, 60)",
                            },
                            {
                              Icon: DeleteRounded,
                              title: "Supprimer",
                              onClick: () => {
                                setIsDeletePopup(questionnaire);
                              },
                              color: "rgb(11, 36, 60)",
                            },
                          ]}
                        >
                          <Grid container direction="column" wrap="nowrap">
                            <Grid item display={`grid`}>
                              <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                alignItems="center"
                                gap={`20px`}
                              >
                                {/* Title and specilaity */}
                                <Grid item xs display={"grid"}>
                                  <Grid
                                    container
                                    direction="column"
                                    wrap="nowrap"
                                    alignItems={`flex-start`}
                                  >
                                    <Grid item display="grid">
                                      <Tooltip title="Ouvrir un lien public">
                                        <IconButton
                                          onClick={() =>
                                            window
                                              .open(
                                                `/#/q/${questionnaire?.id}`,
                                                "_blank"
                                              )
                                              .focus()
                                          }
                                          style={{ margin: 0, padding: 0 }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              fontFamily: "Poppins",
                                              fontSize: "16px",
                                              color: "rgb(11, 36, 60)",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            {questionnaire?.title}
                                          </span>
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                    {questionnaire?.specialities?.length >
                                      0 && (
                                      <Grid item display="grid">
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            color: "rgb(11, 36, 60)",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          {specialitiesNotFront?.length > 0
                                            ? questionnaire?.specialities
                                                .map((spe) =>
                                                  languageConverter.getSpecialityById(
                                                    spe as any,
                                                    specialitiesNotFront
                                                  )
                                                )
                                                .join(", ")
                                            : ``}
                                        </span>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                {/* QR CODE */}
                                <Grid item display="grid">
                                  <Grid
                                    container
                                    direction="column"
                                    wrap="nowrap"
                                    gap="2px"
                                  >
                                    <Grid item display="grid">
                                      <ViewsEye
                                        nbViews={
                                          questionnaire?.scanCodeQR
                                            ? questionnaire?.scanCodeQR
                                            : 0
                                        }
                                      />
                                    </Grid>
                                    <Grid item display="grid">
                                      <Tooltip title={`Lire le code QR`}>
                                        <IconButton
                                          onClick={() =>
                                            window
                                              .open(
                                                `/#/q/${questionnaire?.id}`,
                                                "_blank"
                                              )
                                              .focus()
                                          }
                                          style={{ margin: 0, padding: 0 }}
                                        >
                                          <MyQRCode
                                            value={`${process.env.REACT_APP_WEB_APP_URL}/#/q/${questionnaire?.id}?scan=1`}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </GenericCard>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <MoreOrLessButtons
              items={filteredQuestionnaires}
              maxItem={maxItem}
              setMaxItem={setMaxItem}
            />
          </>
        ) : (
          <>
            {!isLoading && (
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    color: "#0B243C",
                    fontSize: "16px",
                  }}
                >
                  Aucun questionnaire trouvé.
                </span>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default QuestionnaireContent;
