import { ChevronLeftOutlined } from '@mui/icons-material';
import * as React from 'react'
import './ReturnButton.scss';

interface IReturnButtonProps {
  text?: string,
  onClick?: any,
}

const ReturnButton = (props: IReturnButtonProps) => {
  return (
    <button
      className={`return-button`}
      onClick={props.onClick}>
      <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ChevronLeftOutlined /> {props.text}
      </span>
    </button>
  )
}

ReturnButton.defaultProps = {
  text: 'Default',
  onClick: () => {
    console.error("You forgot the 'onClick' attribute in the 'ReturnButton' component");
  },
};

export default ReturnButton;