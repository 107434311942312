import FullPage from "./FullPage";
import * as React from "react";
import { Grid } from "@mui/material";
import BoxTitle from "../components/Library/Box/BoxTitle";
import { ChevronLeftOutlined } from "@mui/icons-material";
import { useHistory } from "react-router";
import { GenericSendButton } from "./ChatbotLexPage";
import {
  GenericLongText,
  GenericTextInput,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import { GenericSelect } from "./QuestionnairePage";
import makeAPIRequest from "../utils/makeAPIRequest";

interface SupportData {
  subject?: string;
  description?: string;
  priority?: "low" | "normal" | "high" | "urgent";
}

const priorityOptions = [
  {
    label: "Basse",
    value: "low",
  },
  {
    label: "Moyenne",
    value: "normal",
  },
  {
    label: "Haute",
    value: "high",
  },
  {
    label: "Urgente",
    value: "urgent",
  },
];

const SupportContent: React.FC = () => {
  const getInitSupportData = (): SupportData => {
    return {
      subject: "",
      description: "",
      priority: "low",
    };
  };

  const [isSendLoading, setIsSendLoading] = React.useState<boolean>(false);
  const [supportData, setSupportData] = React.useState<SupportData>(getInitSupportData());
  const snackbarUse = useOpenSnackbar();

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap="10px"
      maxWidth={"800px"}
      justifySelf="center"
      marginTop="20px"
    >
      <Grid item display="grid">
        <GenericSelect
          fieldTitle="Priorité"
          isMandatory={true}
          options={priorityOptions}
          id="select-priority"
          placeholder=""
          isClearable={false}
          value={
            supportData.priority
              ? priorityOptions.find((x) => x?.value == supportData.priority)
              : undefined
          }
          onChange={(e) => {
            setSupportData((x) => {
              return { ...x, priority: e?.value ? e.value : "" };
            });
          }}
        />
      </Grid>
      <Grid item display="grid">
        <GenericTextInput
          fieldTitle="Objet"
          isMandatory={true}
          id="input-subject"
          value={supportData.subject}
          onChange={(e) => {
            setSupportData((x) => {
              return { ...x, subject: e.target.value };
            });
          }}
        />
      </Grid>
      <Grid item display="grid">
        <GenericLongText
          fieldTitle="Description"
          isMandatory={true}
          value={supportData.description}
          id={`input-description`}
          maxLength={20000}
          onChange={(e) => {
            setSupportData((x) => {
              const copy = { ...x };
              if (e && typeof e === "string") {
                copy.description = e;
              } else {
                copy.description = "";
              }
              return copy;
            });
          }}
        />
      </Grid>
      <Grid item display="grid">
        <GenericSendButton
          errorMessages={{
            emptySubject: "L'objet n'est pas renseigné.",
            emptyDescription: "La description n'est pas renseignée.",
          }}
          errorSelector={{
            emptySubject: "#input-subject",
            emptyDescription: "#input-description",
          }}
          invalidConditions={{
            emptySubject: !supportData.subject,
            emptyDescription: !supportData.description,
          }}
          onSendClick={() => {
            setIsSendLoading(true);
            makeAPIRequest("post", "/support/send_ticket", supportData, "v3")
              .then((res) => {
                setSupportData(getInitSupportData());
                snackbarUse.success("Votre demande a bien été prise en compte.", res);
              })
              .catch((err) => {
                snackbarUse.error("L'envoi de votre ticket a échoué.", err);
              })
              .finally(() => {
                setIsSendLoading(false);
              });
            return;
          }}
          isLoading={isSendLoading}
          textButton="Envoyer"
        />
      </Grid>
    </Grid>
  );
};

const SupportBox: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="80vw"
      minWidth="320px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <BoxTitle first={"Contacter"} second="le support" />
          </Grid>
          <Grid item display="grid" paddingTop="15px">
            <SupportContent />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SupportPage: React.FC<any> = (props) => {
  const history = useHistory();
  const { isCreate, isEdit } = props;

  return (
    <>
      <FullPage>
        <Grid container direction="column" padding="10px" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px" width="80vw">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/home")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <SupportBox {...props} />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default SupportPage;
