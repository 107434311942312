import * as React from "react";
import useAppSelector from "../../../../redux/useAppSelector";
import MySelect from "../../../Library/Select/MySelect";
import "../FilterStyle.scss";
import IFilterProps from "../IFilterProps";

const SpecialitiesSelectItem = (props: IFilterProps) => {
  const name = "spec";
  const specialities = useAppSelector((x) => x.languageReducer.displaySpec);

  const getSpec = (key: any) => {
    const founded = specialities.find((x) => x?.value === key);
    if (founded) {
      return founded;
    }
    return null;
  };

  return (
    <div style={{minWidth: '200px'}}>
      <MySelect
        name={name}
        value={props.filterKey?.spec ? getSpec(props.filterKey.spec) : null}
        id="specialite-select"
        placeholder="Spécialité"
        onChange={(e) => {
          props.handleSelectChange({
            target: {
              value: e?.value ? e?.value : null,
              name: name,
            },
          });
        }}
        options={specialities.filter((x) => x?.value != '69')}
      />
    </div>
  );
  /* // Ancien select
  return (
    <select
      name={name}
      value={props.filterKey?.spec ? props.filterKey.spec : ""}
      id="specialite-select"
      className="select-options"
      onChange={(e) => props.handleSelectChange(e)}
    >
      <option value="" style={{ display: "none" }} disabled>
        Spécialité
      </option>
      {props.specialities &&
        props.specialities.map(
          (n, i) =>
            n.name != "-- Aucune spécialité --" && (
              <option key={i} value={n.id}>
                {n.name != "-- Aucune spécialité --" ? n.name : ""}
              </option>
            )
        )}
    </select>
  );
  */
};

export default SpecialitiesSelectItem;
