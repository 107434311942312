import * as React from "react";
import FullField from "../../Fields/FullField";
import TextInput from "../../Library/Inputs/TextInput";

const DisplayAfterMessage: React.FC<any> = (props) => {
  const { chatbotData, setChatbotData } = props;

  return (
    <FullField title="SMS de conclusion" isMandatory={false}>
      <TextInput
        id="chatbot-after-sms-input"
        value={chatbotData?.smsAfter}
        onChange={(e) => {
          const chatbotDataTmp = { ...chatbotData };

          chatbotDataTmp.smsAfter = e.target.value;
          setChatbotData(chatbotDataTmp);
        }}
      />
    </FullField>
  );
};

export default DisplayAfterMessage;
