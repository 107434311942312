import * as React from 'react';
import './FilterStyle.scss';

const FilterByTextItem = () => {
  return (
    <span
      className="filter-by-text">
      Filtrer par :
    </span>
  );
};

export default FilterByTextItem;
