import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UtilsClass from '../../utils/UtilsClass';

export interface Language {
  civilities: any[];
  nniTypes: any[];
  professions: any[];
  specialities: any[];
  displaySpec: any[];
}

const getLanguage = createAsyncThunk(
  '/GET_LANGUAGE',
  async (payload, thunkAPI): Promise<Language> => {
    try {
      const utils = new UtilsClass();
      await utils.init();
      utils.getCivilities();

      return thunkAPI.fulfillWithValue({
        civilities: utils.getCivilitiesOptionsSorted(),
        nniTypes: utils.getNniTypesOptionsSorted(),
        professions: utils.getProfessionsOptionsSorted(),
        specialities: utils.getSpecialitiesOptionsSorted(),
        displaySpec: utils.getDisplaySpecOptionsSorted(),
      }) as any
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

export interface LanguageState {
  civilities: any[];
  nniTypes: any[];
  professions: any[];
  specialities: any[];
  displaySpec: any[];
}

const initialState: LanguageState = {
  civilities: [],
  nniTypes: [],
  professions: [],
  specialities: [],
  displaySpec: [],
}

export const languageSlice = createSlice({
  name: 'Language',
  initialState,
  reducers: {},
  extraReducers(builder: ActionReducerMapBuilder<LanguageState>) {
    builder.addCase(getLanguage.fulfilled, (state, action) => {
      state.civilities = action.payload.civilities;
      state.nniTypes = action.payload.nniTypes;
      state.professions = action.payload.professions;
      state.specialities = action.payload.specialities;
      state.displaySpec = action.payload.displaySpec;
    })
  },
})
export const languageActions = languageSlice.actions;
export default languageSlice.reducer
export { getLanguage }
