import React from "react";
import FullField from "../../../Fields/FullField";
import MyEditor from "../../../Library/Inputs/MyEditor";

const DisplayTextRGPD = (props: any) => {
  const { questionnaireData, setQuestionnaireData } = props;
  
  return (
    <FullField title="Texte RGPD" isMandatory={false}>
      <div
        style={{
          width: "100%",
        }}
      >
        <MyEditor
          defaultFontFamily="Poppins"
          defaultFontSize="16px"
          defaultColor="#0B243C"
          initialContent={questionnaireData?.textRGPD}
          onUpdate={(html) => {
            const questionnaireDataTmp = { ...questionnaireData };
            questionnaireDataTmp.textRGPD = html;
            setQuestionnaireData(questionnaireDataTmp);
          }}
        />
      </div>
    </FullField>
  );
};

export default DisplayTextRGPD;
