import FullField from "../../../Fields/FullField";
import TextInput from "../../../Library/Inputs/TextInput";
import * as React from 'react';
import HandleErrors from "../../../Library/Errors/HandleErrors";

const DisplayTitle: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const {
    questionnaireData,
    setQuestionnaireData,
  } = props;

  return (
    <FullField title='Titre' isMandatory={true}>
      <HandleErrors
        isError={isError}
        isMandatory={true}
        errorMessage={errorMessage}>
        <TextInput
          id='questionnaire-title-input'
          isError={isError}
          value={questionnaireData?.title}
          onChange={(e) => {
            const questionnaireDataTmp = { ...questionnaireData };

            questionnaireDataTmp.title = e.target.value;
            setQuestionnaireData(questionnaireDataTmp);
          }}
          onFocus={(e) => {
            if (isError) {
              setErrorMessage('');
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!e.target.value) {
              if (!isError) {
                setErrorMessage('Ce champ est obligatoire.');
                setIsError(true);
              }
            } else {
              if (isError) {
                setErrorMessage('');
                setIsError(false);
              }
            }
          }} />
      </HandleErrors>
    </FullField>
  );
};

export default DisplayTitle;