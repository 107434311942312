import { Grid } from "@mui/material";
import * as React from "react";
import ReactDOM from "react-dom";
import OrangeButton from "../Buttons/OrangeButton/OrangeButton";
import FormPopupInfo from "../../FormPopup/FormPopupInfo";
import useAppSelector from "../../../redux/useAppSelector";

interface IInformationPopupProps {
  firstTitle?: string;
  secondTitle?: string;
  message?: string;
  messages?: Array<String>;
  components?: Array<JSX.Element>;
  component?: JSX.Element;
  componentsSpacing?: String;
  confirmButtonText?: string;
  onConfirm?: any;
  children?: any;
  isBig?: boolean;
  isValidateLoading?: boolean;
}

const InformationPopup: React.FC<IInformationPopupProps> = (props) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const {
    firstTitle,
    secondTitle,
    onConfirm,
    children,
    component,
    isValidateLoading,
    components,
    isBig,
    message,
    componentsSpacing,
    messages,
    confirmButtonText,
  } = props;

  return ReactDOM.createPortal(
    <>
      <FormPopupInfo isBig={isBig} xs={windowWidth > 500 ? 7 : 12}>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            item
            display="grid"
            style={{
              borderRadius: "24px",
              paddingTop: "20px",
              paddingLeft: "50px",
              paddingRight: "42px",
            }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {windowWidth > 615 && (
                <Grid item display="grid">
                  <span
                    style={{
                      textAlign: "center",
                      font: "normal normal bold 26px/39px Poppins",
                      color: "#5BB9C1",
                    }}
                  >
                    {firstTitle}{" "}
                    <span
                      style={{
                        textAlign: "center",
                        font: "normal normal bold 26px/39px Poppins",
                        color: "#0B243C",
                      }}
                    >
                      {secondTitle}
                    </span>
                  </span>
                </Grid>
              )}
              <Grid item display="grid">
                <button
                  type="button"
                  className="modal-close-button"
                  onClick={onConfirm}
                >
                  <span style={{ color: "#8D9899", textAlign: "center" }}>
                    &times;
                  </span>
                </button>
              </Grid>
            </Grid>
          </Grid>
          {message?.length > 0 && (
            <Grid
              item
              display="grid"
              justifyContent="center"
              marginTop="28px"
              paddingLeft="20px"
              paddingRight="20px"
            >
              <span
                style={{
                  textAlign: "center",
                  color: "#657273",
                  font: "normal normal normal 16px/25px Poppins",
                }}
              >
                {message}
              </span>
            </Grid>
          )}
          {children && (
            <Grid item display="grid">
              {children}
            </Grid>
          )}
          {components?.length > 0 && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              display="flex"
              spacing={componentsSpacing as any}
              justifyContent="center"
              marginTop="28px"
            >
              {components?.map((component, index) => {
                return (
                  <Grid item key={index} display="grid">
                    {component}
                  </Grid>
                );
              })}
            </Grid>
          )}
          {messages?.length > 0 && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              display="flex"
              justifyContent="center"
              marginTop="28px"
            >
              {messages?.map((element, index) => {
                return (
                  <Grid key={index} item display="grid">
                    <span
                      style={{
                        textAlign: "center",
                        color: "#657273",
                        font: "normal normal normal 16px/25px Poppins",
                      }}
                    >
                      {element}
                    </span>
                  </Grid>
                );
              })}
            </Grid>
          )}
          <Grid item display="grid" marginTop="30px" paddingBottom="30px">
            {confirmButtonText && (
              <Grid
                container
                display="flex"
                direction="row"
                justifyContent="center"
              >
                <Grid item display="grid">
                  <OrangeButton
                    animation={false}
                    enable={!isValidateLoading}
                    onClick={onConfirm}
                    text={confirmButtonText}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </FormPopupInfo>
    </>,
    document.body
  );
};

InformationPopup.defaultProps = {
  firstTitle: "Default",
  secondTitle: "Default",
  message: "",
  messages: [],
  component: null,
  componentsSpacing: "0px",
  components: [],
  isBig: false,
  isValidateLoading: false,
  confirmButtonText: "Oui",
  onConfirm: () => {
    console.error(
      "You forgot the 'onConfirm' attribute in the 'InformationPopup' component"
    );
  },
};

export default InformationPopup;
