import * as React from 'react';
import IFilterProps from './IFilterProps';
import './FilterStyle.scss';

const ClearFilterItem = (props: IFilterProps) => {
  return (
    <button
      onClick={() => props.clearFilters()}
      style={{margin: '0', padding: '0'}}
      className='clear-text-button' >
      Effacer
    </button >
  );
};

export default ClearFilterItem;