import { Grid } from "@mui/material";
import * as React from "react";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import FullField from "../../../Fields/FullField";
import MultilineInput from "../../../Library/Inputs/MultilineInput";

const DisplayNotification: React.FC<any> = (props) => {
  const { questionnaireData, setQuestionnaireData } = props;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <OrangeCheckBox
          value={questionnaireData?.sendNotification}
          onClick={() => {
            const questionnaireDataTmp = { ...questionnaireData };
            questionnaireDataTmp.sendNotification =
              !questionnaireDataTmp.sendNotification;
            setQuestionnaireData(questionnaireDataTmp);
          }}
          text="Envoyer un accusé de réception par SMS aux patients enregistrés"
        />
      </Grid>
      {questionnaireData?.sendNotification && (
        <>
          <Grid item display="grid" paddingTop="10px">
            <Grid container direction="column" wrap="nowrap">
              <Grid item display="grid">
                <span
                  style={{
                    color: "#0B243C",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "14px",
                  }}
                >
                  Vous pouvez utiliser les variables ci-dessous :
                </span>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    color: "#0B243C",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "14px",
                  }}
                >
                  {"{nom_patient} - {prenom_patient}"}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid" paddingTop="5px">
            <MultilineInput
              height="80px"
              maxLength={200}
              whiteSpace="pre-line"
              value={questionnaireData?.notificationMessage}
              onChange={(e) => {
                const questionnaireDataTmp = { ...questionnaireData };
                questionnaireDataTmp.notificationMessage = e;
                setQuestionnaireData(questionnaireDataTmp);
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DisplayNotification;
