import * as React from "react";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import TextInput from "../../Library/Inputs/TextInput";
import { AssignmentOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import FormPopup from "../../FormPopup/FormPopup";
import FormPopupHeader from "../../FormPopup/FormPopupHeader";
import { closeShare } from "../../../redux/shareQuestionnaireReducer";
import { closeSnackbar, openSnackbar } from "../../../redux/snackbarReducer";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import FullField from "../../Fields/FullField";
import MySelect from "../../Library/Select/MySelect";
import useAppSelector from "../../../redux/useAppSelector";

const UniqueLinkRow: React.FC<{ patientsUrl: any }> = (props: any) => {
  const { patientsUrl } = props;
  const reducer = useAppSelector((state) => state.shareQuestionnaireReducer);
  const [patientSearched, setPatientSearched] = React.useState(null);
  const [patientList, setPatientList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  const getUniqueUrl = () => {
    const result = patientsUrl?.find((p) => p?.id === patientSearched?.value);
    return result?.url;
  };

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", `/patients/list`, null, "v3").then((res: any) => {
      setPatientList(res?.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction="column"
      spacing="10px"
      wrap="nowrap"
      paddingBottom="50px"
    >
      <Grid item display="grid">
        <FullField isMandatory={false} title="Lien unique">
          <MySelect
            maxMenuHeight={patientSearched?.value ? 150 : 100}
            options={(() => {
              const optionsTmp = [];
              if (!patientList) return optionsTmp;
              for (const patient of patientList) {
                optionsTmp.push({
                  value: patient?.id,
                  label:
                    patient?.lastname || patient?.firstname
                      ? patient?.lastname + " " + patient?.firstname
                      : patient?.phone,
                });
              }
              return optionsTmp;
            })()}
            noOptionsMessage={() => "Aucun résultat"}
            value={!patientSearched?.value ? null : patientSearched}
            placeholder="Nom du patient"
            onChange={(e) => {
              setPatientSearched(e);
            }}
          />
        </FullField>
      </Grid>
      {patientSearched && (
        <Grid item display="grid">
          <TextInput
            backgroundColor="#edf2f280"
            RightIcon={
              <Tooltip title="Copier le lien">
                <IconButton
                  style={{
                    margin: "0",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  <AssignmentOutlined
                    fontSize="medium"
                    style={{ color: "rgb(11, 36, 60)" }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        reducer?.link + "?p=" + getUniqueUrl()
                      );
                      dispatch(
                        openSnackbar({
                          message: "Lien copié dans le presse-papiers",
                          type: "success",
                          duration: 3000,
                        })
                      );
                    }}
                  />
                </IconButton>
              </Tooltip>
            }
            onChange={() => {
              return null;
            }}
            value={reducer?.link + "?p=" + getUniqueUrl()}
          />
        </Grid>
      )}
    </Grid>
  );
};

const PublicLinkRow = () => {
  const reducer = useAppSelector((state) => state.shareQuestionnaireReducer);
  const dispatch = useDispatch();

  return (
    <FullField isMandatory={false} title="Lien public">
      <TextInput
        backgroundColor="#edf2f280"
        RightIcon={
          <Tooltip title="Copier le lien">
            <IconButton
              style={{ margin: "0", paddingLeft: "5px", paddingRight: "5px" }}
            >
              <AssignmentOutlined
                fontSize="medium"
                style={{ color: "rgb(11, 36, 60)" }}
                onClick={() => {
                  navigator.clipboard.writeText(reducer?.link);
                  dispatch(
                    openSnackbar({
                      message: "Lien copié dans le presse-papiers",
                      type: "success",
                      duration: 3000,
                    })
                  );
                }}
              />
            </IconButton>
          </Tooltip>
        }
        onChange={() => {
          return null;
        }}
        value={reducer?.link}
      />
    </FullField>
  );
};

const PublicStatsLinkRow = () => {
  const reducer = useAppSelector((state) => state.shareQuestionnaireReducer);
  const dispatch = useDispatch();
  const link = reducer?.link?.replace("/q/", "/qstats/");

  return (
    <FullField isMandatory={false} title="Lien vers les statistiques publiques">
      <TextInput
        backgroundColor="#edf2f280"
        RightIcon={
          <Tooltip title="Copier le lien">
            <IconButton
              style={{ margin: "0", paddingLeft: "5px", paddingRight: "5px" }}
            >
              <AssignmentOutlined
                fontSize="medium"
                style={{ color: "rgb(11, 36, 60)" }}
                onClick={() => {
                  navigator.clipboard.writeText(link);
                  dispatch(
                    openSnackbar({
                      message: "Lien copié dans le presse-papiers",
                      type: "success",
                      duration: 3000,
                    })
                  );
                }}
              />
            </IconButton>
          </Tooltip>
        }
        onChange={() => {
          return null;
        }}
        value={link}
      />
    </FullField>
  );
};

const ShareQuestionnaire = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [patientsUrl, setPatientsUrl] = React.useState([]);
  const reducer = useAppSelector((state) => state.shareQuestionnaireReducer);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/patients/list", null, "v3").then((res) => {
      setPatientsUrl(res?.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="40px"
      >
        <FormPopupHeader
          firstTitle={"Partager"}
          secondTitle={"un questionnaire"}
          onClose={() => {
            dispatch(closeShare());
            dispatch(closeSnackbar(null));
          }}
        />
      </Grid>
      {!isLoading ? (
        <Grid item display="grid">
          <Grid
            container
            display="flex"
            width="70%"
            justifySelf="center"
            direction="column"
            wrap="nowrap"
            paddingBottom="70px"
          >
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  color: "rgb(11, 36, 60)",
                }}
              >
                {reducer?.title}
              </span>
            </Grid>
            {reducer?.sharePublicStats === true && (
              <Grid item display="grid" paddingTop="10px">
                <PublicStatsLinkRow />
              </Grid>
            )}
            <Grid item display="grid" paddingTop="10px">
              <PublicLinkRow />
            </Grid>
            <Grid item display="grid" paddingTop="10px">
              <UniqueLinkRow patientsUrl={patientsUrl} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingBottom="50px"
        >
          <CircularProgress />
        </Grid>
      )}
    </FormPopup>
  );
};

export default ShareQuestionnaire;
