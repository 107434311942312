import * as React from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../../../redux/tabsReducer';

const RecentsTabButton: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(setActiveTab('recents'));
      }}
      style={{
        justifyContent: 'center',
        alignItems: 'center', display: 'flex',
        height: '50px',
        paddingLeft: '20px',
        paddingRight: '20px'
      }}>
      <span
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}>
        Tous
      </span>
    </div>
  )
};

export default RecentsTabButton;
