import { Grid } from "@mui/material";
import * as React from 'react';
import MySelect from "../Library/Select/MySelect";

const ConfigGroupsPerPage: React.FC<any> = (props) => {
  const {
    groupsPerPage,
    setGroupsPerPage,
  } = props;

  return (
    <Grid container direction='row' spacing='10px' alignItems='center' wrap='nowrap'>
      <Grid item display='grid'>
        <MySelect
          isClearable={false}
          options={[{ value: 5, label: 5 }, { value: 10, label: 10 }, { value: 20, label: 20 }, { value: 50, label: 50 }, { value: 100, label: 100 }]}
          onChange={(e) => { setGroupsPerPage(e?.value) }}
          value={{ label: groupsPerPage, value: groupsPerPage }}
        />
      </Grid>
      <Grid item display='grid'>
        <span style={{
          color: '#8D9899',
          letterSpacing: '0px',
          fontSize: '16px',
          fontFamily: 'Poppins',
        }}>
          par page
        </span>
      </Grid>
    </Grid>
  );
}

export default ConfigGroupsPerPage;