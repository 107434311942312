import * as React from 'react';
import { CircularProgress, Grid } from "@mui/material";
import UsersListTableTitles from './UsersListTableTitles';
import UsersListTableContent from './UsersListTableContent';
import './usersTable.scss';

const UsersListContent: React.FC<any> = (props) => {
  return (
    <>
      <div className="table-users-grid" style={{ overflow: 'auto' }}>
        <UsersListTableTitles />
        {!props?.isLoading &&
          <UsersListTableContent {...props} />
        }
      </div>
      {props?.isLoading &&
        <Grid container direction='row' display='flex' justifyContent='center' alignItems='center' paddingTop='15px'>
          <CircularProgress />
        </Grid>
      }
    </>
  );
}

export default UsersListContent;