import { Grid } from "@mui/material";
import Dropzone from "react-dropzone";
import FullField from "../../../Fields/FullField";
import * as React from 'react';
import swal from "sweetalert";


const DisplayDocument: React.FC<any> = (props) => {
  const {
    questionnaireData,
    setQuestionnaireData,
  } = props;

  const handleFile = (e) => {
    const fileReader = new FileReader();
    if (e.target.files[0] != null) {
      if (e.target.files[0].size >= 400000000) {
        swal({
          text: "Le fichier est trop lourd",
          icon: "warning",
          dangerMode: true,
        });
      } else if (e.target.files[0].type != "image/png"
        && e.target.files[0].type != "image/jpg"
        && e.target.files[0].type != "image/jpeg") {
        swal({
          text: "Le fichier est au mauvais format",
          icon: "warning"
        });
      } else {
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = (event) => {
          if (event?.target?.result && e?.target?.files?.[0]?.name) {
            const questionnaireDataTmp = { ...questionnaireData };

            questionnaireDataTmp.attachment = event.target.result;
            questionnaireDataTmp.attachment_name = e?.target?.files?.[0]?.name;
            setQuestionnaireData(questionnaireDataTmp);
          }
        }
      }
    }
  }

  return (
    <FullField title='Ajouter une image' isMandatory={false}>
      {!questionnaireData?.attachment && !questionnaireData?.attachment_name &&
        <Grid item display='grid' style={{ width: '100%' }}>
          <Dropzone
            onDrop={() => { /* Non géré */ return (null) }}>
            {({ getRootProps }) => (
              <div className="file_modals" style={{ width: '100%', height: '100%', padding: '15px', backgroundColor: 'transparent', margin: '0', flexDirection: 'column', justifyContent: 'center', display: 'flex', cursor: 'unset' }} {...getRootProps()}>
                <div className="modal_file_button" style={{ alignItems: 'center', display: 'grid', padding: '0', marginTop: '0px', cursor: 'unset', width: '100%', maxWidth: '350px' }}>
                  <div className="modal_file_button_text" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', cursor: 'unset' }}>
                    <input type="file" name={`file-questionnaire`} id={`file-questionnaire`} onChange={(e) => { handleFile(e) }}
                      style={{ display: 'none', cursor: 'pointer', width: '100%' }} />
                    <label style={{ cursor: 'pointer', width: '100%' }} htmlFor={`file-questionnaire`}>Joindre une image</label>
                  </div>
                </div>
                <div className="accept_format">
                  Formats acceptés : png, jpeg, jpg. Maxi 4mo.
                </div>
              </div>)}
          </Dropzone>
        </Grid>
      }
      {questionnaireData?.attachment && questionnaireData?.attachment_name &&
        <Grid container direction='row' display='flex' justifyContent='space-between' border='2px dashed #8d9899' borderRadius='15px' padding='5px 10px 5px 10px'>
          <Grid item display='grid' alignItems='center'>
            <span
              className='text_champs'>
              {questionnaireData?.attachment_name}
            </span>
          </Grid>
          <Grid item display='grid' alignItems='center'>
            <button
              onClick={() => {
                const questionnaireDataTmp = { ...questionnaireData };

                questionnaireDataTmp.attachment = '';
                questionnaireDataTmp.attachment_name = '';
                setQuestionnaireData(questionnaireDataTmp);
              }}
              className={'delete_advice_button'}
              style={{ padding: '0', margin: '0' }}>
              Supprimer
            </button>
          </Grid>
        </Grid>
      }
    </FullField>
  );
};

export default DisplayDocument;