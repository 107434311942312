class PatientAgreement {
  public getPatients = (): Array<string> => {
    const patientsAgreementString = localStorage.getItem('patients_agreement');
    let patientsAgreement = [];

    if (patientsAgreementString) patientsAgreement = JSON.parse(patientsAgreementString);
    return (patientsAgreement);
  }

  public deletePatientById = (id: string): Array<string> => {
    const patients = this.getPatients();
    const indexToDelete = patients.findIndex((element) => element == id)
    if (indexToDelete === -1) return;
    delete patients[indexToDelete];
    const result = patients.filter((element) => element != undefined);
    localStorage.setItem('patients_agreement', JSON.stringify(result));
    return (result);
  }

  public addPatientById = (id: string): Array<string> => {
    const patients = this.getPatients();

    if (!patients.includes(id)) {
      patients.push(id);
      localStorage.setItem('patients_agreement', JSON.stringify(patients));
    }
    return (patients);
  }

  public isOk = (patientsSelected: Array<string>): boolean => {
    if (patientsSelected.length === 0) return (false);

    const patients = this.getPatients();
    for (const patientSelected of patientsSelected) {
      if (!patients.includes(patientSelected)) {
        return (false);
      }
    }
    return (true);
  }
}

export default new PatientAgreement()