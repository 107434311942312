import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import "./OrangeButton.scss";

interface IOrangeButtonProps {
  text?: string;
  onClick?: any;
  enable?: boolean;
  type?: any;
  animation?: boolean;
  style?: any;
  isLoading?: boolean;
}

const OrangeButton = (props: IOrangeButtonProps) => {
  if (props?.isLoading) {
    return (
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <button
      disabled={!props.enable}
      className={`orange-button${
        props.enable ? " enable-orange-button" : " disable-orange-button"
      }${props?.animation ? " button-animation" : ""}`}
      onClick={props.onClick}
      type={props.type}
      style={props?.style}
    >
      {props.text}
    </button>
  );
};

OrangeButton.defaultProps = {
  text: "Default",
  isLoading: false,
  enable: true,
  onClick: () => {
    console.error(
      "You forgot the 'onClick' attribute in the 'OrangeButton' component"
    );
  },
};

export default OrangeButton;
