import {
  DatasetOutlined,
  DonutSmallOutlined,
  InsertPageBreakOutlined,
  PercentOutlined,
  StackedBarChartOutlined,
  TextSnippetOutlined,
} from "@mui/icons-material";

const typeOptionsPDF = [
  {
    value: "display_text_and_image",
    label: "Textes et images personnalisés",
    icon: TextSnippetOutlined,
  },
  {
    value: "page_break",
    label: "Saut de page",
    icon: InsertPageBreakOutlined,
  },
  {
    value: "circular_diagram",
    label: "Diagramme circulaire",
    icon: DonutSmallOutlined,
  },
  {
    value: "stacked_bar_chart",
    label: "Graphique en barres empilées",
    icon: StackedBarChartOutlined,
  },
  {
    value: "array",
    label: "Tableau",
    icon: DatasetOutlined,
  },
  {
    value: "variable",
    label: "Variable",
    icon: PercentOutlined,
  },
  {
    value: "saisis_par_delegue",
    label: "Saisis par délégué",
    icon: DonutSmallOutlined,
  },
  {
    value: "statistique_envoi",
    label: "Statistiques d'envoi",
    icon: StackedBarChartOutlined,
  },
];

export default typeOptionsPDF;
