import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/snackbarReducer";
import makeAPIRequest from "../../utils/makeAPIRequest";
import IAlert from "./IAlert";

const formatDestinataires = (usersList, usersId) => {
  let destinataires = "";
  if (!usersList || usersList?.length === 0) return "";
  if (!usersId || usersId?.length === 0) return "";
  for (const userId of usersId) {
    const userFounded = usersList?.find((user) => user?.user_id == userId);
    if (userFounded && userFounded?.email) {
      destinataires += `${destinataires.length > 0 ? "\n" : ""}${
        userFounded.email
      }`;
    }
  }
  return destinataires;
};

const AlertsListTableContent: React.FC<any> = (props) => {
  const {
    alerts,
    setAlerts,
    setEditInfos,
    setCreateAlertIsOpen,
    actualPage,
    usersList,
    pages,
  } = props;
  const dispatch = useDispatch();

  return (
    <>
      {pages?.[actualPage]?.map((alert: IAlert, index) => {
        return (
          <>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {alert.alertType}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre-line",
              }}
            >
              {alert.limit}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre-line",
              }}
            >
              {alert.period}s
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre-line",
              }}
            >
              {formatDestinataires(usersList, alert.destinataires)}
            </span>
            <Grid
              container
              className="table-grid-text"
              justifyContent="center"
              direction="column"
              wrap="nowrap"
            >
              {alert.reactions.length > 0 ? (
                alert.reactions.map((reaction, index) => {
                  return (
                    <Grid item key={index} display="grid">
                      {reaction}
                    </Grid>
                  );
                })
              ) : (
                <Grid item display="grid">
                  Aucune
                </Grid>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              className="table-grid-text"
              spacing="5px"
              wrap="nowrap"
            >
              <Grid item display="grid">
                <Tooltip title="Modifier">
                  <IconButton
                    onClick={() => {
                      setEditInfos(alert);
                      setCreateAlertIsOpen(true);
                    }}
                    style={{
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <EditRounded
                      fontSize="small"
                      style={{ color: "#0B243C" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item display="grid">
                <Tooltip title="Supprimer">
                  <IconButton
                    onClick={(res) => {
                      makeAPIRequest(
                        "delete",
                        `/alert/${alert._id}/delete`,
                        null,
                        "v3"
                      )
                        .then((res) => {
                          let alertsTmp = [...alerts];
                          const indexToDelete = alertsTmp?.findIndex(
                            (a) => a?._id === alert._id
                          );
                          if (indexToDelete !== -1) {
                            delete alertsTmp[indexToDelete];
                            alertsTmp = alertsTmp?.filter(
                              (a) => a != undefined
                            );
                            setAlerts(alertsTmp);
                          }
                          dispatch(
                            openSnackbar({
                              type: "success",
                              message: "L'alerte a bien été supprimée",
                              duration: 3000,
                            })
                          );
                        })
                        .catch((err) => {
                          dispatch(
                            openSnackbar({
                              type: "error",
                              message:
                                "La suppression de cette alerte a échouée",
                              duration: 3000,
                            })
                          );
                        });
                    }}
                    style={{
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <ClearRounded
                      fontSize="small"
                      style={{ color: "#FF3100" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        );
      })}
    </>
  );
};

export default AlertsListTableContent;
