import FullPage from "./FullPage";
import * as React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { ChevronLeftOutlined } from "@mui/icons-material";
import BoxTitle from "../components/Library/Box/BoxTitle";
import makeAPIRequest from "../utils/makeAPIRequest";
import http from "../http-common";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import MySelect from "../components/Library/Select/MySelect";
import FullField from "../components/Fields/FullField";
import ConfigScheduledPerPage from "../components/Questionnaire/Scheduled/ConfigScheduledPerPage";
import ScheduledNavigation from "../components/Questionnaire/Scheduled/ScheduledNavigation";
import DisplayFormScheduled from "../components/Questionnaire/Scheduled/DisplayFormScheduled";
import StartQuestionnaire from "../components/Questionnaire/StartQuestionnaire";
import useQuery from "../utils/hooks/useQuery";
import useAppSelector from "../redux/useAppSelector";

const DisplayHowManyScheduled: React.FC<any> = (props) => {
  const { filteredAllScheduled } = props;

  const nbScheduled = filteredAllScheduled?.length;

  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
        paddingBottom: !nbScheduled || nbScheduled === 0 ? "70px" : undefined,
      }}
    >
      {!nbScheduled || nbScheduled === 0
        ? "Aucune programmation trouvée"
        : `${nbScheduled} programmation${nbScheduled > 1 ? "s" : ""} trouvée${
            nbScheduled > 1 ? "s" : ""
          }`}
    </span>
  );
};

const DisplayAllScheduled: React.FC<any> = (props) => {
  const { allScheduled, setAllScheduled, forms, patientList } = props;

  const editFormScheduleReducer = useAppSelector(
    (state) => state.editFormScheduleReducer
  );

  // Pages
  const [scheduledPerPage, setScheduledPerPage] = React.useState(5);
  const [actualPage, setActualPage] = React.useState(0);
  const [pages, setPages] = React.useState([]);

  const [statusFilter, setStatusFilter] = React.useState(null);
  const [formSearched, setFormSearched] = React.useState(null);
  const [patientSearched, setPatientSearched] = React.useState(null);
  const [sortingByRecent, setSortingByRecent] = React.useState(true);
  const [displayArchived, setDisplayArchived] = React.useState(false);
  const [filteredAllScheduled, setFilteredAllScheduled] = React.useState([]);

  const patientIdQuery = useQuery().get("p");

  React.useEffect(() => {
    if (patientIdQuery) {
      const patientFounded = patientList?.find((x) => x?.id == patientIdQuery);
      if (patientFounded) {
        setPatientSearched({
          label: patientFounded?.lastname + " " + patientFounded?.firstname,
          value: patientIdQuery,
        });
      }
    }
  }, [patientIdQuery]);

  React.useEffect(() => {
    const getScheduledDate = (scheduled) => {
      if (["cancelled", "completed"].includes(scheduled?.formStatus))
        return scheduled?.lastUpdate;
      return scheduled?.dateTrigger;
    };
    const newFilteredAllScheduled = [...allScheduled];
    setFilteredAllScheduled(
      newFilteredAllScheduled
        ?.filter((scheduled) =>
          statusFilter?.value
            ? scheduled.formStatus === statusFilter?.value
            : true
        )
        ?.filter((scheduled) =>
          displayArchived ? scheduled.isArchived : !scheduled?.isArchived
        )
        ?.filter((scheduled) =>
          patientSearched?.value
            ? scheduled.patient === patientSearched?.value
            : true
        )
        ?.filter((scheduled) =>
          formSearched?.value ? scheduled.formId === formSearched?.value : true
        )
        ?.sort((a, b) =>
          sortingByRecent
            ? getScheduledDate(b) - getScheduledDate(a)
            : getScheduledDate(a) - getScheduledDate(b)
        )
    );
  }, [
    allScheduled,
    sortingByRecent,
    displayArchived,
    statusFilter,
    patientSearched,
    formSearched,
  ]);

  // Update pages after responses filtered change
  React.useEffect(() => {
    const newPages = [];
    let index = 0;
    for (const message of filteredAllScheduled) {
      if (index % scheduledPerPage === 0) {
        newPages.push([]);
      }
      const lastIndex = newPages.length - 1;
      if (newPages[lastIndex]) {
        newPages[lastIndex].push(message);
      }
      index++;
    }
    setPages(newPages);
  }, [filteredAllScheduled, scheduledPerPage]);

  // Update actual page after pages change
  React.useEffect(() => {
    if (actualPage === 0) return;
    if (actualPage > pages.length - 1) {
      setActualPage(pages.length - 1);
      return;
    }
    if (actualPage < 0) {
      setActualPage(0);
      return;
    }
  }, [actualPage, pages]);

  return (
    <>
      {editFormScheduleReducer?.isOpen && (
        <StartQuestionnaire
          isEdit={true}
          allScheduled={allScheduled}
          setAllScheduled={setAllScheduled}
        />
      )}
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            alignItems="center"
            rowSpacing="10px"
            columnSpacing="20px"
          >
            <Grid item display="grid">
              <FullField
                whiteSpace="nowrap"
                title="Filtrer :"
                isMandatory={false}
              >
                <></>
              </FullField>
            </Grid>
            <Grid item display="grid" xs>
              <MySelect
                options={(() => {
                  const optionsTmp = [];
                  if (!patientList) return optionsTmp;
                  for (const patient of patientList) {
                    if (allScheduled?.find((r) => r?.patient === patient?.id)) {
                      optionsTmp.push({
                        value: patient?.id,
                        label: patient?.lastname + " " + patient?.firstname,
                      });
                    }
                  }
                  return optionsTmp;
                })()}
                noOptionsMessage={() => "Aucun résultat"}
                value={!patientSearched?.value ? null : patientSearched}
                placeholder="Patient"
                onChange={(e) => {
                  setPatientSearched(e);
                }}
              />
            </Grid>
            <Grid item xs display="grid">
              <MySelect
                options={(() => {
                  const optionsTmp = [];
                  if (!forms) return optionsTmp;
                  const ids = filteredAllScheduled.map((x) => x?.formId);
                  for (const form of forms) {
                    if (ids.includes(form?.id)) {
                      optionsTmp.push({
                        value: form?.id,
                        label: form?.title,
                      });
                    }
                  }
                  return optionsTmp;
                })()}
                noOptionsMessage={() => "Aucun résultat"}
                value={!formSearched?.value ? null : formSearched}
                placeholder="Questionnaire"
                onChange={(e) => {
                  setFormSearched(e);
                }}
              />
            </Grid>
            <Grid item xs display="grid">
              <MySelect
                options={(() => {
                  const optionsTmp = [
                    {
                      value: "in progress",
                      label: "Attente de la réponse du patient",
                    },
                    {
                      value: "completed",
                      label: "Le patient a répondu",
                    },
                    {
                      value: "pending",
                      label: "Questionnaire non envoyé",
                    },
                    {
                      value: "cancelled",
                      label: "Programmation annulée",
                    },
                  ];
                  return optionsTmp;
                })()}
                noOptionsMessage={() => "Aucun résultat"}
                value={!statusFilter?.value ? null : statusFilter}
                placeholder="Avancement"
                onChange={(e) => {
                  setStatusFilter(e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <OrangeCheckBox
            value={sortingByRecent}
            onClick={() => setSortingByRecent(!sortingByRecent)}
            text="Trier du plus récent au plus ancien"
          />
        </Grid>
        <Grid item display="grid" marginTop="5px">
          <OrangeCheckBox
            value={displayArchived}
            onClick={() => setDisplayArchived(!displayArchived)}
            text="Afficher uniquement les programmations archivées"
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          paddingTop="15px"
        >
          <Grid item display="grid">
            <DisplayHowManyScheduled
              filteredAllScheduled={filteredAllScheduled}
            />
          </Grid>
        </Grid>
        <Grid item display="grid" marginTop="15px">
          <Grid container direction="column" wrap="nowrap" spacing="10px">
            {pages?.[actualPage] &&
              pages?.[actualPage]?.map((filteredScheduled, index) => {
                return (
                  <Grid
                    item
                    key={filteredScheduled?.formScheduleId}
                    display="grid"
                  >
                    <DisplayFormScheduled
                      scheduledIndex={index}
                      filteredScheduled={filteredScheduled}
                      {...props}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        {pages?.length > 0 && (
          <Grid item display="grid" paddingTop="47px">
            <Grid
              container
              direction="row"
              spacing="10px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="grid">
                <ConfigScheduledPerPage
                  scheduledPerPage={scheduledPerPage}
                  setScheduledPerPage={setScheduledPerPage}
                  {...props}
                />
              </Grid>
              <Grid item display="grid">
                <ScheduledNavigation
                  actualPage={actualPage}
                  setActualPage={setActualPage}
                  pages={pages}
                  {...props}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const FormScheduledBox: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [patientList, setPatientList] = React.useState([]);
  const [allScheduled, setAllScheduled] = React.useState(null);
  const [forms, setForms] = React.useState([]);

  React.useEffect(() => {
    if (!isLoading) setIsLoading(true);
    makeAPIRequest("get", `/questions/forms/list`, null, "v3")
      .then((res) => {
        setForms(res?.data);
        makeAPIRequest("get", `/questions/forms/scheduled/list`, null, "v3")
          .then((res) => {
            setAllScheduled(res?.data);
            makeAPIRequest("get", `/patients/list`, null, "v3").then(
              (res: any) => {
                setPatientList(res?.data);
                setIsLoading(false);
              }
            );
          })
          .catch((err) => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid" paddingTop="10px">
        {isLoading && (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && allScheduled && (
          <DisplayAllScheduled
            allScheduled={allScheduled}
            forms={forms}
            patientList={patientList}
            setAllScheduled={setAllScheduled}
          />
        )}
      </Grid>
    </Grid>
  );
};

interface IParams {
  id: string;
}
interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

const QuestionnaireScheduledPage: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  return (
    <>
      <FullPage>
        <Grid container direction="column" width="80vw" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/home?f=questionnaires")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <FormScheduledBox />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default QuestionnaireScheduledPage;
