import FullField from "../FullField";
import * as React from 'react';
import TextInput from "../../Library/Inputs/TextInput";
import { Grid } from "@mui/material";
import Select from "react-select";

interface IAdviceSendPeriodField {
  adviceHours: number,
  onChange: any,
}

const AdviceSendPeriodField = (props: IAdviceSendPeriodField) => {
  const {
    adviceHours,
    onChange,
  } = props;

  const [hours, setHours] = React.useState(0);
  const [days, setDays] = React.useState(0);
  const [months, setMonths] = React.useState(0);
  const [years, setYears] = React.useState(0);
  const [signNumber, setSignNumber] = React.useState({ value: 'positive', label: '+' });

  React.useEffect(() => {
    if (adviceHours < 0) {
      setSignNumber({ value: 'negative', label: '-' });
    }
    recalcul(adviceHours, 0, 0, 0, signNumber);
  }, []);

  React.useEffect(() => {
    recalcul(adviceHours, 0, 0, 0, signNumber);
  }, [signNumber]);

  const cleanNumber = (number?: string) => {
    if (number === '' || number === null) {
      return (0);
    }
    let tmp = '';

    for (let i = 0; i < number.length; i++) {
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(number[i])) tmp += number[i];
    }
    return (parseInt(tmp));
  }

  const recalcul = (newHours?: number, newDays?: number, newMonths?: number, newYears?: number, signNumber?: any) => {
    let hoursTmp = newHours === null ? hours : newHours;
    let daysTmp = newDays === null ? days : newDays;
    let monthsTmp = newMonths === null ? months : newMonths;
    let yearsTmp = newYears === null ? years : newYears;


    if (!hoursTmp) hoursTmp = 0;
    if (!daysTmp) daysTmp = 0;
    if (!monthsTmp) monthsTmp = 0;
    if (!yearsTmp) yearsTmp = 0;

    if (hoursTmp < 0) hoursTmp *= (-1);
    if (daysTmp < 0) daysTmp *= (-1);
    if (monthsTmp < 0) monthsTmp *= (-1);
    if (yearsTmp < 0) yearsTmp *= (-1);

    while (hoursTmp >= 24) { hoursTmp -= 24; daysTmp++; } // positive hours >= 24
    while (daysTmp >= 30) { daysTmp -= 30; monthsTmp++; } // positive days >= 30
    while (monthsTmp >= 12) { monthsTmp -= 12; yearsTmp++; } // positive months >= 12

    // Update hours to send
    const HOURS_IN_A_DAY = 24;
    const HOURS_IN_A_MONTH = HOURS_IN_A_DAY * 30;
    const HOURS_IN_A_YEAR = HOURS_IN_A_MONTH * 12;

    // Update hh/dd/mm/yyyy display
    setHours(hoursTmp);
    setDays(daysTmp);
    setMonths(monthsTmp);
    setYears(yearsTmp);

    if (signNumber.value === "negative") {
      onChange((hoursTmp + daysTmp * HOURS_IN_A_DAY + monthsTmp * HOURS_IN_A_MONTH + yearsTmp * HOURS_IN_A_YEAR) * (-1));
    } else {
      onChange(hoursTmp + daysTmp * HOURS_IN_A_DAY + monthsTmp * HOURS_IN_A_MONTH + yearsTmp * HOURS_IN_A_YEAR);
    }
  };

  const formatValue = (value) => {
    if (value === 0) return ('');
    if (value < 0) {
      return (value * (-1))
    }
    return (value);
  }

  return (
    <FullField title="Période d'envoi" isMandatory={true}>
      <Grid container direction='row' alignItems='center'>
        <Grid item display='grid' paddingRight='15px'>
          <Select
            options={[
              { value: 'positive', label: '+' },
              { value: 'negative', label: '-' }
            ]}
            value={signNumber}
            isClearable={false}
            name={"profession"}
            onChange={(value) => setSignNumber(value)}
          />
        </Grid>
        <Grid item display='grid' paddingRight='7px'>
          <TextInput
            background='#EDF2F2 0% 0% no-repeat padding-box'
            borderRadius='3px'
            height='32px'
            width='38px'
            border='1px solid #D3E0E0'
            type='text'
            padding='5px'
            textAlign='center'
            placeholder='0'
            value={formatValue(hours)}
            onChange={(e) => {
              const result = cleanNumber(e.target.value);
              recalcul(result, null, null, null, signNumber);
            }}
          />
        </Grid>
        <Grid item display='grid' paddingRight='15px'>
          <span className='text_champs'>h</span>
        </Grid>
        <Grid item display='grid' paddingRight='7px'>
          <TextInput
            background='#EDF2F2 0% 0% no-repeat padding-box'
            borderRadius='3px'
            height='32px'
            width='38px'
            border='1px solid #D3E0E0'
            type='text'
            padding='5px'
            textAlign='center'
            placeholder='0'
            value={formatValue(days)}
            onChange={(e) => {
              const result = cleanNumber(e.target.value);
              recalcul(null, result, null, null, signNumber);
            }}
          />
        </Grid>
        <Grid item display='grid' paddingRight='21px'>
          <span className='text_champs'>j</span>
        </Grid>
        <Grid item display='grid' paddingRight='6px'>
          <TextInput
            background='#EDF2F2 0% 0% no-repeat padding-box'
            borderRadius='3px'
            height='32px'
            width='38px'
            border='1px solid #D3E0E0'
            type='text'
            padding='5px'
            placeholder='0'
            textAlign='center'
            value={formatValue(months)}
            onChange={(e) => {
              const result = cleanNumber(e.target.value);
              recalcul(null, null, result, null, signNumber);
            }}
          />
        </Grid>
        <Grid item display='grid' paddingRight='10px'>
          <span className='text_champs'>m</span>
        </Grid>
        <Grid item display='grid' paddingRight='7px'>
          <TextInput
            background='#EDF2F2 0% 0% no-repeat padding-box'
            borderRadius='3px'
            height='32px'
            width='38px'
            border='1px solid #D3E0E0'
            type='text'
            padding='5px'
            textAlign='center'
            placeholder='0'
            value={formatValue(years)}
            onChange={(e) => {
              const result = cleanNumber(e.target.value);
              recalcul(null, null, null, result, signNumber);
            }}
          />
        </Grid>
        <Grid item display='grid'>
          <span className='text_champs'>an(s)</span>
        </Grid>
      </Grid>
    </FullField>
  );
};

export default AdviceSendPeriodField;