import axios from "axios";

const token = "Bearer " + localStorage.getItem("token");
const API_URL = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL:  API_URL,
  headers: {
    "Content-type": "application/json",
    "Authorization" : token,
  }
});