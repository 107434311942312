import FullField from "../../../Fields/FullField";
import * as React from "react";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import { Grid } from "@mui/material";

const DisplaySimpleCheck: React.FC<any> = (props) => {
  const {
    field,
    sectionIndex,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  const isInvalidSimpleCheckField = (field) => {
    if (!field?.validation?.type || !field?.validation?.number) return (false);
    let nbChecked = 0;
    if (field?.value) {
    const values = Object.values(field?.value);
      for (const value of values) {
        if (value) {
          nbChecked++;
        }
      }
    }
    if (field?.validation?.type === 'Exactement' && nbChecked != field?.validation?.number)
      return (true);
    if (field?.validation?.type === 'Au minimum' && field?.validation?.number > nbChecked)
      return (true);
    if (field?.validation?.type === 'Au maximum' && field?.validation?.number < nbChecked)
      return (true);
    return (false);
  }

  const formatHelp = () => {
    const number = field?.validation?.number;
    const type = field?.validation?.type;
    const isMandatory = field?.isMandatory;

    if (isMandatory && type && number) {
      if (type == 'Exactement' && isInvalidSimpleCheckField(field)) {
        return (`Vous devez sélectionner ${number} réponse${number > 1 ? 's' : ''}`);
      }
      if (type == 'Au minimum' && isInvalidSimpleCheckField(field)) {
        return (`Vous devez sélectionner au minimum ${number} réponse${number > 1 ? 's' : ''}`)
      }
      if (type == 'Au maximum' && isInvalidSimpleCheckField(field)) {
        return (`Vous devez sélectionner au maximum ${number} réponse${number > 1 ? 's' : ''}`);
      }
    }
    return (null);
  }

  return (
    <Grid
      container
      id={"field" + field?.id}
      border="0px solid transparent"
      style={{
        width: 'fit-content'
      }}
      borderRadius="10px"
    >
      <FullField
        isQuestionnaire={true}
        title={field?.title}
        isMandatory={false}
        whiteSpace='pre-line'
      >
        <Grid container direction="column" wrap='nowrap' gap="5px">
          <Grid item display='grid'>
            <span style={{
              fontSize: '14px',
              color: '#657273',
              fontFamily: 'Poppins',
            }}>
              {formatHelp()}
            </span>
          </Grid>
          {field?.option?.map((opt, index) => {
            return (
              <Grid item key={index} display="grid">
                <OrangeCheckBox
                  value={field?.value?.[opt] ? true : false}
                  onClick={() => {
                    const questionnaireDataTmp = { ...questionnaireData };

                    const newValue =
                      !questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex]?.value?.[opt];
                    if (
                      !questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex]?.value
                    ) {
                      questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex].value = {};
                    }
                    questionnaireDataTmp.sections[sectionIndex].fields[
                      fieldRowIndex
                    ][fieldIndex].value[opt] = newValue;
                    setQuestionnaireData(questionnaireDataTmp);
                  }}
                  text={opt}
                />
              </Grid>
            );
          })}
        </Grid>
      </FullField>
    </Grid>
  );
};

export default DisplaySimpleCheck;
