import { FunctionComponent } from "react";
import { Card } from "react-bootstrap";
import Tutorial from "../components/Tutorial";
import NavbarComponent from "./NavbarComponent";
import TutorialsList from "../components/TutorialsList";
import Footer from "../components/footer";
import PrivateRoute from "../PrivateRoute";
import "../styles/AppComponents.scss"
import * as React from 'react';
import useGoogleAnalyticsAndUser from "../utils/hooks/useGoogleAnalyticsAndUser";
import useSelectedAdvice from "../utils/hooks/useSelectedAdvice";
import { Grid } from "@mui/material";
import MonPatientBox from "./MonPatientBox/MonPatientBox";
import "./footer.css";
import useAppSelector from "../redux/useAppSelector";

const AppComponent: FunctionComponent = () => {
  const windowWidth = useAppSelector(state => state.windowReducer.windowWidth);
  const [googleAnalytics, user, setGoogleAnalytics, setUser] = useGoogleAnalyticsAndUser();
  const [selectedAdvice, setSelectedAdvice] = useSelectedAdvice();

  React.useEffect(() => {
    googleAnalytics?.visitPage('home');
  }, [googleAnalytics]);

  const getConseilsBoxWidth = () => {
    const calcul = 500 + 84 + 24 + 24;
    const result = windowWidth - calcul;
    if (result >= 1200)
      return (1200);
    if (windowWidth >= 1250) return (result);
    return ('100%');
  };

  return (
    <>
      <Grid container direction='column' wrap="nowrap" display='flex' style={{ minHeight: '100vh', height: '100%'}}>
        <Grid item display='grid' justifyContent='center'>
          <Grid container display='grid' direction='column' wrap="nowrap" spacing='15px'>
            <Grid item display='grid'>
              <NavbarComponent />
            </Grid>
            <Grid item display='grid'>
              <Grid container display='flex' direction='row' justifyContent='center'
                overflow='hidden'>
                <Grid item display='grid' alignItems='flex-start' className="col_card" style={{ justifyContent: 'center', paddingBottom: '25px', paddingLeft: '25px' }}>
                  <MonPatientBox
                    googleAnalytics={googleAnalytics}
                    user={user}
                    setGoogleAnalytics={setGoogleAnalytics}
                    setUser={setUser}
                    setSelectedAdvice={setSelectedAdvice}
                    selectedAdvice={selectedAdvice} />
                </Grid>
                <Grid item xs display='grid' paddingLeft='24px' paddingRight='24px'>
                  <Card className="card card_tutoList shadow-none px-lg-4" style={{ borderRadius: "24px", minWidth: '320px', width: getConseilsBoxWidth() }}>
                    <Card.Body className="card_tutoList">
                      <PrivateRoute
                        exact
                        path={["/home"]}
                        component={TutorialsList}
                        googleAnalytics={googleAnalytics}
                        setSelectedAdvice={setSelectedAdvice}
                        selectedAdvice={selectedAdvice}
                        user={user}
                      />
                      <PrivateRoute path="/tutorials/:id" component={Tutorial} />
                    </Card.Body>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs display='grid' alignItems='flex-end'>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default AppComponent;
