import { Grid } from "@mui/material";
import * as React from "react";
import BoxTitle from "../Library/Box/BoxTitle";
import MySelect from "../Library/Select/MySelect";
import ConfigPatientPerPage from "./ConfigPatientPerPage";
import CreatePatientButton from "./CreatePatientButton";
import ExportPatientsButton from "./ExportPatientsButton";
import HandlePatientsGroups from "./HandlePatientsGroups";
import ImportPatientButton from "./ImportPatientButton";
import MesPatientsContent from "./MesPatientsContent";
import PatientNavigation from "./PatientNavigation";

const PatientListBox: React.FC<any> = (props) => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      minWidth="320px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <BoxTitle first="Mes" second="Patients" />
          </Grid>
          <Grid item display="grid" paddingTop="20px">
            <Grid
              container
              display="flex"
              gap="10px"
              direction="row"
              justifyContent="flex-end"
            >
              <Grid item display="grid">
                <HandlePatientsGroups {...props} />
              </Grid>
              <Grid item display="grid">
                <ExportPatientsButton {...props} />
              </Grid>
              <Grid item display="grid">
                <ImportPatientButton {...props} />
              </Grid>
              <Grid item display="grid">
                <CreatePatientButton />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" wrap="nowrap" paddingTop="20px">
            <Grid item display="grid">
              <Grid container direction="row" spacing="10px">
              <Grid item display="grid">
                  <MySelect
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "160px",
                      }),
                    }}
                    options={(() => {
                      const optionsTmp = [];
                      if (!props?.patientList) return optionsTmp;
                      for (const patient of props?.patientList) {
                        optionsTmp.push({
                          value: patient?.id,
                          label: patient?.id,
                        });
                      }
                      return optionsTmp;
                    })()}
                    noOptionsMessage={() => "Aucun résultat"}
                    value={
                      !props?.patientSearched?.value
                        ? null
                        : {
                          value: props?.patientSearched?.value,
                          label: props?.patientSearched?.value,
                        }
                    }
                    placeholder="ID du patient"
                    onChange={(e) => {
                      const founded = props?.patientList?.find((x) => x?.id == e?.value);
                      props?.setPatientSearched({
                        value: founded?.id,
                        label: founded?.lastname + " " + founded?.firstname,
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <MySelect
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "230px",
                      }),
                    }}
                    options={(() => {
                      const optionsTmp = [];
                      if (!props?.patientList) return optionsTmp;
                      for (const patient of props?.patientList) {
                        optionsTmp.push({
                          value: patient?.id,
                          label: patient?.lastname + " " + patient?.firstname,
                        });
                      }
                      return optionsTmp;
                    })()}
                    noOptionsMessage={() => "Aucun résultat"}
                    value={
                      !props?.patientSearched?.value
                        ? null
                        : props?.patientSearched
                    }
                    placeholder="Nom du patient"
                    onChange={(e) => {
                      props?.setPatientSearched(e);
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <MySelect
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "230px",
                      }),
                    }}
                    options={(() => {
                      const optionsTmp = [];
                      if (!props?.patientGroupsList) return optionsTmp;
                      for (const x of props?.patientGroupsList) {
                        optionsTmp.push({
                          value: x?.id,
                          label: x?.group_name,
                        });
                      }
                      return optionsTmp;
                    })()}
                    noOptionsMessage={() => "Aucun groupe"}
                    value={
                      !props?.groupSearched?.value ? null : props?.groupSearched
                    }
                    placeholder="Nom du groupe"
                    onChange={(e) => {
                      props?.setGroupSearched(e);
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <MySelect
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "200px",
                      }),
                    }}
                    options={[
                      {
                        label: "Uniquement pack en cours",
                        value: "only_pack",
                      },
                      { label: "Aucun pack en cours", value: "no_pack" },
                    ]}
                    value={
                      !props?.packInProgressFilter?.value
                        ? null
                        : props?.packInProgressFilter
                    }
                    placeholder="Pack en cours"
                    isClearable={true}
                    onChange={(e) => {
                      props?.setPackInProgressFilter(e);
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <MySelect
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "200px",
                      }),
                    }}
                    options={[
                      {
                        label: "Sans smartphone",
                        value: "only_without_smartphone",
                      },
                      {
                        label: "Avec smartphone",
                        value: "only_with_smartphone",
                      },
                      {
                        label: "Avec numéro de l'aidant",
                        value: "only_with_helper_phone",
                      },
                    ]}
                    value={
                      !props?.phoneTypeFilter?.value
                        ? null
                        : props?.phoneTypeFilter
                    }
                    placeholder="Type de téléphone"
                    isClearable={true}
                    onChange={(e) => {
                      props?.setPhoneTypeFilter(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid" paddingTop="25px">
              <MesPatientsContent {...props} />
            </Grid>
            {props?.pages?.length > 0 && (
              <Grid item display="grid" paddingTop="5px" marginTop="-100px">
                <Grid
                  container
                  direction="row"
                  spacing="10px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item display="grid">
                    <ConfigPatientPerPage {...props} />
                  </Grid>
                  <Grid item display="grid">
                    <PatientNavigation {...props} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientListBox;
