import { nanoid } from "nanoid";

export interface IConditionChatbot {
  title?: string,
  id: string,
  value: string,
  type: '=' | '>' | '<' | '<=' | '>=' | '!=' | 'contains',
  action: 'add_question' | 'send_alert_by_email' | 'send_sms',
  questionToAdd?: IQuestionChatbot,
  smsToSend?: string,
  receiveAlertAfterQuestionToAddResponse?: boolean,
}

export interface IQuestionChatbot {
  id: string;
  text: string;
  sendMailAfterResponse?: boolean;
  conditions?: Array<IConditionChatbot>
}

export interface IChatbot {
  _id?: string;
  user_id: string;
  creation_date: number;
  id: string;
  title: string;
  smsBefore?: string;
  smsAfter?: string;
  questions: Array<IQuestionChatbot>;
  alwaysSendByNumber?: boolean;

  sendSMSRelance?: boolean,
  dayIntervalSMSRelance?: string,
  durationSMSRelance?: string,
  contentSMSRelance?: string,
}

const initChatbot = (): Partial<IChatbot> => {
  return {
    title: "",
    smsBefore: "",
    smsAfter: "",
    questions: [],
    id: nanoid(6),
    alwaysSendByNumber: false,
  };
};

export default initChatbot;
