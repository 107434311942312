import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import React from "react";
import makeAPIRequest from "../../utils/makeAPIRequest";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import FullField from "../Fields/FullField";
import Creatable from "react-select/creatable";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import ErrorHelp from "../Library/Errors/ErrorHelp";
import MultilineInput from "../Library/Inputs/MultilineInput";
import TextInput from "../Library/Inputs/TextInput";
import { OpenInNewRounded } from "@mui/icons-material";
import { textExportPdf } from "../../utils/exportPdf";
import formatValue from "./Response/formatValue";
import { openSnackbar } from "../../redux/snackbarReducer";
import { useDispatch } from "react-redux";

export const emailValidator = (email: string): boolean => {
  console.log(email);
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!email.match(validRegex)) {
    return false;
  }
  return true;
};

const errorMessages = {
  noDestinataireSelected: "Il faut ajouter au minimum un destinataire",
  emptyContent: "Le contenu du mail ne peut pas être vide",
  emptyEmailSubject: "L'objet du mail ne peut pas être vide",
  noSelectedQuestionnaire: "Il faut sélectionner au minimum un questionnaire",
  "": "",
};

const errorSelector = {
  noDestinataireSelected: "#send-response-destinataires",
  emptyContent: "#send-response-content",
  emptyEmailSubject: "#send-response-subject",
  noSelectedQuestionnaire: "#questionnaires-list-check",
  "": "",
};

const SendResponsePopup: React.FC<any> = (props) => {
  const { setIsResponsesOpen } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [destinataires, setDestinataires] = React.useState([]);
  const [emailSubject, setEmailSubject] = React.useState("Questionnaires");
  const [content, setContent] = React.useState(
    "Vous trouverez ci-joints les questionnaires sous format PDF."
  );
  const [checkedQuestionnaires, setCheckedQuestionnaires] = React.useState<
    string[]
  >([]);
  const [questionnaires, setQuestionnaires] = React.useState([]);
  const [responses, setResponses] = React.useState([]);
  const [error, setError] = React.useState("");
  const [patientList, setPatientList] = React.useState([]);
  const [myListBase64, setMyListBase64] = React.useState([]);

  const getFormatedResponse = (filteredResponse) => {
    const newFilteredResponse = { ...filteredResponse };
    for (const field of filteredResponse?.fields) {
      if (formatValue(field?.value) === "Vide" || !formatValue(field?.value)) {
        continue;
      }
      if (!newFilteredResponse?.sections) newFilteredResponse.sections = {};
      if (!newFilteredResponse.sections[field?.sectionTitle])
        newFilteredResponse.sections[field?.sectionTitle] = [];
      newFilteredResponse.sections[field?.sectionTitle].push(field);
    }
    delete newFilteredResponse.fields;
    return newFilteredResponse;
  };

  React.useEffect(() => {
    if (
      checkedQuestionnaires.length > 0 &&
      myListBase64.length === checkedQuestionnaires.length
    ) {
      const toSend = {
        subject: emailSubject,
        content: content,
        destinataires: destinataires.map((dest) => dest?.label),
        pdfList: myListBase64,
      };
      makeAPIRequest("post", "/questions/response/send_by_mail", toSend, "v3")
        .then((res) => {
          setIsResponsesOpen(false);
          dispatch(
            openSnackbar({
              message: "L'e-mail a été envoyé avec succès",
              type: "success",
              duration: 3000,
            })
          );
        })
        .catch((err) => {
          setIsResponsesOpen(false);
          dispatch(
            openSnackbar({
              message: "L'e-mail n'a pas pu être envoyé",
              type: "error",
              duration: 3000,
            })
          );
        });
    }
  }, [myListBase64, dispatch]);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest('get', `/patients/list`, null, 'v3').then((res: any) => {
      setPatientList(res?.data);
    });
  }, []);

  React.useEffect(() => {
    makeAPIRequest("get", "/questions/forms/list", null, "v3")
      .then((res) => {
        setQuestionnaires(res?.data);
      })
      .catch((err) => console.log(err));
  }, [patientList]);

  React.useEffect(() => {
    const getResponses = async () => {
      const tmp = [];
      for (const questionnaire of questionnaires) {
        const res = await makeAPIRequest(
          "get",
          `/questions/responses/${questionnaire?.id}/list`,
          null,
          "v3"
        );
        tmp.push(
          res?.data
            ?.filter((resp) => !resp?.isArchived)
            ?.sort((a, b) => b?.created - a?.created)
        );
      }
      setResponses(tmp);
      setIsLoading(false);
    };

    getResponses();
  }, [questionnaires]);

  const isValidSubmit = () => {
    const invalidConditions = {
      noDestinataireSelected: destinataires.length === 0,
      emptyEmailSubject: !emailSubject,
      emptyContent: !content,
      noSelectedQuestionnaire: checkedQuestionnaires.length === 0,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  return (
    <>
      <FormPopup>
        <Grid
          item
          display="grid"
          paddingTop="40px"
          paddingLeft="50px"
          paddingRight="50px"
          paddingBottom="40px"
        >
          <FormPopupHeader
            firstTitle={"Envoyer"}
            secondTitle={"des réponses"}
            onClose={() => {
              setIsResponsesOpen(false);
            }}
          />
        </Grid>
        {!isLoading ? (
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              wrap="nowrap"
              paddingBottom="40px"
            >
              <Grid item display="grid">
                <FullField isMandatory={true} title={`Destinataires`}>
                  <div
                    id="send-response-destinataires"
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid transparent",
                    }}
                  >
                    <Creatable
                      noOptionsMessage={() =>
                        "Tapez l'adresse email du destinataire"
                      }
                      formatCreateLabel={(userInput) => `${userInput}`}
                      isMulti={true}
                      maxMenuHeight={120}
                      options={(() => {
                        const optionsTmp = [];
                        return optionsTmp;
                      })()}
                      value={destinataires}
                      placeholder="Liste des destinataires"
                      onChange={(e: any) => {
                        setDestinataires(
                          e?.filter((opt: any) => emailValidator(opt?.label))
                        );
                      }}
                    />
                  </div>
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField isMandatory={true} title={`Objet du mail`}>
                  <TextInput
                    id="send-response-subject"
                    onChange={(e) => setEmailSubject(e?.target?.value)}
                    value={emailSubject}
                  />
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField isMandatory={true} title={`Contenu du mail`}>
                  <Grid container direction="column" wrap="nowrap">
                    <Grid item display="grid" paddingTop="5px">
                      <MultilineInput
                        id="send-response-content"
                        maxLength={50000}
                        onChange={(e) => setContent(e)}
                        value={content}
                        height={"80px"}
                      />
                    </Grid>
                  </Grid>
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField
                  title="Selection des questionnaires"
                  isMandatory={true}
                >
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    spacing="5px"
                    borderRadius="15px"
                    border="1px solid transparent"
                    id="questionnaires-list-check"
                  >
                    {questionnaires.map((quest, index) => {
                      if (
                        !quest?.title ||
                        !responses.find(
                          (resp) => resp[0]?.questionnaireId === quest?.id
                        )
                      ) {
                        return <></>;
                      }
                      return (
                        <Grid item display="grid" key={index}>
                          <Grid
                            container
                            direction="row"
                            spacing="10px"
                            wrap="nowrap"
                            alignItems="center"
                          >
                            <Grid item display="grid">
                              <OrangeCheckBox
                                value={checkedQuestionnaires.includes(
                                  quest?.id
                                )}
                                text={`${quest?.title} (${
                                  responses.find(
                                    (resp) =>
                                      resp[0]?.questionnaireId === quest?.id
                                  )?.length
                                })`}
                                onClick={() => {
                                  setCheckedQuestionnaires((element) => {
                                    if (element.includes(quest?.id)) {
                                      const newArray = [...element];
                                      const indexFounded = element.findIndex(
                                        (e) => e === quest?.id
                                      );
                                      newArray.splice(indexFounded, 1);
                                      return newArray;
                                    } else {
                                      return [...element, quest?.id];
                                    }
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item display="grid">
                              <Tooltip title="Ouvrir">
                                <IconButton
                                  style={{ margin: "0", padding: "0" }}
                                >
                                  <OpenInNewRounded
                                    fontSize="medium"
                                    onClick={async () => {
                                      textExportPdf(
                                        responses
                                          .find(
                                            (resp) =>
                                              resp[0]?.questionnaireId ===
                                              quest?.id
                                          )
                                          .map((resp) =>
                                            getFormatedResponse(resp)
                                          ),
                                        patientList,
                                        quest,
                                        "open"
                                      );
                                    }}
                                    style={{
                                      color: "rgb(11, 36, 60)",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </FullField>
              </Grid>
              <Grid item display="grid">
                <Grid container direction="column" paddingTop="30px" wrap="nowrap">
                  {error?.length > 0 && (
                    <Grid item display="grid">
                      <ErrorHelp
                        errorMessages={errorMessages}
                        errorSelector={errorSelector}
                        error={error}
                      />
                    </Grid>
                  )}
                  <Grid item display="grid" justifyContent="center">
                    {isValidSubmit() ? (
                      <OrangeButton
                        animation={true}
                        enable={true}
                        type="submit"
                        text="Envoyer"
                        onClick={async () => {
                          for (const questId of checkedQuestionnaires) {
                            const result = textExportPdf(
                              responses
                                .find(
                                  (resp) => resp[0]?.questionnaireId === questId
                                )
                                .map((resp) => getFormatedResponse(resp)),
                              patientList,
                              questionnaires.find((q) => q?.id === questId),
                              "get-base64"
                            );
                            result.pdfGenerated.getBase64((data) => {
                              setMyListBase64((l) => {
                                return [
                                  ...l,
                                  { content: data, name: result.name },
                                ];
                              });
                            });
                          }
                        }}
                      />
                    ) : (
                      <OrangeButton
                        animation={false}
                        enable={false}
                        type="submit"
                        text={"Envoyer"}
                        onClick={() => {
                          return null;
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom="50px"
          >
            <CircularProgress />
          </Grid>
        )}
      </FormPopup>
    </>
  );
};

export default SendResponsePopup;
