import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import React from "react";
import makeAPIRequest from "../../utils/makeAPIRequest";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import FullField from "../Fields/FullField";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import ErrorHelp from "../Library/Errors/ErrorHelp";
import MultilineInput from "../Library/Inputs/MultilineInput";
import TextInput from "../Library/Inputs/TextInput";
import { OpenInNewRounded } from "@mui/icons-material";
import { textExportPdf } from "../../utils/exportPdf";
import formatValue from "./Response/formatValue";
import { useDispatch } from "react-redux";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "../../pages/CreateOrEditChatbotLexPage";
import { MoreOrLessButtons } from "../../pages/ChatbotLexPage";

export const getFormatedResponse = (filteredResponse) => {
  const newFilteredResponse = { ...filteredResponse };
  for (const field of filteredResponse?.fields) {
    if (formatValue(field?.value) === "Vide" || !formatValue(field?.value)) {
      continue;
    }
    if (!newFilteredResponse?.sections) newFilteredResponse.sections = {};
    if (!newFilteredResponse.sections[field?.sectionTitle])
      newFilteredResponse.sections[field?.sectionTitle] = [];
    newFilteredResponse.sections[field?.sectionTitle].push(field);
  }
  delete newFilteredResponse.fields;
  return newFilteredResponse;
};

export const getMedimailSearch = (data: MedimailSearchType) => {
  let result = "annuaire=all";
  let isValid = false;
  for (const key of Object.keys(data)) {
    if (data[key]) {
      result += `;${key}=${data[key]}`;
      isValid = true;
    }
  }
  return isValid ? result : "";
};

export const emailValidator = (email: string): boolean => {
  console.log(email);
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!email.match(validRegex)) {
    return false;
  }
  return true;
};

const errorMessages = {
  noDestinataireSelected: "Il faut ajouter au minimum un destinataire",
  emptyContent: "Le contenu du mail ne peut pas être vide",
  emptyEmailSubject: "L'objet du mail ne peut pas être vide",
  noSelectedQuestionnaire: "Il faut sélectionner au minimum un questionnaire",
  "": "",
};

const errorSelector = {
  noDestinataireSelected: "#send-response-destinataires",
  emptyContent: "#send-response-content",
  emptyEmailSubject: "#send-response-subject",
  noSelectedQuestionnaire: "#questionnaires-list-check",
  "": "",
};

export interface MedimailSearchType {
  nom: string;
  prenom: string;
  rpps: string;
  adresse: string;
  ville: string;
  postalCode: string;
  region: string;
  departement: string;
  profession: string;
  specialite: string;
  mail: string;
  tel: string;
}

const DestinataireCard: React.FC<{
  info: MedimailSearchType;
  setSelectedMails: React.Dispatch<React.SetStateAction<MedimailSearchType[]>>;
  selectedMails: MedimailSearchType[];
}> = (props) => {
  const { info, setSelectedMails, selectedMails } = props;
  return (
    <Grid
      container
      direction="row"
      padding="15px"
      border="1px solid #0b243c"
      borderRadius="30px"
      wrap="nowrap"
      gap="15px"
    >
      <Grid item display="grid">
        <OrangeCheckBox
          onClick={() => {
            setSelectedMails((state) => {
              if (state.find((x) => x.mail === info.mail)) {
                return state.filter((y) => y.mail !== info.mail);
              }
              return [...state, info];
            });
          }}
          value={selectedMails.find((x) => x.mail === info.mail) !== undefined}
        />
      </Grid>
      <Grid item display="grid" xs>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap="5px"
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#0B243C",
            wordBreak: "break-all",
          }}
        >
          <Grid item display="grid" alignSelf="center">
            <span>{info.mail}</span>
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              gap="10px"
            >
              {info.nom && info.prenom && (
                <Grid item display="grid">
                  <span>
                    {info.nom} {info.prenom}
                  </span>
                </Grid>
              )}
              {info.ville && (
                <Grid item display="grid">
                  <span>
                    {info.ville}
                    {info.postalCode ? ` (${info.postalCode})` : ""}
                  </span>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              gap="10px"
            >
              {(info.profession || info.specialite) && (
                <Grid item display="grid">
                  <span>
                    {[info.profession, info.specialite]
                      .filter((x) => x)
                      .join(" - ")}
                  </span>
                </Grid>
              )}
              {info.tel && (
                <Grid item display="grid">
                  <span>
                    {[info.tel ? `Tél : ${info.tel}` : ""]
                      .filter((x) => x)
                      .join(" - ")}
                  </span>
                </Grid>
              )}
              {info.rpps && (
                <Grid item display="grid">
                  <span>
                    {[info.rpps ? `RPPS : ${info.rpps}` : ""]
                      .filter((x) => x)
                      .join(" - ")}
                  </span>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const MedimailSearch: React.FC<{
  onUpdateSelected: (selected: MedimailSearchType[]) => void;
  displayOnlyNameAndLastName?: boolean;
  headerText?: string;
}> = (props) => {
  const [searchInputs, setSearchInputs] = React.useState<MedimailSearchType>({
    nom: "",
    adresse: "",
    departement: "",
    mail: "",
    postalCode: "",
    prenom: "",
    profession: "",
    region: "",
    rpps: "",
    specialite: "",
    tel: "",
    ville: "",
  });

  const isNotEmptyFields = () => {
    for (const key of Object.keys(searchInputs)) {
      if (searchInputs[key]) {
        return true;
      }
    }
    return false;
  };

  const [searchList, setSearchStack] = React.useState<string[]>([]);
  const [selectedMails, setSelectedMails] = React.useState<
    MedimailSearchType[]
  >([]);
  const [destOptions, setDestOptions] = React.useState<MedimailSearchType[]>(
    []
  );
  const [destLoading, setDestLoading] = React.useState<boolean>(false);
  const [lastChangeTime, setLastChangeTime] = React.useState<number>(
    Date.now()
  );
  const [timeNow, setTimeNow] = React.useState(Date.now());
  const [maxItem, setMaxItem] = React.useState<number>(5);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeNow(Date.now());
    }, 500);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    setDestOptions((x) => {
      const selected = selectedMails.map((y) => y.mail);
      return x.filter((y) => !selected.includes(y.mail));
    });
    props.onUpdateSelected(selectedMails);
  }, [selectedMails]);

  React.useEffect(() => {
    if (lastChangeTime + 1000 > timeNow) {
      return;
    }
    if (!searchList || searchList.length === 0) {
      setDestLoading(false);
      setDestOptions([]);
      return;
    }
    const search = searchList[searchList.length - 1];
    if (search === searchList?.[searchList.length - 2]) {
      return;
    }
    if (search) {
      setSearchStack((x) => {
        return [...x, search];
      });
      makeAPIRequest(
        "post",
        "/medimail/annuaire/search",
        {
          search: search,
        },
        "v3"
      )
        .then((res) => {
          const data = res?.data;
          if (Array.isArray(data) && data.length > 0) {
            setDestOptions(data);
          } else {
            setDestOptions([]);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        })
        .finally(() => {
          setDestLoading(false);
        });
    } else {
      setDestOptions([]);
      setDestLoading(false);
    }
  }, [searchList, lastChangeTime, timeNow]);

  React.useEffect(() => {
    const search = getMedimailSearch(searchInputs);
    if (!search || search === searchList?.[searchList.length - 1]) {
      setDestLoading(false);
      setDestOptions([]);
      return;
    }
    setDestLoading(true);
    setLastChangeTime(Date.now());
    setSearchStack((x) => {
      return [...x, search];
    });
  }, [searchInputs]);

  return (
    <Grid container direction="column" gap="15px" wrap="nowrap">
      {/* Fields */}
      <Grid item display="grid">
        <Grid container direction="column" gap="15px" wrap="nowrap">
          <Grid item display="grid">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "#0B243C",
                textAlign: "center",
              }}
            >
              {props.headerText}
            </span>
          </Grid>
          <Grid item display="grid">
            <Grid container direction="row" gap="10px">
              <Grid item xs display="grid" minWidth="200px">
                <GenericTextInput
                  fieldTitle="Nom"
                  isMandatory={false}
                  id="medimail-input-nom"
                  value={searchInputs.nom}
                  onChange={(e) => {
                    setSearchInputs((x) => {
                      return { ...x, nom: e.target.value };
                    });
                  }}
                />
              </Grid>
              <Grid item xs display="grid" minWidth="200px">
                <GenericTextInput
                  fieldTitle="Prénom"
                  isMandatory={false}
                  id="medimail-input-prenom"
                  value={searchInputs.prenom}
                  onChange={(e) => {
                    setSearchInputs((x) => {
                      return { ...x, prenom: e.target.value };
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {!props.displayOnlyNameAndLastName && (
            <Grid item display="grid">
              <Grid container direction="row" gap="10px">
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Code postal"
                    isMandatory={false}
                    id="medimail-input-postalCode"
                    value={searchInputs.postalCode}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, postalCode: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Ville"
                    isMandatory={false}
                    id="medimail-input-ville"
                    value={searchInputs.ville}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, ville: e.target.value };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!props.displayOnlyNameAndLastName && (
            <Grid item display="grid">
              <Grid container direction="row" gap="10px">
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Adresse"
                    isMandatory={false}
                    id="medimail-input-adresse"
                    value={searchInputs.adresse}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, adresse: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Département"
                    isMandatory={false}
                    id="medimail-input-departement"
                    value={searchInputs.departement}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, departement: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Région"
                    isMandatory={false}
                    id="medimail-input-region"
                    value={searchInputs.region}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, region: e.target.value };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!props.displayOnlyNameAndLastName && (
            <Grid item display="grid">
              <Grid container direction="row" gap="10px">
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Spécialité"
                    isMandatory={false}
                    id="medimail-input-specialite"
                    value={searchInputs.specialite}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, specialite: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Profession"
                    isMandatory={false}
                    id="medimail-input-profession"
                    value={searchInputs.profession}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, profession: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="RPPS"
                    isMandatory={false}
                    id="medimail-input-rpps"
                    value={searchInputs.rpps}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, rpps: e.target.value };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!props.displayOnlyNameAndLastName && (
            <Grid item display="grid">
              <Grid container direction="row" gap="10px">
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Numéro de téléphone"
                    isMandatory={false}
                    id="medimail-input-tel"
                    value={searchInputs.tel}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, tel: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs display="grid" minWidth="200px">
                  <GenericTextInput
                    fieldTitle="Adresse e-mail"
                    isMandatory={false}
                    id="medimail-input-mail"
                    value={searchInputs.mail}
                    onChange={(e) => {
                      setSearchInputs((x) => {
                        return { ...x, mail: e.target.value };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* Results */}
      {destLoading && (
        <Grid item display="grid" marginTop="10px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {selectedMails.length > 0 && (
        <Grid item display="grid" marginTop="10px">
          <Grid container direction="column" gap="10px" wrap="nowrap">
            <Grid
              item
              display="grid"
              justifySelf="center"
              style={{
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "#0B243C",
              }}
            >
              <span>
                {selectedMails.length} destinataire
                {selectedMails.length > 1 ? "s" : ""} selectionné
                {selectedMails.length > 1 ? "s" : ""}
              </span>
            </Grid>
            <Grid item display="grid">
              <Grid container direction="column" gap="5px" wrap="nowrap">
                {selectedMails.map((info, index) => {
                  return (
                    <Grid item display="grid" key={index}>
                      <DestinataireCard
                        info={info}
                        selectedMails={selectedMails}
                        setSelectedMails={setSelectedMails}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {isNotEmptyFields() && !destLoading && (
        <Grid item display="grid" marginTop="10px">
          <Grid container direction="column" gap="10px" wrap="nowrap">
            <Grid
              item
              display="grid"
              justifySelf="center"
              style={{
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "#0B243C",
              }}
            >
              {destOptions.length > 1 && (
                <span>{destOptions.length} destinataires trouvés</span>
              )}
              {destOptions.length === 1 && <span>1 destinataire trouvé</span>}
              {destOptions.length === 0 && (
                <span>Aucun destinataire trouvé</span>
              )}
            </Grid>
            {destOptions.length > 0 && (
              <>
                <Grid item display="grid">
                  <Grid container direction="column" gap="5px" wrap="nowrap">
                    {destOptions.slice(0, maxItem).map((info, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <DestinataireCard
                            info={info}
                            selectedMails={selectedMails}
                            setSelectedMails={setSelectedMails}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item display="grid">
                  <MoreOrLessButtons
                    marginTop="10px"
                    items={destOptions}
                    maxItem={maxItem}
                    setMaxItem={setMaxItem}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

MedimailSearch.defaultProps = {
  displayOnlyNameAndLastName: false,
  headerText: `Commencez par remplir l'un des champs ci-dessous, puis cochez votre destinataire`,
}

const SendResponseMedimailPopup: React.FC<any> = (props) => {
  const { setIsResponsesMedimailOpen } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [checkedQuestionnaires, setCheckedQuestionnaires] = React.useState<
    string[]
  >([]);

  /* Generic Medimail */
  const [destinataires, setDestinataires] = React.useState([]);
  const [destOptions, setDestOptions] = React.useState([]);
  const [emailSubject, setEmailSubject] = React.useState("Questionnaires");
  const [content, setContent] = React.useState(
    "Vous trouverez ci-joint les questionnaires sous format PDF."
  );
  const [destLoading, setDestLoading] = React.useState<boolean>(false);
  const [myListBase64, setMyListBase64] = React.useState([]);
  const [searchList, setSearchStack] = React.useState<string[]>([]);
  const [lastChangeTime, setLastChangeTime] = React.useState(Date.now());
  const [timeNow, setTimeNow] = React.useState(Date.now());

  const [questionnaires, setQuestionnaires] = React.useState([]);
  const [responses, setResponses] = React.useState([]);
  const [error, setError] = React.useState("");
  const [patientList, setPatientList] = React.useState([]);
  const snackbarUse = useOpenSnackbar();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeNow(Date.now());
    }, 500);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (lastChangeTime + 1000 > timeNow) {
      return;
    }
    if (!searchList || searchList.length === 0) {
      setDestLoading(false);
      setDestOptions([]);
      return;
    }
    const search = searchList[searchList.length - 1];
    if (search === searchList?.[searchList.length - 2]) {
      return;
    }
    if (search) {
      setSearchStack((x) => {
        return [...x, search];
      });
      makeAPIRequest(
        "post",
        "/medimail/annuaire/search",
        {
          search: search,
        },
        "v3"
      )
        .then((res) => {
          const data = res?.data;
          if (Array.isArray(data) && data.length > 0) {
            setDestOptions(
              data.map((x) => {
                return {
                  value: x,
                  label: x,
                };
              })
            );
          } else {
            setDestOptions([]);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        })
        .finally(() => {
          setDestLoading(false);
        });
    } else {
      setDestOptions([]);
      setDestLoading(false);
    }
  }, [searchList, lastChangeTime, timeNow]);

  const getFormatedResponse = (filteredResponse) => {
    const newFilteredResponse = { ...filteredResponse };
    for (const field of filteredResponse?.fields) {
      if (formatValue(field?.value) === "Vide" || !formatValue(field?.value)) {
        continue;
      }
      if (!newFilteredResponse?.sections) newFilteredResponse.sections = {};
      if (!newFilteredResponse.sections[field?.sectionTitle])
        newFilteredResponse.sections[field?.sectionTitle] = [];
      newFilteredResponse.sections[field?.sectionTitle].push(field);
    }
    delete newFilteredResponse.fields;
    return newFilteredResponse;
  };

  React.useEffect(() => {
    if (
      checkedQuestionnaires.length > 0 &&
      myListBase64.length === checkedQuestionnaires.length
    ) {
      const toSend = {
        subject: emailSubject,
        content: content,
        destinataires: destinataires.map((dest) => dest?.label),
        pdfList: myListBase64,
      };
      makeAPIRequest(
        "post",
        "/questions/response/send_by_mail_medimail",
        toSend,
        "v3"
      )
        .then((res) => {
          setIsResponsesMedimailOpen(false);
          snackbarUse.success("L'e-mail a été envoyé avec succès.", res);
        })
        .catch((err) => {
          setIsResponsesMedimailOpen(false);
          snackbarUse.error("L'email n'a pas pu être envoyé.", err);
        });
    }
  }, [myListBase64, dispatch]);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", `/patients/list`, null, "v3").then((res: any) => {
      setPatientList(res?.data);
    });
  }, []);

  React.useEffect(() => {
    makeAPIRequest("get", "/questions/forms/list", null, "v3")
      .then((res) => {
        setQuestionnaires(res?.data);
      })
      .catch((err) => console.log(err));
  }, [patientList]);

  React.useEffect(() => {
    const getResponses = async () => {
      const tmp = [];
      for (const questionnaire of questionnaires) {
        const res = await makeAPIRequest(
          "get",
          `/questions/responses/${questionnaire?.id}/list`,
          null,
          "v3"
        );
        tmp.push(
          res?.data
            ?.filter((resp) => !resp?.isArchived)
            ?.sort((a, b) => b?.created - a?.created)
        );
      }
      setResponses(tmp);
      setIsLoading(false);
    };

    getResponses();
  }, [questionnaires]);

  const isValidSubmit = () => {
    const invalidConditions = {
      noDestinataireSelected: destinataires.length === 0,
      emptyEmailSubject: !emailSubject,
      emptyContent: !content,
      noSelectedQuestionnaire: checkedQuestionnaires.length === 0,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  return (
    <>
      <FormPopup xs={10}>
        <Grid
          item
          display="grid"
          paddingTop="40px"
          paddingLeft="50px"
          paddingRight="50px"
          paddingBottom="40px"
        >
          <FormPopupHeader
            firstTitle={"Envoyer"}
            secondTitle={"des réponses"}
            onClose={() => {
              setIsResponsesMedimailOpen(false);
            }}
          />
        </Grid>
        {!isLoading ? (
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              wrap="nowrap"
              paddingBottom="40px"
            >
              <Grid item display="grid" paddingTop="15px">
                <MedimailSearch
                  onUpdateSelected={(selected) => {
                    setDestinataires(
                      selected.map((x) => {
                        return {
                          label: x.mail,
                          value: x.mail,
                        };
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField isMandatory={true} title={`Objet du mail`}>
                  <TextInput
                    id="send-response-subject"
                    onChange={(e) => setEmailSubject(e?.target?.value)}
                    value={emailSubject}
                  />
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField isMandatory={true} title={`Contenu du mail`}>
                  <Grid container direction="column" wrap="nowrap">
                    <Grid item display="grid" paddingTop="5px">
                      <MultilineInput
                        id="send-response-content"
                        maxLength={50000}
                        onChange={(e) => setContent(e)}
                        value={content}
                        height={"80px"}
                      />
                    </Grid>
                  </Grid>
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField
                  title="Selection des questionnaires"
                  isMandatory={true}
                >
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    spacing="5px"
                    borderRadius="15px"
                    border="1px solid transparent"
                    id="questionnaires-list-check"
                  >
                    {questionnaires.map((quest, index) => {
                      if (
                        !quest?.title ||
                        !responses.find(
                          (resp) => resp[0]?.questionnaireId === quest?.id
                        )
                      ) {
                        return <></>;
                      }
                      return (
                        <Grid item display="grid" key={index}>
                          <Grid
                            container
                            direction="row"
                            spacing="10px"
                            wrap="nowrap"
                            alignItems="center"
                          >
                            <Grid item display="grid">
                              <OrangeCheckBox
                                value={checkedQuestionnaires.includes(
                                  quest?.id
                                )}
                                text={`${quest?.title} (${
                                  responses.find(
                                    (resp) =>
                                      resp[0]?.questionnaireId === quest?.id
                                  )?.length
                                })`}
                                onClick={() => {
                                  setCheckedQuestionnaires((element) => {
                                    if (element.includes(quest?.id)) {
                                      const newArray = [...element];
                                      const indexFounded = element.findIndex(
                                        (e) => e === quest?.id
                                      );
                                      newArray.splice(indexFounded, 1);
                                      return newArray;
                                    } else {
                                      if (checkedQuestionnaires.length >= 5) {
                                        snackbarUse.error(
                                          "Vous ne pouvez pas envoyer plus de 5 pièces jointes."
                                        );
                                        return element;
                                      }
                                      return [...element, quest?.id];
                                    }
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item display="grid">
                              <Tooltip title="Ouvrir">
                                <IconButton
                                  style={{ margin: "0", padding: "0" }}
                                >
                                  <OpenInNewRounded
                                    fontSize="medium"
                                    onClick={async () => {
                                      textExportPdf(
                                        responses
                                          .find(
                                            (resp) =>
                                              resp[0]?.questionnaireId ===
                                              quest?.id
                                          )
                                          .map((resp) =>
                                            getFormatedResponse(resp)
                                          ),
                                        patientList,
                                        quest,
                                        "open"
                                      );
                                    }}
                                    style={{
                                      color: "rgb(11, 36, 60)",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </FullField>
              </Grid>
              <Grid item display="grid">
                <Grid container direction="column" paddingTop="30px" wrap="nowrap">
                  {error?.length > 0 && (
                    <Grid item display="grid">
                      <ErrorHelp
                        errorMessages={errorMessages}
                        errorSelector={errorSelector}
                        error={error}
                      />
                    </Grid>
                  )}
                  <Grid item display="grid" justifyContent="center">
                    {isValidSubmit() ? (
                      <OrangeButton
                        animation={true}
                        enable={true}
                        type="submit"
                        text="Envoyer"
                        onClick={async () => {
                          for (const questId of checkedQuestionnaires) {
                            const result = textExportPdf(
                              responses
                                .find(
                                  (resp) => resp[0]?.questionnaireId === questId
                                )
                                .map((resp) => getFormatedResponse(resp)),
                              patientList,
                              questionnaires.find((q) => q?.id === questId),
                              "get-base64"
                            );
                            result.pdfGenerated.getBase64((data) => {
                              setMyListBase64((l) => {
                                return [
                                  ...l,
                                  { content: data, name: result.name },
                                ];
                              });
                            });
                          }
                        }}
                      />
                    ) : (
                      <OrangeButton
                        animation={false}
                        enable={false}
                        type="submit"
                        text={"Envoyer"}
                        onClick={() => {
                          return null;
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom="50px"
          >
            <CircularProgress />
          </Grid>
        )}
      </FormPopup>
    </>
  );
};

export default SendResponseMedimailPopup;
