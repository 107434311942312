import Select from "react-select";
import Creatable from "react-select/creatable"
import * as React from 'react';
import StyleFieldError from '../../../configs/StyleFieldError.json';
import addingErrorStyleToField from "../../../utils/addingErrorStyleToField";

interface IOption {
  label?: string | number,
  value?: string | number,
}

interface IMySelect {
  width?: string;
  styles?: any;
  placeholder?: string,
  isDisabled?: boolean,
  value?: IOption,
  options?: IOption[],
  components?: any,
  onChange: any,
  onBlur?: any,
  onFocus?: any,
  id?: string,
  isError?: boolean,
  className?: string,
  isClearable?: boolean,
  border?: string,
  noOptionsMessage?: any,
  isOptionDisabled?: any,
  maxMenuHeight?: any,
  name?: string,
}

export const MySelectMulti = (props: IMySelect) => {
  const style = addingErrorStyleToField({
    width: props.width,
    borderRadius: '10px',
    border: '1px solid transparent'
  }, props?.isError);

  return (
    <div className={props?.className} id={props?.id}
      style={style}>
      <div style={{ width: props.width, alignSelf: 'stretch' }}>
        <Creatable
          maxMenuHeight={props?.maxMenuHeight}
          formatCreateLabel={(userInput) => `...`}
          onFocus={props?.onFocus}
          isMulti={true}
          onBlur={props?.onBlur}
          name={props?.name}
          noOptionsMessage={props?.noOptionsMessage}
          components={props?.components}
          placeholder={props?.placeholder}
          isClearable={props?.isClearable}
          value={props?.value}
          options={props?.options}
          isOptionDisabled={props?.isOptionDisabled}
          onChange={(e) => props?.onChange(e)} />
      </div>
    </div>
  );
};

MySelectMulti.defaultProps = {
  isClearable: true,
  noOptionsMessage: () => "Aucune option n'a été trouvée...",
  width: '100%',
}

const MySelect = (props: IMySelect) => {
  const style = addingErrorStyleToField({
    width: props.width,
    borderRadius: '10px',
    border: '1px solid transparent',
    cursor: props?.isDisabled ? 'not-allowed' : 'pointer',
  }, props?.isError);

  return (
    <div className={props?.className} id={props?.id}
      style={style}>
      <div style={{ width: props.width, alignSelf: 'stretch' }}>
        <Select
          styles={props?.styles}
          maxMenuHeight={props?.maxMenuHeight}
          onFocus={props?.onFocus}
          onBlur={props?.onBlur}
          name={props?.name}
          noOptionsMessage={props?.noOptionsMessage}
          components={props?.components}
          placeholder={props?.placeholder}
          isClearable={props?.isClearable}
          value={props?.value}
          options={props?.options}
          isOptionDisabled={props?.isOptionDisabled}
          onChange={(e) => props?.onChange(e)}
          isDisabled={props?.isDisabled} />
      </div>
    </div>
  );
};

MySelect.defaultProps = {
  isClearable: true,
  noOptionsMessage: () => "Aucune option n'a été trouvée...",
  width: '100%',
}

export default MySelect;