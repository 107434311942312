import * as React from "react";
import { Grid, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useParams } from "react-router";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";
import { fixURLhttps } from "../components/MesConseilsBox/Card/PackAdviceContent";
import { IConseilQRCode } from "./ConseilQRCodePage";
import { GenericDateDeclenchement, GenericSendButton } from "./ChatbotLexPage";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import isValidPhoneNumber from "../utils/isValidPhoneNumber";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { transformUrlToAbsolute } from "./LinksPage";

interface ConseilBase {
  id: number;
  validated: 0 | 1;
  user_id: number;
  title: string;
  speciality_id: number;
  conseil_profession_id: number;
  type: "pack" | "simple";
  conseil_favourite_ids: string;
  conseil_use: string;
  conseil_popularity: string;
  reserved: 0 | 1;
  display_name: 0 | 1;
  views: string;
  creation_date: Date;
  is_archived: 0 | 1;
}

interface ConseilResponse extends ConseilBase {
  user_name: string;
  messages: string;
}

interface MessageConseil {
  id: number;
  content: string;
  attachment: string;
  attachment_name: string;
  url: string;
  hours: number;
  questionnaire_interactif_title?: string;
  questionnaire_interactif_id?: string;
  short_url?: 0 | 1;
  stop_sms?: 0 | 1;
  questionnaire_interactif_nano_id?: string;
  is_questionnaire_interactif_owner?: boolean;
}

export interface ConseilReponseAfterParsing extends ConseilBase {
  user_name: string;
  messages: MessageConseil[][];
  fullText?: string;
  score?: number;
}

export const SMSRecapitulatif: React.FC<{
  conseilData: ConseilReponseAfterParsing;
  setConseilData: React.Dispatch<
    React.SetStateAction<ConseilReponseAfterParsing>
  >;
  isLoading: boolean;
}> = (props) => {
  const [hideConseil, setHideConseil] = React.useState<boolean>(false);

  return (
    <>
      <Grid
        item
        display="grid"
        style={{
          paddingRight: "15px",
          paddingBottom: "10px",
        }}
      >
        <Grid container direction="column" spacing="5px" marginTop="10px" wrap="nowrap">
          <Grid item display="grid">
            <div
              style={{
                borderTop: "2px solid #E6EBF0",
                borderRadius: "20px",
              }}
            />
          </Grid>
          <Grid item display="grid" alignSelf="flex-end">
            <Grid
              container
              direction="row"
              wrap="nowrap"
              alignItems={"center"}
              justifyContent={"center"}
              gap="5px"
            >
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "#657273",
                    textAlign: "end",
                  }}
                >
                  Récapitulatif des SMS
                </span>
              </Grid>
              <Grid item display="grid">
                <Tooltip title={hideConseil ? `Afficher` : "Cacher"}>
                  <IconButton
                    style={{
                      margin: "0",
                      padding: "0",
                    }}
                    onClick={() => {
                      setHideConseil((x) => !x);
                    }}
                  >
                    {hideConseil ? (
                      <ExpandLess style={{ color: "#657273" }} />
                    ) : (
                      <ExpandMore style={{ color: "#657273" }} />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!hideConseil && (
        <Grid
          item
          display="grid"
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="5px"
          paddingBottom="5px"
        >
          <ConseilBox
            noPadding={true}
            conseilData={props.conseilData}
            isLoading={props.isLoading}
            setConseilData={props.setConseilData}
          />
        </Grid>
      )}
    </>
  );
};

const UnknownConseil = () => {
  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      Ce conseil n'existe pas ou n'existe plus.
    </span>
  );
};

export const ConseilBox = (props: {
  isLoading: boolean;
  conseilData: ConseilReponseAfterParsing;
  setConseilData: React.Dispatch<
    React.SetStateAction<ConseilReponseAfterParsing>
  >;
  noPadding?: boolean;
}) => {
  const formatHours = (hours: number) => {
    let isNegative = false;
    if (hours < 0) isNegative = true;
    let hour = isNegative ? hours * -1 : hours;
    let day = 0;
    let month = 0;
    let year = 0;
    let format = "";
    const tmp = [];

    while (hour >= 24) {
      hour -= 24;
      day += 1;
    }
    while (day >= 30) {
      day -= 30;
      month += 1;
    }
    while (month >= 12) {
      month -= 12;
      year += 1;
    }
    if (year != 0) tmp.push(`${year} an${year > 1 ? "s" : ""}`);
    if (month != 0) tmp.push(`${month} mois`);
    if (day != 0) tmp.push(`${day} jour${day > 1 ? "s" : ""}`);
    if (hour != 0) tmp.push(`${hour} heure${hour > 1 ? "s" : ""}`);

    if (tmp.length === 1)
      format = `${tmp[0]} ${isNegative ? "avant" : "après"}`;
    if (tmp.length === 2)
      format = `${tmp[0]} et ${tmp[1]} ${isNegative ? "avant" : "après"}`;
    if (tmp.length === 3)
      format = `${tmp[0]}, ${tmp[1]} et ${tmp[2]} ${
        isNegative ? "avant" : "après"
      }`;
    if (tmp.length === 4)
      format = `${tmp[0]}, ${tmp[1]}, ${tmp[2]} et ${tmp[3]} ${
        isNegative ? "avant" : "après"
      }`;

    if (hours === 0) return "Le jour J";
    return format;
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      paddingLeft={props.noPadding ? 0 : "5%"}
      paddingTop={props.noPadding ? 0 : "5%"}
      paddingRight={props.noPadding ? 0 : "5%"}
      paddingBottom={props.noPadding ? 0 : "5%"}
    >
      {props.isLoading && (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!props.isLoading && (
        <Grid item display="grid">
          {props.conseilData && props.conseilData?.messages?.[0]?.length > 0 ? (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              justifyContent={"center"}
              gap="30px"
            >
              <Grid item display="grid">
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    fontFamily: "Poppins",
                    color: "#0B243C",
                    fontWeight: "bold",
                  }}
                >
                  {props.conseilData?.title}
                </span>
              </Grid>
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="20px">
                  {props.conseilData.messages[0]
                    .sort((a, b) => a.hours - b.hours)
                    .map((message, index) => {
                      return (
                        <Grid item display="grid" key={message.id}>
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="5px"
                          >
                            {message.content && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                  }}
                                >
                                  {message.content}
                                </span>
                              </Grid>
                            )}
                            {message.url && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: 12,
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    wordBreak: "break-all"
                                  }}
                                >
                                  Lien web :{" "}
                                  <a
                                    href={transformUrlToAbsolute(message.url)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {message.url}
                                  </a>
                                </span>
                              </Grid>
                            )}
                            {message?.questionnaire_interactif_id &&
                              message?.questionnaire_interactif_title && (
                                <Grid item display="grid">
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontSize: 12,
                                      fontFamily: "Poppins",
                                      color: "#0B243C",
                                    }}
                                  >
                                    Questionnaire interactif :{" "}
                                    <a
                                      href={message.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {message.questionnaire_interactif_title}
                                    </a>
                                  </span>
                                </Grid>
                              )}
                            {message.attachment && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: 12,
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                  }}
                                >
                                  Piece jointe :{" "}
                                  <a
                                    href={fixURLhttps(message.attachment)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {message.attachment_name == ""
                                      ? message.attachment.split("/")[
                                          message.attachment.split("/").length -
                                            1
                                        ]
                                      : message.attachment_name}
                                  </a>
                                </span>
                              </Grid>
                            )}
                            {props.conseilData?.type === "pack" && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    textAlign: "end",
                                    fontSize: 12,
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                  }}
                                >
                                  {formatHours(message.hours)}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <UnknownConseil />
          )}
        </Grid>
      )}
    </Grid>
  );
};

interface IParams {
  id: string;
}

const PatientQRCodePage: React.FC = () => {
  const { id } = useParams<IParams>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [successSchedule, setSuccessSchedule] = React.useState(false);
  const [sendIsLoading, setSendIsLoading] = React.useState<boolean>(false);
  const [qrCode, setQrCode] = React.useState<IConseilQRCode>(undefined);
  const snackbar = useOpenSnackbar();
  let date = new Date();
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  const [patientInput, setPatientInput] = React.useState({
    phone: "",
  });
  const [dateInput, setDateInput] = React.useState(
    date.toISOString().split("T")[0]
  );
  const [timeInput, setTimeInput] = React.useState(
    date.toISOString().split("T")[1].substring(0, 5)
  );
  const [conseilData, setConseilData] =
    React.useState<ConseilReponseAfterParsing>(undefined);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", `/conseil_qrcode/${id}`, null, "v3")
      .then((res) => {
        setQrCode(res?.data);
        setConseilData(res?.data?.conseil);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="90vw"
          direction="column"
          maxWidth="700px"
          paddingTop="50px"
          paddingBottom="50px"
        >
          {isLoading ? (
            <Grid item display="grid" alignSelf={"center"} paddingTop="25px">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {qrCode?.alreadyUsed ? (
                <>
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        color: "#0B243C",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {conseilData?.title ? conseilData?.title : ""}
                    </span>
                  </Grid>
                  <Grid item display="grid" marginTop="20px">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        color: "#0B243C",
                        textAlign: "center",
                      }}
                    >
                      Vos SMS ont déjà été programmés.
                    </span>
                  </Grid>
                  <Grid item display="grid" marginTop="20px">
                    <SMSRecapitulatif
                      conseilData={conseilData}
                      isLoading={isLoading}
                      setConseilData={setConseilData}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {conseilData && conseilData?.messages?.[0]?.length != 0 ? (
                    <>
                      {successSchedule && (
                        <>
                          <Grid item display="grid">
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                color: "#0B243C",
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {conseilData?.title}
                            </span>
                          </Grid>
                          <Grid
                            item
                            display="grid"
                            marginBottom="10px"
                            marginTop="20px"
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                color: "#0B243C",
                                textAlign: "center",
                              }}
                            >
                              Vos SMS ont été bien été programmés, vous pouvez
                              quitter la page.
                            </span>
                          </Grid>
                        </>
                      )}
                      {!successSchedule && (
                        <>
                          <Grid item display="grid">
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                color: "#0B243C",
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {conseilData?.title}
                            </span>
                          </Grid>
                          <Grid item display="grid" marginTop="20px">
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                color: "#0B243C",
                                textAlign: "center",
                              }}
                            >
                              Pour recevoir vos SMS, veuillez renseigner la date
                              d'admission ainsi que votre numéro de téléphone.
                            </span>
                          </Grid>
                          <Grid item display="grid" marginTop="20px">
                            <GenericTextInput
                              fieldTitle="Numéro de téléphone"
                              isMandatory={true}
                              id="qrcode-input-tel"
                              value={patientInput.phone}
                              onChange={(e) => {
                                setPatientInput((x) => {
                                  return { ...x, phone: e.target.value };
                                });
                              }}
                            />
                          </Grid>
                          <Grid item display="grid" marginTop="10px">
                            <GenericDateDeclenchement
                              dateInput={dateInput}
                              setDateInput={setDateInput}
                              fieldTitle="Date d'admission"
                              setTimeInput={setTimeInput}
                              timeInput={timeInput}
                              idDate={"qrcode-date"}
                              idTime={"qrcode-time"}
                            />
                          </Grid>
                          <Grid item display="grid">
                            <GenericSendButton
                              errorMessages={{
                                emptyPhone: "Le numéro n'est pas renseigné",
                                invalidPhone: "Le numéro est invalide",
                                invalidDate: "La date est invalide",
                                invalidTime: "L'heure est invalide",
                              }}
                              isLoading={sendIsLoading}
                              errorSelector={{
                                emptyPhone: "#qrcode-input-tel",
                                invalidPhone: "#qrcode-input-tel",
                                invalidDate: "#qrcode-date",
                                invalidTime: "#qrcode-time",
                              }}
                              invalidConditions={{
                                emptyPhone: patientInput.phone?.length == 0,
                                inavlidPhone: !isValidPhoneNumber(
                                  patientInput.phone
                                ),
                                invalidDate: !dateInput,
                                invalidTime: !timeInput,
                              }}
                              onSendClick={() => {
                                setSendIsLoading(true);
                                const timestamp = Date.parse(
                                  dateInput + "T" + timeInput + ":00"
                                );

                                makeAPIRequest(
                                  "post",
                                  `/conseil_qrcode/${qrCode?._id}/patient_send`,
                                  {
                                    timestamp: timestamp,
                                    phone: patientInput.phone,
                                  },
                                  "v3"
                                )
                                  .then((res) => {
                                    snackbar.success(
                                      "Vos SMS ont bien été programmés.",
                                      res
                                    );
                                    setSuccessSchedule(true);
                                  })
                                  .catch((err) => {
                                    snackbar.error(
                                      "Une erreur est survenue lors de la programmation de vos SMS.",
                                      err
                                    );
                                  })
                                  .finally(() => {
                                    setSendIsLoading(false);
                                  });
                              }}
                              textButton="Valider"
                            />
                          </Grid>
                        </>
                      )}
                      <SMSRecapitulatif
                        conseilData={conseilData}
                        isLoading={isLoading}
                        setConseilData={setConseilData}
                      />
                    </>
                  ) : (
                    <Grid item display="grid">
                      <UnknownConseil />
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default PatientQRCodePage;
