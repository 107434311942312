import { getFieldTitleSpecial } from "./EditQuestionnaire/Fields/DisplayFieldRows";

const QuestionnaireUtils = {
  getFieldListOptions: (questionnaireData) => {
    const groups = [];
    for (const section of questionnaireData?.sections) {
      groups.push({
        label: section?.title ? section?.title : 'Sans titre',
        options: [],
      });
    }
    for (const section of questionnaireData?.sections) {
      for (const fieldRow of section?.fields) {
        for (const field of fieldRow) {
          if (!['display_text_and_image', 'medecin_delegant', 'structure'].includes(field?.type)) {
            const index = groups.findIndex((g) => {
              if (g?.label === 'Sans titre') {
                return (!section?.title);
              } else {
                return (section?.title === g?.label);
              }
            });
            if (index !== -1) {
              groups[index].options.push({
                label: getFieldTitleSpecial(field),
                value: field?.id,
              })
            }
          }
        }
      }
    }
    return (groups);
  },
  getOptionFromValue: (value, fieldOptions) => {
    console.log(fieldOptions)
    console.log(fieldOptions?.options)
    if (fieldOptions?.options && fieldOptions?.options?.length > 0) {
      const founded = fieldOptions.options.find((x) => x?.value === value);
      console.log(founded);
      if (founded) { return (founded) }
    }
    return (fieldOptions.find((element) => element?.id === value));
  },
  getFieldSelectedInfos: (questionnaireData, fieldSelected) => {
    for (const section of questionnaireData?.sections) {
      for (const fieldRow of section?.fields) {
        for (const field of fieldRow) {
          if (field?.id === fieldSelected) {
            return (field);
          }
        }
      }
    }
    return (null);
  },
};

export default QuestionnaireUtils;
