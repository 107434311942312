import { createSlice } from '@reduxjs/toolkit';

const getPatientSelected = () => {
  const patients = localStorage.getItem('PatientSelected');

  if (patients) {
    try {
      localStorage.removeItem('PatientSelected');
    } catch {
      console.log('Cannot delete PatientSelected');
    }
  }
  return ([]);
}

export const selectPatientSlice = createSlice({
  name: 'selectPatient',
  initialState: {
    phoneSelected: [],
    patientSelected: getPatientSelected(),
  },
  reducers: {
    refresh: (state) => {
      state.phoneSelected = [];
      state.patientSelected = getPatientSelected();
    },
    addPhone: (state, action) => {
      const phone = action.payload;

      if (!state.phoneSelected.find((element) => element.phone === phone)) {
        state.phoneSelected.push({
          phone: phone,
          isOpen: false,
        });
      }
    },
    deletePhone: (state, action) => {
      const phone = action.payload;

      const index = state.phoneSelected.findIndex((element) => element.phone === phone);

      if (index !== -1) {
        delete state.phoneSelected[index];
        state.phoneSelected = state.phoneSelected.filter(element => element != undefined);
      }
    },
    openPhone: (state, action) => {
      const phone = action.payload;
      const index = state.phoneSelected.findIndex((element) => element.phone === phone);

      state.phoneSelected[index].isOpen = true;
    },
    closePhones: (state) => {
      for (let i = 0; i < state.phoneSelected.length; i++) {
        if (state.phoneSelected[i].isOpen === true) {
          state.phoneSelected[i].isOpen = false;
        }
      }
    },
    removeAllPatients: (state) => {
      state.patientSelected = [];
      localStorage.removeItem('PatientSelected');
    },
    deletePhoneOpen: (state) => {
      if (state.phoneSelected.length === 0) return;
      for (let i = 0; i < state.phoneSelected.length; i++) {
        if (state.phoneSelected[i].isOpen === true) {
          delete state.phoneSelected[i];
        }
      }
      const tmp = state.phoneSelected.filter(element => element != undefined);
      state.phoneSelected = tmp;
    },
    onlyAddPatient: (state, action) => {
      const patientToAdd = action.payload;

      if (state.patientSelected.includes(patientToAdd?.id)) {
        return;
      } else {
        state.patientSelected.push(patientToAdd?.id);
      }
      const onlyIds = [];
      try {
        for (const patient of state.patientSelected) {
          if (patient) onlyIds.push(patient);
        }
        const result = JSON.stringify(onlyIds);
        localStorage.setItem('PatientSelected', result);
      } catch {
        console.error('Cannot stringify patient selected !')
      }
    },
    onlyRemovePatient: (state, action) => {
      const patientToRemove = action.payload;
      if (state.patientSelected.includes(patientToRemove?.id)) {
        const index = state.patientSelected.findIndex((element) => element === patientToRemove?.id)

        delete state.patientSelected[index];
        const result = state.patientSelected.filter((element) => element != undefined);
        state.patientSelected = result;
      } else {
        return;
      }
      const onlyIds = [];
      try {
        for (const patient of state.patientSelected) {
          if (patient) onlyIds.push(patient);
        }
        const result = JSON.stringify(onlyIds);
        localStorage.setItem('PatientSelected', result);
      } catch {
        console.error('Cannot stringify patient selected !')
      }
    },
    addPatient: (state, action) => {
      const patientToAdd = action.payload;

      if (state.patientSelected.includes(patientToAdd?.id)) {
        const index = state.patientSelected.findIndex((element) => element === patientToAdd?.id)

        delete state.patientSelected[index];
        const result = state.patientSelected.filter((element) => element != undefined);
        state.patientSelected = result;
      } else {
        state.patientSelected.push(patientToAdd?.id);
      }
      const onlyIds = [];
      try {
        for (const patient of state.patientSelected) {
          if (patient) onlyIds.push(patient);
        }
        const result = JSON.stringify(onlyIds);
        localStorage.setItem('PatientSelected', result);
      } catch {
        console.error('Cannot stringify patient selected !')
      }
    },
  }
})

export const {
  addPatient,
  refresh,
  onlyAddPatient,
  onlyRemovePatient,
  addPhone,
  openPhone,
  deletePhoneOpen,
  closePhones,
  deletePhone,
  removeAllPatients,
} = selectPatientSlice.actions

export default selectPatientSlice.reducer