import StyleFieldError from '../configs/StyleFieldError.json';

const addingErrorStyleToField = (style: any, isError: boolean) => {
  const styleCopy = { ...style };

  if (isError) {
    for (const key of Object.keys(StyleFieldError)) {
      styleCopy[key] = StyleFieldError[key];
    }
  }
  return (styleCopy);
}

export default addingErrorStyleToField;