import { createSlice } from '@reduxjs/toolkit';

export const authorizationsSlice = createSlice({
  name: 'authorizations',
  initialState: {
    forms: '',
    chatbots: '',
    chatbots_lex: '',
    protocoles: '',
  },
  reducers: {
    setFormsAccess: (state, action) => {
      state.forms = action.payload;
    },
    setChatbotsAccess: (state, action) => {
      state.chatbots = action.payload;
    },
    setChatbotsLexAccess: (state, action) => {
      state.chatbots_lex = action.payload;
    },
    setProtocolesAccess: (state, action) => {
      state.protocoles = action.payload;
    },
  }
})

export const { setFormsAccess, setChatbotsAccess, setProtocolesAccess, setChatbotsLexAccess } = authorizationsSlice.actions

export default authorizationsSlice.reducer;
