import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./modal.css";
import TutorialDataService from "../services/TutorialService";
import EventEmitter from "../services/event";
import Select from "react-select";
import spec from "../helpers/specialities";
import optionsSpec from "../helpers/specialities";
import "../styles/EditConseil.scss";
import swal from "sweetalert";
import { Grid } from "@mui/material";
import OrangeButton from "./Library/Buttons/OrangeButton/OrangeButton";
import CancellablePopup from "./Library/Popups/CancellablePopup";
import AttachFileContainer from "./MesConseilsBox/CreateAdvice/AttachFileContainer";
import InformationPopup from "./Library/Popups/InformationPopup";

const validationSchema = () => {
  return Yup.object().shape({
    type: Yup.string().required("Ce champ est requis."),
    title: Yup.string().required("Ce champ est requis."),
    speciality: Yup.string().required("Ce champ est requis."),
    attachment: Yup.string().required("Ce champ est requis."),
  });
};

const EditConseilModal = ({ isShowing, hide, id, dataConseil, filterKey, favoriteList, filterKeySearch, filterKeyFavourite, googleAnalytics }: any) => {
  const [arrayData, setArrayData] = useState<any>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [files, setFiles] = useState<any>();
  const [count, setCount] = useState([1]);
  const [, fctMiseAJour] = React.useState({});
  const miseAJour = React.useCallback(() => fctMiseAJour({}), []);
  const [hoursToSend, setHoursToSend] = useState({});
  const [textLenght, setTextLength] = useState<any>({ taille: 0 });
  const [proffessionsBase, setproffessionsBase] = useState<any>();
  const [fichier, setFichier] = useState({});
  const [isPopupExit, setIsPopupExit] = useState(false);
  const [errorContent, setErrorContent] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  let length = 0;

  useEffect(() => {
    const onEdit = (eventData: any) => {
      setErrorMessage('');
      setIsPopupExit(false);
      setSubmitted(false);
      setIsPack(false);
      setErrorContent(false);
      setError(false);
      setSubmitted(false);
      newTutorial();
      miseAJour();
      setCount([0]);

      const proArray = spec.filter(
        (n) => n.value == eventData.value.speciality_id
      );

      setArrayData(eventData.value);
      setTutorial(eventData.value);

      if (eventData.value.conseil_favourite_ids != "[]") setIsChecked(true)
      else setIsChecked(false)

      if (eventData.value.type == "simple") setIsPack(false);
      else setIsPack(true);

      try {
        setproffessionsBase(JSON.stringify(proArray[0].label).slice(1, -1));
      } catch (error) { }
      try {
        let arrayToIncrease = [];
        length = eventData.value.messages[0].length;
        for (let index = 0; index < length; index++) {
          arrayToIncrease = [...arrayToIncrease, index];
        }
        setCount(arrayToIncrease);
      } catch (err) {
        console.log(err);
      }
    };


    const onAttachmentUpdate = () => {
      hide();
    }

    const listenerAttachmentUpdate = EventEmitter.addListener("attachmentUpdate", onAttachmentUpdate);
    const listenerEdit = EventEmitter.addListener("edit", onEdit);

    return () => {
      listenerAttachmentUpdate.remove();
      listenerEdit.remove();
    };
  }, []);

  const [isPack, setIsPack] = useState(
    arrayData.type == "simple" ? false : true
  );

  const initialTutorialState = {
    id: null,
    type: arrayData.type == "simple" ? "true" : "false",
    title: arrayData.title,
    speciality: arrayData.speciality_id,
    messages: arrayData.messages == null ? [] : arrayData.messages,
    add_my_favourites: arrayData.conseil_favourite_ids != "[]" ? true : false,
  };

  const [tutorial, setTutorial] = useState<any>({ ...initialTutorialState });
  const options = [];

  optionsSpec.map((n) => {
    options.push({
      value: n.value,
      label: n.label,
    });
  });

  optionsSpec.sort();

  const isValidSubmit = () => {
    let index = 0;
    // Pack and simple advice
    const invalidConditions = {
      "Le titre n'est pas renseigné.": !tutorial?.title || tutorial?.title?.length === 0,
      "La spécialité n'est pas renseignée.": !tutorial?.speciality_id || tutorial?.speciality_id?.length === 0,
    };
    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (condition != errorMessage) setErrorMessage(condition);
        return (false);
      }
    }

    // Pack
    if (isPack) {
      if (!tutorial?.messages?.[0] || tutorial?.messages?.[0]?.length === 0) return (false);
      for (const advice of tutorial?.messages?.[0]) {
        const invalidConditions = {
          "la période d'envoi n'est pas renseignée.": advice?.hours === null || advice?.hours === undefined,
          "le contenu du message n'est pas renseigné.": !advice?.content || advice?.content === '',
        };
        for (const condition of Object.keys(invalidConditions)) {
          if (invalidConditions[condition]) {
            if (`Conseil n°${index + 1} : ` + condition != errorMessage) {
              setErrorMessage(`Conseil n°${index + 1} : ` + condition);
            }
            return (false);
          }
        }
        index++;
      }
    } else {
      // Simple advice
      const invalidConditions = {
        "Le contenu du message n'est pas renseigné.": !tutorial?.messages?.[0]?.[0]?.content || tutorial?.messages?.[0]?.[0]?.content === '',
      };
      for (const condition of Object.keys(invalidConditions)) {
        if (invalidConditions[condition]) {
          if (condition != errorMessage) setErrorMessage(condition);
          return (false);
        }
      }
    }
    return (true);
  };

  const confirmExit = () => {
    EventEmitter.emit('RefreshAll', { filterKey: filterKey });
    if (isShowing) {
      hide();
    }
    setSubmitted(false);
    setIsPopupExit(false);
  }

  const cancelExit = () => {
    setIsPopupExit(false);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let messageIndex;

    if (name == "input_content") {
      setTextLength({ ...textLenght, taille: value.length });
      if (value.length != 0) setErrorContent(false);
    }
    if (
      isPack &&
      name != "title" &&
      name != "add_my_favourites" &&
      name != "speciality" &&
      name != "type" &&
      name != "file"
    ) {
      const nameIndex = name.substring(0, name.length - 1);
      const temp = tutorial;

      if (name.includes("content")) {
        messageIndex = name.slice(7);
        setTextLength({ ...textLenght, [messageIndex]: value.length });
      }
      if (name.includes("url")) messageIndex = name.slice(3);
      if (name.includes("dateheures")) {
        messageIndex = name.slice(10);
        const array = hoursToSend[messageIndex];
        setHoursToSend({
          ...hoursToSend,
          heure: { ...array, heure: value * 1 },
        });
        if (tutorial?.messages?.[0]?.[messageIndex])
          tutorial.messages[0][messageIndex].hours = value * 1;
      }
      if (name.includes("datejours")) {
        messageIndex = name.slice(9);
        const array = hoursToSend[messageIndex];
        setHoursToSend({
          ...hoursToSend,
          heure: { ...array, jours: value * 24 },
        });
        if (tutorial?.messages?.[0]?.[messageIndex])
          tutorial.messages[0][messageIndex].hours = value * 24;
      }
      if (name.includes("datemois")) {
        messageIndex = name.slice(8);
        const array = hoursToSend[messageIndex];
        setHoursToSend({
          ...hoursToSend,
          heure: { ...array, mois: value * 24 * 30.5 },
        });
        if (tutorial?.messages?.[0]?.[messageIndex])
          tutorial.messages[0][messageIndex].hours = value * 24 * 30.5;
      }
      if (name.includes("datean")) {
        messageIndex = name.slice(6);
        const array = hoursToSend[messageIndex];
        setHoursToSend({
          ...hoursToSend,
          heure: { ...array, an: value * 24 * 30.5 * 365 },
        });
        if (tutorial?.messages?.[0]?.[messageIndex])
          tutorial.messages[0][messageIndex].hours = value * 24 * 30.5 * 365;
      }
      if (!name.includes("date"))
        temp.messages[0][messageIndex] = {
          ...temp.messages[0][messageIndex],
          [nameIndex]: value,
        };
      setTutorial(temp);
    }

    if (name.includes("input_")) {
      const index = name.slice(6);
      const temp = tutorial;
      temp.messages[0][0] = {
        ...temp.messages[0][0],
        [index]: value,
      };
      setTutorial(temp);
    }
    if (event.target.defaultValue == "two") {
      setIsPack(!isPack);
      setCount([1]);
      setTutorial({ ...tutorial, type: "pack" });
    }
    if (event.target.defaultValue == "one") {
      setIsPack(false);
      setTutorial({ ...tutorial, type: "simple" });
    }
    if (name == "add_my_favourites") {
    }

    else setTutorial({ ...tutorial, [name]: value });
  };

  const handleSpec = (e) => {
    const specArray = [];
    specArray[0] = e.value;
    setTutorial({ ...tutorial, speciality_id: specArray[0] });
  };

  const newTutorial = () => {
    setTutorial(initialTutorialState);
    setSubmitted(false);
  };

  const addConseil = () => {
    const tutorialTmp = { ...tutorial };
    const tutorialMessagesTmp = [...tutorial?.messages?.[0]];

    tutorialMessagesTmp.push({
      hours: null,
      content: null,
    })
    if (tutorialTmp?.messages?.[0]) tutorialTmp.messages[0] = tutorialMessagesTmp;
    setTutorial(tutorialTmp);
  };

  const deleteAdvice = (index: number) => {
    if (tutorial?.messages?.[0]?.length <= 1) return;
    const countTmp = [...count];
    const tutorialTmp = { ...tutorial };
    const tutorialMessagesTmp = [...tutorial?.messages?.[0]];

    countTmp.splice(index, 1);

    tutorialMessagesTmp.splice(index, 1);
    if (tutorialTmp?.messages?.[0]) tutorialTmp.messages[0] = [...tutorialMessagesTmp];

    setCount(countTmp);
    setTutorial(tutorialTmp);
  }

  const toggleSelect = (e) => {
    if (e.target.value == "") {
      miseAJour();
    } else {
      setError(false);
    }
  };

  const handleDrop = (file, i) => {
    const temp = tutorial;
    const fileReader = new FileReader();

    if (file[0] != null) {
      if (file[0].size >= 400000000) {
        swal({
          text: "Le fichier est trop lourd",
          icon: "warning",
        });
      } else if (
        file[0].type != "application/pdf" &&
        file[0].type != "image/png" &&
        file[0].type != "image/jpg" &&
        file[0].type != "image/jpeg" &&
        file[0].type != "video/mp3" &&
        file[0].type != "video/mp4" &&
        file[0].type != "video/webm"
      ) {
        swal({
          text: "Le fichier est au mauvais format",
          icon: "warning",
        });
      } else {
        fileReader.readAsDataURL(file[0]);
        fileReader.onload = (event) => {
          if (!isPack) {
            temp.messages[0][0].attachment = event.target.result;
            temp.messages[0][0].attachment_name = file[0].name;
          } else {
            temp.messages[0][i] = {
              ...temp.messages[0][i],
              attachment: event.target.result,
            };
            temp.messages[0][i] = {
              ...temp.messages[0][i],
              attachment_name: file[0].name,
            };
          }
        };
        setTutorial(temp);
        setFiles(file[0]);
        setFichier({ ...fichier, [i]: file[0].name });
      }
    }
    swal({
      text: `Vous avez ajouté ${file[0].name}`,
      icon: "success",
    });
  };

  const saveTutorial = () => {
    let messageToSend = [];
    const temp = tutorial;
    let data;

    if (isPack) {
      for (let index = 0; index < tutorial?.messages?.[0]?.length; index++) {
        let messages;
        if (
          tutorial?.messages?.[0]?.[index]?.attachment?.includes(
            "data:"
          )
        ) {
          messageToSend = [...messageToSend, tutorial?.messages?.[0]?.[index]];
        } else {
          messages = {
            id: tutorial?.messages?.[0]?.[index]?.id,
            hours: tutorial?.messages?.[0]?.[index]?.hours,
            url: tutorial?.messages?.[0]?.[index]?.url,
            content: tutorial?.messages?.[0]?.[index]?.content,
          };
          messageToSend = [...messageToSend, messages];
        }
      }
      for (let i = 0; i < tutorial?.messages?.[0]?.length; i++) {
        try {
          Object.values(hoursToSend[i]).forEach((n: number) => {
            temp.messages[0][i] = {
              ...temp.messages[0][i],
              hours: n,
            };
          });
        } catch (err) { }
        setTutorial(temp);
      }
      data = {
        id: tutorial.id,
        type: "pack",
        title: tutorial.title,
        speciality_id: tutorial.speciality_id,
        messages: messageToSend,
        add_my_favourites: false,
      };
    } else {
      data = {
        id: tutorial?.id,
        type: "simple",
        title: tutorial?.title,
        speciality_id: tutorial?.speciality_id,
        messages: tutorial?.messages?.[0]?.slice(0, 1),
        add_my_favourites: false,
      };
    }
    if (isPack) {
      let thisError = 0;
      for (let i = 0; i < tutorial?.messages?.[0]?.length; i++) {
        if (
          data.messages[i].content != "" &&
          data.messages[i].hours != undefined &&
          data.title != "" &&
          data.speciality_id != ""
        ) {
          thisError = thisError + 1;
        }
      }
      if (thisError == data.messages.length) {
        TutorialDataService.updateConseil(data, tutorial.id)
          .then((response: any) => {
            googleAnalytics?.editAdviceEvent(tutorial?.type, tutorial?.title);
            const found = favoriteList?.find(element => element.id === tutorial.id);
            if (isChecked && !found || !isChecked && found) {
              EventEmitter.emit("Favorite", {
                id: tutorial.id,
                filterKeySearch: filterKeySearch,
                filterKey: filterKey,
                filterKeyFavourite: filterKeyFavourite,
              });
            }
            setSubmitted(true);
            if (isShowing) {
              hide();
            }
            EventEmitter.emit("Created", {
              filterKeyFavourite: filterKeyFavourite,
              filterKeySearch: filterKeySearch,
              filterKey: filterKey,
              text: tutorial,
              isUpdate: true,
            });
          })
          .catch((e: any) => {
            console.log(e);
          });
      }
    } else {
      if (
        tutorial?.messages?.[0]?.[0]?.content == undefined ||
        tutorial.title == "" ||
        tutorial.speciality_id == ""
      ) {
        miseAJour();
      }

      if (
        tutorial?.messages?.[0]?.[0]?.content != "" &&
        tutorial.title != "" &&
        tutorial.speciality_id != "" &&
        tutorial?.messages?.[0]?.[0]?.content != undefined &&
        tutorial.title != undefined &&
        tutorial.speciality_id != undefined &&
        isPack == false
      ) {
        setError(false);
        TutorialDataService.updateConseil(data, tutorial.id)
          .then((response: any) => {
            googleAnalytics?.editAdviceEvent(tutorial?.type, tutorial?.title);
            const found = favoriteList?.find(element => element.id === tutorial.id);
            if (isChecked && !found || !isChecked && found) {
              EventEmitter.emit("Favorite", {
                id: tutorial.id,
                filterKeySearch: filterKeySearch,
                filterKey: filterKey,
                filterKeyFavourite: filterKeyFavourite,
              });
            }
            if (isShowing) {
              hide();
            }
            setSubmitted(true);
            EventEmitter.emit("Created", {
              filterKeyFavourite: filterKeyFavourite,
              filterKeySearch: filterKeySearch,
              filterKey: filterKey,
              text: tutorial,
              isUpdate: true,
            });
          })
          .catch((e: any) => {
            console.log(e);
          });
      }
    }
  };

  const handleFile = (e, i) => {
    const temp = tutorial
    const fileReader = new FileReader();

    if (e.target.files[0] != null) {
      if (e.target.files[0].size >= 400000000) {
        swal({
          text: "Le fichier est trop lourd",
          icon: "warning",
          dangerMode: true,
        });
      } else if (e.target.files[0].type != "application/pdf"
        && e.target.files[0].type != "image/png"
        && e.target.files[0].type != "image/jpg"
        && e.target.files[0].type != "image/jpeg"
        && e.target.files[0].type != "video/mp3"
        && e.target.files[0].type != "video/mp4"
        && e.target.files[0].type != "video/webm") {
        swal({
          text: "Le fichier est au mauvais format",
          icon: "warning"
        });
      } else {
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = (event) => {
          if (!isPack) {
            temp.messages[0][0].attachment = event.target.result;
            temp.messages[0][0].attachment_name = e.target.files[0].name;
          } else {
            temp.messages[0][i] = {
              ...temp.messages[0][i],
              attachment: event.target.result,
            };
            temp.messages[0][i] = {
              ...temp.messages[0][i],
              attachment_name: e.target.files[0].name,
            };
          }
          EventEmitter.emit("edit", { value: temp });
        }
        setFiles(e.target.files[0]);
        setFichier({ ...fichier, [i]: e.target.files[0].name });
      }
    }
  };

  const displayInputs = () => {
    //-------------------------------------------------------------------------
    if (!isPack) {
      return (
        <div>
          <div className="modal_title3">Conseil Unique</div>
          <div className="text_champs">
            Contenu*{" "}

            {tutorial?.messages?.[0]?.[0]?.content?.length > 0 ? 325 - tutorial?.messages?.[0]?.[0]?.content?.length : 325} Caractères restants
          </div>
          {tutorial.messages?.[0] != undefined ? (
            tutorial?.messages?.[0]?.[0]?.content != undefined ? (
              <textarea
                style={{ padding: "10px" }}
                name="input_content"
                className="textarea_modals"
                onChange={handleInputChange}
                onSelect={toggleSelect}
                defaultValue={
                  tutorial?.messages?.[0]?.[0]?.content == null
                    ? ""
                    : tutorial?.messages?.[0]?.[0]?.content
                }
                maxLength={325}
              />
            ) : (
              <textarea
                style={{ padding: "10px" }}
                name="input_content"
                className="textarea_modals"
                onChange={handleInputChange}
                onSelect={toggleSelect}
                maxLength={325}
              />
            )
          ) : null}
          {errorContent ? (
            <div className="alert alert-danger">Ce champ est requis</div>
          ) : null}
          <div className="text_champs">Ajouter un lien web</div>
          <Field
            name="input_url"
            type="text"
            className="input_modals"
            defaultValue={tutorial.messages?.[0]?.[0]?.url}
          />
          <AttachFileContainer
            tutorial={tutorial}
            setTutorial={setTutorial}
            isEdit={true}
            handleDrop={handleDrop}
            handleFile={handleFile}
            message={tutorial?.messages?.[0]?.[0]}
            index={0} />
        </div>
      );
    } else {
      return tutorial?.messages?.[0]?.map((n, i) => (
        <div key={i}>
          <div className="line"></div>
          <Grid container direction='row' display='flex' justifyContent='space-between' alignItems='center' marginTop='30px'>
            <Grid item display='grid'>
              <span className="modal_title_edit_pack">Conseil n°{i + 1}</span>
            </Grid>
            <Grid item display='grid'>
              <button
                onClick={tutorial?.messages?.[0]?.length > 1 ? () => deleteAdvice(i) : null}
                className={tutorial?.messages?.[0]?.length > 1 ? 'delete_advice_button' : 'disable_delete_advice_button'}>
                Supprimer le conseil
              </button>
            </Grid>
          </Grid>
          <div className="text_champs">
            Contenu *{" "}
            <span>
              {tutorial?.messages?.[0]?.[i]?.content?.length > 0 ? 325 - tutorial?.messages?.[0]?.[i]?.content?.length : 325} Caractères
              restants
            </span>
          </div>
          {tutorial?.messages?.[0]?.[i] == undefined ? (
            <textarea
              style={{ padding: "10px" }}
              name={"content" + i}
              className="textarea_modals"
              onChange={handleInputChange}
              maxLength={325}
            />
          ) : (
            <textarea
              style={{ padding: "10px" }}
              name={"content" + i}
              className="textarea_modals"
              onChange={handleInputChange}
              value={tutorial?.messages?.[0]?.[i]?.content}
              maxLength={325}
            />
          )}
          <div className="text_champs">Ajouter un lien web</div>
          {tutorial?.messages?.[0]?.[i] == undefined ? (
            <Field name={"url" + i} type="text" className="input_modals" />
          ) : (
            <Field
              name={"url" + i}
              type="text"
              className="input_modals"
              defaultValue={
                tutorial?.messages?.[0]?.[i].url != ""
                  ? tutorial?.messages?.[0]?.[i].url
                  : ""
              }
            />
          )}
          < AttachFileContainer
            tutorial={tutorial}
            setTutorial={setTutorial}
            isEdit={true}
            handleDrop={handleDrop}
            handleFile={handleFile}
            message={tutorial?.messages?.[0]?.[i]}
            index={i} />
          <div className="text_champs">Période d’envoi *</div>
          <div className="send-container description-in-tabs">
            <div className="send-container description-in-tabs">
              {tutorial?.messages?.[0]?.[i] != undefined ? (
                tutorial?.messages?.[0]?.[i].hours != undefined ? (
                  <div>
                    <input
                      style={{ width: "70px" }}
                      type="number"
                      name={"dateheures" + i}
                      onChange={handleInputChange}
                      defaultValue={
                        Math.abs(tutorial?.messages?.[0]?.[i].hours) < 24
                          ? tutorial?.messages?.[0]?.[i].hours
                          : null
                      }
                    />
                    h
                  </div>
                ) : (
                  <div>
                    <input
                      style={{ width: "70px" }}
                      type="number"
                      name={"dateheures" + i}
                      onChange={handleInputChange}
                    />
                    h
                  </div>
                )
              ) : (
                <div>
                  <input
                    style={{ width: "70px" }}
                    type="number"
                    name={"dateheures" + i}
                    onChange={handleInputChange}
                  />
                  h
                </div>
              )}
              {tutorial?.messages?.[0]?.[i] != undefined ? (
                tutorial?.messages?.[0]?.[i].hours != undefined ? (
                  <div>
                    <input
                      style={{ width: "70px" }}
                      type="number"
                      name={"datejours" + i}
                      onChange={handleInputChange}
                      defaultValue={
                        Math.abs(tutorial?.messages?.[0]?.[i].hours) >= 24
                          ? tutorial?.messages?.[0]?.[i].hours / 24
                          : null
                      }
                    />
                    j
                  </div>
                ) : (
                  <div>
                    <input
                      style={{ width: "70px" }}
                      type="number"
                      name={"datejours" + i}
                      onChange={handleInputChange}
                    />
                    j
                  </div>
                )
              ) : (
                <div>
                  <input
                    style={{ width: "70px" }}
                    type="number"
                    name={"datejours" + i}
                    onChange={handleInputChange}
                  />
                  j
                </div>
              )}
              <input
                style={{ width: "70px" }}

                type="number"
                name={"datemois" + i}
                onChange={handleInputChange}
              />
              m
              <input
                style={{ width: "70px" }}
                type="number"
                name={"datean" + i}
                onChange={handleInputChange}
              />
              an (s)
            </div>
          </div>
        </div>
      ));
    }
  };

  if (isShowing && !submitted) {
    return (
      ReactDOM.createPortal(
        <>
          {isPopupExit &&
            <CancellablePopup
              firstTitle='Modifier'
              secondTitle='un conseil'
              message='Attention, vous allez fermer cette fenêtre et vos modifications ne seront pas enregistrées.'
              confirmButtonText='Fermer'
              cancelButtonText='Ne pas fermer'
              onCancel={cancelExit}
              onConfirm={confirmExit}
            />}
          <div className="modal-overlay-edit">
            <div className={"modal-wrapper"}>
              <div className="create_conseil_modal">
                <div className="modal-header">
                  <div className="modal_title1">
                    Modifier <span className="modal_title2"> un conseil</span>
                  </div>
                  <button
                    type="button"
                    className="modal-close-button"
                    onClick={() => {
                      setIsPopupExit(true);
                    }}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body" style={submitted ? { visibility: "hidden" } : null}>
                  <div className="container">
                    <div className="row">
                      <Formik
                        initialValues={initialTutorialState}
                        validationSchema={validationSchema}
                        onSubmit={saveTutorial}
                      >
                        <Form onChange={(e) => handleInputChange(e)}>
                          <div className="text_champs">Type *</div>
                          <div className="align-box" style={{ marginTop: '15px' }}>
                            <label style={{ marginRight: "46px" }}>
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Field
                                  style={{ marginRight: "7px" }}
                                  name="type"
                                  type="radio"
                                  className="checkbox_type"
                                  value="true"
                                  onClick={() => {
                                    setIsPack(false);
                                  }}
                                />
                                <span className="checkbox_text">
                                  Conseil unique
                                </span>
                              </div>
                            </label>
                            <label>
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Field
                                  style={{
                                    marginRight: '7px',
                                  }}
                                  name="type"
                                  type="radio"
                                  className="checkbox_type"
                                  value="false"
                                  onClick={() => setIsPack(true)}
                                />
                                <span className="checkbox_text">
                                  Pack de conseils
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="text_champs" style={{ marginTop: '33px' }}>Titre *</div>
                          <Field
                            name="title"
                            type="text"
                            className="input_modals"
                            defaultValue={tutorial.title}
                          />
                          <div className="text_champs">Spécialité *</div>
                          <Select
                            options={options}
                            noOptionsMessage={() => "En cours de chargement"}
                            defaultInputValue={proffessionsBase}
                            defaultValue={proffessionsBase}
                            placeholder={""}
                            onChange={(e) => handleSpec(e)}
                          />
                          {displayInputs()}
                          <div className="line"></div>
                          {isPack ? (
                            <div>
                              <div style={{ display: "flex" }}>
                                <button
                                  className="import-button"
                                  style={{
                                    height: "55px",
                                    width: "152px",
                                    margin: "auto",
                                    marginTop: "25px",
                                  }}
                                  onClick={addConseil}
                                >
                                  Ajouter un conseil
                                </button>
                              </div>
                              <div className="line"></div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <label className="label_checkbox">
                            <Field
                              name="add_my_favourites"
                              type="checkbox"
                              className="checkbox_modals"
                              checked={isChecked ? true : false}
                              onClick={() => setIsChecked(!isChecked)}
                            />
                            {isPack ? (
                              <div>Ajouter ce pack aux favoris</div>
                            ) : (
                              <div>Ajouter aux favoris</div>
                            )}
                          </label>
                          <div className="field_star" style={{ paddingBottom: '5px', paddingTop: '10px' }}>
                            * Champs obligatoires
                          </div>

                          {!isValidSubmit() &&
                            <div className="field_star">
                              {errorMessage}
                            </div>
                          }
                          <div className="button_modals" style={{ paddingTop: '15px' }}>
                            <button
                              className="button_modals_class grey_button" style={{ border: 'none', boxShadow: 'none', margin: '0 0 0 0' }}
                              onClick={() => {
                                setIsPopupExit(true);
                              }}>
                              Annuler
                            </button>
                            {isValidSubmit() ?
                              <div style={{ marginLeft: '20px' }}>
                                <OrangeButton
                                  animation={true}
                                  enable={true}
                                  type='submit'
                                  text='Modifier'
                                  onClick={saveTutorial} />
                              </div>
                              :
                              <div style={{ marginLeft: '20px' }}>
                                <OrangeButton
                                  animation={true}
                                  enable={false}
                                  type='submit'
                                  text='Modifier' />
                              </div>
                            }
                          </div>
                          {error ? (
                            <div>
                              <p style={{ margin: "auto" }}>
                                Veuillez remplir tous les champs obligatoires
                              </p>
                            </div>
                          ) : null}
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    );
  } else if (submitted) {
    return (
      <InformationPopup
        firstTitle={`Modifier`}
        secondTitle='un conseil'
        message={`Votre conseil a bien été modifié.`}
        confirmButtonText='OK'
        onConfirm={() => {
          if (isShowing) {
            hide();
          }
          setSubmitted(false);
        }}
      />
    );
  } else {
    return (
      null
    );
  }
};

export default EditConseilModal;
