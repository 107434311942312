import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import makeAPIRequest from '../../utils/makeAPIRequest'

const getQuestionnaires = createAsyncThunk(
  '/GET_QUESTIONNAIRES',
  async (payload, thunkAPI): Promise<any[]> => {
    try {
      const response = await makeAPIRequest(
        'get',
        `/questions/forms/list`,
        null,
        'v3',
      )
      const data: any[] = response?.data;
      return thunkAPI.fulfillWithValue(data) as any
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

export interface QuestionnaireInteractifReducerState {
  list: any[],
  isLoading: boolean,
}

const initialState: QuestionnaireInteractifReducerState = {
  list: [],
  isLoading: false,
}

export const newQuestionnaireSlice = createSlice({
  name: 'Questionnaire',
  initialState,
  reducers: {
    setQuestionnaire: (state, action) => {
      state.list = action.payload;
    }
  },
  extraReducers(builder: ActionReducerMapBuilder<QuestionnaireInteractifReducerState>) {
    builder.addCase(getQuestionnaires.rejected, (state) => {
      state.isLoading = false;
    })
    builder.addCase(getQuestionnaires.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getQuestionnaires.pending, (state) => {
      state.isLoading = true;
    })
  },
})
export const questionnaireActions = newQuestionnaireSlice.actions;
export default newQuestionnaireSlice.reducer
export { getQuestionnaires }
