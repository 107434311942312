import * as React from "react";
import { Grid } from "@mui/material";

const HandlePatientsGroups: React.FC<any> = (props) => {
  const { setIsPatientsGroupOpen } = props;

  return (
    <button
      style={{
        boxShadow: "2px 6px 10px #0F213467",
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: "20px",
        maxWidth: "330px",
        maxHeight: "32px",
        paddingRight: "20px",
        borderRadius: "18px",
        opacity: 1,
        background: "#0B243C 0% 0% no-repeat padding-box",
        letterSpacing: "0px",
        color: "#FFFFFF",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "12px",
        fontFamily: "Poppins",
        justifySelf: "flex-end",
      }}
      onClick={() => {
        setIsPatientsGroupOpen(true);
      }}
    >
      <Grid item display="grid">
        <Grid
          container
          spacing="7px"
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item display="grid">
            <span>Gérer mes groupes</span>
          </Grid>
        </Grid>
      </Grid>
    </button>
  );
};

export default HandlePatientsGroups;
