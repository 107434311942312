import * as React from 'react';
import { Grid } from '@mui/material';

interface ISquareCheckBoxField {
  text: string,
  value: boolean,
  onClick: any,
}

const SquareCheckBoxField = (props: ISquareCheckBoxField) => {
  const {
    text,
    value,
    onClick
  } = props;

  return (
    <Grid container direction='row' display='flex' columnSpacing='8px'>
      <Grid item display='grid'>
        <div style={{
          background: '#EDF2F2 0% 0% no-repeat padding-box', width: '22px', height: '22px', borderRadius: '5px',
          border: '1px solid #D3E0E0', opacity: 1
        }}>
          <input
            type="checkbox"
            className="checkbox_modals"
            checked={value}
            onChange={() => { return (null) }}
            onClick={onClick}
            style={{
              padding: '0', margin: '0', borderRadius: '5px',
              backgroundColor: 'red', width: '100%', height: '100%', opacity: value === true ? 1 : 0,
            }} />
        </div>
      </Grid>
      <Grid item display='grid'>
        <span className="checkbox_text" >
          {text}
        </span>
      </Grid>
    </Grid>
  );
};

export default SquareCheckBoxField;