import * as React from 'react';
import { CircularProgress, Grid } from "@mui/material";
import GroupsListTableTitles from './GroupsListTableTitles';
import GroupsListTableContent from './GroupsListTableContent';
import './groupsTable.scss';

const GroupsListContent: React.FC<any> = (props) => {
  if (!props?.groupsFiltered || props?.groupsFiltered?.length === 0) return (null);
  return (
    <>
      <div className="table-groups-grid" style={{ overflow: 'auto' }}>
        <GroupsListTableTitles />
        {!props?.isLoading &&
          <GroupsListTableContent {...props} />
        }
      </div>
      {props?.isLoading &&
        <Grid container direction='row' display='flex' justifyContent='center' alignItems='center' paddingTop='15px'>
          <CircularProgress />
        </Grid>
      }
    </>
  );
}

export default GroupsListContent;