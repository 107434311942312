import FullField from "../../../Fields/FullField";
import TextInput from "../../../Library/Inputs/TextInput";
import * as React from "react";
import HandleErrors from "../../../Library/Errors/HandleErrors";
import { Grid } from "@mui/material";
import { getFieldsLabelValue } from "../../EditQuestionnaire/QuestionnairePopupEdit/Fields/DisplayAdditionAuto";

function stringToNumber(str: string) {
  const num = Number(str);
  if (isNaN(num)) {
    return 0;
  } else {
    return num;
  }
}

export const calcValueAdditionAuto = (questionnaireData, options) => {
  let counter = 0;
  const fieldsLabelValue = getFieldsLabelValue(questionnaireData);

  console.log(options);
  if (!options || options?.length == 0) { return (counter.toString()); }
  for (const opt of options) {
    const founded = fieldsLabelValue.find((x) => x?.value === opt);
    if (founded && founded?.value) {
      for (const section of questionnaireData?.sections) {
        for (const fieldRow of section?.fields) {
          for (const field of fieldRow) {
            if (field?.id === founded?.value) {
              if (typeof field?.value === 'number') {
                counter += field.value
              } else if (typeof field?.value === 'string') {
                counter += stringToNumber(field.value);
              }
            }
          }
        }
      }
    }
  }
  return (counter.toString())
}

const DisplayAdditionAuto: React.FC<any> = (props) => {
  const {
    field,
    justifyContent,
    sectionIndex,
    disableError,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  return (
    <FullField
      isQuestionnaire={true}
      title={field?.title}
      isMandatory={false}
      justifyContent={justifyContent}
    >
        <Grid container direction="column" wrap="nowrap">
          <Grid item display="grid">
            <TextInput
              readOnly={'readonly'}
              id={"field" + field?.id}
              onChange={(e) => {
                return;
              }}
              value={calcValueAdditionAuto(questionnaireData, questionnaireData.sections[sectionIndex].fields[
                fieldRowIndex
              ][fieldIndex].option)}
            />
          </Grid>
        </Grid>
    </FullField>
  );
};

export default DisplayAdditionAuto;
