const forceDisconnectUser = () => {
  if (localStorage.getItem('token') || localStorage.getItem('user')) {
    localStorage.removeItem('token');
    localStorage.removeItem('lastConnection');
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    localStorage.removeItem("Patient");
    localStorage.removeItem("PatientSelected");
    localStorage.removeItem("adviceSelected");
    localStorage.removeItem("Chose");
    window.location.reload();
  }
};

export default forceDisconnectUser;