import { Grid } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import BoxTitle from "../components/Library/Box/BoxTitle";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import MonCompteContent from "../components/MonCompteContent/MonCompteContent";
import NavbarComponent from "../components/NavbarComponent";
import { setCertificate, setHasGroup } from "../redux/INSiReducer";
import EventEmitter from "../services/event";
import makeAPIRequest from "../utils/makeAPIRequest";
import useAppSelector from "../redux/useAppSelector";

const handlePatientBase = () => {
  EventEmitter.emit("PatientBase", {
    page: "Profile",
  });
};

const MonCompteBox = () => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="1360px"
      minWidth="600px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              columnSpacing="20px"
              rowSpacing="5px"
            >
              <Grid item display="grid">
                <BoxTitle first="Mon" second="Compte" />
              </Grid>
              <Grid item display="grid">
                <Link
                  to={"/patient_list_v2"}
                  style={{ textDecoration: "none" }}
                >
                  <button
                    style={{
                      borderRadius: "10px",
                      background: "#0B243C 0% 0% no-repeat padding-box",
                      fontWeight: 500,
                      fontSize: "13px",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "35px",
                      maxWidth: "254px",
                      width: "254px",
                      color: "#FFFFFF",
                      letterSpacing: "0px",
                    }}
                    onClick={handlePatientBase}
                  >
                    Gérer la base de donnée patients
                  </button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid">
            <MonCompteContent />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ProfilePage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    makeAPIRequest("get", "/insi/get_my_certificate", null, "v3")
      .then((res) => {
        if (res?.data) {
          if (res?.data?.success === false && res?.data?.error === "no_group") {
            dispatch(setHasGroup(false));
          } else {
            dispatch(setCertificate(res?.data?.certificate));
            dispatch(setHasGroup(true));
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Grid
      container
      direction="column"
      display="flex"
      wrap='nowrap'
      style={{
        minHeight: "100vh",
      }}
    >
      <Grid item display="grid" alignItems="flex-start" justifyContent="center">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <NavbarComponent />
          </Grid>
          <Grid item display="grid" paddingTop="20px">
            <Grid
              container
              display="flex"
              direction="row"
              justifyContent="center"
              spacing="20px"
              paddingLeft="24px"
              paddingRight="24px"
            >
              <Grid item display="grid" alignItems="flex-start">
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid">
                    <MesReglagesBox page="profile" />
                  </Grid>
                  {props?.user?.account_type == "4" && (
                    <Grid item display="grid" paddingTop="30px">
                      <AdministrationBox page="profile" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs
                display="grid"
                alignItems="flex-start"
                marginLeft="24px"
                width={windowWidth > 1300 ? "1000px" : null}
              >
                <MonCompteBox />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs display="grid" alignItems="flex-end">
        <Footer />
      </Grid>
    </Grid>
  );
};

export default ProfilePage;
