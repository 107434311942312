import { createSlice } from '@reduxjs/toolkit';

export const groupsTokensSlice = createSlice({
  name: 'groupsTokens',
  initialState: {
    accounts: [],
  },
  reducers: {
    updateAccounts: (state, action) => {
      state.accounts = action.payload;
    },
  }
})

export const { updateAccounts } = groupsTokensSlice.actions

export default groupsTokensSlice.reducer;
