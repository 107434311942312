import FullField from "../../../Fields/FullField";
import * as React from "react";
import MultilineInput from "../../../Library/Inputs/MultilineInput";
import HandleErrors from "../../../Library/Errors/HandleErrors";

const DisplayLongText: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const {
    field,
    sectionIndex,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    disableError,
    setQuestionnaireData,
  } = props;

  return (
    <FullField
      isQuestionnaire={true}
      title={field?.title}
      isMandatory={field?.isMandatory}
      hideAsterics={field?.hideAsterics ? true : false}
    >
      <HandleErrors
        isError={isError && !disableError}
        isMandatory={field?.isMandatory}
        errorMessage={errorMessage}
      >
        <MultilineInput
          id={"field" + field?.id}
          isError={isError && !disableError}
          height="80px"
          maxLength={field?.maxLength}
          whiteSpace="pre-line"
          value={field?.value ? field?.value : ""}
          onChange={(e) => {
            const questionnaireDataTmp = { ...questionnaireData };

            questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
              fieldIndex
            ].value = e;
            setQuestionnaireData(questionnaireDataTmp);
          }}
          onFocus={(e) => {
            if (!field?.isMandatory) return;
            if (isError && !disableError) {
              setErrorMessage("");
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!field?.isMandatory) return;
            if (!e.target.value) {
              if (!isError && !disableError) {
                setErrorMessage("Ce champ est obligatoire.");
                setIsError(true);
              }
            } else {
              if (isError && !disableError) {
                setErrorMessage("");
                setIsError(false);
              }
            }
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

export default DisplayLongText;
