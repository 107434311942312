import FullField from "../../../Fields/FullField";
import * as React from "react";
import MySelect from "../../../Library/Select/MySelect";
import HandleErrors from "../../../Library/Errors/HandleErrors";
import { IField, IForm, Structure } from "../../../../types/FormTypes";
import { Grid } from "@mui/material";
import { setFieldCopy } from "../../../../redux/questionnaireReducer";
import { getFieldByFieldId } from "../../EditQuestionnaire/Fields/DisplayConfirmMessage";
import { formatResponse, isFutureDate } from "../DisplayButtons";
import { isValidConditionResponse } from "../GenerateQuestionnaire";
import {
  getActualTime,
  getActualTimeFromYYYYMMDD,
  getActualTimeNumber,
} from "../../../NavbarComponent";

const DisplayStructure: React.FC<any> = (props) => {
  const [isErrorDate, setIsErrorDate] = React.useState(false);
  const [errorMessageDate, setErrorMessageDate] = React.useState("");
  const [isErrorDelegue, setIsErrorDelegue] = React.useState(false);
  const [errorMessageDelegue, setErrorMessageDelegue] = React.useState("");
  const [isErrorStructure, setIsErrorStructure] = React.useState(false);
  const [errorMessageStructure, setErrorMessageStructure] = React.useState("");
  const ERROR_MESSAGE_DATE =
    "La date renseignée doit être postérieure à la date actuelle.";
  const {
    sectionIndex,
    justifyContent,
    fieldIndex,
    disableError,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;
  const [field, setField] = React.useState<Partial<IField>>(undefined);

  interface IOption {
    label?: string;
    value?: string;
  }

  React.useEffect(() => {
    setField(getFieldByFieldId(questionnaireData, props?.field?.id));
  }, [questionnaireData, props?.field]);

  const getAllOptions = (): [
    IOption[],
    IOption[],
    IOption[],
    IOption[],
    IOption[]
  ] => {
    const options: [IOption[], IOption[], IOption[], IOption[], IOption[]] = [
      [],
      [],
      [],
      [],
      [],
    ];
    if (!field?.structureInfos?.structuresList) {
      return options;
    }
    if (field?.structureInfos?.structuresList?.length == 0) {
      return options;
    }

    if (field?.value?.[0]) {
      const structure: Structure = field.structureInfos.structuresList.find(
        (x) => x?.id == field?.value?.[0]
      );

      if (structure) {
        options[3] = structure.deleguesList
          .map((d) => {
            return {
              value: d.id,
              label: d.nomPrenom,
            };
          })
          .sort((a, b) => {
            const first = a?.label?.toLowerCase();
            const second = b?.label?.toLowerCase();
            return first < second ? -1 : first > second ? 1 : 0;
          });
        options[4] = structure.deleguesList.map((d) => {
          return {
            value: d.id,
            label: d.RPPS,
          };
        });
      }
    }
    for (const structure of field?.structureInfos.structuresList) {
      if (structure?.id) {
        if (structure?.nom) {
          options[0].push({
            value: structure.id,
            label: `${structure.nom}`,
          });
        }
        if (structure?.AM) {
          options[1].push({
            value: structure.id,
            label: `${structure.AM}`,
          });
        }
        if (structure?.coordonnees) {
          options[2].push({
            value: structure.id,
            label: `${structure.coordonnees}`,
          });
        }
      }
    }
    options[0] = options[0].sort((a, b) => {
      const first = a?.label?.toLowerCase();
      const second = b?.label?.toLowerCase();
      return first < second ? -1 : first > second ? 1 : 0;
    });
    return options;
  };

  const allOptions = getAllOptions();

  const [
    optionsNomStructure,
    optionsAMStructure,
    optionsCoordonneesStructure,
    optionNomDelegue,
    optionRPPSDelegue,
  ] = [...allOptions];
  /*
    const optionNomDelegueSelected = optionNomDelegue && field?.value?.[1]
      ? optionNomDelegue.find((x) => x?.value === field?.value?.[1])
      : undefined;
      */

  const [optionSelected, setOptionSelected] = React.useState<{
    nomStructure: IOption;
    amStructure: IOption;
    coordonneesStructure: IOption;
    nomDelegue: IOption;
    rppsDelegue: IOption;
  }>({
    nomStructure: undefined,
    amStructure: undefined,
    coordonneesStructure: undefined,
    nomDelegue: undefined,
    rppsDelegue: undefined,
  });

  React.useEffect(() => {
    const allOptionsTmp = getAllOptions();

    const [
      optionsNomStructureTmp,
      optionsAMStructureTmp,
      optionsCoordonneesStructureTmp,
      optionNomDelegueTmp,
      optionRPPSDelegueTmp,
    ] = [...allOptionsTmp];

    setOptionSelected({
      amStructure:
        optionsAMStructureTmp && field?.value?.[0]
          ? optionsAMStructureTmp.find((x) => x?.value === field?.value?.[0])
          : undefined,
      nomStructure:
        optionsNomStructureTmp && field?.value?.[0]
          ? optionsNomStructureTmp.find((x) => x?.value === field?.value?.[0])
          : undefined,
      coordonneesStructure:
        optionsCoordonneesStructureTmp && field?.value?.[0]
          ? optionsCoordonneesStructureTmp.find(
              (x) => x?.value === field?.value?.[0]
            )
          : undefined,
      nomDelegue:
        optionNomDelegueTmp && field?.value?.[1]
          ? optionNomDelegueTmp.find((x) => x?.value === field?.value?.[1])
          : undefined,
      rppsDelegue:
        optionRPPSDelegueTmp && field?.value?.[1]
          ? optionRPPSDelegueTmp.find((x) => x?.value === field?.value?.[1])
          : undefined,
    });
  }, [field?.value, questionnaireData]);

  const onChangeDelegue = (e) => {
    const questionnaireDataTmp: IForm = { ...questionnaireData };

    if (
      questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ].value?.length === 2
    ) {
      questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ].value.push(undefined);
    }
    if (
      questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ].value?.length !== 3
    ) {
      questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ].value = [undefined, undefined, undefined];
    }
    const newDelegueValue = e?.value;
    questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
      fieldIndex
    ].value[1] = newDelegueValue;

    questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
      fieldIndex
    ].value[2] = undefined;

    /*
    const structure: Structure = field.structureInfos.structuresList.find(
      (x) => x?.id == field?.value?.[0]
    );
    if (structure) {
      const delegue = structure.deleguesList.find(
        (x) => x?.id == newDelegueValue
      );
      if (
        delegue &&
        delegue?.dateDeclarationInfos?.dateDeclarationsList?.length > 0
      ) {
        const responses = formatResponse(questionnaireData, "", "", "");
        for (const dateDeclaration of delegue.dateDeclarationInfos
          .dateDeclarationsList) {
          const isValid = isValidConditionResponse(
            dateDeclaration.conditions,
            responses.fields,
            dateDeclaration.conditionType,
            questionnaireData
          );
          if (isValid) {
            questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
              fieldIndex
            ].value[2] = dateDeclaration.date;
            break;
          }
        }
      }
    }
      */
    setQuestionnaireData(questionnaireDataTmp);
  };

  const onBlurDelegue = (e) => {
    if (!field?.isMandatory) return;
    if (
      !questionnaireData.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ]?.value?.[1]
    ) {
      if (!isErrorDelegue && !disableError) {
        setErrorMessageDelegue("Ce champ est obligatoire.");
        setIsErrorDelegue(true);
      }
    } else {
      if (isErrorDelegue && !disableError) {
        setErrorMessageDelegue("");
        setIsErrorDelegue(false);
      }
    }
  };

  const onChangeStructure = (e) => {
    setQuestionnaireData((x) => {
      const questionnaireDataTmp = { ...x };

      if (
        questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
          fieldIndex
        ].value?.length !== 3
      ) {
        questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
          fieldIndex
        ].value = [undefined, undefined, undefined];
      }
      questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ].value[0] = e?.value;

      // Réinitialise le délégué
      questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ].value[1] = undefined;
      questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ].value[2] = undefined;
      return questionnaireDataTmp;
    });
  };

  const onBlurStructure = (e) => {
    if (!field?.isMandatory) return;
    if (
      !questionnaireData.sections[sectionIndex].fields[fieldRowIndex][
        fieldIndex
      ]?.value?.[0]
    ) {
      if (!isErrorStructure && !disableError) {
        setErrorMessageStructure("Ce champ est obligatoire.");
        setIsErrorStructure(true);
      }
    } else {
      if (isErrorStructure && !disableError) {
        setErrorMessageStructure("");
        setIsErrorStructure(false);
      }
    }
  };

  const onFocusStructure = (e) => {
    if (!field?.isMandatory) return;
    if (isErrorStructure && !disableError) {
      setErrorMessageStructure("");
      setIsErrorStructure(false);
    }
  };

  const onFocusDelegue = (e) => {
    if (!field?.isMandatory) return;
    if (isErrorDelegue && !disableError) {
      setErrorMessageDelegue("");
      setIsErrorDelegue(false);
    }
  };

  let date = "";

  return (
    <Grid container direction="column" wrap="nowrap" gap="10px">
      <Grid item display="grid">
        <FullField
          isQuestionnaire={true}
          title={"Nom de la structure"}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={isErrorStructure && !disableError}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessageStructure}
          >
            <MySelect
              id={"field" + field?.id + "structure"}
              value={
                !optionSelected?.nomStructure
                  ? ("" as any)
                  : optionSelected?.nomStructure
              }
              isError={isErrorStructure && !disableError}
              options={optionsNomStructure}
              placeholder=""
              onChange={onChangeStructure}
              onFocus={onFocusStructure}
              onBlur={onBlurStructure}
            />
          </HandleErrors>
        </FullField>
      </Grid>
      <Grid item display="grid">
        <FullField
          isQuestionnaire={true}
          title={"N° AM de la structure"}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={false /*isErrorStructure && !disableError*/}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessageStructure}
          >
            <MySelect
              isClearable={false}
              isDisabled={true}
              id={"field" + field?.id + "structure"}
              isError={false /*isErrorStructure && !disableError*/}
              value={
                !optionSelected?.amStructure
                  ? ("" as any)
                  : optionSelected?.amStructure
              }
              options={optionsAMStructure}
              placeholder=""
              onChange={onChangeStructure}
              onFocus={onFocusStructure}
              onBlur={onBlurStructure}
            />
          </HandleErrors>
        </FullField>
      </Grid>
      <Grid item display="grid">
        <FullField
          isQuestionnaire={true}
          title={"Coordonnées de la structure"}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={false /*isErrorStructure && !disableError*/}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessageStructure}
          >
            <MySelect
              isClearable={false}
              id={"field" + field?.id + "structure"}
              isError={false /*isErrorStructure && !disableError*/}
              value={
                !optionSelected?.coordonneesStructure
                  ? ("" as any)
                  : optionSelected?.coordonneesStructure
              }
              options={optionsCoordonneesStructure}
              placeholder=""
              isDisabled={true}
              onChange={onChangeStructure}
              onFocus={onFocusStructure}
              onBlur={onBlurStructure}
            />
          </HandleErrors>
        </FullField>
      </Grid>
      {field?.value?.[0] && (
        <>
          <Grid item display="grid">
            <FullField
              isQuestionnaire={true}
              title={"Nom et prénom du délégué"}
              isMandatory={field?.isMandatory}
              justifyContent={justifyContent}
              hideAsterics={field?.hideAsterics ? true : false}
            >
              <HandleErrors
                isError={isErrorDelegue && !disableError}
                isMandatory={field?.isMandatory}
                errorMessage={errorMessageDelegue}
              >
                <MySelect
                  id={"field" + field?.id + "delegue"}
                  isError={isErrorDelegue && !disableError}
                  value={
                    !optionSelected?.nomDelegue
                      ? ("" as any)
                      : optionSelected?.nomDelegue
                  }
                  options={optionNomDelegue}
                  placeholder=""
                  onChange={onChangeDelegue}
                  onFocus={onFocusDelegue}
                  onBlur={onBlurDelegue}
                />
              </HandleErrors>
            </FullField>
          </Grid>
          <Grid item display="grid">
            <FullField
              isQuestionnaire={true}
              title={"N° RPPS du délégué"}
              isMandatory={field?.isMandatory}
              justifyContent={justifyContent}
              hideAsterics={field?.hideAsterics ? true : false}
            >
              <HandleErrors
                isError={false /*isErrorDelegue && !disableError*/}
                isMandatory={field?.isMandatory}
                errorMessage={errorMessageDelegue}
              >
                <MySelect
                  isDisabled={true}
                  isClearable={false}
                  id={"field" + field?.id + "delegue"}
                  isError={false /*isErrorDelegue && !disableError*/}
                  value={
                    !optionSelected?.rppsDelegue
                      ? ("" as any)
                      : optionSelected?.rppsDelegue
                  }
                  options={optionRPPSDelegue}
                  placeholder=""
                  onChange={onChangeDelegue}
                  onFocus={onFocusDelegue}
                  onBlur={onBlurDelegue}
                />
              </HandleErrors>
            </FullField>
          </Grid>
          <Grid item display="grid">
            <FullField
              isQuestionnaire={true}
              title={
                "Date de déclaration à l'ARS du protocole concerné ce jour"
              }
              isMandatory={field?.isMandatory}
              justifyContent={justifyContent}
              hideAsterics={field?.hideAsterics ? true : false}
            >
              <HandleErrors
                isError={isErrorDate}
                isMandatory={true}
                errorMessage={errorMessageDate}
              >
                <input
                  id={field?.id + "delegue-date-input"}
                  //isError={isError && !disableError}
                  type="date"
                  style={{
                    maxWidth: "200px",
                    border: "1px solid #D3E0E0",
                    background: "#EDF2F280 0% 0% no-repeat padding-box",
                    borderRadius: "10px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    color:
                      field?.value?.[2] && field?.value?.[2]?.length > 0
                        ? "#0B243C"
                        : "#657273",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    height: "42px",
                  }}
                  value={field?.value?.[2] ? field?.value?.[2] : ""}
                  onChange={(e) => {
                    const questionnaireDataTmp = { ...questionnaireData };

                    if (
                      questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex].value?.length === 2
                    ) {
                      questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex].value.push(undefined);
                    }
                    if (
                      questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex].value?.length !== 3
                    ) {
                      questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex].value = [undefined, undefined, undefined];
                    }
                    questionnaireDataTmp.sections[sectionIndex].fields[
                      fieldRowIndex
                    ][fieldIndex].value[2] = e?.target?.value;
                    setQuestionnaireData(questionnaireDataTmp);
                  }}
                  onFocus={(e) => {
                    if (
                      errorMessageDate === ERROR_MESSAGE_DATE &&
                      isErrorDate
                    ) {
                      setErrorMessageDate("");
                      setIsErrorDate(false);
                      return;
                    }
                    if (!props?.isMandatory) return;
                    if (isErrorDate && !props?.disableError) {
                      setErrorMessageDate("");
                      setIsErrorDate(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (isFutureDate(e.target.value)) {
                      setErrorMessageDate(ERROR_MESSAGE_DATE);
                      setIsErrorDate(true);
                    } else {
                      if (errorMessageDate === ERROR_MESSAGE_DATE) {
                        setErrorMessageDate("");
                        setIsErrorDate(false);
                      }
                    }
                    if (!props?.isMandatory) return;
                    if (!e.target.value) {
                      if (!isErrorDate && !props?.disableError) {
                        setErrorMessageDate("Ce champ est obligatoire.");
                        setIsErrorDate(true);
                      }
                    } else {
                      if (isErrorDate && !props?.disableError) {
                        setErrorMessageDate("");
                        setIsErrorDate(false);
                      }
                    }
                  }}
                />
              </HandleErrors>
            </FullField>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DisplayStructure;
