import { Help } from "@mui/icons-material";
import { Grid } from "@mui/material";
import * as React from 'react';

const ErrorHelp: React.FC<any> = (props) => {
  const [showError, setShowError] = React.useState<boolean>(false);
  const [saveBorder, setSaveBorder] = React.useState<string>('1px solid transparent');
  const {
    errorMessages,
    errorSelector,
    error,
  } = props;

  return (
    <Grid container direction='row' display='flex' justifyContent='center' spacing='5px' marginBottom='10px'>
      <Grid item display='grid' alignItems='center'>
        <span className='field_star' style={{ color: '#FF3100', whiteSpace: 'pre-line' }}>
          {errorMessages[error]}
        </span>
      </Grid>
      <Grid item display='grid'>
        <button
          style={{
            background: 'none',
            border: 'none',
            padding: '0',
            margin: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              if (showError) {
                const section = document?.querySelector(errorSelector?.[error]);
                if (!section) return;
                if (!section.style.boxShadow) section.style.border = saveBorder;
                if (section.style.width === 'fit-content') {
                  section.style.padding = null;
                }
                setShowError(false);
              }
            }, 2250);
          }}
          onClick={() => {
            if (!showError) setShowError(true);
            if (error === '' || errorSelector?.[error] === '') return;
            const section = document?.querySelector(errorSelector?.[error]);
            if (!section) return;
            if (section?.style?.border === '1px solid #FF3100') { return; }
            if (!section.style?.boxShadow) {
              setSaveBorder(section.style.border);
              if (section.style.width === 'fit-content') {
                section.style.padding = '10px';
              }
              section.style.border = '1px solid #FF3100';
              section.style.transitionProperty = 'border, padding';
              section.style.transitionDelay = '250ms';
              section.style.transitionDuration = '2000ms';
            }
            section?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }}>
          <Help className='field_star' style={{ height: '20px', color: '#FF3100', alignSelf: 'center', marginLeft: '5px'}} />
        </button>
      </Grid>
    </Grid>
  )
};

export default ErrorHelp;