import {
  ArchiveRounded,
  EditRounded,
  UnarchiveRounded,
} from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { openEditFormSchedule } from "../../../redux/editFormScheduleReducer";
import { openSnackbar } from "../../../redux/snackbarReducer";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import { genericGetFromTimestamp } from "../../AdminGroups/CreateGroupPopup";

const DisplayFormScheduled: React.FC<any> = (props) => {
  const {
    forms,
    scheduledIndex,
    patientList,
    filteredScheduled,
    allScheduled,
    setAllScheduled,
  } = props;
  const [isArchiveLoading, setIsArchiveLoading] = React.useState(false);
  const dispatch = useDispatch();

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    let hours = date.getHours().toString();
    if (hours.length === 1) hours = `0${hours}`;

    let minutes = date.getMinutes().toString();
    if (minutes.length === 1) minutes = `0${minutes}`;

    const status = filteredScheduled?.formStatus;
    const converter = {
      "in progress": "Depuis le ",
      completed: "Le ",
      pending: "Programmé pour le ",
      cancelled: "Le ",
    };

    return (
      converter?.[status] +
      dd +
      "/" +
      mm +
      "/" +
      yyyy +
      " à " +
      hours +
      "h" +
      minutes
    );
  };

  const displayForm = () => {
    if (!filteredScheduled?.formId) return "Questionnaire inconnu";
    const formTitle = forms?.find(
      (e) => e?.id === filteredScheduled?.formId
    )?.title;
    if (!formTitle) return "Questionnaire inconnu";
    return formTitle;
  };

  const displayPatient = () => {
    if (!filteredScheduled?.patient) {
      if (filteredScheduled?.phone) {
        return filteredScheduled.phone;
      }
      return "Patient anonyme";
    }
    const patient = patientList?.find(
      (e) => e?.id === filteredScheduled?.patient
    );
    if (!patient) return "Patient inconnu";
    return patient?.lastname + " " + patient?.firstname;
  };

  const formatStatus = () => {
    const status = filteredScheduled?.formStatus;
    const converter = {
      "in progress": "Attente de la réponse du patient",
      completed: "Le patient a répondu",
      pending: "Questionnaire non envoyé",
      cancelled: "Programmation annulée",
    };
    return converter?.[status];
  };

  return (
    <Grid
      container
      direction="row"
      border="1px solid #0B243C"
      boxShadow="4px 4px 3px 2px rgba(11, 36, 60, .3)"
      borderRadius="10px"
      wrap="nowrap"
    >
      <Grid item display="grid" xs>
        <Grid container direction="column" padding="10px" spacing="10px" wrap="nowrap">
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" spacing="1px">
              <Grid item display="grid" alignSelf="flex-end">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "rgb(11, 36, 60)",
                  }}
                >
                  {displayForm()}
                </span>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "rgb(11, 36, 60)",
                  }}
                >
                  {displayPatient()}
                </span>
              </Grid>
              <Grid item display="grid" marginTop="10px">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "rgb(11, 36, 60)",
                  }}
                >
                  {formatStatus()}
                </span>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "rgb(11, 36, 60)",
                  }}
                >
                  {["cancelled", "completed"].includes(
                    filteredScheduled?.formStatus
                  )
                    ? formatDate(filteredScheduled?.lastUpdate)
                    : formatDate(filteredScheduled?.dateTrigger)}
                </span>
              </Grid>
              {filteredScheduled?.questionnaireInteractifSended && (
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "rgb(11, 36, 60)",
                    }}
                  >
                    {`Un questionnaire interactif a été envoyé le ${genericGetFromTimestamp(
                      filteredScheduled?.questionnaireInteractifSended
                        ?.timestamp,
                      "dd/mm/yyyy à hh:min"
                    )}`}
                  </span>
                </Grid>
              )}
              {filteredScheduled?.relanceSMSLog?.length > 0 && (
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "rgb(11, 36, 60)",
                    }}
                  >
                    {filteredScheduled?.relanceSMSLog?.length > 1
                      ? `Relances envoyées : `
                      : `Relance envoyée : `}
                    {filteredScheduled?.relanceSMSLog?.length}
                  </span>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
        <div
          style={{
            height: "100%",
            borderLeft: "1px solid #0B243C",
          }}
        />
      </Grid>
      <Grid item display="grid" justifyContent="center" paddingRight="5px">
        {isArchiveLoading && (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress
              style={{ color: "#657273", width: "24px", height: "24px" }}
            />
          </Grid>
        )}
        {!filteredScheduled?.isArchived && !isArchiveLoading && (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            spacing="20px"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item display="grid">
              <Tooltip title="Archiver">
                <IconButton
                  onClick={() => {
                    setIsArchiveLoading(true);
                    makeAPIRequest(
                      "post",
                      `/questions/form/schedule/${filteredScheduled?.formScheduleId}/archive`,
                      null,
                      "v3"
                    )
                      .then((res) => {
                        setIsArchiveLoading(false);
                        const allScheduledTmp = [...allScheduled];

                        const ind = allScheduledTmp?.findIndex(
                          (r) =>
                            r?.formScheduleId ===
                            filteredScheduled?.formScheduleId
                        );
                        if (ind !== -1) {
                          allScheduledTmp[ind].isArchived = true;
                          setAllScheduled(allScheduledTmp);
                        }
                        dispatch(
                          openSnackbar({
                            duration: 3000,
                            message: "La programmation a bien été archivée",
                            type: "success",
                          })
                        );
                      })
                      .catch((err) => {
                        setIsArchiveLoading(false);
                        dispatch(
                          openSnackbar({
                            duration: 3000,
                            message:
                              "Impossible d'archiver cette programmation",
                            type: "error",
                          })
                        );
                      });
                  }}
                  style={{ margin: "0", padding: "0" }}
                >
                  <ArchiveRounded
                    fontSize="medium"
                    style={{ color: "rgb(11, 36, 60)" }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            {filteredScheduled?.formStatus == "pending" && (
              <Grid item display="grid">
                <Tooltip title="Modifier">
                  <IconButton
                    onClick={() => {
                      dispatch(
                        openEditFormSchedule({
                          formScheduleData: filteredScheduled,
                          title: displayForm(),
                        })
                      );
                    }}
                    style={{
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <EditRounded
                      fontSize="small"
                      style={{ color: "#0B243C" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}
        {filteredScheduled?.isArchived && !isArchiveLoading && (
          <Grid item display="grid" alignItems="center" justifyContent="center">
            <Tooltip title="Désarchiver">
              <IconButton
                onClick={() => {
                  setIsArchiveLoading(true);
                  makeAPIRequest(
                    "post",
                    `/questions/form/schedule/${filteredScheduled?.formScheduleId}/unarchive`,
                    null,
                    "v3"
                  )
                    .then((res) => {
                      setIsArchiveLoading(false);
                      const allScheduledTmp = [...allScheduled];

                      const ind = allScheduledTmp?.findIndex(
                        (r) =>
                          r?.formScheduleId ===
                          filteredScheduled?.formScheduleId
                      );
                      if (ind !== -1) {
                        allScheduledTmp[ind].isArchived = false;
                        setAllScheduled(allScheduledTmp);
                      }
                      dispatch(
                        openSnackbar({
                          duration: 3000,
                          message: "La programmation a bien été désarchivée",
                          type: "success",
                        })
                      );
                    })
                    .catch((err) => {
                      setIsArchiveLoading(false);
                      dispatch(
                        openSnackbar({
                          duration: 3000,
                          message:
                            "Impossible de désarchiver cette programmation",
                          type: "error",
                        })
                      );
                    });
                }}
                style={{ margin: "0", padding: "0" }}
              >
                <UnarchiveRounded
                  fontSize="medium"
                  style={{ color: "rgb(11, 36, 60)" }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DisplayFormScheduled;
