interface IOption {
  value: string;
  label: string;
}

const getOnlyValuesFromArray = (reactions: IOption[]) => {
  if (!reactions || reactions.length === 0) return ([]);
  const result = [];
  for (const reaction of reactions) {
    result.push(reaction.value);
  }
  return (result);
};

export default getOnlyValuesFromArray;
