import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as IconStar } from "../../../../assets/IconStar.svg";
import { setActiveTab } from '../../../../redux/tabsReducer';

const FavoriteTabButton: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(setActiveTab('favourite'));
      }}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '50px', paddingLeft: '20px', paddingRight: '20px' }}>
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}>
        <IconStar />
        <span style={{ paddingLeft: '8px' }}>Mes favoris {props.numberOfFavorite ? `(${props.numberOfFavorite})` : '(0)'}</span>
      </div>
    </div>
  );
};

export default FavoriteTabButton;