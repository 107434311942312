import { createSlice } from '@reduxjs/toolkit';

interface PatientInfos {
  phone: string;
  last_name: string;
  first_name: string;
  ins?: string;
  email?: string;
  isValidated?: boolean;
  error?: string;
}

export const importPatient = createSlice({
  name: 'importPatient',
  initialState: {
    result: [] as Array<PatientInfos>,
    error: [] as Array<PatientInfos>,
    patientUpdateList: [] as Array<PatientInfos>,
    isFinish: false,
  },
  reducers: {
    afterImportPatient: (state, action) => {
      const [result, error, patientUpdateList] = action.payload;

      state.isFinish = true;
      state.result = result;
      state.patientUpdateList = patientUpdateList;
      state.error = error;
    },
    selectImportPatient: (state, action) => {
      const index = action.payload;

      state.result[index].isValidated = !state.result[index].isValidated;
    },
    selectAllImportPatient: (state, action) => {
      const isCheck = action.payload;

      if (isCheck) {
        for (let index = 0; index < state.result.length; index++)
          state.result[index].isValidated = false;
      } else {
        for (let index = 0; index < state.result.length; index++)
          state.result[index].isValidated = true;
      }
    },
    closeImportPatient: (state) => {
      state.isFinish = false;
      state.result = null;
      state.error = null;
    }
  }
})

export const { afterImportPatient, selectImportPatient, selectAllImportPatient, closeImportPatient } = importPatient.actions

export default importPatient.reducer;
