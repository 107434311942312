import FullField from "../FullField";
import * as React from 'react';
import TextInput from "../../Library/Inputs/TextInput";

interface IPatientPhoneField {
  patientPhone: string,
  onChange: any,
  itemBorder?: string,
}

const PatientPhoneField = (props: IPatientPhoneField) => {
  const {
    patientPhone,
    onChange,
    itemBorder,
  } = props;

  return (
    <FullField title='N° de téléphone' isMandatory={true}>
      <TextInput
        className='border-animation'
        id='patient-phone-input'
        border={itemBorder}
        value={patientPhone}
        onChange={(e) => onChange(e)} />
    </FullField>
  );
};

export default PatientPhoneField;