import FullField from "../../../../Fields/FullField";
import * as React from 'react';
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import { useDispatch } from "react-redux";
import TextInput from "../../../../Library/Inputs/TextInput";
import useAppSelector from "../../../../../redux/useAppSelector";

const DisplayTitle: React.FC<any> = (props) => {
  const fieldCopy = useAppSelector(state => state.questionnaireReducer.fieldCopy);
  const dispatch = useDispatch();

  return (
    <FullField title='Titre' isMandatory={false} spacing='1px'>
      <TextInput
        value={fieldCopy?.title}
        placeholder={"Sans titre"}
        onChange={(e) => {
          const fieldCopyTmp = { ...fieldCopy };

          fieldCopyTmp.title = e.target.value;
          dispatch(setFieldCopy(fieldCopyTmp));
        }} />
    </FullField>
  );
};

export default DisplayTitle;