import {
  ArchiveRounded,
  EditRounded,
  UnarchiveRounded,
} from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { isPending } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/snackbarReducer";
import IChatbotSchedule from "../../types/IChatbotSchedule";
import makeAPIRequest from "../../utils/makeAPIRequest";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import FullField from "../Fields/FullField";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import ErrorHelp from "../Library/Errors/ErrorHelp";
import MySelect from "../Library/Select/MySelect";
import ConfigResponsePerPage from "../Questionnaire/Response/ConfigResponsePerPage";
import ResponseNavigation from "../Questionnaire/Response/ResponseNavigation";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatDateFromTimestamp } from "../MonPatientBox/MonPatientBox";
import useQuery from "../../utils/hooks/useQuery";

export const formatStatusWithParameter = (chatbotStatus) => {
  const status = chatbotStatus;
  const converter = {
    "in progress": "Conversation en cours",
    completed: "Conversation terminée",
    pending: "Conversation en attente",
    cancelled: "Conversation annulée",
  };
  return converter?.[status];
};

export const formatDateWithParameter = (timestamp, chatbotStatus) => {
  const date = new Date(timestamp);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  let hours = date.getHours().toString();
  if (hours.length === 1) hours = `0${hours}`;

  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = `0${minutes}`;

  const status = chatbotStatus;
  const converter = {
    "in progress": "Depuis le ",
    completed: "Le ",
    pending: "Débutera le ",
    cancelled: "Le ",
  };

  return (
    converter?.[status] +
    dd +
    "/" +
    mm +
    "/" +
    yyyy +
    " à " +
    hours +
    "h" +
    minutes
  );
};

const errorMessages = {
  emptyDate: "La date de déclenchement n'est pas renseignée.",
  emptyTime: "L'heure de déclenchement n'est pas renseignée.",
  "": "",
};

const errorSelector = {
  emptyDate: "#conversation-message-date",
  emptyTime: "#conversation-message-time",
  "": "",
};

interface IDisplayChatbotConversation {
  conversations?: Array<IChatbotSchedule>;
  setConversations?: any;
  filteredConversation?: IChatbotSchedule;
  chatbot?: any;
  patientList?: any;
  conversationIndex: number;
}

const DisplayChatbotConversation = (props: IDisplayChatbotConversation) => {
  const {
    chatbot,
    conversationIndex,
    patientList,
    filteredConversation,
    conversations,
    setConversations,
  } = props;
  const [isArchiveLoading, setIsArchiveLoading] = React.useState(false);
  const dispatch = useDispatch();

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    let hours = date.getHours().toString();
    if (hours.length === 1) hours = `0${hours}`;

    let minutes = date.getMinutes().toString();
    if (minutes.length === 1) minutes = `0${minutes}`;

    const status = filteredConversation?.chatbotStatus;
    const converter = {
      "in progress": "Depuis le ",
      completed: "Le ",
      pending: "Débutera le ",
      cancelled: "Le ",
    };

    return (
      converter?.[status] +
      dd +
      "/" +
      mm +
      "/" +
      yyyy +
      " à " +
      hours +
      "h" +
      minutes
    );
  };

  const displayPatient = () => {
    if (!filteredConversation?.patient) {
      if (filteredConversation?.phone) {
        return filteredConversation.phone;
      }
      return "Patient anonyme";
    }
    const patient = patientList?.find(
      (e) => e?.id === filteredConversation.patient
    );
    if (!patient || !patient?.lastname || !patient?.firstname)
      return "Patient inconnu";
    return patient?.lastname + " " + patient?.firstname;
  };

  const formatStatus = () => {
    const status = filteredConversation?.chatbotStatus;
    const converter = {
      "in progress": "Conversation en cours",
      completed: "Conversation terminée",
      pending: "Conversation en attente",
      cancelled: "Conversation annulée",
    };
    return converter?.[status];
  };

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyDate: !newDate,
      emptyTime: !newHour,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [newDate, setNewDate] = useState<string>(null);
  const [newHour, setNewHour] = useState(null);
  const [scheduleId, setScheduleId] = useState(null);
  const [error, setError] = useState("");

  return (
    <>
      {isEditOpen && (
        <FormPopup>
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle={"Modifier"}
              secondTitle={"une conversation"}
              onClose={() => {
                setIsEditOpen(false);
                setNewDate(null);
                setNewHour(null);
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              wrap="nowrap"
              paddingBottom="70px"
            >
              <Grid item display="grid">
                <FullField isMandatory={false} title="Date de déclenchement">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    spacing="10px"
                  >
                    <Grid item display="grid">
                      <span>Le</span>
                    </Grid>
                    <Grid item display="grid">
                      <input
                        id="schedule-message-date"
                        type="date"
                        style={{
                          minWidth: "100%",
                          border: "1px solid #D3E0E0",
                          background: "#EDF2F280 0% 0% no-repeat padding-box",
                          borderRadius: "10px",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                          color:
                            newDate && newDate?.length > 0
                              ? "#0B243C"
                              : "#657273",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          height: "42px",
                        }}
                        value={newDate}
                        onChange={(e) => setNewDate(e.target.value)}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <span>à</span>
                    </Grid>
                    <Grid item display="grid">
                      <input
                        id="schedule-message-time"
                        type="time"
                        style={{
                          minWidth: "100%",
                          border: "1px solid #D3E0E0",
                          background: "#EDF2F280 0% 0% no-repeat padding-box",
                          borderRadius: "10px",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                          color:
                            newHour && newHour?.length > 0
                              ? "#0B243C"
                              : "#657273",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          height: "42px",
                        }}
                        value={newHour}
                        onChange={(e) => setNewHour(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </FullField>
                <Grid item>
                  <Grid container direction="column" paddingTop="30px" wrap="nowrap">
                    {error?.length > 0 && (
                      <Grid item display="grid">
                        <ErrorHelp
                          errorMessages={errorMessages}
                          errorSelector={errorSelector}
                          error={error}
                        />
                      </Grid>
                    )}
                    <Grid item display="grid" justifyContent="center">
                      {isValidSubmit() ? (
                        <OrangeButton
                          animation={false}
                          enable={true}
                          type="submit"
                          text={"Mettre à jour"}
                          onClick={() => {
                            const toSend = {
                              dateTrigger: Date.parse(
                                newDate + "T" + newHour + ":00"
                              ),
                            };
                            makeAPIRequest(
                              "post",
                              `/chatbot/schedule/${scheduleId}/update`,
                              toSend,
                              "v3"
                            )
                              .then((res) => {
                                const conversationsTmp: Array<IChatbotSchedule> =
                                  [...conversations];
                                const index = conversationsTmp.findIndex(
                                  (conversationTmp) =>
                                    conversationTmp.chatbotScheduleId ===
                                    scheduleId
                                );
                                conversationsTmp[index].dateTrigger =
                                  Date.parse(newDate + "T" + newHour + ":00");
                                setConversations(conversationsTmp);
                                dispatch(
                                  openSnackbar({
                                    message:
                                      "Votre programmation a bien été modifiée",
                                    type: "success",
                                    duration: 3000,
                                  })
                                );
                                setIsEditOpen(false);
                              })
                              .catch((err) => {
                                dispatch(
                                  openSnackbar({
                                    message:
                                      "La modification de votre programmation a échouée",
                                    type: "error",
                                    duration: 3000,
                                  })
                                );
                              });
                          }}
                        />
                      ) : (
                        <OrangeButton
                          animation={false}
                          enable={false}
                          type="submit"
                          text={"Mettre à jour"}
                          onClick={() => {
                            return null;
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormPopup>
      )}
      <Grid
        container
        direction="row"
        border="1px solid #0B243C"
        boxShadow="4px 4px 3px 2px rgba(11, 36, 60, .3)"
        borderRadius="10px"
        wrap="nowrap"
      >
        <Grid item display="grid" xs>
          <Grid container direction="column" padding="10px" spacing="10px" wrap="nowrap">
            <Grid item display="grid">
              <Grid container direction="column" wrap="nowrap" spacing="1px">
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "rgb(11, 36, 60)",
                    }}
                  >
                    {displayPatient()}
                  </span>
                </Grid>
                <Grid item display="grid" marginTop="10px">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "rgb(11, 36, 60)",
                    }}
                  >
                    {formatStatus()}
                  </span>
                </Grid>
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "rgb(11, 36, 60)",
                    }}
                  >
                    {["cancelled", "completed"].includes(
                      filteredConversation?.chatbotStatus
                    ) && filteredConversation?.lastUpdate != 0
                      ? formatDate(filteredConversation?.lastUpdate)
                      : formatDate(filteredConversation?.dateTrigger)}
                  </span>
                </Grid>
                {filteredConversation?.relanceSMSLog?.length > 0 && (
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        color: "rgb(11, 36, 60)",
                      }}
                    >
                      {filteredConversation?.relanceSMSLog?.length > 1
                        ? `Relances envoyées : `
                        : `Relance envoyée : `}
                      {filteredConversation?.relanceSMSLog?.length}
                    </span>
                  </Grid>
                )}
                <Grid
                  item
                  display="grid"
                  marginTop={
                    filteredConversation?.questions?.length > 0 ? "25px" : null
                  }
                >
                  <Grid
                    container
                    direction="column"
                    spacing="15px"
                    wrap="nowrap"
                  >
                    {filteredConversation?.responses?.map((response, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <Grid container direction="column" wrap="nowrap">
                            <Grid item display="grid">
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "18px",
                                  color: "rgb(11, 36, 60)",
                                }}
                              >
                                {filteredConversation?.questions?.[index]}
                              </span>
                            </Grid>
                            <Grid item display="grid">
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "18px",
                                  color: "rgb(11, 36, 60)",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {response}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                {["in progress", "completed"].includes(
                  filteredConversation?.chatbotStatus
                ) && (
                  <Grid item display="grid" marginTop="10px">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        textAlign: "end",
                        color: "rgb(11, 36, 60)",
                      }}
                    >
                      {filteredConversation?.responses?.length}/
                      {filteredConversation?.questions?.length} réponse(s)
                    </span>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
          <div
            style={{
              height: "100%",
              borderLeft: "1px solid #0B243C",
            }}
          />
        </Grid>
        <Grid item display="grid" justifyContent="center" paddingRight="5px">
          {isArchiveLoading && (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              wrap="nowrap"
            >
              <CircularProgress
                style={{ color: "#657273", width: "24px", height: "24px" }}
              />
            </Grid>
          )}
          {!filteredConversation?.isArchived && !isArchiveLoading && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              spacing="20px"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item display="grid">
                <Tooltip title="Archiver">
                  <IconButton
                    onClick={() => {
                      setIsArchiveLoading(true);
                      makeAPIRequest(
                        "post",
                        `/chatbot/schedule/${filteredConversation?.chatbotScheduleId}/archive`,
                        null,
                        "v3"
                      )
                        .then((res) => {
                          setIsArchiveLoading(false);
                          const conversationsTmp = [...conversations];

                          const ind = conversationsTmp?.findIndex(
                            (r) =>
                              r?.chatbotScheduleId ===
                              filteredConversation?.chatbotScheduleId
                          );
                          if (ind !== -1) {
                            conversationsTmp[ind].isArchived = true;
                            setConversations(conversationsTmp);
                          }
                          dispatch(
                            openSnackbar({
                              duration: 3000,
                              message: "La conversation a bien été archivée",
                              type: "success",
                            })
                          );
                        })
                        .catch((err) => {
                          setIsArchiveLoading(false);
                          dispatch(
                            openSnackbar({
                              duration: 3000,
                              message:
                                "Impossible d'archiver cette conversation",
                              type: "error",
                            })
                          );
                        });
                    }}
                    style={{ margin: "0", padding: "0" }}
                  >
                    <ArchiveRounded
                      fontSize="medium"
                      style={{ color: "rgb(11, 36, 60)" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              {formatStatus() === "Conversation en attente" && (
                <Grid item display="grid">
                  <Tooltip title="Modifier">
                    <IconButton
                      onClick={() => {
                        const date = new Date(
                          filteredConversation?.dateTrigger
                        );
                        const dd = String(date.getDate()).padStart(2, "0");
                        const mm = String(date.getMonth() + 1).padStart(2, "0");
                        const yyyy = date.getFullYear();
                        let hours = date.getHours().toString();
                        let minutes = date.getMinutes().toString();

                        if (hours.length === 1) hours = `0${hours}`;
                        if (minutes.length === 1) minutes = `0${minutes}`;

                        setIsEditOpen(true);
                        setNewDate(`${yyyy}-${mm}-${dd}`);
                        setNewHour(`${hours}:${minutes}`);
                        setScheduleId(filteredConversation?.chatbotScheduleId);
                      }}
                      style={{
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <EditRounded
                        fontSize="small"
                        style={{ color: "#0B243C" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          )}
          {filteredConversation?.isArchived && !isArchiveLoading && (
            <Grid
              item
              display="grid"
              alignItems="center"
              justifyContent="center"
            >
              <Tooltip title="Désarchiver">
                <IconButton
                  onClick={() => {
                    setIsArchiveLoading(true);
                    makeAPIRequest(
                      "post",
                      `/chatbot/schedule/${filteredConversation?.chatbotScheduleId}/unarchive`,
                      null,
                      "v3"
                    )
                      .then((res) => {
                        setIsArchiveLoading(false);
                        const conversationsTmp = [...conversations];

                        const ind = conversationsTmp?.findIndex(
                          (r) =>
                            r?.chatbotScheduleId ===
                            filteredConversation?.chatbotScheduleId
                        );
                        if (ind !== -1) {
                          conversationsTmp[ind].isArchived = false;
                          setConversations(conversationsTmp);
                        }
                        dispatch(
                          openSnackbar({
                            duration: 3000,
                            message: "La conversation a bien été désarchivée",
                            type: "success",
                          })
                        );
                      })
                      .catch((err) => {
                        setIsArchiveLoading(false);
                        dispatch(
                          openSnackbar({
                            duration: 3000,
                            message:
                              "Impossible de désarchiver cette conversation",
                            type: "error",
                          })
                        );
                      });
                  }}
                  style={{ margin: "0", padding: "0" }}
                >
                  <UnarchiveRounded
                    fontSize="medium"
                    style={{ color: "rgb(11, 36, 60)" }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

interface IDisplayHowManyConversations {
  filteredConversations: Array<IChatbotSchedule>;
}

const DisplayHowManyConversations = (props: IDisplayHowManyConversations) => {
  const { filteredConversations } = props;

  const nbConversation = filteredConversations.length;

  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      {!nbConversation || nbConversation === 0
        ? "Aucune conversation trouvée"
        : `${nbConversation} conversation${
            nbConversation > 1 ? "s" : ""
          } trouvée${nbConversation > 1 ? "s" : ""}`}
    </span>
  );
};

const isValidSubmit = () => {
  return true;
};

export const getPatientPhoneById = (id, patientList) => {
  if (!id) return "";
  const patient = patientList?.find((e) => e?.id === id);
  return patient?.phone;
};

export const getPatientNameById = (id, patientList) => {
  if (!id) return "Anonyme";
  const patient = patientList?.find((e) => e?.id === id);
  return patient?.lastname + " " + patient?.firstname;
};

const exportConversationsCsv = (conversations, patientList, chatbot) => {
  const lines = [];
  lines.push(["Date", "Nom du patient", "Numéro de téléphone"]);
  for (const conversation of conversations) {
    for (const question of conversation.questions) {
      if (!lines[0].includes(question)) {
        lines[0].push(`${question}`);
      }
    }
  }

  for (const conversation of conversations) {
    if (conversation.responses.length === 0) continue;
    lines.push([]);
    const index = lines.length - 1;
    for (let i = 0; i < lines[0].length; i++) {
      lines[index].push("");
    }
    let lineIndex = 3;
    lines[index][0] = formatDateFromTimestamp(conversation?.dateTrigger);
    lines[index][1] = getPatientNameById(conversation?.patient, patientList);
    lines[index][2] = conversation?.phone
      ? conversation.phone
      : getPatientPhoneById(conversation?.patient, patientList);
    for (const response of conversation.responses) {
      lines[index][lineIndex] = `${response ? response : ""}`;
      lineIndex += 1;
    }
  }

  let csv = "";
  for (let i = 0; i < lines.length; i++) {
    for (let j = 0; j < lines[i].length; j++) {
      csv += `"${lines[i][j]}"`;
      if (j === lines[i].length - 1) {
        csv += "\n";
      } else {
        csv += ";";
      }
    }
  }

  const universalBOM = "\uFEFF";
  const element = window.document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + csv)
  );
  element.setAttribute(
    "download",
    `${
      chatbot.title ? chatbot.title : "questionnaire-interactif"
    }-conversations.csv`.replaceAll(" ", "-")
  );
  window.document.body.appendChild(element);
  element.click();

  return csv;
};

export const exportPdf = (
  conversations,
  patientList,
  chatbot,
  type: "download" | "open" | "get-base64" = "download"
) => {
  const lines = [];
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let myContent = [
    {
      text: "Conversations du questionnaire interactif",
      style: "header",
    } as any,
  ];

  lines.push(["Date", "Nom du patient"]);
  for (const conversation of conversations) {
    for (const question of conversation.questions) {
      if (!lines[0].includes(question)) {
        lines[0].push(`${question}`);
      }
    }
  }

  for (const conversation of conversations) {
    // if (conversation.responses.length === 0) continue; // Activer si on veut complétement enlever les conversations pas encore commencé (à la place de dire Aucune conversation)
    lines.push([]);
    const index = lines.length - 1;
    for (let i = 0; i < lines[0].length; i++) {
      lines[index].push("");
    }
    let lineIndex = 3;
    lines[index][0] = formatDateWithParameter(
      conversation?.dateTrigger,
      conversation.chatbotStatus
    );
    lines[index][1] = getPatientNameById(conversation?.patient, patientList);
    lines[index][2] = formatStatusWithParameter(conversation.chatbotStatus);
    for (const response of conversation.responses) {
      lines[index][lineIndex] = `${response ? response : ""}`;
      lineIndex += 1;
    }
  }

  // Affichage des réponses du questionnaire
  myContent.push({ text: "\n\n\n" });
  for (let number = 1; number < lines.length; number++) {
    // Boucle différentes réponses
    let verif = 0;
    myContent.push({
      text: `${
        lines[number][1].trim() +
        "\n" +
        lines[number][2].trim() +
        "\n" +
        lines[number][0].trim()
      }`,
      style: "nameDate",
    });
    for (let i = 2; lines[0][i] != null; i++) {
      // boucle différentes questions / réponses
      if (lines[number][i]) {
        verif = 1;
        myContent.push({
          text: `${"\n" + lines[0][i].trim() + " : " + lines[number][i]}`,
          style: "normalText",
        });
      }
    }
    if (verif === 0) {
      myContent.push({ text: "\n\nAucune réponse", style: "normalText" });
    }
    if (number != lines.length - 1)
      // Changer de page à chaque fois sauf pour le dernier
      myContent.push({ text: "", style: "normalText", pageBreak: "after" });
  }

  const myStyles = {
    header: {
      fontSize: 20,
      bold: true,
      alignment: "center",
      decoration: "underline",
    },
    nameDate: {
      fontSize: 15,
      bold: true,
      alignment: "right",
    },
    normalText: {
      fontSize: 15,
    },
  };

  const dataContent = {
    pageSize: "A4",
    pageMargins: [40, 40, 40, 40],
    footer: (currentPage, pageCount) => {
      return {
        table: {
          body: [
            [
              {
                text: "Page " + currentPage.toString() + " sur " + pageCount,
                style: "normalText",
                alignment: "right",
                margin: [pageCount > 10 ? 470 : 490, 0, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
      };
    },
    content: myContent,
    styles: myStyles,
  };

  const name = `${chatbot?.title}-conversations`.replaceAll(" ", "-");
  if (type === "download") {
    pdfMake.createPdf(dataContent).download(name);
  }
  if (type === "open") {
    pdfMake.createPdf(dataContent).open();
  }
  if (type === "get-base64") {
    return {
      pdfGenerated: pdfMake.createPdf(dataContent),
      name: name,
    };
  }
};

const DisplayChatbotConversations: React.FC<any> = (props) => {
  const { conversations, chatbot, patientList } = props;

  // Pages
  const [responsesPerPage, setResponsesPerPage] = React.useState(5);
  const [actualPage, setActualPage] = React.useState(0);
  const [pages, setPages] = React.useState([]);

  const [statusFilter, setStatusFilter] = React.useState(null);
  const [patientSearched, setPatientSearched] = React.useState(null);
  const [sortingByRecent, setSortingByRecent] = React.useState(true);
  const [displayArchived, setDisplayArchived] = React.useState(false);
  const [filteredConversations, setFilteredConversations] = React.useState([]);
  const [isExport, setIsExport] = React.useState(false);
  const [type, setType] = React.useState<"csv" | "pdf">("csv");
  const patientIdQuery = useQuery().get("p");
  const stateQuery = useQuery().get("state");

  React.useEffect(() => {
    if (!patientIdQuery) {
      return;
    }
    const patientsOpts = getPatientsOpts();
    const founded = patientsOpts.find((x) => x?.value == patientIdQuery);
    if (founded) {
      setPatientSearched(founded);
    }
  }, [patientIdQuery, patientList, conversations]);

  React.useEffect(() => {
    if (!stateQuery) {
      return;
    }
    const options = getStatusOptions();
    const founded = options.find((x) => x?.value == stateQuery);
    if (founded) {
      setStatusFilter(founded);
    }
  }, [stateQuery]);

  React.useEffect(() => {
    const getScheduledDate = (scheduled) => {
      if (
        ["cancelled", "completed"].includes(scheduled?.chatbotStatus) &&
        scheduled?.lastUpdate != 0
      )
        return scheduled?.lastUpdate;
      return scheduled?.dateTrigger;
    };
    const newFilteredConversations = [...conversations];
    setFilteredConversations(
      newFilteredConversations
        ?.filter((conv) =>
          statusFilter?.value
            ? conv.chatbotStatus === statusFilter?.value
            : true
        )
        ?.filter((conv) =>
          displayArchived ? conv.isArchived : !conv?.isArchived
        )
        ?.filter((conv) =>
          patientSearched?.value
            ? conv.patient === patientSearched?.value
            : true
        )
        ?.sort((a, b) =>
          sortingByRecent
            ? getScheduledDate(b) - getScheduledDate(a)
            : getScheduledDate(a) - getScheduledDate(b)
        )
    );
  }, [
    conversations,
    sortingByRecent,
    displayArchived,
    statusFilter,
    patientSearched,
  ]);

  // Update pages after responses filtered change
  React.useEffect(() => {
    const newPages = [];
    let index = 0;
    for (const message of filteredConversations) {
      if (index % responsesPerPage === 0) {
        newPages.push([]);
      }
      const lastIndex = newPages.length - 1;
      if (newPages[lastIndex]) {
        newPages[lastIndex].push(message);
      }
      index++;
    }
    setPages(newPages);
  }, [filteredConversations, responsesPerPage]);

  // Update actual page after pages change
  React.useEffect(() => {
    if (actualPage === 0) return;
    if (actualPage > pages.length - 1) {
      setActualPage(pages.length - 1);
      return;
    }
    if (actualPage < 0) {
      setActualPage(0);
      return;
    }
  }, [actualPage, pages]);

  const getStatusOptions = () => {
    const optionsTmp = [
      {
        value: "in progress",
        label: "En cours",
      },
      {
        value: "completed",
        label: "Terminée",
      },
      {
        value: "pending",
        label: "En attente",
      },
      {
        value: "cancelled",
        label: "Annulée",
      },
    ];
    return optionsTmp;
  };
  const getPatientsOpts = () => {
    const optionsTmp = [];
    if (!patientList) return optionsTmp;
    for (const patient of patientList) {
      if (conversations?.find((r) => r?.patient === patient?.id)) {
        optionsTmp.push({
          value: patient?.id,
          label: patient?.lastname + " " + patient?.firstname,
        });
      }
    }
    return optionsTmp;
  };

  return (
    <>
      {isExport && (
        <FormPopup>
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle={"Exporter"}
              secondTitle={"les conversations"}
              onClose={() => {
                setIsExport(false);
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              paddingBottom="50px"
              wrap="nowrap"
            >
              <Grid item display="grid">
                <FullField isMandatory={false} title="Format du fichier">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    spacing="10px"
                  >
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        spacing="15px"
                        paddingTop="10px"
                      >
                        <Grid item display="grid">
                          <input
                            type="radio"
                            className="checkbox_type"
                            onChange={() => {
                              return null;
                            }}
                            onClick={() => {
                              setType("csv");
                            }}
                            checked={type === "csv"}
                          />
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#0B243C",
                              fontFamily: "Poppins",
                            }}
                          >
                            {" "}
                            CSV
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        spacing="15px"
                      >
                        <Grid item display="grid">
                          <input
                            type="radio"
                            className="checkbox_type"
                            onChange={() => {
                              return null;
                            }}
                            onClick={() => {
                              setType("pdf");
                            }}
                            checked={type === "pdf"}
                          />
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#0B243C",
                              fontFamily: "Poppins",
                            }}
                          >
                            {" "}
                            PDF
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      paddingTop="30px"
                    >
                      <Grid item display="grid">
                        <button
                          className="button_modals_class grey_button"
                          style={{
                            border: "none",
                            boxShadow: "none",
                            margin: "0 0 0 0",
                          }}
                          onClick={() => {
                            setType("csv");
                            setIsExport(false);
                          }}
                        >
                          Annuler
                        </button>
                      </Grid>
                      <Grid item display="grid">
                        {isValidSubmit() ? (
                          <div style={{ marginLeft: "25px" }}>
                            <OrangeButton
                              animation={true}
                              enable={true}
                              type="submit"
                              text={`Exporter le ${type.toUpperCase()}`}
                              onClick={async () => {
                                if (type === "csv") {
                                  exportConversationsCsv(
                                    conversations,
                                    patientList,
                                    chatbot
                                  );
                                }
                                if (type === "pdf") {
                                  exportPdf(
                                    conversations,
                                    patientList,
                                    chatbot
                                  );
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div style={{ marginLeft: "25px" }}>
                            <OrangeButton
                              animation={true}
                              enable={false}
                              type="submit"
                              text="Exporter"
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </FullField>
              </Grid>
            </Grid>
          </Grid>
        </FormPopup>
      )}
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid">
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              color: "#0B243C",
              fontWeight: "bold",
            }}
          >
            {chatbot?.title}
          </span>
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <Grid
            container
            direction="row"
            alignItems="center"
            rowSpacing="10px"
            columnSpacing="20px"
          >
            <Grid item display="grid">
              <FullField
                whiteSpace="nowrap"
                title="Filtrer :"
                isMandatory={false}
              >
                <></>
              </FullField>
            </Grid>
            <Grid item display="grid">
              <MySelect
                options={getPatientsOpts()}
                noOptionsMessage={() => "Aucun résultat"}
                value={!patientSearched?.value ? null : patientSearched}
                placeholder="Patient"
                onChange={(e) => {
                  setPatientSearched(e);
                }}
              />
            </Grid>
            <Grid item display="grid">
              <MySelect
                options={getStatusOptions()}
                noOptionsMessage={() => "Aucun résultat"}
                value={!statusFilter?.value ? null : statusFilter}
                placeholder="Avancement de la conversation"
                onChange={(e) => {
                  setStatusFilter(e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <OrangeCheckBox
            value={sortingByRecent}
            onClick={() => setSortingByRecent(!sortingByRecent)}
            text="Trier les conversations par date"
          />
        </Grid>
        <Grid item display="grid" marginTop="5px">
          <OrangeCheckBox
            value={displayArchived}
            onClick={() => setDisplayArchived(!displayArchived)}
            text="Afficher uniquement les conversations archivées"
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          paddingTop="15px"
        >
          <Grid item display="grid">
            <DisplayHowManyConversations
              filteredConversations={filteredConversations}
            />
          </Grid>
          {filteredConversations?.length > 0 && (
            <Grid item display="grid">
              <button
                onClick={() => {
                  setIsExport(true);
                  return;
                }}
                style={{ margin: "0", padding: "0" }}
                className="clear-text-button"
              >
                Exporter les conversations filtrées
              </button>
            </Grid>
          )}
        </Grid>
        <Grid item display="grid" marginTop="15px">
          <Grid container direction="column" wrap="nowrap" spacing="10px">
            {pages?.[actualPage] &&
              pages?.[actualPage]?.map((filteredConversation, index) => {
                return (
                  <Grid item key={filteredConversation?.id} display="grid">
                    <DisplayChatbotConversation
                      conversationIndex={index}
                      filteredConversation={filteredConversation}
                      {...props}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        {pages?.length > 0 && (
          <Grid item display="grid" paddingTop="47px">
            <Grid
              container
              direction="row"
              spacing="10px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="grid">
                <ConfigResponsePerPage
                  responsesPerPage={responsesPerPage}
                  setResponsesPerPage={setResponsesPerPage}
                  {...props}
                />
              </Grid>
              <Grid item display="grid">
                <ResponseNavigation
                  actualPage={actualPage}
                  setActualPage={setActualPage}
                  pages={pages}
                  {...props}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DisplayChatbotConversations;
