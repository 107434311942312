import { createSlice } from '@reduxjs/toolkit';

export const createAdviceSlice = createSlice({
  name: 'createAdvice',
  initialState: {
    actualAdvice: null,
    actualAdviceNotEdited: null,
    isAdviceOpen: false,
    isExitPopup: false,
    addToFavorites: true,
    isSubmittedPopup: false,
    isDuplicate: false,
    isImportAdviceOpen: false,
    beforeSubmittedPopupLoading: false,
    adviceOpenIndex: -1,
  },
  reducers: {
    openAdvice: (state, action = null) => {
      if (action?.payload?.actualAdvice) {
        state.isDuplicate = true;
        state.actualAdviceNotEdited = action.payload.actualAdvice;
        state.actualAdvice = action.payload.actualAdvice;
      } else {
        state.isDuplicate = false;
        state.actualAdvice = {
          title: '',
          add_to_favourites: true,
          type: 'simple',
          speciality_id: null,
          messages: [
            [{
              content: '',
              url: '',
              hours: 0,
              short_url: 1,
              stop_sms: 1,
              attachment_name: '',
              attachment: '',
            }]
          ],
        }
      }
      state.isAdviceOpen = true;
    },
    openImportAdvice: (state, action = null) => {
      if (action?.payload != undefined) state.adviceOpenIndex = action.payload;
      state.isImportAdviceOpen = true;
    },
    closeImportAdvice: (state) => {
      state.isImportAdviceOpen = false;
      state.adviceOpenIndex = -1;
    },
    closeAdvice: (state) => {
      state.actualAdvice = null;
      state.actualAdviceNotEdited = null;
      state.isAdviceOpen = false;
      state.addToFavorites = true;
      state.isExitPopup = false;
      state.isSubmittedPopup = false;
      state.isDuplicate = false;
      state.isImportAdviceOpen = false;
      state.adviceOpenIndex = -1;
    },
    setPackType: (state, action) => {
      const isPack = action.payload;
      state.actualAdvice.type = isPack ? 'pack' : 'simple';
    },
    setTitle: (state, action) => {
      const title = action.payload;
      state.actualAdvice.title = title;
    },
    setContent: (state, action) => {
      const { index, content } = action.payload;
      state.actualAdvice.messages[0][index].content = content;
    },
    setSpeciality: (state, action) => {
      const speciality_id = action.payload;
      state.actualAdvice.speciality_id = speciality_id;
    },
    deleteAdvice: (state, action) => {
      const index = action.payload;
      state.actualAdvice?.messages?.[0]?.splice(index, 1);
    },
    setUrl: (state, action) => {
      const { index, url } = action.payload;
      state.actualAdvice.messages[0][index].url = url;
    },
    setHours: (state, action) => {
      const { index, hours } = action.payload;
      state.actualAdvice.messages[0][index].hours = hours;
    },
    deleteAttachFileAndName: (state, action) => {
      const { index } = action.payload;
      state.actualAdvice.messages[0][index].attachment = null;
      state.actualAdvice.messages[0][index].attachment_name = null;
    },
    setQuestionnaireInteractif: (state, action) => {
      const { label, value, index } = action.payload;
      state.actualAdvice.messages[0][index].questionnaire_interactif_id = value ? value : undefined;
      state.actualAdvice.messages[0][index].questionnaire_interactif_title = label ? label : undefined;
    },
    setShortUrl: (state, action) => {
      const { value, index } = action.payload;
      state.actualAdvice.messages[0][index].short_url = (value == 1 ? 1 : 0);
    },
    setStopSMS: (state, action) => {
      const { value, index } = action.payload;
      state.actualAdvice.messages[0][index].stop_sms = (value == 1 ? 1 : 0);
    },
    setAttachment: (state, action) => {
      const { attachment, attachment_name, index } = action.payload;
      state.actualAdvice.messages[0][index].attachment = attachment;
      state.actualAdvice.messages[0][index].attachment_name = attachment_name;
    },
    setFavorite: (state, action) => {
      state.addToFavorites = action.payload;
    },
    setMarge: (state, action) => {
      state.actualAdvice.marge = action.payload;
    },
    setDisplayName(state, action) {
      state.actualAdvice.display_name = action.payload;
    },
    addAdvice: (state, action = null) => {
      if (action?.payload?.messages?.[0]) {
        if (state.adviceOpenIndex !== -1) {
          const copyMessages = [...action.payload.messages[0]];
          const message = copyMessages?.[0];
          if (message) {
            state.actualAdvice.messages[0][state.adviceOpenIndex] = {
              content: message?.content,
              hours: message?.hours,
              url: message?.url,
              short_url: message?.short_url,
              stop_sms: message?.stop_sms,
              attachment: message?.attachment,
              attachment_name: message?.attachment_name,
            };
          }
        } else {
          const copyMessages = [...action.payload.messages[0]];
          for (const message of copyMessages) {
            state.actualAdvice.messages[0].push({
              content: message?.content,
              hours: message?.hours,
              url: message?.url,
              short_url: message?.short_url,
              stop_sms: message?.stop_sms,
              attachment: message?.attachment,
              attachment_name: message?.attachment_name,
            });
          }
        }
      } else {
        state.actualAdvice.messages[0].push({
          attachment: null,
          attachment_name: null,
          url: '',
          short_url: 1,
          stop_sms: 1,
          hours: 0,
          content: '',
        })
      }
    },
    setIsExitPopup: (state, action) => {
      state.isExitPopup = action.payload;
    },
    setIsSubmittedPopup: (state, action) => {
      state.isSubmittedPopup = action.payload;
    },
    setBeforeSubmittedPopupLoading: (state, action) => {
      state.beforeSubmittedPopupLoading = action.payload;
    },
  }
})

export const {
  openAdvice,
  closeAdvice,
  setPackType,
  setTitle,
  setSpeciality,
  setContent,
  setUrl,
  addAdvice,
  setFavorite,
  setMarge,
  setDisplayName,
  setHours,
  setAttachment,
  setShortUrl,
  deleteAdvice,
  deleteAttachFileAndName,
  setStopSMS,
  setQuestionnaireInteractif,
  setIsExitPopup,
  setIsSubmittedPopup,
  openImportAdvice,
  closeImportAdvice,
  setBeforeSubmittedPopupLoading,
} = createAdviceSlice.actions

export default createAdviceSlice.reducer
