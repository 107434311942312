import { Grid } from "@mui/material";
import * as React from 'react';
import DisplayError from './DisplayError';

interface IHandleErrors {
  errorMessage: string,
  children?: any,
  isError?: boolean,
  isMandatory?: boolean,
}

const HandleErrors = (props: IHandleErrors) => {
  const {
    errorMessage,
    children,
    isError,
    isMandatory,
  } = props;

  return (
    <Grid container direction='column' wrap='nowrap' gap='5px'>
      <Grid item display='grid'>
        {children}
      </Grid>
      {isError && isMandatory &&
        <Grid item display='grid'>
          <DisplayError errorMessage={errorMessage} />
        </Grid>
      }
    </Grid>
  )
};

export default HandleErrors;
