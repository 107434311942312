import ReactGA from 'react-ga';
import IAdvicesSend from '../types/IAdvicesSend';
import UserClass from './UserClass';

class GoogleAnalyticsClass {
  trackingId?: string;
  options?: { debug: boolean, titleCase: boolean };
  user?: UserClass;

  constructor(user?: UserClass) {
    this.user = user;
    this.trackingId = process.env.REACT_APP_GA_TRACKING_ID;
    this.options = {
      debug: true,
      titleCase: false,
    }
  }

  public updateUserId() {
    if (this.user?.isConnected()) {
      ReactGA.set({
        'userId': this.user.getLogin(),
      });
    } else {
      ReactGA.set({
        'userId': null,
      });
    }
  }

  public initialize() {
    this.updateUserId();
    ReactGA.initialize(this.trackingId, this.options);
  }

  public visitPage(pageName: string) {
    this.updateUserId();
    ReactGA.pageview(pageName);
  }

  public duplicateAdviceEvent(type: string, title: string) {
    if (type && title && title?.length > 0) {
      ReactGA.event({
        category: 'Conseils',
        action: `Duplication d'un ${type === 'simple' ? 'conseil' : 'pack de conseils'}`,
        label: `${title}`,
      });
    }
  }

  public createAdviceEvent(type: string, title: string) {
    if (type && title && title?.length > 0) {
      ReactGA.event({
        category: 'Conseils',
        action: `Création d'un ${type === 'simple' ? 'conseil' : 'pack de conseils'}`,
        label: `${title}`,
      });
    }
  }

  public editAdviceEvent(type: string, title: string) {
    if (type && title && title?.length > 0) {
      ReactGA.event({
        category: 'Conseils',
        action: `Modification d'un ${type === 'simple' ? 'conseil' : 'pack de conseils'}`,
        label: `${title}`,
      });
    }
  }

  public updatePatientEvent(firstname: string, lastname: string) {
    if (firstname && lastname) {
      ReactGA.event({
        category: 'Patients',
        action: `Modification d'un patient`,
        label: `${firstname} ${lastname}`,
      });
    }
  }

  public createPatientEvent(firstname: string, lastname: string) {
    if (firstname && lastname) {
      ReactGA.event({
        category: 'Patients',
        action: `Modification d'un patient`,
        label: `${firstname} ${lastname}`,
      });
    }
  }

  public sendAdvicesEvent(advices: IAdvicesSend) {
    this.updateUserId();
    let label = '';
    const advicesCount = advices.conseil_ids?.length;

    if (advices.hours === "0") {
      if (advicesCount === 1) {
        label = `Envoi d'un conseil au ${advices.target}`;
      } else if (advicesCount > 1) {
        label = `Envoi de ${advicesCount} conseils au ${advices.target}`;
      } else if (advices.freetext != "") {
        label = `Envoi d'un conseil personnalisé au ${advices.target}`;
      }
    } else {
      if (advicesCount === 1) {
        label = `Programmation d'un conseil au ${advices.target} pour dans ${advices.hours}h`;
      } else if (advicesCount > 1) {
        label = `Programmation de ${advicesCount} conseils au ${advices.target} pour dans ${advices.hours}h`;
      } else if (advices.freetext != "") {
        label = `Programmation d'un conseil personnalisé au ${advices.target} pour dans ${advices.hours}h`;
      }
    }
    ReactGA.event({
      category: 'Conseils',
      action: `Envois & programmations d'SMS`,
      label: label,
    });
  }
}

export default GoogleAnalyticsClass;