import { ArrowDropDownCircleOutlined, AttachmentOutlined, BusinessOutlined, CalculateOutlined, CalendarMonthOutlined, CheckBoxOutlined, DirectionsRunOutlined, FormatAlignLeftOutlined, MedicationOutlined, MenuBook, PersonOutlined, RadioButtonCheckedOutlined, ShortTextOutlined, StarHalfOutlined, TextSnippetOutlined, ViewListOutlined } from "@mui/icons-material";

const typeOptions = [
  {
    value: 'text',
    label: 'Réponse courte',
    icon: ShortTextOutlined,
  },
  {
    value: 'long_text',
    label: 'Paragraphe',
    icon: FormatAlignLeftOutlined,
  },
  {
    value: 'simple_check',
    label: 'Cases à cocher',
    icon: CheckBoxOutlined,
  },
  {
    value: 'unique_choice',
    label: 'Choix unique',
    icon: RadioButtonCheckedOutlined,
  },
  {
    value: 'liste_deroulante',
    label: 'Liste déroulante',
    icon: ArrowDropDownCircleOutlined,
  },
  {
    value: 'date',
    label: 'Date',
    icon: CalendarMonthOutlined,
  },
  {
    value: 'echelle_notation',
    label: 'Échelle de notation',
    icon: StarHalfOutlined,
  },
  {
    value: 'addition_auto',
    label: 'Addition automatique',
    icon: CalculateOutlined,
  },
  {
    value: 'display_text_and_image',
    label: 'Textes et images personnalisés',
    icon: TextSnippetOutlined,
  },
  {
    value: 'medecins_traitants',
    label: 'Médecins traitants',
    icon: MedicationOutlined,
  },
  {
    value: 'annuaire_mssante',
    label: 'Annuaire MSSanté',
    icon: MenuBook,
  },
  {
    value: 'attribut_patient',
    label: 'Attribut patient',
    icon: PersonOutlined,
  },
  {
    value: 'medecin_delegant',
    label: 'Médecins délégants',
    icon: MedicationOutlined,
  },
  {
    value: 'structure',
    label: 'Structures',
    icon: BusinessOutlined,
  },
  {
    value: 'protocole',
    label: 'Protocoles',
    icon: ViewListOutlined,
  },
  {
    value: 'date_sortie',
    label: 'Date de sortie',
    icon: DirectionsRunOutlined,
  },
  {
    value: 'piece_jointe',
    label: 'Pièce jointe',
    icon: AttachmentOutlined,
  },
];

export default typeOptions;