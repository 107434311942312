import GroupCheckBoxField from "../CheckBox/GroupCheckBoxField";
import * as React from 'react';

interface IPatientGenderField {
  patientGender: string,
  onChange: any,
}

const PatientGenderField = (props: IPatientGenderField) => {
  const {
    patientGender,
    onChange,
  } = props;

  return (
    <GroupCheckBoxField
      title='Genre'
      isMandatory={true}
      listCheckbox={
        [
          {
            text: 'Homme',
            value: patientGender === 'male',
            onClick: () => onChange('male'),
          },
          {
            text: 'Femme',
            value: patientGender === 'female',
            onClick: () => onChange('female'),
          },
          {
            text: 'NSP',
            value: patientGender === '' || !patientGender,
            onClick: () => onChange(''),
          },
        ]
      } />
  );
};

export default PatientGenderField;