import { CircularProgress, Grid } from "@mui/material";
import { nanoid } from "nanoid";
import * as React from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/snackbarReducer";
import useQuery from "../../../utils/hooks/useQuery";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import resizeText from "../../../utils/resizeText";
import OrangeButton from "../../Library/Buttons/OrangeButton/OrangeButton";
import ErrorHelp from "../../Library/Errors/ErrorHelp";
import { calcValueAdditionAuto } from "./Fields/DisplayAdditionAuto";
import {
  Delegue,
  IForm,
  MedecinDelegant,
  Protocole,
  Structure,
} from "../../../types/FormTypes";
import {
  getOnlyFieldWithGoodCondition,
  getOnlySectionWithGoodCondition,
  isValidConditionResponse,
} from "./GenerateQuestionnaire";
import {
  getActualTime,
  getActualTimeFromYYYYMMDD,
} from "../../NavbarComponent";
import isValidPhoneNumber from "../../../utils/isValidPhoneNumber";
import { getFormatedResponse } from "../SendResponseMedimailPopup";

const formatValue = (value) => {
  if (!value) {
    return [""];
  }
  if (["string", "number"].includes(typeof value)) return [value];
  if (Array.isArray(value)) return value;
  if (typeof value === "object") return Object.keys(value);
  return [];
};

export interface FieldsSavedInCache {
  formId: string;
  fields: Array<{
    fieldId: string;
    value: string;
  }>;
}

export const formatResponse = (
  questionnaireData: Partial<IForm>,
  patientUrl: any,
  exclusion = null,
  comment = "",
  saveInCacheIfNecessary = false,
  cleanNotDisplayedField = false
) => {
  const response = {
    id: nanoid(6),
    patientUrl: patientUrl,
    questionnaireId: questionnaireData?.id,
    fields: [],
    comment: comment,
    isPatientConsentant: questionnaireData?.isPatientConsentant ? true : false,
  };

  for (const section of questionnaireData?.sections) {
    for (const fieldRow of section?.fields) {
      for (const field of fieldRow) {
        if (field?.type === "addition_auto") {
          response.fields.push({
            id: field?.id,
            sectionTitle: section?.title,
            fieldTitle: field?.title,
            value: calcValueAdditionAuto(questionnaireData, field?.option),
            isExclusion: exclusion === field?.id ? true : false,
            fieldType: field?.type,
          });
        } else if (field?.type === "structure") {
          const structure: Structure =
            field?.structureInfos?.structuresList?.find(
              (x) => x?.id == field?.value?.[0]
            );
          if (structure) {
            if (structure.nom) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle: "Nom de la structure",
                value: formatValue(structure.nom),
                isExclusion: exclusion === field?.id ? true : false,
                fieldType: field?.type,
              });
            }
            if (structure.AM) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle: "N° AM de la structure",
                value: formatValue(structure.AM),
                isExclusion: exclusion === field?.id ? true : false,
                fieldType: field?.type,
              });
            }
            if (structure.coordonnees) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle: "Coordonnées de la structure",
                value: formatValue(structure.coordonnees),
                isExclusion: exclusion === field?.id ? true : false,
                fieldType: field?.type,
              });
            }
            if (field?.value?.[1]) {
              const delegue: Delegue = structure.deleguesList
                ? structure?.deleguesList?.find((x) => x?.id == field.value[1])
                : undefined;
              if (delegue) {
                if (delegue.nomPrenom) {
                  response.fields.push({
                    id: field?.id,
                    sectionTitle: section?.title,
                    fieldTitle: "Nom et prénom du délégué",
                    value: formatValue(delegue.nomPrenom),
                    isExclusion: exclusion === field?.id ? true : false,
                    fieldType: field?.type,
                  });
                }
                if (delegue.RPPS) {
                  response.fields.push({
                    id: field?.id,
                    sectionTitle: section?.title,
                    fieldTitle: "RPPS du délégué",
                    value: formatValue(delegue.RPPS),
                    isExclusion: exclusion === field?.id ? true : false,
                    fieldType: field?.type,
                  });
                }
              }
            }
            if (field?.value?.[2]) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle:
                  "Date de déclaration à l'ARS du protocole concerné ce jour",
                value: formatValue(field.value[2]),
                isExclusion: exclusion === field?.id ? true : false,
                fieldType: field?.type,
              });
            }
          }
        } else if (field?.type === "protocole") {
          const founded: Protocole =
            field?.protocoleInfos?.protocolesList?.find(
              (x) => x?.id == field?.value
            );
          if (founded) {
            if (founded.nature) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle: "Nature du protocole concerné",
                value: formatValue(founded.nature),
                isExclusion: exclusion === field?.id ? true : false,
                fieldType: field?.type,
              });
            }
            /*
            if (founded.dateDeclarationARS) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle:
                  "Date de déclaration à l'ARS du protocole concerné ce jour",
                value: formatValue(founded.dateDeclarationARS),
                isExclusion: exclusion === field?.id ? true : false,
              });
            }
            */
          }
        } else if (field?.type === "medecin_delegant") {
          const founded: MedecinDelegant =
            field?.medecinDelegantInfos?.medecinsDelegantList?.find(
              (x) => x?.id == field?.value
            );
          if (founded) {
            if (founded.NomPrenom) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle: "Nom et prénom du délégant",
                value: formatValue(founded.NomPrenom),
                isExclusion: exclusion === field?.id ? true : false,
                fieldType: field?.type,
              });
            }
            if (founded.AM) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle: "N° AM du délégant",
                value: formatValue(founded.AM),
                isExclusion: exclusion === field?.id ? true : false,
                fieldType: field?.type,
              });
            }
            if (founded.RPPS) {
              response.fields.push({
                id: field?.id,
                sectionTitle: section?.title,
                fieldTitle: "N° RPPS du délégant",
                value: formatValue(founded.RPPS),
                isExclusion: exclusion === field?.id ? true : false,
                fieldType: field?.type,
              });
            }
          }
        } else if (field?.type != "display_text_and_image") {
          if (field?.value) {
            response.fields.push({
              id: field?.id,
              sectionTitle: section?.title,
              fieldTitle: field?.title,
              value: formatValue(field?.value),
              isExclusion: exclusion === field?.id ? true : false,
              fieldType: field?.type,
            });
          }
        }
        // SAVE IN CACHE
        if (
          field?.saveResponse === true &&
          field?.value &&
          field?.id &&
          saveInCacheIfNecessary
        ) {
          const item: string = localStorage.getItem("formsFieldsSavedInCache");
          const formsFieldsSavedInCache: FieldsSavedInCache[] = item
            ? JSON.parse(item)
            : [];
          const foundedIndexForm = formsFieldsSavedInCache.findIndex(
            (x) => x?.formId == questionnaireData?.id
          );
          if (foundedIndexForm === -1) {
            formsFieldsSavedInCache.push({
              formId: questionnaireData?.id,
              fields: [
                {
                  fieldId: field?.id,
                  value: field?.value,
                },
              ],
            });
            //
          } else {
            const foundedIndexField = formsFieldsSavedInCache[
              foundedIndexForm
            ].fields.findIndex((x) => x?.fieldId == field?.id);
            if (foundedIndexField === -1) {
              formsFieldsSavedInCache[foundedIndexForm].fields.push({
                value: field?.value,
                fieldId: field?.id,
              });
            } else {
              formsFieldsSavedInCache[foundedIndexForm].fields[
                foundedIndexField
              ].value = field?.value;
            }
          }

          localStorage.setItem(
            "formsFieldsSavedInCache",
            JSON.stringify(formsFieldsSavedInCache)
          );
        }
      }
    }
  }

  if (cleanNotDisplayedField) {
    for (const section of questionnaireData?.sections) {
      for (const fieldRow of section?.fields) {
        for (const field of fieldRow) {
          if (field?.conditionsInfos?.conditionsList?.length > 0) {
            for (const condition of field.conditionsInfos.conditionsList) {
              const isValid = isValidConditionResponse(
                condition.conditions,
                response.fields,
                condition.conditionType,
                questionnaireData as any
              );
              if (!isValid) {
                response.fields = response.fields.filter(
                  (x) => x?.id !== field?.id
                );
              }
            }
          }
        }
      }
    }
  }
  return response;
};

export const isFutureDate = (date: string) => {
  if (!date) {
    return false;
  }
  const dateActuelle = getActualTime();

  if (dateActuelle) {
    const dateEvenement = getActualTimeFromYYYYMMDD(`${date}`);
    if (dateEvenement && dateActuelle < dateEvenement) {
      return true;
    }
  }
  return false;
};

const getInvalidConditions = (questionnaireData: IForm) => {
  const isInvalidSimpleCheckField = (field) => {
    if (!field?.validation?.type || !field?.validation?.number) return false;
    let nbChecked = 0;
    if (field?.value) {
      const values = Object.values(field?.value);
      for (const value of values) {
        if (value) {
          nbChecked++;
        }
      }
    }
    if (
      field?.validation?.type === "Exactement" &&
      nbChecked != field?.validation?.number
    )
      return true;
    if (
      field?.validation?.type === "Au minimum" &&
      field?.validation?.number > nbChecked
    )
      return true;
    if (
      field?.validation?.type === "Au maximum" &&
      field?.validation?.number < nbChecked
    )
      return true;
    return false;
  };
  const invalidConditions = {};
  const sections = getOnlySectionWithGoodCondition(questionnaireData).filter((x) => x != undefined);

  for (const section of sections) {
    for (const fieldRow of getOnlyFieldWithGoodCondition(
      section?.fields,
      questionnaireData
    )) {
      for (const field of fieldRow) {
        console.log(field?.value);

        if (field?.type === "simple_check") {
          invalidConditions["field" + field?.id + "bad-validation"] =
            field?.isMandatory && isInvalidSimpleCheckField(field);
        } else if (field?.type === "structure") {
          // Structure obligatoire
          invalidConditions["field" + field?.id + "structure"] =
            field?.isMandatory && !field?.value?.[0];

          // Délégué obligatoire
          invalidConditions["field" + field?.id + "delegue"] =
            field?.isMandatory && !field?.value?.[1];

          // Date de déclaration à l'ARS
          invalidConditions[field?.id + "delegue-date-input-empty"] =
            field?.isMandatory && !field?.value?.[2];
          invalidConditions[field?.id + "delegue-date-input-future"] =
            field?.value?.[2] && isFutureDate(field?.value?.[2]);
        } else if (
          field?.type === "attribut_patient" &&
          field?.attributPatientType === "phone"
        ) {
          invalidConditions["field" + field?.id + "phone-patient-empty"] =
            field?.isMandatory &&
            (field?.value == undefined || field?.value?.length === 0);
          invalidConditions["field" + field?.id + "phone-patient-invalid"] =
            field?.value && !isValidPhoneNumber(field?.value);
          //"field" + field?.id + "phone-patient"
        } else {
          invalidConditions["field" + field?.id + "empty"] =
            field?.isMandatory &&
            (field?.value == undefined || field?.value?.length === 0);
        }
      }
    }
  }
  return invalidConditions;
};

const getErrorSelectorAndErrorMessages = (questionnaireData: any) => {
  const errorSelector = { "": "" };
  const errorMessages = { "": "" };

  for (const section of questionnaireData?.sections) {
    for (const fieldRow of section?.fields) {
      for (const field of fieldRow) {
        if (field?.type === "simple_check") {
          errorSelector[
            "field" + field?.id + "bad-validation"
          ] = `#field${field?.id}`;
          errorMessages["field" + field?.id + "bad-validation"] = `${
            field?.title ? field?.title : "Sans titre"
          }\nVous devez sélectionner ${
            field?.validation?.type !== "Exactement"
              ? field?.validation?.type?.toLowerCase()
              : ""
          } ${field?.validation?.number} réponse${
            field?.validation?.number > 1 ? "s" : ""
          }`;
        } else if (field?.type === "structure") {
          errorSelector[
            "field" + field?.id + "structure"
          ] = `#field${field?.id}structure`;
          errorSelector[
            "field" + field?.id + "delegue"
          ] = `#field${field?.id}delegue`;
          errorMessages["field" + field?.id + "structure"] =
            "Les champs concernant la structure sont obligatoires.";
          errorMessages["field" + field?.id + "delegue"] =
            "Les champs concernant le délégué sont obligatoires.";

          errorSelector[
            field?.id + "delegue-date-input-empty"
          ] = `#${field?.id}delegue-date-input`;
          errorMessages[field?.id + "delegue-date-input-empty"] =
            "La date de déclaration à l'ARS du protocole est obligatoire.";

          errorSelector[
            field?.id + "delegue-date-input-future"
          ] = `#${field?.id}delegue-date-input`;
          errorMessages[field?.id + "delegue-date-input-future"] =
            "La date renseignée doit être postérieure à la date actuelle.";
        } else if (field?.type === "attribut_patient" && field?.attributPatientType === "phone") {
          
          errorMessages["field" + field?.id + "phone-patient-empty"] =
          "Le numéro de téléphone est obligatoire.";
          errorMessages["field" + field?.id + "phone-patient-invalid"] =
          "Le numéro de téléphone est invalide.";
          errorSelector[
            "field" + field?.id + "phone-patient-empty"
          ] = `#field${field?.id}phone-patient`;
          errorSelector[
            "field" + field?.id + "phone-patient-invalid"
          ] = `#field${field?.id}phone-patient`;
        } else {
          errorSelector["field" + field?.id + "empty"] = `#field${field?.id}`;
          errorMessages["field" + field?.id + "empty"] = field?.title
            ? field?.title?.length > 50
              ? `Le champ '${resizeText(field?.title, 50)}' est obligatoire`
              : `Le champ '${field?.title}' est obligatoire`
            : "Le champ est obligatoire";
        }
      }
    }
  }
  return {
    errorSelector: errorSelector,
    errorMessages: errorMessages,
  };
};

const DisplayButtons: React.FC<any> = (props) => {
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const patientUrl = useQuery().get("p");
  const dispatch = useDispatch();
  const { questionnaireData, isPreview, setIsSended, stopPopup, comment, setResponse } =
    props;

  const errorAndSelectedMessage =
    getErrorSelectorAndErrorMessages(questionnaireData);

  const errorMessages = errorAndSelectedMessage.errorMessages;
  const errorSelector = errorAndSelectedMessage.errorSelector;

  const isValidSubmit = () => {
    const invalidConditions = getInvalidConditions(questionnaireData);

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  if (isButtonLoading) {
    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        paddingTop="40px"
        justifyContent="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Grid container direction="column" wrap="nowrap" paddingTop="40px">
        {error?.length > 0 && (
          <Grid item display="grid" paddingBottom="5px">
            <ErrorHelp
              errorMessages={errorMessages}
              errorSelector={errorSelector}
              error={error}
            />
          </Grid>
        )}
        <Grid item display="grid" justifyContent="center">
          {!isPreview && isValidSubmit() ? (
            <OrangeButton
              animation={false}
              enable={true}
              type="submit"
              text={"Envoyer"}
              onClick={
                isPreview
                  ? () => {
                      return null;
                    }
                  : () => {
                      setIsButtonLoading(true);
                      const r = formatResponse(
                        questionnaireData,
                        patientUrl,
                        stopPopup?.id,
                        comment,
                        true,
                        true
                      );
                      makeAPIRequest(
                        "post",
                        "/questions/response/create",
                        r,
                        "v3"
                      )
                        .then((res) => {
                          setIsSended(true);
                          setResponse(getFormatedResponse(r));
                          setIsButtonLoading(false);
                        })
                        .catch((err) => {
                          setIsButtonLoading(false);
                          dispatch(
                            openSnackbar({
                              message: `Impossible d'envoyer votre réponse`,
                              type: "error",
                              duration: 3000,
                            })
                          );
                        });
                    }
              }
            />
          ) : (
            <OrangeButton
              animation={false}
              enable={false}
              type="submit"
              text={"Envoyer"}
              onClick={() => {
                return null;
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DisplayButtons;
