import * as React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import { closeSchedule } from "../../redux/scheduleChatbotReducer";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import ErrorHelp from "../Library/Errors/ErrorHelp";
import makeAPIRequest from "../../utils/makeAPIRequest";
import { openSnackbar } from "../../redux/snackbarReducer";
import { GenericDateDeclenchement, GenericPatientsChoice } from "../../pages/ChatbotLexPage";
import useAppSelector from "../../redux/useAppSelector";

const errorMessages = {
  emptyPatients: "Aucun patient n'est selectionné.",
  emptyDate: "La date de déclenchement n'est pas renseignée.",
  emptyTime: "L'heure de déclenchement n'est pas renseignée.",
  "": "",
};

const errorSelector = {
  emptyPatients: "#schedule-chatbot-list-patients",
  emptyDate: "#schedule-chatbot-date",
  emptyTime: "#schedule-chatbot-time",
  "": "",
};

const ConfigureSchedule: React.FC<any> = (props) => {
  const { reducer } = props;
  const [patientSearched, setPatientSearched] = React.useState(null);
  const [patientList, setPatientList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  let date = new Date();
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  const [dateInput, setDateInput] = React.useState(
    date.toISOString().split("T")[0]
  );
  const [timeInput, setTimeInput] = React.useState(
    date.toISOString().split("T")[1].substring(0, 5)
  );
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyPatients: !patientSearched || patientSearched?.length === 0,
      emptyDate: !dateInput,
      emptyTime: !timeInput,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  React.useEffect(() => {
    if (!isLoading) setIsLoading(true);
    makeAPIRequest("get", `/patients/list`, null, "v3").then((res: any) => {
      setPatientList(res?.data);
      setIsLoading(false);
    });
  }, []);

  const formatPatientsIds = () => {
    const result = [];
    for (const patient of patientSearched) {
      result.push(patient?.value);
    }
    return result;
  };

  if (isLoading) {
    return (
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <GenericPatientsChoice
          patientList={patientList}
          patientSearched={patientSearched}
          setPatientSearched={setPatientSearched}
          id={"schedule-chatbot-list-patients"}
        />
      </Grid>
      <Grid item display="grid" paddingTop="15px">
        <GenericDateDeclenchement
          dateInput={dateInput}
          setDateInput={setDateInput}
          setTimeInput={setTimeInput}
          timeInput={timeInput}
          idDate={"schedule-chatbot-date"}
          idTime={"schedule-chatbot-time"}
        />
      </Grid>
      <Grid container direction="column" paddingTop="30px" wrap="nowrap">
        {error?.length > 0 && (
          <Grid item display="grid">
            <ErrorHelp
              errorMessages={errorMessages}
              errorSelector={errorSelector}
              error={error}
            />
          </Grid>
        )}
        <Grid item display="grid" justifyContent="center">
          {isValidSubmit() ? (
            <OrangeButton
              animation={false}
              enable={true}
              type="submit"
              text={"Envoyer"}
              onClick={() => {
                const toSend = {
                  chatbotId: reducer?.chatbotId,
                  patients: formatPatientsIds(),
                  dateTrigger: Date.parse(dateInput + "T" + timeInput + ":00"),
                };
                makeAPIRequest("post", "/chatbot/schedule", toSend, "v3")
                  .then((res) => {
                    dispatch(
                      openSnackbar({
                        message:
                          "Votre questionnaire interactif a bien été programmé",
                        type: "success",
                        duration: 3000,
                      })
                    );
                    dispatch(closeSchedule());
                  })
                  .catch((err) => {
                    dispatch(
                      openSnackbar({
                        message:
                          "La programmation de votre questionnaire interactif a échouée",
                        type: "error",
                        duration: 3000,
                      })
                    );
                  });
                console.log(toSend);
              }}
            />
          ) : (
            <OrangeButton
              animation={false}
              enable={false}
              type="submit"
              text={"Envoyer"}
              onClick={() => {
                return null;
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const StartChatbot = () => {
  const reducer = useAppSelector((state) => state.scheduleChatbotReducer);
  const dispatch = useDispatch();

  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="40px"
      >
        <FormPopupHeader
          firstTitle={"Programmer"}
          secondTitle={"une conversation"}
          onClose={() => {
            dispatch(closeSchedule());
          }}
        />
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          display="flex"
          width="70%"
          justifySelf="center"
          direction="column"
          wrap="nowrap"
          paddingBottom="40px"
        >
          <Grid item display="grid">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "18px",
                color: "rgb(11, 36, 60)",
              }}
            >
              {reducer?.title}
            </span>
          </Grid>
          <Grid item display="grid" paddingTop="20px">
            <ConfigureSchedule reducer={reducer} />
          </Grid>
        </Grid>
      </Grid>
    </FormPopup>
  );
};

export default StartChatbot;
