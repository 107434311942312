import * as React from 'react'
import './BlueButton.scss';

interface IBlueButtonProps {
  text?: string,
  onClick?: any,
  enable?: boolean,
  type?: any,
}

const BlueButton = (props: IBlueButtonProps) => {
  return (
    <button
      disabled={!props.enable}
      className={'blue-button'}
      onClick={props.onClick}
      type={props.type}>
      {props.text}
    </button>
  )
}

BlueButton.defaultProps = {
  text: 'Default',
  enable: true,
  onClick: () => {
    console.error("You forgot the 'onClick' attribute in the 'BlueButton' component");
  },
};

export default BlueButton;