import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import makeAPIRequest from '../../utils/makeAPIRequest'
import { PDFQuestionnaire } from '../../components/Questionnaire/PDF/CreateOrEditPDFQuestionnaire';

const getFormPdf = createAsyncThunk(
  '/GET_FORM_PDF',
  async (payload, thunkAPI): Promise<PDFQuestionnaire[]> => {
    try {
      const response = await makeAPIRequest(
        'get',
        `/forms/pdf/list`,
        null,
        'v3',
      )
      const data: PDFQuestionnaire[] = response?.data;
      return thunkAPI.fulfillWithValue(data) as any
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

export interface FormPDFReducerState {
  list: PDFQuestionnaire[],
  isLoading: boolean,
}

const initialState: FormPDFReducerState = {
  list: [],
  isLoading: true,
}

export const formPdfSlice = createSlice({
  name: 'formPdf',
  initialState,
  reducers: {},
  extraReducers(builder: ActionReducerMapBuilder<FormPDFReducerState>) {
    builder.addCase(getFormPdf.rejected, (state) => {
      state.isLoading = false;
    })
    builder.addCase(getFormPdf.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    })
  },
})
export const formPdfActions = formPdfSlice.actions;
export default formPdfSlice.reducer
export { getFormPdf }
