import { createSlice } from '@reduxjs/toolkit';

export const helpInstallationVitaleSlice = createSlice({
  name: 'helpInstallationVitale',
  initialState: {
    isOpen: false,
    step: 0,
  },
  reducers: {
    openHelpInstallationVitale: (state) => {
      state.isOpen = true;
      state.step = 0;
    },
    closeHelpInstallationVitale: (state) => {
        state.isOpen = false;
        state.step = 0;
    },
    nextInstallationStep: (state) => {
        if (state.step < 4) {
            state.step = state.step + 1;
        }
    },
    prevInstallationStep: (state) => {
        if (state.step > 0) {
            state.step = state.step - 1;
        }
    }
  }
})

export const { closeHelpInstallationVitale, openHelpInstallationVitale, nextInstallationStep, prevInstallationStep } = helpInstallationVitaleSlice.actions

export default helpInstallationVitaleSlice.reducer;
