import formatPhone from '../../utils/format/formatPhone';
import formatDate from './format/formatDate';
import convertSmsModeStatus from '../../utils/format/convertSmsModeStatus';

const exportMessage = (result, user) => {
    const lines = [];

    lines.push([
      "Statut",
      "Nom patient",
      "Prenom patient",
      "Tel patient",
      "Type",
      "Contenu",
      "Date d'envoi",
      "Conseil envoyé",
      "Nom médecin",
    ]);
    for (let row of result) {
      const dateTmp = (formatDate(row.date) as any)?.date.split('/');
      const date = dateTmp[2] + '/' + dateTmp[1] + '/' + dateTmp[0] + ' ' + (formatDate(row.date) as any).hours;
      const nameTmp = row.patient_name.replace(", ", ",");
      const trueName = nameTmp.split(",");
      let type = "Conseil";

      if (row.pack_id != null) type = "Pack";
      if (row.free_text != "") type = "Texte libre";
      lines.push([
        convertSmsModeStatus(row.smsmode_status),
        trueName[0],
        trueName[1] ? trueName[1] : "",
        formatPhone(row.target),
        type,
        row.content != null ? row.content : row.free_text,
        date,
        row.sended == 1 ? "Oui" : "Non",
        user.last_name + " " + user.first_name
      ]);
    }
    let csv = "";
    for (let i = 0; i < lines.length; i++) {
      for (let j = 0; j < lines[i].length; j++) {
        csv += `"${lines[i][j]}"`;
        if (j === lines[i].length - 1) {
          csv += "\n";
        } else {
          csv += ";";
        }
      }
    }

    const universalBOM = "\uFEFF";
    const element = window.document.createElement('a');
    element.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csv));
    element.setAttribute('download', `messages.csv`.replaceAll(
      " ",
      "-"
    ));
    window.document.body.appendChild(element);
    element.click();
  };


export default exportMessage;