import FullField from "../../../Fields/FullField";
import * as React from "react";
import MySelect from "../../../Library/Select/MySelect";
import HandleErrors from "../../../Library/Errors/HandleErrors";
import { IField } from "../../../../types/FormTypes";
import { Grid } from "@mui/material";
import { getFieldByFieldId } from "../../EditQuestionnaire/Fields/DisplayConfirmMessage";

const DisplayMedecinDelegant: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [field, setField] = React.useState<Partial<IField>>(undefined);
  const {
    sectionIndex,
    justifyContent,
    fieldIndex,
    disableError,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  interface IOption {
    label?: string;
    value?: string;
  }

  React.useEffect(() => {
    setField(getFieldByFieldId(questionnaireData, props?.field?.id));
  }, [questionnaireData, props?.field]);

  const getAllOptions = (): [IOption[], IOption[], IOption[]] => {
    const options: [IOption[], IOption[], IOption[]] = [[], [], []];
    if (!field?.medecinDelegantInfos?.medecinsDelegantList) {
      return options;
    }
    if (field?.medecinDelegantInfos?.medecinsDelegantList?.length == 0) {
      return options;
    }

    for (const delegant of field?.medecinDelegantInfos.medecinsDelegantList) {
      if (delegant?.id) {
        if (delegant?.NomPrenom) {
          options[0].push({
            value: delegant.id,
            label: `${delegant.NomPrenom}`,
          });
        }
        if (delegant?.RPPS) {
          options[1].push({
            value: delegant.id,
            label: `${delegant.RPPS}`,
          });
        }
        if (delegant?.AM) {
          options[2].push({
            value: delegant.id,
            label: `${delegant.AM}`,
          });
        }
      }
    }
    options[0] = options[0].sort((a, b) => {
      const first = a?.label?.toLowerCase();
      const second = b?.label?.toLowerCase();
      return first < second ? -1 : first > second ? 1 : 0;
    });
    return options;
  };

  const allOptions = getAllOptions();

  const [optionsNomPrenom, optionsRPPS, optionsAM] = [...allOptions];

  const [optionSelected, setOptionSelected] = React.useState<{
    nomPrenom: IOption;
    rpps: IOption;
    am: IOption;
  }>({
    nomPrenom: undefined,
    rpps: undefined,
    am: undefined,
  });

  React.useEffect(() => {
    const allOptionsTmp = getAllOptions();

    const [optionsNomPrenomTmp, optionsRPPSTmp, optionsAMTmp] = [
      ...allOptionsTmp,
    ];

    setOptionSelected({
      am: optionsAMTmp
        ? optionsAMTmp.find((x) => x?.value === field?.value)
        : undefined,
      nomPrenom: optionsNomPrenomTmp
        ? optionsNomPrenomTmp.find((x) => x?.value === field?.value)
        : undefined,
      rpps: optionsRPPSTmp
        ? optionsRPPSTmp.find((x) => x?.value === field?.value)
        : undefined,
    });
  }, [field?.value, questionnaireData]);

  return (
    <Grid container direction="column" wrap="nowrap" gap="10px">
      <Grid item display="grid">
        <FullField
          isQuestionnaire={true}
          title={"Nom et prénom du délégant"}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={isError && !disableError}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessage}
          >
            <MySelect
              id={"field" + field?.id}
              isError={isError && !disableError}
              value={!optionSelected?.nomPrenom ? '' as any : optionSelected?.nomPrenom}
              options={optionsNomPrenom}
              placeholder=""
              onChange={(e) => {
                const questionnaireDataTmp = { ...questionnaireData };

                questionnaireDataTmp.sections[sectionIndex].fields[
                  fieldRowIndex
                ][fieldIndex].value = e?.value;
                setQuestionnaireData(questionnaireDataTmp);
              }}
              onFocus={(e) => {
                if (!field?.isMandatory) return;
                if (isError && !disableError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
              onBlur={(e) => {
                if (!field?.isMandatory) return;
                if (
                  !questionnaireData.sections[sectionIndex].fields[
                    fieldRowIndex
                  ][fieldIndex].value
                ) {
                  if (!isError && !disableError) {
                    setErrorMessage("Ce champ est obligatoire.");
                    setIsError(true);
                  }
                } else {
                  if (isError && !disableError) {
                    setErrorMessage("");
                    setIsError(false);
                  }
                }
              }}
            />
          </HandleErrors>
        </FullField>
      </Grid>
      <Grid item display="grid">
        <FullField
          isQuestionnaire={true}
          title={"N° AM du délégant"}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={false /*isError && !disableError*/}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessage}
          >
            <MySelect
              id={"field" + field?.id}
              isDisabled={true}
              isError={false /*isError && !disableError*/}
              value={!optionSelected?.am ? '' as any : optionSelected?.am}
              options={optionsAM}
              placeholder=""
              onChange={(e) => {
                const questionnaireDataTmp = { ...questionnaireData };

                questionnaireDataTmp.sections[sectionIndex].fields[
                  fieldRowIndex
                ][fieldIndex].value = e?.value;
                setQuestionnaireData(questionnaireDataTmp);
              }}
              onFocus={(e) => {
                if (!field?.isMandatory) return;
                if (isError && !disableError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
              onBlur={(e) => {
                if (!field?.isMandatory) return;
                if (
                  !questionnaireData.sections[sectionIndex].fields[
                    fieldRowIndex
                  ][fieldIndex].value
                ) {
                  if (!isError && !disableError) {
                    setErrorMessage("Ce champ est obligatoire.");
                    setIsError(true);
                  }
                } else {
                  if (isError && !disableError) {
                    setErrorMessage("");
                    setIsError(false);
                  }
                }
              }}
            />
          </HandleErrors>
        </FullField>
      </Grid>
      <Grid item display="grid">
        <FullField
          isQuestionnaire={true}
          title={"N° RPPS du délégant"}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={false /*isError && !disableError*/}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessage}
          >
            <MySelect
              id={"field" + field?.id}
              isDisabled={true}
              isError={false /*isError && !disableError*/}
              value={!optionSelected?.rpps ? '' as any : optionSelected?.rpps}
              options={optionsRPPS}
              placeholder=""
              onChange={(e) => {
                const questionnaireDataTmp = { ...questionnaireData };

                questionnaireDataTmp.sections[sectionIndex].fields[
                  fieldRowIndex
                ][fieldIndex].value = e?.value;
                setQuestionnaireData(questionnaireDataTmp);
              }}
              onFocus={(e) => {
                if (!field?.isMandatory) return;
                if (isError && !disableError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
              onBlur={(e) => {
                if (!field?.isMandatory) return;
                if (
                  !questionnaireData.sections[sectionIndex].fields[
                    fieldRowIndex
                  ][fieldIndex].value
                ) {
                  if (!isError && !disableError) {
                    setErrorMessage("Ce champ est obligatoire.");
                    setIsError(true);
                  }
                } else {
                  if (isError && !disableError) {
                    setErrorMessage("");
                    setIsError(false);
                  }
                }
              }}
            />
          </HandleErrors>
        </FullField>
      </Grid>
    </Grid>
  );
};

export default DisplayMedecinDelegant;
