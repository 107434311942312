import {
  AddCardRounded,
  AnalyticsRounded,
  Check,
  ChevronLeftRounded,
  ChevronRightRounded,
  ClearRounded,
  Delete,
  DeleteRounded,
  EditRounded,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import * as React from "react";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import * as editPatientReducer from "../../redux/editPatientReducer";
import * as selectPatientReducer from "../../redux/selectPatientReducer";
import EventEmitter from "../../services/event";
import { closeSnackbar, openSnackbar } from "../../redux/snackbarReducer";
import { nanoid } from "nanoid";
import makeAPIRequest from "../../utils/makeAPIRequest";
import http from "../../http-common";
import InformationPopup from "../Library/Popups/InformationPopup";
import { openHelpInstallationVitale } from "../../redux/helpInstallationVitaleReducer";
import MySelect from "../Library/Select/MySelect";
import IPatientData from "../../types/Patient";
import { GenericFromPopup } from "../../pages/ChatbotLexPage";
import { useOpenSnackbar } from "../../pages/CreateOrEditChatbotLexPage";
import { formatDateFromTimestamp } from "../MonPatientBox/MonPatientBox";
import GenericRadar from "../Library/Draft/GenericRadar";
import GenericDoughnut from "../Library/Draft/GenericDoughnut";
import useAppSelector from "../../redux/useAppSelector";

interface PackInProgress {
  conseilId?: number;
  schedulePackId?: number;
  title?: string;
  sended?: number;
  notSended?: number;
}

const DisplayPatientPack: React.FC<{ packInProgress: PackInProgress[] }> = (
  props
) => {
  const [actualIndex, setActualIndex] = React.useState(0);
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      width="150px"
    >
      <span
        style={{
          fontSize: "12px",
          fontFamily: "Poppins",
          color: "#4D473D",
          textAlign: "center",
        }}
      >
        {`${props.packInProgress[actualIndex].title} (${
          props.packInProgress[actualIndex].sended
        }/${
          props.packInProgress[actualIndex].sended +
          props.packInProgress[actualIndex].notSended
        })`}
      </span>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap="5px"
        justifyContent={"center"}
        alignItems="center"
      >
        {props.packInProgress?.length > 1 && (
          <Grid item display="grid">
            <Tooltip title="Précédent">
              <span>
                <IconButton
                  disabled={actualIndex === 0}
                  style={{ padding: "0", margin: "0" }}
                  onClick={() => {
                    if (actualIndex > 0) setActualIndex((x) => x - 1);
                  }}
                >
                  <ChevronLeftRounded
                    style={{
                      color: "#8D9899",
                      opacity: actualIndex === 0 ? 0.3 : 1,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        )}
        <Grid item display="grid"  alignItems='center' style={{ width: "120px", margin: '10px' }}>
          <GenericDoughnut
            displayLegend={false}
            labels={[`En attente`, `Envoyé`]}
            data={[
              props.packInProgress[actualIndex].notSended,
              props.packInProgress[actualIndex].sended,
            ]}
          />
        </Grid>
        {props.packInProgress?.length > 1 && (
          <Grid item display="grid">
            <Tooltip title="Suivant">
              <span>
                <IconButton
                  disabled={props.packInProgress?.length - 1 === actualIndex}
                  style={{ padding: "0", margin: "0" }}
                  onClick={() => {
                    if (actualIndex < props.packInProgress?.length - 1)
                      setActualIndex((x) => x + 1);
                  }}
                >
                  <ChevronRightRounded
                    style={{
                      color: "#8D9899",
                      opacity:
                        props.packInProgress?.length - 1 === actualIndex
                          ? 0.3
                          : 1,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const translateState = (str: string) => {
  if (str === "completed") {
    return "Terminé";
  }
  if (str === "in progress") {
    return "En cours";
  }
  if (str === "pending") {
    return "En attente";
  }
  if (str === "cancelled") {
    return "Annulé";
  }
  return "";
};

const patientIsSelected = (patient, patientSelected) => {
  if (patientSelected.includes(patient?.id)) return true;
  return false;
};

const MesPatientTableContent: React.FC<any> = (props) => {
  const nbPatients = props?.patientList?.length;
  const dispatch = useDispatch();
  const [patientToUpdateWithVitale, setPatientToUpdateWithVitale] =
    React.useState(null);
  const [checkSoftwareLoading, setCheckSoftwareLoading] = React.useState(false);
  const [checkSoftwareLoadingPercent, setCheckSoftwareLoadingPercent] =
    React.useState(false);
  const [isVitaleReaderLoading, setIsVitaleReaderLoading] =
    React.useState(false);
  const [vitaleReaderLoadingPercent, setVitaleReaderLoadingPercent] =
    React.useState(0);
  const [lastInfosVitale, setLastInfosVitale] = React.useState(null);
  const [importedPatient, setImportedPatient] = React.useState([]);
  const [checkedIndex, setCheckedIndex] = React.useState(0);
  const [isValidateLoading, setIsValidateLoading] = React.useState(false);
  const [isImportPopup, setIsImportPopup] = React.useState(false);
  const loadingTimeInMs = 8000;
  const checkPoints = [70, 85];
  const certificateINSi = useAppSelector((state) => state.INSiReducer.certificate);
  const [openPatientStats, setOpenPatientStats] =
    React.useState<IPatientData>(undefined);

  const endLoading = () => {
    setIsVitaleReaderLoading(false);
    const transactionId = localStorage.getItem("transactionId");
    setVitaleReaderLoadingPercent(0);

    if (transactionId) {
      makeAPIRequest(
        "get",
        `patient/load_patient?transactionId=${transactionId}`
      )
        .then((res) => {
          if (res?.data?.data?.patient?.length > 0) {
            const importedPatientTmp = res?.data?.data?.patient;
            for (let i = 0; i < importedPatientTmp?.length; i++)
              importedPatientTmp[i].isSelected = true;
            setImportedPatient(importedPatientTmp);
            dispatch(
              openSnackbar({
                duration: 2500,
                message: "Lecture de la carte Vitale terminée",
                type: "success",
              })
            );
            setIsImportPopup(true);
          } else {
            dispatch(
              openSnackbar({
                duration: 7000,
                message: "Impossible de lire la carte Vitale.",
                type: "error",
              })
            );
          }
        })
        .catch((e) => {
          dispatch(
            openSnackbar({
              duration: 7000,
              message: "Impossible de lire la carte vitale.",
              type: "error",
            })
          );
        });
    } else {
      console.error("No transaction transactionId found in local storage.");
    }
  };

  const executeCheckPoint = (next) => {
    const transactionId = localStorage.getItem("transactionId");
    if (transactionId) {
      makeAPIRequest(
        "get",
        `patient/load_patient?transactionId=${transactionId}`
      ).then((res) => {
        if (res?.data?.data?.patient?.length > 0) {
          updatePercent(90);
        } else {
          updatePercent(next);
        }
      });
    } else {
      console.error("No transaction transactionId found in local storage.");
      updatePercent(next);
    }
  };

  const updatePercent = (next) => {
    setTimeout(() => {
      dispatch(
        openSnackbar({
          message: `Import des données de la carte Vitale en cours... ${next}%`,
          type: "info",
          duration: 15000,
          position: "bottomCenter",
          width: "480px",
        })
      );
      if (checkPoints.includes(next)) {
        executeCheckPoint(next + 1);
      } else if (next >= 100) {
        dispatch(
          closeSnackbar({
            position: "bottomCenter",
          })
        );
        endLoading();
      } else {
        setVitaleReaderLoadingPercent(next);
        updatePercent(next + 1);
      }
    }, loadingTimeInMs / 100);
  };

  const updateCheckSoftwarePercent = (percent, maxTime) => {
    setTimeout(() => {
      if (percent != 100) {
        dispatch(
          openSnackbar({
            message: `Vérification de votre lecteur... ${percent}%`,
            type: "info",
            duration: 15000,
            position: "bottomCenter",
            width: "340px",
          })
        );
      }
      if (percent >= 100) {
        let infos = null;
        try {
          infos = JSON.parse(localStorage.getItem("lastInfosVitale"));
        } catch {
          infos = null;
        }
        if (infos?.status == "1") {
          const id = nanoid(8);
          localStorage.setItem("transactionId", id);
          setIsVitaleReaderLoading(true);
          updatePercent(0);
          window.location.href = `certipairvitalereader:${id}`;
        }
        setCheckSoftwareLoading(false);
        dispatch(closeSnackbar({ position: "bottomCenter" }));
        return;
      }
      setCheckSoftwareLoadingPercent(percent);
      if (localStorage.getItem("loadedSoftware") == "true") {
        updateCheckSoftwarePercent(90, maxTime);
        localStorage.setItem("loadedSoftware", "false");
      } else {
        updateCheckSoftwarePercent(percent + 1, maxTime);
      }
    }, maxTime / 100);
  };

  const checkSoftware = () => {
    if (!window.navigator.userAgent.includes("Windows")) {
      dispatch(
        openSnackbar({
          message:
            "Vous devez être sur Windows pour utiliser cette fonctionnalité",
          type: "error",
          duration: 3000,
        })
      );
      return;
    }
    const id = `test_${nanoid()}`;
    setVitaleReaderLoadingPercent(0);
    localStorage.setItem("loadedSoftware", "false");
    setCheckSoftwareLoading(true);
    updateCheckSoftwarePercent(0, 11000);
    window.location.href = `certipairvitalereader:${id}`;
    setTimeout(() => {
      makeAPIRequest("get", `vitale/check_vitale?transactionId=${id}`, null)
        .then((res) => {
          localStorage.setItem("loadedSoftware", "true");
          const infos = res?.data?.data?.infos?.[0];
          if (!infos) {
            dispatch(
              openSnackbar({
                message:
                  "Le logiciel Certipair Vitale Reader n'est pas installé",
                type: "error",
                duration: 3000,
              })
            );
            dispatch(openHelpInstallationVitale());
          } else {
            if (infos?.message == "L'API_LEC n'est pas installée") {
              dispatch(openHelpInstallationVitale());
            }
            dispatch(
              openSnackbar({
                message:
                  infos?.status == "1"
                    ? "L'application est prête à importer la carte Vitale"
                    : infos?.message,
                type: infos?.status == "1" ? "success" : "error",
                duration: 5000,
              })
            );
          }
          localStorage.setItem("lastInfosVitale", JSON.stringify(infos));
          setLastInfosVitale(infos);
        })
        .catch((err) => {});
    }, 7500);
  };

  type ChatbotLexScheduleStatus =
    | "in progress"
    | "completed"
    | "pending"
    | "cancelled";
  type ChatbotScheduleStatus =
    | "in progress"
    | "completed"
    | "pending"
    | "cancelled";

  interface FullPatientStatsResult {
    packInProgress: Array<PackInProgress>;
    chatbotsSchedule: Array<{
      title?: string;
      sendedTimestamp?: number;
      state?: ChatbotLexScheduleStatus;
    }>;
    questionnaireInterfactifSchedule: Array<{
      title?: string;
      sendedTimestamp?: number;
      state?: ChatbotScheduleStatus;
    }>;
    formsSchedule: Array<{
      title?: string;
      sendedTimestamp?: number;
      responseTimestamp?: number;
    }>;
    sendedConseils: Array<{
      monthYear: string;
      nbSended: number;
      nbScheduled: number;
    }>;
    graphs: Array<any>;
  }

  const StatsPopup = () => {
    const [patientStats, setPatientStats] =
      React.useState<FullPatientStatsResult>(undefined);
    const snackbarUse = useOpenSnackbar();

    React.useEffect(() => {
      if (openPatientStats?.id) {
        makeAPIRequest(
          "get",
          `/forms/stats/${openPatientStats?.id}/get_full_patient`,
          null,
          "v3"
        )
          .then((res) => {
            console.log(res?.data);
            setPatientStats(res?.data);
          })
          .catch((err) => {
            snackbarUse.error("Impossible de récupérer les statistiques", err);
          });
      }
    }, [openPatientStats]);

    return (
      <GenericFromPopup
        onClose={() => setOpenPatientStats(undefined)}
        title={{ leftPart: "Mes", rightPart: "Patients" }}
        subtitle={`${openPatientStats.firstname} ${openPatientStats.lastname}`}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="40px"
        >
          {patientStats?.formsSchedule &&
            patientStats?.formsSchedule?.length == 0 &&
            patientStats?.sendedConseils &&
            patientStats?.sendedConseils?.length == 0 &&
            patientStats?.graphs &&
            patientStats?.graphs?.length == 0 && (
              <Grid item display="grid">
                <span
                  style={{
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    color: "#0B243c",
                  }}
                >
                  {"Aucune donnée n'est disponible pour ce patient."}
                </span>
              </Grid>
            )}
          {patientStats?.formsSchedule &&
            patientStats?.formsSchedule?.length > 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="20px">
                  <Grid item display="grid">
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        color: "#0B243c",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {"Programmation d'envois de questionnaires"}
                    </span>
                  </Grid>
                  <Grid>
                    <div className="table-grid-full-stats-patient">
                      <span className="table-grid-title">
                        Titre du questionnaire
                      </span>
                      <span className="table-grid-title">{"Date d'envoi"}</span>
                      <span className="table-grid-title">Date de réponse</span>
                      {patientStats.formsSchedule.map((x) => {
                        return (
                          <>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x.title ? x.title : ""}
                            </span>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x.sendedTimestamp
                                ? formatDateFromTimestamp(x.sendedTimestamp)
                                : ""}
                            </span>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x.responseTimestamp
                                ? formatDateFromTimestamp(x.responseTimestamp)
                                : ""}
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          {patientStats?.chatbotsSchedule &&
            patientStats?.chatbotsSchedule?.length > 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="20px">
                  <Grid item display="grid">
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        color: "#0B243c",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {"Programmation d'envois de chatbots"}
                    </span>
                  </Grid>
                  <Grid>
                    <div className="table-grid-full-stats-patient">
                      <span className="table-grid-title">Titre du chatbot</span>
                      <span className="table-grid-title">{"Date d'envoi"}</span>
                      <span className="table-grid-title">État</span>
                      {patientStats.chatbotsSchedule.map((x) => {
                        return (
                          <>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x.title ? x.title : ""}
                            </span>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x.sendedTimestamp
                                ? formatDateFromTimestamp(x.sendedTimestamp)
                                : ""}
                            </span>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x?.state ? translateState(x.state) : ""}
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}

          {patientStats?.questionnaireInterfactifSchedule &&
            patientStats?.questionnaireInterfactifSchedule?.length > 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="20px">
                  <Grid item display="grid">
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        color: "#0B243c",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {"Programmation d'envois de questionnaires interactifs"}
                    </span>
                  </Grid>
                  <Grid>
                    <div className="table-grid-full-stats-patient">
                      <span className="table-grid-title">
                        Titre du questionnaire interactif
                      </span>
                      <span className="table-grid-title">{"Date d'envoi"}</span>
                      <span className="table-grid-title">État</span>
                      {patientStats.questionnaireInterfactifSchedule.map(
                        (x) => {
                          return (
                            <>
                              <span
                                className="table-grid-text"
                                style={{
                                  color: "0b243c",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {x.title ? x.title : ""}
                              </span>
                              <span
                                className="table-grid-text"
                                style={{
                                  color: "0b243c",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {x.sendedTimestamp
                                  ? formatDateFromTimestamp(x.sendedTimestamp)
                                  : ""}
                              </span>
                              <span
                                className="table-grid-text"
                                style={{
                                  color: "0b243c",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {x?.state ? translateState(x.state) : ""}
                              </span>
                            </>
                          );
                        }
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}

          {patientStats?.packInProgress &&
            patientStats?.packInProgress?.length > 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="20px">
                  <Grid item display="grid">
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        color: "#0B243c",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {"Pack en cours"}
                    </span>
                  </Grid>
                  <Grid item display="grid">
                    <Grid
                      container
                      direction="row"
                      gap="10px"
                      justifyContent={"space-evenly"}
                    >
                      {patientStats.packInProgress.map((packInP, index) => {
                        return (
                          <Grid item display="grid" key={index}>
                            <div style={{ width: 300 }}>
                              <GenericDoughnut
                                title={packInP.title}
                                labels={["En attente", "Envoyé"]}
                                data={[packInP.notSended, packInP.sended]}
                              />
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          {patientStats?.sendedConseils &&
            patientStats?.sendedConseils?.length > 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="20px">
                  <Grid item display="grid">
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        color: "#0B243c",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {"Programmation d'envois de conseils"}
                    </span>
                  </Grid>
                  <Grid item display="grid">
                    <div className="table-grid-full-stats-patient">
                      <span className="table-grid-title">Période</span>
                      <span className="table-grid-title">SMS programmés</span>
                      <span className="table-grid-title">SMS envoyés</span>
                      {patientStats.sendedConseils.map((x) => {
                        return (
                          <>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x.monthYear}
                            </span>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x.nbScheduled}
                            </span>
                            <span
                              className="table-grid-text"
                              style={{
                                color: "0b243c",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {x.nbSended}
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          {patientStats?.graphs && patientStats?.graphs?.length > 0 && (
            <Grid item display="grid">
              <Grid container direction="column" wrap="nowrap" gap="20px">
                <Grid item display="grid">
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      color: "#0B243c",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {"Graphiques araignées"}
                  </span>
                </Grid>
                <Grid item display="grid">
                  <Grid
                    container
                    direction="row"
                    justifyContent={"space-evenly"}
                  >
                    {patientStats.graphs.map((graph, index) => {
                      return (
                        <Grid item display="grid" xs key={index} gap="15px">
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            minWidth={"600px"}
                            overflow="auto"
                            gap="10px"
                            justifyContent="center"
                          >
                            <Grid item display="grid">
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  color: "#0B243C",
                                  textAlign: "center",
                                }}
                              >
                                {graph?.title}
                              </span>
                            </Grid>
                            <Grid item display="grid">
                              <GenericRadar
                                legend={false}
                                min={graph?.min ? graph.min : 0}
                                max={graph?.max ? graph.max : undefined}
                                data={{
                                  labels: graph.labels.map(
                                    (x: any) => x?.title
                                  ),
                                  datasets: graph.items.map((x) => {
                                    return {
                                      data: x.data,
                                      label: x.label,
                                      hidden: false,
                                    };
                                  }),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </GenericFromPopup>
    );
  };

  return (
    <>
      {openPatientStats && <StatsPopup />}
      {isImportPopup && (
        <InformationPopup
          isBig={importedPatient?.length > 9 ? true : false}
          firstTitle="Mettre à jour"
          secondTitle="un patient"
          componentsSpacing="15px"
          isValidateLoading={isValidateLoading}
          confirmButtonText="Valider"
          onConfirm={() => {
            setIsValidateLoading(true);
            for (let i = 0; i < importedPatient?.length; i++) {
              if (i === checkedIndex) {
                console.log("to update :");
                console.log(importedPatient[i]);
                makeAPIRequest(
                  "post",
                  `/patient/${importedPatient[i]?.id}/auto_update/${patientToUpdateWithVitale?.id}`
                )
                  .then((res) => {
                    setIsImportPopup(false);
                    setIsValidateLoading(false);
                    dispatch(
                      openSnackbar({
                        message:
                          "Les données du patient ont bien été mis à jour",
                        type: "success",
                        duration: 3000,
                      })
                    );
                    EventEmitter.emit("PatientAdded", {
                      text: importedPatient[i],
                      id: importedPatient[i]?.id,
                    });
                  })
                  .catch((e) => {
                    setIsValidateLoading(false);
                    dispatch(
                      openSnackbar({
                        message: "Impossible de mettre à jour votre patient",
                        type: "error",
                        duration: 3000,
                      })
                    );
                    console.log(e);
                  });
                // update
              } else {
                // delete
                makeAPIRequest(
                  "delete",
                  `/patient/${importedPatient[i]?.id}/delete`
                )
                  .then((res) => {})
                  .catch((e) => {});
              }
            }
          }}
        >
          <Grid container direction="column" display="flex" paddingTop="15px" wrap="nowrap">
            <Grid item display="grid">
              <span
                style={{
                  textAlign: "center",
                  color: "#657273",
                  paddingBottom: "5px",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                Quelles données souhaitez-vous importer ?
              </span>
            </Grid>
            <Grid item display="grid" justifyContent="center">
              {importedPatient?.map((patient, i) => {
                return (
                  <Grid
                    container
                    direction="row"
                    minWidth="290px"
                    marginLeft="-35px"
                    paddingTop="5px"
                    display="flex"
                    key={patient?.id}
                    alignItems="center"
                    columnSpacing="10px"
                  >
                    <Grid item display="grid" xs={10}>
                      <span
                        style={{
                          textAlign: "center",
                          color: "#657273",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                        }}
                      >
                        {`${importedPatient[i]?.firstname} ${importedPatient[i]?.lastname}`}
                      </span>
                    </Grid>
                    <Grid item display="grid" xs={2}>
                      <input
                        type="radio"
                        className="checkbox_type"
                        checked={i === checkedIndex}
                        onChange={() => {
                          return null;
                        }}
                        onClick={() => {
                          setCheckedIndex(i);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </InformationPopup>
      )}
      {props?.pages?.[props?.actualPage]?.map((patient, index) => {
        const value = patientIsSelected(patient, props?.patientSelected);
        return (
          <>
            <div
              key={patient?.id}
              className="table-grid-select"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: !value
                    ? "#EDF2F2 0% 0% no-repeat padding-box"
                    : "#FFA500 0% 0% no-repeat padding-box",
                  width: "22px",
                  height: "22px",
                  borderRadius: "5px",
                  border: "1px solid #D3E0E0",
                  opacity: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {value && (
                  <Check
                    onClick={() =>
                      dispatch(selectPatientReducer?.addPatient(patient))
                    }
                    fontSize="small"
                    style={{ paddingLeft: "3px", color: "white" }}
                  />
                )}
                <Tooltip
                  title={
                    value
                      ? "Désélectionner ce patient"
                      : "Sélectionner ce patient"
                  }
                >
                  <input
                    type="checkbox"
                    className="checkbox_modals"
                    checked={value}
                    onChange={() => {
                      return null;
                    }}
                    onClick={() => {
                      if (props?.patientSelected?.length < 100) {
                        dispatch(selectPatientReducer?.addPatient(patient));
                        dispatch(
                          openSnackbar({
                            message: `Le patient '${patient?.lastname} ${patient?.firstname}' a bien été sélectionné`,
                            type: "success",
                            duration: 2000,
                          })
                        );
                      } else {
                        dispatch(
                          openSnackbar({
                            message: `Vous ne pouvez pas sélectionner plus de 100 patients`,
                            type: "error",
                            duration: 2000,
                          })
                        );
                      }
                    }}
                    style={{
                      padding: "0",
                      margin: "0",
                      borderRadius: "5px",
                      width: "100%",
                      height: "100%",
                      opacity: 0,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {patient?.id}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {patient?.lastname}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {patient?.firstname}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {patient?.phone ? patient?.phone : "À compléter"}
            </span>
            {certificateINSi && (
              <span
                className="table-grid-text"
                style={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "pre-line",
                }}
              >
                {!patient?.ins_numero_identifiant || !patient?.ins_cle
                  ? "Non"
                  : `${patient?.ins_numero_identifiant} ${patient?.ins_cle}`}
              </span>
            )}
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {patient?.packInProgress?.length > 0 && (
                <DisplayPatientPack packInProgress={patient?.packInProgress} />
              )}
            </span>
            <Grid
              container
              className="table-grid-text"
              spacing="15px"
              direction="row"
              wrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="grid">
                <div
                  style={{
                    minWidth: "120px",
                  }}
                >
                  <MySelect
                    isClearable={false}
                    maxMenuHeight={"150px"}
                    placeholder={patient?.group ? patient?.group : "Aucun"}
                    options={
                      props?.patientGroupsList
                        ? [
                            { label: "Aucun", value: "" },
                            ...props.patientGroupsList.map((x) => {
                              return {
                                label: x?.group_name,
                                value: x?.id,
                              };
                            }),
                          ]
                        : []
                    }
                    noOptionsMessage={() => "Aucun groupe"}
                    onChange={(e) => {
                      makeAPIRequest(
                        "post",
                        "/patients/update_group",
                        {
                          group_id: e?.value ? e?.value : "",
                          patient_ids: [patient?.id],
                        },
                        "v2"
                      )
                        .then((res) => {
                          if (res?.data?.success === true) {
                            const patientListTmp = props?.patientList
                              ? [...props.patientList]
                              : [];

                            const indexFounded = patientListTmp.findIndex(
                              (x) => x?.id == patient?.id
                            );
                            if (indexFounded !== -1) {
                              patientListTmp[indexFounded].group = e?.value;
                              props.setPatientList(patientListTmp);
                            }
                            dispatch(
                              openSnackbar({
                                message:
                                  "Le groupe du patient a bien été mis à jour",
                                duration: 3000,
                                type: "success",
                              })
                            );
                          } else {
                            dispatch(
                              openSnackbar({
                                message:
                                  "Impossible de mettre à jour le groupe du patient",
                                duration: 3000,
                                type: "error",
                              })
                            );
                          }
                        })
                        .catch((err) => {
                          dispatch(
                            openSnackbar({
                              message:
                                "Impossible de mettre à jour le groupe du patient",
                              duration: 3000,
                              type: "error",
                            })
                          );
                        });
                    }}
                    value={
                      !props?.patientList || !patient?.group
                        ? null
                        : {
                            value: patient.group,
                            label: props?.patientGroupsList?.find(
                              (x) => x?.id == patient.group
                            )?.group_name,
                          }
                    }
                  />
                </div>
              </Grid>
              <Grid item display="grid">
                <Grid
                  container
                  direction="row"
                  spacing="10px"
                  wrap="wrap"
                  maxWidth="80px"
                >
                  <Grid item display="grid">
                    <Tooltip title="Voir les statistiques">
                      <IconButton
                        style={{ margin: "0", padding: "0" }}
                        onClick={() => {
                          setOpenPatientStats(patient);
                        }}
                      >
                        <AnalyticsRounded
                          fontSize="small"
                          style={{ color: "#0B243C" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item display="grid">
                    <Tooltip title="Importer la carte Vitale">
                      <IconButton
                        style={{ margin: "0", padding: "0" }}
                        onClick={() => {
                          setPatientToUpdateWithVitale(patient);
                          checkSoftware();
                        }}
                      >
                        <AddCardRounded
                          fontSize="small"
                          style={{ color: "#0B243C" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item display="grid">
                    <Tooltip title="Modifier">
                      <IconButton
                        style={{ margin: "0", padding: "0" }}
                        onClick={() =>
                          dispatch(editPatientReducer?.openPatient(patient))
                        }
                      >
                        <EditRounded
                          fontSize="small"
                          style={{ color: "#0B243C" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item display="grid">
                    <Tooltip title="Supprimer">
                      <IconButton
                        onClick={() => {
                          props?.setIsDeletePopup(true);
                          props?.setPatientToDelete(patient);
                        }}
                        style={{
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        <ClearRounded
                          fontSize="small"
                          style={{ color: "#FF3100" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      })}
    </>
  );
};

export default MesPatientTableContent;
