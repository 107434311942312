import FullField from "../../../../Fields/FullField";
import * as React from "react";
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import { useDispatch } from "react-redux";
import useAppSelector from "../../../../../redux/useAppSelector";
import { Grid } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddButton from "../../../../Library/Buttons/AddButton/AddButton";
import { Delete, Edit } from "@mui/icons-material";
import resizeText from "../../../../../utils/resizeText";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../../../../pages/ChatbotLexPage";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "../../../../../pages/CreateOrEditChatbotLexPage";

const CreateOrEditOption: React.FC<{
  isEdit: boolean;
  isCreate: boolean;
  onClose: () => void;
  editIndex?: number;
  onCreate?: (newOption: string) => void;
  onEdit?: (newOption: string) => void;
}> = (props) => {
  const fieldCopy = useAppSelector(
    (state) => state.questionnaireReducer.fieldCopy
  );
  const snackbarUse = useOpenSnackbar();
  const [option, setOption] = React.useState<string>(undefined);
  React.useEffect(() => {
    if (props.isEdit) {
      const opt = fieldCopy?.option?.[props.editIndex];
      if (opt) {
        setOption(opt);
      } else {
        setOption("");
      }
    } else {
      setOption("");
    }
  }, [props.isEdit, props.editIndex, fieldCopy]);

  if (option === undefined) {
    return <></>;
  }
  return (
    <GenericFromPopup
      onClose={props.onClose}
      title={{
        leftPart: props.isEdit ? "Modifier" : "Créer",
        rightPart: "une option",
      }}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="15px"
        padding="20px"
      >
        <Grid item display="grid">
          <GenericTextInput
            fieldTitle="Option"
            isMandatory={true}
            id="option-input"
            value={option}
            onChange={(e) => {
              setOption(e?.target?.value ? e.target.value : "");
            }}
          />
        </Grid>
        <Grid item display="grid">
          <GenericSendButton
            errorMessages={{
              emptyOption: "L'option n'est pas renseignée.",
            }}
            errorSelector={{
              emptyOption: "#option-input",
            }}
            invalidConditions={{
              emptyOption: !option,
            }}
            onSendClick={() => {
              if (fieldCopy?.option?.includes(option)) {
                snackbarUse.error("Cette option existe déjà.");
                return;
              }
              if (props.isEdit && props.isEdit) {
                props.onEdit(option);
              }
              if (props.isCreate && props.onCreate) {
                props.onCreate(option);
              }
              props.onClose();
            }}
            isLoading={false}
            textButton={props.isEdit ? "Modifier" : "Ajouter"}
          />
        </Grid>
      </Grid>
    </GenericFromPopup>
  );
};

export const OptionsField: React.FC = () => {
  const [isAddOptionOpen, setIsAddOptionOpen] = React.useState(false);
  const [editOptionOpen, setEditOptionOpen] = React.useState(undefined);
  const [isDragActive, setIsDragActive] = React.useState(false);
  const fieldCopy = useAppSelector(
    (state) => state.questionnaireReducer.fieldCopy
  );
  const dispatch = useDispatch();

  const handleDropField = (droppedItem) => {
    // Ignore drop outside droppable container
    setIsDragActive(false);
    if (!droppedItem.destination) return;

    // Init variables
    const sourceFieldIndex = droppedItem.source.index;
    const destinationFieldIndex = droppedItem.destination.index;

    console.log("sourceFieldIndex : ", sourceFieldIndex);
    console.log("destinationFieldIndex : ", destinationFieldIndex);

    const options = fieldCopy?.option?.length > 0 ? [...fieldCopy.option] : [];

    if (
      sourceFieldIndex >= 0 &&
      sourceFieldIndex < options.length &&
      destinationFieldIndex >= 0 &&
      destinationFieldIndex < options.length
    ) {
      const [removedOption] = options.splice(sourceFieldIndex, 1);
      options.splice(destinationFieldIndex, 0, removedOption);

      const fieldCopyTmp = { ...fieldCopy };
      fieldCopyTmp.option = options;
      dispatch(setFieldCopy(fieldCopyTmp));
    }
  };

  return (
    <>
      {isAddOptionOpen === true && (
        <CreateOrEditOption
          isCreate={true}
          isEdit={false}
          onClose={() => setIsAddOptionOpen(false)}
          onCreate={(newOption) => {
            const fieldCopyTmp = { ...fieldCopy };
            if (!fieldCopyTmp?.option) {
              fieldCopyTmp.option = [newOption];
            } else {
              fieldCopyTmp.option = [...fieldCopyTmp.option, newOption];
            }
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {editOptionOpen !== undefined && (
        <CreateOrEditOption
          isCreate={false}
          isEdit={true}
          editIndex={editOptionOpen}
          onClose={() => setEditOptionOpen(undefined)}
          onEdit={(newOption) => {
            const fieldCopyTmp = { ...fieldCopy };
            fieldCopyTmp.option = fieldCopyTmp.option.map((opt, index) => {
              if (index === editOptionOpen) {
                return newOption;
              }
              return opt;
            });
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      <FullField title="Options" isMandatory={true} spacing="1px">
        <DragDropContext
          onDragEnd={handleDropField}
          onBeforeDragStart={() => setIsDragActive(true)}
        >
          <Droppable
            key={`droppable`}
            droppableId={`droppable`}
            direction="horizontal"
            isCombineEnabled={false}
            type="RowList"
          >
            {(dropProvided) => (
              <Grid item {...dropProvided.droppableProps} display="grid" justifySelf={fieldCopy?.option?.length == 0 ? 'flex-end' : 'flex-start'}>
                <Grid
                  ref={dropProvided.innerRef}
                  container
                  direction="row"
                  display="flex"
                  spacing="7px"
                >
                  {fieldCopy?.option?.map((option, index) => {
                    return (
                      <Draggable
                        key={/*field?.id*/ option}
                        draggableId={/*field?.id*/ option}
                        index={index}
                      >
                        {(dragProvided) => (
                          <Grid
                            item
                            key={/*field?.id*/ option}
                            display="grid"
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                          >
                            <Grid
                              container
                              direction="row"
                              wrap="nowrap"
                              style={{
                                border: "1px solid rgba(11, 36, 60)",
                                backgroundColor: "rgba(247, 252, 252, 1)",
                                boxShadow:
                                  "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                              }}
                              paddingLeft="5px"
                              paddingRight="5px"
                              borderRadius="10px"
                            >
                              <Grid
                                item
                                display="grid"
                                paddingLeft="2px"
                                paddingRight="2px"
                                alignItems="center"
                                {...dragProvided.dragHandleProps}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  {resizeText(option, 10)}
                                </span>
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Edit
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEditOptionOpen(index);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Delete
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const fieldCopyTmp = { ...fieldCopy };
                                    if (fieldCopy?.option?.length > 0) {
                                      fieldCopyTmp.option =
                                        fieldCopyTmp.option.filter(
                                          (x, i) => i !== index
                                        );
                                    }
                                    dispatch(setFieldCopy(fieldCopyTmp));
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    );
                  })}
                  {!isDragActive && (
                    <Grid item display="grid" justifyContent="flex-start">
                      <Grid
                        container
                        direction="row"
                        gap="10px"
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Grid item display="grid">
                          <AddButton
                            isActive={false}
                            displayText={fieldCopy?.option?.length == 0 ? true : false}
                            text={fieldCopy?.option?.length == 0 ? "Ajouter une première option" : ""}
                            onClick={() => {
                              setIsAddOptionOpen(true);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {dropProvided.placeholder}
                </Grid>
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </FullField>
    </>
  );
};

const DisplayUniqueChoice: React.FC = () => {
  return <OptionsField />;
};

export default DisplayUniqueChoice;
