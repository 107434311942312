import { Grid } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../../redux/snackbarReducer";
import FullField from "../../../Fields/FullField";
import AddButton from "../../../Library/Buttons/AddButton/AddButton";
import TextInput from "../../../Library/Inputs/TextInput";
import MySelect from "../../../Library/Select/MySelect";
import QuestionnaireUtils from "../../QuestionnaireUtils";

const DisplayConditions: React.FC<any> = (props) => {
  const { questionnaireData, setQuestionnaireData } = props;
  const [fieldSelected, setFieldSelected] = React.useState(null);
  const [valueSelectInput, setValueSelectInput] = React.useState(null);
  const [valueInput, setValueInput] = React.useState("");
  const dispatch = useDispatch();
  const fieldOptions =
    QuestionnaireUtils.getFieldListOptions(questionnaireData);
  const fieldSelectedInfos = QuestionnaireUtils.getFieldSelectedInfos(
    questionnaireData,
    fieldSelected
  );

  return (
    <FullField title="Ajouter une condition d'arrêt" isMandatory={false}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing="10px"
        paddingBottom="10px"
      >
        <Grid item display="grid">
          <div style={{ width: "300px" }}>
            <MySelect
              placeholder="Champ"
              maxMenuHeight="200px"
              id="questionnaire-condition-field"
              isClearable={false}
              options={fieldOptions}
              onChange={(e) => {
                setValueInput("");
                setValueSelectInput(null);
                setFieldSelected(e?.value);
              }}
              value={
                !fieldSelected
                  ? null
                  : QuestionnaireUtils.getOptionFromValue(
                      fieldSelected,
                      fieldOptions
                    )
              }
            />
          </div>
        </Grid>
        {fieldSelectedInfos?.type === "protocole" && (
          <>
            <Grid item display="grid">
              <div style={{ width: "300px" }}>
                <span>{JSON.stringify(fieldSelectedInfos)}</span>
              </div>
            </Grid>
          </>
        )}
        {!["protocole"].includes(fieldSelectedInfos?.type) && (
          <>
            <Grid item display="grid">
              {fieldSelectedInfos?.option?.length > 0 ? (
                <div style={{ width: "300px" }}>
                  <MySelect
                    id="questionnaire-condition-value-select"
                    maxMenuHeight="200px"
                    placeholder="Valeur du champ"
                    isClearable={false}
                    value={
                      valueSelectInput
                        ? { value: valueSelectInput, label: valueSelectInput }
                        : null
                    }
                    onChange={(e) => setValueSelectInput(e?.value)}
                    options={fieldSelectedInfos?.option?.map((element) => {
                      return { label: element, value: element };
                    })}
                  />
                </div>
              ) : (
                <div style={{ width: "300px" }}>
                  <TextInput
                    id="questionnaire-condition-value"
                    placeholder="Valeur du champ"
                    value={valueInput}
                    onChange={(e) => setValueInput(e.target.value)}
                  />
                </div>
              )}
            </Grid>
          </>
        )}
        <Grid item display="grid">
          <AddButton
            onClick={() => {
              if (!fieldSelected) {
                dispatch(
                  openSnackbar({
                    type: "error",
                    message: "Aucun champ n'a été selectionné",
                    duration: 3000,
                  })
                );
                return;
              }
              if (!valueSelectInput && !valueInput) {
                dispatch(
                  openSnackbar({
                    type: "error",
                    message: "Veuillez saisir la valeur du champ",
                    duration: 3000,
                  })
                );
                return;
              }
              const questionnaireDataTmp = { ...questionnaireData };
              for (let i = 0; i < questionnaireDataTmp?.sections?.length; i++) {
                for (
                  let j = 0;
                  j < questionnaireDataTmp?.sections[i]?.fields?.length;
                  j++
                ) {
                  for (
                    let k = 0;
                    k < questionnaireDataTmp?.sections[i]?.fields[j]?.length;
                    k++
                  ) {
                    if (
                      questionnaireDataTmp?.sections[i]?.fields[j][k]?.id ===
                      fieldSelected
                    ) {
                      if (!questionnaireData.sections[i].fields[j][k].stops) {
                        questionnaireData.sections[i].fields[j][k].stops = [];
                      }
                      questionnaireData.sections[i].fields[j][k].stops.push({
                        type: "eq",
                        value: valueSelectInput ? valueSelectInput : valueInput,
                      });
                    }
                  }
                }
              }
              setFieldSelected(null);
              setValueInput("");
              setValueSelectInput(null);
              setQuestionnaireData(questionnaireDataTmp);
            }}
            displayText={true}
            text="Ajouter"
          />
        </Grid>
      </Grid>
    </FullField>
  );
};

export default DisplayConditions;
