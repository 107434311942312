import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    message: '',
    type: 'error',
    isOpen: false,
    duration: 10000,
    width: null,
    bottomCenter: {
      message: '',
      type: 'error',
      isOpen: false,
      duration: 10000,
      width: null,
    },
    bottomRight: {
      message: '',
      type: 'error',
      isOpen: false,
      duration: 10000,
      width: null,
    },
  },
  reducers: {
    openSnackbar: (state, action) => {
      const {message, type, duration, position, width} = action.payload;

      if (!position) {
        state.message = message;
        state.type = type;
        state.duration = duration;
        state.isOpen = true;
        state.width = width;
      } else {
        state[position].message = message;
        state[position].type = type;
        state[position].duration = duration;
        state[position].isOpen = true;
        state[position].width = width;
      }
    },
    closeSnackbar: (state, action=null) => {
      const position = action?.payload?.position;

      if (!position) {
        state.isOpen = false;
      } else {
        state[position].isOpen = false;
      }
    },
  }
})

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer;
