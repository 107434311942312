import { CircularProgress } from "@material-ui/core";
import { Grid } from "@mui/material";
import * as React from "react";
import ReactDOM from "react-dom";
import GreyButton from "../Buttons/GreyButton/GreyButton";
import OrangeButton from "../Buttons/OrangeButton/OrangeButton";

interface ICancellablePopupProps {
  onClose?: any;
  firstTitle?: string;
  secondTitle?: string;
  message?: string;
  messages?: Array<string>;
  cancelButtonText?: string;
  confirmButtonText?: string;
  children?: JSX.Element;
  onCancel?: any;
  onConfirm?: any;
  maxWidth?: string,
  isLoading?: boolean;
  zIndex?: number;
}

const CancellablePopup: React.FC<ICancellablePopupProps> = (props) => {
  const {
    firstTitle,
    secondTitle,
    onCancel,
    onClose,
    children,
    onConfirm,
    maxWidth,
    message,
    messages,
    cancelButtonText,
    isLoading,
    confirmButtonText,
    zIndex,
  } = props;
  return ReactDOM.createPortal(
    <>
      <Grid container column={12} component={undefined} className="modal-overlay" style={{
        zIndex: zIndex ? zIndex : undefined,
      }}>
        <Grid
          container
          className="modal-wrapper"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            wrap="nowrap"
            overflow="auto"
            direction="column"
            maxHeight="80vh"
            maxWidth={maxWidth}
            display="flex"
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: "24px",
              paddingTop: "40px",
              paddingLeft: "50px",
              paddingBottom: "65px",
              paddingRight: "42px",
            }}
          >
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item display="grid">
                  <span
                    style={{
                      textAlign: "center",
                      font: "normal normal bold 26px/39px Poppins",
                      color: "#5BB9C1",
                    }}
                  >
                    {firstTitle}{" "}
                    <span
                      style={{
                        textAlign: "center",
                        font: "normal normal bold 26px/39px Poppins",
                        color: "#0B243C",
                      }}
                    >
                      {secondTitle}
                    </span>
                  </span>
                </Grid>
                <Grid item display="grid">
                  <button
                    type="button"
                    className="modal-close-button"
                    onClick={onClose ? onClose : onCancel}
                  >
                    <span style={{ color: "#8D9899", textAlign: "center" }}>
                      &times;
                    </span>
                  </button>
                </Grid>
              </Grid>
            </Grid>
            {children && (
              <Grid item display="grid">
                {children}
              </Grid>
            )}
            {message?.length > 0 && (
              <Grid
                item
                display="grid"
                justifyContent="center"
                marginTop="56px"
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#657273",
                    font: "normal normal normal 16px/25px Poppins",
                  }}
                >
                  {message}
                </span>
              </Grid>
            )}
            {messages?.length > 0 && (
              <Grid
                container
                direction="column"
                wrap="nowrap"
                display="flex"
                justifyContent="center"
                marginTop="56px"
              >
                {messages?.map((element, index) => {
                  return (
                    <Grid key={index} item display="grid">
                      <span
                        style={{
                          textAlign: "center",
                          color: "#657273",
                          font: "normal normal normal 16px/25px Poppins",
                        }}
                      >
                        {element}
                      </span>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {!isLoading ? (
              <Grid item display="grid" marginTop="48px">
                <Grid
                  container
                  display="flex"
                  direction="row"
                  justifyContent="center"
                  columnSpacing="26px"
                  rowSpacing="5px"
                >
                  <Grid item display="grid">
                    <OrangeButton
                      animation={false}
                      enable={true}
                      onClick={onConfirm}
                      text={confirmButtonText}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <GreyButton
                      animation={false}
                      enable={true}
                      onClick={onCancel}
                      text={cancelButtonText}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item display="grid" marginTop="48px">
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>,
    document.body
  );
};

CancellablePopup.defaultProps = {
  firstTitle: "Default",
  secondTitle: "Default",
  message: "",
  messages: [],
  confirmButtonText: "Oui",
  cancelButtonText: "Non",
  isLoading: false,
  maxWidth: '80vw',
  onConfirm: () => {
    console.error(
      "You forgot the 'onConfirm' attribute in the 'CancellablePopup' component"
    );
  },
  onCancel: () => {
    console.error(
      "You forgot the 'onCancel' attribute in the 'CancellablePopup' component"
    );
  },
};

export default CancellablePopup;
