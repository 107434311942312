import * as React from 'react';
import OrangeButton from '../../Library/Buttons/OrangeButton/OrangeButton';
import ISendButtonItemProps from './ISendButtonItemProps';

const EnableSendButtonItem = (props: ISendButtonItemProps) => {
  return (
    <OrangeButton
      animation={true}
      enable={true}
      text='Envoyer'
      onClick={props.handleSumbit} />
  );
}

export default EnableSendButtonItem;