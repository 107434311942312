import * as React from 'react';

const UsersListTableTitles = () => {
  return (
    <>
      <span className='table-grid-title'>
        Compte
      </span>
      <span className='table-grid-title'>
        Email
      </span>
      <span className='table-grid-title'>
        Jetons
      </span>
      <span className='table-grid-title'>
        Expiration
      </span>
    </>
  )
}

export default UsersListTableTitles;