import { createSlice } from '@reduxjs/toolkit';
import IFormSchedule from '../types/IFormSchedule';


interface IinitialState {
    formScheduleData: IFormSchedule;
    title: string;
    isOpen: boolean;
}

export const editFormSchedule = createSlice({
    name: 'editFormSchedule',
    initialState: {
        formScheduleData: null,
        isOpen: false,
    } as IinitialState,
    reducers: {
        openEditFormSchedule: (state, action) => {
            const {formScheduleData, title} = action.payload
            state.formScheduleData = formScheduleData
            state.title = title;
            state.isOpen = true;
        },
        closeSchedule: (state) => {
            state.isOpen = false;
        },
    }
})

export const { openEditFormSchedule, closeSchedule } = editFormSchedule.actions

export default editFormSchedule.reducer;
