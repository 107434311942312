import { nanoid } from "nanoid";
import { IForm } from "../../../../types/FormTypes";

const initQuestionnaire = () => {
  return ({
    title: '',
    textRGPD: `<p dir = "auto"><span style="font-size: 12px;">Les données personnelles présentes dans ce formulaire sont collectées par <strong>« nom société représentant l’utilisateur »</strong>, agissant en tant que Responsable de traitement afin de vous accompagner dans « renseigner objectif du questionneur ». Elles seront accessibles par le personnel habilité <strong>« nom société représentant l’utilisateur »</strong>. Les données seront conservées pendant une durée de <strong>« à définir par le responsable du traitement »</strong> à compter de sa date de réception. Conformément à l'article L1111-8 du Code de la Santé Publique, vos données de santé sont hébergées par un hébergeur certifié Hébergeur Données de Santé (HDS). Vous disposez d'un droit d'accès, de rectification, de suppression, de limitation du traitement de données, ainsi que la possibilité de retirer votre consentement à tout moment. Pour toute information ou pour exercer vos droits, veuillez contacter notre DPO à <strong>contact@certipair.fr</strong>. Si vous estimez que vos droits Informatique et Libertés ne sont pas respectés, vous avez le droit d'introduire une réclamation auprès de la CNIL.</span></p>`,
    description: '',
    attachment: '',
    attachment_name: '',
    sections: [],
    specialities: [],
    notificationMessage: 'Bonjour {nom_patient} {prenom_patient},\nVotre réponse a bien été enregistrée.\n',
    emailMessage:
    "Bonjour,\n\nCi-dessous les réponses au questionnaire {nom_questionnaire}.\n\n{reponses_questionnaire}\n\nEnvoyé par {id_patient}.\nLe {date_reponse}.",
    emailObject: '{nom_questionnaire} - {id_patient}',
    emailDestinataires: [],
    confirmMessage: 'Votre réponse a bien été enregistrée.',
    conditionalConfirmMessages: [],
    stopMessage: "Il s'agit d'un critère d'exclusion, nous clôturons le protocole.",
    sendNotification: false,
    sendEmailAfterResponse: false,
    sendEmailAfterResponseConditionsInfos: {
      conditionsList: [],
    },
    displayMode: 'full',
    id: nanoid(6),
  });
}

export default initQuestionnaire;