import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import Creatable from "react-select/creatable";
import { updateSize } from "../redux/componentSizeReducer";
import QuestionnaireContent from "../components/Questionnaire/ListQuestionnaire/QuestionnaireContent";
import {
  Delete,
  DeleteRounded,
  Download,
  EditRounded,
  Launch,
  PreviewRounded,
  UpgradeOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import { useOpenSnackbar } from "./CreateOrEditChatbotLexPage";
import FormPopup from "../components/FormPopup/FormPopup";
import FormPopupHeader from "../components/FormPopup/FormPopupHeader";
import FullField from "../components/Fields/FullField";
import makeAPIRequest from "../utils/makeAPIRequest";
import OrangeButton from "../components/Library/Buttons/OrangeButton/OrangeButton";
import { openSnackbar } from "../redux/snackbarReducer";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import StartQuestionnaire from "../components/Questionnaire/StartQuestionnaire";
import { useHistory } from "react-router";
import useUsersList from "../utils/hooks/useUsersList";
import getOnlyValuesFromArray from "../utils/getOnlyValuesFromArray";
import SendResponsePopup, {
  emailValidator,
} from "../components/Questionnaire/SendResponsePopup";
import DuplicatePopup from "../components/Questionnaire/DuplicatePopup";
import { Tab, Tabs } from "react-bootstrap";
import { formatDateFromTimestamp } from "../components/MonPatientBox/MonPatientBox";
import { IForm } from "../types/FormTypes";
import { FormScheduledBox } from "./QuestionnairesScheduledPage";
import MySelect, { MySelectMulti } from "../components/Library/Select/MySelect";
import useAppSelector from "../redux/useAppSelector";
import IUsersData from "../types/Users";
import useQuery from "../utils/hooks/useQuery";
import { MySimpleCreateButton } from "../components/TutorialsList";
import {
  GenericFromPopup,
  GenericSendButton,
  MoreOrLessButtons,
} from "./ChatbotLexPage";
import { GenericTextInput } from "./CreateOrEditChatbotLexPage";
import HandleErrors from "../components/Library/Errors/HandleErrors";
import { GenericCreatabale } from "../components/Questionnaire/EditQuestionnaire/QuestionnairePopupEdit/Fields/DisplayAdditionAuto";
import useAppDispatch from "../redux/useAppDispatch";
import { getGraphiqueAraignee } from "../redux/new/graphAraigneeReducer";
import CancellablePopup from "../components/Library/Popups/CancellablePopup";
import GenericRadar from "../components/Library/Draft/GenericRadar";
import AddButton from "../components/Library/Buttons/AddButton/AddButton";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import SendResponseMedimailPopup, {
  MedimailSearch,
} from "../components/Questionnaire/SendResponseMedimailPopup";
import { downloadCSV } from "./AdminGestionIncidentsPage";
import { getFormPdf } from "../redux/new/formPdfReducer";
import {
  PDFElement,
  PDFQuestionnaire,
} from "../components/Questionnaire/PDF/CreateOrEditPDFQuestionnaire";
import MyEditor from "../components/Library/Inputs/MyEditor";
import { createPDFWithElementIds } from "../utils/exportPdf";
import GenericDoughnut from "../components/Library/Draft/GenericDoughnut";
import { cleanHTML } from "../components/Questionnaire/GenerateQuestionnaire/GenerateQuestionnaire";
import MultilineInput from "../components/Library/Inputs/MultilineInput";
import TextInput from "../components/Library/Inputs/TextInput";
import GenericStackedBar from "../components/Library/Draft/GenericStackedBar";
import GenericHorizontalBar from "../components/Library/Draft/GenericHorizontalBar";
import { LIST_CPTS } from "../components/Questionnaire/EditQuestionnaire/QuestionnaireEdit";
import {
  languageConverter,
  removeParenthesis,
} from "../components/MesConseilsBox/TabsContainer/AdminTab/AdminTabContent";

interface ButtonInCard {
  title: string;
  Icon: any;
  onClick: () => void;
  color?: string;
  disable?: boolean;
}

export interface GraphItem {
  id: string;
  color: string;
  legend: string;
  quantity: number;
}

const fixStringNumber = (value: string) => {
  if (!value) {
    return undefined;
  }
  const parsed = parseInt(value.replace(/[^\d.-]/g, ""));
  if (isNaN(parsed)) {
    return undefined;
  }
  return parsed;
};

const DrawButtons: React.FC<{
  btns: ButtonInCard[];
  headerIsPresent?: boolean;
  buttonsSpacing?: string;
  buttonsPadding?: string;
}> = (props) => {
  const { btns, headerIsPresent, buttonsPadding, buttonsSpacing } = props;

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap={buttonsSpacing}
      justifySelf="center"
      alignSelf="center"
      paddingLeft={buttonsPadding}
      paddingBottom={buttonsPadding}
      paddingRight={buttonsPadding}
      paddingTop={headerIsPresent ? "0px" : buttonsPadding}
    >
      {btns.map((btn, index) => {
        return (
          <Grid item display="grid" key={index}>
            <Tooltip title={btn.title}>
              <IconButton
                onClick={
                  btn?.disable
                    ? () => {
                        return;
                      }
                    : btn.onClick
                }
                style={{
                  margin: "0",
                  padding: "0",
                  cursor: btn?.disable ? `not-allowed` : `pointer`,
                }}
              >
                <btn.Icon
                  fontSize="small"
                  style={{
                    color: btn.color ? btn.color : "#0B243C",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const GenericCard: React.FC<{
  leftButtons?: Array<ButtonInCard>;
  rightButtons?: Array<ButtonInCard>;
  Header?: JSX.Element;
  buttonsSpacing?: string;
  buttonsPadding?: string;
  textPin?: string;
  rightPin?: string;
}> = (props) => {
  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={"conseil-card"}
        style={{
          fontSize: "16px",
          fontFamily: "Poppins",
          color: "#0B243C",
          padding: "0",
          animationName: "unset",
          margin: "0",
        }}
      >
        {props.rightPin && (
          <>
            <div
              style={{
                display: "flex",
                fontSize: "12px",
                background: (() => {
                  if (props.rightPin === "Validé") {
                    return "rgb(91, 185, 193) no-repeat padding-box";
                  }
                  if (props.rightPin === "Envoyé") {
                    return "rgb(91, 185, 193) no-repeat padding-box";
                  }
                  return "#000";
                })(),
                borderRadius: "0px 0px 10px 10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "5px",
                paddingTop: "5px",
                position: "absolute",
                top: "0px",
                right: "25px",
              }}
            >
              <p
                style={{
                  margin: "auto",
                  color: "white",
                }}
              >
                {props.rightPin}
              </p>
            </div>
            <div style={{ paddingBottom: props.textPin ? 0 : "32px" }}></div>
          </>
        )}
        {props.textPin && (
          <>
            <div
              style={{
                display: "flex",
                fontSize: "12px",
                background: (() => {
                  if (props.textPin.startsWith("gpt")) {
                    return "rgb(0, 0, 0) no-repeat padding-box";
                  }
                  if (props.textPin === "Non payé") {
                    return "#000";
                  }
                  if (props.textPin === "Payé") {
                    return "rgb(91, 185, 193) no-repeat padding-box";
                  }
                  return "rgb(91, 185, 193) no-repeat padding-box";
                })(),
                borderRadius: "0px 0px 10px 10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "5px",
                paddingTop: "5px",
                position: "absolute",
                top: "0px",
                left: "25px",
              }}
            >
              <p
                style={{
                  margin: "auto",
                  color: "white",
                }}
              >
                {props.textPin}
              </p>
            </div>
            <div style={{ paddingBottom: "32px" }}></div>
          </>
        )}
        {props.Header && (
          <Grid
            item
            display="grid"
            paddingLeft="15px"
            paddingTop="10px"
            paddingBottom="10px"
            paddingRight="15px"
          >
            {props.Header}
          </Grid>
        )}
        <Grid item display="grid">
          <Grid container direction="row" wrap="nowrap">
            {props.leftButtons && props.leftButtons?.length > 0 && (
              <Grid
                item
                display="grid"
                style={{
                  borderRight: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <DrawButtons
                  btns={props.leftButtons}
                  buttonsSpacing={props.buttonsSpacing}
                  buttonsPadding={props.buttonsPadding}
                  headerIsPresent={!!props.Header}
                />
              </Grid>
            )}
            <Grid item display="grid" xs padding="15px">
              {props.children}
            </Grid>
            {props.rightButtons && props.rightButtons?.length > 0 && (
              <Grid
                item
                display="grid"
                style={{
                  borderLeft: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <DrawButtons
                  buttonsSpacing={props.buttonsSpacing}
                  buttonsPadding={props.buttonsPadding}
                  btns={props.rightButtons}
                  headerIsPresent={!!props.Header}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

GenericCard.defaultProps = {
  Header: undefined,
  leftButtons: undefined,
  rightButtons: undefined,
  buttonsPadding: "10px",
  buttonsSpacing: "15px",
};

interface ISendResponsesByMailButton {
  onClickSendResponse: () => void;
}

export const BlueButton: React.FC<{
  label: string;
  overrideButton?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;
}> = (props) => {
  return (
    <button
      style={{
        boxShadow: "2px 6px 10px #0F213467",
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: "20px",
        maxWidth: "330px",
        maxHeight: "50px",
        paddingRight: "20px",
        borderRadius: "18px",
        opacity: 1,
        background: "#0B243C 0% 0% no-repeat padding-box",
        letterSpacing: "0px",
        color: "#FFFFFF",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "12px",
        fontFamily: "Poppins",
        justifySelf: "flex-end",
      }}
      {...props.overrideButton}
    >
      <Grid item display="grid">
        <Grid
          container
          spacing="7px"
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item display="grid">
            <span>{props.label}</span>
          </Grid>
        </Grid>
      </Grid>
    </button>
  );
};

BlueButton.defaultProps = {
  overrideButton: {},
};

const SendResponsesByMailButton = (props: ISendResponsesByMailButton) => {
  const { onClickSendResponse } = props;
  return (
    <BlueButton
      label="Envoyer des réponses"
      overrideButton={{
        onClick: onClickSendResponse,
      }}
    />
  );
};

export interface FormSettings {
  user_id?: string;
  displayTextAtEnd?: boolean;
  textToDisplayAtEnd?: string;
}

export const FormsSettings: React.FC<any> = (props) => {
  const { setIsFormsSettingsOpen } = props;
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [settings, setSettings] = React.useState<FormSettings>(undefined);
  const snackbar = useOpenSnackbar();

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/questions/forms/settings", null, "v3")
      .then((res) => {
        if (res.data) setSettings(res?.data);
      })
      .catch((err) => {
        snackbar.error("Impossible de récupérer la liste des paramètres.", err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <GenericFromPopup
        onClose={() => setIsFormsSettingsOpen(false)}
        title={{ leftPart: "Mes", rightPart: "Paramètres" }}
      >
        {!isLoading ? (
          <Grid container direction="column" wrap="nowrap" gap="10px">
            <Grid item display="grid">
              <OrangeCheckBox
                text={"Afficher un texte à la fin de chaque questionnaire"}
                value={settings?.displayTextAtEnd}
                onClick={() => {
                  setSettings((x) => {
                    return {
                      ...x,
                      displayTextAtEnd: x.displayTextAtEnd ? false : true,
                    };
                  });
                }}
              />
            </Grid>
            {settings?.displayTextAtEnd && (
              <Grid item display="grid">
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <MyEditor
                    defaultFontFamily="Poppins"
                    defaultFontSize="16px"
                    defaultColor="#0B243C"
                    initialContent={settings?.textToDisplayAtEnd}
                    onUpdate={(html) => {
                      setSettings((x) => {
                        return { ...x, textToDisplayAtEnd: html };
                      });
                    }}
                  />
                </div>
              </Grid>
            )}
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{}}
                errorSelector={{}}
                invalidConditions={{}}
                textButton="Mettre à jour"
                onSendClick={() => {
                  makeAPIRequest(
                    "post",
                    "/questions/forms/settings/update",
                    settings,
                    "v3"
                  )
                    .then((res) => {
                      snackbar.success(
                        "Les paramètres ont été mis à jour avec succès.",
                        res
                      );
                      setIsFormsSettingsOpen(false);
                    })
                    .catch((err) => {
                      snackbar.error(
                        "La mise à jour des paramètres a échouée.",
                        err
                      );
                    });
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom="50px"
          >
            <CircularProgress />
          </Grid>
        )}
      </GenericFromPopup>
    </>
  );
};

export const FormsAdminPanel: React.FC<any> = (props) => {
  const { setIsFormsAdminPanelOpen } = props;
  const [adminList, setAdminList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const { usersList, isLoading, setIsLoading, formatThisList } = useUsersList();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!usersList || usersList.length === 0) return;
    setIsLoading(true);
    makeAPIRequest("get", `/questions/access/list`, null, "v3")
      .then((res) => {
        setAdminList(formatThisList(res?.data?.admins));
        setUserList(formatThisList(res?.data?.users));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [usersList]);

  return (
    <>
      <FormPopup>
        <Grid
          item
          display="grid"
          paddingTop="40px"
          paddingLeft="50px"
          paddingRight="50px"
          paddingBottom="40px"
        >
          <FormPopupHeader
            firstTitle={"Gérer"}
            secondTitle={"les droits"}
            onClose={() => {
              setIsFormsAdminPanelOpen(false);
            }}
          />
        </Grid>
        {!isLoading ? (
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              wrap="nowrap"
              paddingBottom="40px"
            >
              <Grid item display="grid">
                <FullField
                  title="Liste des administrateurs"
                  isMandatory={false}
                >
                  <div
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid transparent",
                    }}
                  >
                    <Creatable
                      noOptionsMessage={() => "Aucune option"}
                      formatCreateLabel={(userInput) => `...`}
                      isMulti={true}
                      maxMenuHeight={120}
                      options={(() => {
                        const optionsTmp = [];
                        for (const user of usersList) {
                          if (user?.email && user.email?.length > 0) {
                            optionsTmp.push({
                              label: user?.email,
                              value: user?.user_id,
                            });
                          }
                        }
                        return optionsTmp;
                      })()}
                      value={adminList}
                      placeholder="Ajouter un administrateur"
                      onChange={(e) => {
                        setAdminList(
                          e?.filter((opt) =>
                            usersList?.find((user) => user?.email == opt?.label)
                          )
                        );
                      }}
                    />
                  </div>
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="20px">
                <FullField title="Liste des utilisateurs" isMandatory={false}>
                  <div
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid transparent",
                    }}
                  >
                    <Creatable
                      noOptionsMessage={() => "Aucune option"}
                      formatCreateLabel={(userInput) => `...`}
                      isMulti={true}
                      maxMenuHeight={120}
                      options={(() => {
                        const optionsTmp = [];
                        for (const user of usersList) {
                          if (user?.email && user.email?.length > 0) {
                            optionsTmp.push({
                              label: user?.email,
                              value: user?.user_id,
                            });
                          }
                        }
                        return optionsTmp;
                      })()}
                      value={userList}
                      placeholder="Ajouter un utilisateur"
                      onChange={(e) => {
                        setUserList(
                          e?.filter((opt) =>
                            usersList?.find((user) => user?.email == opt?.label)
                          )
                        );
                      }}
                    />
                  </div>
                </FullField>
              </Grid>
              <Grid
                item
                display="grid"
                alignItems="center"
                justifyContent="center"
                paddingTop="30px"
              >
                <OrangeButton
                  animation={true}
                  enable={true}
                  type="submit"
                  text="Mettre à jour"
                  onClick={() => {
                    makeAPIRequest(
                      "post",
                      `/questions/access/update`,
                      {
                        users: getOnlyValuesFromArray(userList),
                        admins: getOnlyValuesFromArray(adminList),
                      },
                      "v3"
                    )
                      .then((res) => {
                        dispatch(
                          openSnackbar({
                            duration: 3000,
                            message: "Les droits ont bien été mis à jour.",
                            type: "success",
                          })
                        );
                        setIsFormsAdminPanelOpen(false);
                      })
                      .catch((err) => {
                        dispatch(
                          openSnackbar({
                            duration: 3000,
                            message: "Impossible de mettre à jour les droits.",
                            type: "error",
                          })
                        );
                        console.log(err);
                      });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom="50px"
          >
            <CircularProgress />
          </Grid>
        )}
      </FormPopup>
    </>
  );
};

export const MyTabTitle: React.FC<{
  text: string;
  onClick: () => void;
}> = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "50px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <span
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

const CardHeaderAdmin: React.FC<{
  adminQuestionnaire: IForm;
  usersList: IUsersData[];
}> = (props) => {
  const specialitiesDetailed = useAppSelector(
    (x) => x.languageReducer.specialities
  );
  const { adminQuestionnaire, usersList } = props;

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      paddingTop="10px"
      paddingLeft="10px"
      paddingRight="10px"
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      <Grid item display="grid">
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {adminQuestionnaire?.title}
        </span>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {getSpecialityTextFromArrayOfSpecialitiesId(
            adminQuestionnaire?.specialities,
            specialitiesDetailed
          )}
        </span>
      </Grid>
      {usersList && (
        <Grid item display="grid">
          <span>
            Auteur :{" "}
            {(() => {
              const user = usersList.find(
                (u) => u?.user_id === adminQuestionnaire?.user_id
              );
              return `${user?.first_name} ${user?.last_name}`;
            })()}
          </span>
        </Grid>
      )}
      <Grid item display="grid">
        <span>
          Créé le {formatDateFromTimestamp(adminQuestionnaire?.creation_date)}
        </span>
      </Grid>
      {adminQuestionnaire?.update_date && (
        <Grid item display="grid">
          <span>
            Dernière modification le{" "}
            {formatDateFromTimestamp(adminQuestionnaire?.update_date)}
          </span>
        </Grid>
      )}
      {adminQuestionnaire?.lastValidation && (
        <Grid item display="grid" justifyContent="flex-end">
          <span>
            Validé le{" "}
            {formatDateFromTimestamp(adminQuestionnaire?.lastValidation)}
          </span>
        </Grid>
      )}
    </Grid>
  );
};

const AdminFormsContent: React.FC = (props) => {
  const { usersList } = useUsersList();
  const dispatch = useDispatch();
  const history = useHistory();
  const specialities = useAppSelector((x) => x.languageReducer.displaySpec);
  const [adminQuestionnaires, setAdminQuestionnaires] = React.useState<IForm[]>(
    []
  );
  const [maxItem, setMaxItem] = React.useState<number>(10);
  const [filter, setFilter] = React.useState<{
    formState: "validated" | "not_validated" | "need_update" | undefined;
    userId: string | undefined;
    speciality: string | undefined;
  }>({
    formState: undefined,
    userId: undefined,
    speciality: undefined,
  });
  const [sorting, setSorting] = React.useState<{
    direction: "ascendant" | "descendant";
    type: "creation_date" | "lastValidation" | "update_date";
  }>({
    direction: "descendant",
    type: "creation_date",
  });
  const [isAdminLoading, setIsAdminLoading] = React.useState<boolean>(false);
  const listUsersIdHavingForm = adminQuestionnaires.map((x) => x?.user_id);
  const filterStateOptions = [
    {
      label: "Validé",
      value: "validated",
    },
    {
      label: "Non validé",
      value: "not_validated",
    },
    /*
    {
      label: "À mettre à jour",
      value: "need_update",
    },
    */
  ];

  const sortingDirectionOptions = [
    {
      label: "Ascendant",
      value: "ascendant",
    },
    {
      label: "Descendant",
      value: "descendant",
    },
  ];

  const sortingTypeOptions = [
    {
      label: "Date de création",
      value: "creation_date",
    },
    {
      label: "Date de validation",
      value: "lastValidation",
    },
    {
      label: "Date de modification",
      value: "update_date",
    },
  ];

  React.useEffect(() => {
    setIsAdminLoading(true);
    makeAPIRequest(
      "post",
      "/questions/forms/admin_list",
      { filter: filter, sorting: sorting },
      "v3"
    )
      .then((res) => {
        setAdminQuestionnaires(res?.data);
        setIsAdminLoading(false);
      })
      .catch(() => {
        setIsAdminLoading(false);
      });
  }, [filter, sorting]);

  const checkNeedUpdate = (x: IForm) => {
    if (
      x?.lastValidation &&
      x?.update_date &&
      x?.update_date > x?.lastValidation
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing="10px"
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#0B243C",
        }}
      >
        <Grid item display="grid">
          <span>Filtrer :</span>
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            options={filterStateOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              filter.formState
                ? filterStateOptions.find((x) => x.value === filter.formState)
                : undefined
            }
            placeholder="État"
            onChange={(e) => {
              setFilter((state) => {
                return { ...state, formState: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            options={(() => {
              const optionsTmp = [];
              for (const user of usersList) {
                if (
                  user?.email &&
                  user?.token &&
                  user.email?.length > 0 &&
                  listUsersIdHavingForm.includes(user?.user_id)
                ) {
                  optionsTmp.push({
                    label: user?.email,
                    value: user?.user_id,
                  });
                }
              }
              return optionsTmp;
            })()}
            noOptionsMessage={() => "Aucun résultat"}
            value={(() => {
              if (!filter.userId) {
                return undefined;
              }
              const user = usersList.find((x) => x?.user_id === filter.userId);
              if (!user || !user?.email) {
                return undefined;
              }
              return {
                value: filter.userId,
                label: user.email,
              };
            })()}
            placeholder="Auteur"
            onChange={(e) => {
              setFilter((state) => {
                return { ...state, userId: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing="10px"
        paddingTop="10px"
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#0B243C",
        }}
      >
        <Grid item display="grid">
          <span>Trier :</span>
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            isClearable={false}
            options={sortingTypeOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              sorting.type
                ? sortingTypeOptions.find((x) => x.value === sorting.type)
                : undefined
            }
            placeholder=""
            onChange={(e) => {
              setSorting((state) => {
                return { ...state, type: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            isClearable={false}
            options={sortingDirectionOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              sorting.direction
                ? sortingDirectionOptions.find(
                    (x) => x.value === sorting.direction
                  )
                : undefined
            }
            placeholder="Direction"
            onChange={(e) => {
              setSorting((state) => {
                return { ...state, direction: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
      </Grid>
      {isAdminLoading ? (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : adminQuestionnaires?.length == 0 ? (
        <Grid item display="grid" paddingTop="20px">
          <span
            style={{
              fontFamily: "Poppins",
              color: "#0B243C",
              fontSize: "16px",
            }}
          >
            Aucun questionnaire trouvé.
          </span>
        </Grid>
      ) : (
        <ul className="list-group" style={{ paddingTop: "10px" }}>
          {adminQuestionnaires
            .slice(0, maxItem)
            .map((adminQuestionnaire, index) => {
              return (
                <li
                  className={"conseil-card"}
                  style={{ padding: "0", animationName: "unset" }}
                  key={index}
                >
                  <Grid container direction="row" wrap="nowrap">
                    <Grid
                      item
                      display="grid"
                      style={{
                        borderRight: "1px solid rgba(0, 0, 0, 0.125)",
                      }}
                    >
                      <Tooltip
                        title={
                          checkNeedUpdate(adminQuestionnaire)
                            ? "Mettre à jour"
                            : adminQuestionnaire?.lastValidation
                            ? "Déjà validé"
                            : "Valider"
                        }
                      >
                        <IconButton
                          style={{
                            margin: "0",
                            padding: "0",
                            marginLeft: "20px",
                            marginRight: "20px",
                            alignSelf: "center",
                            justifySelf: "center",
                            cursor:
                              adminQuestionnaire?.lastValidation &&
                              !checkNeedUpdate(adminQuestionnaire)
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={
                            adminQuestionnaire?.lastValidation &&
                            !checkNeedUpdate(adminQuestionnaire)
                              ? () => {
                                  return;
                                }
                              : () => {
                                  makeAPIRequest(
                                    "get",
                                    `/questions/form/${adminQuestionnaire.id}/valid`,
                                    null,
                                    "v3"
                                  )
                                    .then(() => {
                                      makeAPIRequest(
                                        "post",
                                        "/questions/forms/admin_list",
                                        { filter: filter, sorting: sorting },
                                        "v3"
                                      )
                                        .then((res) => {
                                          setAdminQuestionnaires(res?.data);
                                          dispatch(
                                            openSnackbar({
                                              message:
                                                "Le questionnaire vient d'être validé",
                                              duration: 3000,
                                              type: "success",
                                            })
                                          );
                                        })
                                        .catch(() => {
                                          dispatch(
                                            openSnackbar({
                                              message:
                                                "Impossible de valider ce questionnaire",
                                              duration: 3000,
                                              type: "error",
                                            })
                                          );
                                        });
                                    })
                                    .catch(() => {
                                      dispatch(
                                        openSnackbar({
                                          message:
                                            "Impossible de valider ce questionnaire",
                                          duration: 3000,
                                          type: "error",
                                        })
                                      );
                                    });
                                }
                          }
                        >
                          {checkNeedUpdate(adminQuestionnaire) ? (
                            <UpgradeOutlined
                              fontSize="small"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <VerifiedOutlined
                              fontSize="small"
                              style={{
                                color: adminQuestionnaire?.lastValidation
                                  ? "red"
                                  : "green",
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs
                      display="grid"
                      style={{
                        paddingLeft: "10px",
                        paddingBottom: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <CardHeaderAdmin
                        adminQuestionnaire={adminQuestionnaire}
                        usersList={usersList}
                      />
                    </Grid>
                    <Grid
                      item
                      display="grid"
                      style={{
                        borderLeft: "1px solid rgba(0, 0, 0, 0.125)",
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        gap="15px"
                        padding="15px"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item display="grid">
                          <Tooltip title="Modifier">
                            <IconButton
                              style={{ margin: "0", padding: "0" }}
                              onClick={() => {
                                history.push(
                                  `/edit_questionnaire/${adminQuestionnaire?.id}`
                                );
                              }}
                            >
                              <EditRounded
                                fontSize="small"
                                style={{ color: "#0B243C" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item display="grid">
                          <Tooltip title="Ouvrir">
                            <IconButton
                              style={{
                                margin: "0",
                                padding: "0",
                              }}
                              onClick={() => {
                                window
                                  .open(
                                    `/#/q/${adminQuestionnaire?.id}`,
                                    "_blank"
                                  )
                                  .focus();
                              }}
                            >
                              <Launch
                                fontSize="small"
                                style={{ color: "#0B243C" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </li>
              );
            })}
        </ul>
      )}
      {!isAdminLoading && (
        <Grid item display="grid">
          <MoreOrLessButtons
            items={adminQuestionnaires}
            maxItem={maxItem}
            setMaxItem={setMaxItem}
          />
        </Grid>
      )}
    </>
  );
};

export type AccessType = "user" | "admin" | "";

export const getSpecialityTextFromArrayOfSpecialitiesId = (
  specialitiesId: string[],
  specialities: any[]
) => {
  if (!Array.isArray(specialitiesId)) {
    return undefined;
  }
  if (specialitiesId.length === 0) {
    return undefined;
  }
  if (!specialities || specialities?.length === 0) {
    return undefined;
  }
  const array = specialitiesId.map((x) => {
    const label = specialities.find((y) => y?.value === x)?.label;
    if (label) {
      return label;
    }
  });
  if (array.length == 0) {
    return undefined;
  }
  return array.join(", ");
};

const CardHeaderValidated: React.FC<{ validatedQuestionnaire: IForm }> = (
  props
) => {
  const specialitiesDetailed = useAppSelector(
    (x) => x.languageReducer.specialities
  );
  const { validatedQuestionnaire } = props;
  const speciality = getSpecialityTextFromArrayOfSpecialitiesId(
    validatedQuestionnaire?.specialities,
    specialitiesDetailed
  );
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems="flex-start"
      justifyContent="center"
      paddingTop="10px"
      paddingLeft="10px"
      paddingRight="10px"
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      <Grid item display="grid">
        <span
          style={{
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          {validatedQuestionnaire?.title}
        </span>
      </Grid>
      {speciality && (
        <Grid item display="grid">
          <span
            style={{
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {speciality}
          </span>
        </Grid>
      )}
    </Grid>
  );
};

const ValidatedFormsContent: React.FC<{
  formsAccess: AccessType;
}> = (props) => {
  const [maxItem, setMaxItem] = React.useState<number>(10);
  const specialities = useAppSelector((x) => x.languageReducer.displaySpec);
  const [filter, setFilter] = React.useState<{
    speciality: string | undefined;
    validatedQuestionnaireId: string | undefined;
    cptsName: string | undefined;
  }>({
    speciality: undefined,
    validatedQuestionnaireId: undefined,
    cptsName: undefined,
  });
  const dispatch = useDispatch();
  const specialitiesNotFront = useAppSelector(
    (state) => state.languageReducer.specialities
  );
  const [validatedQuestionnaires, setValidatedQuestionnaires] = React.useState<
    IForm[]
  >([]);
  const [validatedQuestionnairesFiltered, setValidatedQuestionnairesFiltered] =
    React.useState<IForm[]>([]);
  const [
    isValidatedQuestionnairesLoading,
    setIsValidatedQuestionnairesLoading,
  ] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsValidatedQuestionnairesLoading(true);
    makeAPIRequest("get", "/questions/validated_forms/list", null, "v3")
      .then((res) => {
        setValidatedQuestionnaires(res?.data);
        setIsValidatedQuestionnairesLoading(false);
      })
      .catch(() => {
        setIsValidatedQuestionnairesLoading(false);
      });
  }, []);

  const refreshValidatedQuestionnairesList = (
    afterSuccess: () => void,
    afterFailure: () => void
  ) => {
    makeAPIRequest("get", "/questions/validated_forms/list", null, "v3")
      .then((res) => {
        setValidatedQuestionnaires(res?.data);
        afterSuccess();
      })
      .catch(() => {
        afterFailure();
      });
  };

  const isValidSpeciality = (
    specialitiesInQuest: string[],
    displaySpecId: string
  ) => {
    if (!specialities || !specialitiesInQuest || !displaySpecId) {
      return false;
    }
    const founded = specialities.find((x) => x?.value == displaySpecId);
    if (!founded?.specialities) {
      return false;
    }
    for (const specialityInQuest of specialitiesInQuest) {
      const toIntegerSpe = parseInt(specialityInQuest);
      if (
        !isNaN(toIntegerSpe) &&
        founded?.specialities?.includes(toIntegerSpe)
      ) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    if (
      !validatedQuestionnaires ||
      !Array.isArray(validatedQuestionnaires) ||
      !specialities
    ) {
      return;
    }
    let validatedQuestionnairesTmp = [...validatedQuestionnaires];
    if (filter.speciality) {
      validatedQuestionnairesTmp = validatedQuestionnairesTmp.filter(
        (x) =>
          Array.isArray(x?.specialities) &&
          isValidSpeciality(x?.specialities, filter.speciality)
      );
    }
    if (filter.cptsName) {
      validatedQuestionnairesTmp = validatedQuestionnairesTmp.filter(
        (x) => x.cptsName === filter.cptsName
      );
    }
    if (filter.validatedQuestionnaireId) {
      validatedQuestionnairesTmp = validatedQuestionnairesTmp.filter(
        (x) => x?.id == filter.validatedQuestionnaireId
      );
    }
    setValidatedQuestionnairesFiltered(validatedQuestionnairesTmp);
  }, [filter, validatedQuestionnaires, specialities]);

  if (isValidatedQuestionnairesLoading) {
    return (
      <Grid item display="grid" paddingTop="20px">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container direction="column" wrap="nowrap" gap="10px">
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing="10px"
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              color: "#0B243C",
            }}
          >
            <Grid item display="grid">
              <span>Filtrer :</span>
            </Grid>
            <Grid item display="grid" xs>
              <MySelect
                noOptionsMessage={() => "Aucun résultat"}
                options={validatedQuestionnaires.map((x) => {
                  return {
                    value: x?.id,
                    label: x?.title,
                  };
                })}
                value={(() => {
                  const x = validatedQuestionnaires.find(
                    (x) => x?.id == filter.validatedQuestionnaireId
                  );
                  if (!x) {
                    return undefined;
                  }
                  return {
                    label: x?.title,
                    value: x?.id,
                  };
                })()}
                placeholder="Titre"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, validatedQuestionnaireId: e?.value };
                  });
                }}
              />
            </Grid>
            <Grid item display="grid" xs>
              <MySelect
                noOptionsMessage={() => "Aucun résultat"}
                options={specialities}
                value={
                  specialities && filter.speciality
                    ? (() => {
                        const obj = specialities.find(
                          (y) => y?.value === filter.speciality
                        );
                        if (obj && obj?.label) {
                          return obj;
                        }
                        return undefined;
                      })()
                    : undefined
                }
                placeholder="Spécialité"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, speciality: e?.value };
                  });
                }}
              />
            </Grid>
            <Grid item xs display="grid">
              <MySelect
                noOptionsMessage={() => "Aucun résultat"}
                options={LIST_CPTS.map((x) => {
                  return { label: x, value: x };
                })}
                value={
                  filter.cptsName
                    ? {
                        label: filter.cptsName,
                        value: filter.cptsName,
                      }
                    : undefined
                }
                placeholder="CPTS"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, cptsName: e?.value };
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          {validatedQuestionnairesFiltered?.length == 0 ? (
            <span
              style={{
                fontFamily: "Poppins",
                color: "#0B243C",
                fontSize: "16px",
              }}
            >
              Aucun questionnaire trouvé.
            </span>
          ) : (
            <Grid container direction="column" wrap="nowrap" gap="10px">
              {validatedQuestionnairesFiltered
                .slice(0, maxItem)
                .map((validatedQuestionnaire, index) => {
                  const speciality = validatedQuestionnaire?.cptsName
                    ? validatedQuestionnaire.cptsName
                    : specialitiesNotFront?.length > 0 &&
                      validatedQuestionnaire?.specialities?.length > 0
                    ? validatedQuestionnaire?.specialities
                        .map((spe) =>
                          languageConverter.getSpecialityById(
                            spe as any,
                            specialitiesNotFront
                          )
                        )
                        .join(", ")
                    : ``;
                  return (
                    <Grid item display="grid" key={index}>
                      <GenericCard
                        key={index}
                        rightPin={
                          speciality ? removeParenthesis(speciality) : undefined
                        }
                        rightButtons={[
                          {
                            title: "Ouvrir",
                            onClick: () => {
                              window
                                .open(
                                  `/#/q/${validatedQuestionnaire?.id}?v=1`,
                                  "_blank"
                                )
                                .focus();
                            },
                            Icon: Launch,
                            color: "#0B243C",
                          },
                        ]}
                        leftButtons={[
                          {
                            title: "Importer",
                            Icon: Download,
                            color: "#0B243C",
                            onClick: () => {
                              makeAPIRequest(
                                "post",
                                "/questions/form/create_from_url",
                                {
                                  url: `https://app.certipair.fr/#/q/${validatedQuestionnaire.id}`,
                                  importFromValidated: true,
                                },
                                "v3"
                              )
                                .then(() => {
                                  dispatch(
                                    openSnackbar({
                                      message:
                                        "Le questionnaire vient d'être importé",
                                      duration: 3000,
                                      type: "success",
                                    })
                                  );
                                })
                                .catch(() => {
                                  dispatch(
                                    openSnackbar({
                                      message:
                                        "Impossible d'importer ce questionnaire",
                                      duration: 3000,
                                      type: "error",
                                    })
                                  );
                                });
                            },
                          },
                          props.formsAccess === "admin"
                            ? {
                                title: "Supprimer",
                                color: "#0B243C",
                                Icon: Delete,
                                onClick: () => {
                                  makeAPIRequest(
                                    "get",
                                    `/questions/form/${validatedQuestionnaire.id}/remove_validated`,
                                    null,
                                    "v3"
                                  )
                                    .then(() => {
                                      refreshValidatedQuestionnairesList(
                                        () => {
                                          dispatch(
                                            openSnackbar({
                                              message:
                                                "Le questionnaire a été retiré de la base",
                                              duration: 3000,
                                              type: "success",
                                            })
                                          );
                                        },
                                        () => {
                                          dispatch(
                                            openSnackbar({
                                              message:
                                                "Impossible de retirer ce questionnaire de la base",
                                              duration: 3000,
                                              type: "error",
                                            })
                                          );
                                        }
                                      );
                                    })
                                    .catch(() => {
                                      dispatch(
                                        openSnackbar({
                                          message:
                                            "Impossible de retirer ce questionnaire de la base",
                                          duration: 3000,
                                          type: "error",
                                        })
                                      );
                                    });
                                },
                              }
                            : (undefined as any),
                        ].filter((x) => x !== undefined)}
                      >
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          gap="10px"
                          justifyContent={"center"}
                        >
                          <span
                            style={{
                              whiteSpace: "pre-wrap",
                              fontWeight: 700,
                              color: "#0B243C",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                            }}
                          >
                            {validatedQuestionnaire?.title
                              ? validatedQuestionnaire.title
                              : "Sans titre"}
                          </span>
                        </Grid>
                      </GenericCard>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
        {!isValidatedQuestionnairesLoading && (
          <Grid item display="grid">
            <MoreOrLessButtons
              items={validatedQuestionnairesFiltered}
              maxItem={maxItem}
              setMaxItem={setMaxItem}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const GenericSelectMulti: React.FC<{
  onChange: (e: any) => void;
  value: any;
  options: any[];
  fieldTitle: string;
  isClearable?: boolean;
  id: string;
  isMandatory?: boolean;
  placeholder: string;
  maxMenuHeight?: string;
  components?: any;
}> = (props) => {
  const { fieldTitle, value, onChange, options, isMandatory, id, placeholder } =
    props;

  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  if (!isMandatory) {
    return (
      <FullField title={fieldTitle} isMandatory={isMandatory} spacing="1px">
        <MySelectMulti
          id={id}
          noOptionsMessage={() => "Aucune option"}
          maxMenuHeight={props.maxMenuHeight ? props.maxMenuHeight : "200px"}
          options={options}
          value={value}
          placeholder={placeholder ? placeholder : ""}
          onChange={onChange}
          isClearable={props.isClearable}
          components={props.components}
        />
      </FullField>
    );
  }
  return (
    <FullField title={fieldTitle} isMandatory={isMandatory} spacing="1px">
      <HandleErrors
        isError={isError}
        isMandatory={true}
        errorMessage={errorMessage}
      >
        <MySelectMulti
          id={id}
          noOptionsMessage={() => "Aucune option"}
          maxMenuHeight={props.maxMenuHeight ? props.maxMenuHeight : "200px"}
          options={options}
          value={value}
          isError={isError}
          placeholder={placeholder ? placeholder : ""}
          onChange={onChange}
          isClearable={props.isClearable}
          components={props.components}
          onFocus={(e) => {
            if (isError) {
              setErrorMessage("");
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!value) {
              if (!isError) {
                setErrorMessage("Ce champ est obligatoire.");
                setIsError(true);
              }
            } else {
              if (isError) {
                setErrorMessage("");
                setIsError(false);
              }
            }
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

export const GenericSelect: React.FC<any> = React.forwardRef(
  (
    props: {
      onChange: (e: any) => void;
      value: any;
      options: any[];
      fieldTitle: string;
      isClearable?: boolean;
      id: string;
      isMandatory?: boolean;
      placeholder: string;
      maxMenuHeight?: string;
      components?: any;
    },
    ref
  ) => {
    GenericSelect.displayName = "GenericSelect";
    const {
      fieldTitle,
      value,
      onChange,
      options,
      isMandatory,
      id,
      placeholder,
    } = props;

    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useImperativeHandle(ref, () => ({
      setIsError(value) {
        setIsError(value);
      },
    }));

    if (!isMandatory) {
      return (
        <FullField title={fieldTitle} isMandatory={isMandatory} spacing="1px">
          <MySelect
            maxMenuHeight={props.maxMenuHeight ? props.maxMenuHeight : "200px"}
            id={id}
            placeholder={placeholder ? placeholder : ""}
            value={value}
            options={options}
            components={props.components}
            isClearable={props.isClearable}
            onChange={onChange}
          />
        </FullField>
      );
    }
    return (
      <FullField title={fieldTitle} isMandatory={isMandatory} spacing="1px">
        <HandleErrors
          isError={isError}
          isMandatory={true}
          errorMessage={errorMessage}
        >
          <MySelect
            maxMenuHeight={props.maxMenuHeight ? props.maxMenuHeight : "200px"}
            id={id}
            placeholder={placeholder ? placeholder : ""}
            isClearable={props.isClearable}
            isError={isError}
            components={props.components}
            onFocus={(e) => {
              if (isError) {
                setErrorMessage("");
                setIsError(false);
              }
            }}
            onBlur={() => {
              if (!value) {
                if (!isError) {
                  setErrorMessage("Ce champ est obligatoire.");
                  setIsError(true);
                }
              } else {
                if (isError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }
            }}
            value={value}
            options={options}
            onChange={onChange}
          />
        </HandleErrors>
      </FullField>
    );
  }
);

GenericSelect.defaultProps = {
  isClearable: true,
};

export interface GraphiqueAraignee {
  _id?: string;
  title?: string;
  creation_date?: number;
  update_date?: number;
  formId?: string;
  displayInResponsePage: boolean;
  min?: number;
  max?: number;
  fields?: Array<{
    fieldId: string;
    entitled: string;
  }>;
}

const getFieldTitle = (
  fieldId: string,
  fieldOptions: Array<{ label: string; value: string }>
): string => {
  if (!fieldId) {
    return "";
  }
  if (!fieldOptions || fieldOptions?.length == 0) {
    return "";
  }
  const founded = fieldOptions.find((x) => x.value == fieldId);
  if (!founded || !founded?.label) {
    return "";
  }
  return founded.label;
};

const CreateOrEditGraphAraignee: React.FC<{
  questionnaires: IForm[];
  isCreate: boolean;
  editInfos?: GraphiqueAraignee;
  setEditInfos?: React.Dispatch<React.SetStateAction<GraphiqueAraignee>>;
  setIsOpenCreateGraphAraignee?: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const [selects, setSelects] = React.useState<{
    formSelected: IForm;
    fieldsOptions: Array<{ label: string; value: string }>;
  }>({
    formSelected: undefined,
    fieldsOptions: [],
  });
  const dispatch = useAppDispatch();
  const [graphiqueAraignee, setGraphiqueAraignee] = React.useState<
    Partial<GraphiqueAraignee>
  >({
    fields: [],
    formId: undefined,
    title: "",
    displayInResponsePage: true,
    min: undefined,
    max: undefined,
  });
  const [popupAddValueOpen, setPopupAddValueOpen] =
    React.useState<boolean>(false);
  const [popupAddValueState, setPopupAddValueState] = React.useState<{
    fieldSelected: { value: string; label: string };
    entitled?: string;
  }>({
    fieldSelected: undefined,
    entitled: undefined,
  });
  const snackbarUse = useOpenSnackbar();

  React.useEffect(() => {
    if (
      !selects.formSelected ||
      !Array.isArray(selects.formSelected?.sections)
    ) {
      return;
    }
    const fieldsResult = [];
    for (const section of selects.formSelected?.sections) {
      for (const fieldRow of section.fields) {
        for (const field of fieldRow) {
          if (
            field.id &&
            field.title &&
            [
              "addition_auto",
              "echelle_notation",
              "liste_deroulante",
              "unique_choice",
              "text",
              "simple_check",
            ].includes(field.type)
          ) {
            fieldsResult.push({
              value: field.id,
              label: field.title,
            });
          }
        }
      }
    }
    setSelects((state) => {
      return { ...state, fieldsOptions: fieldsResult };
    });
  }, [selects.formSelected]);

  React.useEffect(() => {
    if (!props.isCreate && props.editInfos) {
      setGraphiqueAraignee(props.editInfos);
    }
  }, [props.isCreate, props.editInfos]);

  React.useEffect(() => {
    if (!Array.isArray(props.questionnaires)) {
      return;
    }
    if (!graphiqueAraignee.formId) {
      setSelects((state) => {
        return { ...state, formSelected: undefined };
      });
      return;
    }
    const founded = props.questionnaires.find(
      (x) => x.id === graphiqueAraignee.formId
    );
    if (!founded) {
      setSelects((state) => {
        return { ...state, formSelected: undefined };
      });
      return;
    }
    setSelects((state) => {
      return { ...state, formSelected: founded };
    });
  }, [graphiqueAraignee.formId, props.questionnaires]);

  return (
    <>
      {popupAddValueOpen && (
        <GenericFromPopup
          onClose={() => setPopupAddValueOpen(false)}
          title={{
            leftPart: "Ajouter",
            rightPart: "une valeur",
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            gap="10px"
            padding="5px"
          >
            <Grid item display="grid">
              <GenericSelect
                fieldTitle={"Choix du champ"}
                options={selects.fieldsOptions.filter(
                  (x) =>
                    !graphiqueAraignee.fields
                      .map((y) => y.fieldId)
                      .includes(x.value)
                )}
                value={popupAddValueState.fieldSelected}
                maxMenuHeight={"200px"}
                id={"graphique-araignee-popup-valeur"}
                onChange={(e: any) => {
                  if (!e) {
                    setPopupAddValueState((x) => {
                      return { ...x, fieldSelected: undefined };
                    });
                  } else {
                    setPopupAddValueState((x) => {
                      return { ...x, fieldSelected: e };
                    });
                  }
                }}
                placeholder="Titre du champ"
                isMandatory={true}
              />
            </Grid>
            <Grid item display="grid">
              <GenericTextInput
                fieldTitle="Intitulé du champ"
                id="graphique-araignee-popup-intitulee"
                isMandatory={false}
                value={popupAddValueState.entitled}
                placeholder={popupAddValueState?.fieldSelected?.label}
                onChange={(e) => {
                  setPopupAddValueState((x) => {
                    return {
                      ...x,
                      entitled: e?.target?.value ? e.target.value : undefined,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item display="grid">
              <GenericSendButton
                invalidConditions={{
                  emptyField:
                    !popupAddValueState.fieldSelected ||
                    !popupAddValueState.fieldSelected?.label,
                }}
                errorMessages={{
                  emptyField: "Aucun champ n'est sélectionné.",
                  "": "",
                }}
                errorSelector={{
                  emptyField: "#graphique-araignee-popup-valeur",
                  "": "",
                }}
                textButton={"Ajouter"}
                onSendClick={() => {
                  setGraphiqueAraignee((x) => {
                    return {
                      ...x,
                      fields: [
                        ...x.fields,
                        {
                          entitled: popupAddValueState.entitled,
                          fieldId: popupAddValueState.fieldSelected.value,
                        },
                      ],
                    };
                  });
                  setPopupAddValueState({
                    fieldSelected: undefined,
                    entitled: undefined,
                  });
                  setPopupAddValueOpen(false);
                }}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <GenericFromPopup
        onClose={
          props.isCreate
            ? () => props.setIsOpenCreateGraphAraignee(false)
            : () => props.setEditInfos(undefined)
        }
        title={{
          leftPart: props.isCreate ? "Créer" : "Modifier",
          rightPart: "un graphique araignée",
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap="10px"
          padding="5px"
        >
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="Titre du graphique"
              isMandatory={true}
              value={graphiqueAraignee.title}
              placeholder={"Titre du graphique"}
              id={`graphique-araignee-title`}
              onChange={(e) => {
                if (!e?.target?.value || typeof e.target.value !== "string") {
                  setGraphiqueAraignee((state) => {
                    return { ...state, title: e.target.value };
                  });
                  return;
                }
                setGraphiqueAraignee((state) => {
                  return { ...state, title: e.target.value };
                });
              }}
            />
          </Grid>
          <Grid item display="grid">
            <GenericSelect
              isMandatory={true}
              maxMenuHeight={"100px"}
              fieldTitle="Questionnaire"
              id={`graphique-areignee-questionnaire`}
              options={props.questionnaires.map((x) => {
                if (x?.id && x?.title) {
                  return {
                    value: x.id,
                    label: x?.title,
                  };
                }
              })}
              value={
                props.questionnaires && graphiqueAraignee.formId
                  ? {
                      label: props.questionnaires.find(
                        (x) => x.id === graphiqueAraignee.formId
                      )?.title,
                      value: graphiqueAraignee.formId,
                    }
                  : undefined
              }
              placeholder="Titre du questionnaire"
              onChange={(e) => {
                setGraphiqueAraignee((x) => {
                  return { ...x, formId: e?.value, fields: [] };
                });
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Grid container direction="row" gap="10px">
              <Grid item display="grid">
                <div style={{ maxWidth: "150px" }}>
                  <GenericTextInput
                    value={
                      graphiqueAraignee.min
                        ? graphiqueAraignee.min.toString()
                        : ""
                    }
                    placeholder="0"
                    id="valeur-min-graph-araignee"
                    fieldTitle="Valeur minimale"
                    isMandatory={false}
                    onChange={(e) => {
                      setGraphiqueAraignee((x) => {
                        const v = fixStringNumber(e?.target?.value);
                        return {
                          ...x,
                          min: v,
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item display="grid">
                <div style={{ maxWidth: "150px" }}>
                  <GenericTextInput
                    value={
                      graphiqueAraignee.max
                        ? graphiqueAraignee.max.toString()
                        : ""
                    }
                    placeholder="Automatique"
                    id="valeur-max-graph-araignee"
                    fieldTitle="Valeur maximale"
                    isMandatory={false}
                    onChange={(e) => {
                      setGraphiqueAraignee((x) => {
                        const v = fixStringNumber(e?.target?.value);
                        return {
                          ...x,
                          max: v,
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid" marginTop="5px">
            <OrangeCheckBox
              onClick={() => {
                setGraphiqueAraignee((x) => {
                  return {
                    ...x,
                    displayInResponsePage: !x.displayInResponsePage,
                  };
                });
              }}
              value={graphiqueAraignee.displayInResponsePage}
              text="Afficher avec les réponses aux questionnaires"
            />
          </Grid>
          {selects.formSelected && (
            <Grid item display="grid" marginTop="5px" marginRight="5px">
              <Grid container direction="column" wrap="nowrap">
                <Grid item display="grid">
                  <AddButton
                    addId="graphique-araignee-add-value-button"
                    onClick={() => {
                      setPopupAddValueOpen(true);
                    }}
                    displayText={true}
                    text="Ajouter une valeur"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {graphiqueAraignee.fields?.length > 0 && (
            <Grid item display="grid">
              <Grid container direction="row" gap="10px">
                {graphiqueAraignee.fields.map((field, index) => {
                  return (
                    <Grid item display="grid" key={index}>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        style={{
                          border: "1px solid rgba(11, 36, 60)",
                          backgroundColor: "rgba(247, 252, 252, 1)",
                          boxShadow: "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                        }}
                        paddingLeft="5px"
                        paddingRight="5px"
                        borderRadius="10px"
                      >
                        <Grid
                          item
                          display="grid"
                          paddingLeft="5px"
                          paddingRight="5px"
                          alignItems="center"
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              color: "#0B243C",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {field.entitled
                              ? field.entitled
                              : getFieldTitle(
                                  field.fieldId,
                                  selects.fieldsOptions
                                )}
                          </span>
                        </Grid>
                        <Grid
                          item
                          display="grid"
                          paddingLeft="5px"
                          paddingRight="5px"
                        >
                          <div
                            style={{
                              height: "100%",
                              borderLeft: "1px solid #0B243C",
                            }}
                          />
                        </Grid>
                        <Grid item display="grid" alignItems="center">
                          <DeleteRounded
                            style={{ color: "#0B243C", cursor: "pointer" }}
                            onClick={() => {
                              setGraphiqueAraignee((x) => {
                                return {
                                  ...x,
                                  fields: x.fields.filter(
                                    (y) => y.fieldId !== field.fieldId
                                  ),
                                };
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          <Grid item display="grid">
            <GenericSendButton
              invalidConditions={{
                emptyAraigneeTitle:
                  !graphiqueAraignee.title ||
                  graphiqueAraignee.title?.length == 0,
                emptyQuestionnaire: !graphiqueAraignee.formId,
                noField:
                  !graphiqueAraignee.fields ||
                  graphiqueAraignee.fields?.length == 0,
                badValues:
                  graphiqueAraignee.min &&
                  graphiqueAraignee.max &&
                  graphiqueAraignee.min >= graphiqueAraignee.max,
              }}
              errorMessages={{
                emptyAraigneeTitle: "Aucun titre n'est renseigné.",
                emptyQuestionnaire: "Aucun questionnaire n'est sélectionné.",
                badValues: "Les valeurs sont incorrectes.",
                noField: "Aucune valeur n'a été ajoutée.",
                "": "",
              }}
              errorSelector={{
                emptyAraigneeTitle: "#graphique-araignee-title",
                emptyQuestionnaire: "#graphique-araignee-questionnaire",
                badValues: "#valeur-max-graph-araignee",
                noField: "#graphique-araignee-add-value-button",
                "": "",
              }}
              textButton={props.isCreate ? "Créer" : "Mettre à jour"}
              onSendClick={
                props.isCreate
                  ? () => {
                      makeAPIRequest(
                        "post",
                        "/forms/stats/create_graph_araignee",
                        graphiqueAraignee,
                        "v3"
                      )
                        .then((res) => {
                          props.setIsOpenCreateGraphAraignee(false);
                          dispatch(getGraphiqueAraignee());
                          snackbarUse.success(
                            "Le graphique a bien été créé.",
                            res
                          );
                        })
                        .catch((err) => {
                          snackbarUse.error(
                            "Impossible de créer ce graphique.",
                            err
                          );
                        });
                    }
                  : () => {
                      makeAPIRequest(
                        "post",
                        `/forms/stats/graph_araignee/${graphiqueAraignee._id}/update`,
                        graphiqueAraignee,
                        "v3"
                      )
                        .then((res) => {
                          props.setEditInfos(undefined);
                          dispatch(getGraphiqueAraignee());
                          snackbarUse.success(
                            "Le graphique a bien été mis à jour.",
                            res
                          );
                        })
                        .catch((err) => {
                          snackbarUse.error(
                            "Impossible de mettre à jour ce graphique.",
                            err
                          );
                        });
                    }
              }
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

interface Draw {
  max?: number;
  min?: number;
  labels: Array<{ id: string; title: string }>;
  items: Array<{
    label: string;
    data: number[];
  }>;
}

const GraphPopup: React.FC<{
  listAraignee: GraphiqueAraignee[];
  onClose: () => void;
  graphInfos: GraphiqueAraignee;
}> = (props) => {
  const [patientSelected, setPatientSelected] =
    React.useState<string>(undefined);
  const [patientsActive, setPatientsActive] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [compareGraph, setCompareGraph] = React.useState<{
    label: string;
    value: string;
  }>(undefined);
  const [compareDraw, setCompareDraw] = React.useState<Draw>({
    min: undefined,
    max: undefined,
    items: [],
    labels: [],
  });
  const [draw, setDraw] = React.useState<Draw>({
    min: undefined,
    max: undefined,
    items: [],
    labels: [],
  });

  React.useEffect(() => {
    let isActiveCompare = false;
    let patientCalcul: string[] = [];
    if (!Array.isArray(draw.items)) {
      return;
    }
    if (compareGraph?.value) {
      isActiveCompare = true;
    }
    for (const item of draw.items) {
      if (!patientCalcul.includes(item.label)) {
        patientCalcul.push(item.label);
      }
    }
    if (isActiveCompare) {
      patientCalcul = patientCalcul.filter((x) =>
        compareDraw.items.find((y) => y.label === x)
      );
    }
    setPatientsActive(patientCalcul);
  }, [draw, compareDraw, compareGraph]);

  React.useEffect(() => {
    if (!compareGraph || !compareGraph?.value) {
      setCompareDraw({ items: [], labels: [] });
      return;
    }
    makeAPIRequest(
      "get",
      `/forms/stats/graph_araignee/${compareGraph.value}/draw`,
      null,
      "v3"
    )
      .then((res) => {
        if (
          Array.isArray(res?.data?.items) &&
          Array.isArray(res?.data?.labels)
        ) {
          setCompareDraw(res?.data);
        }
      })
      .catch(() => {
        return;
      });
  }, [compareGraph]);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest(
      "get",
      `/forms/stats/graph_araignee/${props.graphInfos._id}/draw`,
      null,
      "v3"
    )
      .then((res) => {
        if (
          Array.isArray(res?.data?.items) &&
          Array.isArray(res?.data?.labels)
        ) {
          setDraw(res?.data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [props.graphInfos._id]);

  return (
    <GenericFromPopup
      xs={12}
      onClose={props.onClose}
      title={{
        leftPart: "Voir",
        rightPart: "mon graphique",
      }}
      subtitle={`${props.graphInfos.title}${
        compareGraph ? ` en comparaison avec ${compareGraph.label}` : ""
      }`}
    >
      <Grid container direction="column" wrap="nowrap" gap="15px">
        <Grid item display="grid" alignSelf="flex-end">
          <Grid container direction="row" gap="10px">
            <Grid item display="grid">
              <MySelect
                noOptionsMessage={() => "Aucun résultat"}
                options={patientsActive.map((x) => {
                  return { label: x, value: x };
                })}
                value={
                  patientSelected
                    ? { value: patientSelected, label: patientSelected }
                    : undefined
                }
                placeholder="Choix du patient"
                onChange={(e) => {
                  setPatientSelected(e?.value);
                }}
              />
            </Grid>
            <Grid item display="grid">
              <MySelect
                noOptionsMessage={() => "Aucun résultat"}
                options={props.listAraignee.map((x) => {
                  if (x?.title && x?._id) {
                    return {
                      value: x._id,
                      label: x?.title,
                    };
                  }
                })}
                value={compareGraph}
                placeholder="Comparer le graphique"
                onChange={(e) => {
                  if (!e) {
                    setCompareGraph(undefined);
                  }
                  setCompareGraph(e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isLoading && (
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              gap="15px"
              justifyContent="space-evenly"
            >
              <Grid item display="grid">
                <div
                  style={{
                    width: "650px",
                    overflow: "auto",
                  }}
                >
                  <GenericRadar
                    min={draw.min ? draw.min : 0}
                    max={draw.max ? draw.max : undefined}
                    data={{
                      labels: draw.labels.map((x) => x.title),
                      datasets: draw.items
                        .filter((x) => {
                          if (!patientSelected) {
                            return true;
                          }
                          return x.label == patientSelected;
                        })
                        .map((x, index) => {
                          return {
                            data: x.data,
                            label: x.label,
                            hidden: index === 0 ? false : true,
                          };
                        }),
                    }}
                  />
                </div>
              </Grid>
              {compareDraw?.labels?.length > 0 && compareGraph?.value && (
                <Grid item display="grid">
                  <div
                    style={{
                      width: "650px",
                      overflow: "auto",
                    }}
                  >
                    <GenericRadar
                      min={compareDraw.min ? compareDraw.min : 0}
                      max={compareDraw.max ? compareDraw.max : undefined}
                      data={{
                        labels: compareDraw.labels.map((x) => x.title),
                        datasets: compareDraw.items
                          .filter((x) => {
                            if (!patientSelected) {
                              return true;
                            }
                            return x.label == patientSelected;
                          })
                          .map((x, index) => {
                            return {
                              data: x.data,
                              label: x.label,
                              hidden: index === 0 ? false : true,
                            };
                          }),
                      }}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </GenericFromPopup>
  );
};

const transformPdfToPages = (pdf: PDFQuestionnaire): PDFElement[][] => {
  const pagesTmp: PDFElement[][] = [[]];

  pdf.elements
    .filter((x) => x.type !== "variable")
    .forEach((element) => {
      if (element.type === "page_break") {
        pagesTmp.push([]);
      } else {
        pagesTmp[pagesTmp.length - 1].push(element);
      }
    });
  return pagesTmp.filter((x) => x.length > 0);
};

const DrawPages: React.FC<{ pdf: PDFQuestionnaire }> = (props) => {
  const pages = transformPdfToPages(props.pdf);
  //console.log(pages);
  return (
    <Grid container direction={`column`} wrap="nowrap" gap="20px">
      {pages.map((page, index) => {
        return (
          <Grid
            item
            display="grid"
            key={index}
            className={`pdf-page-container`}
          >
            <Grid
              container
              direction="column"
              wrap="nowrap"
              id={`content-pdf-form-page-${index + 1}`}
              gap={`30px`}
              paddingBottom={"20px"}
            >
              {page.map((element) => {
                return (
                  <Grid item display="grid" key={element?._id}>
                    {element.type === "display_text_and_image" && (
                      <div
                        id="display-text-and-image"
                        style={{
                          wordBreak: "break-word",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: cleanHTML(element?.textAndImageContent),
                        }}
                      />
                    )}
                    {element.type === "array" && (
                      <table
                        style={{
                          border: "2px solid #000",
                        }}
                      >
                        <tbody>
                          {element.arrayData
                            .slice(0, element.nbArrayRows)
                            .map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {row
                                  .slice(0, element.nbArrayColumns)
                                  .map((cell, cellIndex) => (
                                    <td
                                      key={cellIndex}
                                      style={{
                                        border: "2px solid #000",
                                        maxWidth: "200px",
                                        whiteSpace: "pre-line",
                                        wordBreak: "break-word",
                                        padding: "10px",
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        gap="10px"
                                        justifyContent={"center"}
                                      >
                                        <Grid item display="grid">
                                          <span>
                                            {cell?.toDisplay
                                              ? cell.toDisplay
                                              : ""}
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </td>
                                  ))}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                    {["statistique_envoi"].includes(element.type) && (
                      <>
                        <GenericHorizontalBar
                          title={element?.graphTitle}
                          datasets={element?.horizontalBarInfos?.datasets}
                          labels={element?.horizontalBarInfos?.labels}
                        />
                      </>
                    )}
                    {["stacked_bar_chart"].includes(element.type) && (
                      <GenericStackedBar
                        titleSize={14}
                        title={element?.graphTitle}
                        labels={element?.stackedBarItems?.labels}
                        datasets={element?.stackedBarItems?.datasets}
                      />
                    )}
                    {["circular_diagram", "saisis_par_delegue"].includes(
                      element.type
                    ) && (
                      <GenericDoughnut
                        title={element.graphTitle}
                        labels={element.graphItems.map((x) => x.legend)}
                        data={element.graphItems.map((x) => x.quantity)}
                        displayLegend={true}
                        backgroundColor={element.graphItems.map((x) => x.color)}
                        hoverBackgroundColor={[]}
                        titleSize={14}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
            {false && (
              <Grid item display="grid" justifySelf="flex-end">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 16,
                    color: "#0B243C",
                    textAlign: "end",
                  }}
                >
                  Page {index + 1}/{pages.length}
                </span>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

const PDFTab: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const formPdf = useAppSelector((x) => x.formPdfReducer);
  const formsPdfList = formPdf.list;
  const user = useAppSelector((x) => x?.userReducer?.user);
  const [deletePopup, setDeletePopup] =
    React.useState<PDFQuestionnaire>(undefined);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);
  const [seeLoading, setSeeLoading] = React.useState<boolean>(false);
  const isLoading = formPdf.isLoading;
  const [maxItems, setMaxItems] = React.useState<number>(10);
  const snackbar = useOpenSnackbar();
  const [popupSendByMedimailOpen, setPopupSendByMedimailOpen] =
    React.useState<boolean>(false);
  const [popupSendByMail, setPopupSendByMail] = React.useState<boolean>(false);
  const [isDownloadLoading, setIsDownloadLoading] =
    React.useState<boolean>(false);
  const [actualDownloadStep, setActualDownloadStep] = React.useState<number>(0);
  const [seePdf, setSeePdf] = React.useState<PDFQuestionnaire>(undefined);
  const [filter, setFilter] = React.useState<{
    _id: string;
  }>({ _id: undefined });
  let date = new Date();
  date.setUTCFullYear(2018);
  date.setUTCMonth(0);
  date.setUTCDate(1);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  const [startDate, setStartDate] = React.useState(
    date.toISOString().split("T")[0]
  );
  const [startTime, setStartTime] = React.useState(
    date.toISOString().split("T")[1].substring(0, 5)
  );

  date = new Date();
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  date.setUTCHours(23);
  date.setUTCMinutes(59);
  date.setUTCSeconds(59);
  date.setUTCMilliseconds(59); // Définit les millisecondes en UTC
  const [endDate, setEndDate] = React.useState(
    date.toISOString().split("T")[0]
  );
  const [endTime, setEndTime] = React.useState(
    date.toISOString().split("T")[1].substring(0, 5)
  );

  /* Generic Medimail */
  const [destinataires, setDestinataires] = React.useState([]);
  const [destOptions, setDestOptions] = React.useState([]);
  const [emailSubject, setEmailSubject] = React.useState("Questionnaire PDF");
  const [content, setContent] = React.useState(
    "Vous trouverez ci-joint le PDF."
  );
  const [destLoading, setDestLoading] = React.useState<boolean>(false);
  const [myListBase64, setMyListBase64] = React.useState([]);
  const [searchList, setSearchStack] = React.useState<string[]>([]);
  const [lastChangeTime, setLastChangeTime] = React.useState(Date.now());
  const [timeNow, setTimeNow] = React.useState(Date.now());
  const [medimailLoading, setMedimailLoading] = React.useState<boolean>(false);
  const [sendResponseMedimailLoading, setSendResponseMedimailLoading] =
    React.useState<boolean>(false);
  const [sendResponseMedimailStep, setSendResponseMedimailStep] =
    React.useState<number>(0);
  const snackbarUse = useOpenSnackbar();

  /* Classic mail */
  const [myListBase64ClassicMail, setMyListBase64ClassicMail] = React.useState(
    []
  );
  const [classicMailDestinataires, setClassicMailDestinataires] =
    React.useState([]);
  const [classicMailEmailSubject, setClassicMailEmailSubject] =
    React.useState("Questionnaire PDF");
  const [classicMailEmailContent, setClassicMailEmailContent] = React.useState(
    "Vous trouverez ci-joint le PDF."
  );
  const [classicMailLoading, setClassicMailLoading] =
    React.useState<boolean>(false);
  const [sendResponseClassicMailLoading, setSendResponseClassicMailLoading] =
    React.useState<boolean>(false);
  const [sendResponseClassicMailStep, setSendResponseClassicMailStep] =
    React.useState<number>(0);

  const pdfDestinataires = useQuery().get("pdfDestinataires");
  const pdfSubject = useQuery().get("pdfSubject");
  const pdfContent = useQuery().get("pdfContent");
  const pdfSendMail = useQuery().get("pdfSendMail");

  const pdfDocumentId = useQuery().get("pdfDocumentId");

  React.useEffect(() => {
    if (!pdfDestinataires) {
      return;
    }
    const dest = pdfDestinataires?.split(",");
    setClassicMailDestinataires(dest ? dest : []);
  }, [pdfDestinataires]);

  React.useEffect(() => {
    if (!pdfContent) {
      return;
    }
    setClassicMailEmailContent(pdfContent);
  }, [pdfContent]);

  React.useEffect(() => {
    if (!pdfSubject) {
      return;
    }
    setClassicMailEmailSubject(pdfSubject);
  }, [pdfSubject]);

  React.useEffect(() => {
    if (!seePdf) {
      return;
    }
    if (pdfSendMail === "oui") {
      setPopupSendByMail(true);
      setInterval(() => executeSendMail(), 5000);
    }
  }, [pdfSendMail, seePdf]);

  React.useEffect(() => {
    if (!pdfDocumentId) {
      return;
    }
    setSeeLoading(true);
    makeAPIRequest(`get`, `/forms/pdf/${pdfDocumentId}/see`, null, `v3`)
      .then((res) => {
        setSeePdf(res?.data);
      })
      .catch((err) => {
        snackbar.error(`Impossible de récupérer ce PDF.`, err);
      })
      .finally(() => {
        setSeeLoading(false);
      });
  }, [pdfDocumentId]);

  React.useEffect(() => {
    if (!popupSendByMail) {
      return;
    }
    const interval = setInterval(() => {
      setTimeNow(Date.now());
    }, 500);
    return () => clearInterval(interval);
  }, [popupSendByMail]);

  React.useEffect(() => {
    if (!popupSendByMail) {
      return;
    }
    if (lastChangeTime + 1000 > timeNow) {
      return;
    }
    if (!searchList || searchList.length === 0) {
      setDestLoading(false);
      setDestOptions([]);
      return;
    }
    const search = searchList[searchList.length - 1];
    if (search === searchList?.[searchList.length - 2]) {
      return;
    }
    if (search) {
      setSearchStack((x) => {
        return [...x, search];
      });
      makeAPIRequest(
        "post",
        "/medimail/annuaire/search",
        {
          search: search,
        },
        "v3"
      )
        .then((res) => {
          const data = res?.data;
          if (Array.isArray(data) && data.length > 0) {
            setDestOptions(
              data.map((x) => {
                return {
                  value: x,
                  label: x,
                };
              })
            );
          } else {
            setDestOptions([]);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        })
        .finally(() => {
          setDestLoading(false);
        });
    } else {
      setDestOptions([]);
      setDestLoading(false);
    }
  }, [searchList, lastChangeTime, timeNow, popupSendByMail]);

  React.useEffect(() => {
    if (myListBase64ClassicMail?.length == 0) {
      return;
    }
    if (classicMailDestinataires?.length == 0) {
      return;
    }
    const toSend = {
      subject: classicMailEmailSubject,
      content: classicMailEmailContent,
      destinataires: classicMailDestinataires,
      pdfList: myListBase64ClassicMail,
    };
    makeAPIRequest("post", "/questions/response/send_by_mail", toSend, "v3")
      .then((res) => {
        snackbarUse.success("L'e-mail a été envoyé avec succès.", res);
      })
      .catch((err) => {
        snackbarUse.error("L'email n'a pas pu être envoyé.", err);
      })
      .finally(() => {
        setPopupSendByMail(false);
        setClassicMailLoading(false);
        setMyListBase64ClassicMail([]);
        setClassicMailDestinataires([]);
        setClassicMailEmailSubject("Questionnaire PDF");
        setClassicMailEmailContent("Vous trouverez ci-joint le PDF");
      });
  }, [
    myListBase64ClassicMail,
    classicMailDestinataires,
    classicMailEmailContent,
    classicMailEmailSubject,
  ]);

  React.useEffect(() => {
    if (myListBase64?.length == 0) {
      return;
    }
    if (destinataires?.length == 0) {
      return;
    }
    const toSend = {
      subject: emailSubject,
      content: content,
      destinataires: destinataires.map((y) => y?.mail),
      pdfList: myListBase64,
    };
    makeAPIRequest(
      "post",
      "/questions/response/send_by_mail_medimail",
      toSend,
      "v3"
    )
      .then((res) => {
        snackbarUse.success("L'e-mail a été envoyé avec succès.", res);
      })
      .catch((err) => {
        snackbarUse.error("L'email n'a pas pu être envoyé.", err);
      })
      .finally(() => {
        setPopupSendByMedimailOpen(false);
        setMedimailLoading(false);
        setMyListBase64([]);
        setDestinataires([]);
        setEmailSubject("Questionnaire PDF");
        setContent("Vous trouverez ci-joint le PDF");
      });
  }, [myListBase64, destinataires, content, emailSubject]);

  React.useEffect(() => {
    dispatch(getFormPdf())
      .unwrap()
      .catch((err) =>
        snackbar.error(`Impossible de récupérer la liste des PDF.`, err)
      );
  }, [dispatch]);

  const applyFilter = (toFilter: PDFQuestionnaire[]) => {
    if (filter._id) {
      return toFilter.filter((x) => x._id === filter._id);
    }
    return toFilter;
  };

  const executeSendMail = async () => {
    setSendResponseClassicMailLoading(true);
    setSendResponseClassicMailStep(0);
    const result = await createPDFWithElementIds(
      transformedPdf.map((x, index) => {
        return `content-pdf-form-page-${index + 1}`;
      }),
      {
        onStep: (number) => {
          setSendResponseClassicMailStep(number);
        },
        type: "get-base64",
        pageWidth: 850,
      }
    );
    result.pdfGenerated.getBase64((data) => {
      setClassicMailLoading(true);
      setSendResponseClassicMailLoading(false);
      setSendResponseClassicMailStep(0);
      setMyListBase64ClassicMail((l) => {
        return [
          ...l,
          {
            content: data,
            name: seePdf?.title
              ? seePdf.title.replaceAll(" ", "_")
              : "sans_titre",
          },
        ];
      });
    });
  };
  const transformedPdf = seePdf ? transformPdfToPages(seePdf) : [];

  const updateDate = () => {
    const startTimestamp = Date.parse(startDate + "T" + startTime + ":00");
    const endTimestamp = Date.parse(endDate + "T" + endTime + ":00");
    if (startTimestamp > endTimestamp) {
      dispatch(
        openSnackbar({
          type: "error",
          duration: 3000,
          message: "Les dates sont invalides",
        })
      );
      return;
    }
    setSeeLoading(true);
    makeAPIRequest(
      `get`,
      `/forms/pdf/${seePdf?._id}/see?startDate=${startTimestamp}&endDate=${endTimestamp}`,
      null,
      `v3`
    )
      .then((res) => {
        setSeePdf(res?.data);
      })
      .catch((err) => {
        snackbar.error(`Impossible de récupérer ce PDF.`, err);
      })
      .finally(() => {
        setSeeLoading(false);
      });
  };

  return (
    <>
      {popupSendByMail && (
        <GenericFromPopup
          onClose={() => setPopupSendByMail(false)}
          subtitle={`${seePdf.title ? seePdf.title : ``}`}
          title={{
            leftPart: `Envoyer`,
            rightPart: `mon PDF`,
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="30px"
          >
            <Grid item display="grid">
              <FullField isMandatory={true} title={`Destinataires`}>
                <div
                  id="questionnaire-email-destinataires"
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid transparent",
                  }}
                >
                  <Creatable
                    noOptionsMessage={() =>
                      "Tapez l'adresse email du destinataire"
                    }
                    formatCreateLabel={(userInput) => `${userInput}`}
                    isMulti={true}
                    maxMenuHeight={120}
                    options={(() => {
                      const optionsTmp = [];
                      return optionsTmp;
                    })()}
                    value={
                      classicMailDestinataires?.length > 0
                        ? classicMailDestinataires.map((x) => {
                            return { label: x, value: x };
                          })
                        : []
                    }
                    placeholder="Liste des destinataires"
                    onChange={(e: any) => {
                      setClassicMailDestinataires(
                        e
                          ?.filter((opt: any) => emailValidator(opt?.label))
                          ?.map((x) => x?.value)
                      );
                    }}
                  />
                </div>
              </FullField>
            </Grid>
            <Grid item display="grid" paddingTop="15px">
              <FullField isMandatory={true} title={`Objet du mail`}>
                <TextInput
                  id="send-response-subject"
                  onChange={(e) => setClassicMailEmailSubject(e?.target?.value)}
                  value={classicMailEmailSubject}
                />
              </FullField>
            </Grid>
            <Grid item display="grid" paddingTop="15px">
              <FullField isMandatory={true} title={`Contenu du mail`}>
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid" paddingTop="5px">
                    <MultilineInput
                      id="send-response-content"
                      maxLength={50000}
                      onChange={(e) => setClassicMailEmailContent(e)}
                      value={classicMailEmailContent}
                      height={"80px"}
                    />
                  </Grid>
                </Grid>
              </FullField>
            </Grid>
            {sendResponseClassicMailLoading || classicMailLoading ? (
              <Grid item display="grid">
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                >
                  <Grid item display="grid">
                    <CircularProgress />
                  </Grid>
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        color: "#0B243C",
                      }}
                    >
                      {classicMailLoading
                        ? `En attente de l'envoi...`
                        : `Création et envoi du PDF en cours... ${
                            sendResponseClassicMailStep + 1 >
                            transformedPdf.length
                              ? transformedPdf.length
                              : sendResponseClassicMailStep + 1
                          }/${transformedPdf.length}`}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item display="grid">
                <GenericSendButton
                  errorMessages={{
                    noDestinataireSelected:
                      "Il faut ajouter au minimum un destinataire",
                    emptyContent: "Le contenu du mail ne peut pas être vide",
                    emptyEmailSubject: "L'objet du mail ne peut pas être vide",
                  }}
                  errorSelector={{
                    noDestinataireSelected: "#send-response-destinataires",
                    emptyContent: "#send-response-content",
                    emptyEmailSubject: "#send-response-subject",
                  }}
                  invalidConditions={{
                    noDestinataireSelected:
                      classicMailDestinataires.length === 0,
                    emptyEmailSubject: !classicMailEmailSubject,
                    emptyContent: !classicMailEmailContent,
                  }}
                  onSendClick={() => {
                    executeSendMail();
                  }}
                  isLoading={false}
                  textButton="Envoyer"
                />
              </Grid>
            )}
          </Grid>
        </GenericFromPopup>
      )}
      {popupSendByMedimailOpen && (
        <GenericFromPopup
          onClose={() => setPopupSendByMedimailOpen(false)}
          subtitle={`${seePdf.title ? seePdf.title : ``}`}
          title={{
            leftPart: `Envoyer`,
            rightPart: `mon PDF`,
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="30px"
          >
            <Grid item display="grid">
              <MedimailSearch
                onUpdateSelected={(selected) => {
                  setDestinataires(
                    selected.map((x) => {
                      return {
                        label: x.mail,
                        value: x.mail,
                      };
                    })
                  );
                }}
              />
            </Grid>
            <Grid item display="grid" paddingTop="15px">
              <FullField isMandatory={true} title={`Objet du mail`}>
                <TextInput
                  id="send-response-subject"
                  onChange={(e) => setEmailSubject(e?.target?.value)}
                  value={emailSubject}
                />
              </FullField>
            </Grid>
            <Grid item display="grid" paddingTop="15px">
              <FullField isMandatory={true} title={`Contenu du mail`}>
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid" paddingTop="5px">
                    <MultilineInput
                      id="send-response-content"
                      maxLength={50000}
                      onChange={(e) => setContent(e)}
                      value={content}
                      height={"80px"}
                    />
                  </Grid>
                </Grid>
              </FullField>
            </Grid>
            {sendResponseMedimailLoading || medimailLoading ? (
              <Grid item display="grid">
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                >
                  <Grid item display="grid">
                    <CircularProgress />
                  </Grid>
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        color: "#0B243C",
                      }}
                    >
                      {medimailLoading
                        ? `En attente de Medimail...`
                        : `Création et envoi du PDF en cours... ${
                            sendResponseMedimailStep + 1 > transformedPdf.length
                              ? transformedPdf.length
                              : sendResponseMedimailStep + 1
                          }/${transformedPdf.length}`}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item display="grid">
                <GenericSendButton
                  errorMessages={{
                    noDestinataireSelected:
                      "Il faut ajouter au minimum un destinataire",
                    emptyContent: "Le contenu du mail ne peut pas être vide",
                    emptyEmailSubject: "L'objet du mail ne peut pas être vide",
                  }}
                  errorSelector={{
                    noDestinataireSelected: "#send-response-destinataires",
                    emptyContent: "#send-response-content",
                    emptyEmailSubject: "#send-response-subject",
                  }}
                  invalidConditions={{
                    noDestinataireSelected: destinataires.length === 0,
                    emptyEmailSubject: !emailSubject,
                    emptyContent: !content,
                  }}
                  onSendClick={async () => {
                    setSendResponseMedimailLoading(true);
                    setSendResponseMedimailStep(0);
                    const result = await createPDFWithElementIds(
                      transformedPdf.map((x, index) => {
                        return `content-pdf-form-page-${index + 1}`;
                      }),
                      {
                        onStep: (number) => {
                          setSendResponseMedimailStep(number);
                        },
                        type: "get-base64",
                        pageWidth: 850,
                      }
                    );
                    result.pdfGenerated.getBase64((data) => {
                      setMedimailLoading(true);
                      setSendResponseMedimailLoading(false);
                      setSendResponseMedimailStep(0);
                      //console.log(data);
                      setMyListBase64((l) => {
                        return [
                          ...l,
                          {
                            content: data,
                            name: seePdf?.title
                              ? seePdf.title.replaceAll(" ", "_")
                              : "sans_titre",
                          },
                        ];
                      });
                    });
                  }}
                  isLoading={false}
                  textButton="Envoyer"
                />
              </Grid>
            )}
          </Grid>
        </GenericFromPopup>
      )}
      {seePdf && (
        <GenericFromPopup
          onClose={() => setSeePdf(undefined)}
          subtitle={`${seePdf.title ? seePdf.title : ``}`}
          title={{
            leftPart: `Voir`,
            rightPart: `mon PDF`,
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="30px"
          >
            <Grid item display="grid" justifyContent={"flex-end"}>
              <Grid container direction="row" gap="15px">
                <Grid item display="grid" height="fit-content">
                  {/* Send by mail not secure */}
                  <BlueButton
                    label="Envoyer par mail (via CertiPair)"
                    overrideButton={{
                      onClick: () => {
                        setPopupSendByMail(true);
                      },
                    }}
                  />
                </Grid>
                {process.env.REACT_APP_ENABLE_MEDIMAIL == "true" &&
                  user?.email_medimail && (
                    <Grid item display="grid" height="fit-content">
                      {/* Send by medimail */}
                      <BlueButton
                        label="Envoyer via Medimail"
                        overrideButton={{
                          onClick: () => {
                            setPopupSendByMedimailOpen(true);
                          },
                        }}
                      />
                    </Grid>
                  )}
                <Grid item display="grid" height="fit-content">
                  {isDownloadLoading ? (
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      justifyContent="center"
                      alignItems="center"
                      gap="10px"
                    >
                      <Grid item display="grid">
                        <CircularProgress />
                      </Grid>
                      <Grid item display="grid">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#0B243C",
                          }}
                        >
                          {`Création du PDF en cours... ${
                            actualDownloadStep + 1
                          }/${transformedPdf.length}`}
                        </span>
                      </Grid>
                    </Grid>
                  ) : (
                    <BlueButton
                      label="Télécharger le PDF"
                      overrideButton={{
                        onClick: () => {
                          createPDFWithElementIds(
                            transformedPdf.map((x, index) => {
                              return `content-pdf-form-page-${index + 1}`;
                            }),
                            {
                              onStep: (number) => {
                                setActualDownloadStep(number);
                              },
                              setIsDownloadLoading: setIsDownloadLoading,
                              type: "download",
                              name: seePdf?.title
                                ? seePdf.title.replaceAll(" ", "_")
                                : "sans_titre",
                              pageWidth: 850,
                            }
                          );
                        },
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid">
              <FullField isMandatory={true} title="Période">
                <></>
              </FullField>
              <Grid
                container
                direction="column"
                spacing="10px"
                width="fit-content"
                wrap="nowrap"
              >
                <Grid item display="grid">
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                    spacing="10px"
                  >
                    <Grid item display="grid">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#0B243C",
                        }}
                      >
                        Du
                      </span>
                    </Grid>
                    <Grid item display="grid">
                      <input
                        id="statistic-start-date"
                        type="date"
                        style={{
                          minWidth: "100%",
                          border: "1px solid #D3E0E0",
                          background: "#EDF2F280 0% 0% no-repeat padding-box",
                          borderRadius: "10px",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                          color:
                            startDate && startDate?.length > 0
                              ? "#0B243C"
                              : "#657273",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          height: "42px",
                        }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#0B243C",
                        }}
                      >
                        à
                      </span>
                    </Grid>

                    <Grid item display="grid">
                      <input
                        id="statistic-start-time"
                        type="time"
                        style={{
                          minWidth: "100%",
                          border: "1px solid #D3E0E0",
                          background: "#EDF2F280 0% 0% no-repeat padding-box",
                          borderRadius: "10px",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                          color:
                            startTime && startTime?.length > 0
                              ? "#0B243C"
                              : "#657273",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          height: "42px",
                        }}
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display="grid">
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                    spacing="10px"
                  >
                    <Grid item display="grid">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#0B243C",
                        }}
                      >
                        Au
                      </span>
                    </Grid>
                    <Grid item display="grid">
                      <input
                        id="statistic-end-date"
                        type="date"
                        style={{
                          minWidth: "100%",
                          border: "1px solid #D3E0E0",
                          background: "#EDF2F280 0% 0% no-repeat padding-box",
                          borderRadius: "10px",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                          color:
                            endDate && endDate?.length > 0
                              ? "#0B243C"
                              : "#657273",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          height: "42px",
                        }}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#0B243C",
                        }}
                      >
                        à
                      </span>
                    </Grid>
                    <Grid item display="grid">
                      <input
                        id="statistic-end-time"
                        type="time"
                        style={{
                          minWidth: "100%",
                          border: "1px solid #D3E0E0",
                          background: "#EDF2F280 0% 0% no-repeat padding-box",
                          borderRadius: "10px",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                          color:
                            endTime && endTime?.length > 0
                              ? "#0B243C"
                              : "#657273",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          height: "42px",
                        }}
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display="grid" justifyContent="flex-end">
                  {startDate &&
                  endDate &&
                  startTime &&
                  endTime &&
                  !seeLoading ? (
                    <OrangeButton
                      style={{
                        fontSize: "14px",
                        height: "35px",
                        padding: "2px 10px 2px 10px",
                        borderRadius: "15px",
                      }}
                      animation={false}
                      text="Valider"
                      onClick={() => {
                        updateDate();
                      }}
                    />
                  ) : (
                    <OrangeButton
                      style={{
                        fontSize: "14px",
                        height: "35px",
                        padding: "2px 10px 2px 10px",
                        borderRadius: "15px",
                      }}
                      enable={false}
                      animation={false}
                      text="Valider"
                      onClick={() => {
                        return null;
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid">
              <DrawPages pdf={seePdf} />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      {deletePopup && (
        <CancellablePopup
          firstTitle="Mes"
          isLoading={isDeleteLoading}
          secondTitle="PDF"
          messages={[
            `Voulez-vous vraiment supprimer le PDF '${deletePopup?.title}' ?`,
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => {
            setIsDeleteLoading(false);
            setDeletePopup(undefined);
          }}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest(
              "delete",
              `/forms/pdf/${deletePopup?._id}/delete`,
              null,
              "v3"
            )
              .then((res) => {
                setIsDeleteLoading(false);
                setDeletePopup(undefined);
                snackbar.success("Le PDF a bien été supprimé.", res);
                dispatch(getFormPdf());
              })
              .catch((err) => {
                setIsDeleteLoading(false);
                snackbar.error("Impossible de supprimer ce PDF.", err);
              });
          }}
        />
      )}
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid" justifySelf="flex-end">
          <MySimpleCreateButton
            text="Créer un PDF"
            onClick={() => {
              history.push(`/create_pdf_questionnaire`);
            }}
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <Grid container direction="row" gap="10px" alignItems="center">
            <Grid item display="grid">
              <FullField title="Filtrer :" isMandatory={false}>
                <></>
              </FullField>
            </Grid>
            <Grid item xs display="grid">
              <MySelect
                id="pdf-questionnaire-titre"
                noOptionsMessage={() => "Aucun résultat"}
                options={formsPdfList.map((x) => {
                  return {
                    value: x._id,
                    label: x.title,
                  };
                })}
                value={(() => {
                  if (!filter._id) return undefined;
                  const form = formsPdfList.find((x) => x._id === filter._id);
                  return {
                    value: form._id,
                    label: form.title,
                  };
                })()}
                placeholder="Titre du document"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, _id: e?.value };
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid item display={`grid`}>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingTop="15px"
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            {applyFilter(formsPdfList)?.length == 0 ? (
              <Grid item display="grid" paddingTop="20px">
                <span
                  style={{
                    fontFamily: "Poppins",
                    color: "#0B243C",
                    fontSize: "16px",
                  }}
                >
                  Aucun PDF trouvé.
                </span>
              </Grid>
            ) : (
              <Grid item display="grid" paddingTop="20px">
                <Grid container direction="column" wrap="nowrap" gap="10px">
                  {applyFilter(formsPdfList)
                    .slice(0, maxItems)
                    .map((formPdf, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <GenericCard
                            leftButtons={[
                              {
                                Icon: EditRounded,
                                title: "Modifier",
                                onClick: () => {
                                  history.push(
                                    `/edit_pdf_questionnaire/${formPdf._id}`
                                  );
                                },
                              },
                              {
                                Icon: DeleteRounded,
                                title: "Supprimer",
                                onClick: () => {
                                  setDeletePopup(formPdf);
                                },
                              },
                            ]}
                            rightButtons={[
                              {
                                Icon: PreviewRounded,
                                disable: seeLoading ? true : false,
                                onClick: () => {
                                  setSeeLoading(true);
                                  makeAPIRequest(
                                    `get`,
                                    `/forms/pdf/${formPdf?._id}/see`,
                                    null,
                                    `v3`
                                  )
                                    .then((res) => {
                                      setSeePdf(res?.data);
                                    })
                                    .catch((err) => {
                                      snackbar.error(
                                        `Impossible de récupérer ce PDF.`,
                                        err
                                      );
                                    })
                                    .finally(() => {
                                      setSeeLoading(false);
                                    });
                                },
                                title: "Voir le PDF",
                              },
                            ]}
                          >
                            <Grid
                              container
                              direction="column"
                              wrap="nowrap"
                              justifyContent={`center`}
                              alignItems={`center`}
                            >
                              <Grid item display="grid">
                                <span style={{ fontWeight: "bold" }}>
                                  {formPdf.title}
                                </span>
                              </Grid>
                            </Grid>
                          </GenericCard>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            )}
          </>
        )}
        <Grid item display="grid">
          <MoreOrLessButtons
            items={applyFilter(formsPdfList)}
            maxItem={maxItems}
            setMaxItem={setMaxItems}
          />
        </Grid>
      </Grid>
    </>
  );
};

const StatsTab: React.FC<{
  questionnaires: IForm[];
}> = (props) => {
  const dispatch = useAppDispatch();
  const [maxGraphItems, setMaxGraphItems] = React.useState<number>(10);
  const isLoading: boolean = useAppSelector(
    (x) => x.graphAraigneeReducer.isLoading
  );
  const listAraignee: GraphiqueAraignee[] = useAppSelector(
    (x) => x.graphAraigneeReducer.list
  );
  const [filter, setFilter] = React.useState<{
    formId: string;
  }>({ formId: undefined });
  const [openGraph, setOpenGraph] =
    React.useState<GraphiqueAraignee>(undefined);
  const [isOpenCreateGraphAraignee, setIsOpenCreateGraphAraignee] =
    React.useState<boolean>(false);
  const [editInfos, setEditInfos] =
    React.useState<GraphiqueAraignee>(undefined);
  const [deletePopup, setDeletePopup] =
    React.useState<GraphiqueAraignee>(undefined);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);
  const snackbarUse = useOpenSnackbar();

  const applyFilter = (toFilter: GraphiqueAraignee[]) => {
    if (filter.formId) {
      return toFilter.filter((x) => x.formId === filter.formId);
    }
    return toFilter;
  };

  React.useEffect(() => {
    dispatch(getGraphiqueAraignee());
  }, [dispatch]);

  return (
    <>
      {deletePopup && (
        <CancellablePopup
          firstTitle="Mes"
          isLoading={isDeleteLoading}
          secondTitle="graphiques araignées"
          messages={[
            `Voulez-vous vraiment supprimer le graphique '${deletePopup?.title}' ?`,
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => {
            setIsDeleteLoading(false);
            setDeletePopup(undefined);
          }}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest(
              "delete",
              `/forms/stats/graph_araignee/${deletePopup?._id}/delete`,
              null,
              "v3"
            )
              .then((res) => {
                setIsDeleteLoading(false);
                setDeletePopup(undefined);
                snackbarUse.success("Le graphique a bien été supprimé.", res);
                dispatch(getGraphiqueAraignee());
              })
              .catch((err) => {
                setIsDeleteLoading(false);
                snackbarUse.error("Impossible de supprimer ce graphique.", err);
              });
          }}
        />
      )}
      {openGraph && (
        <GraphPopup
          listAraignee={listAraignee}
          onClose={() => {
            setOpenGraph(undefined);
          }}
          graphInfos={openGraph}
        />
      )}
      {editInfos && (
        <CreateOrEditGraphAraignee
          {...props}
          setEditInfos={setEditInfos}
          editInfos={editInfos}
          isCreate={false}
        />
      )}
      {isOpenCreateGraphAraignee && (
        <CreateOrEditGraphAraignee
          {...props}
          setIsOpenCreateGraphAraignee={setIsOpenCreateGraphAraignee}
          isCreate={true}
        />
      )}
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid" justifySelf="flex-end">
          <MySimpleCreateButton
            text="Créer un graphique araignée"
            onClick={() => {
              setIsOpenCreateGraphAraignee(true);
            }}
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <Grid container direction="row" gap="10px" alignItems="center">
            <Grid item display="grid">
              <FullField title="Filtrer :" isMandatory={false}>
                <></>
              </FullField>
            </Grid>
            <Grid item xs display="grid">
              <MySelect
                id="graphique-araignee-questionnaire"
                noOptionsMessage={() => "Aucun résultat"}
                options={props.questionnaires.map((x) => {
                  if (x?.id && x?.title) {
                    return {
                      value: x.id,
                      label: x?.title,
                    };
                  }
                })}
                value={
                  props.questionnaires && filter?.formId
                    ? {
                        label: props.questionnaires.find(
                          (x) => x.id === filter.formId
                        )?.title,
                        value: filter.formId,
                      }
                    : undefined
                }
                placeholder="Titre du questionnaire"
                onChange={(e) => {
                  setFilter((x) => {
                    return { ...x, formId: e?.value };
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {isLoading && (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop="15px"
          >
            <CircularProgress />
          </Grid>
        )}
        {applyFilter(listAraignee)?.length == 0 && !isLoading ? (
          <Grid item display="grid" paddingTop="20px">
            <span
              style={{
                fontFamily: "Poppins",
                color: "#0B243C",
                fontSize: "16px",
              }}
            >
              Aucun graphique trouvé.
            </span>
          </Grid>
        ) : (
          <Grid item display="grid" paddingTop="20px">
            <Grid container direction="column" wrap="nowrap" gap="10px">
              {applyFilter(listAraignee)
                .slice(0, maxGraphItems)
                .map((araignee, index) => {
                  const form: IForm = props.questionnaires.find(
                    (x) => x.id === araignee.formId
                  );
                  return (
                    <Grid item display="grid" key={index}>
                      <GenericCard
                        leftButtons={[
                          {
                            Icon: EditRounded,
                            title: "Modifier",
                            onClick: () => {
                              setEditInfos(araignee);
                            },
                          },
                          {
                            Icon: DeleteRounded,
                            title: "Supprimer",
                            onClick: () => {
                              setDeletePopup(araignee);
                            },
                          },
                        ]}
                        rightButtons={[
                          {
                            Icon: PreviewRounded,
                            onClick: () => {
                              setOpenGraph(araignee);
                            },
                            title: "Voir les graphiques",
                          },
                        ]}
                      >
                        <Grid container direction="column" wrap="nowrap">
                          <Grid item display="grid">
                            <span style={{ fontWeight: "bold" }}>
                              {araignee.title}
                            </span>
                          </Grid>
                          {form && (
                            <Grid item display="grid">
                              <span style={{ fontWeight: "bold" }}>
                                {form.title}
                              </span>
                            </Grid>
                          )}
                        </Grid>
                      </GenericCard>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}
        <Grid item display="grid">
          <MoreOrLessButtons
            items={applyFilter(listAraignee)}
            maxItem={maxGraphItems}
            setMaxItem={setMaxGraphItems}
          />
        </Grid>
      </Grid>
    </>
  );
};

const MesQuestionnairesTab: React.FC<{
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionnaires: React.Dispatch<React.SetStateAction<IForm[]>>;
  questionnaires: IForm[];
}> = (props) => {
  React.useEffect(() => {
    props.setIsLoading(true);
    makeAPIRequest("get", "/questions/forms/list", null, "v3")
      .then((res) => {
        props.setQuestionnaires(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => props.setIsLoading(false));
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      makeAPIRequest("get", "/questions/forms/list", null, "v3")
        .then((res) => {
          props.setQuestionnaires(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <QuestionnaireContent
      questionnaires={props.questionnaires}
      isLoading={props.isLoading}
      setQuestionnaires={props.setQuestionnaires}
    />
  );
};

export const QuestionnaireBox: React.FC<any> = (props) => {
  const { formsAccess } = props;
  const user = useAppSelector((x) => x.userReducer.user);
  const [isLoading, setIsLoading] = React.useState(true);
  const [questionnaires, setQuestionnaires] = React.useState<IForm[]>([]);
  const history = useHistory();
  const [isSendResponsesOpen, setIsResponsesOpen] =
    React.useState<boolean>(false);
  const [isSendResponsesMedimailOpen, setIsResponsesMedimailOpen] =
    React.useState<boolean>(false);
  const [isDuplicateOpen, setIsDuplicateOpen] = React.useState<boolean>(false);
  const [questionnaireTab, setQuestionnaireTab] = React.useState<
    | "Mes Questionnaires"
    | "À importer"
    | "Mes Envois"
    | "Stats"
    | "PDF"
    | "Admin"
  >("Mes Questionnaires");
  const snackbarUse = useOpenSnackbar();
  const scheduleQuestionnaireReducer = useAppSelector(
    (state) => state.scheduleQuestionnaireReducer
  );
  const tab = useQuery().get("tab");
  React.useEffect(() => {
    if (tab === "mesenvois") {
      setQuestionnaireTab("Mes Envois");
    }
    if (tab === "aimporter") {
      setQuestionnaireTab("À importer");
    }
    if (tab === "admin") {
      setQuestionnaireTab("Admin");
    }
    if (tab === "mesquestionnaires") {
      setQuestionnaireTab("Mes Questionnaires");
    }
    if (tab === "stats") {
      setQuestionnaireTab("Stats");
    }
    if (tab === "pdf") {
      setQuestionnaireTab("PDF");
    }
  }, [tab]);

  return (
    <>
      {scheduleQuestionnaireReducer?.isOpen && (
        <StartQuestionnaire isEdit={false} {...props} />
      )}
      {isSendResponsesOpen && (
        <SendResponsePopup setIsResponsesOpen={setIsResponsesOpen} />
      )}
      {isSendResponsesMedimailOpen && (
        <SendResponseMedimailPopup
          setIsResponsesMedimailOpen={setIsResponsesMedimailOpen}
        />
      )}
      {isDuplicateOpen && (
        <DuplicatePopup
          setIsDuplicateOpen={setIsDuplicateOpen}
          setQuestionnaires={setQuestionnaires}
          setIsLoading={setIsLoading}
        />
      )}
      <Grid
        container
        display="flex"
        direction="column"
        wrap="nowrap"
        minHeight="600px"
      >
        <Grid
          item
          display="grid"
          justifyContent="flex-end"
          style={{
            paddingTop: "12px",
          }}
        >
          <Grid
            container
            direction="row"
            spacing="5px"
            wrap="nowrap"
            alignItems="center"
          >
            {false &&
              process.env.REACT_APP_ENABLE_MEDIMAIL == "true" &&
              user?.email_medimail && (
                <Grid item display="grid">
                  <BlueButton
                    label="Envoyer des réponses (Medimail)"
                    overrideButton={{
                      onClick: () => setIsResponsesMedimailOpen(true),
                    }}
                  />
                </Grid>
              )}
            {false && (
              <Grid item display="grid">
                <BlueButton
                  label="Envoyer des réponses"
                  overrideButton={{
                    onClick: () => setIsResponsesOpen(true),
                  }}
                />
              </Grid>
            )}
            {true && (
              <Grid item display="grid">
                <BlueButton
                  label="Exporter les programmations (CSV)"
                  overrideButton={{
                    onClick: () => {
                      makeAPIRequest(
                        "get",
                        "/forms/stats/get_patients_sended",
                        null,
                        "v3"
                      )
                        .then((res: any) => {
                          if (res?.data && Array.isArray(res.data)) {
                            downloadCSV(
                              res.data,
                              `programmations-${Date.now()}`
                            );
                          } else {
                            snackbarUse.error(
                              "Impossible d'exporter les programmations."
                            );
                          }
                        })
                        .catch((err) => {
                          snackbarUse.error(
                            "Impossible d'exporter les programmations.",
                            err
                          );
                        });
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item display="grid" paddingTop="30px">
          <div className="row">
            <div className="col-md-12">
              <Tabs
                activeKey={questionnaireTab}
                className="mb-3 background-tabs"
              >
                <Tab
                  eventKey="Mes Questionnaires"
                  title={
                    <MyTabTitle
                      onClick={() => {
                        history.push(
                          "/home?f=questionnaires&tab=mesquestionnaires"
                        );
                        setQuestionnaireTab("Mes Questionnaires");
                      }}
                      text={"Mes Questionnaires"}
                    />
                  }
                />
                <Tab
                  eventKey="Mes Envois"
                  title={
                    <MyTabTitle
                      onClick={() => {
                        history.push("/home?f=questionnaires&tab=mesenvois");
                        setQuestionnaireTab("Mes Envois");
                      }}
                      text={"Mes Envois"}
                    />
                  }
                />
                <Tab
                  eventKey="Stats"
                  title={
                    <MyTabTitle
                      onClick={() => {
                        history.push("/home?f=questionnaires&tab=stats");
                        setQuestionnaireTab("Stats");
                      }}
                      text={"Stats"}
                    />
                  }
                />
                {process.env.REACT_APP_ENABLE_FORM_PDF == "true" && (
                  <Tab
                    eventKey="PDF"
                    title={
                      <MyTabTitle
                        onClick={() => {
                          history.push("/home?f=questionnaires&tab=pdf");
                          setQuestionnaireTab("PDF");
                        }}
                        text={"PDF"}
                      />
                    }
                  />
                )}
                <Tab
                  style={{ padding: "0 0 0 0" }}
                  eventKey="À importer"
                  title={
                    <MyTabTitle
                      onClick={() => {
                        history.push("/home?f=questionnaires&tab=aimporter");
                        setQuestionnaireTab("À importer");
                      }}
                      text={"À importer"}
                    />
                  }
                ></Tab>
                {formsAccess === "admin" && (
                  <Tab
                    style={{ padding: "0 0 0 0" }}
                    eventKey="Admin"
                    title={
                      <MyTabTitle
                        onClick={() => {
                          history.push("/home?f=questionnaires&tab=admin");
                          setQuestionnaireTab("Admin");
                        }}
                        text={"Admin"}
                      />
                    }
                  ></Tab>
                )}
              </Tabs>
              {questionnaireTab === "Stats" && (
                <StatsTab questionnaires={questionnaires} />
              )}
              {questionnaireTab === "À importer" && (
                <ValidatedFormsContent formsAccess={formsAccess} />
              )}
              {questionnaireTab === "PDF" && <PDFTab />}
              {questionnaireTab === "Mes Envois" && <FormScheduledBox />}
              {questionnaireTab === "Admin" && <AdminFormsContent />}
              {questionnaireTab === "Mes Questionnaires" && (
                <Grid item display="grid" paddingTop="10px">
                  <MesQuestionnairesTab
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setQuestionnaires={setQuestionnaires}
                    questionnaires={questionnaires}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const QuestionnairePage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );
  const formsAccess = useAppSelector(
    (state) => state.authorizationsReducer.forms
  );

  const scheduleQuestionnaireReducer = useAppSelector(
    (state) => state.scheduleQuestionnaireReducer
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(updateSize("#mes-questionnaires-box"));
  }, [dispatch]);

  return (
    <>
      {scheduleQuestionnaireReducer?.isOpen && (
        <StartQuestionnaire isEdit={false} {...props} />
      )}
      <FullPage>
        <Grid
          container
          display="flex"
          direction="row"
          justifyContent="center"
          spacing="20px"
          paddingLeft="24px"
          paddingRight="24px"
        >
          <Grid item display="grid" alignItems="flex-start">
            <Grid container direction="column" wrap="nowrap">
              <Grid item display="grid">
                <MesReglagesBox page="questionnaires" />
              </Grid>
              {props?.user?.account_type == "4" && (
                <Grid item display="grid" paddingTop="30px">
                  <AdministrationBox page="questionnaires" />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs
            display="grid"
            alignItems="flex-start"
            marginLeft="24px"
            width={windowWidth > 1300 ? "1000px" : null}
          >
            <QuestionnaireBox formsAccess={formsAccess} />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default QuestionnairePage;
