import { Grid } from '@mui/material';
import * as React from 'react';
import getAccountsTypeOptions from '../../utils/accountsTypeOptions';
import FullField from '../Fields/FullField';
import BoxTitle from '../Library/Box/BoxTitle';
import MySelect from '../Library/Select/MySelect';
import ConfigUsersPerPage from './ConfigUsersPerPage';
import UsersListContent from './UsersListContent';
import UsersNavigation from './UsersNavigation';

const DisplayHowManyUser: React.FC<any> = (props) => {
  const {
    usersFiltered,
  } = props;

  const nbUser = usersFiltered?.length;

  return (
    <span style={{
      fontFamily: 'Poppins',
      fontSize: '16px',
      color: '#0B243C'
    }}>
      {!nbUser || nbUser === 0 ? 'Aucune utilisateur trouvé'
        : `${nbUser} utilisateur${nbUser > 1 ? 's' : ''} trouvé${nbUser > 1 ? 's' : ''}`
      }
    </span>
  );
};

const UsersListBox: React.FC<any> = (props) => {

  const getUserName = () => {
    let listUser = [];

    for (const element of props?.users) {
      listUser.push({value: element?.id, label: element?.first_name.toLocaleString() + " " + element?.last_name.toLocaleString()});
    }
    return (listUser);
  };

  return (
    <Grid
      container
      direction='column'
      wrap="nowrap"
      display='flex'
      paddingLeft='41px'
      paddingTop='26px'
      paddingRight='41px'
      paddingBottom='60px'
      minWidth='320px'
      style={{
        background: 'rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box',
        borderRadius: '24px',
      }}>
      <Grid item display='grid'>
        <Grid container display='flex' direction='column' wrap="nowrap">
          <Grid item display='grid'>
            <BoxTitle
              first='Mes'
              second='Utilisateurs' />
          </Grid>
          <Grid item display='grid' paddingTop='50px'>
            <Grid container direction='column' wrap="nowrap">
              <Grid item display='grid'>
                <Grid container direction='row' alignItems="center" spacing="10px">
                  <Grid item display='grid'>
                    <span>
                      Filtrer par :
                    </span>
                  </Grid>
                  <Grid item display='grid' width='250px'>
                    <MySelect
                      options={getUserName()}
                      noOptionsMessage={() => 'Aucun résultat'}
                      value={props?.patientNameSearched}
                      placeholder="Nom de l'utilisateur"
                      onChange={(e) => {
                        props?.setPatientNameSearched(e);
                      }} />
                  </Grid>
                  <Grid item display='grid' width='200px'>
                    <MySelect
                      options={getAccountsTypeOptions()}
                      noOptionsMessage={() => 'Aucun résultat'}
                      value={props?.accountTypeSearched?.value == undefined ? null : props?.accountTypeSearched}
                      placeholder='Type de compte'
                      onChange={(e) => {
                        props?.setAccountTypeSearched(e);
                      }} />
                  </Grid>
                </Grid>
              </Grid>
              {!props.isLoading &&
                <Grid item display='grid' paddingTop='20px' justifyContent='center'>
                  <DisplayHowManyUser {...props} />
                </Grid>
              }
              <Grid item display='grid' paddingTop='25px'>
                <UsersListContent {...props} />
              </Grid>
              {props?.pages?.length > 0 &&
                <Grid item display='grid' paddingTop='47px'>
                  <Grid container direction='row' spacing='10px' alignItems='center' justifyContent='space-between'>
                    <Grid item display='grid'>
                      <ConfigUsersPerPage
                        {...props} />
                    </Grid>
                    <Grid item display='grid'>
                      <UsersNavigation
                        {...props} />
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default UsersListBox;