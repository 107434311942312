import FullField from "../../../../Fields/FullField";
import * as React from 'react';
import MySelect from "../../../../Library/Select/MySelect";
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import typeOptions from "../../typeOptions";
import { useDispatch } from "react-redux";
import { components } from "react-select";
import { Grid } from "@mui/material";
import HandleErrors from "../../../../Library/Errors/HandleErrors";
import useAppSelector from "../../../../../redux/useAppSelector";
const { Option } = components;

const DisplayType: React.FC = () => {
  const [isError, setIsError] = React.useState(false);
  const haveMedimail = useAppSelector((x) => x?.userReducer?.user?.email_medimail)
  const [errorMessage, setErrorMessage] = React.useState('');
  const fieldCopy = useAppSelector(state => state.questionnaireReducer.fieldCopy);
  const dispatch = useDispatch();

  const TypeOptionsStyle: React.FC<any> = (props) => {
    const OptionIcon = props.data.icon;
    const text = props.data.label;

    return (
      <Option {...props}>
        <Grid container direction='row' spacing='10px'>
          <Grid item display='grid'>
            <OptionIcon />
          </Grid>
          <Grid item display='grid'>
            <span>
              {text}
            </span>
          </Grid>
        </Grid>
      </Option>
    );
  };

  return (
    <FullField title='Type' isMandatory={true} spacing='1px'>
      <HandleErrors
        isError={isError}
        isMandatory={true}
        errorMessage={errorMessage}>
        <MySelect
          maxMenuHeight={'200px'}
          id='questionnaire-popup-type-input'
          isError={isError}
          onFocus={(e) => {
            if (isError) {
              setErrorMessage('');
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!fieldCopy?.type) {
              if (!isError) {
                setErrorMessage('Ce champ est obligatoire.');
                setIsError(true);
              }
            } else {
              if (isError) {
                setErrorMessage('');
                setIsError(false);
              }
            }
          }}
          components={{ Option: TypeOptionsStyle }}
          value={{ value: fieldCopy?.type, label: typeOptions?.find(element => element?.value === fieldCopy?.type)?.label }}
          options={typeOptions.filter((x) => {
            if (x?.value === 'medecins_traitants') {
              if (!haveMedimail) {
                return (false);
              }
            }
            return (true)
          })}
          onChange={(e) => {
            const fieldCopyTmp = { ...fieldCopy };

            fieldCopyTmp.value = null;
            if (!['simple_check', 'unique_choice', 'liste_deroulante'].includes(fieldCopyTmp.type)) {
              fieldCopyTmp.option = null;
            }
            fieldCopyTmp.type = e?.value;
            if (fieldCopyTmp.type === "echelle_notation") {
              fieldCopyTmp.option = [1, 10];
            }
            if (fieldCopyTmp.type === "display_text_and_image") {
              fieldCopyTmp.title = '';
            }
            if (fieldCopyTmp.type !== "display_text_and_image" && fieldCopyTmp?.title?.[0] == '<') {
              fieldCopyTmp.title = '';
            }
            if (fieldCopyTmp.type === "simple_check") {
              fieldCopyTmp.validation = {};
              fieldCopyTmp.validation.type = "Au minimum";
              fieldCopyTmp.validation.number = 1;
            }
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

export default DisplayType;