import {
  ClearRounded,
  Delete,
  Edit,
  SettingsRounded,
} from "@mui/icons-material";
import _ from "lodash";
import { Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import FullField from "../Fields/FullField";
import TextInput from "../Library/Inputs/TextInput";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../pages/ChatbotLexPage";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import {
  IChatbot,
  IConditionChatbot,
  IQuestionChatbot,
} from "./initObjects/initChatbot";
import AddButton from "../Library/Buttons/AddButton/AddButton";
import { GenericSelect } from "../../pages/QuestionnairePage";
import { nanoid } from "nanoid";
import {
  getCondTypes,
  translateCondType,
} from "../Questionnaire/EditQuestionnaire/Fields/DisplayConfirmMessage";
import {
  GenericLongText,
  GenericTextInput,
} from "../../pages/CreateOrEditChatbotLexPage";
import CancellablePopup from "../Library/Popups/CancellablePopup";

const getActionsOptions = () => {
  return [
    {
      value: "add_question",
      label: "Envoyer une question supplémentaire",
    },
    {
      value: "send_sms",
      label: "Envoyer un SMS",
    },
    {
      value: "send_alert_by_email",
      label: "Recevoir une alerte par e-mail",
    },
  ];
};

const translateAction = (type: string) => {
  const converter = {
    add_question: "Envoyer une question",
    send_sms: "Envoyer un SMS",
    send_alert_by_email: "Recevoir une alerte par e-mail",
  };
  if (type in converter) {
    return converter[type];
  }
  return type;
};

const DisplayQuestions: React.FC<{
  chatbotData: IChatbot;
  setChatbotData: React.Dispatch<React.SetStateAction<IChatbot>>;
}> = (props) => {
  const [openParameters, setOpenParameters] =
    React.useState<IQuestionChatbot>(undefined);
  const { chatbotData, setChatbotData } = props;
  const [openAddCondition, setOpenAddCondition] =
    React.useState<IConditionChatbot>(undefined);
  const [confirmCloseParameters, setConfirmCloseParameters] =
    React.useState<boolean>(false);
  const [openEditCondition, setOpenEditCondition] =
    React.useState<IConditionChatbot>(undefined);
  const toUseConditionSetter = openAddCondition
    ? setOpenAddCondition
    : setOpenEditCondition;
  const toUseCondition = openAddCondition
    ? openAddCondition
    : openEditCondition;
  const noChangeInParameters =
    openParameters && chatbotData
      ? _.isEqual(
          openParameters,
          chatbotData?.questions?.find((y) => y.id == openParameters.id)
        )
      : true;

  return (
    <>
      {confirmCloseParameters && (
        <CancellablePopup
          firstTitle="Quitter"
          secondTitle="les paramètres ?"
          message="Voulez-vous vraiment fermer cette fenêtre et annuler vos modifications ?"
          confirmButtonText="Non"
          cancelButtonText="Oui"
          onCancel={() => {
            setOpenParameters(undefined);
            setConfirmCloseParameters(false);
          }}
          onConfirm={() => setConfirmCloseParameters(false)}
        />
      )}
      {(openAddCondition || openEditCondition) && (
        <GenericFromPopup
          subtitle={openParameters?.text ? openParameters.text : ""}
          title={{
            leftPart: openEditCondition ? "Modifier" : "Ajouter",
            rightPart: "une condition",
          }}
          onClose={() => {
            setOpenAddCondition(undefined);
            setOpenEditCondition(undefined);
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="20px"
          >
            <Grid item display="grid">
              <Grid item display="grid" xs minWidth="200px">
                <GenericTextInput
                  value={toUseCondition.title}
                  fieldTitleInPlaceholderOnly={false}
                  placeholder="Aucun titre"
                  fieldTitle="Titre de la condition"
                  id="title-question-chatbot"
                  onChange={(e) => {
                    toUseConditionSetter((x) => {
                      return { ...x, title: e?.target?.value };
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item display="grid">
              <FullField isMandatory={true} title="Condition à respecter">
                <Grid container direction="row" gap="10px">
                  <Grid item display="grid" minWidth="200px">
                    <GenericSelect
                      isClearable={false}
                      value={{
                        value: toUseCondition.type,
                        label: translateCondType(toUseCondition.type),
                      }}
                      options={getCondTypes()}
                      maxMenuHeight="120px"
                      fieldTitle=""
                      onChange={(e) => {
                        toUseConditionSetter((x) => {
                          return { ...x, type: e?.value };
                        });
                      }}
                      id="conditions-question-chatbot"
                      placeholder=""
                    />
                  </Grid>
                  <Grid
                    item
                    display="grid"
                    xs
                    marginTop="-2px"
                    minWidth="200px"
                  >
                    <GenericTextInput
                      value={toUseCondition.value}
                      fieldTitleInPlaceholderOnly={true}
                      fieldTitle="Valeur"
                      id="value-question-chatbot"
                      onChange={(e) => {
                        toUseConditionSetter((x) => {
                          return { ...x, value: e?.target?.value };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </FullField>
            </Grid>
            <Grid item display="grid" minWidth="200px">
              <GenericSelect
                isMandatory={true}
                isClearable={false}
                value={{
                  value: toUseCondition.action,
                  label: translateAction(toUseCondition.action),
                }}
                options={getActionsOptions()}
                maxMenuHeight="120px"
                fieldTitle="Action à effectuer"
                onChange={(e) => {
                  toUseConditionSetter((x) => {
                    return { ...x, action: e?.value };
                  });
                }}
                id="actions-question-chatbot"
                placeholder=""
              />
            </Grid>

            {toUseCondition.action === "send_sms" && (
              <>
                <Grid item display="grid">
                  <GenericLongText
                    maxLength={325}
                    fieldTitle="SMS à envoyer"
                    id="send_sms_action_content"
                    onChange={(e) => {
                      toUseConditionSetter((x) => {
                        return {
                          ...x,
                          smsToSend: e,
                        };
                      });
                    }}
                    value={
                      toUseCondition?.smsToSend ? toUseCondition?.smsToSend : ""
                    }
                  />
                </Grid>
              </>
            )}
            {toUseCondition.action === "add_question" && (
              <>
                <Grid item display="grid">
                  <FullField isMandatory={true} title={"Question à envoyer"}>
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      gap="10px"
                      alignItems={"center"}
                    >
                      <Grid item display="grid" xs minWidth="200px">
                        <GenericTextInput
                          value={
                            toUseCondition?.questionToAdd?.text
                              ? toUseCondition?.questionToAdd?.text
                              : ""
                          }
                          fieldTitle=""
                          id="send-question-text-chatbot"
                          onChange={(e) => {
                            toUseConditionSetter((x) => {
                              return {
                                ...x,
                                questionToAdd: x.questionToAdd
                                  ? {
                                      ...x.questionToAdd,
                                      text: e?.target?.value,
                                    }
                                  : {
                                      text: e?.target?.value,
                                      id: nanoid(),
                                      conditions: [],
                                      sendMailAfterResponse: false,
                                    },
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </FullField>
                </Grid>
                <Grid item display="grid">
                  <OrangeCheckBox
                    textStyle={{ fontSize: "14px" }}
                    onClick={() =>
                      toUseConditionSetter((x) => {
                        if (x.questionToAdd) {
                          return {
                            ...x,
                            questionToAdd: {
                              ...x.questionToAdd,
                              sendMailAfterResponse: x?.questionToAdd
                                ?.sendMailAfterResponse
                                ? false
                                : true,
                            },
                          };
                        }
                        return x;
                      })
                    }
                    value={
                      toUseCondition?.questionToAdd?.sendMailAfterResponse
                        ? true
                        : false
                    }
                    text="Recevoir un e-mail après chaque réponse à cette question"
                  />
                </Grid>
              </>
            )}
            <Grid item display="grid">
              <GenericSendButton
                onSendClick={() => {
                  setOpenParameters((y) => {
                    if (openAddCondition) {
                      return {
                        ...y,
                        conditions: y?.conditions
                          ? [...y.conditions, toUseCondition]
                          : [toUseCondition],
                      };
                    }
                    return {
                      ...y,
                      conditions: y?.conditions
                        ? y.conditions.map((z) => {
                            if (z.id === openEditCondition.id) {
                              return openEditCondition;
                            }
                            return z;
                          })
                        : [],
                    };
                  });
                  setOpenAddCondition(undefined);
                  setOpenEditCondition(undefined);
                }}
                errorMessages={{
                  emptyValue: "La valeur n'est pas renseignée.",
                  emptyQuestionToAdd:
                    "La question à envoyer n'est pas renseignée.",
                  emptySmsToSend: "Le contenu du SMS n'est pas renseigné.",
                }}
                errorSelector={{
                  emptyValue: "#value-question-chatbot",
                  emptyQuestionToAdd: "#send-question-text-chatbot",
                  emptySmsToSend: "#send_sms_action_content",
                }}
                invalidConditions={{
                  emptyValue: !toUseCondition.value,
                  emptyQuestionToAdd:
                    toUseCondition.action === "add_question" &&
                    !toUseCondition?.questionToAdd?.text,
                  emptySmsToSend:
                    toUseCondition.action === "send_sms" &&
                    !toUseCondition?.smsToSend,
                }}
                isLoading={false}
                textButton={openAddCondition ? "Ajouter" : "Modifier"}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      {openParameters && !confirmCloseParameters && (
        <GenericFromPopup
          subtitle={openParameters?.text ? openParameters.text : ""}
          title={{
            leftPart: "Paramètrer",
            rightPart: "ma question",
          }}
          onClose={() => {
            if (noChangeInParameters) {
              setOpenParameters(undefined);
            } else {
              setConfirmCloseParameters(true);
            }
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="25px"
          >
            <Grid item display="grid">
              <Grid container direction="column" wrap="nowrap" gap="15px">
                <Grid item display="grid">
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    gap="10px"
                    alignItems={"center"}
                  >
                    <Grid item display="grid">
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#0B243C",
                          fontFamily: "Poppins",
                        }}
                      >
                        Conditions
                      </span>
                    </Grid>
                    <Grid item display="grid">
                      <AddButton
                        displayText={false}
                        addId={"button-add-condition"}
                        displayIconCreate={true}
                        onClick={() => {
                          setOpenAddCondition({
                            action: "add_question",
                            id: nanoid(),
                            type: "=",
                            value: "",
                            questionToAdd: {
                              id: nanoid(),
                              text: "",
                              sendMailAfterResponse: false,
                            },
                            title: "",
                          });
                        }}
                        smallVersion={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!openParameters?.conditions ||
                openParameters?.conditions?.length == 0 ? (
                  <Grid item display="grid">
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#0B243C",
                        fontFamily: "Poppins",
                      }}
                    >
                      {`Aucune condition n'a été créée pour cette question.`}
                    </span>
                  </Grid>
                ) : (
                  <Grid item display="grid">
                    <Grid container direction="row" gap="10px">
                      {openParameters.conditions.map((condition, index) => {
                        return (
                          <Grid item display="grid" key={index}>
                            <Grid
                              container
                              direction="row"
                              wrap="nowrap"
                              style={{
                                border: "1px solid rgba(11, 36, 60)",
                                backgroundColor: "rgba(247, 252, 252, 1)",
                                boxShadow:
                                  "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                              }}
                              paddingLeft="7px"
                              paddingRight="7px"
                              borderRadius="10px"
                            >
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                                alignItems="center"
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  {condition.title
                                    ? condition.title
                                    : "Sans titre"}
                                </span>
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Edit
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setOpenEditCondition(condition);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Delete
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setOpenParameters((y) => {
                                      return {
                                        ...y,
                                        conditions: y.conditions.filter(
                                          (z) => z?.id !== condition.id
                                        ),
                                      };
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item display="grid">
              <Grid container direction="column" wrap="nowrap" gap="10px">
                <Grid item display="grid">
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#0B243C",
                      fontFamily: "Poppins",
                    }}
                  >
                    Options
                  </span>
                </Grid>
                <Grid item display="grid">
                  <Grid container direction="column" wrap="nowrap" gap="10px">
                    <Grid item display="grid">
                      <OrangeCheckBox
                        textStyle={{ fontSize: "14px" }}
                        onClick={() =>
                          setOpenParameters((x) => {
                            return {
                              ...x,
                              sendMailAfterResponse: x?.sendMailAfterResponse
                                ? false
                                : true,
                            };
                          })
                        }
                        value={
                          openParameters?.sendMailAfterResponse ? true : false
                        }
                        text="Recevoir un e-mail après chaque réponse à cette question"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{
                  noChange: "Veuillez modifier les paramètres avant de valider",
                }}
                errorSelector={{}}
                invalidConditions={{
                  noChange: noChangeInParameters,
                }}
                textButton="Valider"
                onSendClick={() => {
                  setChatbotData((x) => {
                    return {
                      ...x,
                      questions: x.questions.map((y) => {
                        if (y.id === openParameters.id) {
                          return openParameters;
                        }
                        return y;
                      }),
                    };
                  });
                  setOpenParameters(undefined);
                }}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      {chatbotData?.questions?.length > 0 && (
        <Grid item display="grid" paddingTop="20px">
          <Grid container direction="column" wrap="nowrap" spacing="10px">
            {chatbotData?.questions?.map((question, index) => {
              return (
                <Grid item key={index} display="grid">
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                    spacing="10px"
                  >
                    <Grid item width="30px" display="grid">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "rgb(91, 185, 193)",
                          fontSize: "20px",
                        }}
                      >
                        {index + 1}
                      </span>
                    </Grid>
                    <Grid item xs display="grid">
                      <FullField title="" isMandatory={false}>
                        <TextInput
                          value={question?.text}
                          onChange={(e) => {
                            const chatbotDataTmp = { ...chatbotData };

                            chatbotDataTmp.questions[index].text =
                              e.target.value;
                            setChatbotData(chatbotDataTmp);
                          }}
                        />
                      </FullField>
                    </Grid>
                    <Grid item display="grid">
                      <Tooltip title="Paramètres">
                        <IconButton
                          onClick={() => {
                            setOpenParameters(question);
                          }}
                          style={{
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          <SettingsRounded
                            fontSize="small"
                            style={{ color: "#0B243C" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item display="grid">
                      <Tooltip title="Supprimer">
                        <IconButton
                          onClick={() => {
                            const chatbotDataTmp = { ...chatbotData };

                            delete chatbotDataTmp.questions[index];
                            chatbotDataTmp.questions =
                              chatbotDataTmp?.questions?.filter(
                                (e) => e != undefined
                              );
                            setChatbotData(chatbotDataTmp);
                          }}
                          style={{
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          <ClearRounded
                            fontSize="small"
                            style={{ color: "#FF3100" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DisplayQuestions;
