import * as React from "react";
import { Grid } from "@mui/material";
import { Check } from "@mui/icons-material";

interface IOrangeCheckBox {
  value: boolean;
  onClick: any;
  text?: string;
  children?: JSX.Element;
  textStyle?: React.CSSProperties;
  addLinkText?: string;
}

const OrangeCheckBox = (props: IOrangeCheckBox) => {
  const { value, onClick, text, children, textStyle, addLinkText } = props;

  return (
    <Grid
      container
      direction="row"
      display="flex"
      wrap="nowrap"
      spacing="10px"
      alignItems="center"
    >
      <Grid item display="grid">
        <Grid
          container
          direction="column"
          wrap="nowrap"
          justifyContent="center"
          alignItems="center"
          style={{
            background: !value
              ? "#EDF2F2 0% 0% no-repeat padding-box"
              : "#FFA500 0% 0% no-repeat padding-box",
            width: "22px",
            height: "22px",
            borderRadius: "5px",
            border: "1px solid #D3E0E0",
            opacity: 1,
          }}
        >
          {value && (
            <Check
              onClick={() => onClick()}
              fontSize="small"
              style={{ paddingLeft: "2px", color: "white" }}
            />
          )}
          <input
            type="checkbox"
            className="checkbox_modals"
            checked={value}
            onChange={() => {
              return null;
            }}
            onClick={() => onClick()}
            style={{
              padding: "0",
              margin: "0",
              borderRadius: "5px",
              width: "100%",
              height: "100%",
              opacity: 0,
            }}
          />
        </Grid>
      </Grid>
      {!children && text && (
        <Grid item display="grid" alignItems="flex-start" marginTop="-1px">
          {addLinkText ? (
            <a
              href={addLinkText}
              target="_blank"
              rel="noreferrer"
              style={{textDecoration: 'none', ...textStyle}}
            >
              {text}
            </a>
          ) : (
            <span className="text_champs" style={textStyle}>
              {text}
            </span>
          )}
        </Grid>
      )}
      {children && (
        <Grid item display="grid" alignItems="flex-start">
          {children}
        </Grid>
      )}
    </Grid>
  );
};

OrangeCheckBox.defaultValue = {
  text: "",
};

export default OrangeCheckBox;
