import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UserClass, { IUser } from '../../utils/UserClass';

const getUser = createAsyncThunk(
  '/GET_USER',
  async (payload, thunkAPI): Promise<{user: IUser, userClass: UserClass}> => {
    try {
      const newUser = new UserClass();
      await newUser.init();
      return (thunkAPI.fulfillWithValue({
        user: newUser.user,
        userClass: newUser,
      }));
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

export interface UserState {
  user: IUser;
  userClass: UserClass;
}

const initialState: UserState = {
  user: undefined,
  userClass: undefined,
}

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {},
  extraReducers(builder: ActionReducerMapBuilder<UserState>) {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.userClass = action.payload.userClass;
    })
  },
})
export const userActions = userSlice.actions;
export default userSlice.reducer
export { getUser }
