import { Grid } from "@mui/material";
import * as React from 'react';

interface IFormPopupHeader {
  firstTitle: string,
  secondTitle: string,
  onClose: any,
}

const FormPopupHeader = (props: IFormPopupHeader) => {
  const {
    firstTitle,
    secondTitle,
    onClose,
  } = props;

  return (
    <Grid container direction='row' spacing='3px' wrap='nowrap' display='flex' alignItems='center' justifyContent='space-between'>
      <Grid item display='grid'>
        <span style={{
          textAlign: 'left',
          font: 'normal normal bold 26px/39px Poppins',
          color: '#5BB9C1',
        }}>{firstTitle} <span style={{
          textAlign: 'left',
          font: 'normal normal bold 26px/39px Poppins',
          color: '#0B243C',
        }}>{secondTitle}</span></span>
      </Grid>
      <Grid item display='grid'>
        <button
          type="button"
          className="modal-close-button"
          onClick={onClose}>
          <span style={{ color: '#8D9899', textAlign: 'center' }}>&times;</span>
        </button>
      </Grid>
    </Grid>
  );
};

export default FormPopupHeader;