import { useDispatch } from "react-redux";
import * as createPatientReducer from '../../redux/createPatientReducer';
import * as React from 'react';
import { ReactComponent as IconCreate } from "../../assets/IconCreate.svg";
import { Grid } from "@mui/material";
import useAppSelector from "../../redux/useAppSelector";

const CreatePatientButton: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(state => state.windowReducer.windowWidth);
  const dispatch = useDispatch();

  return (
    <button
      style={{
        boxShadow: '2px 6px 10px #0F213467',
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '20px',
        maxWidth: '330px',
        maxHeight: '32px',
        paddingRight: '20px',
        borderRadius: '18px',
        opacity: 1,
        background: '#0B243C 0% 0% no-repeat padding-box',
        letterSpacing: '0px',
        color: '#FFFFFF',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '12px',
        fontFamily: 'Poppins',
        justifySelf: 'flex-end',
      }}
      onClick={() => dispatch(createPatientReducer?.openPatient({
        lastname: '',
        firstname: '',
        phone: '',
      }))}>
      <Grid item display='grid'>
        <Grid container spacing='7px' direction='row' display='flex' alignItems='center' justifyContent='center'>
          <Grid item display='grid'>
            <IconCreate />
          </Grid>
          {windowWidth > 800 &&
            <Grid item display='grid'>
              <span>
                Ajouter un nouveau patient
              </span>
            </Grid>
          }
        </Grid>
      </Grid>
    </button >
  );
};

export default CreatePatientButton;
