import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useParams } from "react-router";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";
import { fixURLhttps } from "../components/MesConseilsBox/Card/PackAdviceContent";
import useQuery from "../utils/hooks/useQuery";
import { transformUrlToAbsolute } from "./LinksPage";

interface ConseilBase {
  id: number;
  validated: 0 | 1;
  user_id: number;
  title: string;
  speciality_id: number;
  conseil_profession_id: number;
  type: "pack" | "simple";
  conseil_favourite_ids: string;
  conseil_use: string;
  conseil_popularity: string;
  reserved: 0 | 1;
  display_name: 0 | 1;
  views: string;
  creation_date: Date;
  is_archived: 0 | 1;
}

interface ConseilResponse extends ConseilBase {
  user_name: string;
  messages: string;
}

interface MessageConseil {
  id: number;
  content: string;
  attachment: string;
  attachment_name: string;
  url: string;
  hours: number;
  questionnaire_interactif_title?: string;
  questionnaire_interactif_id?: string;
  short_url?: 0 | 1;
  stop_sms?: 0 | 1;
  questionnaire_interactif_nano_id?: string;
  is_questionnaire_interactif_owner?: boolean;
}

export interface ConseilReponseAfterParsing extends ConseilBase {
  user_name: string;
  messages: MessageConseil[][];
  fullText?: string;
  score?: number;
}

const UnknownConseil = () => {
  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      Ce conseil n'existe pas ou n'existe plus.
    </span>
  );
};

export const ConseilBox = (props: {
  isLoading: boolean;
  conseilData: ConseilReponseAfterParsing;
  setConseilData: React.Dispatch<
    React.SetStateAction<ConseilReponseAfterParsing>
  >;
  noPadding?: boolean;
}) => {
  const formatHours = (hours: number) => {
    let isNegative = false;
    if (hours < 0) isNegative = true;
    let hour = isNegative ? hours * -1 : hours;
    let day = 0;
    let month = 0;
    let year = 0;
    let format = "";
    const tmp = [];

    while (hour >= 24) {
      hour -= 24;
      day += 1;
    }
    while (day >= 30) {
      day -= 30;
      month += 1;
    }
    while (month >= 12) {
      month -= 12;
      year += 1;
    }
    if (year != 0) tmp.push(`${year} an${year > 1 ? "s" : ""}`);
    if (month != 0) tmp.push(`${month} mois`);
    if (day != 0) tmp.push(`${day} jour${day > 1 ? "s" : ""}`);
    if (hour != 0) tmp.push(`${hour} heure${hour > 1 ? "s" : ""}`);

    if (tmp.length === 1)
      format = `${tmp[0]} ${isNegative ? "avant" : "après"}`;
    if (tmp.length === 2)
      format = `${tmp[0]} et ${tmp[1]} ${isNegative ? "avant" : "après"}`;
    if (tmp.length === 3)
      format = `${tmp[0]}, ${tmp[1]} et ${tmp[2]} ${
        isNegative ? "avant" : "après"
      }`;
    if (tmp.length === 4)
      format = `${tmp[0]}, ${tmp[1]}, ${tmp[2]} et ${tmp[3]} ${
        isNegative ? "avant" : "après"
      }`;

    if (hours === 0) return "Le jour J";
    return format;
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      paddingLeft={props.noPadding ? 0 : "5%"}
      paddingTop={props.noPadding ? 0 : "5%"}
      paddingRight={props.noPadding ? 0 : "5%"}
      paddingBottom={props.noPadding ? 0 : "5%"}
    >
      {props.isLoading && (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!props.isLoading && (
        <Grid item display="grid">
          {props.conseilData && props.conseilData?.messages?.[0]?.length > 0 ? (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              justifyContent={"center"}
              gap="40px"
            >
              <Grid item display="grid">
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    fontFamily: "Poppins",
                    color: "#0B243C",
                  }}
                >
                  {props.conseilData?.title}
                </span>
              </Grid>
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="30px">
                  {props.conseilData.messages[0]
                    .sort((a, b) => a.hours - b.hours)
                    .map((message, index) => {
                      return (
                        <Grid item display="grid" key={message.id}>
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="5px"
                          >
                            {message.content && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                  }}
                                >
                                  {message.content}
                                </span>
                              </Grid>
                            )}
                            {message.url && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: 12,
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    wordBreak: "break-all"
                                  }}
                                >
                                  Lien web :{" "}
                                  <a
                                    href={transformUrlToAbsolute(message.url)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {message.url}
                                  </a>
                                </span>
                              </Grid>
                            )}
                            {message?.questionnaire_interactif_id &&
                              message?.questionnaire_interactif_title && (
                                <Grid item display="grid">
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontSize: 12,
                                      fontFamily: "Poppins",
                                      color: "#0B243C",
                                    }}
                                  >
                                    Questionnaire interactif :{" "}
                                    <a
                                      href={message.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {message.questionnaire_interactif_title}
                                    </a>
                                  </span>
                                </Grid>
                              )}
                            {message.attachment && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: 12,
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                  }}
                                >
                                  Piece jointe :{" "}
                                  <a
                                    href={fixURLhttps(message.attachment)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {message.attachment_name == ""
                                      ? message.attachment.split("/")[
                                          message.attachment.split("/").length -
                                            1
                                        ]
                                      : message.attachment_name}
                                  </a>
                                </span>
                              </Grid>
                            )}
                            {props.conseilData?.type === "pack" && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    textAlign: "end",
                                    fontSize: 12,
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                  }}
                                >
                                  {formatHours(message.hours)}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <UnknownConseil />
          )}
        </Grid>
      )}
    </Grid>
  );
};

interface IParams {
  id: string;
}

const ConseilViewPage: React.FC = () => {
  const { id } = useParams<IParams>();
  const scan = useQuery().get(`scan`);
  const [isLoading, setIsLoading] = React.useState(true);
  const [conseilData, setConseilData] =
    React.useState<ConseilReponseAfterParsing>(undefined);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest(
      "get",
      `/conseil/${id}${scan === "1" ? "?scan=1" : ""}`,
      null,
      "v3"
    )
      .then((res) => {
        setConseilData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [scan, id]);

  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="1000px"
        >
          <Grid item display="grid">
            <ConseilBox
              isLoading={isLoading}
              setConseilData={setConseilData}
              conseilData={conseilData}
            />
          </Grid>
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default ConseilViewPage;
