import FullPage from "./FullPage"
import * as React from 'react';
import { CircularProgress, Grid } from "@mui/material";
import BoxTitle from "../components/Library/Box/BoxTitle";
import { ChevronLeftOutlined } from "@mui/icons-material";
import { useHistory, useParams } from "react-router";
import GenerateQuestionnaire from "../components/Questionnaire/GenerateQuestionnaire/GenerateQuestionnaire";
import QuestionnaireEdit from "../components/Questionnaire/EditQuestionnaire/QuestionnaireEdit";
import initQuestionnaire from "../components/Questionnaire/GenerateQuestionnaire/InitObjects/initQuestionnaire";
import makeAPIRequest from "../utils/makeAPIRequest";

const QuestionnairePreview: React.FC<any> = (props) => {
  const {
    questionnaireData,
    setQuestionnaireData,
  } = props;

  return (
    <GenerateQuestionnaire
      isPreview={true}
      questionnaireData={questionnaireData}
      setQuestionnaireData={setQuestionnaireData} />
  );
}

const CreateQuestionnaireContent: React.FC<any> = (props) => {
  const {
    isEdit,
    isCreate,
  } = props;
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = React.useState(isCreate ? false : true);
  const [questionnaireData, setQuestionnaireData] = React.useState(isCreate ? initQuestionnaire() : null);

  React.useEffect(() => {
    if (isCreate) return;
    setIsLoading(true);
    makeAPIRequest('get', `/questions/form/${id}`, null, 'v3')
      .then((res) => {
        setQuestionnaireData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false);
      });
  }, [isCreate]);

  if (isLoading) {
    return (
      <Grid container direction='row' paddingTop='30px' display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Grid container direction='column' wrap='nowrap' display='flex'>
      <Grid item display='grid' style={{ paddingTop: '30px', paddingRight: '15px', paddingBottom: '10px' }}>
        <Grid container direction='column' wrap="nowrap" spacing='10px'>
          <Grid item display='grid'>
            <div style={{ borderTop: '2px solid #E6EBF0', borderRadius: '20px' }} />
          </Grid>
          <Grid item display='grid'>
            <span style={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              color: '#657273',
              textAlign: 'end',
            }}>Présentation du questionnaire</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display='grid'>
        <QuestionnaireEdit
          setQuestionnaireData={setQuestionnaireData}
          questionnaireData={questionnaireData}
          {...props} />
      </Grid>
      <Grid item display='grid' style={{ paddingTop: '30px', paddingBottom: '10px' }}>
        <Grid container direction='column' wrap="nowrap" spacing='10px'>
          <Grid item display='grid'>
            <div style={{ borderTop: '2px solid #E6EBF0', borderRadius: '20px', width: '100%' }} />
          </Grid>
          <Grid item display='grid'>
            <span style={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              color: '#657273',
              textAlign: 'end',
            }}>Prévisualisation</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display='grid' borderRadius='15px'>
        <QuestionnairePreview
          setQuestionnaireData={setQuestionnaireData}
          questionnaireData={questionnaireData} />
      </Grid>
    </Grid>
  );
}

const CreateQuestionnaireBox: React.FC<any> = (props) => {
  const {
    isCreate,
    isEdit,
  } = props;

  return (
    <Grid
      container
      direction='column'
      wrap='nowrap'
      display='flex'
      paddingLeft='41px'
      paddingTop='26px'
      paddingRight='41px'
      paddingBottom='60px'
      maxWidth='1000px'
      width='95vw'
      minWidth='320px'
      style={{
        background: 'rgb(247, 252, 252, 0.55)',
        borderRadius: '24px',
      }}>
      <Grid item display='grid'>
        <Grid container display='flex' direction='column' wrap='nowrap'>
          <Grid item display='grid'>
            <BoxTitle
              first={isCreate ? 'Créer' : 'Modifier'}
              second='un questionnaire' />
          </Grid>
          <Grid item display='grid' paddingTop='15px'>
            <CreateQuestionnaireContent {...props} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const CreateOrEditQuestionnairePage: React.FC<any> = (props) => {
  const history = useHistory();
  const {
    isCreate,
    isEdit,
  } = props;

  return (
    <>
      <FullPage>
        <Grid container direction='column' padding='10px' wrap="nowrap">
          <Grid item display='grid' paddingBottom='10px'>
            <button
              className={`return-button`}
              style={{ width: '100px', height: '30px', fontSize: '16px' }}
              onClick={() => history.push('/home?f=questionnaires')} >
              <span style={{ display: 'flex' }}>
                <Grid container direction='row' display='flex' justifyContent='center' alignItems='center'>
                  <Grid item display='grid' marginTop='1px'>
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display='grid'>
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display='grid'>
            <CreateQuestionnaireBox {...props} />
          </Grid>
        </Grid>
      </FullPage>
    </>
  )
}

export default CreateOrEditQuestionnairePage;