import * as React from 'react';
import UtilsClass from '../UtilsClass';

const useUtils = () => {
  const [utils, setUtils] = React.useState(null);
  const [professionOptions, setProfessionOptions] = React.useState([]);
  const [civilitiesOptions, setCivilitiesOptions] = React.useState([]);
  const [specialitiesOptions, setSpecialitiesOptions] = React.useState([]);
  const [nniTypesOptions, setNniTypesOptions] = React.useState([]);

  React.useEffect(() => {
    async function initUtils() {
      const newUtils = new UtilsClass();
      await newUtils.init();
      setUtils(newUtils);
    }
    initUtils();
  }, []);

  React.useEffect(() => {
    setSpecialitiesOptions(utils?.getSpecialitiesOptionsSorted());
    setProfessionOptions(utils?.getProfessionsOptionsSorted());
    setCivilitiesOptions(utils?.getCivilitiesOptionsSorted());
    setNniTypesOptions(utils?.getNniTypesOptionsSorted());
  }, [utils]);

  return ([utils, setUtils, professionOptions, civilitiesOptions, specialitiesOptions, nniTypesOptions]);
};

export default useUtils;
