import * as React from "react";
import addingErrorStyleToField from "../../../utils/addingErrorStyleToField";

interface IMultilineInput {
  onChange: any;
  onBlur?: any;
  onFocus?: any;
  value?: string;
  id?: string;
  className?: string;
  border?: string;
  maxLength?: number;
  isError?: boolean;
  height?: string;
  whiteSpace?: string;
  placeholder?: string;
}

const MultilineInput = (props: IMultilineInput) => {
  const {
    onBlur,
    onFocus,
    onChange,
    value,
    id,
    border,
    className,
    maxLength,
    height,
    isError,
    whiteSpace,
    placeholder,
  } = props;

  let style = {
    padding: "10px",
    margin: "0",
    width: "100%",
    border: border,
    height: height,
    whiteSpace: "break-spaces",
  };
  style = addingErrorStyleToField(style, isError);

  return (
    <div style={{ width: "100%" }}>
      <textarea
        id={id}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        style={style as any}
        className={`textarea_modals ${className ? className : ""}`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        maxLength={maxLength}
      />
    </div>
  );
};

MultilineInput.defaultProps = {
  maxLength: 325,
  whiteSpace: null,
  height: null,
};

export default MultilineInput;
