import * as React from 'react';
import { Grid } from "@mui/material";
import { WarningRounded } from "@mui/icons-material";

const DisplayError: React.FC<any> = (props) => {
  const {
    errorMessage,
  } = props;

  return (
    <Grid container direction='row' display='flex' alignItems='flex-start' wrap='nowrap'>
      <WarningRounded
        fontSize="small"
        style={{ color: 'rgb(255, 49, 0)', marginRight: '3px' }} />
      <span style={{
        color: 'rgb(255, 49, 0)',
        fontSize: '16px',
      }}>
        {errorMessage}
      </span>
    </Grid>
  );
}

export default DisplayError;