import * as React from 'react';
import { Grid } from "@mui/material";
import NavbarComponent from '../components/NavbarComponent';
import Footer from '../components/footer';

interface IFullPage {
  isLoggedIn?: boolean,
  noFooter?: boolean,
  noNavbar?: boolean,
  children?: any,
}

const FullPage = (props: IFullPage) => {
  return (
    <Grid container direction='column' wrap='nowrap'
      style={{
        minHeight: '100vh',
      }}>
      <Grid item display='grid' justifyContent='center'>
        <Grid container direction='column' wrap='nowrap'>
          {!props.noNavbar &&
            <Grid item display='grid'>
              <NavbarComponent isLoggedIn={props?.isLoggedIn} />
            </Grid>
          }
          <Grid item display='grid' paddingTop='20px' justifyContent='center'>
            {props.children}
          </Grid>
        </Grid>
      </Grid>
      {!props.noFooter &&
        <Grid item xs display='grid' alignItems='flex-end'>
          <Footer />
        </Grid>
      }
    </Grid >
  );
}

export default FullPage;
