import * as React from 'react';

const DisplayHowManyGroups: React.FC<any> = (props) => {
  const { groupsFiltered } = props;

  const nbGroups = groupsFiltered?.length;

  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      {!nbGroups || nbGroups === 0
        ? "Aucune groupe trouvé"
        : `${nbGroups} groupe${nbGroups > 1 ? "s" : ""} trouvé${
            nbGroups > 1 ? "s" : ""
          }`}
    </span>
  );
};

export default DisplayHowManyGroups;
