import * as React from 'react';
import { cleanHTML } from '../GenerateQuestionnaire';

const DisplayTextAndImage: React.FC<any> = (props) => {
  const {
    field,
  } = props;

  return (
    <div
      id='display-text-and-image'
      style={{
        wordBreak: 'break-word',
      }}
      dangerouslySetInnerHTML={{ __html: cleanHTML(field?.title) }}
    />
  );
};

export default DisplayTextAndImage;