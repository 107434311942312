import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import Creatable from "react-select/creatable";
import { updateSize } from "../redux/componentSizeReducer";
import {
  ArchiveRounded,
  Delete,
  Download,
  Edit,
  InfoRounded,
  Notifications,
  NotificationsNone,
  ScheduleSend,
  UnarchiveRounded,
  UpgradeOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import FormPopup from "../components/FormPopup/FormPopup";
import FormPopupHeader from "../components/FormPopup/FormPopupHeader";
import FullField from "../components/Fields/FullField";
import makeAPIRequest from "../utils/makeAPIRequest";
import OrangeButton from "../components/Library/Buttons/OrangeButton/OrangeButton";
import { openSnackbar } from "../redux/snackbarReducer";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import useUsersList from "../utils/hooks/useUsersList";
import getOnlyValuesFromArray from "../utils/getOnlyValuesFromArray";
import { Tab, Tabs } from "react-bootstrap";
import { formatDateFromTimestamp } from "../components/MonPatientBox/MonPatientBox";
import { ChatbotCreationStep, IChatbot } from "../types/ChatbotTypes";
import MySelect from "../components/Library/Select/MySelect";
import ErrorHelp from "../components/Library/Errors/ErrorHelp";
import {
  DisplayIntentions,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import { useHistory } from "react-router";
import IPatientData from "../types/Patient";
import { getPatientNameById } from "../components/ChatbotResponses/DisplayChatbotConversations";
import { IChatbotLexSchedule } from "../types/IChatbotSchedule";
import CancellablePopup from "../components/Library/Popups/CancellablePopup";
import useQuery from "../utils/hooks/useQuery";
import { GenericSelect } from "./QuestionnairePage";
import { genericGetFromTimestamp } from "../components/AdminGroups/CreateGroupPopup";
import useAppSelector from "../redux/useAppSelector";

const REFRESH_INTERVAL = 10000;
const ITEM_PER_ADD = 10;

const formatDateSchedule = (timestamp: number, status: any) => {
  const date = new Date(timestamp);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  let hours = date.getHours().toString();
  if (hours.length === 1) hours = `0${hours}`;

  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = `0${minutes}`;

  const converter = {
    "in progress": "Depuis le ",
    completed: "Le ",
    pending: "Programmé pour le ",
    cancelled: "Le ",
  };

  return (
    converter?.[status] +
    dd +
    "/" +
    mm +
    "/" +
    yyyy +
    " à " +
    hours +
    "h" +
    minutes
  );
};

export const cutMyString = (str: string, max: number): string[] => {
  const result: string[] = [];
  const cuttedStrPending = str.split(" ");
  let currentStr = "";

  while (cuttedStrPending.length > 0) {
    if (currentStr.length + cuttedStrPending[0].length + 1 < max) {
      currentStr += `${cuttedStrPending[0]} `;
      cuttedStrPending.shift();
    } else {
      result.push(currentStr);
      currentStr = "";
    }
  }
  if (currentStr) {
    result.push(currentStr);
  }
  return result.map((x) => (x.endsWith(" ") ? x.slice(0, -1) : x));
};

const formatStatus = (status: any) => {
  const converter = {
    "in progress": "En cours",
    completed: "Terminé",
    pending: "En attente",
    cancelled: "Annulé",
  };
  return converter?.[status];
};

const CardHeaderScheduleChatbot: React.FC<{
  scheduleChatbot: IChatbotLexSchedule;
  chatbots: IChatbot[];
  patientList: Partial<IPatientData>[];
}> = (props) => {
  const { scheduleChatbot, chatbots, patientList } = props;
  const [chatbotFounded, setChatbotFounded] =
    React.useState<IChatbot>(undefined);

  React.useEffect(() => {
    if (!scheduleChatbot || !chatbots) {
      return;
    }
    if (!Array.isArray(chatbots)) {
      return;
    }
    const c = chatbots.find((x) => scheduleChatbot.chatbotId === x._id);
    if (c) {
      setChatbotFounded(c);
    }
  }, [scheduleChatbot, chatbots]);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      paddingTop="10px"
      paddingLeft="10px"
      paddingRight="10px"
      paddingBottom="10px"
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      {chatbotFounded && (
        <>
          <Grid item display="grid" alignSelf="center" marginTop="5px">
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {chatbotFounded.title}
            </span>
          </Grid>
        </>
      )}
      {scheduleChatbot && (
        <>
          <Grid item display="grid" marginTop="10px">
            <span>
              {getPatientNameById(scheduleChatbot.patient, patientList)}
            </span>
          </Grid>
          <Grid item display="grid" marginTop="15px" alignSelf="flex-end">
            {formatStatus(scheduleChatbot?.chatbotStatus)}
          </Grid>
          <Grid item display="grid" marginTop="5px" alignSelf="flex-end">
            <span>
              {["cancelled", "completed"].includes(
                scheduleChatbot?.chatbotStatus
              )
                ? formatDateSchedule(
                    scheduleChatbot?.lastUpdate,
                    scheduleChatbot?.chatbotStatus
                  )
                : formatDateSchedule(
                    scheduleChatbot?.dateTrigger,
                    scheduleChatbot?.chatbotStatus
                  )}
            </span>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const ConversationsTab: React.FC = () => {
  const [chatbots, setChatbots] = React.useState<IChatbot[]>([]);
  const [patientList, setPatientList] = React.useState<
    Array<Partial<IPatientData>>
  >([]);
  const [maxItem, setMaxItem] = React.useState<number>(ITEM_PER_ADD);
  const [scheduleChatbots, setScheduleChatbots] = React.useState<
    IChatbotLexSchedule[]
  >([]);
  const [openDetails, setOpenDetails] =
    React.useState<IChatbotLexSchedule>(undefined);
  const openSnackbarFun = useOpenSnackbar();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [sorting, setSorting] = React.useState<{
    direction: "ascendant" | "descendant";
    type: "date_trigger" | "update_date";
  }>({
    direction: "descendant",
    type: "date_trigger",
  });

  const [filter, setFilter] = React.useState<{
    chatbotState: "pending" | "in progress" | "completed" | "cancelled";
    patientId: string | undefined;
    isArchived: boolean;
  }>({
    chatbotState: undefined,
    patientId: undefined,
    isArchived: false,
  });

  const chatbotStateQuery = useQuery().get("state");
  const patientQueryId = useQuery().get("p");
  React.useEffect(() => {
    if (patientQueryId) {
      setFilter((state) => {
        return { ...state, patientId: patientQueryId };
      });
    }
  }, [patientQueryId]);

  React.useEffect(() => {
    if (chatbotStateQuery) {
      setFilter((state) => {
        if (["completed", "cancelled"].includes(chatbotStateQuery)) {
          return {
            ...state,
            chatbotState: chatbotStateQuery as "completed" | "cancelled",
          };
        } else {
          return state;
        }
      });
    }
  }, [chatbotStateQuery]);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/chatbots_lex/list", null, "v3")
      .then((resChatbots: any) => {
        setChatbots(resChatbots?.data);
        makeAPIRequest("get", `/patients/list`, null, "v3")
          .then((resPatients: any) => {
            setPatientList(resPatients?.data);
            makeAPIRequest(
              "post",
              "/chatbots_lex/schedule/list",
              {
                filter: filter,
                sorting: sorting,
              },
              "v3"
            )
              .then((resSchedule: any) => {
                setScheduleChatbots(resSchedule?.data);
                setIsLoading(false);
              })
              .catch((errSchedule: any) => {
                openSnackbarFun.error(
                  "Impossible de récupérer la liste des envois",
                  errSchedule
                );
                setIsLoading(false);
              });
          })
          .catch((errPatients: any) => {
            openSnackbarFun.error(
              "Impossible de récupérer la liste des patients",
              errPatients
            );
          });
      })
      .catch((errChatbots: any) => {
        openSnackbarFun.error(
          "Impossible de récupérer la liste des chatbots",
          errChatbots
        );
      });
  }, [filter, sorting]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      makeAPIRequest(
        "post",
        "/chatbots_lex/schedule/list",
        {
          filter: filter,
          sorting: sorting,
        },
        "v3"
      )
        .then((resSchedule: any) => {
          setScheduleChatbots(resSchedule?.data);
        })
        .catch((errSchedule: any) => {
          return;
        });
    }, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [filter, sorting]);

  const sortingDirectionOptions = [
    {
      label: "Ascendant",
      value: "ascendant",
    },
    {
      label: "Descendant",
      value: "descendant",
    },
  ];

  const sortingTypeOptions = [
    {
      label: "Date de programmation",
      value: "date_trigger",
    },
    {
      label: "Date de modification",
      value: "update_date",
    },
  ];

  const filterStateOptions = [
    {
      label: "En attente",
      value: "pending",
    },
    {
      label: "En cours",
      value: "in progress",
    },
    {
      label: "Terminé",
      value: "completed",
    },
    {
      label: "Annulé",
      value: "cancelled",
    },
  ];

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!openDetails) {
        return;
      }
      makeAPIRequest(
        "get",
        `/chatbots_lex/schedule/${openDetails._id}/details`,
        null,
        "v3"
      )
        .then((res) => {
          setOpenDetails(res?.data);
        })
        .catch((err) => {
          return;
        });
    }, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [openDetails]);

  return (
    <>
      {openDetails && (
        <GenericFromPopup
          onClose={() => setOpenDetails(undefined)}
          title={{
            leftPart: "Détails",
            rightPart: "de la programmation",
          }}
          subtitle={`${formatStatus(openDetails.chatbotStatus)} - ${
            ["cancelled", "completed"].includes(openDetails?.chatbotStatus)
              ? formatDateSchedule(
                  openDetails?.lastUpdate,
                  openDetails?.chatbotStatus
                )
              : formatDateSchedule(
                  openDetails?.dateTrigger,
                  openDetails?.chatbotStatus
                )
          }${
            openDetails?.cancelledReason
              ? `\n${openDetails?.cancelledReason}`
              : ""
          }`}
        >
          <Grid container direction="column" wrap="nowrap" padding="10px">
            <Grid item display="grid">
              {openDetails?.conversation?.length > 0 && (
                <Grid
                  container
                  direction="column"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    color: "#0B243C",
                  }}
                  wrap="nowrap"
                  maxWidth="900px"
                  justifySelf="center"
                  boxShadow="4px 4px 3px 2px rgba(11, 36, 60, .3)"
                  border="1px solid #0B243C"
                  borderRadius="30px"
                  marginTop="20px"
                >
                  <>
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        padding="10px"
                        wrap="nowrap"
                        justifyContent="space-around"
                        borderBottom="1px solid #0B243C"
                      >
                        <Grid item display="grid">
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {"Chatbot"}
                          </span>
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {getPatientNameById(
                              openDetails.patient,
                              patientList
                            )}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        padding="25px"
                        gap="20px"
                      >
                        {openDetails.conversation.map((conv, index) => {
                          return (
                            <Grid
                              item
                              display="grid"
                              key={index}
                              boxShadow="4px 4px 3px 2px rgba(11, 36, 60, .3)"
                              border="1px solid #0b243c"
                              padding="15px"
                              borderRadius="30px"
                              alignSelf={
                                conv.sender === "chatbot"
                                  ? "flex-start"
                                  : "flex-end"
                              }
                            >
                              <Grid
                                container
                                direction="column"
                                maxWidth="400px"
                                wrap="nowrap"
                                spacing="5px"
                              >
                                <Grid item display="grid">
                                  <span
                                    style={{
                                      textAlign:
                                        conv.sender === "chatbot"
                                          ? "left"
                                          : "right",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {conv.message}
                                  </span>
                                </Grid>
                                <Grid item display="grid">
                                  <span
                                    style={{
                                      textAlign:
                                        conv.sender === "chatbot"
                                          ? "right"
                                          : "left",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {conv.sender === "chatbot" && conv.smsReason
                                      ? `${conv.smsReason} - `
                                      : ""}
                                    {formatDateFromTimestamp(conv.date)}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              )}
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing="10px"
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#0B243C",
        }}
      >
        <Grid item display="grid">
          <span>Filtrer :</span>
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            options={filterStateOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              filter.chatbotState
                ? filterStateOptions.find(
                    (x) => x.value === filter.chatbotState
                  )
                : undefined
            }
            placeholder="État"
            onChange={(e) => {
              setFilter((state) => {
                return {
                  ...state,
                  chatbotState: e?.value ? e.value : undefined,
                };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            options={(() => {
              const optionsTmp = [];
              for (const patient of patientList) {
                if (patient?.id) {
                  optionsTmp.push({
                    label: getPatientNameById(patient.id, patientList),
                    value: patient.id,
                  });
                }
              }
              return optionsTmp;
            })()}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              patientList?.length > 0 && filter.patientId
                ? {
                    label: getPatientNameById(filter.patientId, patientList),
                    value: filter.patientId,
                  }
                : undefined
            }
            maxMenuHeight="150px"
            placeholder="Patient"
            onChange={(e) => {
              setFilter((state) => {
                return { ...state, patientId: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            isClearable={false}
            options={[
              {
                label: "Non archivé",
                value: "not_archived",
              },
              {
                label: "Archivé",
                value: "archived",
              },
            ]}
            value={
              filter.isArchived
                ? {
                    label: "Archivé",
                    value: "archived",
                  }
                : {
                    label: "Non archivé",
                    value: "not_archived",
                  }
            }
            onChange={(e) => {
              setFilter((state) => {
                return {
                  ...state,
                  isArchived: e?.value === "not_archived" ? false : true,
                };
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing="10px"
        paddingTop="10px"
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#0B243C",
        }}
      >
        <Grid item display="grid">
          <span>Trier :</span>
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            isClearable={false}
            options={sortingTypeOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              sorting.type
                ? sortingTypeOptions.find((x) => x.value === sorting.type)
                : undefined
            }
            placeholder=""
            onChange={(e) => {
              setSorting((state) => {
                return { ...state, type: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            isClearable={false}
            options={sortingDirectionOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              sorting.direction
                ? sortingDirectionOptions.find(
                    (x) => x.value === sorting.direction
                  )
                : undefined
            }
            placeholder="Direction"
            onChange={(e) => {
              setSorting((state) => {
                return { ...state, direction: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
      </Grid>
      {isLoading && scheduleChatbots.length === 0 && (
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop="30px"
        >
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && scheduleChatbots.length === 0 && (
        <Grid item display="grid" paddingTop="20px">
          <span
            style={{
              fontSize: "16px",
              color: "#0B243C",
              fontFamily: "Poppins",
            }}
          >{`Aucune programmation n'a été trouvée.`}</span>
        </Grid>
      )}
      {scheduleChatbots.length > 0 && (
        <ul className="list-group" style={{ paddingTop: "10px" }}>
          {scheduleChatbots.slice(0, maxItem).map((scheduleChatbot, index) => {
            return (
              <li
                className={"conseil-card"}
                style={{ padding: "0", animationName: "unset" }}
                key={index}
              >
                <Grid container direction="row" wrap="nowrap">
                  <Grid
                    item
                    display="grid"
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    <Tooltip
                      title={
                        scheduleChatbot.isArchived ? "Désarchiver" : "Archiver"
                      }
                    >
                      <IconButton
                        style={{
                          margin: "0",
                          padding: "0",
                          marginLeft: "20px",
                          marginRight: "20px",
                          alignSelf: "center",
                          justifySelf: "center",
                        }}
                        onClick={
                          scheduleChatbot.isArchived
                            ? () => {
                                makeAPIRequest(
                                  "get",
                                  `/chatbots_lex/schedule/${scheduleChatbot._id}/unarchive`,
                                  null,
                                  "v3"
                                )
                                  .then((res) => {
                                    makeAPIRequest(
                                      "post",
                                      "/chatbots_lex/schedule/list",
                                      {
                                        filter: filter,
                                        sorting: sorting,
                                      },
                                      "v3"
                                    )
                                      .then((resSchedule: any) => {
                                        setScheduleChatbots(resSchedule?.data);
                                        openSnackbarFun.success(
                                          "La programmation a bien été désarchivée.",
                                          res
                                        );
                                      })
                                      .catch((err) => {
                                        openSnackbarFun.error(
                                          "Impossible de récupérer la liste des envois.",
                                          err
                                        );
                                      });
                                  })
                                  .catch((err) => {
                                    openSnackbarFun.error(
                                      "Impossible de désarchiver cette programmation.",
                                      err
                                    );
                                  });
                              }
                            : () => {
                                makeAPIRequest(
                                  "get",
                                  `/chatbots_lex/schedule/${scheduleChatbot._id}/archive`,
                                  null,
                                  "v3"
                                )
                                  .then((res) => {
                                    makeAPIRequest(
                                      "post",
                                      "/chatbots_lex/schedule/list",
                                      {
                                        filter: filter,
                                        sorting: sorting,
                                      },
                                      "v3"
                                    )
                                      .then((resSchedule: any) => {
                                        setScheduleChatbots(resSchedule?.data);
                                        openSnackbarFun.success(
                                          "La programmation a bien été archivée.",
                                          res
                                        );
                                      })
                                      .catch((err) => {
                                        openSnackbarFun.error(
                                          "Impossible de récupérer la liste des envois.",
                                          err
                                        );
                                      });
                                  })
                                  .catch((err) => {
                                    openSnackbarFun.error(
                                      "Impossible d'archiver cette programmation.",
                                      err
                                    );
                                  });
                              }
                        }
                      >
                        {scheduleChatbot.isArchived ? (
                          <UnarchiveRounded
                            fontSize="small"
                            style={{ color: "rgb(11, 36, 60)" }}
                          />
                        ) : (
                          <ArchiveRounded
                            fontSize="small"
                            style={{ color: "rgb(11, 36, 60)" }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs
                    display="grid"
                    style={{
                      paddingLeft: "10px",
                      paddingBottom: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <CardHeaderScheduleChatbot
                      scheduleChatbot={scheduleChatbot}
                      chatbots={chatbots}
                      patientList={patientList}
                    />
                  </Grid>
                  <Grid
                    item
                    display="grid"
                    style={{
                      borderLeft: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      spacing="10px"
                      padding="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item display="grid">
                        <Tooltip title="Détails">
                          <IconButton
                            style={{
                              margin: "0",
                              padding: "0",
                            }}
                            onClick={() => {
                              makeAPIRequest(
                                "get",
                                `/chatbots_lex/schedule/${scheduleChatbot._id}/details`,
                                null,
                                "v3"
                              )
                                .then((res) => {
                                  setOpenDetails(res?.data);
                                })
                                .catch((err) => {
                                  openSnackbarFun.error(
                                    "Impossible d'obtenir les détails"
                                  ),
                                    err;
                                });
                            }}
                          >
                            <InfoRounded
                              fontSize="small"
                              style={{ color: "#0B243C" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </li>
            );
          })}
        </ul>
      )}
      <MoreOrLessButtons
        items={scheduleChatbots}
        maxItem={maxItem}
        setMaxItem={setMaxItem}
      />
    </>
  );
};

export const getCreationStepText = (
  creationStep: ChatbotCreationStep
): string => {
  if (creationStep === "Built") {
    return "Prêt à être utilisé";
  }
  return "En cours de construction";
};

export const useDateAndTimeInputs = () => {
  let date = new Date();
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  const [dateInput, setDateInput] = React.useState(
    date.toISOString().split("T")[0]
  );
  const [timeInput, setTimeInput] = React.useState(
    date.toISOString().split("T")[1].substring(0, 5)
  );

  return {
    dateInput,
    setDateInput,
    timeInput,
    setTimeInput,
  };
};

export const GenericDateDeclenchement: React.FC<{
  dateInput: string;
  timeInput: string;
  setDateInput: React.Dispatch<React.SetStateAction<string>>;
  setTimeInput: React.Dispatch<React.SetStateAction<string>>;
  idTime?: string;
  idDate?: string;
  fieldTitle?: string;
}> = (props) => {
  const { timeInput, dateInput, setDateInput, setTimeInput } = props;
  return (
    <FullField isMandatory={true} title={props.fieldTitle}>
      <Grid
        container
        direction="row"
        alignItems="center"
        wrap="nowrap"
        spacing="10px"
      >
        <Grid item display="grid">
          <span>Le</span>
        </Grid>
        <Grid item display="grid">
          <input
            id={props.idDate}
            type="date"
            style={{
              minWidth: "100%",
              border: "1px solid #D3E0E0",
              background: "#EDF2F280 0% 0% no-repeat padding-box",
              borderRadius: "10px",
              paddingRight: "20px",
              paddingLeft: "20px",
              color: dateInput && dateInput?.length > 0 ? "#0B243C" : "#657273",
              fontFamily: "Poppins",
              fontSize: "16px",
              height: "42px",
            }}
            value={dateInput}
            onChange={(e) => setDateInput(e.target.value)}
          />
        </Grid>
        <Grid item display="grid">
          <span>à</span>
        </Grid>
        <Grid item display="grid">
          <input
            id={props.idTime}
            type="time"
            style={{
              minWidth: "100%",
              border: "1px solid #D3E0E0",
              background: "#EDF2F280 0% 0% no-repeat padding-box",
              borderRadius: "10px",
              paddingRight: "20px",
              paddingLeft: "20px",
              color: timeInput && timeInput?.length > 0 ? "#0B243C" : "#657273",
              fontFamily: "Poppins",
              fontSize: "16px",
              height: "42px",
            }}
            value={timeInput}
            onChange={(e) => setTimeInput(e.target.value)}
          />
        </Grid>
      </Grid>
    </FullField>
  );
};

GenericDateDeclenchement.defaultProps = {
  idDate: undefined,
  idTime: undefined,
  fieldTitle: "Date de déclenchement",
};

export const GenericPatientsChoice: React.FC<{
  patientList: any[];
  id?: string;
  fieldTitle?: string;
  patientSearched: Array<{
    label?: string;
    value?: string;
  }>;
  setPatientSearched: any;
}> = (props) => {
  return (
    <FullField isMandatory={true} title={props.fieldTitle}>
      <div
        id={props.id}
        style={{
          borderRadius: "10px",
          width: "100%",
          border: "1px solid transparent",
        }}
      >
        <Creatable
          noOptionsMessage={() => "Aucune option"}
          formatCreateLabel={(userInput) => `...`}
          isMulti={true}
          maxMenuHeight={150}
          options={(() => {
            const optionsTmp = [];
            if (!props.patientList) return optionsTmp;
            for (const patient of props.patientList) {
              if (patient?.phone) {
                optionsTmp.push({
                  value: patient?.id,
                  label: patient?.lastname + " " + patient?.firstname,
                });
              }
            }
            return optionsTmp;
          })()}
          value={props.patientSearched}
          placeholder="Liste des patients"
          onChange={(e) => {
            const newPatients = [];
            for (const patient of e) {
              if (props.patientList?.find((p) => p?.id === patient?.value))
                newPatients.push(patient);
            }
            props.setPatientSearched(newPatients);
          }}
        />
      </div>
    </FullField>
  );
};

GenericPatientsChoice.defaultProps = {
  fieldTitle: "Choix des patients",
  id: undefined,
};

export const GenericFromPopup: React.FC<{
  title: { leftPart: string; rightPart: string };
  subtitle?: string;
  onClose: () => void;
  xs?: number;
  minHeight?: number;
  addCloseSecurity?: boolean;
}> = (props) => {
  const [displaySecurity, setDisplaySecurity] = React.useState(false);
  return (
    <>
      {displaySecurity && (
        <CancellablePopup
          cancelButtonText="Non"
          confirmButtonText="Oui"
          firstTitle="Fermer"
          secondTitle="ma pop-up"
          isLoading={false}
          zIndex={25001}
          onClose={() => {
            setDisplaySecurity(false);
          }}
          onConfirm={() => {
            setDisplaySecurity(false);
            props.onClose();
          }}
          onCancel={() => {
            setDisplaySecurity(false);
          }}
          message={`Êtes-vous sûr de vouloir fermer cette pop-up ?\nLes modifications apportées ne seront pas sauvegardées.`}
        />
      )}
      <FormPopup
        xs={props?.xs ? props?.xs : 9}
        minHeight={props?.minHeight ? props.minHeight : undefined}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          paddingTop="40px"
          paddingLeft="50px"
          paddingRight="50px"
          paddingBottom="50px"
        >
          <Grid item display="grid">
            <FormPopupHeader
              firstTitle={props.title.leftPart}
              secondTitle={props.title.rightPart}
              onClose={
                props.addCloseSecurity
                  ? () => setDisplaySecurity(true)
                  : () => props.onClose()
              }
            />
          </Grid>
          <Grid item display="grid" paddingTop="25px">
            <Grid
              container
              direction="column"
              gap="15px"
              wrap="nowrap"
              style={{
                fontSize: "16px",
                color: "#0B243C",
                fontFamily: "Poppins",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              {props.subtitle && (
                <Grid
                  item
                  display="grid"
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      whiteSpace: "pre-line",
                      textAlign: "center",
                    }}
                  >
                    {props.subtitle}
                  </span>
                </Grid>
              )}
              <Grid item display="grid">
                {props.children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormPopup>
    </>
  );
};

export const GenericSendButton: React.FC<{
  onSendClick: () => void;
  isLoading?: boolean;
  invalidConditions: any;
  errorMessages: any;
  errorSelector: any;
  textButton?: string;
  disablePaddingTop?: boolean;
}> = (props) => {
  const [error, setError] = React.useState("");

  const isValidSubmit = () => {
    if (!props.invalidConditions) {
      return true;
    }
    for (const condition of Object.keys(props.invalidConditions)) {
      if (props.invalidConditions[condition]) {
        if (!props.errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      paddingTop={props.disablePaddingTop ? "0px" : "30px"}
      marginBottom="15px"
    >
      {error?.length > 0 && (
        <Grid item display="grid">
          <ErrorHelp
            errorMessages={props.errorMessages}
            errorSelector={props.errorSelector}
            error={error}
          />
        </Grid>
      )}
      <Grid item display="grid" justifyContent="center">
        {isValidSubmit() ? (
          <OrangeButton
            animation={false}
            enable={true}
            type="submit"
            isLoading={props.isLoading}
            text={props.textButton}
            onClick={props.onSendClick}
          />
        ) : (
          <OrangeButton
            animation={false}
            enable={false}
            isLoading={props.isLoading}
            type="submit"
            text={props.textButton}
            onClick={() => {
              return null;
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

GenericSendButton.defaultProps = {
  textButton: "Envoyer",
  isLoading: false,
};

const ChatbotLexSchedulePopup: React.FC<{
  setOpenSchedule: React.Dispatch<React.SetStateAction<IChatbot>>;
  chatbotLex: IChatbot;
}> = (props) => {
  const { dateInput, setDateInput, setTimeInput, timeInput } =
    useDateAndTimeInputs();
  const [patientList, setPatientList] = React.useState([]);
  const [isOpenDetails, setIsOpenDetails] = React.useState<boolean>(false);
  const [sendIsLoading, setSendIsLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [patientSearched, setPatientSearched] = React.useState<
    Array<{
      label?: string;
      value?: string;
    }>
  >(null);
  const [inactiveTimeStop, setInactiveTimeStop] = React.useState<{
    label?: string;
    value?: number;
  }>({ label: "Au bout de 10 minutes sans réponse", value: 10 });
  const [tooManyResponseStop, setTooManyResponseStop] = React.useState<{
    label?: string;
    value?: number;
  }>({ label: "Au bout de 20 réponses", value: 20 });
  const dispatch = useDispatch();

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", `/patients/list`, null, "v3")
      .then((res: any) => {
        setPatientList(res?.data);
        setIsLoading(false);
      })
      .catch(() => {
        dispatch(
          openSnackbar({
            message: "Impossible de récupérer la liste des patients",
            duration: 3000,
            type: "error",
          })
        );
        setIsLoading(false);
        props.setOpenSchedule(undefined);
      });
  }, []);

  const onSendClick = () => {
    if (patientSearched?.length == 0) {
      return;
    }
    setSendIsLoading(true);
    makeAPIRequest(
      "post",
      `/chatbots_lex/schedule`,
      {
        chatbotId: props.chatbotLex._id,
        patients: patientSearched.map((p) => p?.value),
        dateTrigger: Date.parse(dateInput + "T" + timeInput + ":00"),
        inactiveTimeStop: inactiveTimeStop.value,
        tooManyResponseStop: tooManyResponseStop.value,
      },
      "v3"
    )
      .then((res) => {
        dispatch(
          openSnackbar({
            message: res?.data?.message
              ? res?.data?.message
              : `Votre chatbot a bien été programmé`,
            type: "success",
            duration: 5000,
          })
        );
        props.setOpenSchedule(undefined);
      })
      .catch((err) => {
        dispatch(
          openSnackbar({
            message: err?.response?.data?.message
              ? err?.response?.data?.message
              : `La programmation de votre chatbot a échouée`,
            type: "error",
            duration: 5000,
          })
        );
      })
      .finally(() => setSendIsLoading(false));
  };

  if (isLoading) {
    return <></>;
  }
  if (isOpenDetails) {
    return (
      <GenericFromPopup
        title={{
          leftPart: `Confirmation`,
          rightPart: `de l'envoi`,
        }}
        subtitle={props.chatbotLex.title}
        onClose={() => setIsOpenDetails(false)}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap="20px"
          style={{
            fontSize: 16,
            color: "#0B243C",
            fontFamily: "Poppins",
            whiteSpace: "pre-line",
          }}
        >
          <Grid item display="grid">
            <span>
              Les SMS seront envoyés le{" "}
              {genericGetFromTimestamp(
                Date.parse(dateInput + "T" + timeInput + ":00"),
                "dd/mm/yyyy à hh:min"
              )}{" "}
              {patientSearched?.length > 1 ? "aux patients" : "au patient"}{" "}
              ci-dessous.
            </span>
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" gap="25px">
              {patientSearched
                .map((p) => p?.value)
                .filter((p) => p)
                .map((patientId, index) => {
                  const patient = patientList.find((x) => x?.id == patientId);
                  if (!patient || !patient?.firstname || !patient?.lastname) {
                    return <></>;
                  }
                  const smsIntro = props.chatbotLex.smsIntro
                    .replaceAll("{nom_patient}", patient?.lastname)
                    .replaceAll("{prénom_patient}", patient?.firstname)
                    .replaceAll("{prenom_patient}", patient?.firstname)
                    .replaceAll(
                      "{liste_questions}",
                      props.chatbotLex.intents
                        .filter((i) => i?.addToListQuestions)
                        .map((x, index) => `${index + 1}) ${x.questions?.[0]}`)
                        .join("\n")
                    );
                  const cuttedSmsIntro = cutMyString(smsIntro, 630);
                  return (
                    <Grid item display="grid" key={index}>
                      <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        gap="15px"
                      >
                        <Grid item display="grid">
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              textAlign: "center",
                            }}
                          >
                            {patient.firstname} {patient?.lastname} -{" "}
                            {cuttedSmsIntro.length} SMS
                          </span>
                        </Grid>
                        <Grid item display="grid">
                          <Grid container direction="row" gap="15px">
                            {cuttedSmsIntro.map((smsIntroPart, index) => {
                              return (
                                <Grid
                                  item
                                  display="grid"
                                  key={index}
                                  alignSelf="flex-start"
                                  border="1px solid black"
                                  boxShadow="4px 4px 2px 1px rgba(11, 36, 60, .5)"
                                  borderRadius="16px"
                                  padding="10px"
                                >
                                  <>
                                    <span style={{ fontSize: 14 }}>
                                      {smsIntroPart}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: 14,
                                        textAlign: "end",
                                        paddingTop: 5,
                                      }}
                                    >
                                      {index + 1}/{cuttedSmsIntro.length}
                                    </span>
                                  </>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              isLoading={sendIsLoading}
              invalidConditions={{
                emptyPatients:
                  !patientSearched || patientSearched?.length === 0,
                emptyDate: !dateInput,
                emptyTime: !timeInput,
              }}
              errorMessages={{
                emptyPatients: "Aucun patient n'est selectionné.",
                emptyDate: "La date de déclenchement n'est pas renseignée.",
                emptyTime: "L'heure de déclenchement n'est pas renseignée.",
                "": "",
              }}
              errorSelector={{
                emptyPatients: "#chatbot-patient-choice",
                emptyDate: "#chatbot-date-choice",
                emptyTime: "#chatbot-time-choice",
                "": "",
              }}
              onSendClick={onSendClick}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    );
  }

  return (
    <GenericFromPopup
      title={{
        leftPart: `Programmer`,
        rightPart: `l'envoi`,
      }}
      subtitle={props.chatbotLex.title}
      onClose={() => props.setOpenSchedule(undefined)}
    >
      <Grid container direction="column" wrap="nowrap" spacing="10px">
        <Grid item display="grid">
          <GenericSelect
            isMandatory={true}
            isClearable={false}
            maxMenuHeight={"200px"}
            fieldTitle="Arrêt du chatbot"
            id={`inactive-time-stop`}
            options={[
              {
                label: "Au bout de 5 minutes sans réponse",
                value: 5,
              },
              {
                label: "Au bout de 10 minutes sans réponse",
                value: 10,
              },
              {
                label: "Au bout de 15 minutes sans réponse",
                value: 15,
              },
              {
                label: "Au bout de 20 minutes sans réponse",
                value: 20,
              },
            ]}
            value={inactiveTimeStop}
            placeholder={""}
            onChange={(e) => {
              if (e?.value && e?.label) {
                setInactiveTimeStop(e);
              }
            }}
          />
        </Grid>
        <Grid item display="grid">
          <GenericSelect
            isMandatory={true}
            isClearable={false}
            maxMenuHeight={"200px"}
            fieldTitle=""
            id={`number-of-response-stop`}
            options={[
              {
                label: "Au bout de 10 réponses",
                value: 10,
              },
              {
                label: "Au bout de 20 réponses",
                value: 20,
              },
              {
                label: "Au bout de 30 réponses",
                value: 30,
              },
              {
                label: "Au bout de 40 réponses",
                value: 40,
              },
              {
                label: "Au bout de 50 réponses",
                value: 50,
              },
            ]}
            value={tooManyResponseStop}
            placeholder={""}
            onChange={(e) => {
              if (e?.value && e?.label) {
                setTooManyResponseStop(e);
              }
            }}
          />
        </Grid>
        <Grid item display="grid">
          <GenericPatientsChoice
            patientList={patientList}
            patientSearched={patientSearched}
            setPatientSearched={setPatientSearched}
            id={"chatbot-patient-choice"}
          />
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <GenericDateDeclenchement
            dateInput={dateInput}
            setDateInput={setDateInput}
            setTimeInput={setTimeInput}
            timeInput={timeInput}
            idDate={"chatbot-date-choice"}
            idTime={"chatbot-time-choice"}
          />
        </Grid>
        <Grid item display="grid">
          <GenericSendButton
            invalidConditions={{
              emptyPatients: !patientSearched || patientSearched?.length === 0,
              emptyDate: !dateInput,
              emptyTime: !timeInput,
            }}
            errorMessages={{
              emptyPatients: "Aucun patient n'est selectionné.",
              emptyDate: "La date de déclenchement n'est pas renseignée.",
              emptyTime: "L'heure de déclenchement n'est pas renseignée.",
              "": "",
            }}
            errorSelector={{
              emptyPatients: "#chatbot-patient-choice",
              emptyDate: "#chatbot-date-choice",
              emptyTime: "#chatbot-time-choice",
              "": "",
            }}
            textButton={"Suivant"}
            onSendClick={() => setIsOpenDetails(true)}
          />
        </Grid>
      </Grid>
    </GenericFromPopup>
  );
};

export const ChatbotLexDetails = (props: {
  setOpenDetail: any;
  chatbotLex: IChatbot;
}) => {
  return (
    <GenericFromPopup
      title={{
        leftPart: `Détails`,
        rightPart: `du chatbot`,
      }}
      subtitle={props.chatbotLex.title}
      onClose={() => props.setOpenDetail(undefined)}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing="10px"
        padding="10px"
        style={{
          whiteSpace: "pre-line",
        }}
      >
        {props.chatbotLex.smsIntro && (
          <Grid item display="grid">
            <Grid container direction="row" wrap="nowrap" gap="10px">
              <Grid item display="grid">
                <span>{`SMS d'introduction : `}</span>
              </Grid>
              <Grid item display="grid" xs>
                <span>{`${props.chatbotLex.smsIntro}`}</span>
              </Grid>
            </Grid>
          </Grid>
        )}
        {props.chatbotLex.smsSecours && (
          <Grid item display="grid">
            <Grid container direction="row" wrap="nowrap" gap="10px">
              <Grid item display="grid">
                <span>{`SMS de secours : `}</span>
              </Grid>
              <Grid item display="grid" xs>
                <span>{`${props.chatbotLex.smsSecours}`}</span>
              </Grid>
            </Grid>
          </Grid>
        )}
        {props.chatbotLex.nluIntentConfidenceThreshold !== undefined && (
          <Grid item display="grid">
            <Grid container direction="row" wrap="nowrap" gap="10px">
              <Grid item display="grid">
                <span>{`Seuil de confiance : `}</span>
              </Grid>
              <Grid item display="grid" xs>
                <span>{`${
                  props.chatbotLex.nluIntentConfidenceThreshold
                    ? Math.round(
                        props.chatbotLex.nluIntentConfidenceThreshold * 100
                      )
                    : 60
                }%`}</span>
              </Grid>
            </Grid>
          </Grid>
        )}
        {Array.isArray(props.chatbotLex.intents) &&
          props.chatbotLex.intents.length > 0 && (
            <Grid item display="grid">
              <Grid
                container
                direction="column"
                wrap="nowrap"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#0B243C",
                }}
              >
                <Grid item display="grid">
                  <span>{`Il y a ${props.chatbotLex.intents.length} intention${
                    props.chatbotLex.intents.length > 1 ? "s" : ""
                  } :`}</span>
                </Grid>
                <Grid item display="grid" paddingTop="10px">
                  <DisplayIntentions
                    chatbotData={props.chatbotLex}
                    activeDelete={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
    </GenericFromPopup>
  );
};

export const ChatbotLexAdminPanel: React.FC<any> = (props) => {
  const { setIsChatbotsLexAdminPanelOpen } = props;
  const [adminList, setAdminList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const { usersList, formatThisList } = useUsersList();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!usersList || usersList.length === 0) return;
    makeAPIRequest("get", `/chatbot_lex/access/list`, null, "v3")
      .then((res) => {
        setAdminList(formatThisList(res?.data?.admins));
        setUserList(formatThisList(res?.data?.users));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [usersList]);

  return (
    <>
      <FormPopup>
        <Grid
          item
          display="grid"
          paddingTop="40px"
          paddingLeft="50px"
          paddingRight="50px"
          paddingBottom="40px"
        >
          <FormPopupHeader
            firstTitle={"Gérer"}
            secondTitle={"les droits"}
            onClose={() => {
              setIsChatbotsLexAdminPanelOpen(false);
            }}
          />
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            display="flex"
            width="70%"
            justifySelf="center"
            direction="column"
            wrap="nowrap"
            paddingBottom="40px"
          >
            <Grid item display="grid">
              <FullField title="Liste des administrateurs" isMandatory={false}>
                <div
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid transparent",
                  }}
                >
                  <Creatable
                    noOptionsMessage={() => "Aucune option"}
                    formatCreateLabel={(userInput) => `...`}
                    isMulti={true}
                    maxMenuHeight={120}
                    options={(() => {
                      const optionsTmp = [];
                      for (const user of usersList) {
                        if (user?.email && user.email?.length > 0) {
                          optionsTmp.push({
                            label: user?.email,
                            value: user?.user_id,
                          });
                        }
                      }
                      return optionsTmp;
                    })()}
                    value={adminList}
                    placeholder="Ajouter un administrateur"
                    onChange={(e) => {
                      setAdminList(
                        e?.filter((opt) =>
                          usersList?.find((user) => user?.email == opt?.label)
                        )
                      );
                    }}
                  />
                </div>
              </FullField>
            </Grid>
            <Grid item display="grid" paddingTop="20px">
              <FullField title="Liste des utilisateurs" isMandatory={false}>
                <div
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid transparent",
                  }}
                >
                  <Creatable
                    noOptionsMessage={() => "Aucune option"}
                    formatCreateLabel={(userInput) => `...`}
                    isMulti={true}
                    maxMenuHeight={120}
                    options={(() => {
                      const optionsTmp = [];
                      for (const user of usersList) {
                        if (user?.email && user.email?.length > 0) {
                          optionsTmp.push({
                            label: user?.email,
                            value: user?.user_id,
                          });
                        }
                      }
                      return optionsTmp;
                    })()}
                    value={userList}
                    placeholder="Ajouter un utilisateur"
                    onChange={(e) => {
                      setUserList(
                        e?.filter((opt) =>
                          usersList?.find((user) => user?.email == opt?.label)
                        )
                      );
                    }}
                  />
                </div>
              </FullField>
            </Grid>
            <Grid
              item
              display="grid"
              alignItems="center"
              justifyContent="center"
              paddingTop="30px"
            >
              <OrangeButton
                animation={true}
                enable={true}
                type="submit"
                text="Mettre à jour"
                onClick={() => {
                  makeAPIRequest(
                    "post",
                    `/chatbot_lex/access/update`,
                    {
                      users: getOnlyValuesFromArray(userList),
                      admins: getOnlyValuesFromArray(adminList),
                    },
                    "v3"
                  )
                    .then((res) => {
                      dispatch(
                        openSnackbar({
                          duration: 3000,
                          message: "Les droits ont bien été mis à jour.",
                          type: "success",
                        })
                      );
                      setIsChatbotsLexAdminPanelOpen(false);
                    })
                    .catch((err) => {
                      dispatch(
                        openSnackbar({
                          duration: 3000,
                          message: "Impossible de mettre à jour les droits.",
                          type: "error",
                        })
                      );
                      console.log(err);
                    });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormPopup>
    </>
  );
};

export const MyTabTitle: React.FC<{
  text: string;
  onClick: () => void;
}> = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "50px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <span
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

const AdminChatboxLexContent: React.FC = (props) => {
  const { usersList } = useUsersList();
  const dispatch = useDispatch();
  const [adminChatbotsLex, setAdminChatbots] = React.useState<IChatbot[]>([]);
  const [filter, setFilter] = React.useState<{
    chatbotState: "validated" | "not_validated" | undefined;
    userId: string | undefined;
  }>({
    chatbotState: undefined,
    userId: undefined,
  });
  const [sorting, setSorting] = React.useState<{
    direction: "ascendant" | "descendant";
    type: "creation_date" | "lastValidation" | "update_date";
  }>({
    direction: "descendant",
    type: "creation_date",
  });
  const [isAdminLoading, setIsAdminLoading] = React.useState<boolean>(false);
  const [openDetails, setOpenDetails] = React.useState<IChatbot>(undefined);
  const [maxItem, setMaxItem] = React.useState<number>(ITEM_PER_ADD);

  const listUsersIdHavingChatbot = adminChatbotsLex.map((x) => x?.user_id);
  const filterStateOptions = [
    {
      label: "Validé",
      value: "validated",
    },
    {
      label: "Non validé",
      value: "not_validated",
    },
    {
      label: "À mettre à jour",
      value: "need_update",
    },
  ];

  const sortingDirectionOptions = [
    {
      label: "Ascendant",
      value: "ascendant",
    },
    {
      label: "Descendant",
      value: "descendant",
    },
  ];

  const sortingTypeOptions = [
    {
      label: "Date de création",
      value: "creation_date",
    },
    {
      label: "Date de validation",
      value: "lastValidation",
    },
    {
      label: "Date de modification",
      value: "update_date",
    },
  ];

  React.useEffect(() => {
    setIsAdminLoading(true);
    makeAPIRequest(
      "post",
      "/chatbots_lex/admin_list",
      { filter: filter, sorting: sorting },
      "v3"
    )
      .then((res) => {
        setAdminChatbots(res?.data);
        setIsAdminLoading(false);
      })
      .catch(() => {
        setIsAdminLoading(false);
      });
  }, [filter, sorting]);

  const CardHeader: React.FC<{ adminChatbotLex: IChatbot }> = (props) => {
    const { adminChatbotLex } = props;

    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        paddingTop="10px"
        paddingLeft="10px"
        paddingRight="10px"
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#0B243C",
        }}
      >
        <Grid item display="grid">
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {adminChatbotLex?.title}
          </span>
        </Grid>
        {usersList && (
          <Grid item display="grid">
            <span>
              Auteur :{" "}
              {(() => {
                const user = usersList.find(
                  (u) => u?.user_id === adminChatbotLex?.user_id
                );
                return `${user?.first_name} ${user?.last_name}`;
              })()}
            </span>
          </Grid>
        )}
        <Grid item display="grid">
          <span>
            Créé le {formatDateFromTimestamp(adminChatbotLex?.creation_date)}
          </span>
        </Grid>
        {adminChatbotLex?.update_date && (
          <Grid item display="grid">
            <span>
              Dernière modification le{" "}
              {formatDateFromTimestamp(adminChatbotLex?.update_date)}
            </span>
          </Grid>
        )}
        {adminChatbotLex?.lastValidation && (
          <Grid item display="grid" justifyContent="flex-end">
            <span>
              Validé le{" "}
              {formatDateFromTimestamp(adminChatbotLex?.lastValidation)}
            </span>
          </Grid>
        )}
      </Grid>
    );
  };
  const checkNeedUpdate = (x: IChatbot) => {
    if (
      x?.lastValidation &&
      x?.update_date &&
      x?.update_date > x?.lastValidation
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {openDetails && (
        <ChatbotLexDetails
          chatbotLex={openDetails}
          setOpenDetail={setOpenDetails}
        />
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing="10px"
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#0B243C",
        }}
      >
        <Grid item display="grid">
          <span>Filtrer :</span>
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            options={filterStateOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              filter.chatbotState
                ? filterStateOptions.find(
                    (x) => x.value === filter.chatbotState
                  )
                : undefined
            }
            placeholder="État"
            onChange={(e) => {
              setFilter((state) => {
                return {
                  ...state,
                  chatbotState: e?.value ? e.value : undefined,
                };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            options={(() => {
              const optionsTmp = [];
              for (const user of usersList) {
                if (
                  user?.email &&
                  user?.token &&
                  user.email?.length > 0 &&
                  listUsersIdHavingChatbot.includes(user?.user_id)
                ) {
                  optionsTmp.push({
                    label: user?.email,
                    value: user?.user_id,
                  });
                }
              }
              return optionsTmp;
            })()}
            noOptionsMessage={() => "Aucun résultat"}
            value={(() => {
              if (!filter.userId) {
                return undefined;
              }
              const user = usersList.find((x) => x?.user_id === filter.userId);
              if (!user || !user?.email) {
                return undefined;
              }
              return {
                value: filter.userId,
                label: user.email,
              };
            })()}
            placeholder="Auteur"
            onChange={(e) => {
              setFilter((state) => {
                return { ...state, userId: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing="10px"
        paddingTop="10px"
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#0B243C",
        }}
      >
        <Grid item display="grid">
          <span>Trier :</span>
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            isClearable={false}
            options={sortingTypeOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              sorting.type
                ? sortingTypeOptions.find((x) => x.value === sorting.type)
                : undefined
            }
            placeholder=""
            onChange={(e) => {
              setSorting((state) => {
                return { ...state, type: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" xs>
          <MySelect
            isClearable={false}
            options={sortingDirectionOptions}
            noOptionsMessage={() => "Aucun résultat"}
            value={
              sorting.direction
                ? sortingDirectionOptions.find(
                    (x) => x.value === sorting.direction
                  )
                : undefined
            }
            placeholder="Direction"
            onChange={(e) => {
              setSorting((state) => {
                return { ...state, direction: e?.value ? e.value : undefined };
              });
            }}
          />
        </Grid>
      </Grid>
      {!isAdminLoading &&
        Array.isArray(adminChatbotsLex) &&
        adminChatbotsLex.length == 0 && (
          <Grid item display="grid" paddingTop="20px">
            <span
              style={{
                fontFamily: "Poppins",
                color: "#0B243C",
                fontSize: "16px",
              }}
            >
              Aucun chatbot trouvé.
            </span>
          </Grid>
        )}
      {isAdminLoading ? (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <ul className="list-group" style={{ paddingTop: "10px" }}>
          {adminChatbotsLex.slice(0, maxItem).map((adminChatbotLex, index) => {
            return (
              <li
                className={"conseil-card"}
                style={{ padding: "0", animationName: "unset" }}
                key={index}
              >
                <Grid container direction="row" wrap="nowrap">
                  <Grid
                    item
                    display="grid"
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    <Tooltip
                      title={
                        checkNeedUpdate(adminChatbotLex)
                          ? "Mettre à jour"
                          : adminChatbotLex?.lastValidation
                          ? "Déjà validé"
                          : "Valider"
                      }
                    >
                      <IconButton
                        style={{
                          margin: "0",
                          padding: "0",
                          marginLeft: "20px",
                          marginRight: "20px",
                          alignSelf: "center",
                          justifySelf: "center",
                          cursor:
                            adminChatbotLex?.lastValidation &&
                            !checkNeedUpdate(adminChatbotLex)
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={
                          adminChatbotLex?.lastValidation &&
                          !checkNeedUpdate(adminChatbotLex)
                            ? () => {
                                return;
                              }
                            : () => {
                                makeAPIRequest(
                                  "get",
                                  `/chatbots_lex/${adminChatbotLex._id}/valid`,
                                  null,
                                  "v3"
                                )
                                  .then(() => {
                                    makeAPIRequest(
                                      "post",
                                      "/chatbots_lex/admin_list",
                                      { filter: filter, sorting: sorting },
                                      "v3"
                                    )
                                      .then((res) => {
                                        setAdminChatbots(res?.data);
                                        dispatch(
                                          openSnackbar({
                                            message:
                                              "Le chatbot vient d'être validé",
                                            duration: 3000,
                                            type: "success",
                                          })
                                        );
                                      })
                                      .catch(() => {
                                        dispatch(
                                          openSnackbar({
                                            message:
                                              "Impossible de valider ce chatbot",
                                            duration: 3000,
                                            type: "error",
                                          })
                                        );
                                      });
                                  })
                                  .catch(() => {
                                    dispatch(
                                      openSnackbar({
                                        message:
                                          "Impossible de valider ce chatbot",
                                        duration: 3000,
                                        type: "error",
                                      })
                                    );
                                  });
                              }
                        }
                      >
                        {checkNeedUpdate(adminChatbotLex) ? (
                          <UpgradeOutlined
                            fontSize="small"
                            style={{ color: "green" }}
                          />
                        ) : (
                          <VerifiedOutlined
                            fontSize="small"
                            style={{
                              color: adminChatbotLex?.lastValidation
                                ? "red"
                                : "green",
                            }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs
                    display="grid"
                    style={{
                      paddingLeft: "10px",
                      paddingBottom: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <CardHeader adminChatbotLex={adminChatbotLex} />
                  </Grid>
                  <Grid
                    item
                    display="grid"
                    style={{
                      borderLeft: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      spacing="10px"
                      padding="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item display="grid">
                        <Tooltip title="Détails">
                          <IconButton
                            style={{
                              margin: "0",
                              padding: "0",
                            }}
                            onClick={() => {
                              makeAPIRequest(
                                "get",
                                `/chatbots_lex/${adminChatbotLex._id}/admin_details`,
                                null,
                                "v3"
                              )
                                .then((res) => {
                                  setOpenDetails(res?.data);
                                })
                                .catch(() => {
                                  dispatch(
                                    openSnackbar({
                                      message:
                                        "Impossible d'obtenir les détails",
                                      type: "error",
                                      duration: 3000,
                                    })
                                  );
                                });
                            }}
                          >
                            <InfoRounded
                              fontSize="small"
                              style={{ color: "#0B243C" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </li>
            );
          })}
        </ul>
      )}
      <MoreOrLessButtons
        items={adminChatbotsLex}
        maxItem={maxItem}
        setMaxItem={setMaxItem}
      />
    </>
  );
};

export type AccessType = "user" | "admin" | "";

const ValidatedChatbotsLexContent: React.FC<{
  chatbotsLexAccess: AccessType;
}> = (props) => {
  const dispatch = useDispatch();
  const [adminChatbotsLex, setValidatedChatbotsLex] = React.useState<
    IChatbot[]
  >([]);
  const [maxItem, setMaxItem] = React.useState<number>(ITEM_PER_ADD);
  const [openDetails, setOpenDetails] = React.useState<IChatbot>(undefined);
  const [isValidatedChatbotsLexLoading, setIsValidatedChatbotsLexLoading] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    setIsValidatedChatbotsLexLoading(true);
    makeAPIRequest("get", "/chatbots_lex/validated_list", null, "v3")
      .then((res) => {
        setValidatedChatbotsLex(res?.data);
        setIsValidatedChatbotsLexLoading(false);
      })
      .catch(() => {
        setIsValidatedChatbotsLexLoading(false);
      });
  }, []);

  const refreshValidatedChatbotsLexList = (
    afterSuccess: () => void,
    afterFailure: () => void
  ) => {
    makeAPIRequest("get", "/chatbots_lex/validated_list", null, "v3")
      .then((res) => {
        setValidatedChatbotsLex(res?.data);
        afterSuccess();
      })
      .catch(() => {
        afterFailure();
      });
  };

  if (isValidatedChatbotsLexLoading) {
    return (
      <Grid item display="grid" paddingTop="20px">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      </Grid>
    );
  } else if (Array.isArray(adminChatbotsLex) && adminChatbotsLex.length == 0) {
    return (
      <Grid item display="grid" paddingTop="10px">
        <span
          style={{
            fontFamily: "Poppins",
            color: "#0B243C",
            fontSize: "16px",
          }}
        >
          Aucun chatbot à importer.
        </span>
      </Grid>
    );
  }

  const CardHeader: React.FC<{ validatedChatbotLex: IChatbot }> = (props) => {
    const { validatedChatbotLex } = props;

    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          display="grid"
          paddingTop="10px"
          paddingLeft="10px"
          paddingRight="10px"
          style={{
            fontFamily: "Poppins",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {validatedChatbotLex?.title}
          </span>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {openDetails && (
        <ChatbotLexDetails
          chatbotLex={openDetails}
          setOpenDetail={setOpenDetails}
        />
      )}
      <ul className="list-group">
        {adminChatbotsLex
          .slice(0, maxItem)
          .map((validatedChatbotLex, index) => {
            return (
              <li
                className={"conseil-card"}
                style={{ padding: "0", animationName: "unset" }}
                key={index}
              >
                <Grid container direction="row" wrap="nowrap">
                  <Grid
                    item
                    display="grid"
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      spacing="10px"
                      padding="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item display="grid">
                        <Tooltip title="Importer">
                          <IconButton
                            style={{
                              margin: "0",
                              padding: "0",
                            }}
                            onClick={() => {
                              makeAPIRequest(
                                "get",
                                `/chatbots_lex/${validatedChatbotLex._id}/import`,
                                null,
                                "v3"
                              )
                                .then((res: any) => {
                                  dispatch(
                                    openSnackbar({
                                      message: res?.data?.message
                                        ? res?.data?.message
                                        : `Le chatbot a été importé`,
                                      duration: 3000,
                                      type: "success",
                                    })
                                  );
                                })
                                .catch((err: any) => {
                                  dispatch(
                                    openSnackbar({
                                      message: err?.response?.data?.message
                                        ? err.response.data.message
                                        : `Impossible d'importer ce chatbot`,
                                      duration: 3000,
                                      type: "error",
                                    })
                                  );
                                });
                            }}
                          >
                            <Download
                              fontSize="small"
                              style={{ color: "#0B243C" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      {props.chatbotsLexAccess === "admin" && (
                        <Grid item display="grid">
                          <Tooltip title="Supprimer">
                            <IconButton
                              style={{
                                margin: "0",
                                padding: "0",
                              }}
                              onClick={() => {
                                makeAPIRequest(
                                  "get",
                                  `/chatbots_lex/${validatedChatbotLex._id}/remove_validated`,
                                  null,
                                  "v3"
                                )
                                  .then(() => {
                                    refreshValidatedChatbotsLexList(
                                      () => {
                                        dispatch(
                                          openSnackbar({
                                            message:
                                              "Le chatbot a été retiré de la base",
                                            duration: 3000,
                                            type: "success",
                                          })
                                        );
                                      },
                                      () => {
                                        dispatch(
                                          openSnackbar({
                                            message:
                                              "Impossible de retirer ce chatbot de la base",
                                            duration: 3000,
                                            type: "error",
                                          })
                                        );
                                      }
                                    );
                                  })
                                  .catch(() => {
                                    dispatch(
                                      openSnackbar({
                                        message:
                                          "Impossible de retirer ce chatbot de la base",
                                        duration: 3000,
                                        type: "error",
                                      })
                                    );
                                  });
                              }}
                            >
                              <Delete
                                fontSize="small"
                                style={{ color: "#0B243C" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs
                    display="grid"
                    style={{
                      paddingLeft: "10px",
                      paddingBottom: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <CardHeader validatedChatbotLex={validatedChatbotLex} />
                  </Grid>
                  <Grid
                    item
                    display="grid"
                    style={{
                      borderLeft: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      spacing="10px"
                      padding="15px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item display="grid">
                        <Tooltip title="Détails">
                          <IconButton
                            style={{
                              margin: "0",
                              padding: "0",
                            }}
                            onClick={() => {
                              makeAPIRequest(
                                "get",
                                `/chatbots_lex/${validatedChatbotLex._id}/validated_details`,
                                null,
                                "v3"
                              )
                                .then((res) => {
                                  setOpenDetails(res?.data);
                                })
                                .catch(() => {
                                  dispatch(
                                    openSnackbar({
                                      message:
                                        "Impossible d'obtenir les détails",
                                      type: "error",
                                      duration: 3000,
                                    })
                                  );
                                });
                            }}
                          >
                            <InfoRounded
                              fontSize="small"
                              style={{ color: "#0B243C" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </li>
            );
          })}
      </ul>
      <MoreOrLessButtons
        items={adminChatbotsLex}
        maxItem={maxItem}
        setMaxItem={setMaxItem}
      />
    </>
  );
};

export const MoreOrLessButtons: React.FC<{
  items: any[];
  maxItem: number;
  setMaxItem: React.Dispatch<React.SetStateAction<number>>;
  marginTop?: string;
}> = (props) => {
  return (
    <Grid container direction="row" gap="10px" justifyContent="center">
      {props.items.length > props.maxItem && (
        <Grid item display="grid">
          <button
            className="more-button"
            style={{ marginTop: props?.marginTop ? props.marginTop : "25px" }}
            onClick={() => {
              props.setMaxItem((x) => x + ITEM_PER_ADD);
            }}
          >
            Afficher plus ({props.items.length - props.maxItem} restant
            {props.items.length - props.maxItem > 1 ? "s" : ""})
          </button>
        </Grid>
      )}
      {props.maxItem > ITEM_PER_ADD && props.items.length > ITEM_PER_ADD && (
        <Grid item display="grid">
          <button
            className="more-button"
            style={{ marginTop: props?.marginTop ? props.marginTop : "25px" }}
            onClick={() => {
              props.setMaxItem((x) => x - ITEM_PER_ADD);
            }}
          >
            Afficher moins
          </button>
        </Grid>
      )}
    </Grid>
  );
};

const MesChatbotsTab: React.FC<{
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setChatbots: React.Dispatch<React.SetStateAction<IChatbot[]>>;
  chatbots: IChatbot[];
}> = (props) => {
  const history = useHistory();
  const [openSchedule, setOpenSchedule] = React.useState<IChatbot>(undefined);
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = React.useState<IChatbot>(undefined);
  const [openNotifications, setOpenNotifications] =
    React.useState<IChatbot>(undefined);
  const openSnackbarFun = useOpenSnackbar();
  const [maxItem, setMaxItem] = React.useState<number>(ITEM_PER_ADD);
  const [deletePopup, setDeletePopup] = React.useState<IChatbot>(undefined);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      makeAPIRequest("get", "/chatbots_lex/list", null, "v3")
        .then((res) => {
          props.setChatbots(res?.data);
        })
        .catch((err) => {
          openSnackbarFun.error(
            "Impossible de récupérer la liste des chatbots",
            err
          );
        });
    }, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    props.setIsLoading(true);
    makeAPIRequest("get", "/chatbots_lex/list", null, "v3")
      .then((res) => {
        props.setChatbots(res?.data);
        props.setIsLoading(false);
      })
      .catch((err) => {
        openSnackbarFun.error(
          "Impossible de récupérer la liste des chatbots",
          err
        );
        props.setIsLoading(false);
      });
  }, []);

  if (props.isLoading) {
    return (
      <Grid item display="grid" paddingTop="20px">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      </Grid>
    );
  } else if (Array.isArray(props.chatbots) && props.chatbots.length == 0) {
    return (
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            color: "#0B243C",
            fontSize: "16px",
          }}
        >
          Aucun chatbot trouvé.
        </span>
      </Grid>
    );
  }

  const CardHeader: React.FC<{ chatbot: IChatbot }> = (props) => {
    const { chatbot } = props;

    return (
      <Grid
        container
        direction="column"
        spacing="10px"
        wrap="nowrap"
        paddingTop="20px"
        paddingBottom="20px"
        paddingLeft="10px"
        paddingRight="10px"
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#0B243C",
        }}
      >
        <Grid item xs display="grid">
          <span
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {chatbot?.title}
          </span>
        </Grid>
        <Grid item display="grid" alignSelf="flex-end">
          <span>{getCreationStepText(chatbot?.creationStep)}</span>
        </Grid>
      </Grid>
    );
  };

  const newNotifications = openNotifications?.intentSuggestions
    ? openNotifications.intentSuggestions.filter((x) => x.view === false)
    : [];
  const othersNotifications = openNotifications?.intentSuggestions
    ? openNotifications.intentSuggestions.filter((x) => x.view === true)
    : [];

  return (
    <>
      {deletePopup && (
        <CancellablePopup
          firstTitle="Mes"
          isLoading={isDeleteLoading}
          secondTitle="Chatbots"
          messages={[
            `Voulez-vous vraiment supprimer le chatbot '${deletePopup?.title}' ?`,
            "Toutes les programmations seront perdues",
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => {
            setIsDeleteLoading(false);
            setDeletePopup(undefined);
          }}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest(
              "delete",
              `/chatbotlex/${deletePopup._id}/delete`,
              null,
              "v3"
            )
              .then((res: any) => {
                makeAPIRequest("get", "/chatbots_lex/list", null, "v3")
                  .then((resList) => {
                    setDeletePopup(undefined);
                    setIsDeleteLoading(false);
                    props.setChatbots(resList?.data);
                    dispatch(
                      openSnackbar({
                        message: `Le chatbot a bien été supprimé`,
                        duration: 3000,
                        type: "success",
                      })
                    );
                  })
                  .catch(() => {
                    setDeletePopup(undefined);
                    setIsDeleteLoading(false);
                    dispatch(
                      openSnackbar({
                        message: `Impossible de récupérer la liste des chatbots`,
                        duration: 3000,
                        type: "error",
                      })
                    );
                  });
              })
              .catch((err: any) => {
                setIsDeleteLoading(false);
                dispatch(
                  openSnackbar({
                    message: err?.response?.data?.message
                      ? err.response.data.message
                      : `Impossible de supprimer ce chatbot`,
                    duration: 3000,
                    type: "error",
                  })
                );
              });
          }}
        />
      )}
      {openSchedule && (
        <ChatbotLexSchedulePopup
          setOpenSchedule={setOpenSchedule}
          chatbotLex={openSchedule}
        />
      )}
      {openDetails && (
        <ChatbotLexDetails
          chatbotLex={openDetails}
          setOpenDetail={setOpenDetails}
        />
      )}
      {openNotifications && (
        <GenericFromPopup
          title={{
            leftPart: `Intentions`,
            rightPart: `à ajouter`,
          }}
          subtitle={openNotifications.title}
          onClose={() => setOpenNotifications(undefined)}
        >
          <Grid
            container
            direction="column"
            gap="25px"
            wrap="nowrap"
            spacing="10px"
            style={{
              fontSize: "16px",
              fontFamily: "Poppins",
              color: "#0B243C",
            }}
          >
            {newNotifications.length > 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Nouvelles suggestions
                    </span>
                  </Grid>
                  {newNotifications.map((intentSuggestion, index) => {
                    return (
                      <Grid item display="grid" key={index}>
                        <span>• {intentSuggestion.message}</span>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {othersNotifications.length > 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Anciennes suggestions
                    </span>
                  </Grid>
                  {othersNotifications.map((intentSuggestion, index) => {
                    return (
                      <Grid item display="grid" key={index}>
                        <span>• {intentSuggestion.message}</span>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </GenericFromPopup>
      )}
      <ul className="list-group">
        {props.chatbots.slice(0, maxItem).map((chatbot, index) => {
          return (
            <li
              className={"conseil-card"}
              style={{ padding: "0", animationName: "unset" }}
              key={index}
            >
              <Grid container direction="row" wrap="nowrap">
                <Grid
                  item
                  display="grid"
                  style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.125)",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    spacing="20px"
                    padding="15px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item display="grid">
                      <Tooltip
                        title={
                          chatbot.creationStep === "Built"
                            ? "Programmer un envoi"
                            : "Chatbot en cours de construction"
                        }
                      >
                        <IconButton
                          style={{
                            margin: "0",
                            padding: "0",
                            cursor:
                              chatbot.creationStep !== "Built"
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={
                            chatbot.creationStep !== "Built"
                              ? () => {
                                  return;
                                }
                              : () => {
                                  setOpenSchedule(chatbot);
                                }
                          }
                        >
                          <ScheduleSend
                            fontSize="small"
                            style={{
                              color:
                                chatbot.creationStep === "Built"
                                  ? "#0B243C"
                                  : "#FF3100",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item display="grid">
                      <Tooltip
                        title={
                          chatbot.creationStep === "Built"
                            ? "Modifier"
                            : "Chatbot en cours de construction"
                        }
                      >
                        <IconButton
                          style={{
                            margin: "0",
                            padding: "0",
                            cursor:
                              chatbot.creationStep !== "Built"
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={
                            chatbot.creationStep !== "Built"
                              ? () => {
                                  return;
                                }
                              : () => {
                                  history.push(`/edit_chatbot/${chatbot._id}`);
                                }
                          }
                        >
                          <Edit
                            fontSize="small"
                            style={{
                              color:
                                chatbot.creationStep === "Built"
                                  ? "#0B243C"
                                  : "#FF3100",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item display="grid">
                      <Tooltip title="Supprimer">
                        <IconButton
                          style={{
                            margin: "0",
                            padding: "0",
                          }}
                          onClick={() => {
                            setDeletePopup(chatbot);
                          }}
                        >
                          <Delete
                            fontSize="small"
                            style={{ color: "#0B243C" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs
                  display="grid"
                  style={{
                    paddingLeft: "10px",
                    paddingBottom: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <CardHeader chatbot={chatbot} />
                </Grid>
                <Grid
                  item
                  display="grid"
                  style={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.125)",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    spacing="10px"
                    padding="15px"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    {chatbot.intentSuggestions.length > 0 && (
                      <Grid item display="grid">
                        <Tooltip title="Notifications">
                          <IconButton
                            style={{
                              margin: "0",
                              padding: "0",
                            }}
                            onClick={() => {
                              makeAPIRequest(
                                "get",
                                `/chatbots_lex/${chatbot._id}/notifications`,
                                null,
                                "v3"
                              )
                                .then((res) => {
                                  setOpenNotifications(res?.data);
                                })
                                .catch(() => {
                                  dispatch(
                                    openSnackbar({
                                      message:
                                        "Impossible d'obtenir les notifications",
                                      type: "error",
                                      duration: 3000,
                                    })
                                  );
                                });
                            }}
                          >
                            {chatbot.intentSuggestions.filter(
                              (x) => x.view === false
                            ).length > 0 ? (
                              <>
                                <div
                                  style={{
                                    width: "6px",
                                    height: "6px",
                                    borderRadius: "15px",
                                    backgroundColor: "#ffa500",
                                    position: "absolute",
                                    right: 2,
                                    top: 1,
                                  }}
                                />
                                <Notifications
                                  fontSize="small"
                                  style={{ color: "#0B243C" }}
                                />
                              </>
                            ) : (
                              <NotificationsNone
                                fontSize="small"
                                style={{ color: "#0B243C" }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item display="grid">
                      <Tooltip title="Détails">
                        <IconButton
                          style={{
                            margin: "0",
                            padding: "0",
                          }}
                          onClick={() => {
                            makeAPIRequest(
                              "get",
                              `/chatbots_lex/${chatbot._id}/details`,
                              null,
                              "v3"
                            )
                              .then((res) => {
                                setOpenDetails(res?.data);
                              })
                              .catch(() => {
                                dispatch(
                                  openSnackbar({
                                    message: "Impossible d'obtenir les détails",
                                    type: "error",
                                    duration: 3000,
                                  })
                                );
                              });
                          }}
                        >
                          <InfoRounded
                            fontSize="small"
                            style={{ color: "#0B243C" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </li>
          );
        })}
      </ul>
      <MoreOrLessButtons
        items={props.chatbots}
        maxItem={maxItem}
        setMaxItem={setMaxItem}
      />
    </>
  );
};

export const ChatbotLexBox: React.FC<any> = (props) => {
  const { chatbotsLexAccess } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [chatbots, setChatbots] = React.useState<IChatbot[]>([]);
  const [chatbotLexTab, setChatbotLexTab] = React.useState<
    "Mes Chatbots" | "À importer" | "Mes Envois" | "Admin"
  >("Mes Chatbots");
  const history = useHistory();
  const tab = useQuery().get("tab");
  React.useEffect(() => {
    if (tab === "mesenvois") {
      setChatbotLexTab("Mes Envois");
    }
    if (tab === "aimporter") {
      setChatbotLexTab("À importer");
    }
    if (tab === "admin") {
      setChatbotLexTab("Admin");
    }
    if (tab === "meschatbots") {
      setChatbotLexTab("Mes Chatbots");
    }
  }, [tab]);

  return (
    <>
      <Grid container display="flex" direction="column" wrap="nowrap">
        <Grid item display="grid" paddingTop="30px">
          <div className="row">
            <div className="col-md-12">
              <Tabs activeKey={chatbotLexTab} className="mb-3 background-tabs">
                <Tab
                  eventKey="Mes Chatbots"
                  title={
                    <MyTabTitle
                      onClick={() => {
                        history.push("/home?f=chatbots&tab=meschatbots");
                        setChatbotLexTab("Mes Chatbots");
                      }}
                      text={"Mes Chatbots"}
                    />
                  }
                />
                <Tab
                  eventKey="Mes Envois"
                  title={
                    <MyTabTitle
                      onClick={() => {
                        history.push("/home?f=chatbots&tab=mesenvois");
                        setChatbotLexTab("Mes Envois");
                      }}
                      text={"Mes Envois"}
                    />
                  }
                />
                <Tab
                  style={{ padding: "0 0 0 0" }}
                  eventKey="À importer"
                  title={
                    <MyTabTitle
                      onClick={() => {
                        history.push("/home?f=chatbots&tab=aimporter");
                        setChatbotLexTab("À importer");
                      }}
                      text={"À importer"}
                    />
                  }
                ></Tab>
                {chatbotsLexAccess === "admin" && (
                  <Tab
                    style={{ padding: "0 0 0 0" }}
                    eventKey="Admin"
                    title={
                      <MyTabTitle
                        onClick={() => {
                          history.push("/home?f=chatbots&tab=admin");
                          setChatbotLexTab("Admin");
                        }}
                        text={"Admin"}
                      />
                    }
                  ></Tab>
                )}
              </Tabs>
              {chatbotLexTab === "À importer" && (
                <ValidatedChatbotsLexContent
                  chatbotsLexAccess={chatbotsLexAccess}
                />
              )}
              {chatbotLexTab === "Mes Envois" && <ConversationsTab />}
              {chatbotLexTab === "Admin" && <AdminChatboxLexContent />}
              {chatbotLexTab === "Mes Chatbots" && (
                <Grid item display="grid" paddingTop="10px">
                  <MesChatbotsTab
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setChatbots={setChatbots}
                    chatbots={chatbots}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const ChatbotLexPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );
  const chatbotsLexAccess = useAppSelector(
    (state) => state.authorizationsReducer.chatbots_lex
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(updateSize("#mes-chatbots-lex-box"));
  }, [dispatch]);

  return (
    <>
      <FullPage>
        <Grid
          container
          display="flex"
          direction="row"
          justifyContent="center"
          spacing="20px"
          paddingLeft="24px"
          paddingRight="24px"
        >
          <Grid item display="grid" alignItems="flex-start">
            <Grid container direction="column" wrap="nowrap">
              <Grid item display="grid">
                <MesReglagesBox page="chatbots" />
              </Grid>
              {props?.user?.account_type == "4" && (
                <Grid item display="grid" paddingTop="30px">
                  <AdministrationBox page="chatbots" />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs
            display="grid"
            alignItems="flex-start"
            marginLeft="24px"
            width={windowWidth > 1300 ? "1000px" : null}
          >
            <ChatbotLexBox chatbotsLexAccess={chatbotsLexAccess} />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default ChatbotLexPage;
