import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";
import useQuery from "../utils/hooks/useQuery";

interface ConseilBase {
  id: number;
  validated: 0 | 1;
  user_id: number;
  title: string;
  speciality_id: number;
  conseil_profession_id: number;
  type: "pack" | "simple";
  conseil_favourite_ids: string;
  conseil_use: string;
  conseil_popularity: string;
  reserved: 0 | 1;
  display_name: 0 | 1;
  views: string;
  creation_date: Date;
  is_archived: 0 | 1;
}

interface MessageConseil {
  id: number;
  content: string;
  attachment: string;
  attachment_name: string;
  url: string;
  hours: number;
  questionnaire_interactif_title?: string;
  questionnaire_interactif_id?: string;
  short_url?: 0 | 1;
  stop_sms?: 0 | 1;
  questionnaire_interactif_nano_id?: string;
  is_questionnaire_interactif_owner?: boolean;
}

export interface ConseilReponseAfterParsing extends ConseilBase {
  user_name: string;
  messages: MessageConseil[][];
  fullText?: string;
  score?: number;
}

export const ValidationBox = (props: { noPadding?: boolean }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const email = useQuery().get("email");
  const key = useQuery().get("key");
  const user_id = useQuery().get("user_id");
  const type = useQuery().get("type");

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("post", "/user/validate_account", {
      type: type,
      key: key,
      email: email,
      user_id: user_id,
    }, 'v3')
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false))
      .finally(() => setIsLoading(false));
  }, [key, user_id, type, email]);

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      paddingLeft={props.noPadding ? 0 : "5%"}
      paddingTop={props.noPadding ? 0 : "5%"}
      paddingRight={props.noPadding ? 0 : "5%"}
      paddingBottom={props.noPadding ? 0 : "5%"}
    >
      {isLoading ? (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid item display="grid">
          <Grid
            container
            direction="column"
            wrap="nowrap"
            justifyContent={"center"}
            gap="40px"
          >
            {!success && (
              <span
                style={{
                  textAlign: "center",
                  fontSize: "min(max(13px, 1.055vw), 16px)",
                  fontFamily: "Poppins",
                  color: "#0B243C",
                }}
              >
                {`Une erreur est survenue, le compte n'as pas pu être validé.`}
              </span>
            )}
            {email && key && user_id && type && success && (
              <Grid item display="grid">
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "min(max(13px, 1.055vw), 16px)",
                    fontFamily: "Poppins",
                    color: "#0B243C",
                  }}
                >
                  {`Le compte '${email}' a bien été validé, un e-mail a été envoyé à l'utilisateur.`}
                </span>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ValidationPage: React.FC = () => {
  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="700px"
        >
          <Grid item display="grid">
            <ValidationBox />
          </Grid>
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default ValidationPage;
