import * as React from 'react';
import GoogleAnalyticsClass from '../GoogleAnalyticsClass';
import UserClass from '../UserClass';

const useGoogleAnalytics = () => {
  const [user, setUser] = React.useState(null);
  const [googleAnalytics, setGoogleAnalytics] = React.useState(null);

  React.useEffect(() => {
    async function initUserAndGoogleAnalytics() {
      const newUser = new UserClass();
      await newUser.init();
      setUser(newUser);
      setGoogleAnalytics(new GoogleAnalyticsClass(newUser));
    }
    initUserAndGoogleAnalytics();
  }, []);

  return ([googleAnalytics, user, setGoogleAnalytics, setUser]);
};

export default useGoogleAnalytics;
