import { Grid } from '@mui/material';
import * as React from 'react';
import AdministrationBox from '../components/MesReglagesBox/AdministrationBox';
import MesReglagesBox from '../components/MesReglagesBox/MesReglagesBox';
import FullPage from './FullPage';
import UsersListBox from '../components/AdminUsers/UsersListBox';
import makeAPIRequest from '../utils/makeAPIRequest';
import useAppSelector from '../redux/useAppSelector';

const AdminUsersPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(state => state.windowReducer.windowWidth);
  const [accountTypeSearched, setAccountTypeSearched] = React.useState(null);
  const [patientNameSearched, setPatientNameSearched] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [usersFiltered, setUsersFiltered] = React.useState([]);
  const [usersPerPage, setUsersPerPage] = React.useState(10);
  const [actualPage, setActualPage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  const [isUsersLoaded, setIsUsersLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!isLoading) setIsLoading(true);
    makeAPIRequest('get', '/users/list', null, 'v2').then((res) => {
      if (res?.data?.data?.length > 0) setUsers(res?.data?.data);
      setIsUsersLoaded(true);
    }).catch((err) => console.error(err));
  }, []);

  // Update users filtered after users change
  React.useEffect(() => {
    let usersFilteredTmp = [...users];
    // let tmp = [...usersFilteredTmp];

    usersFilteredTmp = usersFilteredTmp?.filter((u) => accountTypeSearched?.value != undefined ? u.account_type == accountTypeSearched?.value : true);
    if (patientNameSearched?.value !== "") {
      usersFilteredTmp = usersFilteredTmp?.filter((u) => patientNameSearched?.value != undefined ? u.id == patientNameSearched?.value : true);
    }
    setUsersFiltered(usersFilteredTmp);
  }, [users, accountTypeSearched, patientNameSearched])

  // Update actual page after pages change
  React.useEffect(() => {
    if (actualPage === 0) return;
    if (actualPage > pages.length - 1)
      setActualPage(pages.length - 1);
    if (actualPage < 0) setActualPage(0);
  }, [actualPage, pages]);

  // Update pages after patient list filtered change
  React.useEffect(() => {
    const newPages = [];
    let index = 0;
    for (const user of usersFiltered) {
      if (index % usersPerPage === 0) {
        newPages.push([]);
      }
      const lastIndex = newPages.length - 1;
      if (newPages[lastIndex]) {
        newPages[lastIndex].push(user);
      }
      index++;
    }
    setPages(newPages);
  }, [usersFiltered, usersPerPage]);

  React.useEffect(() => {
    if (isUsersLoaded) setIsLoading(false);
  }, [pages]);

  return (
    <FullPage>
      <Grid container display='flex' direction='row' justifyContent='center' spacing='20px' paddingLeft='24px' paddingRight='24px'>
        <Grid item display='grid' alignItems='flex-start'>
          <Grid container direction='column' wrap='nowrap'>
            <Grid item display='grid'>
              <MesReglagesBox
                page='admin_users' />
            </Grid>
            {props?.user?.account_type == '4' &&
              <Grid item display='grid' paddingTop='30px'>
                <AdministrationBox
                  page='admin_users' />
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs display='grid' alignItems='flex-start' marginLeft='24px' width={windowWidth > 1300 ? '1000px' : null}>
          <UsersListBox
            pages={pages}
            accountTypeSearched={accountTypeSearched}
            setAccountTypeSearched={setAccountTypeSearched}
            users={users}
            usersFiltered={usersFiltered}
            setUsers={setUsers}
            setPages={setPages}
            usersPerPage={usersPerPage}
            setUsersPerPage={setUsersPerPage}
            actualPage={actualPage}
            setActualPage={setActualPage}
            isLoading={isLoading}
            patientNameSearched={patientNameSearched}
            setPatientNameSearched={setPatientNameSearched}
            {...props} />
        </Grid>
      </Grid>
    </FullPage >
  );
};

export default AdminUsersPage;