import FullField from "../../../Fields/FullField";
import TextInput from "../../../Library/Inputs/TextInput";
import * as React from "react";
import HandleErrors from "../../../Library/Errors/HandleErrors";
import { Grid } from "@mui/material";

const DisplayText: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const {
    field,
    justifyContent,
    sectionIndex,
    disableError,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  return (
    <FullField
      isQuestionnaire={true}
      title={field?.title}
      isMandatory={field?.isMandatory}
      hideAsterics={field?.hideAsterics ? true : false}
      justifyContent={justifyContent}
    >
      <HandleErrors
        isError={isError && !disableError}
        isMandatory={field?.isMandatory}
        errorMessage={errorMessage}
      >
        <Grid container direction="column" wrap="nowrap">
          <Grid item display="grid">
            <TextInput
              id={"field" + field?.id}
              isError={isError && !disableError}
              onChange={(e) => {
                const questionnaireDataTmp = { ...questionnaireData };

                questionnaireDataTmp.sections[sectionIndex].fields[
                  fieldRowIndex
                ][fieldIndex].value = e.target.value;
                setQuestionnaireData(questionnaireDataTmp);
              }}
              onFocus={(e) => {
                if (!field?.isMandatory) return;
                if (isError && !disableError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
              onBlur={(e) => {
                if (!field?.isMandatory) return;
                if (!e.target.value) {
                  if (!isError && !disableError) {
                    setErrorMessage("Ce champ est obligatoire.");
                    setIsError(true);
                  }
                } else {
                  if (isError && !disableError) {
                    setErrorMessage("");
                    setIsError(false);
                  }
                }
              }}
              value={field?.value ? field?.value : ""}
            />
          </Grid>
        </Grid>
      </HandleErrors>
    </FullField>
  );
};

export default DisplayText;
