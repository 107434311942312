import * as React from 'react';
import { Grid } from '@mui/material';
import '../FilterStyle.scss';
import IFilterProps from '../IFilterProps';
import AdviceSelectItem from './AdviceSelectItem';
import AttachmentSelectItem from './AttachmentSelectItem';
import SpecialitiesSelectItem from './SpecialitiesSelectItem';

const SelectItemsRow = (props: IFilterProps) => {
  return (
    <Grid
      container
      direction='row'
      spacing={'5px'}
      justifyContent='center'
      alignItems='center'>
      <Grid
        item xs
        display='grid'>
        <SpecialitiesSelectItem {...props} />
      </Grid>
      <Grid
        item xs
        display='grid'>
        <AdviceSelectItem {...props} />
      </Grid>
      <Grid
        item xs
        display='grid'>
        <AttachmentSelectItem {...props} />
      </Grid>
    </Grid >
  );
};

export default SelectItemsRow;
