import FullPage from "./FullPage"
import * as React from 'react';
import { CircularProgress, Grid } from "@mui/material";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { ChevronLeftOutlined } from "@mui/icons-material";
import BoxTitle from "../components/Library/Box/BoxTitle";
import makeAPIRequest from "../utils/makeAPIRequest";
import DisplayChatbotConversations from "../components/ChatbotResponses/DisplayChatbotConversations";

const UnknownChatbot = () => {
  return (
    <span style={{
      fontFamily: 'Poppins',
      fontSize: '16px',
      color: '#0B243C'
    }}>
      {`Ce questionnaire interactif n'existe pas.`}
    </span>
  );
}

const ChatbotReponsesBox: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [patientList, setPatientList] = React.useState([]);
  const [conversations, setConversations] = React.useState(null);
  const [chatbot, setChatbot] = React.useState(null);
  const {
    chatbotId,
  } = props;

  React.useEffect(() => {
    const interval = setInterval(() => {
      makeAPIRequest('get', `/chatbots/scheduled/${chatbotId}/list`, null, 'v3')
        .then((res) => {
          setConversations(res?.data);
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (!isLoading) setIsLoading(true);
    makeAPIRequest('get', `/chatbot/${chatbotId}`, null, 'v3')
      .then((res) => {
        setChatbot(res?.data);
        makeAPIRequest('get', `/chatbots/scheduled/${chatbotId}/list`, null, 'v3')
        .then((res) => {
          setConversations(res?.data);
          makeAPIRequest('get', `/patients/list`, null, 'v3').then((res: any) => {
            setPatientList(res?.data);
            setIsLoading(false);
          });
        })
        .catch((err) => {
          setIsLoading(false);
        });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Grid
      container
      direction='column'
      wrap="nowrap"
      display='flex'
      paddingLeft='41px'
      paddingTop='26px'
      paddingRight='41px'
      minWidth='320px'
      paddingBottom={conversations ? '60px' : '26px'}
      style={{
        background: 'rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box',
        borderRadius: '24px',
      }}>
      <Grid item display='grid'>
        <BoxTitle
          first='Réponses'
          second={`au questionnaire interactif`} />
      </Grid>
      <Grid item display='grid' paddingTop='30px'>
        {isLoading &&
          <Grid container direction='row' display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress />
          </Grid>
        }
        {!isLoading && !conversations &&
          <UnknownChatbot />
        }
        {!isLoading && conversations &&
          <DisplayChatbotConversations
            conversations={conversations}
            chatbot={chatbot}
            patientList={patientList}
            setConversations={setConversations} />
        }
      </Grid>
    </Grid>
  );
};

interface IParams {
  id: string
}
interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

const ChatbotReponsePage: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { id } = useParams<IParams>();

  return (
    <>
      <FullPage>
        <Grid container direction='column' wrap="nowrap"
          width='80vw'>
          <Grid item display='grid' paddingBottom='10px'>
            <button
              className={`return-button`}
              style={{ width: '100px', height: '30px', fontSize: '16px' }}
              onClick={() => history.push('/home?f=questionnaires_interactifs')} >
              <span style={{ display: 'flex' }}>
                <Grid container direction='row' display='flex' justifyContent='center' alignItems='center'>
                  <Grid item display='grid' marginTop='1px'>
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display='grid'>
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display='grid'>
            <ChatbotReponsesBox
              chatbotId={id} />
          </Grid>
        </Grid>
      </FullPage>
    </>
  )
}

export default ChatbotReponsePage;