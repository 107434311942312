import { createSlice } from '@reduxjs/toolkit';

export const scheduleQuestionnaire = createSlice({
  name: 'scheduleQuestionnaire',
  initialState: {
    title: '',
    questionnaireId: '',
    isOpen: false,
  },
  reducers: {
    openSchedule: (state, action) => {
      const { questionnaireId, title } = action.payload;

      state.isOpen = true;
      state.questionnaireId = questionnaireId;
      state.title = title;
    },
    closeSchedule: (state) => {
      state.isOpen = false;
      state.questionnaireId = '';
      state.title = '';
    },
  }
})

export const { openSchedule, closeSchedule } = scheduleQuestionnaire.actions

export default scheduleQuestionnaire.reducer;
