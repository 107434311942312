import { Grid, IconButton } from "@mui/material";
import * as React from "react";
import { ReactComponent as IconAdd } from "../../../assets/IconAdd.svg";
import { ReactComponent as IconEdit } from "../../../assets/IconEdit.svg";
import {
  DeleteOutline,
  KeyboardArrowDown,
  KeyboardArrowDownOutlined,
  KeyboardArrowUp,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { getActualTimeNumber } from "../../NavbarComponent";
import CancellablePopup from "../../Library/Popups/CancellablePopup";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import EventEmitter from "../../../services/event";
import { useOpenSnackbar } from "../../../pages/CreateOrEditChatbotLexPage";
import { ButtonIconWithTooltip } from "../../Questionnaire/PDF/CreateOrEditPDFQuestionnaire";

const NotSelectedCardBody: React.FC<any> = (props) => {
  const [isDeletePopupOpen, setIsDeletePopupOpen] =
    React.useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);
  const snackbar = useOpenSnackbar();

  const isLessThanOneDay = (dateString) => {
    const date = new Date(dateString);
    const heureActuelle = getActualTimeNumber();
    const difference = new Date(heureActuelle).getTime() - date.getTime();
    return difference < 86400000;
  };

  return (
    <>
      {isDeletePopupOpen && (
        <CancellablePopup
          firstTitle="Mes"
          isLoading={isDeleteLoading}
          secondTitle="Conseils"
          messages={[
            `Voulez-vous vraiment supprimer le conseil${
              props?.tutorial?.title ? ` '${props?.tutorial?.title}'` : ""
            } ?`,
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => {
            setIsDeletePopupOpen(false);
            setIsDeleteLoading(false);
          }}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest("get", `conseil/${props?.tutorial?.id}/user_delete`)
              .then((res) => {
                snackbar.success("Le conseil a bien été supprimé.", res);
                setIsDeleteLoading(false);
                setIsDeletePopupOpen(false);
                EventEmitter.emit("RefreshAll", { filterKey: undefined });
              })
              .catch((err) => {
                snackbar.error("Impossible de supprimer ce conseil.", err);
                setIsDeleteLoading(false);
              });
          }}
        />
      )}
      <Grid container direction="column" display="flex" marginTop="10px" wrap="nowrap">
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            display="flex"
            paddingLeft="20px"
            paddingRight="20px"
          >
            <Grid item display="grid">
              <Grid container direction="row" display="flex" spacing="20px">
                <Grid item display="grid" alignItems={"center"}>
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    gap="10px"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {props.onMoveUp && (
                      <Grid item display="grid">
                        <ButtonIconWithTooltip
                          onClick={props.onMoveUp}
                          tooltipText=""
                          Icon={
                            <KeyboardArrowUpOutlined
                              style={{
                                width: "24px",
                                height: "24px",
                                color: props.isFirstOnList
                                  ? "#D3E0E0"
                                  : "#657273",
                                cursor: props.isFirstOnList
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            />
                          }
                        />
                      </Grid>
                    )}
                    <Grid item display="grid">
                      <IconAdd
                        style={{ cursor: "pointer", margin: "0" }}
                        onClick={() =>
                          props?.handleAdd(props?.tutorial, props?.index)
                        }
                      />
                    </Grid>
                    {props.onMoveDown && (
                      <Grid item display="grid">
                        <ButtonIconWithTooltip
                          onClick={props.onMoveDown}
                          tooltipText=""
                          Icon={
                            <KeyboardArrowDownOutlined
                              style={{
                                width: "24px",
                                height: "24px",
                                color: props.isLastOnList
                                  ? "#D3E0E0"
                                  : "#657273",
                                cursor: props.isLastOnList
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            />
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item display="grid" alignItems={"center"}>
                  <div
                    className="vl-l"
                    style={{
                      height: "80%",
                      marginRight:
                        props?.tutorial?.type === "pack" &&
                        !props?.idOpen[props?.tutorial.id]
                          ? "10px"
                          : "0px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              display="grid"
              xs
              style={{
                flexWrap: "nowrap",
                overflowY: props?.tutorial?.type === "pack" ? "hidden" : "auto",
              }}
              height={
                props?.tutorial?.type === "pack" &&
                props?.idOpen[props?.tutorial.id]
                  ? "100%"
                  : "108px"
              }
            >
              {props.DisplayConseil}
            </Grid>
            <Grid item display="grid">
              <Grid container direction="row" display="flex" spacing="20px">
                <Grid item display="grid" alignItems="center">
                  <div className="vl-r" style={{ height: "80%" }} />
                </Grid>
                <Grid item display="grid">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    display="flex"
                    spacing="10px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item display="grid" justifyContent="center">
                      {props.DisplayFavorite}
                    </Grid>
                    <Grid item display="grid" justifyContent="center">
                      <button className="icon_button modal-toggle">
                        <IconEdit
                          onClick={() => props?.handleEdit(props?.tutorial)}
                        />
                      </button>
                    </Grid>
                    {props?.tutorial?.creation_date &&
                      props?.tutorial?.validated == 0 &&
                      isLessThanOneDay(props.tutorial.creation_date) && (
                        <Grid item display="grid" justifyContent="center">
                          <IconButton
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                            onClick={() => {
                              setIsDeletePopupOpen(true);
                            }}
                          >
                            <DeleteOutline
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          </IconButton>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NotSelectedCardBody;
