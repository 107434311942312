import { fixDateInverse } from "./DisplayFormatedResponse";

const formatValue = (value: any) => {
  let toDisplay = '';

  if (Array.isArray(value)) {
    for (let i = 0; i < value?.length; i++) {
      if (i === value.length - 1 || value.length === 0) {
        toDisplay += `${value[i]}`;
      } else if (value?.length > 1) {
        toDisplay += `${value[i]}, `;
      }
    }
  }
  if (!value || value?.length === 0) return ('Vide');
  return (fixDateInverse(toDisplay));
}

export default formatValue;