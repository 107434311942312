import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import makeAPIRequest from '../../utils/makeAPIRequest'

export interface NotificationParameters {
  end_pack_sms: boolean;
  incident_sms_send: boolean;
  new_form_response: boolean;
  chatbot_conversation_cancelled: boolean;
  questionnaire_interactif_ended: boolean;
  code_qr: boolean;
  chatbot_conversation_ended: boolean;
}

export type NotificationParameter = keyof NotificationParameters;

export interface NotificationItem {
  date?: number;
  content: string;
  onClickRedirect: string;
  type: NotificationParameter,
}

export interface Notifications {
  _id?: string;
  user_id?: string;
  lastOpen?: number;
  list?: Array<NotificationItem>;
  parameters?: NotificationParameters;
}

export interface NotificationsParametersLanguage {
  isNotificationsParametersLanguage: boolean;
  notificationsParametersLanguage: Array<{
    label: string;
    value: NotificationParameter;
  }>
}

interface ReplyGetNotifications {
  notifications: Notifications,
  languages: NotificationsParametersLanguage,
}

const openNotifications = createAsyncThunk(
  '/OPEN_NOTIFICATIONS',
  async (payload, thunkAPI): Promise<number> => {
    try {
      const response = await makeAPIRequest(
        'get',
        `/notifications/open`,
        null,
        'v3',
      )
      const data: { lastOpen: number } = response?.data;
      return thunkAPI.fulfillWithValue(data?.lastOpen) as any
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

const updateParameters = createAsyncThunk<
  { parameters: NotificationParameters },
  Partial<NotificationParameters>,
  {
    rejectValue: any
  }
>(
  '/UPDATE_PARAMETERS',
  async (payload, thunkAPI): Promise<{ parameters: NotificationParameters }> => {
    try {
      const response = await makeAPIRequest(
        'post',
        `/notifications/parameters`,
        payload,
        'v3',
      )
      const data: Notifications = response?.data;
      return thunkAPI.fulfillWithValue({
        parameters: data?.parameters,
      }) as any
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

const getLanguagesAndParameters = createAsyncThunk(
  '/GET_LANGUAGES_AND_PARAMETERS',
  async (payload, thunkAPI): Promise<{ languages: NotificationsParametersLanguage, parameters: NotificationParameters }> => {
    try {
      const response = await makeAPIRequest(
        'get',
        `/notifications/list`,
        null,
        'v3',
      )
      const data: ReplyGetNotifications = response?.data;
      return thunkAPI.fulfillWithValue({
        languages: data?.languages,
        parameters: data?.notifications.parameters,
      }) as any
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

const getNotifications = createAsyncThunk(
  '/GET_NOTIFICATIONS',
  async (payload, thunkAPI): Promise<Notifications> => {
    try {
      const response = await makeAPIRequest(
        'get',
        `/notifications/list`,
        null,
        'v3',
      )
      const data: ReplyGetNotifications = response?.data;
      return thunkAPI.fulfillWithValue(data?.notifications) as any
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

export interface NotificationReducerState {
  notifications: Notifications,
  languages: NotificationsParametersLanguage,
}

const initialState: NotificationReducerState = {
  languages: undefined,
  notifications: undefined,
}

export const notificationsSlice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {},
  extraReducers(builder: ActionReducerMapBuilder<NotificationReducerState>) {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      if (!state.notifications) { state.notifications = {} }
      state.notifications.lastOpen = action.payload.lastOpen;
      state.notifications.list = action.payload.list;
    })
    builder.addCase(getLanguagesAndParameters.fulfilled, (state, action) => {
      if (!state.notifications) { state.notifications = {} }
      state.languages = action.payload.languages;
      state.notifications.parameters = action.payload.parameters;
    })
    builder.addCase(openNotifications.fulfilled, (state, action) => {
      if (!state.notifications) { state.notifications = {} }
      state.notifications.lastOpen = action.payload;
    })
    builder.addCase(updateParameters.fulfilled, (state, action) => {
      if (!state.notifications) { state.notifications = {} }
      console.log(action.payload);
      state.notifications.parameters = action.payload.parameters;
    })
  },
})
export const notificationsAction = notificationsSlice.actions;
export default notificationsSlice.reducer
export { getNotifications, getLanguagesAndParameters, openNotifications, updateParameters }
