const isValidPhoneNumber = (phoneNumber?: string, authorized = ['6', '7']) => {
  for (let i = 0; i < phoneNumber.length; i++) {
    if (i === 0 && phoneNumber[i] === '+') { continue; }
    if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(phoneNumber[i])) {
      return (false);
    }
  }
  // +262
  if (phoneNumber?.length === 13 && (phoneNumber.substring(0, 5) === "+2626"
  || phoneNumber.substring(0, 5) === "+2626" || phoneNumber.substring(0, 5) === "+2627"
  || phoneNumber.substring(0, 5) === "+2627" )) {
    return (true);
  }
  if (phoneNumber?.length === 14 && (phoneNumber.substring(0, 6) === "002626"
  || phoneNumber.substring(0, 6) === "002626" || phoneNumber.substring(0, 6) === "002627"
  || phoneNumber.substring(0, 6) === "002627" )) {
    return (true);
  }
  const ArrayAuthorizedPlus = [];
  const ArrayAuthorizedZero = [];
  for (const number of authorized) {
    ArrayAuthorizedPlus.push(`+33${number}`);
    ArrayAuthorizedZero.push(`0${number}`);
  }

  if (![10, 12].includes(phoneNumber?.length))
    return (false);
  if (phoneNumber?.length === 12) {
    if (!ArrayAuthorizedPlus.includes(phoneNumber.substr(0, 4))) {
      return (false);
    }
    for (const number of phoneNumber?.substr(3, phoneNumber?.length)) {
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(number)) {
        return (false);
      }
    }
  }
  if (phoneNumber?.length === 10) {
    if (!ArrayAuthorizedZero.includes(phoneNumber.substr(0, 2)))
      return (false);
    for (const number of phoneNumber) {
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(number)) {
        return (false);
      }
    }
  }
  return (true);
}

export default isValidPhoneNumber;
