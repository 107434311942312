const setStrictPositiveNumberField = (string) => {
  let onlyNumbers = '';
  let index = 0;

  for (const ch of string) {
    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(ch)) {
      if (!(ch === '0' && index === 0)) {
        onlyNumbers += ch;
      }
    }
    index++;
  }
  const number = parseInt(onlyNumbers);

  if (number > 3000)
    return (3000);
  return (number);
};

export default setStrictPositiveNumberField;