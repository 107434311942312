import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import formatValue from "../components/Questionnaire/Response/formatValue";
import html2canvas from "html2canvas";

const getPatientNameById = (id, patientList) => {
  if (!id) return "Anonyme";
  const patient = patientList?.find((e) => e?.id === id);
  return (
    patient?.lastname?.substring(0, 3) + patient?.id + " " + patient?.firstname
  );
};

const calculIndex = (result, firstLine) => {
  const index = firstLine.findIndex((res) => res === result);

  if (index === -1) return null;
  return index;
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  let hours = date.getHours().toString();
  if (hours.length === 1) hours = `0${hours}`;

  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = `0${minutes}`;

  return dd + "/" + mm + "/" + yyyy + " à " + hours + "h" + minutes;
};

export const createPDFWithElementIds = async (
  elementIds: string[],
  opts: {
    type?: "download" | "open" | "get-base64";
    onStep?: ((number: number) => void) | undefined;
    setIsDownloadLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    pageWidth?: number;
    quality?: number;
    name?: string;
  } = {
    type: "download",
    onStep: undefined,
    setIsDownloadLoading: undefined,
    pageWidth: 850,
    name: 'sans_titre',
  }
) => {
  if (opts.setIsDownloadLoading) {
    opts.setIsDownloadLoading(true);
  }
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const content: any[] = [];
  await new Promise((r) => setTimeout(r, 300));
  const result = [];
  let maxHeight = 0;
  let indexElementId = 0;

  for (const elementId of elementIds) {
    const idTmp = `${elementId}`;
    const elementTmp = document.getElementById(idTmp);
    await new Promise((r) => setTimeout(r, 300));
    const resultGetBase = await getBase64FromElementId(
      elementTmp,
      opts?.quality ? opts.quality : 4
    );
    result.push(resultGetBase?.base64string);
    if (resultGetBase?.imageHeight && resultGetBase?.imageWidth) {
      const ratio = opts.pageWidth / resultGetBase.imageWidth;
      if (maxHeight < resultGetBase.imageHeight * ratio) {
        maxHeight = resultGetBase.imageHeight * ratio;
      }
    }
    if (opts.onStep) {
      opts.onStep(indexElementId + 1);
    }
    indexElementId++;
  }

  for (let i = 0; i < result.length; i++) {
    if (result[i] && result[i] !== "data:," && result[i]?.length > 50) {
      content.push({
        image: result[i],
        width: opts.pageWidth,
      });
      if (i !== result.length - 1) {
        content.push({ text: "", style: "normalText", pageBreak: "after" });
      }
    }
  }

  const myStyles = {
    header: {
      fontSize: 16,
      bold: true,
      alignment: "center",
    },
    normalText: {
      fontSize: 12,
    },
  };

  const dataContent = {
    pageSize: {
      width: opts.pageWidth + 90,
      height: maxHeight + 110,
      // height: maxHeight + 120,
    },
    // pageMargins: [50, 50, 50, 60],
    pageMargins: [50, 50, 50, 50],
    footer: (currentPage, pageCount) => {
      return {
        table: {
          body: [
            []
            /*
            [
              {
                text: `Page ` + currentPage.toString() + "/" + pageCount,
                style: "normalText",
                alignment: "right",
                margin: [
                  pageCount > 10 ? opts.pageWidth - 40 : opts.pageWidth - 20,
                  0,
                  0,
                  0,
                ],
              },
            ],
            */
          ],
        },
        layout: "noBorders",
      };
    },
    content: content,
    styles: myStyles,
  };

  if (opts.setIsDownloadLoading) {
    opts.setIsDownloadLoading(false);
    opts.onStep(0);
  }

  if (opts.type === "download") {
    pdfMake.createPdf(dataContent).download(opts.name);
  }
  if (opts.type === "open") {
    pdfMake.createPdf(dataContent).open();
  }
  if (opts.type === "get-base64") {
    return {
      pdfGenerated: pdfMake.createPdf(dataContent),
      name: opts.name,
    };
  }
};

const getBase64FromElementId = async (
  element: HTMLElement,
  scale?: number
): Promise<{
  base64string: string;
  imageHeight: number;
  imageWidth: number;
}> => {
  /*
  const elements = document.querySelectorAll(".chartjs-render-monitor");
  elements.forEach((el) => {
    el.setAttribute("crossOrigin", "anonymous");
  })
  */
  if (element) {
    const canvas = await html2canvas(element, {
      scale: scale ? scale : undefined,
    });
    //document.body.appendChild(canvas);
    const data = canvas.toDataURL("image/jpeg");
    canvas.height;
    //element.setAttribute("display", "none");
    return {
      base64string: data,
      imageHeight: canvas.height,
      imageWidth: canvas.width,
    };
  } else {
    console.log("INVALID ELEMENT");
    console.log(element);
  }
  return undefined;
};

export const textExportPdf = (
  data,
  patientList,
  questionnaireData,
  type: "download" | "open" | "get-base64" = "download"
) => {
  const lines = [];
  console.log(data);
  lines.push(["Date", "Nom du patient"]);
  for (const response of data) {
    if (typeof response?.sections !== "object") continue;
    for (const sectionTitle of Object.keys(response?.sections)) {
      for (const field of response?.sections?.[sectionTitle]) {
        const fieldTitle = field?.fieldTitle;
        const result = `${sectionTitle}${sectionTitle ? " " : ""}${fieldTitle}`;
        if (!lines[0].includes(result))
          lines[0].push(
            `${sectionTitle}${sectionTitle ? " " : ""}${fieldTitle}`
          );
      }
    }
  }

  for (const response of data) {
    if (typeof response?.sections !== "object") continue;
    lines.push([]);
    const index = lines.length - 1;
    for (let i = 0; i < lines[0].length; i++) lines[index].push("");
    lines[index][0] = formatDate(response?.created);
    lines[index][1] = getPatientNameById(response?.patientId, patientList);
    for (const sectionTitle of Object.keys(response?.sections)) {
      for (const field of response?.sections?.[sectionTitle]) {
        const fieldTitle = field?.fieldTitle;
        const result = `${sectionTitle}${sectionTitle ? " " : ""}${fieldTitle}`;
        const newIndex = calculIndex(result, lines[0]);
        if (newIndex) lines[index][newIndex] = formatValue(field?.value);
      }
    }
  }

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let myContent = [
    {
      text: "Réponses au questionnaire",
      style: "header",
    } as any,
  ];

  //  Aucune réponse au questionnaire
  if (lines.length < 2)
    myContent.push({
      text: "\n\n\nAucune réponse au questionnaire",
      style: "normalText",
    });
  else {
    // Affichage des réponses du questionnaire
    myContent.push({ text: "\n\n\n" });
    for (let number = 1; number < lines.length; number++) {
      // Boucle différentes réponses
      myContent.push({
        text: `${lines[number][1].trim() + "\n" + lines[number][0].trim()}`,
        style: "nameDate",
      });
      for (
        let i = 2;
        lines[0][i] != null;
        i++ // boucle différentes questions / réponses
      )
        if (lines[number][i]) {
          myContent.push({
            text: `${"\n" + lines[0][i].trim() + " : " + lines[number][i]}`,
            style: "normalText",
          });
        }
      if (number != lines.length - 1)
        // Changer de page à chaque fois sauf pour le dernier
        myContent.push({ text: "", style: "normalText", pageBreak: "after" });
    }
  }

  const myStyles = {
    header: {
      fontSize: 20,
      bold: true,
      alignment: "center",
      decoration: "underline",
    },
    nameDate: {
      fontSize: 15,
      bold: true,
      alignment: "right",
    },
    normalText: {
      fontSize: 15,
    },
  };

  const dataContent = {
    pageSize: "A4",
    pageMargins: [40, 40, 40, 40],
    footer: (currentPage, pageCount) => {
      return {
        table: {
          body: [
            [
              // marginLeft = 500 pour mettre tout à gauche | autre solution ?
              {
                text: "Page " + currentPage.toString() + " sur " + pageCount,
                style: "normalText",
                alignment: "right",
                margin: [pageCount > 10 ? 470 : 490, 0, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
      };
    },
    content: myContent,
    styles: myStyles,
  };

  const name = `${questionnaireData?.title}-reponses`.replaceAll(" ", "-");
  if (type === "download") {
    pdfMake.createPdf(dataContent).download(name);
  }
  if (type === "open") {
    pdfMake.createPdf(dataContent).open();
  }
  if (type === "get-base64") {
    return {
      pdfGenerated: pdfMake.createPdf(dataContent),
      name: name,
    };
  }
};

export const exportPdf = async (
  onStep: (number: number) => void,
  data,
  patientList,
  questionnaireData,
  type: "download" | "open" | "get-base64" = "download"
) => {
  const lines = [];
  lines.push(["Date", "Nom du patient"]);
  for (const response of data) {
    if (typeof response?.sections !== "object") continue;
    for (const sectionTitle of Object.keys(response?.sections)) {
      for (const field of response?.sections?.[sectionTitle]) {
        const fieldTitle = field?.fieldTitle;
        const result = `${sectionTitle}${sectionTitle ? " " : ""}${fieldTitle}`;
        if (!lines[0].includes(result))
          lines[0].push(
            `${sectionTitle}${sectionTitle ? " " : ""}${fieldTitle}`
          );
      }
    }
  }

  for (const response of data) {
    if (typeof response?.sections !== "object") continue;
    lines.push([]);
    const index = lines.length - 1;
    for (let i = 0; i < lines[0].length; i++) lines[index].push("");
    lines[index][0] = formatDate(response?.created);
    lines[index][1] = getPatientNameById(response?.patientId, patientList);
    for (const sectionTitle of Object.keys(response?.sections)) {
      for (const field of response?.sections?.[sectionTitle]) {
        const fieldTitle = field?.fieldTitle;
        const result = `${sectionTitle}${sectionTitle ? " " : ""}${fieldTitle}`;
        const newIndex = calculIndex(result, lines[0]);
        if (newIndex) lines[index][newIndex] = formatValue(field?.value);
      }
    }
  }

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let myContent = [
    {
      text: questionnaireData?.title
        ? questionnaireData?.title
        : "Questionnaire",
      style: "header",
    } as any,
  ];
  await new Promise((r) => setTimeout(r, 300));
  const result = [];
  let maxHeight = 0;
  for (let i = 0; i < data.length; i++) {
    const idTmp = `full-response-${data[i]?.id}`;
    const elementTmp = document.getElementById(idTmp);
    elementTmp.style.setProperty("display", "block");
    await new Promise((r) => setTimeout(r, 300));
    const resultGetBase = await getBase64FromElementId(elementTmp);
    result.push(resultGetBase?.base64string);
    if (resultGetBase?.imageHeight && resultGetBase?.imageWidth) {
      const ratio = 790 / resultGetBase.imageWidth;
      if (maxHeight < resultGetBase.imageHeight * ratio) {
        maxHeight = resultGetBase.imageHeight * ratio;
      }
    }
    elementTmp.style.setProperty("display", "none");
    onStep(i + 1);
  }
  for (let i = 0; i < result.length; i++) {
    if (result[i] && result[i] !== "data:," && result[i]?.length > 50) {
      myContent.push({
        image: result[i],
        width: 790,
      });
      if (i !== result.length - 1) {
        myContent.push({ text: "", style: "normalText", pageBreak: "after" });
      }
    }
  }

  const myStyles = {
    header: {
      fontSize: 16,
      bold: true,
      alignment: "center",
    },
    normalText: {
      fontSize: 12,
    },
  };

  const dataContent = {
    pageSize: {
      width: 850,
      height: maxHeight + 90,
    },
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return {
        table: {
          body: [
            [
              // marginLeft = 500 pour mettre tout à gauche | autre solution ?
              {
                text: currentPage.toString() + "/" + pageCount,
                style: "normalText",
                alignment: "right",
                margin: [pageCount > 10 ? 780 : 800, 0, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
      };
    },
    content: myContent,
    styles: myStyles,
  };

  const name = `${questionnaireData?.title}-reponses`.replaceAll(" ", "-");
  if (type === "download") {
    pdfMake.createPdf(dataContent).download(name);
  }
  if (type === "open") {
    pdfMake.createPdf(dataContent).open();
  }
  if (type === "get-base64") {
    return {
      pdfGenerated: pdfMake.createPdf(dataContent),
      name: name,
    };
  }
};

export default exportPdf;
