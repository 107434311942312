const initialState = {
  isPopup: false,
  patient: null,
};

export const setIsPopup = res => dispatch => { dispatch({ type: 'SET_IS_POPUP', payload: res }) };
export const setPatient = res => dispatch => { dispatch({ type: 'SET_PATIENT', payload: res }) };

function sendAdviceReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_IS_POPUP':
      return ({ ...state, isPopup: action.payload });
    case 'SET_PATIENT':
      return ({ ...state, patient: action.payload });
    default:
      return state;
  }
}

export default sendAdviceReducer;