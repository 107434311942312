import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import makeAPIRequest from "../utils/makeAPIRequest";
import BoxTitle from "../components/Library/Box/BoxTitle";
import TextInput from "../components/Library/Inputs/TextInput";
import OrangeButton from "../components/Library/Buttons/OrangeButton/OrangeButton";
import { openSnackbar } from "../redux/snackbarReducer";
import Creatable from "react-select/creatable";
import { useDispatch } from "react-redux";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import { emailValidator } from "../components/Questionnaire/SendResponsePopup";
import {
  GenericLongText,
  GenericTextInput,
} from "./CreateOrEditChatbotLexPage";
import useAppSelector from "../redux/useAppSelector";

export interface INodeParameters {
  activeIAConseils: boolean;
  sendEmailAfterRegister: {
    isActive: boolean;
    emails: Array<string>;
  };
  sendEmailAfterConseilCreation: {
    isActive: boolean;
    emails: Array<string>;
  };
  setEmailNotInCopy: {
    isActive: boolean;
    emails: Array<string>;
  };
  sendEmailBeforeUserExpire: {
    isActive: boolean;
    daysBefore: number;
    emails: Array<string>;
    content?: string;
    subject?: string;
  };
  sendEmailStatsEveryWeek: {
    isActive: boolean;
    emails: Array<string>;
  };
  sendEmailAlertLimitSMS: {
    isActive: boolean;
    emails: Array<string>;
  };
}

const DisplayScheduledSMSAlertParameter: React.FC<any> = (props) => {
  const { nodeParameters, setNodeParameters } = props;

  return (
    <Grid container direction="row" alignItems="center" spacing="10px">
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          Si
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={nodeParameters?.conseilsScheduledLimit}
            onChange={(e) => {
              let parsed = parseInt(e.target.value);
              if (isNaN(parsed)) {
                parsed = undefined;
              }
              setNodeParameters({
                ...nodeParameters,
                conseilsScheduledLimit: parsed,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          SMS programmés au même patient les
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={nodeParameters?.sendConseilsFollowingDaysAlert}
            onChange={(e) => {
              let parsed = parseInt(e.target.value);
              if (isNaN(parsed)) {
                parsed = undefined;
              }
              setNodeParameters({
                ...nodeParameters,
                sendConseilsFollowingDaysAlert: parsed,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          prochains jours
        </span>
      </Grid>
    </Grid>
  );
};

const DisplayLastSendedSMSAlertParameter: React.FC<any> = (props) => {
  const { nodeParameters, setNodeParameters } = props;

  return (
    <Grid container direction="row" alignItems="center" spacing="10px">
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          Si
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={nodeParameters?.conseilsAlreadySendLimit}
            onChange={(e) => {
              let parsed = parseInt(e.target.value);
              if (isNaN(parsed)) {
                parsed = undefined;
              }
              setNodeParameters({
                ...nodeParameters,
                conseilsAlreadySendLimit: parsed,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          SMS envoyés au même patient les
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={nodeParameters?.sendConseilsLastDaysAlert}
            onChange={(e) => {
              let parsed = parseInt(e.target.value);
              if (isNaN(parsed)) {
                parsed = undefined;
              }
              setNodeParameters({
                ...nodeParameters,
                sendConseilsLastDaysAlert: parsed,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          derniers jours
        </span>
      </Grid>
    </Grid>
  );
};

const DisplayMoreConseilsCostParameter: React.FC<any> = (props) => {
  const { parameters, setParameters } = props;

  return (
    <Grid container direction="row" alignItems="center" spacing="10px">
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          Afficher plus de conseils coûte
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={parameters?.display_more_conseils_cost}
            onChange={(e) => {
              setParameters({
                ...parameters,
                display_more_conseils_cost: e.target.value,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          jeton(s)
        </span>
      </Grid>
    </Grid>
  );
};

const SendSMSCostParameter: React.FC<any> = (props) => {
  const { parameters, setParameters } = props;

  return (
    <Grid container direction="row" alignItems="center" spacing="10px">
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          L'envoi d'un SMS coûte
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={parameters?.send_sms_tokens_cost}
            onChange={(e) => {
              setParameters({
                ...parameters,
                send_sms_tokens_cost: e.target.value,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          jeton(s)
        </span>
      </Grid>
    </Grid>
  );
};

const ClassicAccountTokensParameter: React.FC<any> = (props) => {
  const { parameters, setParameters } = props;

  return (
    <Grid container direction="row" alignItems="center" spacing="10px">
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          Un compte classique commence avec
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={parameters?.classic_account_tokens}
            onChange={(e) => {
              setParameters({
                ...parameters,
                classic_account_tokens: e.target.value,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          jeton(s)
        </span>
      </Grid>
    </Grid>
  );
};

const TestAccountTokensParameter: React.FC<any> = (props) => {
  const { parameters, setParameters } = props;

  return (
    <Grid container direction="row" alignItems="center" spacing="10px">
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          Un compte de test commence avec
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={parameters?.test_account_tokens}
            onChange={(e) => {
              setParameters({
                ...parameters,
                test_account_tokens: e.target.value,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          jeton(s)
        </span>
      </Grid>
    </Grid>
  );
};

const TestAccountExpirationParameter: React.FC<any> = (props) => {
  const { parameters, setParameters } = props;

  return (
    <Grid container direction="row" alignItems="center" spacing="10px">
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          Un compte de test expire au bout de
        </span>
      </Grid>
      <Grid item display="grid">
        <div style={{ maxWidth: "65px" }}>
          <TextInput
            value={parameters?.test_account_expiration}
            onChange={(e) => {
              setParameters({
                ...parameters,
                test_account_expiration: e.target.value,
              });
            }}
          />
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Poppins",
            letterSpacing: "0px",
            fontSize: "16px",
            color: "#0B243C",
          }}
        >
          jour(s)
        </span>
      </Grid>
    </Grid>
  );
};

const AdminParametersBox = (props: {
  parameters: any;
  isLoading: boolean;
  setParameters: React.Dispatch<React.SetStateAction<any>>;
  nodeParameters: Partial<INodeParameters>;
  setNodeParameters: React.Dispatch<
    React.SetStateAction<Partial<INodeParameters>>
  >;
}) => {
  const {
    parameters,
    isLoading,
    setParameters,
    nodeParameters,
    setNodeParameters,
  } = props;
  const dispatch = useDispatch();
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      minWidth="320px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <BoxTitle first="Mes" second="Paramètres" />
          </Grid>
          <Grid item display="grid" paddingTop="50px">
            {!isLoading ? (
              <Grid container direction="column" spacing="10px" wrap="nowrap">
                <Grid item display="grid">
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#0B243C",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Gestion des jetons
                  </span>
                </Grid>
                <Grid item display="grid">
                  <TestAccountExpirationParameter {...props} />
                </Grid>
                <Grid item display="grid">
                  <TestAccountTokensParameter {...props} />
                </Grid>
                {/*<Grid item display='grid'>
                  <ClassicAccountTokensParameter {...props} />
            </Grid>*/}
                <Grid item display="grid">
                  <SendSMSCostParameter {...props} />
                </Grid>
                <Grid item display="grid">
                  <DisplayMoreConseilsCostParameter {...props} />
                </Grid>
                <Grid item display="grid" marginTop="15px">
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#0B243C",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Alertes via pop-up avant programmation
                  </span>
                </Grid>
                <Grid item display="grid">
                  <DisplayLastSendedSMSAlertParameter {...props} />
                </Grid>
                <Grid item display="grid">
                  <DisplayScheduledSMSAlertParameter {...props} />
                </Grid>

                <Grid item display="grid" marginTop="15px">
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#0B243C",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    {`Intelligence artificielle`}
                  </span>
                </Grid>

                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={() => {
                      setNodeParameters((state) => {
                        return {
                          ...state,
                          activeIAConseils: state.activeIAConseils ? false : true,
                        };
                      });
                    }}
                    value={nodeParameters?.activeIAConseils ? true : false}
                    text="Activer la génération de conseils"
                  />
                </Grid>
                <Grid item display="grid" marginTop="15px">
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#0B243C",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    {`Envoi d'e-mails`}
                  </span>
                </Grid>
                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={() => {
                      setNodeParameters((state) => {
                        const copyObject = { ...state.sendEmailAlertLimitSMS };

                        if (copyObject.isActive) {
                          copyObject.isActive = false;
                        } else {
                          copyObject.isActive = true;
                        }
                        return {
                          ...state,
                          sendEmailAlertLimitSMS: copyObject,
                        };
                      });
                    }}
                    value={nodeParameters?.sendEmailAlertLimitSMS?.isActive}
                    text="Envoyer un email lorsqu'une limite d'envoi est presque atteinte et dépassée"
                  />
                </Grid>

                {nodeParameters?.sendEmailAlertLimitSMS?.isActive && (
                  <Grid item display="grid">
                    <div
                      id="parameters-inscription-destinataires"
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "600px",
                        border: "1px solid transparent",
                      }}
                    >
                      <Creatable
                        noOptionsMessage={() =>
                          "Tapez l'adresse email du destinataire"
                        }
                        formatCreateLabel={(userInput) => `${userInput}`}
                        isMulti={true}
                        maxMenuHeight={120}
                        options={(() => {
                          const optionsTmp = [];
                          return optionsTmp;
                        })()}
                        value={nodeParameters.sendEmailAlertLimitSMS.emails.map(
                          (x) => {
                            return { label: x, value: x };
                          }
                        )}
                        placeholder="Liste des destinataires"
                        onChange={(e: any) => {
                          setNodeParameters((state) => {
                            const copyObject = {
                              ...state.sendEmailAlertLimitSMS,
                            };

                            copyObject.emails = e
                              ?.filter((opt: any) => emailValidator(opt?.label))
                              ?.map((x) => x?.label);
                            return {
                              ...state,
                              sendEmailAlertLimitSMS: copyObject,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={() => {
                      setNodeParameters((state) => {
                        const copyObject = { ...state.sendEmailStatsEveryWeek };

                        if (copyObject.isActive) {
                          copyObject.isActive = false;
                        } else {
                          copyObject.isActive = true;
                        }
                        return {
                          ...state,
                          sendEmailStatsEveryWeek: copyObject,
                        };
                      });
                    }}
                    value={nodeParameters?.sendEmailStatsEveryWeek?.isActive}
                    text="Envoyer un email hebdomadaire de stats d'utilisation de CertiPair"
                  />
                </Grid>
                {nodeParameters?.sendEmailStatsEveryWeek?.isActive && (
                  <Grid item display="grid">
                    <div
                      id="parameters-inscription-destinataires"
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "600px",
                        border: "1px solid transparent",
                      }}
                    >
                      <Creatable
                        noOptionsMessage={() =>
                          "Tapez l'adresse email du destinataire"
                        }
                        formatCreateLabel={(userInput) => `${userInput}`}
                        isMulti={true}
                        maxMenuHeight={120}
                        options={(() => {
                          const optionsTmp = [];
                          return optionsTmp;
                        })()}
                        value={nodeParameters.sendEmailStatsEveryWeek.emails.map(
                          (x) => {
                            return { label: x, value: x };
                          }
                        )}
                        placeholder="Liste des destinataires"
                        onChange={(e: any) => {
                          setNodeParameters((state) => {
                            const copyObject = {
                              ...state.sendEmailStatsEveryWeek,
                            };

                            copyObject.emails = e
                              ?.filter((opt: any) => emailValidator(opt?.label))
                              ?.map((x) => x?.label);
                            return {
                              ...state,
                              sendEmailStatsEveryWeek: copyObject,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={() => {
                      setNodeParameters((state) => {
                        const copyObject = { ...state.sendEmailAfterRegister };

                        if (copyObject.isActive) {
                          copyObject.isActive = false;
                        } else {
                          copyObject.isActive = true;
                        }
                        return { ...state, sendEmailAfterRegister: copyObject };
                      });
                    }}
                    value={nodeParameters?.sendEmailAfterRegister?.isActive}
                    text="Envoyer un email à chaque nouvelle inscription"
                  />
                </Grid>
                {nodeParameters?.sendEmailAfterRegister?.isActive && (
                  <Grid item display="grid">
                    <div
                      id="parameters-inscription-destinataires"
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "600px",
                        border: "1px solid transparent",
                      }}
                    >
                      <Creatable
                        noOptionsMessage={() =>
                          "Tapez l'adresse email du destinataire"
                        }
                        formatCreateLabel={(userInput) => `${userInput}`}
                        isMulti={true}
                        maxMenuHeight={120}
                        options={(() => {
                          const optionsTmp = [];
                          return optionsTmp;
                        })()}
                        value={nodeParameters.sendEmailAfterRegister.emails.map(
                          (x) => {
                            return { label: x, value: x };
                          }
                        )}
                        placeholder="Liste des destinataires"
                        onChange={(e: any) => {
                          setNodeParameters((state) => {
                            const copyObject = {
                              ...state.sendEmailAfterRegister,
                            };

                            copyObject.emails = e
                              ?.filter((opt: any) => emailValidator(opt?.label))
                              ?.map((x) => x?.label);
                            return {
                              ...state,
                              sendEmailAfterRegister: copyObject,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                )}

                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={() => {
                      setNodeParameters((state) => {
                        const copyObject = { ...state.sendEmailAfterConseilCreation };

                        if (copyObject.isActive) {
                          copyObject.isActive = false;
                        } else {
                          copyObject.isActive = true;
                        }
                        return { ...state, sendEmailAfterConseilCreation: copyObject };
                      });
                    }}
                    value={nodeParameters?.sendEmailAfterConseilCreation?.isActive}
                    text="Envoyer un email après chaque création de conseil"
                  />
                </Grid>
                {nodeParameters?.sendEmailAfterConseilCreation?.isActive && (
                  <Grid item display="grid">
                    <div
                      id="parameters-inscription-destinataires"
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "600px",
                        border: "1px solid transparent",
                      }}
                    >
                      <Creatable
                        noOptionsMessage={() =>
                          "Tapez l'adresse email du destinataire"
                        }
                        formatCreateLabel={(userInput) => `${userInput}`}
                        isMulti={true}
                        maxMenuHeight={120}
                        options={(() => {
                          const optionsTmp = [];
                          return optionsTmp;
                        })()}
                        value={nodeParameters.sendEmailAfterConseilCreation.emails.map(
                          (x) => {
                            return { label: x, value: x };
                          }
                        )}
                        placeholder="Liste des destinataires"
                        onChange={(e: any) => {
                          setNodeParameters((state) => {
                            const copyObject = {
                              ...state.sendEmailAfterConseilCreation,
                            };

                            copyObject.emails = e
                              ?.filter((opt: any) => emailValidator(opt?.label))
                              ?.map((x) => x?.label);
                            return {
                              ...state,
                              sendEmailAfterConseilCreation: copyObject,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                )}

                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={() => {
                      setNodeParameters((state) => {
                        const copyObject = { ...state.setEmailNotInCopy };

                        if (copyObject.isActive) {
                          copyObject.isActive = false;
                        } else {
                          copyObject.isActive = true;
                        }
                        return { ...state, setEmailNotInCopy: copyObject };
                      });
                    }}
                    value={nodeParameters?.setEmailNotInCopy?.isActive}
                    text="Désactiver de l'ajout en CC de certains utilisateurs"
                  />
                </Grid>
                {nodeParameters?.setEmailNotInCopy?.isActive && (
                  <Grid item display="grid">
                    <div
                      id="parameters-inscription-destinataires"
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "600px",
                        border: "1px solid transparent",
                      }}
                    >
                      <Creatable
                        noOptionsMessage={() =>
                          "Tapez l'adresse email du destinataire"
                        }
                        formatCreateLabel={(userInput) => `${userInput}`}
                        isMulti={true}
                        maxMenuHeight={120}
                        options={(() => {
                          const optionsTmp = [];
                          return optionsTmp;
                        })()}
                        value={nodeParameters.setEmailNotInCopy.emails.map(
                          (x) => {
                            return { label: x, value: x };
                          }
                        )}
                        placeholder="Liste des destinataires"
                        onChange={(e: any) => {
                          setNodeParameters((state) => {
                            const copyObject = {
                              ...state.setEmailNotInCopy,
                            };

                            copyObject.emails = e
                              ?.filter((opt: any) => emailValidator(opt?.label))
                              ?.map((x) => x?.label);
                            return {
                              ...state,
                              setEmailNotInCopy: copyObject,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={() => {
                      setNodeParameters((state) => {
                        const copyObject = {
                          ...state.sendEmailBeforeUserExpire,
                        };

                        if (copyObject.isActive) {
                          copyObject.isActive = false;
                        } else {
                          copyObject.isActive = true;
                        }
                        return {
                          ...state,
                          sendEmailBeforeUserExpire: copyObject,
                        };
                      });
                    }}
                    value={nodeParameters?.sendEmailBeforeUserExpire?.isActive}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing="10px"
                    >
                      <Grid item display="grid">
                        <span className="text_champs">
                          {`Envoyer un email à l'utilisateur`}
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <div style={{ maxWidth: "65px" }}>
                          <TextInput
                            value={
                              nodeParameters?.sendEmailBeforeUserExpire
                                ?.daysBefore
                            }
                            onChange={(e) => {
                              setNodeParameters((state) => {
                                const copyState = {
                                  ...state.sendEmailBeforeUserExpire,
                                };

                                let parsed = parseInt(e.target.value);
                                if (isNaN(parsed)) {
                                  parsed = undefined;
                                }
                                copyState.daysBefore = parsed;
                                return {
                                  ...state,
                                  sendEmailBeforeUserExpire: copyState,
                                };
                              });
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item display="grid">
                        <span className="text_champs">
                          {"jour(s) avant l'expiration de son compte"}
                        </span>
                      </Grid>
                    </Grid>
                  </OrangeCheckBox>
                </Grid>
                {nodeParameters?.sendEmailBeforeUserExpire?.isActive && (
                  <>
                    <Grid item display="grid">
                      <div
                        id="parameters-inscription-destinataires-2"
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          maxWidth: "600px",
                          border: "1px solid transparent",
                        }}
                      >
                        <Creatable
                          noOptionsMessage={() => "CC"}
                          formatCreateLabel={(userInput) => `${userInput}`}
                          isMulti={true}
                          maxMenuHeight={120}
                          options={(() => {
                            const optionsTmp = [];
                            return optionsTmp;
                          })()}
                          value={nodeParameters.sendEmailBeforeUserExpire.emails.map(
                            (x) => {
                              return { label: x, value: x };
                            }
                          )}
                          placeholder="Liste des destinataires"
                          onChange={(e: any) => {
                            setNodeParameters((state) => {
                              const copyObject = {
                                ...state.sendEmailBeforeUserExpire,
                              };

                              copyObject.emails = e
                                ?.filter((opt: any) =>
                                  emailValidator(opt?.label)
                                )
                                ?.map((x) => x?.label);
                              return {
                                ...state,
                                sendEmailBeforeUserExpire: copyObject,
                              };
                            });
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item display="grid">
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "600px",
                        }}
                      >
                        <GenericTextInput
                          fieldTitle=""
                          id="subject-mail-expiration"
                          placeholder="Objet"
                          onChange={(e) => {
                            setNodeParameters((x) => {
                              return {
                                ...x,
                                sendEmailBeforeUserExpire: {
                                  ...x.sendEmailBeforeUserExpire,
                                  subject: e?.target?.value
                                    ? e.target.value
                                    : "",
                                },
                              };
                            });
                          }}
                          value={
                            nodeParameters?.sendEmailBeforeUserExpire?.subject
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item display="grid">
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "600px",
                        }}
                      >
                        <GenericLongText
                          maxLength={5000}
                          placeholder="Contenu"
                          fieldTitle=""
                          id="content_mail_expiration"
                          onChange={(e) => {
                            setNodeParameters((x) => {
                              return {
                                ...x,
                                sendEmailBeforeUserExpire: {
                                  ...x.sendEmailBeforeUserExpire,
                                  content: e ? e : "",
                                },
                              };
                            });
                          }}
                          value={
                            nodeParameters?.sendEmailBeforeUserExpire?.content
                          }
                        />
                      </div>
                    </Grid>
                  </>
                )}
                {!isSubmitLoading ? (
                  <Grid
                    item
                    display="grid"
                    alignItems="center"
                    justifyContent="center"
                    marginTop="30px"
                  >
                    <OrangeButton
                      animation={false}
                      enable={!isSubmitLoading}
                      type="submit"
                      text="Mettre à jour"
                      onClick={() => {
                        setIsSubmitLoading(true);
                        makeAPIRequest(
                          "post",
                          "/parameters/update",
                          parameters,
                          "v2"
                        )
                          .then((res) => {
                            makeAPIRequest(
                              "post",
                              "/parameters/update",
                              nodeParameters,
                              "v3"
                            )
                              .then((resNode) => {
                                dispatch(
                                  openSnackbar({
                                    message:
                                      "Les paramètres ont bien été mis à jour",
                                    type: "success",
                                    duration: 3000,
                                  })
                                );
                                setIsSubmitLoading(false);
                              })
                              .catch(() => {
                                dispatch(
                                  openSnackbar({
                                    message:
                                      "Les paramètres ont été mis à jour partiellement",
                                    type: "error",
                                    duration: 3000,
                                  })
                                );
                                setIsSubmitLoading(false);
                              });
                          })
                          .catch(() => {
                            dispatch(
                              openSnackbar({
                                message:
                                  "Impossible de mettre à jour les paramètres",
                                type: "error",
                                duration: 3000,
                              })
                            );
                            setIsSubmitLoading(false);
                          });
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item display="grid" paddingTop="30px" marginTop="15px">
                    <Grid
                      container
                      direction="row"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AdminParametersPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const [isLoading, setIsLoading] = React.useState(false);
  const [parameters, setParameters] = React.useState({});
  const [nodeParameters, setNodeParameters] = React.useState<
    Partial<INodeParameters>
  >({});

  const formatParameters = (parameters) => {
    if (!parameters) return {};

    const newParameters = {};
    for (const param of parameters) {
      newParameters[param.type] = param.value;
    }
    return newParameters;
  };

  React.useEffect(() => {
    if (!isLoading) setIsLoading(true);
    makeAPIRequest("get", "/parameters/list", null, "v2")
      .then((res) => {
        setParameters(formatParameters(res?.data?.data?.parameters));
        makeAPIRequest("get", "/parameters", null, "v3").then((resNode) => {
          setIsLoading(false);
          setNodeParameters(resNode?.data);
        });
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_parameters" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_parameters" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
        >
          <AdminParametersBox
            setParameters={setParameters}
            parameters={parameters}
            isLoading={isLoading}
            nodeParameters={nodeParameters}
            setNodeParameters={setNodeParameters}
          />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminParametersPage;
