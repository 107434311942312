import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import AuthService from "../services/auth.services";
import isValidPhoneNumber from "../utils/isValidPhoneNumber";
import { openPatient } from "../redux/createPatientReducer";
import { useDispatch } from "react-redux";
import { addPhone } from "../redux/selectPatientReducer";

const addNewPatient = (input: string, dispatch: any) => {
  const saved = input;
  let firstname = "";
  let lastname = "";
  let phone = "";
  if ([" "].includes(saved)) {
    const splited = saved.split(" ");
    if (splited?.length >= 2) {
      firstname = splited[0];
      lastname = splited[1];
      phone = "";
    }
  } else {
    if (isValidPhoneNumber(saved)) {
      firstname = "";
      lastname = "";
      phone = saved;
    } else {
      firstname = "";
      lastname = saved;
      phone = "";
    }
  }
  dispatch(
    openPatient({
      lastname: lastname,
      firstname: firstname,
      phone: phone,
    })
  );
};

interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

const AutoLog: React.FC<Props> = (props) => {
  const [failure, setFailure] = React.useState(false);
  const dispatch = useDispatch();
  const path: string = props.location.pathname.slice(10);
  const array = path.split(",");

  useEffect(() => {
    getData();
  }, []);

  const callRedirect = (tentative) => {
    setTimeout(() => {
      if (tentative === 10) {
        setFailure(true);
        return;
      }
      if (localStorage.getItem("token")) {
        props.history.push('/home');
      } else {
        callRedirect(tentative + 1);
      }
    }, 1000);
  };
  const getData = () => {
    AuthService.autoLogin(array[0], array[1]).then(() => {
      for (let i = 2; i < array?.length; i++) {
        dispatch(addPhone(array[i]));
      }
      callRedirect(0);
    });
  };

  return (
    <div>
      <h1>
        {failure ? "Impossible de se connecter" : "En cours de connexion"}
      </h1>
    </div>
  );
};

export default AutoLog;
