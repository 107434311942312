import { Grid } from "@mui/material";
import GreyButton from "../../../Library/Buttons/GreyButton/GreyButton";
import OrangeButton from "../../../Library/Buttons/OrangeButton/OrangeButton";
import * as React from 'react';
import { useDispatch } from "react-redux";
import { setFieldCopy } from "../../../../redux/questionnaireReducer";
import ErrorHelp from "../../../Library/Errors/ErrorHelp";
import { IField, IForm } from "../../../../types/FormTypes";
import { getConsentementPhrase } from "../../GenerateQuestionnaire/GenerateQuestionnaire";

const errorMessages = {
  emptyType: "Aucun type n'est renseigné.",
  emptyUniqueChoiceOption: "Aucune option n'est renseignée.",
  emptyAlertChoiceOption: "Aucun mot-clé n'est renseignée.",
  emptySimpleCheckOption: "Aucune option n'est renseignée.",
  emptyListeDeroulanteOption: "Aucune option n'est renseignée.",
  emptyAdditionAutoOption: "Aucun champ n'est renseignée.",
  emptyTextAndImageContent: "Le contenu n'est pas renseigné.",
  emptyConsentementPhrase: "La phrase de consentement du patient n'est pas renseignée.",
  noMedecinTraitant: "Aucun médecin traitant n'a été ajouté.",
  noProtocole: "Aucun protocole n'a été ajouté.",
  noMedecinDelegant: "Aucun médecin délégant n'a été ajouté.",
  noStructure: "Aucune structure n'a été ajoutée.",
  noAttributPatientType: "Aucun attribut n'a été selectionné.",
  '': '',
};

const errorSelector = {
  emptyType: '#questionnaire-popup-type-input',
  emptyUniqueChoiceOption: '#questionnaire-popup-unique-choice-options-input',
  emptyAlertChoiceOption: '#questionnaire-popup-alert-choice-options-input',
  emptySimpleCheckOption: "#questionnaire-popup-check-options-input",
  emptyListeDeroulanteOption: "#questionnaire-popup-liste-deroulante-options-input",
  emptyAdditionAutoOption: "#questionnaire-popup-addition-auto-options-input",
  emptyTextAndImageContent: '#questionnaire-popup-text-and-image-content',
  emptyConsentementPhrase: '#consent-input',
  noMedecinTraitant: '#add-medecin-traitant-button',
  noStructure: '#add-structure-button',
  noProtocole: '#add-protocole-button',
  noMedecinDelegant: '#add-medecin-delegant-button',
  noAttributPatientType: "#choice-attribut-patient",
  '': '',
};

const DisplayButtons: React.FC<any> = (props) => {
  const [error, setError] = React.useState<string>('');
  const dispatch = useDispatch();
  const fieldCopy: IField = props?.fieldCopy;
  const questionnaireData: IForm = props?.questionnaireData;
  const {
    setQuestionnaireData,
  } = props;

  const updateField = () => {
    const questionnaireDataTmp = { ...questionnaireData };

    for (let i = 0; i < questionnaireDataTmp?.sections?.length; i++) {
      for (let j = 0; j < questionnaireDataTmp.sections[i].fields.length; j++) {
        for (let k = 0; k < questionnaireDataTmp.sections[i].fields[j].length; k++) {
          if (questionnaireDataTmp.sections[i].fields[j][k]?.id === fieldCopy?.id) {
            questionnaireDataTmp.sections[i].fields[j][k] = { ...fieldCopy };
            setQuestionnaireData(questionnaireDataTmp);
            dispatch(setFieldCopy(null));
            return;
          }
        }
      }
    }
  }

  const isValidSubmit = () => {
    //
    const invalidConditions = {
      emptyType: !fieldCopy?.type,
      emptyUniqueChoiceOption: fieldCopy?.type === 'unique_choice' && (!fieldCopy?.option || fieldCopy?.option?.length === 0),
      emptyAlertChoiceOption: fieldCopy?.alertType?.length > 0 && (!fieldCopy?.alertWords || fieldCopy?.alertWords?.length === 0),
      emptySimpleCheckOption: fieldCopy?.type === 'simple_check' && (!fieldCopy?.option || fieldCopy?.option?.length === 0),
      emptyListeDeroulanteOption: fieldCopy?.type === 'liste_deroulante' && (!fieldCopy?.option || fieldCopy?.option?.length === 0),
      emptyAdditionAutoOption: fieldCopy?.type === 'addition_auto' && (!fieldCopy?.option || fieldCopy?.option?.length === 0),
      emptyConsentementPhrase: fieldCopy?.type === 'medecins_traitants' && (!getConsentementPhrase(questionnaireData, fieldCopy?.id) && !fieldCopy?.medecinsTraitantInfos?.consentText),
      noMedecinTraitant: fieldCopy?.type === 'medecins_traitants' && (!fieldCopy?.medecinsTraitantInfos?.medecinTraitantsList || fieldCopy.medecinsTraitantInfos.medecinTraitantsList.length == 0),
      noProtocole: fieldCopy?.type === 'protocole' && (!fieldCopy?.protocoleInfos?.protocolesList || fieldCopy.protocoleInfos.protocolesList.length == 0),
      noMedecinDelegant: fieldCopy?.type === 'medecin_delegant' && (!fieldCopy?.medecinDelegantInfos?.medecinsDelegantList || fieldCopy.medecinDelegantInfos.medecinsDelegantList.length == 0),
      noStructure: fieldCopy?.type === 'structure' && (!fieldCopy?.structureInfos?.structuresList || fieldCopy.structureInfos.structuresList.length == 0),
      noAttributPatientType: fieldCopy?.type === 'attribut_patient' && !fieldCopy?.attributPatientType,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return (false);
        }
        if (error !== condition) setError(condition);
        return (false);
      }
    }
    if (error !== '') setError('');
    return (true);
  };

  return (
    <Grid container direction='column' wrap="nowrap">
      <Grid item display='grid'>
        {error?.length > 0 &&
          <Grid item display='grid' paddingTop='8px'>
            <ErrorHelp
              errorMessages={errorMessages}
              errorSelector={errorSelector}
              error={error} />
          </Grid >
        }
      </Grid>
      <Grid item display='grid'>
        <Grid container direction='row' display='flex' columnSpacing='15px' rowSpacing='10px' justifyContent='center' alignItems='center'>
          <Grid item display='grid'>
            <GreyButton
              animation={false}
              enable={true}
              text='Annuler'
              onClick={() => {
                dispatch(setFieldCopy(null));
              }} />
          </Grid>
          <Grid item display='grid'>
            {isValidSubmit() ?
              <OrangeButton
                animation={false}
                enable={true}
                type='submit'
                text={'Modifier'}
                onClick={() => updateField()} />
              :
              <OrangeButton
                animation={false}
                enable={false}
                type='submit'
                text={'Modifier'}
                onClick={() => { return (null); }} />
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DisplayButtons;