import React from "react";
import { Radar } from "react-chartjs-2";

const myDefaultObject: <T>(obj: T, objSet: Partial<T>) => T = (obj, objSet) => {
  if (typeof obj !== "object" || typeof objSet !== "object") {
    return obj;
  }
  const newObj = { ...obj };
  for (const keyObjSet of Object.keys(objSet)) {
    if (!(keyObjSet in newObj)) {
      newObj[keyObjSet] = objSet[keyObjSet];
    }
  }
  return newObj;
};

const GenericRadar: React.FC<{
  min?: number;
  legend?: boolean;
  max?: number;
  data: {
    labels: string[];
    datasets: Array<any>;
  };
}> = (props) => {
  let object: any = {};
  if (!props.legend) {
    object.legend = false;
  }
  return (
    <>
      {/* @ts-expect-error Server Component */}
      <Radar
        options={{
          ...object,
          scale: {
            ticks: {
              min: props.min ? props.min : 0,
              max: props.max ? props.max : undefined,
              precision: 0,
            },
          },
        }}
        data={{
          ...props.data,
          datasets: props.data.datasets.map((x) => {
            return myDefaultObject<any>(x, {
              backgroundColor: "rgba(91, 185, 193, 0.2)",
              borderColor: "#0b243c",
              borderWidth: 2,
              label: "Non défini",
              hidden: true,
              animation: false,
            });
          }),
        }}
      />
    </>
  );
};

GenericRadar.defaultProps = {
  legend: true,
};

export default GenericRadar;
