import React from "react";
import FullField from "../../../Fields/FullField";
import MyEditor from "../../../Library/Inputs/MyEditor";
import { Grid } from "@mui/material";
import {
  AdditionnalCondition,
  ConditionalConfirmMessage,
  IField,
  IForm,
} from "../../../../types/FormTypes";
import { Delete, Edit } from "@mui/icons-material";
import AddButton from "../../../Library/Buttons/AddButton/AddButton";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../../../pages/ChatbotLexPage";
import { nanoid } from "nanoid";
import {
  GenericLongText,
  GenericTextInput,
} from "../../../../pages/CreateOrEditChatbotLexPage";
import { MyConditionsHandler } from "../../PDF/CreateOrEditPDFQuestionnaire";
import { ConseilReponseAfterParsing } from "../../../../pages/ConseilViewPage";
import { GenericSelect } from "../../../../pages/QuestionnairePage";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import TextInput from "../../../Library/Inputs/TextInput";

const initAdditionnalCondition = (): AdditionnalCondition => {
  return {
    conditions: [],
    type: "and",
    id: nanoid(),
    title: "",
  };
};

const AdditionnalConditionCreateOrEdit: React.FC<{
  additionnalCondition?: AdditionnalCondition;
  isEdit?: boolean;
  questionnaireData?: Partial<IForm>;
  onSubmit: (newAdditionnalCondition: AdditionnalCondition) => void;
  onClose?: () => void;
}> = (props) => {
  const [additionnalCondition, setAdditionnalCondition] =
    React.useState<AdditionnalCondition>(undefined);

  React.useEffect(() => {
    if (props.isEdit) {
      setAdditionnalCondition(props.additionnalCondition);
    } else {
      setAdditionnalCondition(initAdditionnalCondition());
    }
  }, [props.isEdit, props.additionnalCondition]);

  if (!additionnalCondition) {
    return <></>;
  }
  return (
    <GenericFromPopup
      addCloseSecurity={true}
      title={{
        leftPart: props.isEdit ? "Modifier" : "Créer",
        rightPart: "ma condition supplémentaire",
      }}
      onClose={props.onClose}
    >
      {additionnalCondition && (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="20px"
        >
          <Grid item display="grid">
            <GenericTextInput
              isMandatory={false}
              placeholder="Sans titre"
              fieldTitle="Titre"
              id="condition-title"
              value={additionnalCondition.title}
              onChange={(e) => {
                setAdditionnalCondition((x) => {
                  return { ...x, title: e?.target?.value };
                });
              }}
            />
          </Grid>
          <Grid item display="grid">
            <MyConditionsHandler
              conditionType={additionnalCondition?.type}
              conditions={additionnalCondition?.conditions}
              updateConditionType={(value) => {
                setAdditionnalCondition((state) => {
                  return {
                    ...state,
                    type: value,
                  };
                });
              }}
              deleteConditionByIndex={(index) => {
                setAdditionnalCondition((state) => {
                  return {
                    ...state,
                    conditions: state.conditions.filter((x, i) => index !== i),
                  };
                });
              }}
              addCondition={(condition) => {
                setAdditionnalCondition((x) => {
                  return { ...x, conditions: [...x.conditions, condition] };
                });
              }}
              questionnaireData={props.questionnaireData}
            />
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noCondition: "Aucune condition n'a été ajoutée.",
              }}
              errorSelector={{
                noCondition: "#add-cond-button",
              }}
              invalidConditions={{
                noCondition:
                  !additionnalCondition?.conditions ||
                  additionnalCondition?.conditions?.length == 0,
              }}
              onSendClick={() => {
                props.onSubmit(additionnalCondition);
              }}
              isLoading={false}
              textButton={props.isEdit ? "Modifier" : "Créer"}
            />
          </Grid>
        </Grid>
      )}
    </GenericFromPopup>
  );
};

export const getCondTypes = () => {
  return [
    {
      value: "=",
      label: "Égal à",
    },
    {
      value: "!=",
      label: "Différent de",
    },
    {
      value: ">",
      label: "Supérieur à",
    },
    {
      value: ">=",
      label: "Supérieur ou égal à",
    },
    {
      value: "<=",
      label: "Inférieur ou égal à",
    },
    {
      value: "<",
      label: "Inférieur à",
    },
    {
      value: "contains",
      label: "Contient",
    },
  ];
};

export const translateCondType = (type: string) => {
  const converter = {
    "=": "Égal à",
    "!=": "Différent de",
    ">": "Supérieur à",
    "<": "Inférieur à",
    "<=": "Inférieur ou égal à",
    ">=": "Supérieur ou égal à",
    contains: "Contient",
  };
  if (type in converter) {
    return converter[type];
  }
  return type;
};

export const getFieldTitleByFieldId = (
  questionnaireData: Partial<IForm>,
  fieldId: string
): string => {
  const questionnaireDataTmp = { ...questionnaireData };

  for (let i = 0; i < questionnaireData?.sections?.length; i++) {
    for (let j = 0; j < questionnaireData?.sections[i]?.fields?.length; j++) {
      for (
        let k = 0;
        k < questionnaireData?.sections[i]?.fields[j]?.length;
        k++
      ) {
        if (questionnaireDataTmp?.sections[i]?.fields[j][k]?.id === fieldId) {
          return questionnaireDataTmp?.sections[i]?.fields[j][k]?.title;
        }
      }
    }
  }
  return "";
};

export const getFieldByFieldId = (
  questionnaireData: Partial<IForm>,
  fieldId: string
): Partial<IField> => {
  const questionnaireDataTmp = { ...questionnaireData };

  for (let i = 0; i < questionnaireData?.sections?.length; i++) {
    for (let j = 0; j < questionnaireData?.sections[i]?.fields?.length; j++) {
      for (
        let k = 0;
        k < questionnaireData?.sections[i]?.fields[j]?.length;
        k++
      ) {
        if (questionnaireDataTmp?.sections[i]?.fields[j][k]?.id === fieldId) {
          return questionnaireDataTmp?.sections[i]?.fields[j][k];
        }
      }
    }
  }
  return undefined;
};

export const DisplayStopMessage: React.FC<any> = (props) => {
  const { questionnaireData, setQuestionnaireData } = props;

  return (
    <FullField title="Message affiché après l'arrêt" isMandatory={false}>
      <div
        style={{
          width: "100%",
        }}
      >
        <MyEditor
          defaultFontFamily="Poppins"
          defaultFontSize="16px"
          defaultColor="#0B243C"
          initialContent={questionnaireData?.stopMessage}
          onUpdate={(html) => {
            const questionnaireDataTmp = { ...questionnaireData };
            questionnaireDataTmp.stopMessage = html;
            setQuestionnaireData(questionnaireDataTmp);
          }}
        />
      </div>
    </FullField>
  );
};

const DisplayConfirmMessage: React.FC<{
  questionnaireData: Partial<IForm>;
  setQuestionnaireData: React.Dispatch<React.SetStateAction<Partial<IForm>>>;
  favoriteConseils: ConseilReponseAfterParsing[];
}> = (props) => {
  const initialConditionalConfirmMessage: ConditionalConfirmMessage = {
    id: nanoid(),
    conditions: [],
    message: "Votre réponse a bien été enregistrée.",
    title: "Titre de la condition principale",
    type: "and",
    redirectUrl: "",
    conseilId: undefined,
    additionnalConditions: [],
    redirectInstant: false,
    resendDelay: 30,
    resendFormActive: false,
    resendSMSContent: "Bonjour {nom_patient} {prenom_patient},\nVoici le questionnaire : {lien_questionnaire}",
  };
  const [addConditionalMessageOpen, setAddConditionalMessageOpen] =
    React.useState<{
      isOpen: boolean;
      value: ConditionalConfirmMessage;
      isEdit: boolean;
      editID?: string;
    }>({
      isOpen: false,
      value: initialConditionalConfirmMessage,
      isEdit: false,
      editID: undefined,
    });
  const [defaultEditOpen, setDefaultEditOpen] = React.useState<{
    isOpen: boolean;
    value: string;
    redirectURL: string;
    redirectInstant: boolean;
    conseilId: string;
  }>({
    isOpen: false,
    value: undefined,
    redirectURL: undefined,
    redirectInstant: false,
    conseilId: undefined,
  });
  const [openCreateAdditionnalCondition, setOpenCreateAdditionnalCondition] =
    React.useState<boolean>(false);
  const [openEditAdditionnalCondition, setOpenEditAdditionnalCondition] =
    React.useState<AdditionnalCondition>(undefined);
  const { questionnaireData, setQuestionnaireData } = props;
  const resetPopup = () => {
    setAddConditionalMessageOpen({
      isOpen: false,
      value: { ...initialConditionalConfirmMessage, id: nanoid() },
      isEdit: false,
      editID: undefined,
    });
  };

  return (
    <>
      {openCreateAdditionnalCondition && (
        <AdditionnalConditionCreateOrEdit
          onSubmit={(newAdditionnalCondition) => {
            setAddConditionalMessageOpen((state) => {
              return {
                ...state,
                value: {
                  ...state.value,
                  additionnalConditions: !state.value.additionnalConditions
                    ? [newAdditionnalCondition]
                    : [
                        ...state.value.additionnalConditions,
                        newAdditionnalCondition,
                      ],
                },
              };
            });
            setOpenCreateAdditionnalCondition(false);
          }}
          additionnalCondition={undefined}
          isEdit={false}
          onClose={() => setOpenCreateAdditionnalCondition(false)}
          questionnaireData={questionnaireData}
        />
      )}
      {openEditAdditionnalCondition && (
        <AdditionnalConditionCreateOrEdit
          onSubmit={(newAdditionnalCondition) => {
            setAddConditionalMessageOpen((x) => {
              return {
                ...x,
                value: {
                  ...x.value,
                  additionnalConditions: x.value.additionnalConditions.map(
                    (y) => {
                      if (y.id === newAdditionnalCondition.id) {
                        return newAdditionnalCondition;
                      }
                      return y;
                    }
                  ),
                },
              };
            });
            setOpenEditAdditionnalCondition(undefined);
          }}
          additionnalCondition={openEditAdditionnalCondition}
          isEdit={true}
          onClose={() => setOpenEditAdditionnalCondition(undefined)}
          questionnaireData={questionnaireData}
        />
      )}
      {addConditionalMessageOpen.isOpen === true && (
        <GenericFromPopup
          onClose={() => resetPopup()}
          title={
            addConditionalMessageOpen.isEdit
              ? { leftPart: "Modifier", rightPart: "mon message" }
              : { leftPart: "Ajouter", rightPart: "un message" }
          }
        >
          <Grid
            container
            direction="column"
            padding="10px"
            wrap="nowrap"
            gap="10px"
          >
            <Grid item display="grid">
              <GenericTextInput
                fieldTitle="Titre"
                id="add-conditional-title"
                isMandatory={false}
                onChange={(e) => {
                  setAddConditionalMessageOpen((state) => {
                    return {
                      ...state,
                      value: { ...state.value, title: e.target.value },
                    };
                  });
                }}
                value={addConditionalMessageOpen.value.title}
              />
            </Grid>
            <Grid item display="grid">
              <MyConditionsHandler
                conditions={addConditionalMessageOpen?.value?.conditions}
                conditionType={addConditionalMessageOpen?.value?.type}
                addCondition={(condition) => {
                  setAddConditionalMessageOpen((state) => {
                    return {
                      ...state,
                      value: {
                        ...state.value,
                        conditions: [...state.value.conditions, condition],
                      },
                    };
                  });
                }}
                updateConditionType={(value) => {
                  setAddConditionalMessageOpen((state) => {
                    return {
                      ...state,
                      value: {
                        ...state.value,
                        type: value,
                      },
                    };
                  });
                }}
                deleteConditionByIndex={(index) => {
                  setAddConditionalMessageOpen((state) => {
                    return {
                      ...state,
                      value: {
                        ...state.value,
                        conditions: state.value.conditions.filter(
                          (x, i) => index !== i
                        ),
                      },
                    };
                  });
                }}
                questionnaireData={questionnaireData}
              />
            </Grid>
            <Grid item display="grid" marginTop="10px">
              <AddButton
                onClick={() => {
                  setOpenCreateAdditionnalCondition(true);
                }}
                addId={"add-condition-button"}
                displayText={true}
                text="Ajouter une condition supplémentaire"
              />
            </Grid>
            <Grid item display="grid" marginBottom="10px">
              {addConditionalMessageOpen?.value?.additionnalConditions &&
              addConditionalMessageOpen?.value?.additionnalConditions?.length >
                0 ? (
                <Grid container direction="row" gap="10px">
                  {addConditionalMessageOpen.value.additionnalConditions.map(
                    (condition, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            style={{
                              border: "1px solid rgba(11, 36, 60)",
                              backgroundColor: "rgba(247, 252, 252, 1)",
                              boxShadow: "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                            }}
                            paddingLeft="7px"
                            paddingRight="7px"
                            borderRadius="10px"
                          >
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                              alignItems="center"
                            >
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  color: "#0B243C",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {condition?.title
                                  ? condition.title
                                  : "Sans titre"}
                              </span>
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Edit
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenEditAdditionnalCondition(condition);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Delete
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setAddConditionalMessageOpen((state) => {
                                    return {
                                      ...state,
                                      value: {
                                        ...state.value,
                                        additionnalConditions:
                                          state.value.additionnalConditions.filter(
                                            (x) => x?.id !== condition?.id
                                          ),
                                      },
                                    };
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              ) : (
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "#0B243C",
                    textAlign: "end",
                  }}
                >{`Aucune condition supplémentaire n'a été ajoutée`}</span>
              )}
            </Grid>
            <Grid item display="grid">
              <FullField title="Message de confirmation" isMandatory={false}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <MyEditor
                    defaultFontFamily="Poppins"
                    defaultFontSize="16px"
                    defaultColor="#0B243C"
                    initialContent={addConditionalMessageOpen.value.message}
                    onUpdate={(html) => {
                      setAddConditionalMessageOpen((x) => {
                        return { ...x, value: { ...x.value, message: html } };
                      });
                    }}
                  />
                </div>
              </FullField>
            </Grid>

            <Grid item display="grid">
              <GenericSelect
                fieldTitle="Conseil à envoyer (parmi mes favoris)"
                maxMenuHeight="150px"
                placeholder="Ne pas envoyer de conseil"
                id="conseil-to-send"
                onChange={(e) => {
                  setAddConditionalMessageOpen((x) => {
                    return {
                      ...x,
                      value: {
                        ...x.value,
                        conseilId: e?.value,
                      },
                    };
                  });
                }}
                options={props.favoriteConseils.map((x) => {
                  return { label: x.title, value: x.id };
                })}
                value={
                  [
                    props.favoriteConseils.find(
                      (x) =>
                        x?.id?.toString() ==
                        addConditionalMessageOpen?.value?.conseilId
                    ),
                  ].map((x) => {
                    if (!x) {
                      return undefined;
                    }
                    return { label: x.title, value: x.id };
                  })[0]
                }
                isClearable={true}
              />
            </Grid>
            <Grid item display="grid">
              <GenericTextInput
                fieldTitle={`Rediriger vers un lien web (${
                  addConditionalMessageOpen?.value?.redirectInstant
                    ? "instantanément"
                    : "après 5 secondes"
                })`}
                id="add-conditional-redirect"
                isMandatory={false}
                onChange={(e) => {
                  setAddConditionalMessageOpen((state) => {
                    return {
                      ...state,
                      value: {
                        ...state.value,
                        redirectUrl: e.target.value?.replaceAll(" ", ""),
                      },
                    };
                  });
                }}
                value={addConditionalMessageOpen.value.redirectUrl}
              />
            </Grid>
            <Grid item display="grid">
              <OrangeCheckBox
                onClick={() => {
                  setAddConditionalMessageOpen((state) => {
                    return {
                      ...state,
                      value: {
                        ...state.value,
                        redirectInstant: state?.value?.redirectInstant
                          ? false
                          : true,
                      },
                    };
                  });
                }}
                value={
                  addConditionalMessageOpen?.value?.redirectInstant
                    ? true
                    : false
                }
                text="Rediriger instantanément"
              />
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing="10px"
              >
                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={() => {
                      setAddConditionalMessageOpen((state) => {
                        return {
                          ...state,
                          value: {
                            ...state.value,
                            resendFormActive: state.value?.resendFormActive
                              ? false
                              : true,
                          },
                        };
                      });
                    }}
                    value={
                      addConditionalMessageOpen?.value?.resendFormActive
                        ? true
                        : false
                    }
                    text=""
                  />
                </Grid>
                <Grid item display="grid">
                  <span className="text_champs">Renvoyer ce questionnaire</span>
                </Grid>
                <Grid item display="grid">
                  <div style={{ maxWidth: "65px" }}>
                    <TextInput
                      placeholder="0"
                      value={addConditionalMessageOpen?.value?.resendDelay}
                      onChange={(e) => {
                        let parsed = parseInt(e.target.value);
                        if (isNaN(parsed)) {
                          parsed = undefined;
                        }
                        setAddConditionalMessageOpen((state) => {
                          return {
                            ...state,
                            value: {
                              ...state.value,
                              resendDelay: parsed,
                            },
                          };
                        });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item display="grid">
                  <span className="text_champs">jours après la réponse</span>
                </Grid>
              </Grid>
            </Grid>
            {addConditionalMessageOpen?.value?.resendFormActive && (
              <Grid item display="grid">
                <GenericLongText
                  usableVariables={["nom_patient", "prenom_patient", "lien_questionnaire"]}
                  fieldTitle="Contenu du SMS"
                  isMandatory={false}
                  placeholder="Aucun"
                  value={addConditionalMessageOpen?.value?.resendSMSContent}
                  id={`resend-sms-content-message-input`}
                  maxLength={325}
                  onChange={(e) => {
                    setAddConditionalMessageOpen((x) => {
                      let resendSMSContent = "";
                      if (e && typeof e === "string") {
                        resendSMSContent = e;
                      }
                      return {
                        ...x,
                        value: {
                          ...x.value,
                          resendSMSContent: resendSMSContent,
                        }
                      }
                    });
                  }}
                />
              </Grid>
            )}
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{
                  noCondition:
                    "Vous devez ajouter au minimum une condition principale",
                  "": "",
                }}
                errorSelector={{
                  noCondition: "#add-cond-button",
                  "": "",
                }}
                invalidConditions={{
                  noCondition:
                    addConditionalMessageOpen.value.conditions?.length == 0,
                  "": "",
                }}
                onSendClick={() => {
                  /* Create */
                  if (addConditionalMessageOpen.isEdit !== true) {
                    setQuestionnaireData((state) => {
                      const arrBefore = state?.conditionalConfirmMessages
                        ? state.conditionalConfirmMessages
                        : [];
                      return {
                        ...state,
                        conditionalConfirmMessages: [
                          ...arrBefore,
                          addConditionalMessageOpen.value,
                        ],
                      };
                    });
                  }
                  if (addConditionalMessageOpen.isEdit === true) {
                    setQuestionnaireData((state) => {
                      const indexFounded =
                        state.conditionalConfirmMessages.findIndex(
                          (x) => x.id === addConditionalMessageOpen.editID
                        );
                      return {
                        ...state,
                        conditionalConfirmMessages:
                          state.conditionalConfirmMessages.map((x, i) =>
                            i === indexFounded
                              ? addConditionalMessageOpen.value
                              : x
                          ),
                      };
                    });
                  }
                  resetPopup();
                }}
                textButton={
                  addConditionalMessageOpen.isEdit ? "Modifier" : "Ajouter"
                }
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      {defaultEditOpen.isOpen === true && (
        <GenericFromPopup
          onClose={() =>
            setDefaultEditOpen({
              isOpen: false,
              value: undefined,
              redirectURL: undefined,
              redirectInstant: false,
              conseilId: undefined,
            })
          }
          title={{ leftPart: "Modifier", rightPart: "mon message" }}
        >
          <Grid
            container
            direction="column"
            padding="10px"
            wrap="nowrap"
            gap="10px"
          >
            <Grid item display="grid">
              <FullField
                title="Message de confirmation par défaut"
                isMandatory={false}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <MyEditor
                    defaultFontFamily="Poppins"
                    defaultFontSize="16px"
                    defaultColor="#0B243C"
                    initialContent={defaultEditOpen?.value}
                    onUpdate={(html) => {
                      setDefaultEditOpen((x) => {
                        return { ...x, value: html };
                      });
                    }}
                  />
                </div>
              </FullField>
            </Grid>
            <Grid item display="grid">
              <GenericSelect
                fieldTitle="Conseil à envoyer (parmi mes favoris)"
                maxMenuHeight="150px"
                placeholder="Ne pas envoyer de conseil"
                id="conseil-to-send"
                onChange={(e) => {
                  setDefaultEditOpen((x) => {
                    return {
                      ...x,
                      conseilId: e?.value,
                    };
                  });
                }}
                options={props.favoriteConseils.map((x) => {
                  return { label: x.title, value: x.id };
                })}
                value={
                  [
                    props.favoriteConseils.find(
                      (x) => x?.id?.toString() == defaultEditOpen?.conseilId
                    ),
                  ].map((x) => {
                    if (!x) {
                      return undefined;
                    }
                    return { label: x.title, value: x.id };
                  })[0]
                }
                isClearable={true}
              />
            </Grid>
            <Grid item display="grid">
              <GenericTextInput
                fieldTitle={`Rediriger vers un lien web (${
                  defaultEditOpen.redirectInstant
                    ? "instantanément"
                    : "après 5 secondes"
                })`}
                id="add-default-redirect"
                isMandatory={false}
                onChange={(e) => {
                  setDefaultEditOpen((state) => {
                    return {
                      ...state,
                      redirectURL: e.target.value?.replaceAll(" ", ""),
                    };
                  });
                }}
                value={defaultEditOpen.redirectURL}
              />
            </Grid>
            <Grid item display="grid">
              <OrangeCheckBox
                onClick={() => {
                  setDefaultEditOpen((state) => {
                    return {
                      ...state,
                      redirectInstant: state.redirectInstant ? false : true,
                    };
                  });
                }}
                value={defaultEditOpen?.redirectInstant ? true : false}
                text="Rediriger instantanément"
              />
            </Grid>
            <Grid item display="grid">
              <GenericSendButton
                textButton="Valider"
                errorMessages={{}}
                errorSelector={{}}
                invalidConditions={{}}
                onSendClick={() => {
                  setQuestionnaireData((state) => {
                    return {
                      ...state,
                      confirmMessage: defaultEditOpen.value,
                      defaultRedirectURL: defaultEditOpen.redirectURL,
                      defaultRedirectInstant: defaultEditOpen?.redirectInstant,
                      defaultConseilId: defaultEditOpen.conseilId,
                    };
                  });
                  setDefaultEditOpen({
                    isOpen: false,
                    value: undefined,
                    redirectURL: undefined,
                    redirectInstant: false,
                    conseilId: undefined,
                  });
                }}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <FullField
        title="Message de confirmation, envoi de conseils, renvoi de questionnaire et redirection"
        isMandatory={false}
      >
        <Grid container direction="row" gap="10px">
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              wrap="nowrap"
              style={{
                border: "1px solid rgba(11, 36, 60)",
                backgroundColor: "rgba(247, 252, 252, 1)",
                boxShadow: "3px 3px 2px 1px rgba(11, 36, 60, .3)",
              }}
              paddingLeft="7px"
              paddingRight="7px"
              borderRadius="10px"
            >
              <Grid
                item
                display="grid"
                paddingLeft="5px"
                paddingRight="5px"
                alignItems="center"
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                    color: "#0B243C",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  {"Par défaut"}
                </span>
              </Grid>
              <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
                <div
                  style={{
                    height: "100%",
                    borderLeft: "1px solid #0B243C",
                  }}
                />
              </Grid>
              <Grid item display="grid" alignItems="center">
                <Edit
                  style={{ color: "#0B243C", cursor: "pointer" }}
                  onClick={() => {
                    setDefaultEditOpen({
                      isOpen: true,
                      value: questionnaireData?.confirmMessage,
                      redirectURL: questionnaireData?.defaultRedirectURL,
                      redirectInstant:
                        questionnaireData?.defaultRedirectInstant,
                      conseilId: questionnaireData?.defaultConseilId,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {questionnaireData?.conditionalConfirmMessages &&
            questionnaireData.conditionalConfirmMessages.map((cond, index) => {
              return (
                <Grid item display="grid" key={index}>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    style={{
                      border: "1px solid rgba(11, 36, 60)",
                      backgroundColor: "rgba(247, 252, 252, 1)",
                      boxShadow: "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                    }}
                    paddingLeft="7px"
                    paddingRight="7px"
                    borderRadius="10px"
                  >
                    <Grid
                      item
                      display="grid"
                      paddingLeft="5px"
                      paddingRight="5px"
                      alignItems="center"
                    >
                      <span
                        style={{
                          fontFamily: "Poppins",
                          color: "#0B243C",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        {cond.title ? cond.title : "Sans titre"}
                      </span>
                    </Grid>
                    <Grid
                      item
                      display="grid"
                      paddingLeft="5px"
                      paddingRight="5px"
                    >
                      <div
                        style={{
                          height: "100%",
                          borderLeft: "1px solid #0B243C",
                        }}
                      />
                    </Grid>
                    <Grid item display="grid" alignItems="center">
                      <Edit
                        style={{ color: "#0B243C", cursor: "pointer" }}
                        onClick={() => {
                          setAddConditionalMessageOpen({
                            isEdit: true,
                            isOpen: true,
                            value: cond,
                            editID: cond.id,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      display="grid"
                      paddingLeft="5px"
                      paddingRight="5px"
                    >
                      <div
                        style={{
                          height: "100%",
                          borderLeft: "1px solid #0B243C",
                        }}
                      />
                    </Grid>
                    <Grid item display="grid" alignItems="center">
                      <Delete
                        style={{ color: "#0B243C", cursor: "pointer" }}
                        onClick={() => {
                          setQuestionnaireData((state) => {
                            return {
                              ...state,
                              conditionalConfirmMessages:
                                state.conditionalConfirmMessages.filter(
                                  (x) => x.id !== cond.id
                                ),
                            };
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          <Grid item display="grid">
            <AddButton
              addId="form-message-confirm-add"
              displayText={false}
              text="Ajouter un message de confirmation"
              onClick={() => {
                setAddConditionalMessageOpen({
                  isOpen: true,
                  value: { ...initialConditionalConfirmMessage, id: nanoid() },
                  isEdit: false,
                  editID: undefined,
                });
              }}
            />
          </Grid>
        </Grid>
      </FullField>
    </>
  );
};

export default DisplayConfirmMessage;
