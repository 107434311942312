import { Grid } from "@mui/material";
import React from "react";
import makeAPIRequest from "../../utils/makeAPIRequest";
import FullField from "../Fields/FullField";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import TextInput from "../Library/Inputs/TextInput";
import { openSnackbar } from "../../redux/snackbarReducer";
import { useDispatch } from "react-redux";
import ErrorHelp from "../Library/Errors/ErrorHelp";

const errorMessages = {
  invalideUrl: "Le lien du questionnaire est invalide",
  "": "",
};

const errorSelector = {
  invalideUrl: "#questionnaires-link-check",
  "": "",
};

const DuplicatePopup: React.FC<any> = (props) => {
  const { setIsDuplicateOpen, setQuestionnaires, setIsLoading } = props;
  const [linkQuestionnaire, setLinkQuestionnaire] = React.useState("");
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();

  const executeErrorRequest = () => {
    setIsLoading(false);
    dispatch(
      openSnackbar({
        message: "Le questionnaire n'a pas pu être importé",
        type: "error",
        duration: 3000,
      })
    );
  }

  const isValidSubmit = () => {
    const invalidConditions = {
      invalideUrl: !linkQuestionnaire.includes("/#/q/"),
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  return (
    <FormPopup>
      <Grid item display='grid' paddingTop='40px' paddingLeft='50px' paddingRight='50px' paddingBottom='40px'>
        <FormPopupHeader
          firstTitle={'Dupliquer'}
          secondTitle={'un questionnaire'}
          onClose={() => {
            setIsDuplicateOpen(false);
          }} />
      </Grid>
      <>
        <Grid item display='grid' width='70%' justifySelf='center' direction='column' wrap="nowrap">
          <Grid container display='flex' width='70%' justifySelf='center' direction='column' wrap="nowrap" paddingBottom='40px'>
            <Grid item display='grid'>
              <FullField isMandatory={false} title='Lien du questionnaire'>
                <TextInput
                  id="questionnaires-link-check"
                  value={linkQuestionnaire}
                  placeholder="https://app.certipair.fr/#/q/..."
                  onChange={(e) => {
                    setLinkQuestionnaire(e.target.value);
                  }}
                />
              </FullField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          <Grid container direction="column" paddingTop='10px' paddingBottom='40px' wrap="nowrap">
            {error?.length > 0 && linkQuestionnaire.length > 0 && (
              <Grid item display="grid">
                <ErrorHelp
                  errorMessages={errorMessages}
                  errorSelector={errorSelector}
                  error={error}
                />
              </Grid>
            )}
            <Grid item display="grid" justifyContent="center">
              {isValidSubmit() ? (
                <OrangeButton
                  animation={true}
                  enable={true}
                  type="submit"
                  text={"Valider"}
                  onClick={() => {
                    makeAPIRequest("post", "/questions/form/create_from_url", { url: linkQuestionnaire }, "v3")
                      .then(() => {
                        makeAPIRequest("get", "/questions/forms/list", null, "v3")
                          .then((res) => {
                            setQuestionnaires(res?.data);
                            setIsLoading(false);
                            setIsDuplicateOpen(false);
                            dispatch(
                              openSnackbar({
                                message: "Le questionnaire a bien été importé",
                                type: "success",
                                duration: 3000,
                              })
                            );
                          })
                          .catch(() => executeErrorRequest());
                      })
                      .catch(() => executeErrorRequest());
                  }}
                />
              ) : (
                <OrangeButton
                  animation={false}
                  enable={false}
                  type="submit"
                  text={"Envoyer"}
                  onClick={() => {
                    return null;
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    </FormPopup>
  );
};

export default DuplicatePopup;
