import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import {
  ForwardToInboxOutlined,
  MarkEmailReadOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useHistory } from "react-router";

interface IMyMenuItem {
  text: string;
  Icon: any;
  dividerEnd: boolean;
  onClick?: any;
  isBold?: boolean;
}

const MyMenuItem: React.FC<IMyMenuItem> = (props) => {
  const { text, dividerEnd, Icon, onClick, isBold } = props;

  return (
    <>
      <MenuItem onClick={onClick}>
        <Grid container direction="row" display="flex">
          <Grid item display="grid">
            <ListItemIcon>
              <Icon
                fontSize="small"
                style={{ color: "#0B243C", opacity: isBold ? 0.4 : 1 }}
              />
            </ListItemIcon>
          </Grid>
          <Grid item display="grid" alignItems="center">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                color: "#0B243C",
                letterSpacing: "0px",
                opacity: isBold ? 0.4 : 1,
              }}
            >
              {text}
            </span>
          </Grid>
        </Grid>
      </MenuItem>
      {dividerEnd && <Divider />}
    </>
  );
};

const AccountMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip style={{ padding: "0", margin: "0" }} title="Dérouler le menu">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <IconButton
              aria-label="menu"
              onClick={() => {}}
              sx={{ color: "#0B243C" }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MyMenuItem
          text="Mon compte"
          isBold={window.location.href.endsWith("profile")}
          onClick={() => history.push("/profile")}
          dividerEnd={true}
          Icon={PermIdentityOutlined}
        />
        <MyMenuItem
          text="Messages envoyés"
          isBold={window.location.href.endsWith("send_messages")}
          onClick={() => history.push("/send_messages")}
          dividerEnd={false}
          Icon={ForwardToInboxOutlined}
        />
        <MyMenuItem
          text="Messages programmés"
          isBold={window.location.href.endsWith("scheduled_conseils")}
          onClick={() => history.push("/scheduled_conseils")}
          dividerEnd={true}
          Icon={MarkEmailReadOutlined}
        />
        <MyMenuItem
          text="Se déconnecter"
          onClick={() => {
            if (localStorage.getItem("token") || localStorage.getItem("user")) {
              localStorage.removeItem("token");
              localStorage.removeItem("lastConnection");
              localStorage.removeItem("user");
              localStorage.removeItem("user_id");
              localStorage.removeItem("Patient");
              localStorage.removeItem("PatientSelected");
              localStorage.removeItem("adviceSelected");
              localStorage.removeItem("Chose");
              window.location.reload();
            }
          }}
          dividerEnd={false}
          Icon={Logout}
        />
      </Menu>
    </>
  );
};

export default AccountMenu;
