import { Grid } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { closeHelpInstallationVitale, nextInstallationStep, prevInstallationStep } from '../../redux/helpInstallationVitaleReducer';
import FormPopup from '../FormPopup/FormPopup';
import FormPopupHeader from '../FormPopup/FormPopupHeader';
import useAppSelector from '../../redux/useAppSelector';

const EndInstallation = () => {
    const dispatch = useDispatch();

    return (
        <FormPopup>
            <Grid item display='grid' paddingTop='40px' paddingLeft='50px' paddingRight='50px' paddingBottom='40px'>
                <FormPopupHeader
                    firstTitle={'Installation'}
                    secondTitle={`terminée`}
                    onClose={() => {
                        dispatch(closeHelpInstallationVitale());
                    }} />
            </Grid>
            <Grid item display='grid'>
                <Grid container display='flex' width='70%' justifySelf='center' direction='column' wrap="nowrap" paddingBottom='40px'>
                    <Grid item display='grid'>
                        <span
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#0B243C',
                                textAlign: 'center',
                            }}>
                            L'installation est terminée
                        </span>
                    </Grid>
                    <Grid item display='grid'>
                        <span
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#0B243C',
                                textAlign: 'center',
                            }}>
                            Vous pouvez fermer cette fenêtre
                        </span>
                    </Grid>
                    <Grid item display='grid' alignSelf='flex-end' paddingTop='30px'>
                        <span
                            onClick={() => dispatch(closeHelpInstallationVitale())}
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#0B243C',
                                cursor: 'pointer',
                            }}>
                            Terminer {'>'}
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </FormPopup>
    )
}

const WelcomeInstallation = () => {
    const dispatch = useDispatch();

    return (
        <FormPopup>
            <Grid item display='grid' paddingTop='40px' paddingLeft='50px' paddingRight='50px' paddingBottom='40px'>
                <FormPopupHeader
                    firstTitle={'Installation'}
                    secondTitle={`requise`}
                    onClose={() => {
                        dispatch(closeHelpInstallationVitale());
                    }} />
            </Grid>
            <Grid item display='grid'>
                <Grid container display='flex' width='70%' justifySelf='center' direction='column' wrap="nowrap" paddingBottom='40px'>
                    <Grid item display='grid'>
                        <span
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#0B243C',
                                textAlign: 'center',
                            }}>
                            Pour importer des cartes Vitale, vous avez besoin d'effectuer une installation rapide
                        </span>
                    </Grid>
                    <Grid item display='grid'>
                        <span
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#0B243C',
                                textAlign: 'center',
                            }}>
                            Pour débuter l'installation guidée, veuillez cliquer sur continuer
                        </span>
                    </Grid>
                    <Grid item display='grid' alignSelf='flex-end' paddingTop='30px'>
                        <span
                            onClick={() => dispatch(nextInstallationStep())}
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#0B243C',
                                cursor: 'pointer',
                            }}>
                            Continuer {'>'}
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </FormPopup>
    )
}

const VitaleInstallation = () => {
    const vitaleInstallationReducer = useAppSelector(state => state.helpInstallationVitaleReducer)
    const dispatch = useDispatch();

    if (!vitaleInstallationReducer?.isOpen) {
        return (null);
    }
    if (vitaleInstallationReducer?.step === 0) {
        return (
            <WelcomeInstallation />
        )
    }
    if (vitaleInstallationReducer?.step === 5) {
        return (
            <EndInstallation />
        )
    }
    return (
        <FormPopup>
            <Grid item display='grid' paddingTop='40px' paddingLeft='50px' paddingRight='50px' paddingBottom='40px'>
                <FormPopupHeader
                    firstTitle={'Installation'}
                    secondTitle={`étape ${vitaleInstallationReducer.step}/4`}
                    onClose={() => {
                        dispatch(closeHelpInstallationVitale());
                    }} />
            </Grid>
            <Grid item display='grid'>
                <Grid container display='flex' width='70%' justifySelf='center' direction='column' wrap="nowrap" paddingBottom='40px'>
                    <Grid item display='grid'>
                        <span
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#0B243C',
                                textAlign: 'center',
                            }}>
                            {vitaleInstallationReducer?.step === 1 &&
                                <>
                                    Vous devez télécharger le fichier API LEC en <a href='/packages/api_lec-6.41.00.msi' target='_blank'>cliquant ici</a>
                                </>
                            }
                            {vitaleInstallationReducer?.step === 2 &&
                                <>
                                    Vous devez télécharger le fichier .NET Core 3.1.0 Runtime (x86), en <a href='/packages/dotnet-runtime-3.1.0-win-x86.exe' target='_blank'>cliquant ici</a>
                                </>
                            }
                            {vitaleInstallationReducer?.step === 3 &&
                                <>
                                    Vous devez télécharger le fichier Certipair Vitale Reader en <a href='/packages/Certipair-Vitale-Reader-v1.1.2.msi' target='_blank'>cliquant ici</a>
                                </>
                            }
                            {vitaleInstallationReducer?.step === 4 &&
                                <>
                                    Pour terminer, vous devez renseigner votre numéro RPPS
                                </>
                            }
                        </span>
                    </Grid>
                    <Grid item display='grid'>
                        <span
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Poppins',
                                color: '#0B243C',
                                textAlign: 'center',
                            }}>
                            {(vitaleInstallationReducer?.step === 1 || vitaleInstallationReducer?.step === 2 || vitaleInstallationReducer?.step === 3) &&
                                <>
                                    Une fois que c'est fait, lancez-le et suivez les étapes d'installation
                                </>
                            }
                            {vitaleInstallationReducer?.step === 4 &&
                                <>
                                    Ouvrez CertiPair Vitale Reader et cliquez sur 'Renseigner' ou 'Changer'
                                </>
                            }
                        </span>
                    </Grid>
                    {vitaleInstallationReducer?.step < 5 &&
                        <Grid item display='grid' paddingTop='30px'>
                            <Grid container direction='row' justifyContent='space-between'>
                                <Grid item display='grid'>
                                    <span
                                        onClick={() => dispatch(prevInstallationStep())}
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Poppins',
                                            color: '#0B243C',
                                            cursor: 'pointer',
                                        }}>
                                        {'<'} Retour
                                    </span>
                                </Grid>
                                <Grid item display='grid'>
                                    <span
                                        onClick={() => dispatch(nextInstallationStep())}
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Poppins',
                                            color: '#0B243C',
                                            cursor: 'pointer',
                                        }}>
                                        Continuer {'>'}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </FormPopup>
    );
}

export default VitaleInstallation;