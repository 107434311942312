interface IUtils {
  professions: any[],
  specialities: any[],
  civilities: any[],
  nni: any[],
  displaySpec: any[],
}

class UtilsClass {
  public utils?: IUtils;

  constructor() {
    this.utils = null;
  }

  public init = async () => {
    this.utils = await this.getFullObject();
  }

  public refresh = async () => {
    this.utils = await this.getFullObject();
  }

  public getFullObject = async () => {
    const newUtils: IUtils = {
      professions: null,
      specialities: null,
      civilities: null,
      displaySpec: null,
      nni: null,
    };
    await fetch(process.env.REACT_APP_API_URL + "/utils/language")
      .then((res) => res.json())
      .then((result) => {
        newUtils.professions = result?.data?.professions;
        newUtils.specialities = result?.data?.specialities;
        newUtils.displaySpec = result?.data?.displaySpec;
        const index = newUtils.specialities?.findIndex((element) => element?.id === '12786');
        if (index != -1) delete newUtils.specialities[index]
        newUtils.civilities = result?.data?.civilities;
        newUtils.civilities?.push({ id: '', name: '' });
        newUtils.nni = result?.data?.nni;
      });
    return (newUtils);
  };

  private sort = (array?: any[]) => {
    const sorted = array.sort((elementA, elementB) => {
      const first = elementA?.label?.toUpperCase();
      const second = elementB?.label?.toUpperCase();
      return (first < second) ? -1 : (first > second) ? 1 : 0;
    });
    return (sorted);
  }

  private transformToOptions = (array?: any[]) => {
    const options = [];

    for (const option of array) {
      let obj: any = {};
      if (option?.specialities) {
        obj.specialities = JSON.parse(option?.specialities);
      }
      obj.value = option?.id;
      obj.label = option?.name;
      options.push(obj);
    }
    return (options);
  }

  private transformToOptionsAndSort = (array?: any[]) => {
    const options = this.transformToOptions(array);
    return (this.sort(options));
  }

  public getProfessions = () => {
    return (this.utils?.professions);
  }

  public getNniTypes = () => {
    return (this.utils?.nni);
  }

  public getSpecialities = () => {
    return (this.utils?.specialities);
  }

  public getDisplaySpec = () => {
    return (this.utils?.displaySpec);
  }

  public getCivilities = () => {
    return (this.utils?.civilities);
  };

  public getNniTypesOptionsSorted = () => {
    return (this.transformToOptionsAndSort(this.getNniTypes()));
  }

  public getSpecialitiesOptionsSorted = () => {
    return (this.transformToOptionsAndSort(this.getSpecialities()));
  }

  public getCivilitiesOptionsSorted = () => {
    return (this.transformToOptionsAndSort(this.getCivilities()));
  }

  public getDisplaySpecOptionsSorted = () => {
    return (this.transformToOptionsAndSort(this.getDisplaySpec()));
  }

  public getProfessionsOptionsSorted = () => {
    return (this.transformToOptionsAndSort(this.getProfessions()));
  }
}

export default UtilsClass;
