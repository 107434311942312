import {
  AnalyticsRounded,
  DeleteRounded,
  EditRounded,
  PreviewRounded,
  ScheduleSendRounded,
  ShareRounded,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import * as React from "react";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router";
import CancellablePopup from "../../Library/Popups/CancellablePopup";
import { useDispatch } from "react-redux";
import { openShare } from "../../../redux/shareQuestionnaireReducer";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import { openSnackbar } from "../../../redux/snackbarReducer";
import { openSchedule } from "../../../redux/scheduleQuestionnaireReducer";
import { GenericFromPopup } from "../../../pages/ChatbotLexPage";
import GenericDoughnut from "../../Library/Draft/GenericDoughnut";
import { useOpenSnackbar } from "../../../pages/CreateOrEditChatbotLexPage";

interface GraphCircularFormStat {
  title: string;
  data: Array<{
    fieldId: string;
    label: string;
    value: number;
  }>;
}

export const StatisticsPopup: React.FC<{
  closePopup: () => void;
  formId: string | undefined;
  formTitle: string;
}> = (props) => {
  const [graphCircularFormStats, setGraphCircularFormStats] = React.useState<
    GraphCircularFormStat[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const snackbarUse = useOpenSnackbar();

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", `/forms/stats/${props.formId}/get_all`, null, "v3")
      .then((res) => {
        if (Array.isArray(res?.data)) {
          setGraphCircularFormStats(res.data);
        }
      })
      .catch((err) => {
        props.closePopup();
        snackbarUse.error("Impossible de récupérer les statistiques.", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.formId]);

  if (isLoading) {
    return <></>;
  }
  return (
    <GenericFromPopup
      onClose={props.closePopup}
      title={{
        leftPart: "Mes",
        rightPart: "Statistiques",
      }}
      subtitle={props.formTitle}
    >
      <Grid container direction={"column"} wrap="nowrap" padding="20px">
        {graphCircularFormStats?.length > 0 && (
          <Grid item display="grid">
            <Grid container direction="row" wrap="wrap" gap="20px">
              {graphCircularFormStats.filter((x) => x?.data?.length > 0).map((graph, index) => {
                return (
                  <Grid item display="grid" key={index}>
                    <GenericDoughnut
                      title={graph.title}
                      labels={graph.data.map((x) => x.label)}
                      data={graph.data.map((x) => x.value)}
                      displayLegend={false}
                      titleSize={18}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        {graphCircularFormStats?.length == 0 && (
          <span
            style={{
              fontSize: "16px",
              fontFamily: "Poppins",
              color: "#0B243c",
            }}
          >
            {"Aucune donnée n'est disponible pour ce questionnaire."}
          </span>
        )}
      </Grid>
    </GenericFromPopup>
  );
};

const DisplayQuestionnaire: React.FC<any> = (props) => {
  const [isDeletePopup, setIsDeletePopup] = React.useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { questionnaire, setQuestionnaires, questionnaires } = props;

  return (
    <>
      {isDeletePopup && (
        <CancellablePopup
          firstTitle="Mes"
          isLoading={isDeleteLoading}
          secondTitle="Questionnaires"
          messages={[
            `Voulez-vous vraiment supprimer le questionnaire '${questionnaire?.title}' ?`,
            "Toutes les réponses seront perdues",
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => setIsDeletePopup(false)}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest(
              "post",
              "/questions/form/delete",
              { form_id: questionnaire?.id },
              "v3"
            )
              .then((res) => {
                dispatch(
                  openSnackbar({
                    message: `Le questionnaire '${questionnaire?.title}' a bien été supprimé`,
                    type: "success",
                    duration: 3000,
                  })
                );
                const questionnairesTmp = [...questionnaires];
                const indexToDelete = questionnairesTmp?.findIndex(
                  (q) => q?.id === questionnaire?.id
                );
                if (indexToDelete !== -1)
                  delete questionnairesTmp[indexToDelete];
                const newQuestionnaires = questionnairesTmp?.filter(
                  (element) => element != undefined
                );
                setIsDeleteLoading(false);
                setIsDeletePopup(false);
                setQuestionnaires(newQuestionnaires);
              })
              .catch((err) => {
                dispatch(
                  openSnackbar({
                    message:
                      err?.response?.data?.error &&
                      err?.response?.data?.error != "Internal Server Error"
                        ? err.response.data.error
                        : `Le suppression du questionnaire '${questionnaire?.title}' a échouée`,
                    type: "error",
                    duration: 3000,
                  })
                );
                setIsDeleteLoading(false);
                setIsDeletePopup(false);
              });
          }}
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        wrap="nowrap"
        boxShadow="4px 4px 2px 1px rgba(11, 36, 60, .5)"
        border="1px solid rgb(11, 36, 60)"
        borderRadius="10px"
      >
        <Grid item display="grid" alignItems="center" paddingLeft="5px">
          <Tooltip title="Voir les réponses">
            <IconButton style={{ margin: "0", padding: "0" }}>
              <PreviewRounded
                fontSize="medium"
                style={{ color: "rgb(11, 36, 60)" }}
                onClick={() => {
                  history.push(`/questionnaire/${questionnaire?.id}/reponses`);
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
          <div
            style={{
              height: "100%",
              borderLeft: "1px solid #0B243C",
            }}
          />
        </Grid>
        <Grid item display="grid" alignItems="center">
          <Tooltip title="Partager">
            <IconButton style={{ margin: "0", padding: "0" }}>
              <ShareRounded
                fontSize="medium"
                onClick={() => {
                  dispatch(
                    openShare({
                      questionnaireId: questionnaire?.id,
                      title: questionnaire?.title,
                      sharePublicStats: questionnaire?.sharePublicStats ? true : false,
                    })
                  );
                }}
                style={{ color: "rgb(11, 36, 60)", cursor: "pointer" }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
          <div
            style={{
              height: "100%",
              borderLeft: "1px solid #0B243C",
            }}
          />
        </Grid>
        <Grid item display="grid" alignItems="center">
          <Tooltip title="Programmer un envoi">
            <IconButton style={{ margin: "0", padding: "0" }}>
              <ScheduleSendRounded
                fontSize="medium"
                onClick={() => {
                  dispatch(
                    openSchedule({
                      questionnaireId: questionnaire?.id,
                      title: questionnaire?.title,
                    })
                  );
                }}
                style={{ color: "rgb(11, 36, 60)", cursor: "pointer" }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
          <div
            style={{
              height: "100%",
              borderLeft: "1px solid #0B243C",
            }}
          />
        </Grid>
        <Grid item display="grid" maxWidth="300px">
          <Tooltip title="Ouvrir un lien public">
            <IconButton
              onClick={() =>
                window.open(`/#/q/${questionnaire?.id}`, "_blank").focus()
              }
              style={{ margin: "0", padding: "5px" }}
            >
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "rgb(11, 36, 60)",
                  wordBreak: "break-word",
                }}
              >
                {questionnaire?.title}
              </span>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          container
          direction="row"
          width="fit-content"
          gridAutoRows="fit-content"
          wrap="nowrap"
        >
          <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
            <div
              style={{
                height: "100%",
                borderLeft: "1px solid #0B243C",
              }}
            />
          </Grid>
          <Grid item display="grid" alignItems="center">
            <Tooltip title="Statistiques">
              <IconButton
                style={{ margin: "0", padding: "0" }}
                onClick={() => {
                  //setOpenStatistics(questionnaire?.id);
                }}
              >
                <AnalyticsRounded
                  fontSize="small"
                  style={{ color: "#0B243C" }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
            <div
              style={{
                height: "100%",
                borderLeft: "1px solid #0B243C",
              }}
            />
          </Grid>
          <Grid item display="grid" alignItems="center">
            <Tooltip title="Modifier">
              <IconButton
                style={{ margin: "0", padding: "0" }}
                onClick={() => {
                  history.push(`/edit_questionnaire/${questionnaire?.id}`);
                }}
              >
                <EditRounded fontSize="small" style={{ color: "#0B243C" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
            <div
              style={{
                height: "100%",
                borderLeft: "1px solid #0B243C",
              }}
            />
          </Grid>
          <Grid item display="grid" alignItems="center" paddingRight="5px">
            <Tooltip title="Supprimer">
              <IconButton style={{ margin: "0", padding: "0" }}>
                <DeleteRounded
                  onClick={() => {
                    setIsDeletePopup(true);
                  }}
                  fontSize="medium"
                  style={{ color: "rgb(11, 36, 60)" }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DisplayQuestionnaire;
