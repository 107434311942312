import React from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const GenericDoughnut: React.FC<{
  labels: string[];
  data: number[];
  title?: string;
  titleSize?: number;
  hoverBackgroundColor?: string[];
  backgroundColor?: string[];
  displayLegend?: boolean;
}> = (props) => {
  const isEmptyData = props.data.every((value) => value === 0);

  const data = {
    labels: isEmptyData ? ["Aucune donnée"] : props.labels,
    datasets: [
      {
        data: isEmptyData ? [1] : props.data,
        backgroundColor: isEmptyData ? ["#0B243C"] : props.backgroundColor,
        hoverBackgroundColor: isEmptyData
          ? ["#0B243C"]
          : props.hoverBackgroundColor,
      },
    ],
  };
  const options = {
    animation: {
      animateRotate: false,
      animateScale: false,
    },
    legend: {
      display: props.displayLegend,
    },
    title: {
      display: props?.title ? true : false,
      text: props?.title,
      fontSize: props.titleSize,
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (isEmptyData) {
            return "";
          }
          const dataset = ctx.chart.data.datasets[0];
          const total = dataset.data.reduce((acc, data) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1) + "%";
          if (percentage === "0.0%") {
            return "";
          }
          return percentage;
        },
        display: true,
        color: "white",
        animated: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  {/* @ts-expect-error Server Component */}
  return <Doughnut data={data} plugins={[ChartDataLabels]} options={options} />;
};

GenericDoughnut.defaultProps = {
  title: "",
  displayLegend: true,
  titleSize: 20,
  backgroundColor: [
    "#0B243C",
    "#ffa500",
    "#5bb9c1",
    "#374c80",
    "#7a5195",
    "#bc5090",
    "#ef5675",
    "#ff764a",
    "#006d77",
    "#3d5a80",
    "#98c1d9",
    "#003f5c",
    "#ec676a",
    "#fe8a71",
    "#ffab73",
    "#f6c0b8",
    "#ffdaab",
    "#004e64",
    "#138086",
    "#35b0ab",
    "#57c1ad",
    "#9cd6b0",
    "#c8e6c9",
    "#e0f0a6",
    "#eeff8b",
    "#ffa600",
    "#ffb86f",
    "#f7797d",
    "#b5b4c4",
    "#d3d3d3",
    "#4a4e4d",
    "#f2f4f4",
    "#7a7a7a",
    "#6e6e6e",
    "#2e3030",
    "#a1c5c9",
    "#b5c6cc",
    "#7a9bae",
    "#adcfef",
    "#cfd7dd",
    "#fffafa",
    "#f1fcff",
    "#f7f8f8",
    "#e6e8e8",
    "#d5d8dc",
    "#f8f9f9",
    "#f2f4f4",
    "#ebeced",
    "#e0e1e2",
    "#d2d4d4",
    "#c5c8c9",
  ],
  hoverBackgroundColor: [
    "#0B243C",
    "#ffa500",
    "#5bb9c1",
    "#003f5c",
    "#374c80",
    "#7a5195",
    "#bc5090",
    "#ef5675",
    "#ff764a",
    "#ffa600",
    "#006d77",
    "#3d5a80",
    "#98c1d9",
    "#ec676a",
    "#fe8a71",
    "#ffab73",
    "#f6c0b8",
    "#ffdaab",
    "#004e64",
    "#138086",
    "#35b0ab",
    "#57c1ad",
    "#9cd6b0",
    "#c8e6c9",
    "#e0f0a6",
    "#eeff8b",
    "#ffb86f",
    "#f7797d",
    "#b5b4c4",
    "#d3d3d3",
    "#4a4e4d",
    "#f2f4f4",
    "#7a7a7a",
    "#6e6e6e",
    "#2e3030",
    "#a1c5c9",
    "#b5c6cc",
    "#7a9bae",
    "#adcfef",
    "#cfd7dd",
    "#fffafa",
    "#f1fcff",
    "#f7f8f8",
    "#e6e8e8",
    "#d5d8dc",
    "#f8f9f9",
    "#f2f4f4",
    "#ebeced",
    "#e0e1e2",
    "#d2d4d4",
    "#c5c8c9",
  ],
};

export default GenericDoughnut;
