import { Grid } from "@mui/material";
import * as React from "react";
import BoxTitle from "../Library/Box/BoxTitle";
import ConfigGroupsPerPage from "./ConfigGroupsPerPage";
import GroupsNavigation from "./GroupsNavigation";
import CreateGroupPopup from "./CreateGroupPopup";
import CreateGroupButton from "./CreateGroupButton";
import DisplayHowManyGroups from "./DisplayHowManyGroups";
import GroupsListContent from "./GroupsListContent";

const AdminGroupsBox: React.FC<any> = (props) => {
  const [createGroupIsOpen, setCreateGroupIsOpen] = React.useState(false);
  const [editInfos, setEditInfos] = React.useState(null);

  return (
    <>
      {createGroupIsOpen && (
        <CreateGroupPopup
          editInfos={editInfos}
          setEditInfos={setEditInfos}
          setCreateGroupIsOpen={setCreateGroupIsOpen}
          {...props}
        />
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        minWidth="320px"
        id="admin-groups-box"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid container display="flex" direction="column" wrap="nowrap">
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing="10px"
              >
                <Grid item display="grid">
                  <BoxTitle first="Gestion" second="des groupes" />
                </Grid>
                <Grid item display="grid">
                  <CreateGroupButton
                    onClick={() => setCreateGroupIsOpen(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid" paddingTop="50px">
              <Grid container direction="column" wrap="nowrap">
                {!props.isLoading && (
                  <Grid
                    item
                    display="grid"
                    paddingTop="20px"
                    justifyContent="center"
                  >
                    <DisplayHowManyGroups {...props} />
                  </Grid>
                )}
                <Grid item display="grid" paddingTop="25px">
                  <GroupsListContent
                    editInfos={editInfos}
                    setEditInfos={setEditInfos}
                    createGroupIsOpen={createGroupIsOpen}
                    setCreateGroupIsOpen={setCreateGroupIsOpen}
                    {...props}
                  />
                </Grid>
                {props?.pages?.length > 0 && (
                  <Grid item display="grid" paddingTop="47px">
                    <Grid
                      container
                      direction="row"
                      spacing="10px"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item display="grid">
                        <ConfigGroupsPerPage {...props} />
                      </Grid>
                      <Grid item display="grid">
                        <GroupsNavigation {...props} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminGroupsBox;
