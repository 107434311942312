import * as React from 'react';
import OrangeButton from '../../Library/Buttons/OrangeButton/OrangeButton';

const DisableSendButtonItem = () => {
  return (
    <OrangeButton
      animation={true}
      enable={false}
      text='Envoyer' />
  );
}

export default DisableSendButtonItem;
