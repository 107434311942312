import React, { useState, useEffect, useRef } from "react";
import TutorialService from "../../services/TutorialService";
import EventEmitter from "../../services/event";
import moment from "moment";
import TriggerDateContainer from "./TriggerDateContainer/TriggerDateContainer";
import SendButtonItem from "./SendButtonItem/SendButtonItem";
import { Grid, IconButton, Tooltip } from "@mui/material";
import SelectedAdvice from "../../utils/SelectedAdvice";
import AdviceToSendContainer from "./AdviceToSendContainer/AdviceToSendContainer";
import MonPatientHeader from "./MonPatientHeader/MonPatientHeader";
import { ReactComponent as IconValide } from "../../assets/Icone_Valide.svg";
import { ClearRounded, EditRounded, Help } from "@mui/icons-material";
import BlueButton from "../Library/Buttons/BlueButton/BlueButton";
import { useDispatch } from "react-redux";
import { setIsPopup, setPatient } from "../../redux/sendAdviceReducer";
import * as selectPatientReducer from "../../redux/selectPatientReducer";
import RecipientContainerV2 from "./RecipientContainer/RecipientContainerV2";
import { openSnackbar } from "../../redux/snackbarReducer";
import CancellablePopup from "../Library/Popups/CancellablePopup";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import PatientAgreement from "../../utils/local_storage/PatientAgreement";
import CreateOrEditPatient from "../FormPopup/CreateOrEditPatient";
import makeAPIRequest from "../../utils/makeAPIRequest";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../pages/ChatbotLexPage";
import FullField from "../Fields/FullField";
import { genericGetFromTimestamp } from "../AdminGroups/CreateGroupPopup";
import useQuery from "../../utils/hooks/useQuery";
import isValidPhoneNumber from "../../utils/isValidPhoneNumber";
import IPatientData from "../../types/Patient";
import useAppSelector from "../../redux/useAppSelector";
import { ConseilReponseAfterParsing } from "../../pages/PatientQRCodePage";
import {
  GenericLongText,
  useOpenSnackbar,
} from "../../pages/CreateOrEditChatbotLexPage";

const remove0033If = (phone: string) => {
  if (phone.startsWith("+33") && phone.length === 12) {
    return "0" + phone.slice(3);
  }
  if (phone.startsWith("0033") && phone.length === 13) {
    return "0" + phone.slice(4);
  }
  return phone;
};

export const addIndicatifIfNot = (phone: string) => {
  if (!phone) {
    return phone;
  }
  if (phone.startsWith("+33") && phone.length === 12) {
    return "0033" + phone.slice(3);
  }
  if (phone.length === 10) {
    return "0033" + phone.slice(1);
  }
  return phone;
};

export const formatDateFromTimestamp = (timestamp, isHistory = false) => {
  const date = new Date(timestamp);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  let hours = date.getHours().toString();
  if (hours.length === 1) hours = `0${hours}`;

  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = `0${minutes}`;

  let seconds = date.getSeconds().toString();
  if (seconds.length === 1) seconds = `0${seconds}`;

  if (isHistory) {
    return dd + "/" + mm + " " + hours + ":" + minutes + ":" + seconds;
  }
  return dd + "/" + mm + "/" + yyyy + " à " + hours + "h" + minutes;
};

const getTimestampProg = (
  messageId,
  replaceDates,
  hoursToCompute,
  date,
  hours,
  hoursText
) => {
  const founded = replaceDates.find((x) => x?.messageId === messageId);
  if (founded?.timestamp) {
    return founded.timestamp;
  }
  const HOURS_IN_MS = 3600000;
  if (!hours) hours = 0;
  if (!date) date = Date.now();
  else {
    date = Date.parse(date + "T" + hoursText + ":00");
  }

  if (typeof hoursToCompute !== "number")
    hoursToCompute = parseInt(hoursToCompute);
  if (typeof hours !== "number") hours = parseInt(hours);

  const resultTimestamp = date + hoursToCompute * HOURS_IN_MS;
  return resultTimestamp;
};

const calcDateProg = (
  messageId,
  replaceDates,
  hoursToCompute,
  date,
  hours,
  hoursText
) => {
  const founded = replaceDates.find((x) => x?.messageId === messageId);
  if (founded?.timestamp) {
    return formatDateFromTimestamp(founded.timestamp);
  }
  const HOURS_IN_MS = 3600000;
  if (!hours) hours = 0;
  if (!date) date = Date.now();
  else {
    date = Date.parse(date + "T" + hoursText + ":00");
  }

  if (typeof hoursToCompute !== "number")
    hoursToCompute = parseInt(hoursToCompute);
  if (typeof hours !== "number") hours = parseInt(hours);

  const resultTimestamp = date + hoursToCompute * HOURS_IN_MS;
  return formatDateFromTimestamp(resultTimestamp);
};

const errorMessages = {
  PatientToComplete: "Les informations du patient ne sont pas complètes",
  NoPatientSelected: "Aucun patient selectionné",
  NoEnoughTokens: "Vous n'avez pas assez de jetons",
  NoAdviceSelectedAndNeeded: "Aucun conseil n'a été ajouté",
  NoFreeTextAndNeeded: "Le texte personnalisé n'est pas renseigné",
  InvalidDateAndNeeded: "La date renseignée est invalide",
  InvalidHoursAndNeeded: "L'heure renseignée est invalide",
  NoAgree: "L'accord oral n'est pas certifié",
  ZeroDays: "Le nombre de jours est invalide (0)",
  InvalidDateLimit: "La date renseignée est invalide",
  "": "",
};

const errorSelector = {
  PatientToComplete: "#recipient-box",
  NoPatientSelected: "#recipient-box",
  NoEnoughTokens: "",
  NoAdviceSelectedAndNeeded: "#advice-to-send-box",
  NoFreeTextAndNeeded: "#free-text-input",
  InvalidDateAndNeeded: "#trigger-date-input",
  InvalidHoursAndNeeded: "#trigger-hours-input",
  NoAgree: "#recipient-agree",
  ZeroDays: "#false-days",
  InvalidDateLimit: "#invalid-limit-date",
  "": "",
};

const EditPopupMessage: React.FC<{
  editMessageOpen: any;
  replaceTexts: any;
  setEditMessageOpen: any;
  timestampProg: number;
  onValidClick: (
    messageId: string,
    date: string,
    hour: string,
    timestamp: number,
    content: string
  ) => void;
}> = (props) => {
  const [dateInput, setDateInput] = React.useState<string>(undefined);
  const [hourInput, setHourInput] = React.useState<string>(undefined);
  const [content, setContent] = React.useState<string>("");

  React.useEffect(() => {
    setDateInput(genericGetFromTimestamp(props.timestampProg, "yyyy-mm-dd"));
    setHourInput(genericGetFromTimestamp(props.timestampProg, "hh:min"));
  }, [props.timestampProg]);

  React.useEffect(() => {
    if (
      !props.replaceTexts ||
      !props.editMessageOpen ||
      !props?.editMessageOpen?.content
    ) {
      return;
    }
    const founded = props.replaceTexts.find(
      (x) => x?.messageId == props?.editMessageOpen?.id
    );
    if (founded && founded?.text) {
      setContent(founded?.text);
    } else if (props?.editMessageOpen?.content) {
      setContent(props?.editMessageOpen?.content);
    }
  }, [props.editMessageOpen, props.replaceTexts]);

  if (!content) {
    return <></>;
  }
  return (
    <GenericFromPopup
      onClose={() => props.setEditMessageOpen(undefined)}
      title={{ leftPart: "Modifier", rightPart: "mon message" }}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="10px"
        padding="10px"
      >
        {content && (
          <Grid item display="grid">
            <GenericLongText
              isMandatory={true}
              fieldTitle="Contenu"
              maxLength={325}
              id="contenu-popup"
              value={content}
              onChange={(e) => {
                setContent(e);
              }}
            />
          </Grid>
        )}
        <Grid item display="grid">
          <FullField
            title="Date de déclenchement du message"
            isMandatory={true}
          >
            <Grid container direction="row" gap="13px">
              <Grid item display="flex" className="item2">
                <input
                  type="date"
                  name="date_send"
                  id="trigger-date-input-edit-message"
                  style={{
                    background: "#EDF2F2 0% 0% no-repeat padding-box",
                    border: "1px solid #D3E0E0",
                    borderRadius: "3px",
                    opacity: 1,
                    width: "140px",
                    height: "32px",
                    textAlign: "center",
                    font: "normal normal normal 14px/21px Poppins",
                    color: "#657273",
                  }}
                  value={dateInput}
                  onChange={(e) => {
                    setDateInput(e.target.value);
                  }}
                />
              </Grid>
              <Grid item display="flex" style={{ alignItems: "center" }}>
                <span>à</span>
              </Grid>
              <Grid item className="item2" display="flex">
                <input
                  type="time"
                  name="hours_send"
                  id="trigger-hours-input-edit-message"
                  style={{
                    background: "#EDF2F2 0% 0% no-repeat padding-box",
                    border: "1px solid #D3E0E0",
                    borderRadius: "3px",
                    opacity: 1,
                    width: "140px",
                    height: "32px",
                    textAlign: "center",
                    font: "normal normal normal 14px/21px Poppins",
                    color: "#657273",
                  }}
                  value={hourInput}
                  onChange={(e) => {
                    setHourInput(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </FullField>
        </Grid>
        <Grid item display="grid">
          <GenericSendButton
            onSendClick={() => {
              const dateTimeString = dateInput + "T" + hourInput + ":00";
              const timestamp = Date.parse(dateTimeString);
              props.onValidClick(
                props.editMessageOpen?.id,
                dateInput,
                hourInput,
                timestamp,
                content
              );
            }}
            invalidConditions={{
              emptyDate: !dateInput,
              emptyHour: !hourInput,
            }}
            errorMessages={{
              emptyDate: "La date n'est pas renseignée",
              emptyHour: "L'heure n'est pas renseignée",
              "": "",
            }}
            errorSelector={{
              emptyDate: "#trigger-date-input-edit-message",
              emptyHour: "#trigger-hours-input-edit-message",
              "": "",
            }}
            textButton="Valider"
          />
        </Grid>
      </Grid>
    </GenericFromPopup>
  );
};

const MonPatientBox: React.FC<any> = (props) => {
  const MonPatientRef = useRef(null);
  const selectedAdviceObject = new SelectedAdvice();
  const tokensReducer = useAppSelector((state) => state.tokensReducer);
  const phoneSelected = useAppSelector(
    (state) => state.selectPatientReducer.phoneSelected
  );
  const patientSelected = useAppSelector(
    (state) => state.selectPatientReducer.patientSelected
  );
  const dispatch = useDispatch();
  const [alertPopupInfos, setAlertPopupInfos] = React.useState(null);
  const [patientList, setPatientList] = React.useState([]);
  const [phonesInputMessage, setPhonesInputMessage] = useState("");
  const [modeVocalise, setModeVocalise] = React.useState(false);
  const [modeMail, setModeMail] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [phonesInput, setPhonesInput] = useState("");
  const [showError, setShowError] = useState(false);
  const [isMultipleRecip, setIsMultipleRecip] = useState(false);
  const [hours, setHours] = useState<any>();
  const [isPackToSendPopup, setIsPackToSendPopup] = useState(false);
  const [dateInput, setdateInput] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [acutalTutorial, setActualTutorial] = useState<any>();
  const [isPatientListLoading, setIsPatientListLoading] =
    React.useState<boolean>(true);
  const [userDateLoaded, setUserDataLoaded] = useState("");
  const [now, setNow] = useState(true);
  const [freeText, setFreeText] = useState(false);
  const [inputText, setInputText] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [showed, setShowed] = useState(false);
  const [index, setIndex] = useState(0);
  const [hoursText, setHoursText] = React.useState<string>("");
  const [dateText, setDateText] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [excludedMessages, setExcludedMessages] = React.useState([]);
  const [today, setToday] = useState<any>();
  const [errorIsHidden, setErrorIsHidden] = useState(false);
  const { isPopup, patient } = useAppSelector(
    (state) => state.sendAdviceReducer
  );
  const [sendMailAfterPack, setSendMailAfterPack] = React.useState(false);
  const [isCheck, setIsCheck] = React.useState(false);
  const [numberDays, setNumberDays] = React.useState("");
  const [dateTextLimit, setDateTextLimit] = React.useState("");
  const [isCheckVisible, setIsCheckVisible] = React.useState(false);
  const [patientGroupsList, setPatientGroupsList] = React.useState([]);
  const [editMessageOpen, setEditMessageOpen] = React.useState<any>(undefined);
  const [listePatientsExecuted, setListePatientsExecuted] =
    React.useState<boolean>(false);
  const isEditPatientOpen = useAppSelector(
    (state) => state.editPatientReducer.isPatientOpen
  );
  const isCreatePatientOpen = useAppSelector(
    (state) => state.createPatientReducer.isPatientOpen
  );
  const [replaceDates, setReplaceDates] = React.useState<
    Array<{
      messageId: string;
      timestamp: number;
    }>
  >([]);
  const [replaceTexts, setReplaceTexts] = React.useState<
    Array<{
      messageId: string;
      text: string;
    }>
  >([]);
  const snackbarUse = useOpenSnackbar();

  const listeTel = useQuery().get("listeTel");
  const listePatients = useQuery().get("listePatients");
  const freeTextQuery = useQuery().get("texteLibre");
  const accordOralQuery = useQuery().get("accordOral");
  const dateDeclenchementQuery = useQuery().get("dateDeclenchement");
  const listeConseilsQuery = useQuery().get("listeConseils");
  const envoiAutoQuery = useQuery().get("envoiAuto");

  React.useEffect(() => {
    selectedAdviceObject.clear();
  }, []);

  React.useEffect(() => {
    if (!envoiAutoQuery) {
      return;
    }
    if (envoiAutoQuery.toLowerCase() !== "oui") {
      return;
    }
    setTimeout(() => {
      const querySelectorButton = "#sendScheduleButton";
      const section: HTMLElement = document
        ?.querySelector(querySelectorButton)
        .querySelector("button");
      if (!section) {
        return;
      }
      section?.scrollIntoView({ behavior: "smooth", block: "end" });
      if (section?.click) {
        section?.click();
      }
    }, 5000);
  }, [envoiAutoQuery]);

  React.useEffect(() => {
    if (!listeConseilsQuery) {
      return;
    }
    const conseilsQuery = listeConseilsQuery.split(",");
    if (conseilsQuery?.length == 0) {
      return;
    }
    const ids = [];
    for (const x of conseilsQuery) {
      const tmp = Number(x);
      if (!isNaN(tmp)) {
        ids.push(tmp);
      }
    }
    makeAPIRequest(
      "post",
      "/conseils/by_ids",
      {
        ids: ids,
      },
      `v3`
    )
      .then((res) => {
        if (res?.data?.length > 0) {
          handleAddConseils(res.data);
        }
      })
      .catch((err) => {
        snackbarUse.error("Impossible de charger les conseils.", err);
      });
  }, [listeConseilsQuery]);

  React.useEffect(() => {
    if (!dateDeclenchementQuery) {
      return;
    }
    const splited = dateDeclenchementQuery.split(" ");
    const dateTmp = splited?.[0];
    const hoursTmp = splited?.[1];

    if (dateTmp && dateTmp.split("-").length === 3) {
      setNow(false);
      setdateInput(dateTmp as any);
      setDateText(dateTmp);
    }
    if (hoursTmp && hoursTmp.includes(":")) {
      setHoursText(hoursTmp);
    }
  }, [dateDeclenchementQuery]);

  React.useEffect(() => {
    // listePatients
    // 0700000001,nom,prenom,1977-10-15;0700000002,nom,prenom,1977-10-15
    if (
      !listePatients ||
      !patientList ||
      isPatientListLoading ||
      listePatientsExecuted
    ) {
      return;
    }
    const listePatientsSplited = listePatients.split(";");
    if (listePatientsSplited?.length == 0) {
      return;
    }

    setListePatientsExecuted(true);
    const patientsToCreate = [];

    for (const patientString of listePatientsSplited) {
      const [phone, lastname, firstname, dob] = patientString.split(",");
      if (isValidPhoneNumber(phone)) {
        if (firstname && lastname) {
          const patientFounded: IPatientData = patientList.find(
            (p) =>
              p?.phone == phone &&
              p?.firstname &&
              p?.lastname &&
              p?.firstname.toLowerCase() == firstname.toLowerCase() &&
              p?.lastname.toLowerCase() == lastname.toLowerCase()
          );
          if (patientFounded) {
            dispatch(selectPatientReducer.onlyAddPatient(patientFounded));
          } else {
            console.log(patientFounded);
            const toSend = {
              firstname: firstname,
              lastname: lastname,
              phone: phone,
              INS: "0000000000000",
            };

            if (dob) toSend["dob"] = dob;
            console.log(toSend);
            patientsToCreate.push(toSend);
          }
        } else {
          dispatch(selectPatientReducer.addPhone(phone));
        }
      }
    }
    if (patientsToCreate.length == 0) {
      return;
    }
    setIsPatientListLoading(true);
    makeAPIRequest(
      "post",
      `/patients/create`,
      {
        patients: patientsToCreate,
      },
      "v2"
    )
      .then((res) => {
        if (res?.data?.success === true) {
          const ids: string[] = res?.data?.data?.["ID"];
          if (ids?.length > 0) {
            makeAPIRequest("get", `/patients/get_all`, null, "v3")
              .then((res: any) => {
                const patientListTmp = res.data.data.search_patients_results;
                setPatientList(patientListTmp);
                for (const idTmp of ids) {
                  const patientFoundedTmp = patientListTmp?.find(
                    (x) => x?.id == idTmp
                  );
                  if (patientFoundedTmp) {
                    dispatch(
                      selectPatientReducer.onlyAddPatient(patientFoundedTmp)
                    );
                  }
                }
              })
              .then(() => {})
              .catch(() => {})
              .finally(() => {
                setIsPatientListLoading(false);
              });
          }
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, [listePatients, patientList, isPatientListLoading, listePatientsExecuted]);

  React.useEffect(() => {
    if (!listeTel || !patientList) {
      return;
    }
    const splited = listeTel.split(",");
    if (splited?.length == 0) {
      return;
    }
    for (const x of splited) {
      if (isValidPhoneNumber(x)) {
        const patientFounded = patientList.find((p) => p?.phone == x);
        if (patientFounded) {
          dispatch(selectPatientReducer.deletePhone(x));
          dispatch(selectPatientReducer.onlyAddPatient(patientFounded));
        } else {
          dispatch(selectPatientReducer.addPhone(x));
        }
      }
    }
  }, [listeTel, patientList]);

  React.useEffect(() => {
    if (freeTextQuery) {
      setFreeText(true);
      setInputText(freeTextQuery);
    }
  }, [freeTextQuery]);

  React.useEffect(() => {
    makeAPIRequest("get", `/patient/groups`)
      .then((res: any) => {
        setPatientGroupsList(res?.data?.data?.groups);
      })
      .catch((err) => {
        setPatientGroupsList([]);
      });
  }, []);

  useEffect(() => {
    if (
      !freeText &&
      props.selectedAdvice.length === 1 &&
      props.selectedAdvice[0]?.type !== "pack"
    ) {
      setIsCheckVisible(true);
    } else {
      setIsCheckVisible(false);
    }
  }, [props.selectedAdvice, freeText]);

  /* Check if agreement have to be checked */
  useEffect(() => {
    if (
      PatientAgreement.isOk(patientSelected) ||
      (accordOralQuery && accordOralQuery.toLowerCase() === "oui") ||
      (envoiAutoQuery && envoiAutoQuery.toLowerCase() === "oui")
    ) {
      setAgree(true);
    } else {
      setAgree(false);
    }
  }, [patientSelected, accordOralQuery]);

  useEffect(() => {
    setToday(moment().format("L"));
    fetchData();
    if (!patient)
      dispatch(setPatient(JSON.parse(localStorage.getItem("Patient"))) as any);

    const onAdd = (eventData) => {
      fetchData();
      setFreeText(false);
    };

    const onDelete = (eventData) => {
      setShowed(false);
      fetchData();
    };

    const userLoad = (eventData) => {
      setUserDataLoaded(eventData.value);
    };

    const listenerAdd = EventEmitter.addListener("Added", onAdd);
    const listenerDelete = EventEmitter.addListener("DeletedConseil", onDelete);
    const listerPatient = EventEmitter.addListener("userLoaded", userLoad);

    return () => {
      listenerAdd.remove();
      listenerDelete.remove();
      listerPatient.remove();
    };
  }, []);

  const fetchData = () => {
    if (localStorage.getItem("loaded") == "true") {
      setLoaded(true);
      setUserDataLoaded(localStorage.getItem("loadedUser"));
    }
  };

  const refesh = () => {
    const values = selectedAdviceObject.getAll();

    return values;
  };

  const handleAddConseils = (conseilsToAdd: ConseilReponseAfterParsing[]) => {
    conseilsToAdd.map((c) => {
      if (selectedAdviceObject.isSelectedById(c.id)) {
        return;
      }
      selectedAdviceObject.add(c);
      EventEmitter.emit("Added", {
        text: c,
      });
    });
    props.setSelectedAdvice(selectedAdviceObject.getAll());
  };

  const handleDelete = (id) => {
    selectedAdviceObject.deleteById(id);
    props.setSelectedAdvice(selectedAdviceObject.getAll());
    EventEmitter.emit("DeletedConseil", {
      text: id,
    });
  };

  const handleFile = (file): void => {
    if (file) {
      setFile(file);

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFileBase64(reader.result as string);
      };
    } else {
      setFile(null);
      setFileBase64(null);
    }
  };

  const resetAllInformations = () => {
    setReplaceDates([]);
    setReplaceTexts([]);
    setEditMessageOpen(undefined);
    setExcludedMessages([]);
    setSendMailAfterPack(false);
    setdateInput(null);
    setAgree(false);
    setModeVocalise(false);
    setModeMail(false);
    setFreeText(false);
    setInputText("");
    setFile(null);
    setFileBase64(null);
    setNow(true);
    setHoursText("");
    localStorage.removeItem("PatientSelected");
    dispatch(selectPatientReducer?.refresh());
    localStorage.removeItem("Patient");
    localStorage.removeItem("Chose");
    setDateText("");
    setShowed(false);
    setPhonesInput("");
    setPhonesInputMessage("");
    setIsMultipleRecip(false);
    selectedAdviceObject.clear();
    props.setSelectedAdvice(selectedAdviceObject.getAll());
    setIsLoading(false);
    fetchData();
  };

  const cleanPhones = (phones) => {
    const phonesCleaned: string[] = [];

    for (const phone of phones) {
      if (phone.length === 1 && phone[0] === "+") {
        phonesCleaned.push("");
      }
      if (phone.length === 2 && phone.substring(0, 2) === "+3") {
        phonesCleaned.push("");
      } else if (phone.length >= 3 && phone.substring(0, 3) === "+33") {
        phonesCleaned.push("0" + phone.substring(3, phone.length));
      } else {
        phonesCleaned.push(phone);
      }
    }
    return phonesCleaned;
  };

  const isValidPhonesInput = () => {
    const phonesChecked = [];
    const phones = phonesInput.replace(/ +(?= )/g, "").split(" ");
    let index = 0;
    for (const phone of cleanPhones(phones)) {
      for (const number of phone) {
        if (
          !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(number)
        ) {
          setPhonesInputMessage(
            `Le n° de tel '${phones[index]}' est invalide.`
          );
          return false;
        }
      }
      if (phone.length >= 1 && phone[0] != "0") {
        setPhonesInputMessage(`Le n° de tel '${phones[index]}' est invalide.`);
        return false;
      }
      if (phone.length >= 2 && !["06", "07"].includes(phone.substring(0, 2))) {
        setPhonesInputMessage(`Le n° de tel '${phones[index]}' est invalide.`);
        return false;
      }
      if (phone.length < 10 && index != phones.length - 1) {
        setPhonesInputMessage(`Le n° de tel '${phones[index]}' est invalide.`);
        return false;
      }
      if (phone.length < 10) {
        if (phones.length <= 1) {
          setPhonesInputMessage(`Séparer chaque n° de tel par un espace.`);
          return false;
        } else {
          if (phone.length === 0) {
            setPhonesInputMessage(`Renseigner le n° de tel suivant.`);
            return true;
          }
          if (phone.length < 10) {
            setPhonesInputMessage(
              `Le n° de tel '${phones[index]}' est invalide.`
            );
            return false;
          }
          setPhonesInputMessage(`Renseigner le n° de tel suivant.`);
          return false;
        }
      }
      if (phonesChecked.includes(phone)) {
        setPhonesInputMessage(`Le n° de tel '${phones[index]}' est en double.`);
        return false;
      }
      if (phone.length !== 10) {
        setPhonesInputMessage(`Le n° de tel '${phones[index]}' est invalide.`);
        return false;
      }
      phonesChecked.push(phone);
      index++;
    }
    setPhonesInputMessage(``);
    return true;
  };

  const allPatientsHavingMail = () => {
    let result = false;
    if (phoneSelected?.length > 0) {
      return false;
    }
    for (const patient of patientList) {
      if (patientSelected?.includes(patient?.id)) {
        if (!patient?.email) {
          return false;
        } else {
          result = true;
        }
      }
    }
    return result;
  };

  const isValidToSend = () => {
    const numberDaysBeforeSend =
      numberDays !== "" ? parseInt(numberDays, 10) : 1;
    let numberRepetitionSend = 1;
    let numberHoursRepetition = 0;
    const newSendArray = [];
    const toSend = createToSendObject();
    if (isCheck === true) {
      const time = createDateToSend();
      if (parseInt(time.hours, 10) > parseInt(toSend.hours, 10)) {
        numberHoursRepetition = parseInt(time.hours) - parseInt(toSend.hours);
      }
    }
    let addExcludedMessages: any = {};

    excludedMessages.length > 0
      ? (addExcludedMessages.excluded_messages = [...excludedMessages])
      : (addExcludedMessages.excluded_messages = []);

    numberRepetitionSend = numberHoursRepetition / 24 / numberDaysBeforeSend;
    for (let i = 0; i <= numberRepetitionSend; i++) {
      const newHours = (
        parseInt(toSend?.hours, 10) +
        24 * (i * numberDaysBeforeSend)
      ).toString();
      const newMinutes = now ? "0" : toSend?.minutes;
      const targets = [];

      for (const patient of patientList) {
        if (patientSelected?.includes(patient?.id)) {
          targets.push({
            patient_id: patient?.id,
            target: modeMail ? patient?.email : patient?.phone,
          });
        }
      }
      for (const phone of phoneSelected) {
        targets.push({
          target: phone?.phone,
        });
      }
      newSendArray.push({
        targets: targets,
        is_vocal: modeVocalise ? 1 : 0,
        is_mail: modeMail ? 1 : 0,
        hours: newHours,
        minutes: newMinutes,
        conseil_ids: toSend?.conseil_ids?.map((x) => x?.toString()),
        free_text: toSend?.free_text,
        send_mail_after: sendMailAfterPack ? 1 : 0,
        file_name: toSend?.file_name,
        file_data: toSend?.file_data,
        replace_dates: replaceDates,
        replace_texts: replaceTexts,
        ...addExcludedMessages,
      });
    }

    let isBadTarget = false;

    console.log(newSendArray);

    for (const sendArray of newSendArray) {
      if (sendArray?.targets?.length > 0) {
        if (sendArray.targets.find((x) => !x?.target)) {
          isBadTarget = true;
        }
      }
    }

    const invalidConditions = {
      NoEnoughTokens: getTokensCost() > props?.user?.user?.tokens,
      PatientToComplete: isBadTarget,
      NoPatientSelected:
        (!patientSelected || patientSelected?.length === 0) &&
        phoneSelected.length === 0,
      NoAdviceSelectedAndNeeded:
        freeText === false && toSend.conseil_ids.length === 0,
      NoFreeTextAndNeeded: freeText === true && toSend.free_text === "",
      InvalidDateAndNeeded: !now && !dateInput,
      InvalidHoursAndNeeded: !now && hoursText.length != 5,
      NoAgree: !agree,
      ZeroDays: numberDays === "0" && isCheck,
      InvalidDateLimit: isCheck && !dateTextLimit,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  const createDateToSend = () => {
    let HoursToAdd = 0;
    const time = {
      hours: "",
      minutes: "",
    };

    let date4 = new Date();
    date4 = new Date(date4.getTime() - date4.getTimezoneOffset() * 60000);
    if (!hoursText) {
      HoursToAdd = date4.getHours();
    } else {
      HoursToAdd = parseInt(hoursText.slice(0, 2), 10);
    }
    const date5 = new Date(dateTextLimit + "T00:00:00");
    const date6 = new Date(date5.getTime() - date5.getTimezoneOffset() * 60000);
    let diffMs = (date6 as any) - (date4 as any);
    if (diffMs < 0) diffMs = 0;
    const diffDays = Math.floor(diffMs / 86400000);
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000) + diffDays * 24;
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    const HourstoSend = diffHrs + HoursToAdd;
    time.minutes = diffMins.toString();
    time.hours = HourstoSend.toString();
    return time;
  };

  const createToSendObject = () => {
    const toSend = {
      patient_id: "",
      target: "",
      hours: "",
      modeVocalise: 0,
      modeMail: 0,
      minutes: "",
      conseil_ids: [],
      free_text: "",
      file_name: "",
      file_data: "",
    };

    let date4 = new Date();
    date4 = new Date(date4.getTime() - date4.getTimezoneOffset() * 60000);
    const date5 = new Date(dateInput + "T" + hoursText + ":00");
    const date6 = new Date(date5.getTime() - date5.getTimezoneOffset() * 60000);
    let diffMs = (date6 as any) - (date4 as any);
    if (diffMs < 0) diffMs = 0;
    const diffDays = Math.floor(diffMs / 86400000);
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000) + diffDays * 24;
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    const HourstoSend = diffHrs;
    toSend.minutes = diffMins.toString();
    const ArrayToSend = refesh();
    const ArrayId = [];

    for (let i = 0; i < ArrayToSend.length; i++) ArrayId[i] = ArrayToSend[i].id;
    if (localStorage.getItem("Chose") === "true") {
      const Patientdata = patient;
      toSend.patient_id = Patientdata?.id;
      toSend.target = modeMail ? Patientdata?.email : Patientdata?.phone;
    } else {
      toSend.patient_id = "";
      toSend.target = userDateLoaded;
    }
    if (!freeText) {
      toSend.conseil_ids = ArrayId;
    } else {
      toSend.free_text = inputText;
      toSend.conseil_ids = [];
    }
    if (props.selectedAdvice) {
      for (const selected of props.selectedAdvice) {
        if (
          selected?.id &&
          !toSend?.conseil_ids?.includes(selected.id.toString())
        ) {
          toSend.conseil_ids.push(selected.id.toString());
        }
      }
    }
    if (file) {
      toSend.file_name = file.name;
      toSend.file_data = fileBase64;
    }
    if (now === true) toSend.hours = "0";
    else toSend.hours = HourstoSend.toString();
    return toSend;
  };

  const getTokensCost = () => {
    let counter = 0;

    if ([2, 4, "2", "4"].includes(props?.user?.user?.account_type)) {
      // Premium and administrator
      return 0;
    }
    for (const patient of patientList) {
      if (patientSelected?.includes(patient?.id)) {
        counter++;
      }
    }
    counter += phoneSelected?.length;
    return counter * tokensReducer?.sendSmsCost;
  };

  const sendConseil = async () => {
    const numberDaysBeforeSend =
      numberDays !== "" ? parseInt(numberDays, 10) : 1;
    let numberRepetitionSend = 1;
    let numberHoursRepetition = 0;
    const newSendArray = [];
    const toSend = createToSendObject();
    if (isCheck === true) {
      const time = createDateToSend();
      if (parseInt(time.hours, 10) > parseInt(toSend.hours, 10)) {
        numberHoursRepetition = parseInt(time.hours) - parseInt(toSend.hours);
      }
    }
    let addExcludedMessages: any = {};

    excludedMessages.length > 0
      ? (addExcludedMessages.excluded_messages = [...excludedMessages])
      : (addExcludedMessages.excluded_messages = []);

    numberRepetitionSend = numberHoursRepetition / 24 / numberDaysBeforeSend;
    for (let i = 0; i <= numberRepetitionSend; i++) {
      const newHours = (
        parseInt(toSend?.hours, 10) +
        24 * (i * numberDaysBeforeSend)
      ).toString();
      const newMinutes = now ? "0" : toSend?.minutes;
      const targets = [];

      for (const patient of patientList) {
        if (patientSelected?.includes(patient?.id)) {
          targets.push({
            patient_id: patient?.id,
            target: modeMail ? patient?.email : patient?.phone,
          });
        }
      }
      for (const phone of phoneSelected) {
        targets.push({
          target: phone?.phone,
        });
      }
      newSendArray.push({
        targets: targets,
        is_vocal: modeVocalise ? 1 : 0,
        is_mail: modeMail ? 1 : 0,
        hours: newHours,
        minutes: newMinutes,
        conseil_ids: toSend?.conseil_ids?.map((x) => x?.toString()),
        free_text: toSend?.free_text,
        send_mail_after: sendMailAfterPack ? 1 : 0,
        file_name: toSend?.file_name,
        file_data: toSend?.file_data,
        replace_dates: replaceDates,
        replace_texts: replaceTexts,
        ...addExcludedMessages,
      });
    }
    let countIndex = 0;

    for (const elementToSend of newSendArray) {
      if (!isLoading) setIsLoading(true);
      const res = await TutorialService.sendMulti(elementToSend);
      if (
        res?.data?.data?.[0]?.code ==
        "action-sendConseil-WSAjaxClassConseilsSend-354"
      ) {
        dispatch(
          openSnackbar({
            message: "Vous n'avez pas assez de jetons",
            type: "error",
            duration: 3000,
          })
        );
        resetAllInformations();
        return;
      }
      if (res?.data?.success !== true) {
        dispatch(
          openSnackbar({
            message: "Une erreur est survenue lors de la programmation",
            type: "error",
            duration: 3000,
          })
        );
        resetAllInformations();
        return;
      }
      props.googleAnalytics?.sendAdvicesEvent(elementToSend);
      if (countIndex === newSendArray.length - 1) {
        setErrorIsHidden(true);
        resetAllInformations();
        dispatch(setIsPopup(true) as any);
        setTimeout(() => {
          setErrorIsHidden(false);
          dispatch(setIsPopup(false) as any);
        }, 15000);
      }
      countIndex++;
    }
  };

  const getPatientsByPatientSelected = () => {
    if (!patientSelected || patientSelected?.length == 0) {
      return [];
    }
    const result: any[] = [];
    patientSelected.forEach((id: number) => {
      if (!result.find((y) => y?.id == id)) {
        const founded = patientList.find((x) => x?.id == id);
        if (founded) {
          result.push(founded);
        }
      }
    });
    return result;
  };

  const handleSumbit = async (bypass = false) => {
    setIsLoading(true);
    /*
    // LATENCE TO FIX
    if (!bypass) {
      const patientSelectedToCheck = getPatientsByPatientSelected().map((x) => {
        return {
          phone: addIndicatifIfNot(x?.phone),
          firstname: x?.firstname,
          lastname: x?.lastname,
        };
      });
      const phoneSelectedToCheck =
        phoneSelected?.length > 0
          ? phoneSelected
              .map((x) =>
                x?.phone ? { phone: addIndicatifIfNot(x.phone) } : undefined
              )
              .filter((x) => x)
          : [];
      try {
        const res = await makeAPIRequest(
          "post",
          "/patients/check_limit_sended",
          {
            toCheck: [...patientSelectedToCheck, ...phoneSelectedToCheck],
          },
          "v3"
        );
        if (res?.data?.length > 0) {
          setAlertPopupInfos(res.data);
          setIsLoading(false);
          return;
        }
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    }
    */

    const values = selectedAdviceObject.getAll();

    if (isValidToSend()) {
      if (values?.length === 1 && values[0]?.type === "pack") {
        setIsPackToSendPopup(true);
      } else {
        await sendConseil();
      }
    }
  };

  const setActual = (state, tutorial, i) => {
    if (showed == true) {
      setShowed(false);
    } else {
      setShowed(true);
      setActualTutorial(tutorial);
      setIndex(i + 1);
    }
  };

  return (
    <>
      {isCreatePatientOpen && (
        <CreateOrEditPatient
          isCreate={true}
          isEdit={false}
          patientGroupsList={patientGroupsList}
          setPatientList={setPatientList}
        />
      )}
      {isEditPatientOpen && (
        <CreateOrEditPatient
          isCreate={false}
          isEdit={true}
          patientGroupsList={patientGroupsList}
          setPatientList={setPatientList}
        />
      )}
      {alertPopupInfos && (
        <CancellablePopup
          firstTitle="Continuer"
          secondTitle="quand même ?"
          confirmButtonText="Continuer"
          cancelButtonText="Annuler"
          onCancel={() => setAlertPopupInfos(null)}
          onConfirm={async () => {
            setAlertPopupInfos(null);
            setIsPackToSendPopup(false);
            handleSumbit(true);
          }}
          maxWidth="60vw"
        >
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              wrap="nowrap"
            >
              <Grid item display="grid" paddingTop="40px">
                <span
                  style={{
                    fontFamily: "Poppins",
                    color: "rgb(101, 114, 115)",
                    fontSize: "16px",
                  }}
                >
                  Vous avez{" "}
                  {alertPopupInfos.length > 1
                    ? `${alertPopupInfos.length} alertes`
                    : "une alerte"}
                </span>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <Grid container direction="column" spacing="15px" wrap="nowrap">
                  {alertPopupInfos.map((info, index) => {
                    return (
                      <Grid item key={index} display="grid">
                        <Grid item display="grid">
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            spacing="20px"
                          >
                            <Grid item display="grid" alignSelf="center">
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  marginTop: "1px",
                                  fontWeight: "bold",
                                  color: "rgb(91, 185, 193)",
                                  fontSize: "14px",
                                }}
                              >
                                {index + 1}
                              </span>
                            </Grid>
                            {info?.type === "scheduled" && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "14px",
                                  }}
                                >
                                  {info?.patientInfos?.lastname &&
                                  info?.patientInfos?.firstname
                                    ? `${info?.patientInfos?.lastname} ${info?.patientInfos?.firstname}`
                                    : "Le numéro " +
                                      remove0033If(info?.patientInfos?.phone)}
                                  {" a déjà "}
                                  {info?.quantity}
                                  {` SMS programmés dans les ${info.periodInDays} jours à venir`}
                                </span>
                              </Grid>
                            )}
                            {info?.type === "sended" && (
                              <Grid item display="grid">
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "14px",
                                  }}
                                >
                                  {info?.patientInfos?.lastname &&
                                  info?.patientInfos?.firstname
                                    ? `${info?.patientInfos?.lastname} ${info?.patientInfos?.firstname}`
                                    : "Le numéro " +
                                      remove0033If(info?.patientInfos?.phone)}
                                  {" a déjà reçu "}
                                  {info?.quantity}
                                  {` SMS ces ${info.periodInDays} derniers jours`}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CancellablePopup>
      )}

      {editMessageOpen && (
        <EditPopupMessage
          replaceTexts={replaceTexts}
          editMessageOpen={editMessageOpen}
          setEditMessageOpen={setEditMessageOpen}
          timestampProg={getTimestampProg(
            editMessageOpen?.id,
            replaceDates,
            editMessageOpen?.hours,
            dateInput,
            hoursText,
            hoursText
          )}
          onValidClick={(messageId, cbDate, cbHour, cbTimestamp, content) => {
            setReplaceDates((state) => {
              const stateCopy = [...state];
              const messageFoundedIndex = stateCopy.findIndex(
                (x) => x.messageId === messageId
              );
              if (messageFoundedIndex === -1) {
                stateCopy.push({
                  messageId: messageId,
                  timestamp: cbTimestamp,
                });
              } else {
                stateCopy[messageFoundedIndex].timestamp = cbTimestamp;
              }
              return stateCopy;
            });
            if (editMessageOpen?.content !== content) {
              setReplaceTexts((state) => {
                const stateCopy = [...state];
                const messageFoundedIndex = stateCopy.findIndex(
                  (x) => x.messageId === messageId
                );
                if (messageFoundedIndex === -1) {
                  stateCopy.push({
                    messageId: messageId,
                    text: content,
                  });
                } else {
                  stateCopy[messageFoundedIndex].text = content;
                }
                return stateCopy;
              });
            } else {
              setReplaceTexts((state) =>
                state.filter((x) => x?.messageId !== editMessageOpen?.id)
              );
            }
            setEditMessageOpen(undefined);
          }}
        />
      )}
      {isPackToSendPopup && (
        <CancellablePopup
          firstTitle="Envoyer"
          secondTitle="ce pack ?"
          confirmButtonText="Envoyer"
          cancelButtonText="Annuler"
          onCancel={() => {
            setExcludedMessages([]);
            setIsPackToSendPopup(false);
            setReplaceDates([]);
            setReplaceTexts([]);
            setIsLoading(false);
          }}
          onConfirm={async () => {
            setExcludedMessages([]);
            setIsPackToSendPopup(false);
            if (isValidToSend()) {
              await sendConseil();
            }
          }}
          maxWidth="70vw"
        >
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              maxWidth="800px"
              wrap="nowrap"
            >
              <Grid item display="grid" paddingTop="40px">
                <TriggerDateContainer
                  isInRecap={true}
                  setNow={setNow}
                  now={now}
                  hours={hours}
                  setHours={setHours}
                  hoursText={hoursText}
                  setHoursText={setHoursText}
                  setdateInput={setdateInput}
                  dateInput={dateInput}
                  handleSumbit={() => handleSumbit(false)}
                  dateText={dateText}
                  setDateText={setDateText}
                  showError={showError}
                  error={error}
                  setIsCheck={setIsCheck}
                  isCheck={isCheck}
                  setNumberDays={setNumberDays}
                  numberDays={numberDays}
                  setDateTextLimit={setDateTextLimit}
                  dateTextLimit={dateTextLimit}
                  isCheckVisible={isCheckVisible}
                />
              </Grid>
              <Grid item display="grid" paddingTop="20px">
                <span
                  style={{
                    fontFamily: "Poppins",
                    color: "rgb(101, 114, 115)",
                    fontSize: "16px",
                  }}
                >
                  Voulez-vous vraiment programmer l'envoi de ce pack ?
                </span>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <Grid container direction="column" spacing="15px" wrap="nowrap">
                  {selectedAdviceObject
                    .getAll()?.[0]
                    ?.messages?.[0]?.sort(
                      (elementA, elementB) =>
                        getTimestampProg(
                          elementA?.id,
                          replaceDates,
                          elementA?.hours,
                          dateInput,
                          hoursText,
                          hoursText
                        ) -
                        getTimestampProg(
                          elementB?.id,
                          replaceDates,
                          elementB?.hours,
                          dateInput,
                          hoursText,
                          hoursText
                        )
                    )
                    .filter(
                      (message) => !excludedMessages.includes(message?.id)
                    )
                    .map((message, index, arr) => {
                      return (
                        <Grid item key={message?.id} display="grid">
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            gap="20px"
                          >
                            <Grid item xs display="grid">
                              <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                spacing="7px"
                              >
                                <Grid item display="grid">
                                  <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    spacing="20px"
                                  >
                                    <Grid
                                      item
                                      display="grid"
                                      alignSelf="center"
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          marginTop: "1px",
                                          fontWeight: "bold",
                                          color: "rgb(91, 185, 193)",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {index + 1}
                                      </span>
                                    </Grid>
                                    <Grid item display="grid">
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          color: "#0B243C",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {(() => {
                                          const founded = replaceTexts.find(
                                            (x) => x?.messageId == message?.id
                                          );
                                          if (founded && founded?.text) {
                                            return founded.text;
                                          }
                                          if (message?.content) {
                                            return message.content;
                                          }
                                          return "";
                                        })()}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item display="grid" alignSelf="flex-end">
                                  <span
                                    style={{
                                      fontFamily: "Poppins",
                                      color: "#0B243C",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Pour le{" "}
                                    {calcDateProg(
                                      message?.id,
                                      replaceDates,
                                      message?.hours,
                                      dateInput,
                                      hoursText,
                                      hoursText
                                    )}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              xs={1}
                              alignContent="center"
                              justifyContent="flex-end"
                            >
                              <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                gap="10px"
                              >
                                <Grid item display="grid">
                                  <Tooltip title="Modifier">
                                    <IconButton
                                      onClick={() => {
                                        setEditMessageOpen(message);
                                      }}
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    >
                                      <EditRounded
                                        fontSize="small"
                                        style={{ color: "#0B243C" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                {arr?.length > 1 && (
                                  <Grid item display="grid">
                                    <Tooltip title="Supprimer">
                                      <IconButton
                                        onClick={() => {
                                          setExcludedMessages((exc) => [
                                            ...exc,
                                            message?.id,
                                          ]);
                                        }}
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                        }}
                                      >
                                        <ClearRounded
                                          fontSize="small"
                                          style={{ color: "#FF3100" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid item display="grid" paddingTop="20px">
                <OrangeCheckBox
                  value={sendMailAfterPack}
                  onClick={() => {
                    setSendMailAfterPack((e) => !e);
                  }}
                  text="Être prévenu par mail lorsque la série est terminée"
                  textStyle={{
                    textAlign: "center",
                    color: "#0B243C",
                    fontSize: "16px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CancellablePopup>
      )}
      <Grid item display="grid">
        <Grid
          container
          direction="column"
          wrap="nowrap"
          alignItems="flex-start"
          display="flex"
        >
          {isPopup && (
            <Grid
              container
              direction="column"
              className={"opacity-animation-up"}
              display="flex"
              justifyContent="center"
              wrap="nowrap"
              style={{
                width: MonPatientRef?.current?.offsetWidth,
                height: MonPatientRef?.current?.offsetHeight,
                position: "absolute",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: "24px",
                padding: "24px",
                zIndex: 10000,
              }}
            >
              <Grid item display="grid" justifyContent="center">
                <IconValide />
              </Grid>
              <Grid
                item
                display="grid"
                paddingTop="37px"
                justifyContent="center"
              >
                <span
                  style={{
                    textAlign: "center",
                    font: "normal normal normal 16px/22px Poppins",
                    letterSpacing: "0px",
                    color: "#0B243C",
                  }}
                >
                  L'envoi de votre conseil a bien été pris en compte
                </span>
              </Grid>
              <Grid
                item
                display="grid"
                paddingTop="40px"
                justifyContent="center"
              >
                <BlueButton
                  onClick={() => {
                    setTimeout(() => {
                      setErrorIsHidden(false);
                    }, 1000);
                    dispatch(setIsPopup(false) as any);
                    resetAllInformations();
                  }}
                  text="OK"
                />
              </Grid>
            </Grid>
          )}
          <Grid item display="grid" ref={MonPatientRef}>
            <Grid
              container
              direction="column"
              display="flex"
              wrap="nowrap"
              style={{
                background: "rgba(247, 252, 252, 0.55)",
                borderRadius: "24px",
                padding: "19px",
                maxWidth: "500px",
                minWidth: "400px",
              }}
            >
              <Grid item marginBottom="22px" display="grid">
                <MonPatientHeader />
              </Grid>
              <Grid
                item
                display="grid"
                id="recipient-box"
                className="border-animation"
                style={{
                  background: "rgba(255, 255, 255, 0.6)",
                  borderRadius: "18px",
                  width: "100%",
                  border:
                    showError &&
                    (error === "NoPatientSelected" ||
                      error === "PatientToComplete")
                      ? "1px solid #FF3100"
                      : "1px solid transparent",
                }}
              >
                <RecipientContainerV2
                  isPatientListLoading={isPatientListLoading}
                  setIsPatientListLoading={setIsPatientListLoading}
                  patientList={patientList}
                  patientSelected={patientSelected}
                  setPatientList={setPatientList}
                  phonesInput={phonesInput}
                  setPhonesInput={setPhonesInput}
                  setIsMultipleRecip={setIsMultipleRecip}
                  isMultipleRecip={isMultipleRecip}
                  isValidPhonesInput={isValidPhonesInput}
                  phonesInputMessage={phonesInputMessage}
                  fetchAllData={fetchData}
                  error={error}
                  showError={showError}
                />
              </Grid>
              <Grid
                item
                display="grid"
                marginTop="18px"
                style={{
                  background: "rgba(255, 255, 255, 0.6)",
                  borderRadius: "18px",
                  width: "100%",
                }}
              >
                <AdviceToSendContainer
                  showed={showed}
                  acutalTutorial={acutalTutorial}
                  index={index}
                  freeText={freeText}
                  setFreeText={setFreeText}
                  inputText={inputText}
                  handleFile={handleFile}
                  file={file}
                  setActual={setActual}
                  handleDelete={handleDelete}
                  setInputText={setInputText}
                  error={error}
                  showError={showError}
                  {...props}
                />
              </Grid>
              <Grid
                item
                display="grid"
                marginTop="18px"
                marginBottom="25px"
                style={{
                  background: "rgba(255, 255, 255, 0.6)",
                  borderRadius: "18px",
                  width: "100%",
                }}
              >
                <TriggerDateContainer
                  setNow={setNow}
                  now={now}
                  dateInput={dateInput}
                  setHours={setHours}
                  hoursText={hoursText}
                  hours={hours}
                  isInRecap={false}
                  setHoursText={setHoursText}
                  setdateInput={setdateInput}
                  handleSumbit={() => handleSumbit(false)}
                  dateText={dateText}
                  setDateText={setDateText}
                  showError={showError}
                  error={error}
                  setIsCheck={setIsCheck}
                  isCheck={isCheck}
                  setNumberDays={setNumberDays}
                  numberDays={numberDays}
                  setDateTextLimit={setDateTextLimit}
                  dateTextLimit={dateTextLimit}
                  isCheckVisible={isCheckVisible}
                />
              </Grid>
              {process.env.REACT_APP_ENABLE_MAIL_SEND_CONSEILS == "true" &&
                allPatientsHavingMail() && (
                  <Grid
                    item
                    display="grid"
                    width="90%"
                    alignSelf="center"
                    paddingBottom="25px"
                    id="recipient-mode-mail"
                  >
                    <OrangeCheckBox
                      value={modeMail}
                      onClick={() => {
                        setModeMail((e) => !e);
                      }}
                      text="Tout envoyer par e-mail, plutôt que par SMS."
                      textStyle={{
                        textAlign: "center",
                        color: "#0B243C",
                        fontSize: "15px",
                      }}
                    />
                  </Grid>
                )}
              {process.env.REACT_APP_ENABLE_ORANGE_VOCAL == "true" && (
                <Grid
                  item
                  display="grid"
                  width="90%"
                  alignSelf="center"
                  paddingBottom="25px"
                  id="recipient-mode-vocalise"
                >
                  <OrangeCheckBox
                    value={modeVocalise}
                    onClick={() => {
                      setModeVocalise((e) => !e);
                    }}
                    text="Mode vocalisé"
                    textStyle={{
                      textAlign: "center",
                      color: "#0B243C",
                      fontSize: "15px",
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                display="grid"
                width="90%"
                alignSelf="center"
                paddingBottom="25px"
                id="recipient-agree"
              >
                <OrangeCheckBox
                  value={agree}
                  onClick={() => {
                    if (!agree) {
                      for (const idToAdd of patientSelected)
                        PatientAgreement.addPatientById(idToAdd);
                    } else {
                      for (const idToDelete of patientSelected)
                        PatientAgreement.deletePatientById(idToDelete);
                    }
                    setAgree((e) => !e);
                  }}
                  text="Je certifie que j'ai bien obtenu l'accord du patient pour lui envoyer des messages de santé par SMS via l'application CertiPair et que ces données pourront être exploitées de manière anonymisée."
                  textStyle={{
                    textAlign: "center",
                    color: "#0B243C",
                    fontSize: "15px",
                  }}
                />
              </Grid>
              {error?.length > 0 && !errorIsHidden && (
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  spacing="5px"
                  marginBottom="10px"
                >
                  <Grid item display="grid" alignItems="center">
                    <span className="field_star" style={{ color: "#FF3100" }}>
                      {errorMessages[error]}
                    </span>
                  </Grid>
                  <Grid item display="grid" alignItems="center">
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        padding: "0",
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          if (showError) setShowError(false);
                        }, 2250);
                      }}
                      onClick={() => {
                        if (!showError) setShowError(true);
                        if (error === "" || errorSelector?.[error] === "")
                          return;
                        const section = document?.querySelector(
                          errorSelector?.[error]
                        );
                        section?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                    >
                      <Help
                        className="field_star"
                        style={{ height: "20px", color: "#FF3100" }}
                      />
                    </button>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                display="grid"
                justifyContent="center"
                id="sendScheduleButton"
              >
                <SendButtonItem
                  isLoading={isLoading}
                  handleSumbit={() => handleSumbit(false)}
                  isValidToSend={isValidToSend}
                />
              </Grid>
              {getTokensCost() > 0 &&
                [0, 1, 3, "0", "1", "3"].includes(
                  props?.user?.user?.account_type
                ) && (
                  <Grid item display="grid" paddingTop="10px">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        color: "rgb(101, 114, 115)",
                        fontSize: "14px",
                      }}
                    >
                      * Vous possèdez un compte{" "}
                      {props?.user?.user?.account_type == 0 && "de test"}
                      {props?.user?.user?.account_type == 3 && "de test"}
                      {props?.user?.user?.account_type == 1 && "classique"}, cet
                      envoi vous coûtera {getTokensCost()} jetons
                    </span>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MonPatientBox;
