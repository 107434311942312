import FullField from "../../../../Fields/FullField";
import * as React from 'react';
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import { useDispatch } from "react-redux";
import TextInput from "../../../../Library/Inputs/TextInput";
import setStrictPositiveNumberField from "../../../../Fields/setStrictPositiveNumberField";
import useAppSelector from "../../../../../redux/useAppSelector";

const DisplayLongText: React.FC = () => {
  const fieldCopy = useAppSelector(state => state.questionnaireReducer.fieldCopy);
  const dispatch = useDispatch();

  return (
    <FullField title='Limite de caractères' isMandatory={false} spacing='1px'>
      <TextInput
        placeholder='500'
        value={fieldCopy?.maxLength ? fieldCopy.maxLength : ''}
        onChange={(e) => {
          const fieldCopyTmp = { ...fieldCopy };
          const number = setStrictPositiveNumberField(e.target.value)

          fieldCopyTmp.maxLength = number;
          dispatch(setFieldCopy(fieldCopyTmp));
        }} />
    </FullField>
  );
};

export default DisplayLongText;