import React from "react";
import { HorizontalBar } from "react-chartjs-2";

const GenericHorizontalBar: React.FC<{
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
  }>;
  title?: string;
  titleSize?: number;
  displayLegend?: boolean;
}> = (props) => {
  const labels = props.labels;
  const data = {
    labels,
    datasets: props.datasets,
  };

  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right" as const,
      },
    },
    title: {
      display: props?.title ? true : false,
      text: props?.title,
      fontSize: props.titleSize,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          beforeUpdate(axis) {
            const labels = axis.chart.data.labels;
            for (let i = 0; i < labels.length; i++) {
              const lbl = labels[i];
              if (typeof lbl === "string" && lbl.length > 30) {
                labels[i] = lbl.substring(0, 30) + "...";
              }
            }
          },
        },
      ],
    },
  };

  return (
    <>
      {/* @ts-expect-error Server Component */}
      <HorizontalBar data={data} options={options} />
    </>
  );
};

GenericHorizontalBar.defaultProps = {
  title: "",
  displayLegend: true,
  titleSize: 20,
};

export default GenericHorizontalBar;
