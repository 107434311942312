import React from "react";
import "./footer.css";
import LogoBlanc from "../assets/logo-blanc.png";
import { Link } from "react-router-dom";

interface IFooter {
  isLoggedIn?: boolean;
}

const Footer: React.FC<IFooter> = (props) => {
  return (
    <div>
      <div className="phantom"></div>
      <div className="footer">
        <div className="phantom2"></div>
        <img src={LogoBlanc} alt="" />
        {props?.isLoggedIn !== false && (
          <div className="footer-link">
            <Link
              to={"/home"}
              style={{
                fontSize: "18px",
                textDecoration: "none",
                color: "#FFA500",
              }}
            >
              Accueil
            </Link>
            -
            <Link
              to={"/home?f=conseils&favorisAuto=oui"}
              style={{
                fontSize: "18px",
                textDecoration: "none",
                color: "#FFA500",
              }}
            >
              Favoris
            </Link>
            -
            <Link
              to={"/profile"}
              style={{
                fontSize: "18px",
                textDecoration: "none",
                color: "#FFA500",
              }}
            >
              Mon profil
            </Link>
          </div>
        )}
        <div className="footer-second-link" style={{ paddingTop: "5px" }}>
          <Link to={"/support"}>Support</Link> -{" "}
          <a
            href="https://www.certipair.fr/mentions-legales/"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
        </div>
        <div className="phantom2"></div>
      </div>
    </div>
  );
};

export default Footer;
