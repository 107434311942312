import axios from "axios";
import makeAPIRequest from "../utils/makeAPIRequest";
import { getActualTimeNumber } from "../components/NavbarComponent";

//On doit changer seulement ces 2 liens
const LOG_URL = process.env.REACT_APP_LOG_URL;
const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  async login(username: string, password: string) {
    return axios
      .post(LOG_URL, {
        username,
        password,
      })
      .then((response: any) => {
        console.log(response);
        if (response.data.token) {
          localStorage.setItem("lastConnection", getActualTimeNumber().toString());
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem(
            "user_id",
            JSON.stringify(response.data.user_id)
          );
        }

        return response.data;
      });
  }

  autoLogin(username : string, token: string){
    return axios.post(LOG_URL, {
      username,
      token,
    })
    .then((response: any) => {
      if (response.data.token) {
        console.log(response);
        localStorage.setItem("lastConnection", getActualTimeNumber().toString());
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem(
          "user_id",
          JSON.stringify(response.data.user_id)
        );
      } else {
        console.log(response);
      }

      return response.data;
    });
  }



  
  get_lang() {
  console.log(API_URL);

    return axios.get(API_URL + "/utils/language").then((response: any) => {
      if (response) {
        localStorage.setItem("civilities", response.data.civilities);
        localStorage.setItem("specialities", response.data.specialities);
        localStorage.setItem("professions", response.data.professions);
        localStorage.setItem(
          "specialities_others",
          response.data.specialities_others
        );
        localStorage.setItem("pathologies", response.data.pathologies);
        localStorage.setItem("languages", response.data.languages);
      }

      return response.data;
    });
  }

  register(data) {
    return axios
      .post(API_URL + "/user/register", data)
      .then((response: any) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("user_id", JSON.stringify(response.data.user_id));
        } else if (response.data.success === false) {
          let error = "";
          response.data.data.map((number: any) => (error = number.message));
          localStorage.setItem("error", error);
        }
        return response.data;
      });
  }

  get_profile(id: number) {
    return makeAPIRequest('get', "/user/0").then((response: any) => {
      if (response.data.token) {
        localStorage.setItem("lastConnection", getActualTimeNumber().toString());
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user_id", JSON.stringify(response.data.user_id));
      }

      return response.data;
    });
  }

  logout() {
    localStorage.removeItem("PatientSelected");
    localStorage.removeItem("Patient");
    localStorage.removeItem("Chose");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

export default new AuthService();
