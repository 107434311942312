import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GraphiqueAraignee } from '../../pages/QuestionnairePage';
import makeAPIRequest from '../../utils/makeAPIRequest'

const getGraphiqueAraignee = createAsyncThunk(
  '/GET_GRAPHIQUE_ARAIGNEE',
  async (payload, thunkAPI): Promise<GraphiqueAraignee[]> => {
    try {
      const response = await makeAPIRequest(
        'get',
        `/forms/stats/graph_araignee/list`,
        null,
        'v3',
      )
      const data: GraphiqueAraignee[] = response?.data;
      return thunkAPI.fulfillWithValue(data) as any
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err) as any
    }
  },
)

export interface GraphAraigneeReducerState {
  list: GraphiqueAraignee[],
  isLoading: boolean,
}

const initialState: GraphAraigneeReducerState = {
  list: [],
  isLoading: true,
}

export const graphAraigneeSlice = createSlice({
  name: 'GraphAraignee',
  initialState,
  reducers: {},
  extraReducers(builder: ActionReducerMapBuilder<GraphAraigneeReducerState>) {
    builder.addCase(getGraphiqueAraignee.rejected, (state) => {
      state.isLoading = false;
    })
    builder.addCase(getGraphiqueAraignee.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    })
  },
})
export const graphAraigneActions = graphAraigneeSlice.actions;
export default graphAraigneeSlice.reducer
export { getGraphiqueAraignee }
