const formatPhone = (phone: string) => {
  if (!phone) return "";
  if (phone.length === 9) {
    return "0" + phone;
  }

  // +262
  if (phone.length === 14 && phone.substring(0, 5) === "00262") {
    return phone.replace("00262", "00262");
  }
  if (phone.length === 14 && phone.substring(0, 5) === "+2620") {
    return phone.replace("+2620", "00262");
  }
  if (phone.length === 12 && phone.substring(0, 3) === "262") {
    return phone.replace("262", "00262");
  }
  if (phone.length === 13 && phone.substring(0, 4) === "2620") {
    return phone.replace("2620", "00262");
  }


  // +33
  if (phone.length === 13 && phone.substring(0, 4) === "0033") {
    return phone.replace("0033", "0");
  }
  if (phone.length === 13 && phone.substring(0, 4) === "+330") {
    return phone.replace("+330", "0");
  }
  if (phone.length === 11 && phone.substring(0, 2) === "33") {
    return phone.replace("33", "0");
  }
  if (phone.length === 12 && phone.substring(0, 3) === "330") {
    return phone.replace("330", "0");
  }
  return phone;
};

export default formatPhone;