import * as React from "react";
import { Grid } from "@mui/material";

interface ISimpleFullPage {
  isLoggedIn?: boolean;
  noFooter?: boolean;
  noNavbar?: boolean;
  children?: any;
}
//
const SimpleFullPage = (props: ISimpleFullPage) => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems='center'
    >
      <Grid item display="grid">
        {props.children}
      </Grid>
    </Grid>
  );
};

export default SimpleFullPage;
