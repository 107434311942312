import * as React from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import FormPopup from "../../../FormPopup/FormPopup";
import { setFieldCopy } from "../../../../redux/questionnaireReducer";
import FormPopupHeader from "../../../FormPopup/FormPopupHeader";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import DisplayType from "./Fields/DisplayType";
import DisplayTitle from "./Fields/DisplayTitle";
import DisplayLongText from "./Fields/DisplayLongText";
import DisplaySimpleCheck from "./Fields/DisplaySimpleCheck";
import DisplayListeDeroulante from "./Fields/DisplayListeDeroulante";
import DisplayUniqueChoice from "./Fields/DisplayUniqueChoice";
import DisplayButtons from "./DisplayButtons";
import DisplayEchelleDeNotation from "./Fields/DisplayEchelleDeNotation";
import DisplaySimpleCheckValidation from "./Fields/DisplaySimpleCheckValidation";
import DisplayTextAndImage from "./Fields/DisplayTextAndImage";
import DisplayAlertType from "./Fields/DisplayAlertType";
import DisplayAlertChoice from "./Fields/DisplayAlertChoice";
import DisplayAdditionAuto from "./Fields/DisplayAdditionAuto";
import AddButton from "../../../Library/Buttons/AddButton/AddButton";
import {
  ConditionDisplayField,
  ConditionDisplayFieldInfos,
  DateDeclaration,
  DateDeclarationInfos,
  Delegue,
  IField,
  IForm,
  Medecin,
  MedecinDelegant,
  MedecinDelegantInfos,
  MedecinTraitant,
  MedecinTraitantAction,
  MedecinTraitantInfos,
  Protocole,
  ProtocoleInfos,
  Structure,
  StructureInfos,
} from "../../../../types/FormTypes";
import {
  GenericLongText,
  GenericTextInput,
} from "../../../../pages/CreateOrEditChatbotLexPage";
import { nanoid } from "nanoid";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../../../pages/ChatbotLexPage";
import { GenericSelect } from "../../../../pages/QuestionnairePage";
import {
  ButtonIconWithTooltip,
  MyConditionsHandler,
} from "../../PDF/CreateOrEditPDFQuestionnaire";
import { Delete, Edit, EditRounded } from "@mui/icons-material";
import { MedimailSearch } from "../../SendResponseMedimailPopup";
import { getConsentementPhrase } from "../../GenerateQuestionnaire/GenerateQuestionnaire";
import { GenericDateInput } from "../../GenerateQuestionnaire/Fields/DisplayDate";

const attributPatientOptions = [
  {
    label: "Nom",
    value: "lastname",
  },
  {
    label: "Prénom",
    value: "firstname",
  },
  {
    label: "Numéro de téléphone",
    value: "phone",
  },
  {
    label: "Date de naissance",
    value: "birth_date",
  },
];

const medecinTraitantActionOptions = [
  {
    value: "sendResponseToMedecinTraitantOrIDEIfContract",
    label:
      "Envoyer la réponse au IDE si contrat signé, sinon envoyer au médecin traitant",
  },
  {
    value: "onlySendToMedecinTraitant",
    label: "Envoyer la réponse au médecin traitant",
  },
];

const initMedecinTraitantAction = (): MedecinTraitantAction => {
  return {
    id: nanoid(),
    action: "sendResponseToMedecinTraitantOrIDEIfContract",
    conditions: [],
    conditionType: "and",
    consentIsMandatory: true,
    notConsentIsMandatory: true,
    title: "",
  };
};

const initMedecinTraitant = (): MedecinTraitant => {
  return {
    haveContractWithIDE: false,
    id: nanoid(),
    ideInfos: undefined,
    medecinInfos: undefined,
  };
};

export const initConditionDisplayField = (): ConditionDisplayField => {
  return {
    id: nanoid(),
    conditions: [],
    conditionType: "and",
    title: "",
  };
};

export const initConditionDisplayFieldInfos =
  (): ConditionDisplayFieldInfos => {
    return {
      conditionsList: [],
    };
  };

const initDelegue = (): Delegue => {
  return {
    id: nanoid(),
    nomPrenom: "",
    RPPS: "",
  };
};

const initStructure = (): Structure => {
  return {
    id: nanoid(),
    AM: "",
    coordonnees: "",
    nom: "",
    deleguesList: [],
  };
};

const initStructureInfos = (): StructureInfos => {
  return {
    structuresList: [],
  };
};

const initDateDeclaration = (): DateDeclaration => {
  return {
    id: nanoid(),
    conditions: [],
    conditionType: "and",
    date: "",
  };
};

const initDateDeclarationInfos = (): DateDeclarationInfos => {
  return {
    dateDeclarationsList: [],
  };
};

const initProtocole = (): Protocole => {
  return {
    id: nanoid(),
    //dateDeclarationARS: "",
    nature: "",
  };
};

const initProtocoleInfos = (): ProtocoleInfos => {
  return {
    protocolesList: [],
  };
};

const initMedecinDelegant = (): MedecinDelegant => {
  return {
    id: nanoid(),
    AM: "",
    NomPrenom: "",
    RPPS: "",
  };
};

const initMedecinDelegantInfos = (): MedecinDelegantInfos => {
  return {
    medecinsDelegantList: [],
  };
};

const initMedecinsTraitantInfos = (): MedecinTraitantInfos => {
  return {
    actions: [],
    medecinTraitantsList: [],
    consentText: "",
  };
};

const CreateOrEditMedecinTraitantAction: React.FC<{
  isCreate: boolean;
  editInfos: MedecinTraitantAction;
  closePopup: () => void;
  questionnaireData: IForm;
  pushNewAction?: (newAction: MedecinTraitantAction) => void;
  editThisAction?: (actionToEdit: MedecinTraitantAction) => void;
}> = (props) => {
  const {
    isCreate,
    editInfos,
    closePopup,
    questionnaireData,
    pushNewAction,
    editThisAction,
  } = props;
  const [medecinTraitantAction, setMedecinTraitantAction] =
    React.useState<MedecinTraitantAction>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setMedecinTraitantAction(editInfos);
    }
  }, [editInfos]);

  if (!medecinTraitantAction) {
    return <></>;
  }
  return (
    <>
      <GenericFromPopup
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `une action`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="20px"
        >
          <GenericTextInput
            fieldTitle="Titre"
            isMandatory={false}
            id="input-title"
            placeholder="Sans titre"
            value={medecinTraitantAction.title}
            onChange={(e) => {
              setMedecinTraitantAction((x) => {
                return { ...x, title: e.target.value };
              });
            }}
          />
          <Grid item display="grid">
            <GenericSelect
              fieldTitle={
                "Action à effectuer si les conditions sont respectées"
              }
              options={medecinTraitantActionOptions}
              value={medecinTraitantActionOptions.find(
                (x) => x.value === medecinTraitantAction.action
              )}
              maxMenuHeight={"200px"}
              id={"action-medecin-traitant"}
              isClearable={false}
              onChange={(e: any) => {
                setMedecinTraitantAction((x) => {
                  return { ...x, action: e?.value };
                });
              }}
              placeholder=""
              isMandatory={true}
            />
          </Grid>
          <Grid item display="grid">
            <MyConditionsHandler
              conditionType={medecinTraitantAction?.conditionType}
              conditions={medecinTraitantAction?.conditions}
              updateConditionType={(value) => {
                setMedecinTraitantAction((state) => {
                  return {
                    ...state,
                    conditionType: value,
                  };
                });
              }}
              deleteConditionByIndex={(index) => {
                setMedecinTraitantAction((state) => {
                  return {
                    ...state,
                    conditions: state.conditions.filter((x, i) => index !== i),
                  };
                });
              }}
              addCondition={(condition) => {
                setMedecinTraitantAction((x) => {
                  return { ...x, conditions: [...x.conditions, condition] };
                });
              }}
              questionnaireData={questionnaireData}
            />
          </Grid>
          <Grid item display="grid">
            <OrangeCheckBox
              value={medecinTraitantAction?.consentIsMandatory}
              onClick={() =>
                setMedecinTraitantAction((x) => {
                  return {
                    ...x,
                    consentIsMandatory: x.consentIsMandatory ? false : true,
                  };
                })
              }
              text="Effectuer l'action si le patient est consentant"
            />
          </Grid>
          <Grid item display="grid">
            <OrangeCheckBox
              value={medecinTraitantAction?.notConsentIsMandatory}
              onClick={() =>
                setMedecinTraitantAction((x) => {
                  return {
                    ...x,
                    notConsentIsMandatory: x.notConsentIsMandatory
                      ? false
                      : true,
                  };
                })
              }
              text="Effectuer l'action si le patient n'est pas consentant"
            />
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noCondition: "Aucune condition n'a été ajoutée.",
              }}
              errorSelector={{
                noCondition: "#add-cond-button",
              }}
              invalidConditions={{
                noCondition:
                  !medecinTraitantAction?.conditions ||
                  medecinTraitantAction?.conditions?.length == 0,
              }}
              onSendClick={() => {
                if (isCreate) {
                  if (pushNewAction) {
                    pushNewAction(medecinTraitantAction);
                  }
                  closePopup();
                } else {
                  if (editThisAction) {
                    editThisAction(medecinTraitantAction);
                  }
                  closePopup();
                }
              }}
              isLoading={false}
              textButton={isCreate ? "Ajouter" : "Modifier"}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const MedecinOrIDEInfos: React.FC<{
  medecinOrIDE: Medecin;
  title: string;
  onEditClick: () => void;
}> = (props) => {
  return (
    <Grid item display="grid">
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="10px"
        style={{
          fontSize: "16px",
          color: "#0B243C",
          fontFamily: "Poppins",
          whiteSpace: "pre-line",
          textAlign: "left",
        }}
      >
        <Grid item display="grid">
          <span>{props.title}</span>
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            wrap="nowrap"
            gap="10px"
            alignItems={"center"}
          >
            <Grid item display={"grid"}>
              <ButtonIconWithTooltip
                tooltipText="Modifier"
                Icon={
                  <EditRounded fontSize="small" style={{ color: "#0B243C" }} />
                }
                onClick={props.onEditClick}
              />
            </Grid>
            <Grid item display="grid">
              <Grid container direction="column" wrap="nowrap" gap="5px">
                {props.medecinOrIDE?.nom && props.medecinOrIDE?.prenom && (
                  <Grid item display="grid">
                    <span>
                      {props.medecinOrIDE.nom} {props.medecinOrIDE.prenom}
                    </span>
                  </Grid>
                )}
                <Grid item display="grid">
                  {props.medecinOrIDE?.mail && (
                    <span>{props.medecinOrIDE.mail}</span>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CreateOrEditCondition: React.FC<{
  isCreate: boolean;
  editInfos: ConditionDisplayField;
  closePopup: () => void;
  questionnaireData: IForm;
  pushNewCondition?: (newMedecin: ConditionDisplayField) => void;
  editThisCondition?: (medecinToEdit: ConditionDisplayField) => void;
}> = (props) => {
  const {
    isCreate,
    editInfos,
    closePopup,
    pushNewCondition,
    editThisCondition,
    questionnaireData,
  } = props;
  const [condition, setCondition] =
    React.useState<ConditionDisplayField>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setCondition(editInfos);
    }
  }, [editInfos]);

  if (!condition) {
    return <></>;
  }
  return (
    <>
      <GenericFromPopup
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `une condition d'affichage`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="15px"
        >
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="Titre"
              placeholder="Sans titre"
              id="condition-titre"
              onChange={(e) => {
                const newText = e?.target?.value;
                setCondition((x) => {
                  return { ...x, title: newText };
                });
              }}
              value={condition?.title}
              isMandatory={false}
            />
          </Grid>
          <Grid item display="grid">
            <MyConditionsHandler
              conditionType={condition?.conditionType}
              conditions={condition?.conditions}
              updateConditionType={(value) => {
                setCondition((state) => {
                  return {
                    ...state,
                    conditionType: value,
                  };
                });
              }}
              deleteConditionByIndex={(index) => {
                setCondition((state) => {
                  return {
                    ...state,
                    conditions: state.conditions.filter((x, i) => index !== i),
                  };
                });
              }}
              addCondition={(condition) => {
                setCondition((x) => {
                  return { ...x, conditions: [...x.conditions, condition] };
                });
              }}
              questionnaireData={questionnaireData}
            />
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noCondition: "Aucune condition n'a été ajoutée",
              }}
              errorSelector={{
                noCondition: "add-cond-button",
              }}
              invalidConditions={{
                noCondition:
                  !condition?.conditions || condition?.conditions?.length == 0,
              }}
              onSendClick={() => {
                if (isCreate) {
                  if (pushNewCondition) {
                    pushNewCondition(condition);
                  }
                  closePopup();
                } else {
                  if (editThisCondition) {
                    editThisCondition(condition);
                  }
                  closePopup();
                }
                return;
              }}
              isLoading={false}
              textButton={isCreate ? "Ajouter" : "Modifier"}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const CreateOrEditMedecinDelegant: React.FC<{
  isCreate: boolean;
  editInfos: MedecinDelegant;
  closePopup: () => void;
  pushNewMedecinDelegant?: (newMedecin: MedecinDelegant) => void;
  editThisMedecinDelegant?: (medecinToEdit: MedecinDelegant) => void;
}> = (props) => {
  const {
    isCreate,
    editInfos,
    closePopup,
    pushNewMedecinDelegant,
    editThisMedecinDelegant,
  } = props;
  const [medecinDelegant, setMedecinDelegant] =
    React.useState<MedecinDelegant>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setMedecinDelegant(editInfos);
    }
  }, [editInfos]);

  if (!medecinDelegant) {
    return <></>;
  }
  return (
    <>
      <GenericFromPopup
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `un médecin délégant`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="15px"
        >
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="Nom et prénom"
              id="nom-prenom-medecin-delegant"
              onChange={(e) => {
                const newText = e?.target?.value;
                setMedecinDelegant((x) => {
                  return { ...x, NomPrenom: newText };
                });
              }}
              value={medecinDelegant?.NomPrenom}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="N° AM"
              id="am-medecin-delegant"
              onChange={(e) => {
                const newText = e?.target?.value;
                setMedecinDelegant((x) => {
                  return { ...x, AM: newText };
                });
              }}
              value={medecinDelegant?.AM}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="N° RPPS"
              id="rpps-medecin-delegant"
              onChange={(e) => {
                const newText = e?.target?.value;
                setMedecinDelegant((x) => {
                  return { ...x, RPPS: newText };
                });
              }}
              value={medecinDelegant?.RPPS}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noName: "Le nom n'est pas renseigné",
                noAM: "Aucun numéro AM n'est renseigné",
                noRPPS: "Aucun numéro RPPS n'est renseigné",
              }}
              errorSelector={{
                noName: "nom-prenom-medecin-delegant",
                noAM: "am-medecin-delegant",
                noRPPS: "rpps-medecin-delegant",
              }}
              invalidConditions={{
                noName: !medecinDelegant?.NomPrenom,
                noAM: !medecinDelegant?.AM,
                noRPPS: !medecinDelegant?.RPPS,
              }}
              onSendClick={() => {
                if (isCreate) {
                  if (pushNewMedecinDelegant) {
                    pushNewMedecinDelegant(medecinDelegant);
                  }
                  closePopup();
                } else {
                  if (editThisMedecinDelegant) {
                    editThisMedecinDelegant(medecinDelegant);
                  }
                  closePopup();
                }
                return;
              }}
              isLoading={false}
              textButton={isCreate ? "Ajouter" : "Modifier"}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const CreateOrEditProtocole: React.FC<{
  isCreate: boolean;
  editInfos: Protocole;
  closePopup: () => void;
  pushNewProtocole?: (newMedecin: Protocole) => void;
  editThisProtocole?: (medecinToEdit: Protocole) => void;
}> = (props) => {
  const {
    isCreate,
    editInfos,
    closePopup,
    pushNewProtocole,
    editThisProtocole,
  } = props;
  const [protocole, setProtocole] = React.useState<Protocole>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setProtocole(editInfos);
    }
  }, [editInfos]);

  if (!protocole) {
    return <></>;
  }
  return (
    <>
      <GenericFromPopup
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `un protocole`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="15px"
        >
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="Nature du protocole concerné"
              id="nature-protocole"
              onChange={(e) => {
                const newText = e?.target?.value;
                setProtocole((x) => {
                  return { ...x, nature: newText };
                });
              }}
              value={protocole?.nature}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          {/*
          <Grid>
            <GenericDateInput
              fieldTitle="Date de déclaration à l'ARS du protocolé concerné ce jour"
              id="date-protocole"
              value={protocole?.dateDeclarationARS}
              onChange={(e) => {
                setProtocole((x) => {
                  return { ...x, dateDeclarationARS: e?.target?.value };
                });
              }}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
            */}
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noNature: "La nature n'est pas renseignée.",
                //noDate: "La date n'est pas renseignée.",
              }}
              errorSelector={{
                noNature: "#nature-protocole",
                //noDate: "#date-protocole",
              }}
              invalidConditions={{
                noNature: !protocole?.nature,
                //noDate: !protocole?.dateDeclarationARS,
              }}
              onSendClick={() => {
                if (isCreate) {
                  if (pushNewProtocole) {
                    pushNewProtocole(protocole);
                  }
                  closePopup();
                } else {
                  if (editThisProtocole) {
                    editThisProtocole(protocole);
                  }
                  closePopup();
                }
                return;
              }}
              isLoading={false}
              textButton={isCreate ? "Ajouter" : "Modifier"}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const CreateOrEditDateDeclaration: React.FC<{
  isCreate: boolean;
  editInfos: DateDeclaration;
  closePopup: () => void;
  pushNewDateDeclaration?: (newDateDeclaration: DateDeclaration) => void;
  editThisDateDeclaration?: (dateDeclarationToEdit: DateDeclaration) => void;
  questionnaireData: Partial<IForm>;
}> = (props) => {
  const {
    isCreate,
    editInfos,
    closePopup,
    pushNewDateDeclaration,
    editThisDateDeclaration,
    questionnaireData,
  } = props;
  const [dateDeclaration, setDateDeclaration] =
    React.useState<DateDeclaration>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setDateDeclaration(editInfos);
    }
  }, [editInfos]);

  if (!dateDeclaration) {
    return <></>;
  }
  return (
    <>
      <GenericFromPopup
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `une date de déclaration`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="15px"
        >
          <Grid>
            <GenericDateInput
              fieldTitle="Date de déclaration à l'ARS"
              id="date-declaration-ars"
              value={dateDeclaration?.date}
              onChange={(e) => {
                setDateDeclaration((x) => {
                  return { ...x, date: e?.target?.value };
                });
              }}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <MyConditionsHandler
              conditions={dateDeclaration?.conditions}
              conditionType={dateDeclaration?.conditionType}
              addCondition={(condition) => {
                setDateDeclaration((state) => {
                  return {
                    ...state,
                    conditions: [...state.conditions, condition],
                  };
                });
              }}
              updateConditionType={(value) => {
                setDateDeclaration((state) => {
                  return {
                    ...state,
                    conditionType: value,
                  };
                });
              }}
              deleteConditionByIndex={(index) => {
                setDateDeclaration((state) => {
                  return {
                    ...state,
                    conditions: state.conditions.filter((x, i) => index !== i),
                  };
                });
              }}
              questionnaireData={questionnaireData}
            />
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noCondition: "Aucune condition n'a été ajoutée.",
                noDate: "La date n'est pas renseignée.",
              }}
              errorSelector={{
                noDate: "#date-declaration-ars",
                noCondition: "#add-cond-button",
              }}
              invalidConditions={{
                noDate: !dateDeclaration?.date,
                noCondition:
                  !dateDeclaration?.conditions ||
                  dateDeclaration?.conditions?.length == 0,
              }}
              onSendClick={() => {
                if (isCreate) {
                  if (pushNewDateDeclaration) {
                    pushNewDateDeclaration(dateDeclaration);
                  }
                  closePopup();
                } else {
                  if (editThisDateDeclaration) {
                    editThisDateDeclaration(dateDeclaration);
                  }
                  closePopup();
                }
                return;
              }}
              isLoading={false}
              textButton={isCreate ? "Ajouter" : "Modifier"}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const CreateOrEditDelegue: React.FC<{
  isCreate: boolean;
  editInfos: Delegue;
  closePopup: () => void;
  pushNewDelegue?: (newDelegue: Delegue) => void;
  editThisDelegue?: (delegueToEdit: Delegue) => void;
  questionnaireData: Partial<IForm>;
}> = (props) => {
  const {
    isCreate,
    editInfos,
    closePopup,
    pushNewDelegue,
    editThisDelegue,
    questionnaireData,
  } = props;
  const [delegue, setDelegue] = React.useState<Delegue>(undefined);
  const [createDeclarationDateOpen, setCreateDeclarationDateOpen] =
    React.useState<boolean>(false);
  const [editDeclarationDate, setEditDeclarationDate] =
    React.useState<DateDeclaration>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setDelegue(editInfos);
    }
  }, [editInfos]);

  if (!delegue) {
    return <></>;
  }
  return (
    <>
      {createDeclarationDateOpen && (
        <CreateOrEditDateDeclaration
          questionnaireData={questionnaireData}
          isCreate={true}
          editInfos={initDateDeclaration()}
          closePopup={() => setCreateDeclarationDateOpen(false)}
          pushNewDateDeclaration={(newDateDeclaration) => {
            setDelegue((x) => {
              const delegueTmp: Delegue = { ...x };

              if (!delegueTmp.dateDeclarationInfos) {
                delegueTmp.dateDeclarationInfos = initDateDeclarationInfos();
              } else {
                delegueTmp.dateDeclarationInfos = {
                  ...x.dateDeclarationInfos,
                };
              }
              delegueTmp.dateDeclarationInfos.dateDeclarationsList = [
                ...delegueTmp.dateDeclarationInfos.dateDeclarationsList,
                newDateDeclaration,
              ];
              return delegueTmp;
            });
          }}
        />
      )}
      {editDeclarationDate && (
        <CreateOrEditDateDeclaration
          questionnaireData={questionnaireData}
          isCreate={false}
          editInfos={editDeclarationDate}
          closePopup={() => setEditDeclarationDate(undefined)}
          editThisDateDeclaration={(dateDeclarationToEdit) => {
            setDelegue((x) => {
              const delegueTmp: Delegue = { ...x };
              if (!delegueTmp.dateDeclarationInfos) {
                delegueTmp.dateDeclarationInfos = initDateDeclarationInfos();
              } else {
                delegueTmp.dateDeclarationInfos = {
                  ...x.dateDeclarationInfos,
                };
              }
              delegueTmp.dateDeclarationInfos.dateDeclarationsList = [
                ...delegueTmp.dateDeclarationInfos.dateDeclarationsList,
              ].map((dateDeclaration) => {
                if (dateDeclaration?.id === dateDeclarationToEdit?.id) {
                  return dateDeclarationToEdit;
                }
                return dateDeclaration;
              });
              return delegueTmp;
            });
          }}
        />
      )}
      <GenericFromPopup
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `un délégué`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="15px"
        >
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="Nom et prénom du délégué"
              id="nom-prenom-delegue"
              onChange={(e) => {
                const newText = e?.target?.value;
                setDelegue((x) => {
                  return { ...x, nomPrenom: newText };
                });
              }}
              value={delegue?.nomPrenom}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="RPPS du délégué"
              id="rpps-delegue"
              onChange={(e) => {
                const newText = e?.target?.value;
                setDelegue((x) => {
                  return { ...x, RPPS: newText };
                });
              }}
              value={delegue?.RPPS}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" gap="10px">
              <Grid item display="grid" marginTop="20px">
                <AddButton
                  onClick={() => {
                    setCreateDeclarationDateOpen(true);
                  }}
                  addId={"add-declaration-date-button-date-button"}
                  displayText={true}
                  text="Ajouter une date de déclaration"
                />
              </Grid>
              <Grid item display="grid">
                {delegue?.dateDeclarationInfos?.dateDeclarationsList &&
                delegue?.dateDeclarationInfos?.dateDeclarationsList?.length >
                  0 ? (
                  <Grid container direction="row" gap="10px">
                    {delegue.dateDeclarationInfos.dateDeclarationsList.map(
                      (dateDeclaration, index) => {
                        return (
                          <Grid item display="grid" key={index}>
                            <Grid
                              container
                              direction="row"
                              wrap="nowrap"
                              style={{
                                border: "1px solid rgba(11, 36, 60)",
                                backgroundColor: "rgba(247, 252, 252, 1)",
                                boxShadow:
                                  "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                              }}
                              paddingLeft="7px"
                              paddingRight="7px"
                              borderRadius="10px"
                            >
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                                alignItems="center"
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  {dateDeclaration?.date
                                    ? `${dateDeclaration.date}`
                                    : "Non renseigné"}
                                </span>
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Edit
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEditDeclarationDate(dateDeclaration);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Delete
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setDelegue((x) => {
                                      if (
                                        !x?.dateDeclarationInfos
                                          ?.dateDeclarationsList
                                      ) {
                                        return {
                                          ...x,
                                          dateDeclarationInfos: {
                                            dateDeclarationsList: [],
                                          },
                                        };
                                      }
                                      return {
                                        ...x,
                                        dateDeclarationInfos: {
                                          dateDeclarationsList:
                                            x.dateDeclarationInfos.dateDeclarationsList.filter(
                                              (x) =>
                                                x?.id !== dateDeclaration?.id
                                            ),
                                        },
                                      };
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                ) : (
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#0B243C",
                      textAlign: "end",
                    }}
                  >{`Aucune date de déclaration à l'ARS n'a été ajoutée`}</span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noName: "Le nom n'est pas renseigné.",
                noRPPS: "Le numéro RPPS n'est pas renseigné.",
                //noDeclaration: "Aucune date de déclaration n'a été ajoutée.",
              }}
              errorSelector={{
                noName: "#nom-structure",
                noRPPS: "#rpps-structure",
                //noDeclaration: "#add-declaration-date-button",
              }}
              invalidConditions={{
                noName: !delegue?.nomPrenom,
                noRPPS: !delegue?.RPPS,
                /*noDeclaration:
                  !delegue?.dateDeclarationInfos?.dateDeclarationsList ||
                  delegue?.dateDeclarationInfos?.dateDeclarationsList?.length ==
                    0,*/
              }}
              onSendClick={() => {
                if (isCreate) {
                  if (pushNewDelegue) {
                    pushNewDelegue(delegue);
                  }
                  closePopup();
                } else {
                  if (editThisDelegue) {
                    editThisDelegue(delegue);
                  }
                  closePopup();
                }
                return;
              }}
              isLoading={false}
              textButton={isCreate ? "Ajouter" : "Modifier"}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const CreateOrEditStructure: React.FC<{
  isCreate: boolean;
  editInfos: Structure;
  closePopup: () => void;
  pushNewStructure?: (newMedecin: Structure) => void;
  editThisStructure?: (medecinToEdit: Structure) => void;
  questionnaireData: Partial<IForm>;
}> = (props) => {
  const {
    isCreate,
    editInfos,
    closePopup,
    pushNewStructure,
    editThisStructure,
    questionnaireData,
  } = props;
  const [structure, setStructure] = React.useState<Structure>(undefined);

  // Délégués
  const [createDelegueOpen, setCreateDelegueOpen] =
    React.useState<boolean>(false);
  const [editDelegueOpen, setEditDelegueOpen] =
    React.useState<Delegue>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setStructure(editInfos);
    }
  }, [editInfos]);

  if (!structure) {
    return <></>;
  }
  return (
    <>
      {createDelegueOpen && (
        <CreateOrEditDelegue
          questionnaireData={questionnaireData}
          isCreate={true}
          editInfos={initDelegue()}
          closePopup={() => setCreateDelegueOpen(false)}
          pushNewDelegue={(newDelegue) => {
            setStructure((x) => {
              return { ...x, deleguesList: [...x.deleguesList, newDelegue] };
            });
          }}
        />
      )}
      {editDelegueOpen && (
        <CreateOrEditDelegue
          questionnaireData={questionnaireData}
          isCreate={false}
          editInfos={editDelegueOpen}
          closePopup={() => setEditDelegueOpen(undefined)}
          editThisDelegue={(delegueToEdit) => {
            setStructure((x) => {
              return {
                ...x,
                deleguesList: [...x.deleguesList].map((delegue) => {
                  if (delegue?.id === delegueToEdit?.id) {
                    return delegueToEdit;
                  }
                  return delegue;
                }),
              };
            });
          }}
        />
      )}
      <GenericFromPopup
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `un structure`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="15px"
        >
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="Nom de la structure"
              id="nom-structure"
              onChange={(e) => {
                const newText = e?.target?.value;
                setStructure((x) => {
                  return { ...x, nom: newText };
                });
              }}
              value={structure?.nom}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="N° AM de la structure"
              id="am-structure"
              onChange={(e) => {
                const newText = e?.target?.value;
                setStructure((x) => {
                  return { ...x, AM: newText };
                });
              }}
              value={structure?.AM}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <GenericTextInput
              fieldTitle="Coordonnées de la structure"
              id="coordonnes-structure"
              onChange={(e) => {
                const newText = e?.target?.value;
                setStructure((x) => {
                  return { ...x, coordonnees: newText };
                });
              }}
              value={structure?.coordonnees}
              isMandatory={true}
              placeholder=""
            />
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" gap="10px">
              <Grid item display="grid" marginTop="20px">
                <AddButton
                  onClick={() => {
                    setCreateDelegueOpen(true);
                  }}
                  addId={"add-delegue-button"}
                  displayText={true}
                  text="Ajouter un délégué"
                />
              </Grid>
              <Grid item display="grid">
                {structure?.deleguesList &&
                structure?.deleguesList?.length > 0 ? (
                  <Grid container direction="row" gap="10px">
                    {structure.deleguesList.map((delegue, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            style={{
                              border: "1px solid rgba(11, 36, 60)",
                              backgroundColor: "rgba(247, 252, 252, 1)",
                              boxShadow: "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                            }}
                            paddingLeft="7px"
                            paddingRight="7px"
                            borderRadius="10px"
                          >
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                              alignItems="center"
                            >
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  color: "#0B243C",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {delegue?.nomPrenom
                                  ? `${delegue.nomPrenom}`
                                  : "Non renseigné"}
                              </span>
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Edit
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setEditDelegueOpen(delegue);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Delete
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setStructure((x) => {
                                    return {
                                      ...x,
                                      deleguesList: [...x.deleguesList].filter(
                                        (x) => x?.id !== delegue?.id
                                      ),
                                    };
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#0B243C",
                      textAlign: "end",
                    }}
                  >{`Aucun délégué n'a été ajouté`}</span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noName: "Le nom n'est pas renseigné.",
                noAM: "Le numéro AM n'est pas renseigné.",
                noCoordonnees: "Les coordonnées ne sont pas renseignées.",
                noDelegue: "Aucun délégué n'a été ajouté.",
              }}
              errorSelector={{
                noName: "#nom-structure",
                noAM: "#am-structure",
                noCoordonnees: "#coordonnees-structure",
                noDelegue: "#add-delegue-button",
              }}
              invalidConditions={{
                noName: !structure?.nom,
                noAM: !structure?.AM,
                noCoordonnees: !structure?.coordonnees,
                noDelegue:
                  !structure?.deleguesList ||
                  structure?.deleguesList?.length == 0,
              }}
              onSendClick={() => {
                if (isCreate) {
                  if (pushNewStructure) {
                    pushNewStructure(structure);
                  }
                  closePopup();
                } else {
                  if (editThisStructure) {
                    editThisStructure(structure);
                  }
                  closePopup();
                }
                return;
              }}
              isLoading={false}
              textButton={isCreate ? "Ajouter" : "Modifier"}
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const CreateOrEditMedecinTraitant: React.FC<{
  isCreate: boolean;
  editInfos: MedecinTraitant;
  closePopup: () => void;
  pushNewMedecin?: (newMedecin: MedecinTraitant) => void;
  editThisMedecin?: (medecinToEdit: MedecinTraitant) => void;
}> = (props) => {
  const { isCreate, editInfos, closePopup, pushNewMedecin, editThisMedecin } =
    props;
  const [medecinTraitant, setMedecinTraitant] =
    React.useState<MedecinTraitant>(undefined);

  React.useEffect(() => {
    if (editInfos) {
      setMedecinTraitant(editInfos);
    }
  }, [editInfos]);

  if (!medecinTraitant) {
    return <></>;
  }
  return (
    <>
      <GenericFromPopup
        onClose={closePopup}
        title={{
          leftPart: isCreate ? `Ajouter` : "Modifier",
          rightPart: `un médecin traitant`,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="30px"
        >
          {!medecinTraitant?.ideInfos &&
          medecinTraitant?.medecinInfos &&
          medecinTraitant?.haveContractWithIDE ? (
            <Grid item display="grid">
              <MedimailSearch
                headerText="Commencez par rechercher l'IDE, puis sélectionnez-le."
                displayOnlyNameAndLastName={true}
                onUpdateSelected={(selected) => {
                  if (!selected || selected?.length == 0) {
                    return;
                  }
                  setMedecinTraitant((x) => {
                    return { ...x, ideInfos: selected[0] };
                  });
                }}
              />
            </Grid>
          ) : (
            <>
              {!medecinTraitant?.medecinInfos ? (
                <Grid item display="grid">
                  <MedimailSearch
                    headerText="Commencez par rechercher le médecin traitant, puis sélectionnez-le."
                    displayOnlyNameAndLastName={true}
                    onUpdateSelected={(selected) => {
                      if (!selected || selected?.length == 0) {
                        return;
                      }
                      setMedecinTraitant((x) => {
                        return { ...x, medecinInfos: selected[0] };
                      });
                    }}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item display="grid">
                    <MedecinOrIDEInfos
                      title={`Médecin traitant selectionné`}
                      medecinOrIDE={medecinTraitant?.medecinInfos}
                      onEditClick={() => {
                        setMedecinTraitant((x) => {
                          return { ...x, medecinInfos: undefined };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <OrangeCheckBox
                      value={
                        medecinTraitant?.haveContractWithIDE ? true : false
                      }
                      onClick={() => {
                        setMedecinTraitant((x) => {
                          return {
                            ...x,
                            haveContractWithIDE: x?.haveContractWithIDE
                              ? false
                              : true,
                          };
                        });
                        return;
                      }}
                      text="Ce médecin traitant a signé un contrat avec un IDE"
                    />
                  </Grid>
                  {medecinTraitant?.ideInfos &&
                    medecinTraitant?.haveContractWithIDE && (
                      <Grid item display="grid">
                        <MedecinOrIDEInfos
                          title={`IDE selectionné`}
                          medecinOrIDE={medecinTraitant?.ideInfos}
                          onEditClick={() => {
                            setMedecinTraitant((x) => {
                              return { ...x, ideInfos: undefined };
                            });
                          }}
                        />
                      </Grid>
                    )}
                  <Grid item display="grid">
                    <GenericSendButton
                      errorMessages={{}}
                      errorSelector={{}}
                      invalidConditions={{}}
                      onSendClick={() => {
                        if (isCreate) {
                          if (pushNewMedecin) {
                            pushNewMedecin(medecinTraitant);
                          }
                          closePopup();
                        } else {
                          if (editThisMedecin) {
                            editThisMedecin(medecinTraitant);
                          }
                          closePopup();
                        }
                        return;
                      }}
                      isLoading={false}
                      textButton={isCreate ? "Ajouter" : "Modifier"}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </GenericFromPopup>
    </>
  );
};

const QuestionnairePopupEdit: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const fieldCopy: IField = props?.fieldCopy;
  const questionnaireData: IForm = props?.questionnaireData;

  // Médecins traitants
  const [createMedecinTraitantActionOpen, setCreateMedecinTraitantActionOpen] =
    React.useState<boolean>(false);
  const [editMedecinTraitantActionOpen, setEditMedecinTraitantActionOpen] =
    React.useState<MedecinTraitantAction>(undefined);
  const [createMedecinTraitantOpen, setCreateMedecinTraitantOpen] =
    React.useState<boolean>(false);
  const [editMedecinTraitantOpen, setEditMedecinTraitantOpen] =
    React.useState<MedecinTraitant>(undefined);

  // Protocoles
  const [createProtocoleOpen, setCreateProtocoleOpen] =
    React.useState<boolean>(false);
  const [editProtocoleOpen, setEditProtocoleOpen] =
    React.useState<Protocole>(undefined);

  // Médecins délégants
  const [createMedecinDelegantOpen, setCreateMedecinDelegantOpen] =
    React.useState<boolean>(false);
  const [editMedecinDelegantOpen, setEditMedecinDelegantOpen] =
    React.useState<MedecinDelegant>(undefined);

  // Structures
  const [createStructureOpen, setCreateStructureOpen] =
    React.useState<boolean>(false);
  const [editStructureOpen, setEditStructureOpen] =
    React.useState<Structure>(undefined);

  // Conditions
  const [createConditionOpen, setCreateConditionOpen] =
    React.useState<boolean>(false);
  const [editConditionOpen, setEditConditionOpen] =
    React.useState<ConditionDisplayField>(undefined);

  return (
    <>
      {createStructureOpen && (
        <CreateOrEditStructure
          questionnaireData={questionnaireData}
          isCreate={true}
          editInfos={initStructure()}
          closePopup={() => setCreateStructureOpen(false)}
          pushNewStructure={(newStructure) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.structureInfos) {
              fieldCopyTmp.structureInfos = initStructureInfos();
            } else {
              fieldCopyTmp.structureInfos = {
                ...fieldCopy.structureInfos,
              };
            }
            fieldCopyTmp.structureInfos.structuresList = [
              ...fieldCopyTmp.structureInfos.structuresList,
              newStructure,
            ];
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {editStructureOpen && (
        <CreateOrEditStructure
          questionnaireData={questionnaireData}
          isCreate={false}
          editInfos={editStructureOpen}
          closePopup={() => setEditStructureOpen(undefined)}
          editThisStructure={(structureToEdit) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.structureInfos) {
              fieldCopyTmp.structureInfos = initStructureInfos();
            } else {
              fieldCopyTmp.structureInfos = {
                ...fieldCopy.structureInfos,
              };
            }
            fieldCopyTmp.structureInfos.structuresList = [
              ...fieldCopyTmp.structureInfos.structuresList,
            ].map((structure) => {
              if (structure?.id === structureToEdit?.id) {
                return structureToEdit;
              }
              return structure;
            });
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {createConditionOpen && (
        <CreateOrEditCondition
          isCreate={true}
          questionnaireData={questionnaireData}
          editInfos={initConditionDisplayField()}
          closePopup={() => setCreateConditionOpen(false)}
          pushNewCondition={(newCondition) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.conditionsInfos) {
              fieldCopyTmp.conditionsInfos = initConditionDisplayFieldInfos();
            } else {
              fieldCopyTmp.conditionsInfos = {
                ...fieldCopy.conditionsInfos,
              };
            }
            fieldCopyTmp.conditionsInfos.conditionsList = [
              ...fieldCopyTmp.conditionsInfos.conditionsList,
              newCondition,
            ];
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {editConditionOpen && (
        <CreateOrEditCondition
          questionnaireData={questionnaireData}
          isCreate={false}
          editInfos={editConditionOpen}
          closePopup={() => setEditConditionOpen(undefined)}
          editThisCondition={(conditionToEdit) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.conditionsInfos) {
              fieldCopyTmp.conditionsInfos = initConditionDisplayFieldInfos();
            } else {
              fieldCopyTmp.conditionsInfos = {
                ...fieldCopy.conditionsInfos,
              };
            }
            fieldCopyTmp.conditionsInfos.conditionsList = [
              ...fieldCopyTmp.conditionsInfos.conditionsList,
            ].map((condition) => {
              if (condition?.id === conditionToEdit?.id) {
                return conditionToEdit;
              }
              return condition;
            });
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {createMedecinDelegantOpen && (
        <CreateOrEditMedecinDelegant
          isCreate={true}
          editInfos={initMedecinDelegant()}
          closePopup={() => setCreateMedecinDelegantOpen(false)}
          pushNewMedecinDelegant={(newMedecinDelegant) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.medecinDelegantInfos) {
              fieldCopyTmp.medecinDelegantInfos = initMedecinDelegantInfos();
            } else {
              fieldCopyTmp.medecinDelegantInfos = {
                ...fieldCopy.medecinDelegantInfos,
              };
            }
            fieldCopyTmp.medecinDelegantInfos.medecinsDelegantList = [
              ...fieldCopyTmp.medecinDelegantInfos.medecinsDelegantList,
              newMedecinDelegant,
            ];
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {editMedecinDelegantOpen && (
        <CreateOrEditMedecinDelegant
          isCreate={false}
          editInfos={editMedecinDelegantOpen}
          closePopup={() => setEditMedecinDelegantOpen(undefined)}
          editThisMedecinDelegant={(medecinDelegantToEdit) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.medecinDelegantInfos) {
              fieldCopyTmp.medecinDelegantInfos = initMedecinDelegantInfos();
            } else {
              fieldCopyTmp.medecinDelegantInfos = {
                ...fieldCopy.medecinDelegantInfos,
              };
            }
            fieldCopyTmp.medecinDelegantInfos.medecinsDelegantList = [
              ...fieldCopyTmp.medecinDelegantInfos.medecinsDelegantList,
            ].map((medecinDelegant) => {
              if (medecinDelegant?.id === medecinDelegantToEdit?.id) {
                return medecinDelegantToEdit;
              }
              return medecinDelegant;
            });
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {createProtocoleOpen && (
        <CreateOrEditProtocole
          isCreate={true}
          editInfos={initProtocole()}
          closePopup={() => setCreateProtocoleOpen(false)}
          pushNewProtocole={(newProtocole) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.protocoleInfos) {
              fieldCopyTmp.protocoleInfos = initProtocoleInfos();
            } else {
              fieldCopyTmp.protocoleInfos = {
                ...fieldCopy.protocoleInfos,
              };
            }
            fieldCopyTmp.protocoleInfos.protocolesList = [
              ...fieldCopyTmp.protocoleInfos.protocolesList,
              newProtocole,
            ];
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {editProtocoleOpen && (
        <CreateOrEditProtocole
          isCreate={false}
          editInfos={editProtocoleOpen}
          closePopup={() => setEditProtocoleOpen(undefined)}
          editThisProtocole={(protocoleToEdit) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.protocoleInfos) {
              fieldCopyTmp.protocoleInfos = initProtocoleInfos();
            } else {
              fieldCopyTmp.protocoleInfos = {
                ...fieldCopy.protocoleInfos,
              };
            }
            fieldCopyTmp.protocoleInfos.protocolesList = [
              ...fieldCopyTmp.protocoleInfos.protocolesList,
            ].map((protocole) => {
              if (protocole?.id === protocoleToEdit?.id) {
                return protocoleToEdit;
              }
              return protocole;
            });
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {createMedecinTraitantOpen && (
        <CreateOrEditMedecinTraitant
          isCreate={true}
          editInfos={initMedecinTraitant()}
          closePopup={() => setCreateMedecinTraitantOpen(false)}
          pushNewMedecin={(newMedecin) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.medecinsTraitantInfos) {
              fieldCopyTmp.medecinsTraitantInfos = initMedecinsTraitantInfos();
            } else {
              fieldCopyTmp.medecinsTraitantInfos = {
                ...fieldCopy.medecinsTraitantInfos,
              };
            }
            fieldCopyTmp.medecinsTraitantInfos.medecinTraitantsList = [
              ...fieldCopyTmp.medecinsTraitantInfos.medecinTraitantsList,
              newMedecin,
            ];
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {editMedecinTraitantOpen && (
        <CreateOrEditMedecinTraitant
          isCreate={false}
          editInfos={editMedecinTraitantOpen}
          closePopup={() => setEditMedecinTraitantOpen(undefined)}
          editThisMedecin={(medecinToEdit) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.medecinsTraitantInfos) {
              fieldCopyTmp.medecinsTraitantInfos = initMedecinsTraitantInfos();
            } else {
              fieldCopyTmp.medecinsTraitantInfos = {
                ...fieldCopy.medecinsTraitantInfos,
              };
            }
            fieldCopyTmp.medecinsTraitantInfos.medecinTraitantsList = [
              ...fieldCopyTmp.medecinsTraitantInfos.medecinTraitantsList,
            ].map((medecin) => {
              if (medecin?.id === medecinToEdit?.id) {
                return medecinToEdit;
              }
              return medecin;
            });
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {createMedecinTraitantActionOpen && (
        <CreateOrEditMedecinTraitantAction
          questionnaireData={questionnaireData}
          isCreate={true}
          editInfos={initMedecinTraitantAction()}
          closePopup={() => setCreateMedecinTraitantActionOpen(false)}
          pushNewAction={(newAction) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.medecinsTraitantInfos) {
              fieldCopyTmp.medecinsTraitantInfos = initMedecinsTraitantInfos();
            } else {
              fieldCopyTmp.medecinsTraitantInfos = {
                ...fieldCopy.medecinsTraitantInfos,
              };
            }
            fieldCopyTmp.medecinsTraitantInfos.actions = [
              ...fieldCopyTmp.medecinsTraitantInfos.actions,
              newAction,
            ];
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      {editMedecinTraitantActionOpen && (
        <CreateOrEditMedecinTraitantAction
          questionnaireData={questionnaireData}
          isCreate={false}
          editInfos={editMedecinTraitantActionOpen}
          closePopup={() => setEditMedecinTraitantActionOpen(undefined)}
          editThisAction={(actionToEdit) => {
            const fieldCopyTmp: IField = { ...fieldCopy };
            if (!fieldCopyTmp.medecinsTraitantInfos) {
              fieldCopyTmp.medecinsTraitantInfos = initMedecinsTraitantInfos();
            } else {
              fieldCopyTmp.medecinsTraitantInfos = {
                ...fieldCopy.medecinsTraitantInfos,
              };
            }
            fieldCopyTmp.medecinsTraitantInfos.actions = [
              ...fieldCopyTmp.medecinsTraitantInfos.actions,
            ].map((action) => {
              if (action?.id === actionToEdit?.id) {
                return actionToEdit;
              }
              return action;
            });
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      )}
      <FormPopup xs={9}>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            item
            display="grid"
            paddingTop="50px"
            paddingLeft="60px"
            paddingRight="60px"
            paddingBottom="50px"
          >
            <FormPopupHeader
              firstTitle={"Modifier"}
              secondTitle={"un champ"}
              onClose={() => {
                dispatch(setFieldCopy(null));
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              justifySelf="center"
              width="70%"
              spacing="10px"
              paddingBottom="20px"
            >
              <Grid item display="grid">
                <DisplayType />
              </Grid>

              {![
                "display_text_and_image",
                "protocole",
                "medecin_delegant",
                "structure",
              ].includes(fieldCopy?.type) && (
                <Grid item display="grid">
                  <DisplayTitle />
                </Grid>
              )}
              {["text", "long_text"].includes(fieldCopy?.type) && (
                <>
                  <Grid item display="grid">
                    <GenericTextInput
                      fieldTitle="Valeur par défaut"
                      id="default-value"
                      value={fieldCopy?.defaultValue}
                      isMandatory={false}
                      placeholder="Aucune"
                      onChange={(e) => {
                        const fieldCopyTmp: IField = {
                          ...fieldCopy,
                        };
                        fieldCopyTmp.defaultValue = e?.target?.value;
                        dispatch(setFieldCopy(fieldCopyTmp));
                      }}
                    />
                  </Grid>
                </>
              )}
              {fieldCopy?.type === "display_text_and_image" && (
                <Grid item display="grid">
                  <DisplayTextAndImage />
                </Grid>
              )}
              {fieldCopy?.type === "long_text" && (
                <Grid item display="grid">
                  <DisplayLongText />
                </Grid>
              )}
              {fieldCopy?.type === "simple_check" && (
                <Grid item display="grid">
                  <DisplaySimpleCheck />
                </Grid>
              )}
              {fieldCopy?.type === "liste_deroulante" && (
                <Grid item display="grid">
                  <DisplayListeDeroulante />
                </Grid>
              )}
              {fieldCopy?.type === "echelle_notation" && (
                <Grid item display="grid">
                  <DisplayEchelleDeNotation />
                </Grid>
              )}
              {fieldCopy?.type === "addition_auto" && (
                <Grid item display="grid">
                  <DisplayAdditionAuto questionnaireData={questionnaireData} />
                </Grid>
              )}
              {fieldCopy?.type === "unique_choice" && (
                <Grid item display="grid">
                  <DisplayUniqueChoice />
                </Grid>
              )}
              {fieldCopy?.type === "medecin_delegant" && (
                <Grid item display="grid">
                  <Grid container direction="column" wrap="nowrap" gap="10px">
                    <Grid item display="grid" marginTop="20px">
                      <AddButton
                        onClick={() => {
                          setCreateMedecinDelegantOpen(true);
                        }}
                        addId={"add-medecin-delegant-button"}
                        displayText={true}
                        text="Ajouter un médecin délégant"
                      />
                    </Grid>
                    <Grid item display="grid">
                      {fieldCopy?.medecinDelegantInfos?.medecinsDelegantList &&
                      fieldCopy?.medecinDelegantInfos?.medecinsDelegantList
                        ?.length > 0 ? (
                        <Grid container direction="row" gap="10px">
                          {fieldCopy.medecinDelegantInfos.medecinsDelegantList.map(
                            (medecinDelegant, index) => {
                              return (
                                <Grid item display="grid" key={index}>
                                  <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    style={{
                                      border: "1px solid rgba(11, 36, 60)",
                                      backgroundColor: "rgba(247, 252, 252, 1)",
                                      boxShadow:
                                        "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                                    }}
                                    paddingLeft="7px"
                                    paddingRight="7px"
                                    borderRadius="10px"
                                  >
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                      alignItems="center"
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          color: "#0B243C",
                                          fontSize: "16px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {medecinDelegant?.NomPrenom
                                          ? `${medecinDelegant.NomPrenom}`
                                          : "Non renseigné"}
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Edit
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setEditMedecinDelegantOpen(
                                            medecinDelegant
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Delete
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const fieldCopyTmp: IField = {
                                            ...fieldCopy,
                                          };
                                          if (
                                            !fieldCopyTmp.medecinDelegantInfos
                                          ) {
                                            fieldCopyTmp.medecinDelegantInfos =
                                              initMedecinDelegantInfos();
                                          } else {
                                            fieldCopyTmp.medecinDelegantInfos =
                                              {
                                                ...fieldCopy.medecinDelegantInfos,
                                              };
                                          }
                                          fieldCopyTmp.medecinDelegantInfos.medecinsDelegantList =
                                            [
                                              ...fieldCopyTmp
                                                .medecinDelegantInfos
                                                .medecinsDelegantList,
                                            ].filter(
                                              (x) =>
                                                x?.id !== medecinDelegant?.id
                                            );
                                          dispatch(setFieldCopy(fieldCopyTmp));
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      ) : (
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "#0B243C",
                            textAlign: "end",
                          }}
                        >{`Aucun médecin délégant n'a été ajouté`}</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {fieldCopy?.type === "structure" && (
                <Grid item display="grid">
                  <Grid container direction="column" wrap="nowrap" gap="10px">
                    <Grid item display="grid" marginTop="20px">
                      <AddButton
                        onClick={() => {
                          setCreateStructureOpen(true);
                        }}
                        addId={"add-structure-button"}
                        displayText={true}
                        text="Ajouter une structure"
                      />
                    </Grid>
                    <Grid item display="grid">
                      {fieldCopy?.structureInfos?.structuresList &&
                      fieldCopy?.structureInfos?.structuresList?.length > 0 ? (
                        <Grid container direction="row" gap="10px">
                          {fieldCopy.structureInfos.structuresList.map(
                            (structure, index) => {
                              return (
                                <Grid item display="grid" key={index}>
                                  <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    style={{
                                      border: "1px solid rgba(11, 36, 60)",
                                      backgroundColor: "rgba(247, 252, 252, 1)",
                                      boxShadow:
                                        "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                                    }}
                                    paddingLeft="7px"
                                    paddingRight="7px"
                                    borderRadius="10px"
                                  >
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                      alignItems="center"
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          color: "#0B243C",
                                          fontSize: "16px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {structure?.nom
                                          ? `${structure.nom}`
                                          : "Non renseigné"}
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Edit
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setEditStructureOpen(structure);
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Delete
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const fieldCopyTmp: IField = {
                                            ...fieldCopy,
                                          };
                                          if (!fieldCopyTmp.structureInfos) {
                                            fieldCopyTmp.structureInfos =
                                              initStructureInfos();
                                          } else {
                                            fieldCopyTmp.structureInfos = {
                                              ...fieldCopy.structureInfos,
                                            };
                                          }
                                          fieldCopyTmp.structureInfos.structuresList =
                                            [
                                              ...fieldCopyTmp.structureInfos
                                                .structuresList,
                                            ].filter(
                                              (x) => x?.id !== structure?.id
                                            );
                                          dispatch(setFieldCopy(fieldCopyTmp));
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      ) : (
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "#0B243C",
                            textAlign: "end",
                          }}
                        >{`Aucune structure n'a été ajoutée`}</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {fieldCopy?.type === "protocole" && (
                <Grid item display="grid">
                  <Grid container direction="column" wrap="nowrap" gap="10px">
                    <Grid item display="grid" marginTop="20px">
                      <AddButton
                        onClick={() => {
                          setCreateProtocoleOpen(true);
                        }}
                        addId={"add-protocole-button"}
                        displayText={true}
                        text="Ajouter un protocole"
                      />
                    </Grid>
                    <Grid item display="grid">
                      {fieldCopy?.protocoleInfos?.protocolesList &&
                      fieldCopy?.protocoleInfos?.protocolesList?.length > 0 ? (
                        <Grid container direction="row" gap="10px">
                          {fieldCopy.protocoleInfos.protocolesList.map(
                            (protocole, index) => {
                              return (
                                <Grid item display="grid" key={index}>
                                  <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    style={{
                                      border: "1px solid rgba(11, 36, 60)",
                                      backgroundColor: "rgba(247, 252, 252, 1)",
                                      boxShadow:
                                        "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                                    }}
                                    paddingLeft="7px"
                                    paddingRight="7px"
                                    borderRadius="10px"
                                  >
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                      alignItems="center"
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          color: "#0B243C",
                                          fontSize: "16px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {protocole?.nature
                                          ? `${protocole.nature}`
                                          : "Non renseigné"}
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Edit
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setEditProtocoleOpen(protocole);
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Delete
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const fieldCopyTmp: IField = {
                                            ...fieldCopy,
                                          };
                                          if (!fieldCopyTmp.protocoleInfos) {
                                            fieldCopyTmp.protocoleInfos =
                                              initProtocoleInfos();
                                          } else {
                                            fieldCopyTmp.protocoleInfos = {
                                              ...fieldCopy.protocoleInfos,
                                            };
                                          }
                                          fieldCopyTmp.protocoleInfos.protocolesList =
                                            [
                                              ...fieldCopyTmp.protocoleInfos
                                                .protocolesList,
                                            ].filter(
                                              (x) => x?.id !== protocole?.id
                                            );
                                          dispatch(setFieldCopy(fieldCopyTmp));
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      ) : (
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "#0B243C",
                            textAlign: "end",
                          }}
                        >{`Aucun protocole n'a été ajouté`}</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {fieldCopy?.type === "attribut_patient" && (
                <Grid item display="grid">
                  <Grid container direction="column" wrap="nowrap" gap="10px">
                    <Grid item display="grid">
                      <GenericSelect
                        fieldTitle="Attribut"
                        id="choice-attribut-patient"
                        options={attributPatientOptions}
                        value={attributPatientOptions.find(
                          (x) => x?.value == fieldCopy?.attributPatientType
                        )}
                        placeholder=""
                        isClearable={false}
                        isMandatory={true}
                        maxMenuHeight="150px"
                        onChange={(e) => {
                          const fieldCopyTmp: IField = { ...fieldCopy };
                          fieldCopyTmp.attributPatientType = e?.value;
                          dispatch(setFieldCopy(fieldCopyTmp));
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {fieldCopy?.type === "medecins_traitants" && (
                <Grid item display="grid">
                  <Grid container direction="column" wrap="nowrap" gap="10px">
                    {!getConsentementPhrase(
                      questionnaireData,
                      fieldCopy?.id
                    ) && (
                      <Grid item display="grid">
                        <GenericLongText
                          fieldTitle="Consentement du patient"
                          isMandatory={true}
                          value={fieldCopy?.medecinsTraitantInfos?.consentText}
                          id={`consent-input`}
                          maxLength={20000}
                          onChange={(e) => {
                            const fieldCopyTmp: IField = { ...fieldCopy };
                            if (!fieldCopyTmp.medecinsTraitantInfos) {
                              fieldCopyTmp.medecinsTraitantInfos =
                                initMedecinsTraitantInfos();
                            } else {
                              fieldCopyTmp.medecinsTraitantInfos = {
                                ...fieldCopy.medecinsTraitantInfos,
                              };
                            }
                            if (e && typeof e === "string") {
                              fieldCopyTmp.medecinsTraitantInfos.consentText =
                                e;
                            } else {
                              fieldCopyTmp.medecinsTraitantInfos.consentText =
                                "";
                            }

                            dispatch(setFieldCopy(fieldCopyTmp));
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item display="grid" marginTop="20px">
                      <AddButton
                        onClick={() => {
                          setCreateMedecinTraitantOpen(true);
                        }}
                        addId={"add-medecin-traitant-button"}
                        displayText={true}
                        text="Ajouter un médecin traitant"
                      />
                    </Grid>
                    <Grid item display="grid">
                      {fieldCopy?.medecinsTraitantInfos?.medecinTraitantsList &&
                      fieldCopy?.medecinsTraitantInfos?.medecinTraitantsList
                        ?.length > 0 ? (
                        <Grid container direction="row" gap="10px">
                          {fieldCopy.medecinsTraitantInfos.medecinTraitantsList.map(
                            (medecinTraitant, index) => {
                              return (
                                <Grid item display="grid" key={index}>
                                  <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    style={{
                                      border: "1px solid rgba(11, 36, 60)",
                                      backgroundColor: "rgba(247, 252, 252, 1)",
                                      boxShadow:
                                        "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                                    }}
                                    paddingLeft="7px"
                                    paddingRight="7px"
                                    borderRadius="10px"
                                  >
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                      alignItems="center"
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          color: "#0B243C",
                                          fontSize: "16px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {medecinTraitant?.medecinInfos
                                          ?.prenom &&
                                        medecinTraitant?.medecinInfos?.nom
                                          ? `${medecinTraitant?.medecinInfos?.nom} ${medecinTraitant?.medecinInfos?.prenom}`
                                          : "Inconnu"}
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Edit
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setEditMedecinTraitantOpen(
                                            medecinTraitant
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Delete
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const fieldCopyTmp: IField = {
                                            ...fieldCopy,
                                          };
                                          if (
                                            !fieldCopyTmp.medecinsTraitantInfos
                                          ) {
                                            fieldCopyTmp.medecinsTraitantInfos =
                                              initMedecinsTraitantInfos();
                                          } else {
                                            fieldCopyTmp.medecinsTraitantInfos =
                                              {
                                                ...fieldCopy.medecinsTraitantInfos,
                                              };
                                          }
                                          fieldCopyTmp.medecinsTraitantInfos.medecinTraitantsList =
                                            [
                                              ...fieldCopyTmp
                                                .medecinsTraitantInfos
                                                .medecinTraitantsList,
                                            ].filter(
                                              (x) =>
                                                x?.id !== medecinTraitant?.id
                                            );
                                          dispatch(setFieldCopy(fieldCopyTmp));
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      ) : (
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "#0B243C",
                            textAlign: "end",
                          }}
                        >{`Aucun médecin traitant n'a été ajouté`}</span>
                      )}
                    </Grid>
                    <Grid item display="grid" marginTop="20px">
                      <AddButton
                        onClick={() => {
                          setCreateMedecinTraitantActionOpen(true);
                        }}
                        addId={"add-medecin-traitant-action-button"}
                        displayText={true}
                        text="Ajouter une action"
                      />
                    </Grid>
                    <Grid item display="grid">
                      {fieldCopy?.medecinsTraitantInfos?.actions &&
                      fieldCopy?.medecinsTraitantInfos?.actions?.length > 0 ? (
                        <Grid container direction="row" gap="10px">
                          {fieldCopy.medecinsTraitantInfos.actions.map(
                            (action, index) => {
                              return (
                                <Grid item display="grid" key={index}>
                                  <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    style={{
                                      border: "1px solid rgba(11, 36, 60)",
                                      backgroundColor: "rgba(247, 252, 252, 1)",
                                      boxShadow:
                                        "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                                    }}
                                    paddingLeft="7px"
                                    paddingRight="7px"
                                    borderRadius="10px"
                                  >
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                      alignItems="center"
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          color: "#0B243C",
                                          fontSize: "16px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {action?.title
                                          ? action.title
                                          : "Sans titre"}
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Edit
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setEditMedecinTraitantActionOpen(
                                            action
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      paddingLeft="5px"
                                      paddingRight="5px"
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          borderLeft: "1px solid #0B243C",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      display="grid"
                                      alignItems="center"
                                    >
                                      <Delete
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const fieldCopyTmp: IField = {
                                            ...fieldCopy,
                                          };
                                          if (
                                            !fieldCopyTmp.medecinsTraitantInfos
                                          ) {
                                            fieldCopyTmp.medecinsTraitantInfos =
                                              initMedecinsTraitantInfos();
                                          } else {
                                            fieldCopyTmp.medecinsTraitantInfos =
                                              {
                                                ...fieldCopy.medecinsTraitantInfos,
                                              };
                                          }
                                          fieldCopyTmp.medecinsTraitantInfos.actions =
                                            [
                                              ...fieldCopyTmp
                                                .medecinsTraitantInfos.actions,
                                            ].filter((x) => x.id !== action.id);
                                          dispatch(setFieldCopy(fieldCopyTmp));
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      ) : (
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "#0B243C",
                            textAlign: "end",
                          }}
                        >{`Aucune action n'a été ajoutée`}</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {["date_sortie"].includes(fieldCopy?.type) && (
                <Grid item display="grid" marginTop="10px">
                  <Grid container direction="column" gap="10px">
                    <Grid item display="grid">
                      <OrangeCheckBox
                        onClick={() => {
                          const fieldCopyTmp = { ...fieldCopy };

                          fieldCopyTmp.editPackDeclenchement =
                            fieldCopyTmp.editPackDeclenchement ? false : true;
                          dispatch(setFieldCopy(fieldCopyTmp));
                        }}
                        value={fieldCopy?.editPackDeclenchement === true}
                        text="Modifier la date de déclenchement du pack automatiquement"
                      />
                    </Grid>
                    <Grid item display="grid">
                      <OrangeCheckBox
                        onClick={() => {
                          const fieldCopyTmp = { ...fieldCopy };

                          fieldCopyTmp.sendAgainSMSWithFormAtJ1 =
                            fieldCopyTmp.sendAgainSMSWithFormAtJ1
                              ? false
                              : true;
                          dispatch(setFieldCopy(fieldCopyTmp));
                        }}
                        value={fieldCopy?.sendAgainSMSWithFormAtJ1 === true}
                        text="Renvoyer le SMS avec le questionnaire à J+1"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {![
                "display_text_and_image",
                "echelle_notation",
                "addition_auto",
                "date",
                "medecins_traitants",
                "attribut_patient",
                "protocole",
                "medecin_delegant",
                "structure",
                "date_sortie",
                "piece_jointe",
              ].includes(fieldCopy?.type) && (
                <>
                  <Grid item display="grid">
                    <DisplayAlertType />
                  </Grid>

                  {fieldCopy.alertType?.length > 0 && (
                    <Grid item display="grid">
                      <DisplayAlertChoice />
                    </Grid>
                  )}
                </>
              )}
              {!["display_text_and_image", "addition_auto", "piece_jointe"].includes(
                fieldCopy?.type
              ) && (
                <>
                  {fieldCopy?.type === "echelle_notation" && (
                    <Grid item display="grid" marginTop="10px">
                      <OrangeCheckBox
                        value={fieldCopy?.displayStarsNotation ? true : false}
                        onClick={() => {
                          const fieldCopyTmp = { ...fieldCopy };

                          fieldCopyTmp.displayStarsNotation = fieldCopyTmp?.displayStarsNotation ? false : true;
                          dispatch(setFieldCopy(fieldCopyTmp));
                        }}
                        text="Afficher sous forme d'étoile"
                      />
                    </Grid>
                  )}
                  <Grid item display="grid" marginTop="10px">
                    <OrangeCheckBox
                      value={fieldCopy?.isMandatory}
                      onClick={() => {
                        const fieldCopyTmp = { ...fieldCopy };

                        fieldCopyTmp.isMandatory = !fieldCopyTmp.isMandatory;
                        dispatch(setFieldCopy(fieldCopyTmp));
                      }}
                      text="Ce champ est obligatoire"
                    />
                  </Grid>
                  {fieldCopy?.isMandatory && (
                    <Grid item display="grid">
                      <OrangeCheckBox
                        value={fieldCopy?.hideAsterics ? true : false}
                        onClick={() => {
                          const fieldCopyTmp = { ...fieldCopy };

                          fieldCopyTmp.hideAsterics = fieldCopyTmp.hideAsterics
                            ? false
                            : true;
                          dispatch(setFieldCopy(fieldCopyTmp));
                        }}
                        text="Cacher l'astérisque"
                      />
                    </Grid>
                  )}
                  <Grid item display="grid">
                    <OrangeCheckBox
                      value={fieldCopy?.saveResponse ? true : false}
                      onClick={() => {
                        const fieldCopyTmp = { ...fieldCopy };

                        fieldCopyTmp.saveResponse = fieldCopyTmp?.saveResponse
                          ? false
                          : true;
                        dispatch(setFieldCopy(fieldCopyTmp));
                      }}
                      text="Mémoriser la réponse dans le cache"
                    />
                  </Grid>
                </>
              )}
              <Grid item display="grid">
                <AddButton
                  onClick={() => {
                    setCreateConditionOpen(true);
                  }}
                  addId={"add-condition-affichage-button"}
                  displayText={true}
                  text="Ajouter une condition d'affichage"
                />
              </Grid>
              <Grid item display="grid">
                {fieldCopy?.conditionsInfos?.conditionsList &&
                fieldCopy?.conditionsInfos?.conditionsList?.length > 0 ? (
                  <Grid container direction="row" gap="10px">
                    {fieldCopy.conditionsInfos.conditionsList.map(
                      (condition, index) => {
                        return (
                          <Grid item display="grid" key={index}>
                            <Grid
                              container
                              direction="row"
                              wrap="nowrap"
                              style={{
                                border: "1px solid rgba(11, 36, 60)",
                                backgroundColor: "rgba(247, 252, 252, 1)",
                                boxShadow:
                                  "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                              }}
                              paddingLeft="7px"
                              paddingRight="7px"
                              borderRadius="10px"
                            >
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                                alignItems="center"
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  {condition?.title
                                    ? condition.title
                                    : "Sans titre"}
                                </span>
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Edit
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEditConditionOpen(condition);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Delete
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const fieldCopyTmp: IField = {
                                      ...fieldCopy,
                                    };
                                    if (!fieldCopyTmp.conditionsInfos) {
                                      fieldCopyTmp.conditionsInfos =
                                        initConditionDisplayFieldInfos();
                                    } else {
                                      fieldCopyTmp.conditionsInfos = {
                                        ...fieldCopy.conditionsInfos,
                                      };
                                    }
                                    fieldCopyTmp.conditionsInfos.conditionsList =
                                      [
                                        ...fieldCopyTmp.conditionsInfos
                                          .conditionsList,
                                      ].filter((x) => x.id !== condition.id);
                                    dispatch(setFieldCopy(fieldCopyTmp));
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                ) : (
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#0B243C",
                      textAlign: "end",
                    }}
                  >{`Aucune condition n'a été ajoutée`}</span>
                )}
              </Grid>
              {fieldCopy?.type === "simple_check" && fieldCopy?.isMandatory && (
                <Grid item display="grid">
                  <DisplaySimpleCheckValidation />
                </Grid>
              )}
              <Grid item display="grid" marginTop="15px">
                <DisplayButtons {...props} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormPopup>
    </>
  );
};

export default QuestionnairePopupEdit;
