import FullPage from "./FullPage";
import * as React from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  Slider,
  Tooltip,
} from "@mui/material";
import BoxTitle from "../components/Library/Box/BoxTitle";
import {
  ChevronLeftOutlined,
  ClearRounded,
  EditRounded,
  PlaylistAddCheckCircleOutlined,
  PlaylistAddCheckOutlined,
  PlaylistRemoveOutlined,
} from "@mui/icons-material";
import { useHistory, useParams } from "react-router";
import AddButton from "../components/Library/Buttons/AddButton/AddButton";
import makeAPIRequest from "../utils/makeAPIRequest";
import { openSnackbar } from "../redux/snackbarReducer";
import { useDispatch } from "react-redux";
import CancellablePopup from "../components/Library/Popups/CancellablePopup";
import FullField from "../components/Fields/FullField";
import TextInput from "../components/Library/Inputs/TextInput";
import { IChatbot, IChatbotIntent } from "../types/ChatbotTypes";
import FormPopupHeader from "../components/FormPopup/FormPopupHeader";
import FormPopup from "../components/FormPopup/FormPopup";
import HandleErrors from "../components/Library/Errors/HandleErrors";
import MultilineInput from "../components/Library/Inputs/MultilineInput";
import { GenericSendButton } from "./ChatbotLexPage";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";

interface PercentageSliderProps {
  updateValue: (newValue: number) => void;
  value: number;
}

export const PercentageSlider: React.FC<PercentageSliderProps> = ({
  value,
  updateValue,
}) => {
  const handleChange = (event, newValue) => {
    updateValue(Number((newValue / 100).toFixed(2)));
  };

  return (
    <Slider
      size="medium"
      aria-label="Small"
      valueLabelDisplay="auto"
      min={0}
      max={100}
      value={Math.round(value * 100)}
      onChange={handleChange}
    />
  );
};

const AddOrEditIntention: React.FC<{
  isEdit?: boolean;
  setIsOpenAddIntentionPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditIntentionPopup?: React.Dispatch<React.SetStateAction<number>>;
  editIntentionPopup?: number;
  setAddIntentions: React.Dispatch<React.SetStateAction<IChatbotIntent>>;
  addIntentions: IChatbotIntent;
  setChatbotData: any;
}> = (props) => {
  const openSnackbar = useOpenSnackbar();

  return (
    <FormPopup>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="50px"
      >
        <Grid item display="grid">
          <FormPopupHeader
            firstTitle={props.isEdit ? "Modifier" : "Ajouter"}
            secondTitle={"une intention"}
            onClose={
              props.isEdit
                ? () => {
                    props.setEditIntentionPopup(undefined);
                  }
                : () => {
                    props.setIsOpenAddIntentionPopup(false);
                  }
            }
          />
        </Grid>
        <Grid item display="grid" paddingTop="25px">
          <Grid
            container
            direction="column"
            spacing="15px"
            wrap="nowrap"
            style={{
              fontSize: "16px",
              color: "#0B243C",
              fontFamily: "Poppins",
            }}
          >
            <Grid item display="grid">
              <Grid container direction="column" spacing="15px" wrap="nowrap">
                {/* Titre */}
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitle="Titre"
                    isMandatory={true}
                    value={props.addIntentions.title}
                    id={`chatbot-intention-title`}
                    onChange={(e) => {
                      props.setAddIntentions((x) => {
                        const copy = { ...x };
                        if (
                          e?.target?.value &&
                          typeof e.target.value === "string"
                        ) {
                          copy.title = e.target.value;
                        } else {
                          copy.title = "";
                        }
                        return copy;
                      });
                    }}
                  />
                </Grid>
                {/* Questions */}
                <Grid item display="grid">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    spacing="10px"
                  >
                    <>
                      {props.addIntentions.questions.map(
                        (question, questionIndex) => {
                          return (
                            <Grid item display="grid" key={questionIndex}>
                              <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                spacing="10px"
                              >
                                <Grid item xs display="grid">
                                  <Grid
                                    container
                                    direction={"column"}
                                    wrap="nowrap"
                                    gap={`10px`}
                                  >
                                    <Grid item display={`grid`}>
                                      <GenericLongText
                                        fieldTitle={`Question n°${
                                          questionIndex + 1
                                        }`}
                                        isMandatory={true}
                                        value={question}
                                        id={`chatbot-intention-question-${questionIndex}`}
                                        maxLength={325}
                                        onChange={(e) => {
                                          props.setAddIntentions((x) => {
                                            const copy = { ...x };
                                            if (e && typeof e === "string") {
                                              copy.questions[questionIndex] = e;
                                            } else {
                                              copy.questions[questionIndex] =
                                                "";
                                            }
                                            return copy;
                                          });
                                        }}
                                      />
                                    </Grid>
                                    {questionIndex === 0 && (
                                      <Grid
                                        item
                                        display="grid"
                                        marginBottom={`10px`}
                                      >
                                        <OrangeCheckBox
                                          value={
                                            props.addIntentions
                                              ?.addToListQuestions
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            props.setAddIntentions((x) => {
                                              return {
                                                ...x,
                                                addToListQuestions:
                                                  x?.addToListQuestions
                                                    ? false
                                                    : true,
                                              };
                                            })
                                          }
                                          text="Ajouter cette question à la variable {liste_questions}"
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                {props.addIntentions.questions.length > 1 && (
                                  <Grid
                                    item
                                    display="grid"
                                    alignSelf="center"
                                    marginTop="15px"
                                  >
                                    <Tooltip title="Supprimer">
                                      <IconButton
                                        onClick={() => {
                                          props.setAddIntentions((state) => {
                                            return {
                                              ...state,
                                              questions: state.questions.filter(
                                                (x, index) =>
                                                  index !== questionIndex
                                              ),
                                            };
                                          });
                                        }}
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                        }}
                                      >
                                        <ClearRounded
                                          fontSize="small"
                                          style={{ color: "#FF3100" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                      <Grid item display="grid" justifySelf="flex-end">
                        <AddButton
                          addId="chatbot-intention-question-add"
                          displayText={true}
                          text="Ajouter"
                          onClick={() => {
                            props.setAddIntentions((x) => {
                              return {
                                ...x,
                                questions: [...x.questions, ""],
                              };
                            });
                          }}
                        />
                      </Grid>
                    </>
                  </Grid>
                </Grid>
                {/* Réponse */}
                <Grid item display="grid">
                  <GenericLongText
                    fieldTitle="Réponse"
                    isMandatory={true}
                    value={props.addIntentions.reponse}
                    id={`chatbot-intention-reponse`}
                    maxLength={325}
                    onChange={(e) => {
                      props.setAddIntentions((x) => {
                        const copy = { ...x };
                        if (e && typeof e === "string") {
                          copy.reponse = e;
                        } else {
                          copy.reponse = "";
                        }
                        return copy;
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid">
              <GenericSendButton
                invalidConditions={{
                  emptyTitleIntent: !props.addIntentions?.title,
                  ...(() => {
                    const all = {};
                    props.addIntentions.questions.map((question, index) => {
                      const key = `emptyQuestion${index}`;
                      all[key] = !question;
                    });
                    return all;
                  })(),
                  noResponse: !props.addIntentions?.reponse,
                }}
                errorMessages={{
                  emptyTitleIntent: "Le titre n'est pas renseigné.",
                  ...(() => {
                    const all = {};
                    props.addIntentions.questions.map((question, index) => {
                      const key = `emptyQuestion${index}`;
                      all[key] = `La question n°${
                        index + 1
                      } n'est pas renseignée.`;
                    });
                    return all;
                  })(),
                  noResponse: "La réponse n'est pas renseignée.",
                  "": "",
                }}
                errorSelector={{
                  emptyTitleIntent: "#chatbot-intention-title",
                  ...(() => {
                    const all = {};
                    props.addIntentions.questions.map((question, index) => {
                      const key = `emptyQuestion${index}`;
                      all[key] = `#chatbot-intention-question-${index}`;
                    });
                    return all;
                  })(),
                  noResponse: "#chatbot-intention-reponse",
                  "": "",
                }}
                textButton={props.isEdit ? "Modifier" : "Ajouter"}
                onSendClick={
                  !props.isEdit
                    ? () => {
                        props.setChatbotData((state) => {
                          return {
                            ...state,
                            intents: Array.isArray(state.intents)
                              ? [...state.intents, props.addIntentions]
                              : [props.addIntentions],
                          };
                        });
                        props.setIsOpenAddIntentionPopup(false);
                        openSnackbar.success("L'intention a bien été ajoutée.");
                      }
                    : () => {
                        props.setChatbotData((state) => {
                          const copy = { ...state };
                          if (Array.isArray(copy.intents)) {
                            const newIntents = [...state.intents];
                            newIntents[props.editIntentionPopup] = {
                              ...props.addIntentions,
                            };
                            copy.intents = newIntents;
                            return copy;
                          }
                          return state;
                        });
                        props.setEditIntentionPopup(undefined);
                        openSnackbar.success(
                          "L'intention a bien été modifiée."
                        );
                      }
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormPopup>
  );
};

export const DisplayIntentions: React.FC<{
  chatbotData: Partial<IChatbot>;
  setChatbotData?: React.Dispatch<React.SetStateAction<Partial<IChatbot>>>;
  setEditIntentionPopup?: React.Dispatch<React.SetStateAction<number>>;
  setAddIntentions?: React.Dispatch<React.SetStateAction<IChatbotIntent>>;
  activeDelete?: boolean;
}> = (props) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        columnSpacing="25px"
        rowSpacing="25px"
      >
        {props.chatbotData.intents.map((intent, index) => {
          return (
            <Grid
              item
              display="grid"
              key={index}
              style={{
                maxWidth: "500px",
                minWidth: "200px",
                alignSelf: "center",
              }}
            >
              <Grid
                container
                direction="column"
                alignSelf="flex-start"
                wrap="nowrap"
                border="1px solid black"
                boxShadow="4px 4px 2px 1px rgba(11, 36, 60, .5)"
                borderRadius="16px"
              >
                {/* Titre */}
                <Grid
                  item
                  display="grid"
                  borderBottom="1px solid black"
                  padding="10px"
                >
                  <Grid
                    container
                    direction="row"
                    spacing="15px"
                    wrap="nowrap"
                    justifyContent={
                      props.activeDelete ? "space-between" : undefined
                    }
                  >
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        gap="10px"
                        alignItems={"center"}
                      >
                        {intent?.addToListQuestions ? (
                          <Grid item display="grid">
                            <Tooltip title="La première question de cette intention est incluse dans la variable {liste_questions}">
                              <PlaylistAddCheckOutlined
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  color: "green",
                                  cursor: "help",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        ) : (
                          <Grid item display="grid">
                            <Tooltip title="Aucune question de cette intention n'est incluse dans la variable {liste_questions}">
                              <PlaylistRemoveOutlined
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  color: "red",
                                  cursor: "help",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item display="grid">
                          <span
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >{`${
                            intent.title
                              ? `${intent.title}`
                              : `Titre non défini`
                          }`}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                    {props.activeDelete && (
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="row"
                          wrap="nowrap"
                          spacing="5px"
                        >
                          <Grid item display="grid">
                            <Tooltip title="Modifier">
                              <IconButton
                                onClick={() => {
                                  props.setAddIntentions(intent);
                                  props.setEditIntentionPopup(index);
                                }}
                                style={{
                                  margin: "0",
                                  padding: "0",
                                }}
                              >
                                <EditRounded
                                  fontSize="small"
                                  style={{ color: "#0B243C" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item display="grid">
                            <Tooltip title="Supprimer">
                              <IconButton
                                onClick={() => {
                                  if (!props.setChatbotData) {
                                    return;
                                  }
                                  props.setChatbotData((state) => {
                                    if (!Array.isArray(state.intents)) {
                                      return state;
                                    }
                                    return {
                                      ...state,
                                      intents: state.intents.filter(
                                        (x, i) => i !== index
                                      ),
                                    };
                                  });
                                }}
                                style={{
                                  margin: "0",
                                  padding: "0",
                                }}
                              >
                                <ClearRounded
                                  fontSize="small"
                                  style={{ color: "#FF3100" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {/* Questions */}
                <Grid item display="grid" borderBottom="1px solid black">
                  <Grid
                    container
                    direction="column"
                    padding="10px"
                    wrap="nowrap"
                  >
                    {intent.questions.map((question, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <span>
                            {index + 1}. {question}
                          </span>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                {/* Réponse */}
                <Grid item display="grid" padding="5px">
                  <span style={{ textAlign: "center" }}>{`${
                    intent.reponse ? intent.reponse : "Réponse non définie"
                  }`}</span>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

DisplayIntentions.defaultProps = {
  setChatbotData: undefined,
  activeDelete: false,
};

export const useOpenSnackbar = () => {
  const dispatch = useDispatch();

  return {
    error: (message: string, error?: any, options?: { duration?: 3000 }) => {
      let msg = message;
      if (error?.response?.data?.popupMessage) {
        msg = error.response.data.popupMessage;
      }
      dispatch(
        openSnackbar({
          message: msg,
          duration: options?.duration ? options.duration : 3000,
          type: "error",
        })
      );
    },
    success: (
      message: string,
      response?: any,
      options?: { duration?: 3000 }
    ) => {
      let msg = message;
      if (response?.data?.popupMessage) {
        msg = response.data.popupMessage;
      }
      dispatch(
        openSnackbar({
          message: msg,
          duration: options?.duration ? options.duration : 3000,
          type: "success",
        })
      );
    },
  };
};

export const GenericLongText = (props: {
  onChange: (e: any) => void;
  value: string | undefined;
  placeholder?: string;
  fieldTitle: string;
  usableVariables?: string[];
  disableError?: boolean;
  height?: string;
  id: string;
  isMandatory?: boolean;
  maxLength?: number;
}) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const {
    fieldTitle,
    value,
    disableError,
    onChange,
    isMandatory,
    id,
    maxLength,
    placeholder,
  } = props;

  return (
    <FullField
      isQuestionnaire={true}
      title={fieldTitle}
      isMandatory={isMandatory}
    >
      {props?.usableVariables && props.usableVariables?.length > 0 && (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          paddingBottom="5px"
          gap="5px"
        >
          <Grid item display="grid">
            <span
              style={{
                color: "#0B243C",
                fontWeight: "500",
                fontFamily: "Poppins",
                letterSpacing: "0px",
                fontSize: "14px",
              }}
            >
              Vous pouvez utiliser les variables :
            </span>
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                color: "#0B243C",
                fontWeight: "500",
                fontFamily: "Poppins",
                letterSpacing: "0px",
                fontSize: "14px",
              }}
            >
              {props.usableVariables.map((x) => `{${x}}`).join(" - ")}
            </span>
          </Grid>
        </Grid>
      )}
      <HandleErrors
        isError={isError && !disableError}
        isMandatory={isMandatory}
        errorMessage={errorMessage}
      >
        <MultilineInput
          id={id}
          placeholder={placeholder}
          isError={isError && !disableError}
          height={props?.height ? props.height : "80px"}
          maxLength={maxLength ? maxLength : 2000}
          whiteSpace="pre-line"
          value={value ? value : ""}
          onChange={onChange}
          onFocus={(e) => {
            if (!isMandatory) return;
            if (isError && !disableError) {
              setErrorMessage("");
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!isMandatory) return;
            if (!e.target.value) {
              if (!isError && !disableError) {
                setErrorMessage("Ce champ est obligatoire.");
                setIsError(true);
              }
            } else {
              if (isError && !disableError) {
                setErrorMessage("");
                setIsError(false);
              }
            }
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

export const GenericTextInput: React.FC<any> = React.forwardRef(
  (
    props: {
      onChange: (e: any) => void;
      value: string | undefined;
      fieldTitle: string;
      id: string;
      isMandatory?: boolean;
      type?: string;
      placeholder?: string;
      fieldTitleInPlaceholderOnly?: boolean;
      usableVariables?: string[];
      disabled?: boolean;
    },
    ref
  ) => {
    GenericTextInput.displayName = "GenericTextInput";
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const {
      fieldTitle,
      value,
      onChange,
      isMandatory,
      id,
      placeholder,
      fieldTitleInPlaceholderOnly,
      disabled,
      type,
    } = props;

    React.useImperativeHandle(ref, () => ({
      setIsError(value) {
        setIsError(value);
      },
    }));

    const fieldTitleResult = fieldTitleInPlaceholderOnly ? "" : fieldTitle;
    const getPlaceholder = () => {
      let p = "";
      if (fieldTitleInPlaceholderOnly && fieldTitle) {
        p = fieldTitle;
        if (isMandatory) {
          p += " *";
        }
      } else {
        if (placeholder) {
          p = placeholder;
        }
      }
      return p;
    };
    const placeholderResult = getPlaceholder();

    const VariableList: React.FC = () => {
      return (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          paddingBottom="5px"
          gap="5px"
        >
          <Grid item display="grid">
            <span
              style={{
                color: "#0B243C",
                fontWeight: "500",
                fontFamily: "Poppins",
                letterSpacing: "0px",
                fontSize: "14px",
              }}
            >
              Vous pouvez utiliser les variables :
            </span>
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                color: "#0B243C",
                fontWeight: "500",
                fontFamily: "Poppins",
                letterSpacing: "0px",
                fontSize: "14px",
              }}
            >
              {props.usableVariables.map((x) => `{${x}}`).join(" - ")}
            </span>
          </Grid>
        </Grid>
      );
    };

    if (!isMandatory) {
      return (
        <FullField title={fieldTitleResult} isMandatory={isMandatory}>
          {props?.usableVariables && props.usableVariables?.length > 0 && (
            <VariableList />
          )}
          <TextInput
            id={id}
            type={type}
            isError={false}
            value={value}
            onChange={onChange}
            placeholder={placeholderResult}
            disabled={disabled}
          />
        </FullField>
      );
    }

    return (
      <FullField title={fieldTitleResult} isMandatory={isMandatory}>
        {props?.usableVariables && props.usableVariables?.length > 0 && (
          <VariableList />
        )}
        <HandleErrors
          isError={isError}
          isMandatory={true}
          errorMessage={errorMessage}
        >
          <TextInput
            id={id}
            isError={isError}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholderResult}
            disabled={disabled}
            onFocus={(e) => {
              if (isError) {
                setErrorMessage("");
                setIsError(false);
              }
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                if (!isError) {
                  setErrorMessage("Ce champ est obligatoire.");
                  setIsError(true);
                }
              } else {
                if (isError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }
            }}
          />
        </HandleErrors>
      </FullField>
    );
  }
);

const DisplayTitle: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const { chatbotData, setChatbotData } = props;

  return (
    <FullField title="Titre" isMandatory={true}>
      <HandleErrors
        isError={isError}
        isMandatory={true}
        errorMessage={errorMessage}
      >
        <TextInput
          id="chatbot-title-input"
          isError={isError}
          value={chatbotData?.title}
          onChange={(e) => {
            const chatbotDataTmp = { ...chatbotData };

            chatbotDataTmp.title = e.target.value;
            setChatbotData(chatbotDataTmp);
          }}
          onFocus={(e) => {
            if (isError) {
              setErrorMessage("");
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!e.target.value) {
              if (!isError) {
                setErrorMessage("Ce champ est obligatoire.");
                setIsError(true);
              }
            } else {
              if (isError) {
                setErrorMessage("");
                setIsError(false);
              }
            }
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

const initChatbotLex = (): Partial<IChatbot> => {
  return {
    title: "",
    smsIntro: "",
    smsConclusion: "",
    smsSecours: "Je ne suis pas sûr de comprendre, pouvez-vous reformuler ?",
    intents: [],
    nluIntentConfidenceThreshold: 0.6,
  };
};

const initChatbotIntent = (): IChatbotIntent => {
  return {
    questions: [""],
    reponse: "",
    title: "",
    addToListQuestions: true,
  };
};

const CreateChatbotContent: React.FC<any> = (props) => {
  const { isEdit, isCreate } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [chatbotData, setChatbotData] = React.useState<Partial<IChatbot>>(
    initChatbotLex()
  );
  const [addIntentions, setAddIntentions] = React.useState<IChatbotIntent>(
    initChatbotIntent()
  );
  const [isOpenAddIntentionPopup, setIsOpenAddIntentionPopup] =
    React.useState<boolean>(false);
  const [isSendLoading, setIsSendLoading] = React.useState<boolean>(false);
  const [editIntentionPopup, setEditIntentionPopup] =
    React.useState<number>(undefined);
  const [isSuccessCreatePopup, setIsSuccessCreatePopup] = React.useState(false);
  const dispatch = useDispatch();
  const openSnackbar = useOpenSnackbar();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  React.useEffect(() => {
    console.log(addIntentions);
  }, [addIntentions]);

  React.useEffect(() => {
    if (isCreate) return;
    setIsLoading(true);
    makeAPIRequest("get", `/chatbots_lex/${id}/details`, null, "v3")
      .then((res) => {
        setChatbotData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [isCreate, isEdit]);

  if (isLoading) {
    return (
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop="30px"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      {editIntentionPopup !== undefined && (
        <AddOrEditIntention
          isEdit={true}
          addIntentions={addIntentions}
          setAddIntentions={setAddIntentions}
          setChatbotData={setChatbotData}
          setEditIntentionPopup={setEditIntentionPopup}
          editIntentionPopup={editIntentionPopup}
        />
      )}
      {isOpenAddIntentionPopup && (
        <AddOrEditIntention
          isEdit={false}
          addIntentions={addIntentions}
          setAddIntentions={setAddIntentions}
          setChatbotData={setChatbotData}
          setIsOpenAddIntentionPopup={setIsOpenAddIntentionPopup}
        />
      )}
      {isSuccessCreatePopup && (
        <CancellablePopup
          firstTitle={isCreate ? "Créer" : "Modifier"}
          secondTitle="un chatbot"
          message={`Le chatbot '${chatbotData?.title}' a bien été ${
            isCreate ? "crée" : "modifié"
          }.`}
          cancelButtonText="Retour"
          onCancel={() => {
            history.push("/home?f=chatbots&tab=meschatbots");
          }}
          confirmButtonText="Créer un nouveau chatbot"
          onConfirm={() => {
            setIsSuccessCreatePopup(false);
            setChatbotData(initChatbotLex());
            history.push("/create_chatbot");
          }}
        />
      )}
      <Grid container direction="column" wrap="nowrap" display="flex">
        <Grid item display="grid" paddingTop="10px">
          <DisplayTitle
            chatbotData={chatbotData}
            setChatbotData={setChatbotData}
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <GenericLongText
            fieldTitle={`SMS d'introduction`}
            isMandatory={true}
            height="120px"
            usableVariables={[
              `liste_questions`,
              `nom_patient`,
              `prenom_patient`,
            ]}
            value={chatbotData.smsIntro}
            id={"chatbot-sms-intro"}
            maxLength={3000}
            onChange={(e) => {
              setChatbotData((state) => {
                return {
                  ...state,
                  smsIntro: e,
                };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <GenericLongText
            fieldTitle={`SMS de conclusion`}
            isMandatory={true}
            value={chatbotData.smsConclusion}
            id={"chatbot-sms-conclusion"}
            maxLength={325}
            onChange={(e) => {
              setChatbotData((state) => {
                return {
                  ...state,
                  smsConclusion: e,
                };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <GenericLongText
            fieldTitle={`SMS de secours`}
            isMandatory={true}
            value={chatbotData.smsSecours}
            id={"chatbot-sms-secours"}
            maxLength={325}
            onChange={(e) => {
              setChatbotData((state) => {
                return {
                  ...state,
                  smsSecours: e,
                };
              });
            }}
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px" width={250}>
          <FullField
            isMandatory={false}
            title="Seuil de confiance"
            helper={`Le seuil de confiance vous permet de personnaliser le niveau de précision et de tolérance de votre chatbot. Vous pouvez régler ce seuil en utilisant une petite bulle graduée de 0 à 100. Plus vous déplacez la bulle vers 0, plus le chatbot sera sensible aux prédictions d'intention moins confiantes, ce qui peut conduire à des réponses plus souples mais potentiellement moins précises. À l'inverse, en déplaçant la bulle vers 100, le chatbot sera plus strict dans sa décision de considérer une intention comme valide, ce qui réduit le risque de réponses incorrectes. Trouvez le réglage qui correspond le mieux à vos besoins en déplaçant la bulle vers le niveau de confiance souhaité. Vous pouvez ajuster ce paramètre en fonction de votre expérience d'utilisation pour obtenir le comportement désiré du chatbot.`}
          >
            <PercentageSlider
              value={
                chatbotData?.nluIntentConfidenceThreshold !== undefined
                  ? chatbotData.nluIntentConfidenceThreshold
                  : 0.6
              }
              updateValue={(newValue) => {
                setChatbotData((state) => {
                  return {
                    ...state,
                    nluIntentConfidenceThreshold: newValue,
                  };
                });
              }}
            />
          </FullField>
        </Grid>
        <Grid item display="grid" paddingTop="20px">
          <AddButton
            addId="chatbot-question-add"
            displayText={true}
            text="Ajouter une intention"
            onClick={() => {
              setAddIntentions(initChatbotIntent());
              setIsOpenAddIntentionPopup(true);
            }}
          />
        </Grid>
        <Grid item display="grid" paddingTop="30px">
          <DisplayIntentions
            setEditIntentionPopup={setEditIntentionPopup}
            chatbotData={chatbotData}
            setChatbotData={setChatbotData}
            activeDelete={true}
            setAddIntentions={setAddIntentions}
          />
        </Grid>
        <Grid item display="grid" paddingTop="20px">
          <GenericSendButton
            invalidConditions={{
              emptyTitle: !chatbotData?.title,
              emptySMSIntro: !chatbotData?.smsIntro,
              emptySMSConclusion: !chatbotData?.smsConclusion,
              emptySMSSecours: !chatbotData?.smsSecours,
              noIntention:
                !chatbotData?.intents || chatbotData?.intents?.length === 0,
            }}
            errorMessages={{
              emptyTitle: "Le titre n'est pas renseigné.",
              emptySMSIntro: "Le SMS d'introduction n'est pas renseigné.",
              emptySMSConclusion: "Le SMS de conclusion n'est pas renseigné.",
              emptySMSSecours: "Le SMS de secours n'est pas renseigné.",
              noIntention: "Aucune intention n'a été ajoutée.",
              "": "",
            }}
            errorSelector={{
              emptyTitle: "#chatbot-title-input",
              emptySMSIntro: "#chatbot-sms-intro",
              emptySMSConclusion: "#chatbot-sms-conclusion",
              emptySMSSecours: "#chatbot-sms-secours",
              noIntention: "#chatbot-question-add",
              "": "",
            }}
            isLoading={isSendLoading}
            textButton={isCreate ? "Créer" : "Mettre à jour"}
            onSendClick={
              isCreate
                ? () => {
                    setIsSendLoading(true);
                    makeAPIRequest(
                      "post",
                      `/chatbotlex/create`,
                      chatbotData,
                      "v3"
                    )
                      .then((res: any) => {
                        openSnackbar.success("Le chatbot a bien été créé", res);
                        history.push("/home?f=chatbots&tab=meschatbots");
                      })
                      .catch((err: any) => {
                        openSnackbar.error(
                          "Impossible de créer ce chatbot",
                          err
                        );
                      })
                      .finally(() => setIsSendLoading(false));
                  }
                : () => {
                    setIsSendLoading(true);
                    makeAPIRequest(
                      "post",
                      `/chatbotlex/${chatbotData._id}/update`,
                      {
                        ...chatbotData,
                        intents: chatbotData.intents
                          ? chatbotData.intents.map((intent) => {
                              return {
                                ...intent,
                                addToListQuestions: intent?.addToListQuestions
                                  ? true
                                  : false,
                              };
                            })
                          : [],
                      } as Partial<IChatbot>,
                      "v3"
                    )
                      .then((res: any) => {
                        openSnackbar.success(
                          "Le chatbot a bien été mis à jour",
                          res
                        );
                        history.push("/home?f=chatbots&tab=meschatbots");
                      })
                      .catch((err: any) => {
                        openSnackbar.error(
                          "Impossible de mettre à jour ce chatbot",
                          err
                        );
                      })
                      .finally(() => setIsSendLoading(false));
                  }
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

const CreateChatbotLexBox: React.FC<any> = (props) => {
  const { isCreate, isEdit, isLoading } = props;

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="80vw"
      minWidth="320px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <BoxTitle
              first={isCreate ? "Créer" : "Modifier"}
              second="un chatbot"
            />
          </Grid>
          <Grid item display="grid" paddingTop="15px">
            <CreateChatbotContent {...props} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CreateOrEditChatbotLexPage: React.FC<any> = (props) => {
  const history = useHistory();
  const { isCreate, isEdit } = props;

  return (
    <>
      <FullPage>
        <Grid container direction="column" padding="10px" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px" width="80vw">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/home?f=chatbots&tab=meschatbots")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <CreateChatbotLexBox {...props} />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default CreateOrEditChatbotLexPage;
