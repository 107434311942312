import * as React from "react";
import FullField from "../../Fields/FullField";
import TextInput from "../../Library/Inputs/TextInput";

const DisplayBeforeMessage: React.FC<any> = (props) => {
  const { chatbotData, setChatbotData } = props;

  return (
    <FullField title="SMS d'introduction" isMandatory={false}>
      <TextInput
        id="chatbot-before-sms-input"
        value={chatbotData?.smsBefore}
        onChange={(e) => {
          const chatbotDataTmp = { ...chatbotData };

          chatbotDataTmp.smsBefore = e.target.value;
          setChatbotData(chatbotDataTmp);
        }}
      />
    </FullField>
  );
};

export default DisplayBeforeMessage;
