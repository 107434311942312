import * as React from 'react';
import ConseilComponent from '../../../ConseilComponent';
import FilterContainer from '../../FilterContainer/FilterContainer';
import useAppSelector from '../../../../redux/useAppSelector';

const FavoriteTabContent: React.FC<any> = (props) => {
  const activeTab = useAppSelector(state => state.tabsReducer.activeTab);
  return (
    <div className="phantom-gradient">
      {activeTab === 'favourite' &&
        <FilterContainer
          tab='favourite'
          filterKey={props.filterKeyFavourite}
          clearFilters={props.clearFilters}
          specialities={props.specialites}
          handleSelectChange={props.handleSelectChange} />
      }
      <ConseilComponent
        {...props}
        filterSpecialities=""
        filterConseil="" />
    </div>
  );
};

export default FavoriteTabContent;
