import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import * as React from "react";
import * as editPatientReducer from "../../redux/editPatientReducer";
import * as createPatientReducer from "../../redux/createPatientReducer";
import ReactDOM from "react-dom";
import FormPopupHeader from "./FormPopupHeader";
import FormPopup from "./FormPopup";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import { Help } from "@mui/icons-material";
import CancellablePopup from "../Library/Popups/CancellablePopup";
import GreyButton from "../Library/Buttons/GreyButton/GreyButton";
import InformationPopup from "../Library/Popups/InformationPopup";
import EventEmitter from "../../services/event";
import PatientFirstNameField from "../Fields/PatientFields/PatientFirstnameField";
import PatientLastNameField from "../Fields/PatientFields/PatientLastnameField";
import DoubleFieldRow from "../Fields/DoubleFieldRow";
import PatientPhoneField from "../Fields/PatientFields/PatientPhoneField";
import PatientEmailField from "../Fields/PatientFields/PatientEmailField";
import ConfigInterfacePatientForm from "../../configs/InterfacePatientForm.json";
import PatientBirthdayField from "../Fields/PatientFields/PatientBirthdayFIeld";
import PatientCityINSEEField from "../Fields/PatientFields/PatientBirthplace";
import PatientGenderField from "../Fields/PatientFields/PatientGenderField";
import PatientINSField from "../Fields/PatientFields/PatientINSField";
import TutorialService from "../../services/TutorialService";
import isValidPhoneNumber from "../../utils/isValidPhoneNumber";
import { closePhones, deletePhoneOpen } from "../../redux/selectPatientReducer";
import { openSnackbar } from "../../redux/snackbarReducer";
import makeAPIRequest from "../../utils/makeAPIRequest";
import MySelect from "../Library/Select/MySelect";
import FullField from "../Fields/FullField";
import TextInput from "../Library/Inputs/TextInput";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import useAppSelector from "../../redux/useAppSelector";
import { GenericLongText } from "../../pages/CreateOrEditChatbotLexPage";

const errorMessages = {
  emptyPhoneNumber: "Le numéro de téléphone n'est pas renseigné.",
  badPhoneNumber: "Le numéro de téléphone est invalide.",
  emptyINS: "L'INS n'est pas renseignée.",
  badINSLength: "L'INS doit comporter 13 chiffres.",
  "": "",
};

const errorSelector = {
  emptyPhoneNumber: "#patient-phone-input",
  badPhoneNumber: "#patient-phone-input",
  emptyINS: "#patient-ins-input",
  badINSLength: "#patient-ins-input",
  "": "",
};

const CreateOrEditPatient: React.FC<any> = (props) => {
  const [showError, setShowError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const { isEdit, isCreate, patientList, setPatientList, executeSorting } =
    props;
  const [reducer, setReducer] = React.useState(null);
  const [type, setType] = React.useState("create");
  const dispatch = useDispatch();
  const accesses = useAppSelector((state) => state.authorizationsReducer);
  const selector = {
    edit: {
      isSubmittedPopup: useAppSelector(
        (state?: any) => state.editPatientReducer.isSubmittedPopup
      ),
      isExitPopup: useAppSelector(
        (state?: any) => state.editPatientReducer.isExitPopup
      ),
      isPatientOpen: useAppSelector(
        (state?: any) => state.editPatientReducer.isPatientOpen
      ),
      actualPatient: useAppSelector(
        (state?: any) => state.editPatientReducer.actualPatient
      ),
    },
    create: {
      isSubmittedPopup: useAppSelector(
        (state?: any) => state.createPatientReducer.isSubmittedPopup
      ),
      isExitPopup: useAppSelector(
        (state?: any) => state.createPatientReducer.isExitPopup
      ),
      isPatientOpen: useAppSelector(
        (state?: any) => state.createPatientReducer.isPatientOpen
      ),
      actualPatient: useAppSelector(
        (state?: any) => state.createPatientReducer.actualPatient
      ),
    },
  };
  const [groupeSelected, setGroupeSelected] = React.useState(null);
  const [isButtonValidateLoading, setIsButtonValidateLoading] =
    React.useState(false);

  const getGroupNamePatient = (idGroup: any) => {
    let groupName = "";

    if (props?.patientGroupsList?.length > 0) {
      for (const element of props.patientGroupsList) {
        if (element.id == idGroup) {
          groupName = element.group_name;
        }
      }
    }
    return groupName;
  };

  React.useEffect(() => {
    setGroupeSelected({
      value: selector?.[type]?.actualPatient?.group,
      label: getGroupNamePatient(selector?.[type]?.actualPatient?.group),
    });
  }, [selector?.[type]?.actualPatient?.group]);

  React.useEffect(() => {
    if (isEdit === true) {
      setReducer(editPatientReducer);
      setType("edit");
    }
    if (isCreate === true) {
      setReducer(createPatientReducer);
      setType("create");
    }
  }, [isEdit, isCreate]);

  const isDuplicatedPatient = (patientInfos: any): boolean => {
    if (!patientList || patientList?.length == 0) {
      return false;
    }
    if (
      patientList.find(
        (p) =>
          p?.firstname?.toLowerCase() == patientInfos?.firstname?.toLowerCase() &&
          p?.lastname?.toLowerCase() == patientInfos?.lastname?.toLowerCase() &&
          p?.phone == patientInfos?.phone
      )
    ) {
      return true;
    }
    return false;
  };

  const isValidSubmit = () => {
    const actualPatient = selector?.[type]?.actualPatient;
    const invalidConditions = {
      emptyPhoneNumber: !actualPatient?.phone || actualPatient?.phone === "",
      badPhoneNumber:
        actualPatient?.phone && !isValidPhoneNumber(actualPatient?.phone),
      emptyINS: !actualPatient?.INS || actualPatient?.INS === "",
      badINSLength: actualPatient?.INS && actualPatient?.INS?.length != 13,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  if (selector?.[type]?.isSubmittedPopup) {
    return ReactDOM.createPortal(
      <>
        <InformationPopup
          firstTitle={isEdit ? `Modifier` : "Ajouter"}
          secondTitle={"mon patient"}
          message={
            isEdit
              ? `Votre patient a bien été modifié.`
              : `Votre patient a bien été ajouté.`
          }
          confirmButtonText="OK"
          onConfirm={() => dispatch(reducer?.closePatient())}
        />
      </>,
      document.body
    );
  } else if (selector?.[type]?.isExitPopup) {
    return ReactDOM.createPortal(
      <>
        <CancellablePopup
          firstTitle={isEdit ? "Modifier" : "Ajouter"}
          secondTitle={"mon patient"}
          message="Attention, vous allez fermer cette fenêtre et vos modifications ne seront pas enregistrées."
          confirmButtonText="Fermer"
          cancelButtonText="Ne pas fermer"
          onCancel={() => {
            dispatch(reducer?.setIsExitPopup(false));
          }}
          onConfirm={() => {
            dispatch(reducer?.closePatient());
          }}
        />
      </>,
      document.body
    );
  } else if (selector?.[type] && reducer && selector?.[type]?.isPatientOpen) {
    return (
      <FormPopup xs={8}>
        <Grid
          item
          display="grid"
          paddingTop="40px"
          paddingLeft="50px"
          paddingRight="50px"
          paddingBottom="40px"
        >
          <FormPopupHeader
            firstTitle={isCreate ? "Ajouter" : "Modifier"}
            secondTitle={"mon patient"}
            onClose={() => dispatch(reducer?.setIsExitPopup(true))}
          />
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            display="flex"
            width="70%"
            justifySelf="center"
            direction="column"
            wrap="nowrap"
          >
            <Grid item display="grid">
              <DoubleFieldRow
                rowSpacing={ConfigInterfacePatientForm.fieldsRowSpacing}
                firstField={
                  <PatientLastNameField
                    itemBorder={null}
                    onChange={(e) =>
                      dispatch(reducer?.setLastName(e.target.value))
                    }
                    patientLastName={selector?.[type]?.actualPatient?.lastname}
                  />
                }
                secondField={
                  <PatientFirstNameField
                    itemBorder={null
                    }
                    onChange={(e) =>
                      dispatch(reducer?.setFirstName(e.target.value))
                    }
                    patientFirstName={
                      selector?.[type]?.actualPatient?.firstname
                    }
                  />
                }
              />
            </Grid>
            <Grid
              item
              display="grid"
              paddingTop={ConfigInterfacePatientForm.fieldsRowSpacing}
            >
              <DoubleFieldRow
                rowSpacing={ConfigInterfacePatientForm.fieldsRowSpacing}
                firstField={
                  <PatientPhoneField
                    itemBorder={
                      showError &&
                        ["badPhoneNumber", "emptyPhoneNumber"].includes(error)
                        ? "1px solid #FF3100"
                        : null
                    }
                    onChange={(e) => {
                      dispatch(
                        reducer?.setPhone(
                          e?.target?.value
                            ? e.target.value.replaceAll(" ", "")
                            : ""
                        )
                      );
                    }}
                    patientPhone={selector?.[type]?.actualPatient?.phone}
                  />
                }
                secondField={
                  <PatientEmailField
                    onChange={(e) => dispatch(reducer.setEmail(e.target.value))}
                    patientEmail={selector?.[type]?.actualPatient?.email}
                  />
                }
              />
            </Grid>
            <Grid
              item
              display="grid"
              paddingTop={ConfigInterfacePatientForm.fieldsRowSpacing}
            >
              <DoubleFieldRow
                rowSpacing={ConfigInterfacePatientForm.fieldsRowSpacing}
                firstField={
                  <PatientGenderField
                    onChange={(e) => dispatch(reducer?.setGender(e))}
                    patientGender={selector?.[type]?.actualPatient?.sexe}
                  />
                }
                secondField={
                  <PatientBirthdayField
                    onChange={(e) => dispatch(reducer?.setBirthday(e))}
                    patientBirthday={selector?.[type]?.actualPatient?.dob}
                  />
                }
              />
            </Grid>
            <Grid
              item
              display="grid"
              paddingTop={ConfigInterfacePatientForm.fieldsRowSpacing}
            >
              <DoubleFieldRow
                rowSpacing={ConfigInterfacePatientForm.fieldsRowSpacing}
                firstField={
                  <PatientCityINSEEField
                    onChange={(e) =>
                      dispatch(reducer.setCityINSEE(e.target.value))
                    }
                    patientCityINSEE={
                      selector?.[type]?.actualPatient?.city_INSEE
                    }
                  />
                }
                secondField={
                  <FullField title="Groupe" isMandatory={false}>
                    <MySelect
                      isClearable={false}
                      maxMenuHeight={"150px"}
                      options={
                        props?.patientGroupsList
                          ? [
                            { label: "-- Aucun groupe --", value: "" },
                            ...props.patientGroupsList.map((x) => {
                              return {
                                label: x?.group_name,
                                value: x?.id,
                              };
                            }),
                          ]
                          : []
                      }
                      placeholder={"-- Aucun groupe --"}
                      noOptionsMessage={() => "Aucun groupe"}
                      onChange={(e) => {
                        setGroupeSelected(e);
                        dispatch(reducer.setGroupe(parseInt(e.value)));
                      }}
                      value={
                        groupeSelected.value === "" ||
                          groupeSelected === null ||
                          !groupeSelected.value
                          ? null
                          : groupeSelected
                      }
                    />
                  </FullField>
                }
              />
            </Grid>
            <Grid
              item
              display="grid"
              paddingTop={ConfigInterfacePatientForm.fieldsRowSpacing}
            >
              <DoubleFieldRow
                rowSpacing={ConfigInterfacePatientForm.fieldsRowSpacing}
                firstField={
                  <FullField title="Numéro patient" isMandatory={false}>
                    <TextInput
                      value={selector?.[type]?.actualPatient?.numero_patient}
                      onChange={(e) =>
                        dispatch(reducer.setNumeroPatient(e.target.value))
                      }
                    />
                  </FullField>
                }
                secondField={<GenericLongText value={selector?.[type]?.actualPatient?.comment}
                  isMandatory={false} fieldTitle="Commentaire" id="patient-comment-input"
                  maxLength={1000} onChange={(e) => {
                    dispatch(reducer.setComment(e ? e : ''))
                  }} disableError={true}  />}
              />
            </Grid>
            {accesses.forms && (
              <Grid
                item
                display="grid"
                paddingTop={ConfigInterfacePatientForm.fieldsRowSpacing}
              >
                <OrangeCheckBox
                  text={`Envoyer uniquement des liens publics (questionnaires)`}
                  value={
                    selector?.[type]?.actualPatient
                      ?.disable_forms_private_links == 1
                      ? true
                      : false
                  }
                  onClick={() => {
                    dispatch(
                      reducer.setDisableFormsPrivateLinks(
                        selector?.[type]?.actualPatient
                          ?.disable_forms_private_links == 1
                          ? 0
                          : 1
                      )
                    );
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              display="grid"
              paddingTop={ConfigInterfacePatientForm.fieldsRowSpacing}
            >
              <OrangeCheckBox
                text={`Ce patient n'a pas de smartphone`}
                value={
                  selector?.[type]?.actualPatient?.no_smartphone == 1
                    ? true
                    : false
                }
                onClick={() => {
                  dispatch(
                    reducer.setDisableNoSmartphone(
                      selector?.[type]?.actualPatient?.no_smartphone == 1
                        ? 0
                        : 1
                    )
                  );
                }}
              />
            </Grid>
            <Grid
              item
              display="grid"
              paddingTop={ConfigInterfacePatientForm.fieldsRowSpacing}
            >
              <OrangeCheckBox
                text={`J'ai renseigné le numéro de téléphone de l'aidant`}
                value={
                  selector?.[type]?.actualPatient?.is_helper_phone == 1
                    ? true
                    : false
                }
                onClick={() => {
                  dispatch(
                    reducer.setDisableHelperPhone(
                      selector?.[type]?.actualPatient?.is_helper_phone == 1
                        ? 0
                        : 1
                    )
                  );
                }}
              />
            </Grid>
            {selector?.[type]?.actualPatient?.is_helper_phone == 1 && (
              <Grid item display="grid" paddingTop={"10px"}>
                <DoubleFieldRow
                  rowSpacing={"0px"}
                  firstField={
                    <FullField title="Nom de l'aidant" isMandatory={false}>
                      <TextInput
                        value={selector?.[type]?.actualPatient?.helper_name}
                        onChange={(e) =>
                          dispatch(reducer.setHelperName(e.target.value))
                        }
                      />
                    </FullField>
                  }
                  secondField={<></>}
                />
              </Grid>
            )}
            <Grid item display="grid" paddingTop="37px">
              <span className="text_champs" style={{ textAlign: "left" }}>
                * Ces champs sont indispensables
              </span>
            </Grid>
            {error?.length > 0 && (
              <Grid item display="grid" paddingTop="8px">
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  spacing="5px"
                  marginBottom="10px"
                >
                  <Grid item display="grid" alignItems="center">
                    <span className="field_star" style={{ color: "#FF3100" }}>
                      {errorMessages[error]}
                    </span>
                  </Grid>
                  <Grid item display="grid" alignItems="center">
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        padding: "0",
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          if (showError) setShowError(false);
                        }, 2250);
                      }}
                      onClick={() => {
                        if (!showError) setShowError(true);
                        if (error === "" || errorSelector?.[error] === "")
                          return;
                        const section = document?.querySelector(
                          errorSelector?.[error]
                        );
                        section?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                    >
                      <Help
                        className="field_star"
                        style={{ height: "20px", color: "#FF3100" }}
                      />
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              display="grid"
              paddingTop={error?.length > 0 ? "8px" : "52px"}
              paddingBottom="65px"
            >
              <Grid
                container
                direction="row"
                display="flex"
                columnSpacing="25px"
                rowSpacing="10px"
                justifyContent="center"
                alignItems="center"
              >
                {!isButtonValidateLoading && (
                  <Grid item display="grid">
                    <GreyButton
                      animation={true}
                      enable={true}
                      text="Annuler"
                      onClick={() => {
                        dispatch(closePhones());
                        dispatch(reducer?.setIsExitPopup(true));
                      }}
                    />
                  </Grid>
                )}
                <Grid item display="grid">
                  {isValidSubmit() ? (
                    <div>
                      <OrangeButton
                        isLoading={isButtonValidateLoading}
                        animation={true}
                        enable={true}
                        type="submit"
                        text={isCreate ? "Ajouter" : "Mettre à jour"}
                        onClick={
                          isEdit
                            ? () => {
                              const actualPatient =
                                selector?.[type]?.actualPatient;
                              const toSend = {};
                              for (const key of Object.keys(actualPatient)) {
                                if (actualPatient[key] != undefined) {
                                  if (
                                    key === "dob" &&
                                    actualPatient[key]?.length > 0 &&
                                    actualPatient[key] != "0000-00-00"
                                  ) {
                                    const date = new Date(actualPatient[key]);
                                    if (date)
                                      toSend[key] = date.toISOString();
                                  } else {
                                    toSend[key] = actualPatient[key];
                                  }
                                }
                              }

                              setIsButtonValidateLoading(true);
                              TutorialService.update(
                                actualPatient?.id,
                                toSend
                              )
                                .then((res: any) => {
                                  makeAPIRequest(
                                    "get",
                                    `/patients/get_all`,
                                    null,
                                    "v3"
                                  )
                                    .then((res: any) => {
                                      dispatch(
                                        reducer?.setIsSubmittedPopup(true)
                                      );
                                      setPatientList(
                                        res?.data?.data
                                          ?.search_patients_results
                                      );
                                      if (executeSorting) {
                                        executeSorting();
                                      }
                                      setIsButtonValidateLoading(false);
                                    })
                                    .catch((err) => {
                                      dispatch(
                                        openSnackbar({
                                          message:
                                            "Impossible de charger la liste des patients",
                                          type: "error",
                                        })
                                      );
                                      setIsButtonValidateLoading(false);
                                    });
                                })
                                .catch((e) => {
                                  dispatch(
                                    openSnackbar({
                                      message:
                                        "Impossible de mettre à jour ce patient",
                                      type: "error",
                                    })
                                  );
                                  setIsButtonValidateLoading(false);
                                });
                            }
                            : () => {
                              const actualPatient =
                                selector?.[type]?.actualPatient;

                              const toSend: any = {
                                disable_forms_private_links: 0,
                                helper_name: "",
                                comment: "",
                                is_helper_phone: 0,
                                no_smartphone: 0,
                              };
                              for (const key of Object.keys(actualPatient)) {
                                if (
                                  actualPatient[key] &&
                                  actualPatient[key] !== "" &&
                                  actualPatient[key] != "0000-00-00"
                                ) {
                                  if (key === "dob") {
                                    const date = new Date(actualPatient[key]);
                                    if (date)
                                      toSend[key] = date.toISOString();
                                  } else {
                                    toSend[key] = actualPatient[key];
                                  }
                                }
                              }

                              if (isDuplicatedPatient(toSend)) {
                                dispatch(
                                  openSnackbar({
                                    message: `Le patient ${toSend?.lastname.toLowerCase()}  ${toSend?.firstname.toLowerCase()} existe déjà`,
                                    duration: 3000,
                                    type: "error",
                                  })
                                );
                              } else {
                                setIsButtonValidateLoading(true);
                                TutorialService.createPatient(toSend)
                                  .then((res: any) => {
                                    if (res.data.data.create == "done") {
                                      makeAPIRequest(
                                        "get",
                                        `/patients/get_all`,
                                        null,
                                        "v3"
                                      ).then((res: any) => {
                                        dispatch(
                                          reducer?.setIsSubmittedPopup(true)
                                        );
                                        setPatientList(
                                          res?.data?.data
                                            ?.search_patients_results
                                        );
                                        if (executeSorting) {
                                          executeSorting();
                                        }
                                        dispatch(deletePhoneOpen());
                                        setIsButtonValidateLoading(false);
                                      });
                                    } else {
                                      dispatch(
                                        openSnackbar({
                                          message:
                                            "La création du patient a échouée",
                                          type: "error",
                                        })
                                      );
                                      setIsButtonValidateLoading(false);
                                    }
                                  })
                                  .catch((e) => {
                                    dispatch(
                                      openSnackbar({
                                        message:
                                          "La création du patient a échouée",
                                        type: "error",
                                      })
                                    );
                                    setIsButtonValidateLoading(false);
                                  });
                              }
                            }
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <OrangeButton
                        animation={true}
                        enable={false}
                        type="submit"
                        text={isCreate ? "Ajouter" : "Mettre à jour"}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormPopup>
    );
  } else {
    return null;
  }
};

export default CreateOrEditPatient;
