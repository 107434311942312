import { createSlice } from '@reduxjs/toolkit';

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {
    fieldCopy: null,
  },
  reducers: {
    setFieldCopy: (state, action) => {
      const field = action.payload;

      state.fieldCopy = field;
    },
  }
})

export const {
  setFieldCopy,
} = questionnaireSlice.actions

export default questionnaireSlice.reducer;
