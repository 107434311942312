import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  onlyAddPatient,
  onlyRemovePatient,
} from "../../redux/selectPatientReducer";
import { openSnackbar } from "../../redux/snackbarReducer";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import useAppSelector from "../../redux/useAppSelector";

interface INameWithSorting {
  name?: string;
  sortingTypeCroissantName: string;
  sortingTypeDecroissantName: string;
  sortingType: string;
  setSortingType: any;
  children?: JSX.Element;
}

export const NameWithSorting = (props: INameWithSorting) => {
  const {
    name,
    children,
    sortingTypeCroissantName,
    sortingTypeDecroissantName,
    sortingType,
    setSortingType,
  } = props;

  return (
    <Grid container direction="row" className="table-grid-title" wrap="nowrap">
      {children ? (
        <Grid item display="grid" paddingRight="7px">
          {children}
        </Grid>
      ) : (
        <Grid item display="grid" paddingRight="7px">
          <span>{name}</span>
        </Grid>
      )}
      <Grid item display="grid" alignItems="center">
        <Tooltip title="Ordre croissant">
          <IconButton style={{ margin: "0", padding: "0" }}>
            <KeyboardArrowDownRounded
              onClick={() => {
                setSortingType((x: string) =>
                  x === sortingTypeCroissantName
                    ? null
                    : sortingTypeCroissantName
                );
              }}
              fontSize="medium"
              style={{
                color:
                  sortingType === sortingTypeCroissantName
                    ? "#0B243C"
                    : undefined,
              }}
            />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item display="grid" alignItems="center">
        <Tooltip title="Ordre décroissant">
          <IconButton style={{ margin: "0", padding: "0" }}>
            <KeyboardArrowUpRounded
              onClick={() => {
                setSortingType((x: string) =>
                  x === sortingTypeDecroissantName
                    ? null
                    : sortingTypeDecroissantName
                );
              }}
              fontSize="medium"
              color={undefined}
              style={{
                color:
                  sortingType === sortingTypeDecroissantName
                    ? "#0B243C"
                    : undefined,
              }}
            />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const MesPatientTableTitles: React.FC<any> = (props) => {
  const { patientSelected, patientList, patientListFiltered, pages, actualPage } = props;
  const patientInPage = props?.pages?.[props?.actualPage]
  const patientSelectedInPage = patientInPage?.filter((x) => patientSelected.includes(x?.id))
  const patientIdsInPage = patientInPage?.map((x) => x?.id)
  const patientIdsSelectedInPage = patientSelected?.filter((x) => patientIdsInPage?.includes(x))
  const nbPatientSelected = patientIdsSelectedInPage?.length;
  const nbPatient = props?.pages?.[props?.actualPage]?.length;
  const dispatch = useDispatch();
  const certificateINSi = useAppSelector((state) => state.INSiReducer.certificate);

  return (
    <>
      <NameWithSorting
        setSortingType={props?.setSortingType}
        sortingType={props?.sortingType}
        sortingTypeCroissantName="checkbox-croissant"
        sortingTypeDecroissantName="checkbox-decroissant"
      >
        <OrangeCheckBox
          value={nbPatientSelected > 0}
          onClick={() => {
            if (nbPatient === 0) {
              dispatch(
                openSnackbar({
                  message: `Aucun patient ne peut être sélectionné`,
                  duration: 3000,
                  type: "error",
                })
              );
              return;
            }
            if (nbPatientSelected > 0) {
              for (let i = 0; i < patientSelectedInPage?.length; i++) {
                dispatch(onlyRemovePatient(patientSelectedInPage[i]));
              }
              dispatch(
                openSnackbar({
                  message: "Les patients ont bien été désélectionnés",
                  duration: 3000,
                  type: "success",
                })
              );
            } else {
              props?.pages?.[props?.actualPage]
              for (let i = 0; i < 100 && i < nbPatient; i++) {
                dispatch(onlyAddPatient(props?.pages?.[props?.actualPage][i]));
              }
              const count = nbPatient < 100 ? nbPatient : 100;
              dispatch(
                openSnackbar({
                  message: `${count} patient${
                    nbPatient > 1
                      ? "s ont été sélectionnés"
                      : " a été sélectionné"
                  }`,
                  duration: 3000,
                  type: "success",
                })
              );
            }
          }}
        />
      </NameWithSorting>
      <NameWithSorting
        name="ID"
        setSortingType={props?.setSortingType}
        sortingType={props?.sortingType}
        sortingTypeCroissantName="id-croissant"
        sortingTypeDecroissantName="id-decroissant"
      />
      <NameWithSorting
        name="Nom"
        setSortingType={props?.setSortingType}
        sortingType={props?.sortingType}
        sortingTypeCroissantName="nom-croissant"
        sortingTypeDecroissantName="nom-decroissant"
      />
      <NameWithSorting
        name="Prénom"
        setSortingType={props?.setSortingType}
        sortingType={props?.sortingType}
        sortingTypeCroissantName="prenom-croissant"
        sortingTypeDecroissantName="prenom-decroissant"
      />
      <span className="table-grid-title">Tel</span>
      {certificateINSi && (
        <NameWithSorting
          name="INS"
          setSortingType={props?.setSortingType}
          sortingType={props?.sortingType}
          sortingTypeCroissantName="ins-croissant"
          sortingTypeDecroissantName="ins-decroissant"
        />
      )}
      <NameWithSorting
        name="Pack en cours"
        setSortingType={props?.setSortingType}
        sortingType={props?.sortingType}
        sortingTypeCroissantName="pack-croissant"
        sortingTypeDecroissantName="pack-decroissant"
      />
      <NameWithSorting
        name="Groupe"
        setSortingType={props?.setSortingType}
        sortingType={props?.sortingType}
        sortingTypeCroissantName="groupe-croissant"
        sortingTypeDecroissantName="groupe-decroissant"
      />
    </>
  );
};

export default MesPatientTableTitles;
