import FullField from "../../../Fields/FullField";
import TextInput from "../../../Library/Inputs/TextInput";
import * as React from "react";

const DisplayTitleVisible: React.FC<any> = (props) => {
  const { questionnaireData, setQuestionnaireData } = props;

  return (
    <FullField title="Titre affiché" isMandatory={false}>
      <TextInput
        id="questionnaire-title-visible-input"
        isError={false}
        placeholder={questionnaireData?.title ? questionnaireData.title : ''}
        value={questionnaireData?.visibleTitle ? questionnaireData?.visibleTitle : undefined}
        onChange={(e) => {
          const questionnaireDataTmp = { ...questionnaireData };

          questionnaireDataTmp.visibleTitle = e.target.value;
          setQuestionnaireData(questionnaireDataTmp);
        }}
      />
    </FullField>
  );
};

export default DisplayTitleVisible;
