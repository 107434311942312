import { createSlice } from '@reduxjs/toolkit';

export const windowReducer = createSlice({
  name: 'window',
  initialState: {
    windowWidth: window?.innerWidth,
    windowHeight: window?.innerHeight,
  },
  reducers: {
    updateWidthAndHeight: (state, action) => {
      state.windowWidth = action.payload.width;
      state.windowHeight = action.payload.height;
    },
    updateWindowWidth: (state, action) => {
      state.windowWidth = action.payload;
    },
    updatewindowHeight: (state, action) => {
      state.windowHeight = action.payload;
    },
  }
})

export const { updateWindowWidth, updatewindowHeight, updateWidthAndHeight } = windowReducer.actions

export default windowReducer.reducer;
