const isPdf = (fileName) => {
  const str = fileName;
  const formats = ['pdf'];
  if (!str || str?.length === 0) return (false);
  for (const format of formats) {
    if (str?.endsWith(format))
      return (true);
  }
  return (false);
}

export default isPdf;