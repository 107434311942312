import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import Creatable from "react-select/creatable";
import { openSnackbar } from "../../redux/snackbarReducer";
import getOnlyValuesFromArray from "../../utils/getOnlyValuesFromArray";
import useUsersList from "../../utils/hooks/useUsersList";
import makeAPIRequest from "../../utils/makeAPIRequest";
import FullField from "../Fields/FullField";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";

const ProtocolesAdminPanel: React.FC<any> = (props) => {
  const { setIsProtocolesAdminPanelOpen } = props;
  const [adminList, setAdminList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const { usersList, isLoading, setIsLoading, formatThisList } = useUsersList();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!usersList || usersList.length === 0) return;
    setIsLoading(true);
    makeAPIRequest("get", `/protocole/access/list`, null, "v3")
      .then((res) => {
        setAdminList(formatThisList(res?.data?.admins));
        setUserList(formatThisList(res?.data?.users));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [usersList]);

  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="40px"
      >
        <FormPopupHeader
          firstTitle={"Gérer"}
          secondTitle={"les droits"}
          onClose={() => {
            setIsProtocolesAdminPanelOpen(false);
          }}
        />
      </Grid>
      {!isLoading ? (
        <Grid item display="grid">
          <Grid
            container
            display="flex"
            width="70%"
            justifySelf="center"
            direction="column"
            wrap="nowrap"
            paddingBottom="40px"
          >
            <Grid item display="grid">
              <FullField title="Liste des administrateurs" isMandatory={false}>
                <div
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid transparent",
                  }}
                >
                  <Creatable
                    noOptionsMessage={() => "Aucune option"}
                    formatCreateLabel={(userInput) => `...`}
                    isMulti={true}
                    maxMenuHeight={120}
                    options={(() => {
                      const optionsTmp = [];
                      for (const user of usersList) {
                        if (user?.email && user.email?.length > 0) {
                          optionsTmp.push({
                            label: user?.email,
                            value: user?.user_id,
                          });
                        }
                      }
                      return optionsTmp;
                    })()}
                    value={adminList}
                    placeholder="Ajouter un administrateur"
                    onChange={(e) => {
                      setAdminList(
                        e?.filter((opt) =>
                          usersList?.find((user) => user?.email == opt?.label)
                        )
                      );
                    }}
                  />
                </div>
              </FullField>
            </Grid>
            <Grid item display="grid" paddingTop="20px">
              <FullField title="Liste des utilisateurs" isMandatory={false}>
                <div
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid transparent",
                  }}
                >
                  <Creatable
                    noOptionsMessage={() => "Aucune option"}
                    formatCreateLabel={(userInput) => `...`}
                    isMulti={true}
                    maxMenuHeight={120}
                    options={(() => {
                      const optionsTmp = [];
                      for (const user of usersList) {
                        if (user?.email && user.email?.length > 0) {
                          optionsTmp.push({
                            label: user?.email,
                            value: user?.user_id,
                          });
                        }
                      }
                      return optionsTmp;
                    })()}
                    value={userList}
                    placeholder="Ajouter un utilisateur"
                    onChange={(e) => {
                      setUserList(
                        e?.filter((opt) =>
                          usersList?.find((user) => user?.email == opt?.label)
                        )
                      );
                    }}
                  />
                </div>
              </FullField>
            </Grid>
            <Grid
              item
              display="grid"
              alignItems="center"
              justifyContent="center"
              paddingTop="30px"
            >
              <OrangeButton
                animation={true}
                enable={true}
                type="submit"
                text="Mettre à jour"
                onClick={() => {
                  makeAPIRequest(
                    "post",
                    `/protocole/access/update`,
                    {
                      users: getOnlyValuesFromArray(userList),
                      admins: getOnlyValuesFromArray(adminList),
                    },
                    "v3"
                  )
                    .then((res) => {
                      dispatch(
                        openSnackbar({
                          duration: 3000,
                          message: "Les droits ont bien été mis à jour.",
                          type: "success",
                        })
                      );
                      setIsProtocolesAdminPanelOpen(false);
                    })
                    .catch((err) => {
                      dispatch(
                        openSnackbar({
                          duration: 3000,
                          message: "Impossible de mettre à jour les droits.",
                          type: "error",
                        })
                      );
                      console.log(err);
                    });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingBottom="50px"
        >
          <CircularProgress />
        </Grid>
      )}
    </FormPopup>
  );
};

export default ProtocolesAdminPanel;
