import { createSlice } from '@reduxjs/toolkit';

export const INSISlice = createSlice({
  name: 'insi',
  initialState: {
    certificate: undefined,
    hasGroup: false,
  },
  reducers: {
    setCertificate: (state, action) => {
      state.certificate = action.payload;
    },
    setHasGroup: (state, action) => {
      state.hasGroup = action.payload;
    },
  }
})

export const { setCertificate, setHasGroup } = INSISlice.actions

export default INSISlice.reducer;
