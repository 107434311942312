import { createSlice } from '@reduxjs/toolkit';

export const shareQuestionnaire = createSlice({
  name: 'shareQuestionnaire',
  initialState: {
    title: '',
    link: '',
    questionnaireId: '',
    isOpen: false,
    sharePublicStats: false,
  },
  reducers: {
    openShare: (state, action) => {
      const { questionnaireId, title, sharePublicStats } = action.payload;

      state.isOpen = true;
      state.questionnaireId = questionnaireId;
      state.title = title;
      state.sharePublicStats = sharePublicStats;
      state.link = `${window.location.origin}/#/q/${questionnaireId}`
    },
    closeShare: (state) => {
      state.isOpen = false;
      state.sharePublicStats = false;
      state.questionnaireId = '';
      state.title = '';
      state.link = '';
    },
  }
})

export const { openShare, closeShare } = shareQuestionnaire.actions

export default shareQuestionnaire.reducer;
