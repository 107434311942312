import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import BoxTitle from "../../components/Library/Box/BoxTitle";
import CreateAlertButton from "../../components/AdminAlerts/CreateAlertButton";
import CreateAlertPopup from "../../components/AdminAlerts/CreateAlertPopup";
import AlertsListContent from "./AlertsListContent";
import { useDispatch } from "react-redux";
import BottomNavigation from "../Library/Pagination/BottomNavigation";
import makeAPIRequest from "../../utils/makeAPIRequest";
import { openSnackbar } from "../../redux/snackbarReducer";

const DisplayHowManyAlerts: React.FC<any> = (props) => {
  const { alertsFiltered } = props;

  const nbAlerts = alertsFiltered?.length;

  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      {!nbAlerts || nbAlerts === 0
        ? "Aucune alerte trouvée"
        : `${nbAlerts} alerte${nbAlerts > 1 ? "s" : ""} trouvée${
            nbAlerts > 1 ? "s" : ""
          }`}
    </span>
  );
};

const AdminAlertsBox: React.FC<any> = (props) => {
  const [createAlertIsOpen, setCreateAlertIsOpen] = React.useState(false);
  const [editInfos, setEditInfos] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  const [usersList, setUsersList] = React.useState([]);

  const [alerts, setAlerts] = React.useState([]);
  const [alertsFiltered, setAlertsFiltered] = React.useState([]);

  const [perPage, setPerPage] = React.useState(10);
  const [actualPage, setActualPage] = React.useState(0);
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/users/list", null, "v3")
      .then((res) => {
        if (res?.data) {
          setUsersList(res.data);
        }
        makeAPIRequest("get", "/alerts/list", null, "v3")
          .then((res) => {
            if (res?.data?.length > 0) setAlerts(res?.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.error(err);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          openSnackbar({
            message: "Impossible de récupérer la liste des utilisateurs",
            type: "error",
            duration: 3000,
          })
        );
      });
  }, []);

  // Update alerts filtered after alerts change
  React.useEffect(() => {
    const alertsFilteredTmp = [...alerts];

    setAlertsFiltered(alertsFilteredTmp);
  }, [alerts]);

  // Update actual page after pages change
  React.useEffect(() => {
    if (actualPage === 0) return;
    if (actualPage > pages.length - 1) setActualPage(pages.length - 1);
    if (actualPage < 0) setActualPage(0);
  }, [actualPage, pages]);

  // Update pages after patient list filtered change
  React.useEffect(() => {
    const newPages = [];
    let index = 0;
    for (const alert of alertsFiltered) {
      if (index % perPage === 0) {
        newPages.push([]);
      }
      const lastIndex = newPages.length - 1;
      if (newPages[lastIndex]) {
        newPages[lastIndex].push(alert);
      }
      index++;
    }
    setPages(newPages);
  }, [alertsFiltered, perPage]);

  return (
    <>
      {createAlertIsOpen && (
        <CreateAlertPopup
          editInfos={editInfos}
          alerts={alerts}
          setAlerts={setAlerts}
          setEditInfos={setEditInfos}
          setCreateAlertIsOpen={setCreateAlertIsOpen}
          {...props}
        />
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        minWidth="320px"
        id="admin-alerts-box"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid container display="flex" direction="column" wrap="nowrap">
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing="10px"
              >
                <Grid item display="grid">
                  <BoxTitle first="Gestion" second="des alertes" />
                </Grid>
                <Grid item display="grid">
                  <CreateAlertButton
                    onClick={() => setCreateAlertIsOpen(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {isLoading && (
              <Grid
                container
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop="50px"
              >
                <CircularProgress />
              </Grid>
            )}
            {!isLoading && (
              <Grid item display="grid" paddingTop="50px">
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid" alignSelf="center">
                    <DisplayHowManyAlerts alertsFiltered={alertsFiltered} />
                  </Grid>
                  <Grid item display="grid" paddingTop="25px">
                    <AlertsListContent
                      usersList={usersList}
                      alerts={alerts}
                      setAlerts={setAlerts}
                      alertsFiltered={alertsFiltered}
                      isLoading={isLoading}
                      setEditInfos={setEditInfos}
                      setCreateAlertIsOpen={setCreateAlertIsOpen}
                      actualPage={actualPage}
                      pages={pages}
                      {...props}
                    />
                  </Grid>
                  {pages?.length > 0 && (
                    <Grid item display="grid" paddingTop="47px">
                      <BottomNavigation
                        perPage={perPage}
                        setPerPage={setPerPage}
                        actualPage={actualPage}
                        setActualPage={setActualPage}
                        pages={pages}
                        text="Alert"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminAlertsBox;
