import * as React from "react";
import { useDispatch } from "react-redux";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import FullField from "../Fields/FullField";
import TextInput from "../Library/Inputs/TextInput";
import Creatable from "react-select/creatable";
import makeAPIRequest from "../../utils/makeAPIRequest";
import { openSnackbar } from "../../redux/snackbarReducer";
import ErrorHelp from "../Library/Errors/ErrorHelp";
import { CircularProgress, Grid } from "@mui/material";
import useUsersList from "../../utils/hooks/useUsersList";
import ImportButton from "../Library/Buttons/ImportButton/ImportButton";
import {
  ArrowDropDown,
  ArrowRightAltOutlined,
  ArrowRightOutlined,
  ArrowUpwardOutlined,
} from "@mui/icons-material";

export const getDDMMYYYYFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  return dd + "/" + mm + "/" + yyyy;
};

export const genericGetFromTimestamp = (
  timestamp: number,
  format: "dd/mm/yyyy" | "hh:min" | "yyyy-mm-dd" | 'hh:min:ss' | "dd/mm/yyyy à hh:min:ss" | "dd/mm/yyyy à hh:min"
) => {
  const date = new Date(timestamp);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear().toString();
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  const ss = String(date.getSeconds()).padStart(2, "0");

  return format
    .replaceAll("dd", dd)
    .replaceAll("mm", mm)
    .replaceAll("yyyy", yyyy)
    .replaceAll("hh", hh)
    .replaceAll("min", min)
    .replaceAll("ss", ss);
};

const errorMessages = {
  emptyGroupName: "Aucun nom de groupe n'est renseigné",
  emptyUsers: "Aucun utilisateur n'a été selectionné",
  emptyINSiIDAM: "Le numéro d'autorisation n'est pas renseigné",
  emptyINSiPassword: "Le mot de passe n'est pas renseigné",
  emptyINSiVersionLPS: "La version du LPS n'est pas renseignée",
  "": "",
};

const errorSelector = {
  emptyGroupName: "#create-group-name",
  emptyUsers: "#create-group-list-members",
  emptyINSiIDAM: "#idam-field",
  emptyINSiPassword: "#password-certif-field",
  emptyINSiVersionLPS: "#version-lps-field",
  "": "",
};

interface IINSiCertificateAddPopup {
  setINSICertificate: any;
  INSICertificate: any;
  setIsPopupCertificateToFillOpen: any;
  isPopupCertificateToFillOpen: boolean;
}

export const INSiCertificateAddPopup: React.FC<IINSiCertificateAddPopup> = (
  props
) => {
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyINSiIDAM:
        props.isPopupCertificateToFillOpen && !props.INSICertificate?.IDAM,
      emptyINSiPassword:
        props.isPopupCertificateToFillOpen && !props.INSICertificate?.password,
      emptyINSiVersionLPS:
        props.isPopupCertificateToFillOpen &&
        !props.INSICertificate?.versionLPS,
    };
    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="50px"
      >
        <FormPopupHeader
          firstTitle={"Ajout"}
          secondTitle={"du certificat"}
          onClose={() => {
            props.setIsPopupCertificateToFillOpen(false);
            props.setINSICertificate(undefined);
          }}
        />
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          display="flex"
          width="70%"
          justifySelf="center"
          direction="column"
          wrap="nowrap"
          paddingBottom="50px"
        >
          <Grid item display="grid">
            <FullField title="Version LPS" isMandatory={true}>
              <TextInput
                id="version-lps-field"
                onChange={(e) => {
                  props.setINSICertificate((state) => {
                    return { ...state, versionLPS: e.target.value };
                  });
                }}
                value={props.INSICertificate?.versionLPS}
              />
            </FullField>
          </Grid>
          <Grid item display="grid" paddingTop="10px">
            <FullField title="Numéro d'autorisation (IDAM)" isMandatory={true}>
              <TextInput
                id="idam-field"
                onChange={(e) => {
                  props.setINSICertificate((state) => {
                    return { ...state, IDAM: e.target.value };
                  });
                }}
                value={props.INSICertificate?.IDAM}
              />
            </FullField>
          </Grid>
          <Grid item display="grid" paddingTop="10px">
            <FullField title="Mot de passe du certificat" isMandatory={true}>
              <TextInput
                id="password-certif-field"
                onChange={(e) => {
                  props.setINSICertificate((state) => {
                    return { ...state, password: e.target.value };
                  });
                }}
                type={"password"}
                value={props.INSICertificate?.password}
              />
            </FullField>
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" paddingTop="50px" wrap="nowrap">
              {error?.length > 0 && (
                <Grid item display="grid">
                  <ErrorHelp
                    errorMessages={errorMessages}
                    errorSelector={errorSelector}
                    error={error}
                  />
                </Grid>
              )}
              <Grid item display="grid" justifyContent="center">
                {isValidSubmit() ? (
                  <OrangeButton
                    animation={false}
                    enable={true}
                    type="submit"
                    text={"Continuer"}
                    onClick={() => {
                      makeAPIRequest(
                        "post",
                        "/insi/check_valid_certificate",
                        {
                          binary: props.INSICertificate.binary as string,
                          password: props.INSICertificate.password,
                        },
                        "v3"
                      )
                        .then((res) => {
                          const issuer = res?.data?.infos?.issuer;
                          const subject = res?.data?.infos?.subject;
                          const from = res?.data?.infos?.notBefore;
                          const to = res?.data?.infos?.notAfter;
                          if (parseInt(to) < Date.now()) {
                            dispatch(
                              openSnackbar({
                                message: `Le certificat a expiré le ${getDDMMYYYYFromTimestamp(
                                  parseInt(to)
                                )}`,
                                type: "error",
                                duration: 3000,
                              })
                            );
                            return;
                          } else if (parseInt(from) > Date.now()) {
                            dispatch(
                              openSnackbar({
                                message: `Le certificat ne sera valide qu'à partir du ${getDDMMYYYYFromTimestamp(
                                  parseInt(from)
                                )}`,
                                type: "error",
                                duration: 3000,
                              })
                            );
                            return;
                          }
                          props.setINSICertificate((state) => {
                            return {
                              ...state,
                              infos: {
                                issuer: {
                                  C: issuer?.countryName
                                    ? issuer.countryName
                                    : "Inconnu",
                                  ST: issuer?.stateOrProvinceName
                                    ? issuer.stateOrProvinceName
                                    : "Inconnu",
                                  O: issuer?.organizationName
                                    ? issuer.organizationName
                                    : "Inconnu",
                                  OU: issuer?.organizationalUnitName
                                    ? issuer.organizationalUnitName
                                    : "Inconnu",
                                  CN: issuer?.commonName
                                    ? issuer.commonName
                                    : "Inconnu",
                                },
                                subject: {
                                  C: subject?.countryName
                                    ? subject.countryName
                                    : "Inconnu",
                                  ST: subject?.stateOrProvinceName
                                    ? subject.stateOrProvinceName
                                    : "Inconnu",
                                  O: subject?.organizationName
                                    ? subject.organizationName
                                    : "Inconnu",
                                  OU: subject?.organizationalUnitName
                                    ? subject.organizationalUnitName
                                    : "Inconnu",
                                  CN: subject?.commonName
                                    ? subject.commonName
                                    : "Inconnu",
                                },
                                validity: {
                                  from: from,
                                  to: to,
                                },
                              },
                            };
                          });
                          dispatch(
                            openSnackbar({
                              message: `Le certificat est valide, vous pouvez l'enregistrer`,
                              type: "success",
                              duration: 3000,
                            })
                          );
                          props.setIsPopupCertificateToFillOpen(false);
                        })
                        .catch((err) => {
                          dispatch(
                            openSnackbar({
                              message: `Le certificat et/ou le mot de passe est incorrect`,
                              type: "error",
                              duration: 3000,
                            })
                          );
                        });
                    }}
                  />
                ) : (
                  <OrangeButton
                    animation={false}
                    enable={false}
                    type="submit"
                    text={"Continuer"}
                    onClick={() => {
                      return null;
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormPopup>
  );
};

const CreateGroupPopup: React.FC<any> = (props) => {
  const { editInfos, groups, setGroups } = props;
  const [error, setError] = React.useState("");
  const { setCreateGroupIsOpen, setEditInfos } = props;
  const [groupName, setGroupName] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const { usersList, isLoading } = useUsersList();
  const [isPopupCertificateToFillOpen, setIsPopupCertificateToFillOpen] =
    React.useState<boolean>(false);
  const [displayCertificateINSiDetails, setDisplayCertificateINSiDetails] =
    React.useState(true);
  const [INSICertificate, setINSICertificate] = React.useState<
    | {
        binary: string;
        name: string;
        password: string;
        versionLPS: string;
        IDAM: string;
        infos?: {
          subject: {
            C: string;
            ST: string;
            O: string;
            OU: string;
            CN: string;
          };
          issuer: {
            C: string;
            ST: string;
            O: string;
            OU: string;
            CN: string;
          };
          validity: {
            from: string;
            to: string;
          };
        };
      }
    | undefined
  >(undefined);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setGroupName(editInfos?.name);
    setINSICertificate(editInfos?.INSICertificate);
  }, [editInfos]);

  React.useEffect(() => {
    if (editInfos) {
      const usersTmp = [];
      for (const user of usersList) {
        if (user?.email && user.email?.length > 0) {
          if (editInfos?.users?.includes(user?.user_id)) {
            usersTmp.push({
              label: user?.email,
              value: user?.user_id,
            });
          }
        }
      }
      setUsers(usersTmp);
    }
  }, [editInfos, usersList]);

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyGroupName: !isPopupCertificateToFillOpen && !groupName,
      emptyUsers:
        !isPopupCertificateToFillOpen && (!users || users?.length === 0),
      emptyINSiIDAM: isPopupCertificateToFillOpen && !INSICertificate?.IDAM,
      emptyINSiPassword:
        isPopupCertificateToFillOpen && !INSICertificate?.password,
      emptyINSiVersionLPS:
        isPopupCertificateToFillOpen && !INSICertificate?.versionLPS,
    };
    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  if (isPopupCertificateToFillOpen && INSICertificate) {
    return (
      <INSiCertificateAddPopup
        setIsPopupCertificateToFillOpen={setIsPopupCertificateToFillOpen}
        INSICertificate={INSICertificate}
        isPopupCertificateToFillOpen={isPopupCertificateToFillOpen}
        setINSICertificate={setINSICertificate}
      />
    );
  }
  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="50px"
      >
        <FormPopupHeader
          firstTitle={editInfos ? "Modifier" : "Créer"}
          secondTitle={"un groupe"}
          onClose={() => {
            setCreateGroupIsOpen(false);
            setEditInfos(null);
          }}
        />
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          display="flex"
          width="70%"
          justifySelf="center"
          direction="column"
          wrap="nowrap"
          paddingBottom="50px"
        >
          {isLoading && (
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingTop="15px"
            >
              <CircularProgress />
            </Grid>
          )}
          {!isLoading && (
            <>
              <Grid item display="grid">
                <FullField isMandatory={true} title="Nom du groupe">
                  <TextInput
                    id="create-group-name"
                    onChange={(e) => {
                      setGroupName(e.target.value);
                    }}
                    value={groupName}
                  />
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField isMandatory={true} title="Membres du groupe">
                  <div
                    id="create-group-list-members"
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid transparent",
                    }}
                  >
                    <Creatable
                      noOptionsMessage={() => "Aucune option"}
                      formatCreateLabel={(userInput) => `...`}
                      isMulti={true}
                      maxMenuHeight={120}
                      options={(() => {
                        const optionsTmp = [];
                        for (const user of usersList) {
                          if (
                            user?.email &&
                            user?.token &&
                            user.email?.length > 0
                          ) {
                            optionsTmp.push({
                              label: user?.email,
                              value: user?.user_id,
                            });
                          }
                        }
                        return optionsTmp;
                      })()}
                      value={users}
                      placeholder="Liste des adresses"
                      onChange={(e) => {
                        setUsers(
                          e?.filter((opt) =>
                            usersList?.find((user) => user?.email == opt?.label)
                          )
                        );
                      }}
                    />
                  </div>
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField
                  isMandatory={false}
                  title="Certificat INSi du groupe"
                >
                  <Grid container direction="row">
                    <Grid item display="grid" style={{ width: "100%" }}>
                      <ImportButton
                        isAfterImport={INSICertificate != undefined}
                        fileNameAfterImport={INSICertificate?.name}
                        onCloseAfterImport={() => setINSICertificate(undefined)}
                        label={"Joindre un certificat (INSi)"}
                        onChange={(e) => {
                          const fileReader = new FileReader();
                          if (e.target.files[0] != null) {
                            if (e.target.files[0].size >= 500000000) {
                              dispatch(
                                openSnackbar({
                                  message: "Le fichier est trop lourd",
                                  type: "error",
                                  duration: 3000,
                                })
                              );
                            } else {
                              fileReader.readAsBinaryString(e.target.files[0]);
                              fileReader.onload = (event) => {
                                setIsPopupCertificateToFillOpen(true);
                                setINSICertificate({
                                  binary: event.target.result as string,
                                  name: e.target.files[0].name,
                                  password: "",
                                  versionLPS: "",
                                  IDAM: "",
                                  infos: null,
                                });
                              };
                            }
                          }
                        }}
                        acceptedFormat={[".pfx", ".p12"]}
                      >
                        <>
                          {INSICertificate?.infos?.validity && (
                            <span
                              style={{
                                fontSize: "12px",
                                marginLeft: "15px",
                                color: "#0B243C",
                                fontFamily: "Poppins",
                              }}
                            >
                              Valide du{" "}
                              {getDDMMYYYYFromTimestamp(
                                parseInt(INSICertificate.infos.validity.from)
                              )}{" "}
                              au{" "}
                              {getDDMMYYYYFromTimestamp(
                                parseInt(INSICertificate.infos.validity.to)
                              )}
                            </span>
                          )}
                          {INSICertificate?.infos && (
                            <Grid
                              container
                              direction="row"
                              justifySelf="flex-start"
                              paddingBottom="3px"
                              paddingTop="5px"
                              marginLeft="8px"
                              wrap="nowrap"
                              width="unset"
                              onClick={() =>
                                setDisplayCertificateINSiDetails((s) => !s)
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <Grid
                                item
                                display="grid"
                                alignSelf="center"
                                justifySelf="center"
                              >
                                {displayCertificateINSiDetails ? (
                                  <ArrowDropDown
                                    style={{
                                      color: "#0B243C",
                                    }}
                                  />
                                ) : (
                                  <ArrowRightOutlined
                                    style={{
                                      color: "#0B243C",
                                    }}
                                  />
                                )}
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                alignSelf="center"
                                justifySelf="center"
                              >
                                <span
                                  style={{
                                    color: "#0B243C",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Détails
                                </span>
                              </Grid>
                            </Grid>
                          )}
                          {INSICertificate?.infos &&
                          displayCertificateINSiDetails ? (
                            <Grid
                              container
                              direction="column"
                              wrap="nowrap"
                              paddingLeft="40px"
                              paddingBottom="10px"
                              paddingRight="15px"
                            >
                              <Grid item display="grid">
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    color: "#0B243C",
                                  }}
                                >
                                  {"Entité représentée par le certificat"}
                                </span>
                              </Grid>
                              {Object.keys(INSICertificate.infos.subject).map(
                                (subjectElementKey, index) => {
                                  if (
                                    INSICertificate.infos.subject[
                                      subjectElementKey
                                    ] === "Inconnu"
                                  ) {
                                    return <></>;
                                  }
                                  return (
                                    <Grid item display="grid" key={index}>
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "12px",
                                          color: "#0B243C",
                                        }}
                                      >
                                        {subjectElementKey} :{" "}
                                        {
                                          INSICertificate.infos.subject[
                                            subjectElementKey
                                          ]
                                        }
                                      </span>
                                    </Grid>
                                  );
                                }
                              )}
                              <Grid item display="grid" paddingTop="10px">
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    color: "#0B243C",
                                  }}
                                >
                                  {"Émetteur"}
                                </span>
                              </Grid>
                              {Object.keys(INSICertificate.infos.issuer).map(
                                (issuerElementKey, index) => {
                                  if (
                                    INSICertificate.infos.issuer[
                                      issuerElementKey
                                    ] === "Inconnu"
                                  ) {
                                    return <></>;
                                  }
                                  return (
                                    <Grid item display="grid" key={index}>
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "12px",
                                          color: "#0B243C",
                                        }}
                                      >
                                        {issuerElementKey} :{" "}
                                        {
                                          INSICertificate.infos.issuer[
                                            issuerElementKey
                                          ]
                                        }
                                      </span>
                                    </Grid>
                                  );
                                }
                              )}
                              <Grid item display="grid" paddingTop="10px">
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    color: "#0B243C",
                                  }}
                                >
                                  {"Informations LPS"}
                                </span>
                              </Grid>
                              <Grid item display="grid">
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    color: "#0B243C",
                                  }}
                                >
                                  {`IDAM : ${
                                    INSICertificate?.IDAM
                                      ? INSICertificate?.IDAM
                                      : "Inconnu"
                                  }`}
                                </span>
                              </Grid>
                              <Grid item display="grid">
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    color: "#0B243C",
                                  }}
                                >
                                  {`Version : ${
                                    INSICertificate?.versionLPS
                                      ? INSICertificate?.versionLPS
                                      : "Inconnu"
                                  }`}
                                </span>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </>
                      </ImportButton>
                    </Grid>
                    <Grid item display="grid"></Grid>
                  </Grid>
                </FullField>
              </Grid>
              <Grid item display="grid">
                <Grid container direction="column" paddingTop="50px" wrap="nowrap">
                  {error?.length > 0 && (
                    <Grid item display="grid">
                      <ErrorHelp
                        errorMessages={errorMessages}
                        errorSelector={errorSelector}
                        error={error}
                      />
                    </Grid>
                  )}
                  <Grid item display="grid" justifyContent="center">
                    {isValidSubmit() ? (
                      <OrangeButton
                        animation={false}
                        enable={true}
                        type="submit"
                        text={editInfos ? "Mettre à jour" : "Créer"}
                        onClick={
                          editInfos
                            ? () => {
                                let toSend = {
                                  name: groupName,
                                  INSICertificate: INSICertificate,
                                  users: (() => {
                                    const usersIds = [];
                                    for (const user of users) {
                                      if (user?.value)
                                        usersIds.push(user.value);
                                    }
                                    return usersIds;
                                  })(),
                                };
                                makeAPIRequest(
                                  "post",
                                  `/group/${editInfos?._id}/update`,
                                  toSend,
                                  "v3"
                                )
                                  .then((res) => {
                                    makeAPIRequest(
                                      "get",
                                      "/groups/list",
                                      null,
                                      "v3"
                                    )
                                      .then((res) => {
                                        if (res?.data?.length > 0)
                                          setGroups(res?.data);
                                        setCreateGroupIsOpen(false);
                                        setEditInfos(null);
                                        dispatch(
                                          openSnackbar({
                                            message: `Le groupe ${groupName} a été modifié avec succès`,
                                            duration: 3000,
                                            type: "success",
                                          })
                                        );
                                      })
                                      .catch((err) => {
                                        dispatch(
                                          openSnackbar({
                                            type: "error",
                                            message:
                                              "Impossible de récupérer la liste des groupes",
                                            duration: 3000,
                                          })
                                        );
                                      });
                                  })
                                  .catch((err) => {
                                    dispatch(
                                      openSnackbar({
                                        type: "error",
                                        message:
                                          "Impossible de mettre à jour le groupe",
                                        duration: 3000,
                                      })
                                    );
                                  });
                              }
                            : () => {
                                let toSend = {
                                  name: groupName,
                                  INSICertificate: INSICertificate,
                                  users: (() => {
                                    const usersIds = [];
                                    for (const user of users) {
                                      if (user?.value)
                                        usersIds.push(user.value);
                                    }
                                    return usersIds;
                                  })(),
                                };
                                makeAPIRequest(
                                  "post",
                                  "/group/create",
                                  toSend,
                                  "v3"
                                )
                                  .then((res) => {
                                    makeAPIRequest(
                                      "get",
                                      "/groups/list",
                                      null,
                                      "v3"
                                    )
                                      .then((res) => {
                                        if (res?.data?.length > 0)
                                          setGroups(res?.data);
                                        setCreateGroupIsOpen(false);
                                        setEditInfos(null);
                                        dispatch(
                                          openSnackbar({
                                            message: `Le groupe ${groupName} a été crée avec succès`,
                                            duration: 3000,
                                            type: "success",
                                          })
                                        );
                                      })
                                      .catch((err) => {
                                        dispatch(
                                          openSnackbar({
                                            type: "error",
                                            message:
                                              "Impossible de récupérer la liste des groupes",
                                            duration: 3000,
                                          })
                                        );
                                      });
                                  })
                                  .catch((err) => {
                                    dispatch(
                                      openSnackbar({
                                        type: "error",
                                        message:
                                          "Impossible de créer le groupe",
                                        duration: 3000,
                                      })
                                    );
                                  });
                              }
                        }
                      />
                    ) : (
                      <OrangeButton
                        animation={false}
                        enable={false}
                        type="submit"
                        text={"Créer"}
                        onClick={() => {
                          return null;
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </FormPopup>
  );
};

export default CreateGroupPopup;
