import "../../../styles/ConseilCard.scss";
import "../../../services/user.service";
import { Tabs, Tab } from "react-bootstrap";
import * as React from "react";
import RecentsTabContent from "./RecentsTab/RecentsTabContent";
import RecentsTabButton from "./RecentsTab/RecentsTabButton";
import FavoriteTabButton from "./FavoriteTab/FavoriteTabButton";
import FavoriteTabContent from "./FavoriteTab/FavoriteTabContent";
import SearchTabButton from "./SearchTab/SearchTabButton";
import SearchComponents from "../../SearchComponents";
import AdminTabButton from "./AdminTab/AdminTabButton";
import AdminTabContent from "./AdminTab/AdminTabContent";
import useAppSelector from "../../../redux/useAppSelector";
import { CircularProgress } from "@mui/material";

const TabsContainer: React.FC<any> = (props) => {
  const [numberOfFavorite, setNumberOfFavorite] = React.useState(null);
  const [favoriteLoading, setFavoriteLoading] = React.useState([]);
  const searchTabs = useAppSelector((state) => state.tabsReducer.searchTabs);
  const activeTab = useAppSelector((state) => state.tabsReducer.activeTab);
  const user = useAppSelector((x) => x.userReducer.user);
  const displaySpec = useAppSelector((x) => x.languageReducer.displaySpec);
  
  if (!user || !displaySpec) {
    return (
      <>
        <div
          style={{
            paddingTop: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      </>
    );
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <Tabs activeKey={activeTab} className="mb-3 background-tabs">
          <Tab eventKey="recents" title={<RecentsTabButton {...props} />}></Tab>
          <Tab
            style={{ padding: "0 0 0 0" }}
            eventKey="favourite"
            title={
              <FavoriteTabButton
                {...props}
                numberOfFavorite={numberOfFavorite}
              />
            }
          >
            <FavoriteTabContent
              {...props}
              favoriteLoading={favoriteLoading}
              setFavoriteLoading={setFavoriteLoading}
              setNumberOfFavorite={setNumberOfFavorite}
            />
          </Tab>
          {searchTabs.map((tab, index) => (
            <Tab
              key={`search_tab_${index}`}
              eventKey={"searched" + index}
              title={<SearchTabButton index={index} tab={tab} />}
            >
              <SearchComponents
                tab={tab}
                favoriteLoading={favoriteLoading}
                setFavoriteLoading={setFavoriteLoading}
                i={index}
                index={index}
                {...props}
              />
            </Tab>
          ))}
          {props?.user?.user?.account_type === "4" && (
            <Tab
              style={{ padding: "0 0 0 0" }}
              eventKey="admin"
              title={<AdminTabButton {...props} />}
            ></Tab>
          )}
        </Tabs>
      </div>
      {props?.user?.user?.account_type === "4" && activeTab === "admin" && (
        <div style={{ paddingTop: "0px" }}>
          <AdminTabContent {...props} />
        </div>
      )}
      {activeTab === "recents" && (
        <div style={{ paddingTop: "0px", minHeight: "500px" }}>
          <RecentsTabContent
            {...props}
            favoriteLoading={favoriteLoading}
            setFavoriteLoading={setFavoriteLoading}
          />
        </div>
      )}
      {searchTabs.map((tab, index) => (
        <>
          {activeTab === `search_tab_${index}` && (
            <div style={{ minHeight: "500px" }}>
              <SearchComponents
                tab={tab}
                favoriteLoading={favoriteLoading}
                setFavoriteLoading={setFavoriteLoading}
                i={index}
                index={index}
                {...props}
              />
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default TabsContainer;
