import { createSlice } from '@reduxjs/toolkit';

interface IState {
  sendSmsCost?: number,
  displayMoreConseilsCost?: number,
}

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState: {
    sendSmsCost: undefined,
    displayMoreConseilsCost: undefined,
  } as IState,
  reducers: {
    updateTokensCost: (state, action) => {
      const { sendSmsCost, displayMoreConseilsCost } = action.payload;

      state.sendSmsCost = sendSmsCost;
      state.displayMoreConseilsCost = displayMoreConseilsCost;
    }
  }
})

export const { updateTokensCost } = tokensSlice.actions

export default tokensSlice.reducer