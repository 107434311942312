import { Grid } from "@mui/material";
import * as React from "react";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import FullField from "../../../Fields/FullField";

const DisplayModeDisplay: React.FC<any> = (props) => {
  const { questionnaireData, setQuestionnaireData } = props;

  return (
    <FullField title="Mode d'affichage" isMandatory={false}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid">
          <OrangeCheckBox
            value={questionnaireData?.displayMode === 'page'}
            onClick={() => {
              const questionnaireDataTmp = { ...questionnaireData };
              if (questionnaireDataTmp.displayMode === 'page') {
                questionnaireDataTmp.displayMode = 'full';
              } else {
                questionnaireDataTmp.displayMode = 'page'
              }
              setQuestionnaireData(questionnaireDataTmp);
            }}
            text="Afficher les sections page par page"
          />
        </Grid>
      </Grid>
    </FullField>
  );
};

export default DisplayModeDisplay;
