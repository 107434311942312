import FullField from "../FullField";
import * as React from 'react';
import TextInput from "../../Library/Inputs/TextInput";

interface IAdviceUrlField {
  adviceUrl: string,
  onChange: any,
}

const AdviceUrlField = (props: IAdviceUrlField) => {
  const {
    adviceUrl,
    onChange,
  } = props;

  return (
    <FullField title='Ajouter un lien web' isMandatory={false}>
      <TextInput
        value={adviceUrl}
        onChange={(e) => onChange(e)} />
    </FullField>
  );
};

export default AdviceUrlField;