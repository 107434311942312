const isNumber = (str: any) => {
  if (typeof str != "string") return false;

  return !isNaN(str as any) && !isNaN(parseInt(str));
}

export const isPositiveOrNullNumber = (str: string): boolean => {
  if (!isNumber(str)) return (false);
  const number = parseInt(str);
  if (number >= 0) return (true);
  return (false);
}

const isPositiveNumber = (str: string): boolean => {
  if (!isNumber(str)) return (false);
  const number = parseInt(str);
  if (number > 0) return (true);
  return (false);
}

export const isPositiveFloat = (str: string): boolean => {
  if (!isNumber(str)) return (false);
  const number = parseFloat(str);
  if (number > 0) return (true);
  return (false);
}

export default isPositiveNumber;
