import FullField from "../../../../Fields/FullField";
import * as React from "react";
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import { useDispatch } from "react-redux";
import addingErrorStyleToField from "../../../../../utils/addingErrorStyleToField";
import { Grid } from "@mui/material";
import MySelect from "../../../../Library/Select/MySelect";
import useAppSelector from "../../../../../redux/useAppSelector";

const DisplayEchelleDeNotation: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const fieldCopy = useAppSelector(
    (state) => state.questionnaireReducer.fieldCopy
  );
  const dispatch = useDispatch();

  let style = {
    borderRadius: "10px",
    width: "100%",
    border: "1px solid transparent",
  };
  style = addingErrorStyleToField(style, isError);

  return (
    <FullField title="Options" isMandatory={true}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            spacing="10px"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item display="grid">
              <MySelect
                maxMenuHeight={'120px'}
                isClearable={false}
                onChange={(e) => {
                  const fieldCopyTmp = { ...fieldCopy };
                  const options = [...fieldCopyTmp.option];
                  options[0] = e?.label;
                  fieldCopyTmp.option = options;
                  dispatch(setFieldCopy(fieldCopyTmp));
                }}
                placeholder=""
                value={{
                  label: fieldCopy?.option?.[0],
                  value: fieldCopy?.option?.[0],
                }}
                options={[
                  { label: 0, value: 0 },
                  { label: 1, value: 1 },
                ]}
              />
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#0B243C",
                }}
              >
                à
              </span>
            </Grid>
            <Grid item display="grid">
              <MySelect
                maxMenuHeight={'120px'}
                isClearable={false}
                onChange={(e) => {
                  const fieldCopyTmp = { ...fieldCopy };
                  const options = [...fieldCopyTmp.option];
                  options[1] = e?.label;
                  fieldCopyTmp.option = options;
                  dispatch(setFieldCopy(fieldCopyTmp));
                }}
                placeholder=""
                value={{
                  label: fieldCopy?.option?.[1],
                  value: fieldCopy?.option?.[1],
                }}
                options={
                  (() => {
                    const MAX_NOTATION = 30;
                    const opt = [];
                    for (let i = 1; i <= MAX_NOTATION; i++) {
                      opt.push({label: i, value: i});
                    }
                    return (opt);
                  })()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullField>
  );
};

export default DisplayEchelleDeNotation;
