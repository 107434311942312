import { Grid } from '@mui/material';
import React from 'react';

interface Props {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  acceptedFormat: Array<string>;
  label: string;
  isAfterImport?: boolean;
  fileNameAfterImport?: string;
  onCloseAfterImport?: () => void;
}

const ImportButton: React.FC<Props> = (props) => {
  if (props.isAfterImport && props.fileNameAfterImport && props.onCloseAfterImport) {
    return (
      <Grid container direction='column' wrap="nowrap" display='flex' border='2px dashed #8d9899' borderRadius='15px'>
        <Grid item display='grid'>
          <Grid container direction='row' justifyContent='space-between' wrap='nowrap' paddingLeft='10px' paddingRight='5px' alignItems='center' spacing='10px'>
            <Grid item display='grid' alignItems='center'>
              <span
                className='text_champs'>
                {props.fileNameAfterImport}
              </span>
            </Grid>
            <Grid item display='grid' alignItems='center'>
              <button
                onClick={() => props.onCloseAfterImport()}
                className={'delete_advice_button'}>
                Supprimer
              </button>
            </Grid>
          </Grid>
        </Grid>
        {props.children &&
          <Grid item display='grid'>
            {props.children}
          </Grid>
        }
      </Grid>
    )
  }
  return (
    <div
      className="file_modals"
      style={{
        width: "100%",
        margin: "0",
        flexDirection: "column",
        padding: "0",
        justifyContent: "center",
        display: "flex",
        cursor: "unset",
        height: "unset",
        paddingTop: "20px",
        paddingBottom: "20px",
        backgroundColor: 'transparent',
      }}
    >
      <div
        className="modal_file_button"
        style={{
          alignItems: "center",
          display: "grid",
          marginLeft: "20px",
          marginRight: "20px",
          alignSelf: 'center',
          marginTop: "0px",
          cursor: "unset",
          zIndex: 0,
        }}
      >
        <div
          className="modal_file_button_text"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            cursor: "unset",
          }}
        >
          <input
            type="file"
            name={`file-import-certificate`}
            id={`file-import-certificate`}
            onChange={props.onChange}
            accept={props.acceptedFormat.join(', ')}
            style={{ display: "none", cursor: "unset" }}
          />
          <label
            style={{ cursor: "pointer", width: "100%" }}
            htmlFor={`file-import-certificate`}
          >
            {props.label}
          </label>
        </div>
      </div>
      <div className="accept_format">Formats acceptés : {props.acceptedFormat.join(', ')}</div>
    </div>
  );
};

ImportButton.defaultProps = {
  onCloseAfterImport: () => {return;},
  fileNameAfterImport: '',
  isAfterImport: false,
}

export default ImportButton;
