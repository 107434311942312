import { createSlice } from '@reduxjs/toolkit';

const convertSelectorByName = (selector) => {
  if (selector === '#mes-questionnaires-box')
    return ('mesQuestionnairesBox');
  if (selector === '#admin-groups-box')
    return ('adminGroupsBox');
  if (selector === '#admin-alerts-box')
    return ('adminAlertsBox');
  if (selector === '#mes-chatbots-box')
    return ('mesChatbotsBox');
  if (selector === '#mes-chatbots-lex-box')
    return ('mesChatbotsLexBox');
  return ('');
}

const getObjectBySelector = (selector) => {
  const select = document.querySelector(selector);

  return ({
    width: select?.offsetWidth,
    height: select?.offsetHeight,
    name: convertSelectorByName(selector),
  })
}

export const componentSizeSlice = createSlice({
  name: 'componentSize',
  initialState: {
    mesChatbotsLexBox: {
      height: null,
      width: null,
    },
    mesChatbotsBox: {
      height: null,
      width: null,
    },
    mesQuestionnairesBox: {
      height: null,
      width: null,
    },
    adminGroupsBox: {
      height: null,
      width: null,
    },
    adminAlertsBox: {
      height: null,
      width: null,
    }
  },
  reducers: {
    updateSize: (state, action) => {
      const selector = action.payload;
      const result = getObjectBySelector(selector);

      state[result?.name].width = result?.width;
      state[result?.name].height = result?.height;
    },
  }
})

export const {
  updateSize,
} = componentSizeSlice.actions

export default componentSizeSlice.reducer;
