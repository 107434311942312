import React from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/snackbarReducer";
import makeAPIRequest from "../makeAPIRequest";

const useUsersList = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [usersList, setUsersList] = React.useState([]);
  const dispatch = useDispatch();

  const formatThisList = (
    list: Array<string>
  ): Array<{ value: string; label: string }> => {
    const newList: Array<{ value: string; label: string }> = [];
    if (list?.length == 0) return newList;
    for (const element of list) {
      const email = usersList.find((u) => u?.user_id == element)?.email;
      if (email) {
        newList.push({
          value: element,
          label: usersList.find((u) => u?.user_id == element)?.email,
        });
      }
    }
    return newList;
  };

  React.useEffect(() => {
    makeAPIRequest("get", "/users/list", null, "v3")
      .then((res) => {
        if (res?.data) {
          setUsersList(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        dispatch(
          openSnackbar({
            message: "Impossible de récupérer la liste des utilisateurs",
            type: "error",
            duration: 3000,
          })
        );
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      makeAPIRequest("get", "/users/list", null, "v3")
        .then((res) => {
          if (res?.data) {
            setUsersList(res.data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          dispatch(
            openSnackbar({
              message: "Impossible de récupérer la liste des utilisateurs",
              type: "error",
              duration: 3000,
            })
          );
          setIsLoading(false);
        });
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return {
    isLoading: isLoading,
    usersList: usersList,
    setUsersList: setUsersList,
    setIsLoading: setIsLoading,
    formatThisList: formatThisList,
  };
};

export default useUsersList;
