import * as React from "react";
import { Grid } from "@mui/material";
import AddButton from "../../../Library/Buttons/AddButton/AddButton";
import { Delete, DragIndicator, Edit, EditRounded } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import initField from "../../GenerateQuestionnaire/InitObjects/initField";
import DisplayFieldRows from "./DisplayFieldRows";
import SectionPopupEdit from "../QuestionnairePopupEdit/SectionPopupEdit";
import { getConsentementPhrase } from "../../GenerateQuestionnaire/GenerateQuestionnaire";
import { ISection } from "../../../../types/FormTypes";

const DisplaySections: React.FC<any> = (props) => {
  const { setQuestionnaireData, questionnaireData } = props;
  const [isSectionEditPopup, setIsSectionEditPopup] = React.useState(null);

  const handleDropsections = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const sectionsTmp = [...questionnaireData?.sections];
    // Remove dragged item
    const [reorderedItem] = sectionsTmp.splice(droppedItem.source.index, 1);
    // Add dropped itema
    sectionsTmp.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    const questionnaireDataTmp = { ...questionnaireData };
    questionnaireDataTmp.sections = sectionsTmp;
    setQuestionnaireData(questionnaireDataTmp);
  };

  return (
    <>
    {isSectionEditPopup &&
      <SectionPopupEdit sectionInfos={isSectionEditPopup} setIsSectionEditPopup={setIsSectionEditPopup} {...props} />
    }
      <DragDropContext onDragEnd={handleDropsections}>
        <Droppable droppableId="droppable-sections">
          {(provided) => (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              spacing="20px"
              display="flex"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {questionnaireData?.sections?.map((section: ISection, sectionIndex) => {
                return (
                  <Draggable
                    key={section?.id}
                    draggableId={section?.id}
                    index={sectionIndex}
                  >
                    {(provided) => (
                      <Grid
                        item
                        key={sectionIndex}
                        display="grid"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Grid
                          container
                          overflow='auto'
                          direction="row"
                          component={undefined}
                          border="2px solid #0B243C"
                          boxShadow="6px 6px 2px 1px rgba(11, 36, 60, .5)"
                          backgroundColor="rgba(247, 252, 252, 1)"
                          borderRadius="8px"
                          wrap="nowrap"
                        >
                          <Grid item display="grid">
                            <Grid
                              container
                              direction="column"
                              wrap="nowrap"
                              justifyContent="center"
                              alignItems="center"
                              padding="10px"
                              borderRight="2px solid #0B243C"
                            >
                              <Grid
                                item
                                display="grid"
                                {...provided.dragHandleProps}
                              >
                                <DragIndicator style={{ color: "#0B243C" }} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item display="grid" width="100%" padding="10px">
                            <Grid container direction="column" wrap="nowrap">
                              <Grid item display="grid">
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "18px",
                                    color: "#23595d",
                                  }}
                                >
                                  {section?.title
                                    ? section?.title
                                    : "Sans titre"}
                                </span>
                              </Grid>
                              {section?.fields?.length > 0 && (
                                <Grid item display="grid" paddingTop="5px">
                                  <DisplayFieldRows
                                    questionnaireData={questionnaireData}
                                    setQuestionnaireData={setQuestionnaireData}
                                    section={section}
                                    sectionIndex={sectionIndex}
                                  />
                                </Grid>
                              )}
                              <Grid
                                item
                                display="grid"
                                paddingTop={
                                  section?.fields?.length > 0 ? "15px" : "5px"
                                }
                                justifyContent="flex-start"
                              >
                                <AddButton
                                  displayText={true}
                                  text="Ajouter un champ"
                                  onClick={() => {
                                    // Copy data
                                    const questionnaireDataTmp = {
                                      ...questionnaireData,
                                    };
                                    // Push new field
                                    const fieldsTmp = section?.fields;
                                    fieldsTmp.push([initField(getConsentementPhrase(questionnaireData, undefined) ? false : true)]);
                                    questionnaireDataTmp.sections[
                                      sectionIndex
                                    ].fields = fieldsTmp;
                                    // Update data
                                    setQuestionnaireData(questionnaireDataTmp);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            display="grid"
                            borderLeft="2px solid #0B243C"
                          >
                            <Grid
                              container
                              minWidth='100%'
                              direction="column"
                              wrap="nowrap"
                              justifyContent="center"
                              alignItems="center"
                              padding="10px"
                            >
                              <Grid item display="grid">
                                <EditRounded
                                  style={{
                                    cursor: "pointer",
                                    color: "#0B243C",
                                  }}
                                  onClick={() => {
                                    setIsSectionEditPopup({
                                      title: section?.title,
                                      id: section?.id,
                                      index: sectionIndex,
                                      section: section,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" paddingTop="10px">
                                <Delete
                                  style={{
                                    cursor: "pointer",
                                    color: "#0B243C",
                                  }}
                                  onClick={() => {
                                    const questionnaireDataTmp = {
                                      ...questionnaireData,
                                    };

                                    delete questionnaireDataTmp.sections[
                                      sectionIndex
                                    ];
                                    const sectionsTmp =
                                      questionnaireDataTmp.sections.filter(
                                        (element) => element != undefined
                                      );
                                    questionnaireDataTmp.sections = sectionsTmp;
                                    setQuestionnaireData(questionnaireDataTmp);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DisplaySections;
