import {
  DeleteRounded,
  EditRounded,
  PreviewRounded,
  ScheduleSendRounded,
  SearchOutlined,
  ShareRounded,
} from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { openSchedule } from "../../redux/scheduleChatbotReducer";
import { openSnackbar } from "../../redux/snackbarReducer";
import makeAPIRequest from "../../utils/makeAPIRequest";
import FullField from "../Fields/FullField";
import TextInput from "../Library/Inputs/TextInput";
import CancellablePopup from "../Library/Popups/CancellablePopup";
import { getQuestionnairesInteractifs, questionnaireInteractifActions } from "../../redux/new/questionnaireInteractifReducer";
import useAppDispatch from "../../redux/useAppDispatch";
import useAppSelector from "../../redux/useAppSelector";

const DisplayChatbot: React.FC<any> = (props) => {
  const [isDeletePopup, setIsDeletePopup] = React.useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { chatbots, chatbot } = props;

  return (
    <>
      {isDeletePopup && (
        <CancellablePopup
          firstTitle="Mes"
          isLoading={isDeleteLoading}
          secondTitle="Questionnaires Interactifs"
          messages={[
            `Voulez-vous vraiment supprimer le questionnaire interactif '${chatbot?.title}' ?`,
            "Toutes les conversations seront perdues",
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => setIsDeletePopup(false)}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest(
              "post",
              "/chatbot/delete",
              { chatbot_id: chatbot?.id },
              "v3"
            )
              .then((res) => {
                dispatch(
                  openSnackbar({
                    message: `Le questionnaire interactif '${chatbot?.title}' a bien été supprimé`,
                    type: "success",
                    duration: 3000,
                  })
                );
                const chatbotsTmp = [...chatbots];
                const indexToDelete = chatbotsTmp?.findIndex(
                  (q) => q?.id === chatbot?.id
                );
                if (indexToDelete !== -1) delete chatbotsTmp[indexToDelete];
                const newChatbots = chatbotsTmp?.filter(
                  (element) => element != undefined
                );
                setIsDeleteLoading(false);
                setIsDeletePopup(false);
                dispatch(questionnaireInteractifActions.setQuestionnaireInteractifs(newChatbots));
              })
              .catch((err) => {
                dispatch(
                  openSnackbar({
                    message: `Le suppression du questionnaire interactif '${chatbot?.title}' a échouée`,
                    type: "error",
                    duration: 3000,
                  })
                );
                setIsDeleteLoading(false);
                setIsDeletePopup(false);
              });
          }}
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        wrap="nowrap"
        boxShadow="4px 4px 2px 1px rgba(11, 36, 60, .5)"
        border="1px solid rgb(11, 36, 60)"
        borderRadius="10px"
      >
        <Grid item display='grid' alignItems='center' paddingLeft='5px'>
          <Tooltip title='Voir les conversations'>
            <IconButton style={{ margin: '0', padding: '0' }}>
              <PreviewRounded
                fontSize='medium'
                style={{ color: 'rgb(11, 36, 60)' }}
                onClick={() => {
                  history.push(`/chatbot/${chatbot?.id}/reponses`);
                }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item display='grid' paddingLeft='5px' paddingRight='5px'>
          <div style={{
            height: '100%',
            borderLeft: '1px solid #0B243C'
          }} />
        </Grid>
        <Grid item display="grid" alignItems="center">
          <Tooltip title="Programmer une conversation">
            <IconButton style={{ margin: "0", padding: "0" }}>
              <ScheduleSendRounded
                fontSize="medium"
                onClick={() => {
                  dispatch(openSchedule({
                    chatbotId: chatbot?.id,
                    title: chatbot?.title,
                  }));
                }}
                style={{ color: "rgb(11, 36, 60)", cursor: "pointer" }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
          <div
            style={{
              height: "100%",
              borderLeft: "1px solid #0B243C",
            }}
          />
        </Grid>
        <Grid item display="grid" padding="5px">
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              color: "rgb(11, 36, 60)",
            }}
          >
            {chatbot?.title}
          </span>
        </Grid>
        <Grid
          item
          container
          direction="row"
          width="fit-content"
          gridAutoRows="fit-content"
          wrap="nowrap"
        >
          <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
            <div
              style={{
                height: "100%",
                borderLeft: "1px solid #0B243C",
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Tooltip title="Modifier">
              <IconButton
                style={{ margin: "0", padding: "0" }}
                onClick={() => {
                  history.push(`/edit_questionnaire_interactif/${chatbot?.id}`);
                }}
              >
                <EditRounded fontSize="small" style={{ color: "#0B243C" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
            <div
              style={{
                height: "100%",
                borderLeft: "1px solid #0B243C",
              }}
            />
          </Grid>
          <Grid item display="grid" alignItems="center" paddingRight="5px">
            <Tooltip title="Supprimer">
              <IconButton style={{ margin: "0", padding: "0" }}>
                <DeleteRounded
                  onClick={() => {
                    setIsDeletePopup(true);
                  }}
                  fontSize="medium"
                  style={{ color: "rgb(11, 36, 60)" }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const ChatbotContent = () => {
  const [searchInput, setSearchInput] = React.useState<string>("");
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((app) => app.questionnaireInteractifReducer.isLoading);
  const chatbots = useAppSelector((app) => app.questionnaireInteractifReducer.list);

  React.useEffect(() => {
    dispatch(getQuestionnairesInteractifs());
  }, []);

  const filteredChatbot = () => {
    return chatbots?.filter((chatbot) =>
      chatbot?.title?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  };

  return (
    <>
      <Grid container direction="column" spacing="15px" wrap="nowrap">
        <Grid item display="grid">
          <FullField title="Rechercher un questionnaire interactif :" isMandatory={false}>
            <TextInput
              RightIcon={<SearchOutlined style={{ color: "#0B243C" }} />}
              height="100%"
              padding="6px 25px 6px 25px"
              placeholder="Titre du questionnaire interactif"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </FullField>
        </Grid>
        {isLoading && (
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        {filteredChatbot()?.length > 0 ? (
          <Grid item display="grid">
            <Grid container direction="row" width="fit-content" spacing="10px">
              {filteredChatbot().map((chatbot, index) => {
                return (
                  <Grid item key={chatbot?.id} display="grid">
                    <DisplayChatbot
                      chatbots={chatbots}
                      chatbot={chatbot}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ) : (
          <>
            {!isLoading && (
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    color: "#0B243C",
                    fontSize: "16px",
                  }}
                >
                  Aucun questionnaire interactif trouvé.
                </span>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default ChatbotContent;
