import FullField from "../../../../Fields/FullField";
import * as React from "react";
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import { useDispatch } from "react-redux";
import MyEditor from "../../../../Library/Inputs/MyEditor";
import useAppSelector from "../../../../../redux/useAppSelector";

const DisplayTextAndImage: React.FC<any> = (props) => {
  const fieldCopy = useAppSelector(
    (state) => state.questionnaireReducer.fieldCopy
  );
  const dispatch = useDispatch();

  return (
    <FullField title="Contenu" isMandatory={true}>
      <div
        style={{
          width: "100%",
        }}
      >
        <MyEditor
          defaultFontFamily="Poppins"
          defaultFontSize="16px"
          defaultColor="#0B243C"
          initialContent={fieldCopy?.title}
          onUpdate={(html) => {
            const fieldCopyTmp = {...fieldCopy};
            fieldCopyTmp.title = html;
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
        />
      </div>
    </FullField>
  );
};

export default DisplayTextAndImage;
