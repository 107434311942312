import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { RouteComponentProps, useParams } from "react-router";
import useQuery from "../utils/hooks/useQuery";
import GenerateQuestionnaire from "../components/Questionnaire/GenerateQuestionnaire/GenerateQuestionnaire";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";
import { IForm } from "../types/FormTypes";
import { FieldsSavedInCache } from "../components/Questionnaire/GenerateQuestionnaire/DisplayButtons";

const UnknownQuestionnare = () => {
  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      Ce questionnaire n'existe plus.
    </span>
  );
};

const QuestionnaireBox: React.FC<any> = (props) => {
  const { questionnaireData, setQuestionnaireData, isLoading } = props;

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      paddingLeft="5%"
      paddingTop="5%"
      paddingRight="5%"
      paddingBottom="5%"
    >
      {isLoading && (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid item display="grid">
          {questionnaireData ? (
            <GenerateQuestionnaire
              isPreview={false}
              setQuestionnaireData={setQuestionnaireData}
              questionnaireData={questionnaireData}
            />
          ) : (
            <UnknownQuestionnare />
          )}
        </Grid>
      )}
    </Grid>
  );
};

interface IParams {
  id: string;
}

interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

const setAutoValue = (form: IForm): IForm => {
  const item: string = localStorage.getItem("formsFieldsSavedInCache");
  const formsFieldsSavedInCache: FieldsSavedInCache[] = item
    ? JSON.parse(item)
    : [];
  const foundedIndexForm = formsFieldsSavedInCache.findIndex(
    (x) => x?.formId == form?.id
  );
  if (!form) {
    return undefined;
  }
  for (let i = 0; i < form?.sections?.length; i++) {
    for (let j = 0; j < form.sections[i].fields.length; j++) {
      for (let k = 0; k < form.sections[i].fields[j].length; k++) {
        const field = form.sections[i].fields[j][k];
        if (field.saveResponse && foundedIndexForm !== -1) {
          const fieldFounded = formsFieldsSavedInCache[
            foundedIndexForm
          ].fields.find((x) => x?.fieldId == field?.id);
          if (fieldFounded) {
            form.sections[i].fields[j][k].value = fieldFounded.value;
          }
        } else {
          if (field?.defaultValue) {
            form.sections[i].fields[j][k].value = field.defaultValue;
          }
        }
      }
    }
  }
  return form;
};

const QuestionnaireViewPage: React.FC<Props> = (props: Props) => {
  const { id } = useParams<IParams>();
  const scan = useQuery().get("scan");
  const [isLoading, setIsLoading] = React.useState(true);
  const [questionnaireData, setQuestionnaireData] = React.useState(null);
  const isValidatedForm = useQuery().get("v");
  const patientId = useQuery().get("p");

  React.useEffect(() => {
    if (!isLoading) setIsLoading(true);
    makeAPIRequest(
      "get",
      `/questions/${isValidatedForm === "1" ? "form_validated" : "form"}/${id}${
        patientId
          ? `?p=${patientId}${scan === "1" ? "&scan=1" : ""}`
          : `${scan === "1" ? "?scan=1" : ""}`
      }`,
      null,
      "v3"
    )
      .then((res) => {
        const questionnaire: IForm = res?.data;
        setQuestionnaireData(setAutoValue(questionnaire));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [isValidatedForm, patientId, scan]);

  if (questionnaireData?.responseAlreadyRegistered === true) {
    return (
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="1000px"
        >
          <Grid item display="grid" padding="50px">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "#0B243C",
              }}
            >
              Vous avez déjà répondu à ce questionnaire.
            </span>
          </Grid>
        </Grid>
      </SimpleFullPage>
    );
  }
  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="1000px"
        >
          <Grid item display="grid">
            <QuestionnaireBox
              isLoading={isLoading}
              setQuestionnaireData={setQuestionnaireData}
              questionnaireData={questionnaireData}
            />
          </Grid>
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default QuestionnaireViewPage;
