import * as React from "react";
import MySelect from "../../../Library/Select/MySelect";
import "../FilterStyle.scss";
import IFilterProps from "../IFilterProps";

const AdviceSelectItem = (props: IFilterProps) => {
  const name = "conseil";

  const options = [
    {
      label: "Conseil unique",
      value: "simple",
    },
    {
      label: "Pack de conseils",
      value: "pack",
    },
    {
      label: "Sans smartphone",
      value: "without_smartphone",
    },
  ];

  const getOpt = (key: any) => {
    const founded = options.find((x) => x?.value === key);
    if (founded) {
      return founded;
    }
    return null;
  };

  return (
    <div style={{ minWidth: "200px" }}>
      <MySelect
        name={name}
        value={
          props.filterKey?.conseil ? getOpt(props.filterKey.conseil) : null
        }
        id="conseil-select"
        placeholder="Conseil"
        onChange={(e) => {
          props.handleSelectChange({
            target: {
              value: e?.value ? e?.value : null,
              name: name,
            },
          });
        }}
        options={options}
      />
    </div>
  );

  /* // Ancien select
  return (
    <select
      name={name}
      value={props.filterKey?.conseil ? props.filterKey.conseil : ''}
      id="conseil-select"
      className="select-options"
      onChange={(e) => props.handleSelectChange(e)} >
      <option value="" style={{ display: 'none' }} disabled>Conseil</option>
      <option value="simple">Conseil unique</option>
      <option value="pack">Pack de conseils</option>
    </select>
  );
  */
};

export default AdviceSelectItem;
