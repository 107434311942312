import moment from "moment-timezone";

function convertISOToCustomFormat(isoDate) {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  if (!isoRegex.test(isoDate)) {
    return (isoDate)
  }
  return moment(isoDate).tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss');
}
const formatDate = (date: string) => {
  const changeDate = convertISOToCustomFormat(date)
  if (!changeDate) return ('');
  const calcHours = (hours) => {
    if (!hours) { return (0)}
    let newHours = hours.split(':');
    newHours = newHours?.[0] + 'h' + newHours?.[1];
    return (newHours);
  }
  const splited = changeDate.split(' ');
  const newDate = {
    date: splited[0].replaceAll('-', '/'),
    hours: calcHours(splited[1]),
  };

  return (newDate);
}

export const formatDateEN = (date: string) => {
  if (!date) return ('');
  return (date.toString());
}

export default formatDate;
