import { Grid } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as snackbarReducer from '../../../../redux/snackbarReducer';
import FullField from '../../../Fields/FullField';
import AddButton from '../../../Library/Buttons/AddButton/AddButton';
import TextInput from '../../../Library/Inputs/TextInput';
import initSection from '../../GenerateQuestionnaire/InitObjects/initSection';
import useAppSelector from '../../../../redux/useAppSelector';

const DisplayAddSection: React.FC<any> = (props) => {
  const [sectionInput, setSectionInput] = React.useState('');
  const dispatch = useDispatch();
  const windowWidth = useAppSelector(state => state.windowReducer.windowWidth);
  const {
    questionnaireData,
    setQuestionnaireData,
  } = props;

  return (
    <FullField title='Sections' isMandatory={false}>
      <Grid container direction='row' justifyContent='flex-end' spacing='5px' paddingBottom='10px'>
        <Grid item xs display='grid'>
          <TextInput id='questionnaire-section-box' placeholder='Titre de la section' value={sectionInput} onChange={(e) => setSectionInput(e.target.value)} />
        </Grid>
        <Grid item display='grid'>
          <AddButton
            onClick={() => {
              const questionnaireDataTmp = { ...questionnaireData };

              if (questionnaireDataTmp.sections.find((section) => section?.title === sectionInput)) {
                dispatch(snackbarReducer?.openSnackbar(
                  {
                    message: 'Cette section existe déjà',
                    type: 'error',
                    duration: 5000,
                  }
                ));
                return;
              }
              questionnaireDataTmp.sections.push(initSection(sectionInput));
              setQuestionnaireData(questionnaireDataTmp);
              setSectionInput('');
            }}
            displayText={windowWidth > 600 ? true : false} text='Créer une nouvelle section' />
        </Grid>
      </Grid>
    </FullField>
  )
};

export default DisplayAddSection;