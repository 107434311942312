import * as React from 'react';

const GroupsListTableTitles = () => {
  return (
    <>
      <span className='table-grid-title'>
        Nom du groupe
      </span>
      <span className='table-grid-title'>
        Membres
      </span>
      <span className='table-grid-title'>
        Certificat INSi
      </span>
      <span className='table-grid-title'>
        
      </span>
    </>
  )
}

export default GroupsListTableTitles;