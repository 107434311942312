import * as React from "react";
import { Grid } from "@mui/material";
import AddButton from "../../../Library/Buttons/AddButton/AddButton";
import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import initField from "../../GenerateQuestionnaire/InitObjects/initField";
import { setFieldCopy } from "../../../../redux/questionnaireReducer";
import resizeText from "../../../../utils/resizeText";
import typeOptions from "./../typeOptions";
import { getConsentementPhrase } from "../../GenerateQuestionnaire/GenerateQuestionnaire";
import { IField } from "../../../../types/FormTypes";
import { ButtonIconWithTooltip } from "../../PDF/CreateOrEditPDFQuestionnaire";
import { nanoid } from "nanoid";

export const getFieldTitleSpecial = (field: IField) => {
  if (field?.type === "protocole") {
    return "Protocoles";
  } else if (field?.type === "medecin_delegant") {
    return "Médecins délégants";
  } else if (field?.type === "date_sortie") {
    return "Date de sortie";
  } else if (field?.type === "structure") {
    return "Structures";
  } else if (field?.type === "display_text_and_image") {
    return "Textes et images personnalisés";
  } else {
    if (field?.title) {
      return field.title;
    }
  }
  return "Sans titre";
};

const DisplayFieldRows: React.FC<any> = (props) => {
  const { section, sectionIndex, questionnaireData, setQuestionnaireData } =
    props;

  const [isDragActive, setIsDragActive] = React.useState(false);
  const dispatch = useDispatch();

  const getIconByType = (type?: string) => {
    if (!type) return null;

    const Icon = typeOptions?.find((element) => element.value === type)?.icon;
    return <Icon style={{ color: "#0B243C", fontSize: "18px" }} />;
  };

  const getDragFieldRowIndex = (str) => {
    const splited = str.split("-");

    const indexStr = splited[splited.length - 1];
    const indexNb = parseInt(indexStr);

    return indexNb;
  };

  const handleDropField = (droppedItem) => {
    // Ignore drop outside droppable container
    setIsDragActive(false);
    if (!droppedItem.destination) return;

    // Init variables
    const sourceFieldRowIndex = getDragFieldRowIndex(
      droppedItem.source.droppableId
    );
    const destinationFieldRowIndex = getDragFieldRowIndex(
      droppedItem.destination.droppableId
    );
    const sourceFieldIndex = droppedItem.source.index;
    const destinationFieldIndex = droppedItem.destination.index;
    const questionnaireDataTmp = { ...questionnaireData };

    if (
      destinationFieldRowIndex === sourceFieldRowIndex &&
      sourceFieldIndex === destinationFieldIndex
    )
      return;

    // Save source
    const saveSource = {
      ...questionnaireDataTmp?.sections?.[sectionIndex]?.fields?.[
        sourceFieldRowIndex
      ]?.[sourceFieldIndex],
    };
    if (!saveSource) return;
    // Update source
    delete questionnaireDataTmp?.sections?.[sectionIndex]?.fields?.[
      sourceFieldRowIndex
    ]?.[sourceFieldIndex];
    const sourceFieldRowTmp = questionnaireDataTmp?.sections?.[
      sectionIndex
    ]?.fields?.[sourceFieldRowIndex]?.filter((element) => element != undefined);
    questionnaireDataTmp.sections[sectionIndex].fields[sourceFieldRowIndex] =
      sourceFieldRowTmp;
    if (sourceFieldRowTmp?.length === 0) {
      delete questionnaireDataTmp.sections[sectionIndex].fields[
        sourceFieldRowIndex
      ];
      const fieldsTmp = questionnaireDataTmp.sections[
        sectionIndex
      ].fields?.filter((element) => element != undefined);
      questionnaireDataTmp.sections[sectionIndex].fields = fieldsTmp;
    }

    // Update destination
    if (
      questionnaireDataTmp.sections[sectionIndex].fields.length ===
      destinationFieldRowIndex
    ) {
      questionnaireDataTmp.sections[sectionIndex].fields.push([]);
    }
    questionnaireDataTmp.sections[sectionIndex].fields[
      destinationFieldRowIndex
    ].splice(destinationFieldIndex, 0, saveSource);

    // Update questionnaire
    setQuestionnaireData(questionnaireDataTmp);
  };

  return (
    <DragDropContext
      onDragEnd={handleDropField}
      onBeforeDragStart={() => setIsDragActive(true)}
    >
      <Grid container direction="column" spacing="7px" wrap="nowrap">
        {section?.fields?.map((fieldRow, fieldRowIndex) => {
          return (
            <Droppable
              key={`droppable-${fieldRowIndex}`}
              droppableId={`droppable-${fieldRowIndex}`}
              direction="horizontal"
              isCombineEnabled={false}
              type="FieldRowList"
            >
              {(dropProvided) => (
                <Grid
                  item
                  {...dropProvided.droppableProps}
                  key={fieldRowIndex}
                  display="grid"
                >
                  <Grid
                    ref={dropProvided.innerRef}
                    container
                    direction="row"
                    display="flex"
                    spacing="7px"
                  >
                    {fieldRow?.map((field, fieldIndex) => {
                      return (
                        <Draggable
                          key={field?.id}
                          draggableId={field?.id}
                          index={fieldIndex}
                        >
                          {(dragProvided) => (
                            <Grid
                              item
                              key={field?.id}
                              display="grid"
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                            >
                              <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                style={{
                                  border: "1px solid rgba(11, 36, 60)",
                                  backgroundColor: "rgba(247, 252, 252, 1)",
                                  boxShadow:
                                    "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                                }}
                                paddingLeft="5px"
                                paddingRight="5px"
                                borderRadius="10px"
                              >
                                <Grid
                                  item
                                  display="grid"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  {getIconByType(field?.type)}
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="5px"
                                  paddingRight="5px"
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      borderLeft: "1px solid #0B243C",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="2px"
                                  paddingRight="2px"
                                  alignItems="center"
                                  {...dragProvided.dragHandleProps}
                                >
                                  <span
                                    style={{
                                      fontFamily: "Poppins",
                                      color: "#0B243C",
                                      fontSize: "16px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {field?.title &&
                                    field?.type !== "display_text_and_image"
                                      ? resizeText(field?.title, 15)
                                      : getFieldTitleSpecial(field)}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="5px"
                                  paddingRight="5px"
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      borderLeft: "1px solid #0B243C",
                                    }}
                                  />
                                </Grid>
                                <Grid item display="grid" alignItems="center">
                                  <Edit
                                    style={{
                                      color: "#0B243C",
                                      cursor: "pointer",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      const fieldCopyTmp = {
                                        ...questionnaireData.sections[
                                          sectionIndex
                                        ].fields[fieldRowIndex][fieldIndex],
                                      };
                                      dispatch(setFieldCopy(fieldCopyTmp));
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="5px"
                                  paddingRight="5px"
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      borderLeft: "1px solid #0B243C",
                                    }}
                                  />
                                </Grid>
                                <Grid item display="grid" alignItems={"center"}>
                                  <ButtonIconWithTooltip
                                    onClick={() => {
                                      const questionnaireDataTmp = {
                                        ...questionnaireData,
                                      };
                                      const copyField = {...questionnaireDataTmp.sections[
                                        sectionIndex
                                      ].fields[fieldRowIndex][fieldIndex], id: nanoid(8)};

                                      questionnaireDataTmp.sections[sectionIndex].fields.splice(fieldRowIndex + 1, 0, [copyField]);
                                      setQuestionnaireData(
                                        questionnaireDataTmp
                                      );
                                    }}
                                    tooltipText={"Dupliquer"}
                                    Icon={
                                      <ContentCopy
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                          fontSize: "18px",
                                        }}
                                      />
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="5px"
                                  paddingRight="5px"
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      borderLeft: "1px solid #0B243C",
                                    }}
                                  />
                                </Grid>
                                <Grid item display="grid" alignItems="center">
                                  <Delete
                                    style={{
                                      color: "#0B243C",
                                      cursor: "pointer",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      const questionnaireDataTmp = {
                                        ...questionnaireData,
                                      };

                                      delete questionnaireDataTmp.sections[
                                        sectionIndex
                                      ].fields[fieldRowIndex][fieldIndex];
                                      const fieldsRowTmp =
                                        questionnaireDataTmp.sections[
                                          sectionIndex
                                        ].fields[fieldRowIndex].filter(
                                          (element) => element != undefined
                                        );
                                      questionnaireDataTmp.sections[
                                        sectionIndex
                                      ].fields[fieldRowIndex] = fieldsRowTmp;
                                      if (
                                        questionnaireDataTmp.sections[
                                          sectionIndex
                                        ].fields[fieldRowIndex]?.length === 0
                                      ) {
                                        delete questionnaireDataTmp.sections[
                                          sectionIndex
                                        ].fields[fieldRowIndex];
                                        const fieldsTmp =
                                          questionnaireDataTmp.sections[
                                            sectionIndex
                                          ].fields.filter(
                                            (element) => element != undefined
                                          );
                                        questionnaireDataTmp.sections[
                                          sectionIndex
                                        ].fields = fieldsTmp;
                                      }
                                      setQuestionnaireData(
                                        questionnaireDataTmp
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      );
                    })}
                    {!isDragActive && (
                      <Grid item display="grid" justifyContent="flex-start">
                        <AddButton
                          isActive={false}
                          displayText={false}
                          text=""
                          onClick={() => {
                            // Copy data
                            const questionnaireDataTmp = {
                              ...questionnaireData,
                            };

                            // Push new field
                            questionnaireDataTmp.sections[sectionIndex].fields[
                              fieldRowIndex
                            ].push(
                              initField(
                                getConsentementPhrase(
                                  questionnaireData,
                                  undefined
                                )
                                  ? false
                                  : true
                              )
                            );

                            // Update data
                            setQuestionnaireData(questionnaireDataTmp);
                          }}
                        />
                      </Grid>
                    )}
                    {dropProvided.placeholder}
                  </Grid>
                </Grid>
              )}
            </Droppable>
          );
        })}
      </Grid>
    </DragDropContext>
  );
};

export default DisplayFieldRows;
