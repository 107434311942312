import * as React from 'react';
import SquareCheckBoxField from "../CheckBox/SquareCheckBoxField";

interface IAdviceAddToFavorites {
  addToFavorites: boolean,
  onClick: any,
  isPack: boolean,
}

const AdviceAddToFavorites = (props: IAdviceAddToFavorites) => {
  const {
    addToFavorites,
    onClick,
    isPack,
  } = props;

  return (
    <SquareCheckBoxField text={isPack ? 'Ajouter ce pack aux favoris' : 'Ajouter aux favoris'} value={addToFavorites} onClick={onClick} />
  )
};

export default AdviceAddToFavorites;