import FullField from "../FullField";
import * as React from "react";
import MultilineInput from "../../Library/Inputs/MultilineInput";
import { Grid } from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import { ButtonIconWithTooltip } from "../../Questionnaire/PDF/CreateOrEditPDFQuestionnaire";
import {
  InsertEmoticonOutlined,
  InsertEmoticonRounded,
} from "@mui/icons-material";
import { useOnOutsideClick } from "../../NavbarComponent";

interface IAdviceContentField {
  adviceContent: string;
  index?: number;
  onChange: any;
  itemBorder?: string;
}

const AdviceContentField = (props: IAdviceContentField) => {
  const { adviceContent, index, onChange, itemBorder } = props;
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const emojiPickerRef = React.useRef(null);
  const [lastPosition, setLastPosition] = React.useState(0);

  useOnOutsideClick(emojiPickerRef, () => {
    setOpenEmoji(false);
  });

  return (
    <FullField
      title="Contenu"
      isMandatory={true}
      remainingCharacters={true}
      contentLength={adviceContent?.length}
    >
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap="5px"
        paddingBottom="5px"
      >
        <Grid item display="grid">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <span
                style={{
                  color: "#0B243C",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  letterSpacing: "0px",
                  fontSize: "14px",
                }}
              >
                Vous pouvez utiliser les variables ci-dessous :
              </span>
            </Grid>
            <Grid item display="grid" paddingBottom="5px">
              <span
                style={{
                  color: "#0B243C",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  letterSpacing: "0px",
                  fontSize: "14px",
                }}
              >
                {
                  "{mon_numero} - {mon_nom} - {mon_prenom} - {mon_email} - {date_declenchement} - {heure_declenchement} - {jour_declenchement} - {nom_patient} - {prenom_patient}"
                }
              </span>
            </Grid>
          </Grid>
        </Grid>
        {adviceContent?.length < 324 && (
          <Grid
            item
            display="grid"
            justifySelf={"flex-end"}
            alignSelf="flex-end"
          >
            <div ref={emojiPickerRef} style={{ position: "relative" }}>
              <ButtonIconWithTooltip
                Icon={
                  openEmoji ? (
                    <InsertEmoticonRounded
                      style={{
                        color: "#0B243C",
                      }}
                    />
                  ) : (
                    <InsertEmoticonOutlined
                      style={{
                        color: "#0B243C",
                      }}
                    />
                  )
                }
                tooltipText="Ajouter une émoticône"
                onClick={() => {
                  if (!openEmoji) {
                    const textarea: any = document?.querySelector(
                      `#advice-content-input-${index}`
                    );
                    const position = textarea?.selectionStart;
                    textarea?.focus();
                    setLastPosition(position);
                  }
                  setOpenEmoji((x) => !x);
                }}
              />
              {openEmoji && (
                <div
                  style={{
                    position: "absolute",
                    top: 30,
                    right: 0,
                    zIndex: 10000,
                  }}
                >
                  <EmojiPicker
                    searchDisabled={true}
                    skinTonesDisabled={true}
                    categories={
                      [
                        {
                          category: "suggested",
                          name: "RÉCENTS",
                        } as any,
                        {
                          category: "smileys_people",
                          name: "ÉMÔTICONES ET ÉMOTIONS",
                        } as any,
                        {
                          category: "activities",
                          name: "ACTIVITÉS ET ÉVÉNEMENTS",
                        } as any,
                        {
                          category: "activities",
                          name: "ANIMAUX ET NATURE",
                        } as any,
                        {
                          category: "travel_places",
                          name: "VOYAGES ET LIEUX",
                        } as any,
                        {
                          category: "food_drink",
                          name: "ALIMENTATION ET BOISSONS",
                        } as any,
                        {
                          category: "objects",
                          name: "OBJETS",
                        } as any,
                        {
                          category: "symbols",
                          name: "SYMBOLES",
                        } as any,
                        {
                          category: "flags",
                          name: "DRAPEAUX",
                        } as any,
                      ] as any
                    }
                    onEmojiClick={(event) => {
                      if (!adviceContent) {
                        onChange(event.emoji);
                      } else {
                        onChange(
                          adviceContent.slice(0, lastPosition) +
                            event.emoji +
                            adviceContent.slice(lastPosition)
                        );
                      }
                      setOpenEmoji(false);
                    }}
                  />
                </div>
              )}
            </div>
          </Grid>
        )}
      </Grid>
      <MultilineInput
        border={itemBorder}
        className="border-animation"
        id={`advice-content-input-${index}`}
        value={adviceContent}
        onChange={(e) => onChange(e)}
        onFocus={() => setOpenEmoji(false)}
      />
    </FullField>
  );
};

export default AdviceContentField;
