import { Grid } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import OrangeCheckBox from "../../Fields/CheckBox/OrangeCheckBox";
import {
  selectImportPatient,
  selectAllImportPatient,
} from "../../../redux/ImportPatientReducer";

interface PatientInfos {
  phone: string;
  last_name: string;
  first_name: string;
  ins?: string;
  email?: string;
  isValidated?: boolean;
  error?: string;
}

interface IImportPatientTableProps {
  data: Array<PatientInfos>;
  error: boolean;
  isUpdate?: boolean;
}

const ImportPatientTable = (props: IImportPatientTableProps) => {
  const { data, error, isUpdate } = props;
  const [actualDisplayNumber, setActualDisplayNumber] = React.useState(5);
  const [numberSelected, setNumberSelected] = React.useState(data.length);
  const [allSelected, setAllSelected] = React.useState(true);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (numberSelected !== data.length) setAllSelected(false);
    else setAllSelected(true);
  }, [numberSelected]);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
    >
      <Grid
        item
        maxHeight="200px"
        overflow="auto"
        className="table-grid-import"
        style={{
          gridTemplateColumns: error
            ? "auto 1fr 1fr 1fr"
            : (isUpdate ? '1fr 1fr 1fr' : "10fr 10fr 10fr 2fr"),
        }}
      >
        {error && (
          <span
            className="table-grid-title"
            style={{
              borderRight: "1px solid #657273",
              paddingRight: error ? "25px" : null,
            }}
          >
            Erreur
          </span>
        )}
        <span
          className="table-grid-title"
          style={{ paddingLeft: error ? "25px" : null }}
        >
          Nom
        </span>
        <span className="table-grid-title">Prénom</span>
        <span className="table-grid-title">Tel</span>
        {!error && !isUpdate && (
          <span className="table-grid-title">
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="flex-end"
            >
              <Grid item display="grid">
                <OrangeCheckBox
                  value={allSelected}
                  onClick={() => {
                    dispatch(selectAllImportPatient(allSelected));
                    if (allSelected) setNumberSelected(0);
                    else setNumberSelected(data.length);
                    setAllSelected(!allSelected);
                  }}
                  text=""
                />
              </Grid>
            </Grid>
          </span>
        )}
        {data
          .filter((e, index) => index < actualDisplayNumber)
          .map((element, index) => {
            return (
              <>
                {error && (
                  <span
                    className="table-grid-text"
                    style={{
                      borderRight: "1px solid #657273",
                      paddingRight: error ? "25px" : null,
                    }}
                  >
                    {element?.error}
                  </span>
                )}
                <span
                  className="table-grid-text"
                  style={{ paddingLeft: error ? "25px" : null }}
                >
                  {element.last_name}
                </span>
                <span className="table-grid-text">{element.first_name}</span>
                <span className="table-grid-text">{element.phone}</span>
                {!error && !isUpdate && (
                  <span className="table-grid-text">
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      justifyContent="flex-end"
                    >
                      <Grid item display="grid">
                        <OrangeCheckBox
                          value={element?.isValidated}
                          onClick={() => {
                            const increase = numberSelected + 1;
                            const decrease = numberSelected - 1;

                            if (element.isValidated)
                              setNumberSelected(decrease);
                            else setNumberSelected(increase);
                            dispatch(selectImportPatient(index));
                          }}
                          text=""
                        />
                      </Grid>
                    </Grid>
                  </span>
                )}
              </>
            );
          })}
      </Grid>
      {(actualDisplayNumber > 5 || actualDisplayNumber < data.length) && (
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            justifyContent="center"
            paddingTop="45px"
            gap="15px"
          >
            {actualDisplayNumber > 5 && (
              <Grid item display="grid">
                <button
                  className="more-button"
                  onClick={() => setActualDisplayNumber((d) => d - 5)}
                >
                  Afficher moins
                </button>
              </Grid>
            )}
            {actualDisplayNumber < data.length && (
              <Grid item display="grid">
                <button
                  className="more-button"
                  onClick={() => setActualDisplayNumber((d) => d + 5)}
                >
                  Afficher plus ({data.length - actualDisplayNumber} restants)
                </button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ImportPatientTable;
