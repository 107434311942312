import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../styles/ResetPassword.scss";
import InformationPopup from "./Library/Popups/InformationPopup";
import { GenericFromPopup, GenericSendButton } from "../pages/ChatbotLexPage";
import { Grid } from "@mui/material";
import { GenericTextInput, useOpenSnackbar } from "../pages/CreateOrEditChatbotLexPage";
import { emailValidator } from "./Questionnaire/SendResponsePopup";
import makeAPIRequest from "../utils/makeAPIRequest";

interface IProps {
  isShowing: any;
  hide: any;
}

const ResetPassword = ({ isShowing, hide }: IProps) => {
  const [email, setEmail] = useState<string>("");
  const [successPopup, setSuccessPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const openSnackbarUse = useOpenSnackbar();

  const resetAll = () => {
    setEmail("");
    setSuccessPopup(false);
    setIsLoading(false);
  };

  const handleSumbit = () => {
    setIsLoading(true);
    makeAPIRequest(
      "post",
      "/user/ask_for_reset_password",
      {
        email: email,
      },
      "v3"
    )
      .then((res) => {
        if (isShowing) { hide(); }
        setSuccessPopup(true);
      })
      .catch((err) => {
        openSnackbarUse.error("Impossible de réinitialiser le mot de passe.", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    /*
    fetch(
      `${process.env.REACT_APP_API_URL}/user/reset_pwd?email=${email}`
    )
      .then((res: any) => res.json())
      .then((res) => {
        if (res?.success && res?.data?.reset_pwd === "done") {
          if (isShowing) hide();
          setSuccessPopup(true);
        }
      });
      */
  };

  if (successPopup) {
    return (
      <InformationPopup
        firstTitle={`Réinitialiser`}
        secondTitle="mon mot de passe"
        message={`Vous allez recevoir un mail d'ici quelques minutes si votre compte existe.`}
        confirmButtonText="OK"
        onConfirm={() => {
          resetAll();
        }}
      />
    );
  } else if (isShowing && !successPopup) {
    return (
      <GenericFromPopup
        onClose={() => {
          hide();
          resetAll();
        }}
        title={{
          leftPart: "Réinitialiser",
          rightPart: "mon mot de passe",
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap="10px"
          maxWidth="600px"
          justifySelf={"center"}
        >
          <Grid item display="grid">
            <GenericTextInput
              fieldTitleInPlaceholderOnly={false}
              fieldTitle="Adresse e-mail"
              isMandatory={true}
              id="input-email-reset"
              value={email}
              onChange={(e) => {
                setEmail(e?.target?.value ? e.target.value : "");
              }}
            />
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                emptyEmail: "L'adresse e-mail n'est pas renseigné.",
                badEmail: "L'adresse e-mail est incorrect.",
              }}
              errorSelector={{
                emptyEmail: "#input-email-reset",
                badEmail: "#input-email-reset",
              }}
              invalidConditions={{
                emptyEmail: !email,
                badEmail: !emailValidator(email),
              }}
              onSendClick={() => {
                handleSumbit();
              }}
              isLoading={isLoading}
              disablePaddingTop={false}
              textButton="Réinitialiser"
            />
          </Grid>
        </Grid>
      </GenericFromPopup>
    );
  } else {
    return <></>;
  }
};

export default ResetPassword;
