import * as React from "react";
import { SketchPicker } from "react-color";
import Creatable from "react-select/creatable";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import {
  ChevronLeftOutlined,
  ClearRounded,
  Delete,
  Edit,
  EditRounded,
  SquareRounded,
} from "@mui/icons-material";
import { useHistory, useParams } from "react-router";
import FullPage from "../../../pages/FullPage";
import BoxTitle from "../../Library/Box/BoxTitle";
import { MySimpleCreateButton } from "../../TutorialsList";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../../pages/ChatbotLexPage";
import { IField, IForm } from "../../../types/FormTypes";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import {
  GenericLongText,
  GenericTextInput,
  useOpenSnackbar,
} from "../../../pages/CreateOrEditChatbotLexPage";
import { GenericSelect, GraphItem } from "../../../pages/QuestionnairePage";
import typeOptionsPDF from "./typeOptionsPDF";
import { components } from "react-select";
import MyEditor from "../../Library/Inputs/MyEditor";
import FullField from "../../Fields/FullField";
import { nanoid } from "nanoid";
import CancellablePopup from "../../Library/Popups/CancellablePopup";
import AddButton from "../../Library/Buttons/AddButton/AddButton";
import QuestionnaireUtils from "../QuestionnaireUtils";
import useAppDispatch from "../../../redux/useAppDispatch";
import { getQuestionnaires } from "../../../redux/new/newQuestionnaireReducer";
import useAppSelector from "../../../redux/useAppSelector";
import {
  getFieldByFieldId,
  getFieldTitleByFieldId,
  translateCondType,
} from "../EditQuestionnaire/Fields/DisplayConfirmMessage";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import OrangeCheckBox from "../../Fields/CheckBox/OrangeCheckBox";
const { Option } = components;
import { getFieldsLabelValue } from "../EditQuestionnaire/QuestionnairePopupEdit/Fields/DisplayAdditionAuto";
import { genericGetFromTimestamp } from "../../AdminGroups/CreateGroupPopup";
import MultilineInput from "../../Library/Inputs/MultilineInput";
import TextInput from "../../Library/Inputs/TextInput";
import { emailValidator } from "../SendResponsePopup";
import { GenericDateInput } from "../GenerateQuestionnaire/Fields/DisplayDate";
import isPositiveNumber from "../../../utils/isPositiveNumber";
import resizeText from "../../../utils/resizeText";

const PDFVariables = [
  "titre_questionnaire",
  "nombre_reponses",
  "date_premiere_reponse",
  "heure_premiere_reponse",
  "date_creation_questionnaire",
  "heure_creation_questionnaire",
  "date_generation_pdf",
  "heure_generation_pdf",
  "nombre_flash_qr_code",
  "date_séléctionnée_début",
  "heure_séléctionnée_début",
  "date_séléctionnée_fin",
  "heure_séléctionnée_fin",
];

const hourOptions = [
  {
    value: 9,
    label: "9h",
  },
  {
    value: 17,
    label: "17h",
  },
];

export const weekDayOptions = [
  {
    value: "monday",
    label: "Lundi",
  },
  {
    value: "tuesday",
    label: "Mardi",
  },
  {
    value: "wednesday",
    label: "Mercredi",
  },
  {
    value: "thursday",
    label: "Jeudi",
  },
  {
    value: "friday",
    label: "Vendredi",
  },
  {
    value: "saturday",
    label: "Samedi",
  },
  {
    value: "sunday",
    label: "Dimanche",
  },
];

export function getEndTimestampFromYYYYMMDD(dateStr: string): number {
  const dateObj = new Date(dateStr);

  dateObj.setHours(23, 59, 59, 999);

  const timestamp = dateObj.getTime();

  return timestamp;
}

export function getStartTimestampFromYYYYMMDD(dateStr: string): number {
  const dateObj = new Date(dateStr);

  dateObj.setHours(0, 0, 0, 0);

  const timestamp = dateObj.getTime();

  return timestamp;
}

const typeOptionsCell = [
  {
    label: "Condition",
    value: "condition",
  },
  {
    label: "Texte",
    value: "text",
  },
];

export const ButtonIconWithTooltip: React.FC<{
  Icon: JSX.Element;
  onClick: () => void;
  tooltipText: string;
}> = (props) => {
  return (
    <Tooltip
      title={props.tooltipText}
      style={{
        height: "fit-content",
        width: "fit-content",
      }}
    >
      <IconButton
        style={{
          margin: 0,
          padding: 0,
        }}
        onClick={props.onClick}
      >
        {props.Icon}
      </IconButton>
    </Tooltip>
  );
};

const NB_ROWS_AND_COLS_IN_TABLE = 20;

const RowsColumnsOptions = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "11",
    value: 11,
  },
  {
    label: "12",
    value: 12,
  },
  {
    label: "13",
    value: 13,
  },
  {
    label: "14",
    value: 14,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "16",
    value: 16,
  },
  {
    label: "17",
    value: 17,
  },
  {
    label: "18",
    value: 18,
  },
  {
    label: "19",
    value: 19,
  },
  {
    label: "20",
    value: 20,
  },
];

interface ConditionPDF {
  value: string;
  type: "=" | ">" | "<" | "<=" | ">=" | "!=" | "contains";
  fieldId: string;
}

interface StackedBar {
  formId?: string;
  fieldId?: string;
  id?: string;
  entitled?: string;
}

interface SecteurGraphique {
  legend?: string;
  color?: string;
  conditionType: "or" | "and";
  conditions: ConditionPDF[];
  id?: string;
}

interface CellData {
  id?: string;
  type: "text" | "condition";
  textContent?: string;
  formId?: string;
  toDisplay?: string;
  /* Conditions */
  conditionType: "or" | "and";
  conditions: ConditionPDF[];
  /* Date */
  onlyResponseInPeriod?: boolean;
  startDate?: string;
  endDate?: string;
  /* Percent */
  displayPercent?: boolean;
  roundPercent?: boolean;
}

interface VariableData {
  id?: string;
  name?: string;
  /* Conditions */
  conditionType: "or" | "and";
  conditions: ConditionPDF[];
  /* Percent */
  displayPercent?: boolean;
  roundPercent?: boolean;
  /* Date */
  onlyResponseInPeriod?: boolean;
  startDate?: string;
  endDate?: string;
}

interface DataSet {
  label: string; // Titre de la barre
  data: number[]; // Nombre de réponse pour chaque réponse possible (voir labels)
  backgroundColor: string; // Couleur
}

interface StackedBarItems {
  labels: string[]; // Liste de réponses possibles
  datasets: DataSet[]; // Liste des datasets
}

export interface StatsOfSendForm {
  id: string;
  formId: string;
  options: {
    scheduleCount: {
      color: string;
      legend: string;
      isActive: boolean;
    };
    responsesCount: {
      color: string;
      legend: string;
      isActive: boolean;
    };
    relancesCount: {
      color: string;
      legend: string;
      isActive: boolean;
    };
    noResponseAfterAllRelanceCount: {
      color: string;
      legend: string;
      isActive: boolean;
    };
    responseAfterRelanceCount: {
      color: string;
      legend: string;
      isActive: boolean;
    }
  };
}

export type OptionsStatsOfSendForm = keyof StatsOfSendForm["options"];

export interface StatsOfSend {
  list: StatsOfSendForm[];
}

export interface HorizontalBarInfos {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }[];
}

export interface PDFElement {
  _id?: string;
  formId?: string;
  type?:
  | "display_text_and_image"
  | "page_break"
  | "circular_diagram"
  | "saisis_par_delegue"
  | "array"
  | "variable"
  | "stacked_bar_chart"
  | "statistique_envoi";
  statsOfSend?: StatsOfSend;
  textAndImageContent?: string;
  variable?: VariableData;
  graphTitle?: string;
  horizontalBarInfos?: HorizontalBarInfos;
  graphItems?: GraphItem[];
  stackedBarItems?: StackedBarItems;
  secteurs?: SecteurGraphique[];
  stackedBars?: StackedBar[];
  nbArrayRows?: number;
  nbArrayColumns?: number;
  arrayData?: CellData[][];
  /* Date */
  onlyResponseInPeriod?: boolean;
  startDate?: string;
  endDate?: string;
}

export const conditionTypeOptions = [
  {
    label: "Toutes les conditions sont vraies",
    value: "and",
  },
  {
    label: "Aucune condition n'est vraie",
    value: "zero",
  },
  {
    label: "Au moins une condition est vraie",
    value: "or",
  },
  {
    label: "Au moins 2 conditions sont vraies",
    value: "more_or_two",
  },
  {
    label: "Moins de 2 conditions sont vraies",
    value: "less_than_two",
  },
  {
    label: "Au moins 3 conditions sont vraies",
    value: "more_or_three",
  },
  {
    label: "Moins de 3 conditions sont vraies",
    value: "less_than_three",
  },
  {
    label: "Au moins 4 conditions sont vraies",
    value: "more_or_four",
  },
  {
    label: "Moins de 4 conditions sont vraies",
    value: "less_than_four",
  },
  {
    label: "Au moins 5 conditions sont vraies",
    value: "more_or_five",
  },
  {
    label: "Moins de 5 conditions sont vraies",
    value: "less_than_five",
  },
  {
    label: "Au moins 6 conditions sont vraies",
    value: "more_or_six",
  },
  {
    label: "Moins de 6 conditions sont vraies",
    value: "less_than_six",
  },
  {
    label: "Au moins 7 conditions sont vraies",
    value: "more_or_seven",
  },
  {
    label: "Moins de 7 conditions sont vraies",
    value: "less_than_seven",
  },
  {
    label: "Au moins 8 conditions sont vraies",
    value: "more_or_eight",
  },
  {
    label: "Moins de 8 conditions sont vraies",
    value: "less_than_eight",
  },
  {
    label: "Au moins 9 conditions sont vraies",
    value: "more_or_nine",
  },
  {
    label: "Moins de 9 conditions sont vraies",
    value: "less_than_nine",
  },
  {
    label: "Au moins 10 conditions sont vraies",
    value: "more_or_ten",
  },
  {
    label: "Moins de 10 conditions sont vraies",
    value: "less_than_ten",
  },
];

export interface PDFQuestionnaire {
  _id?: string;
  title?: string;
  elements: PDFElement[];
  user_id?: string;
  creation_date?: number;
  update_date?: number;
  mailHebdoSend?: {
    isActive?: boolean;
    weekDay?: string;
    hour?: number;
    destinataires?: string[];
    subject?: string;
    content?: string;
  };
}

const initStackedBar = (): StackedBar => {
  return {
    entitled: undefined,
    fieldId: undefined,
    formId: undefined,
    id: nanoid(),
  };
};

const initStatsOfSendForm = (): StatsOfSendForm => {
  return {
    id: nanoid(),
    formId: undefined,
    options: {
      noResponseAfterAllRelanceCount: {
        color: "#D0021B",
        isActive: false,
        legend: "Total sans réponse suite aux relances",
      },
      responseAfterRelanceCount: {
        color: "#fea51e",
        isActive: false,
        legend: "Total de réponses suite aux relances",
      },
      relancesCount: {
        color: "#4A90E2",
        isActive: false,
        legend: "Total de relances",
      },
      responsesCount: {
        color: "#7ED321",
        isActive: false,
        legend: "Total de réponses",
      },
      scheduleCount: {
        color: "#9013FE",
        isActive: false,
        legend: "Total de programmations",
      },
    },
  };
};

const initSector = (): SecteurGraphique => {
  return {
    conditions: [],
    conditionType: "and",
    color:
      "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
    id: nanoid(),
    legend: "",
  };
};

const initCell = (): CellData => {
  return {
    conditions: [],
    conditionType: "and",
    type: "text",
    displayPercent: false,
    roundPercent: false,
    id: nanoid(),
    textContent: "",
    formId: undefined,
  };
};

const initArrayRow = (): CellData[] => {
  const row: CellData[] = [];
  for (let i = 0; i < NB_ROWS_AND_COLS_IN_TABLE; i++) {
    row.push(initCell());
  }
  return row;
};

const fixCellsInTable = (cells: CellData[][]): CellData[][] => {
  const cellsCopy = [...cells];

  for (let i = 0; i < cellsCopy.length; i++) {
    while (cellsCopy[i].length < NB_ROWS_AND_COLS_IN_TABLE) {
      cellsCopy[i].push(initCell());
    }
  }
  while (cellsCopy.length < NB_ROWS_AND_COLS_IN_TABLE) {
    cellsCopy.push(initArrayRow());
  }
  return cellsCopy;
};

const initArrayData = (): CellData[][] => {
  const rows: CellData[][] = [];
  for (let i = 0; i < NB_ROWS_AND_COLS_IN_TABLE; i++) {
    rows.push(initArrayRow());
  }
  return rows;
};

const addSansTitre = (title: string) => {
  if (!title) {
    return "Sans titre";
  }
  return title;
};

export const MyConditionsHandler: React.FC<{
  questionnaireData?: Partial<IForm>;
  updateConditionType: (value: any) => void;
  deleteConditionByIndex: (index: number) => void;
  addCondition: (condition: ConditionPDF) => void;
  conditionType?: string;
  conditions?: ConditionPDF[];
}> = (props) => {
  const fieldOptions = props.questionnaireData
    ? QuestionnaireUtils.getFieldListOptions(props.questionnaireData)
    : undefined;
  console.log(fieldOptions);
  const [fieldSelected, setFieldSelected] = React.useState(null);
  const [valueSelectInput, setValueSelectInput] = React.useState(null);
  const [valueInput, setValueInput] = React.useState("");
  const fieldSelectedInfos: IField = props.questionnaireData
    ? QuestionnaireUtils.getFieldSelectedInfos(
      props.questionnaireData,
      fieldSelected
    )
    : undefined;
  const [typeCondition, setTypeCondition] = React.useState({
    value: "=",
    label: "Égal à",
  });
  const canGetValueList =
    (fieldSelectedInfos?.option?.length > 0 ||
      fieldSelectedInfos?.type === "protocole") &&
    !["echelle_notation", "addition_auto"].includes(fieldSelectedInfos?.type);
  const isBirthDate =
    fieldSelectedInfos?.attributPatientType === "birth_date" &&
    fieldSelectedInfos?.type === "attribut_patient";
  const snackbarUse = useOpenSnackbar();

  const getValueSelectInput = () => {
    if (!valueSelectInput) {
      return null;
    }
    if (fieldSelectedInfos?.type === "protocole") {
      const founded = fieldSelectedInfos?.protocoleInfos?.protocolesList?.find(
        (x) => x?.id == valueSelectInput
      );

      if (founded) {
        return {
          value: founded?.id,
          label: founded?.nature,
        };
      }
    }
    return {
      value: valueSelectInput,
      label: valueSelectInput,
    };
  };

  const getFieldSelectedOptions = () => {
    if (fieldSelectedInfos?.type === "protocole") {
      if (!fieldSelectedInfos?.protocoleInfos?.protocolesList) {
        return undefined;
      }
      return fieldSelectedInfos?.protocoleInfos?.protocolesList
        ?.filter((x) => x?.nature)
        ?.map((protocole) => {
          return {
            label: protocole?.nature,
            value: protocole?.nature,
          };
        });
    }
    return fieldSelectedInfos?.option?.map((element) => {
      return { label: element, value: element };
    });
  };

  const getValueFromConditionValue = (fieldId: string, fieldValue: string) => {
    const field: IField = props.questionnaireData
      ? QuestionnaireUtils.getFieldSelectedInfos(
        props.questionnaireData,
        fieldId
      )
      : undefined;

    if (!field || !["protocole"].includes(field?.type)) {
      return fieldValue;
    }
    /*
    if (field?.type === "protocole") {
      if (!field?.protocoleInfos?.protocolesList) {
        return fieldValue;
      }
      const founded = field?.protocoleInfos?.protocolesList?.find(
        (x) => x?.id == fieldValue
      );
      if (founded?.nature) {
        return founded.nature;
      }
    }
    */

    return fieldValue;
  };

  return (
    <Grid container direction="column" wrap="nowrap" gap="10px">
      <Grid item display="grid">
        <GenericSelect
          fieldTitle="Conditions"
          isMandatory={true}
          placeholder=""
          id="field-or-and"
          isClearable={false}
          maxMenuHeight={"120px"}
          options={conditionTypeOptions}
          onChange={(e) => {
            props.updateConditionType(e?.value);
          }}
          value={conditionTypeOptions.find(
            (x) => x.value == props.conditionType
          )}
        />
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems={"center"}
          spacing="10px"
          paddingBottom="10px"
        >
          <Grid item display="grid" minWidth="300px">
            <GenericSelect
              fieldTitle=""
              placeholder="Champ"
              maxMenuHeight="200px"
              id="condition-field"
              isClearable={false}
              options={fieldOptions}
              onChange={(e) => {
                setValueInput("");
                setValueSelectInput(null);
                setFieldSelected(e?.value);
                console.log(e?.value);
              }}
              value={
                !fieldSelected
                  ? null
                  : QuestionnaireUtils.getOptionFromValue(
                    fieldSelected,
                    fieldOptions
                  )
              }
            />
          </Grid>
          <Grid item display="grid" minWidth="200px">
            <GenericSelect
              fieldTitle=""
              id="list-cond-options"
              placeholder=""
              value={typeCondition}
              maxMenuHeight="120px"
              isClearable={false}
              onChange={(e) => {
                setTypeCondition(e);
              }}
              options={[
                {
                  value: "=",
                  label: "Égal à",
                },
                {
                  value: "!=",
                  label: "Différent de",
                },
                {
                  value: ">",
                  label: "Supérieur à",
                },
                {
                  value: ">=",
                  label: "Supérieur ou égal à",
                },
                {
                  value: "<=",
                  label: "Inférieur ou égal à",
                },
                {
                  value: "<",
                  label: "Inférieur à",
                },
                {
                  value: "contains",
                  label: "Contient",
                },
              ]}
            />
          </Grid>
          <Grid item display="grid" minWidth="200px">
            {canGetValueList ? (
              <GenericSelect
                fieldTitle=""
                id="questionnaire-condition-value-select"
                maxMenuHeight="120px"
                placeholder="Valeur du champ"
                isClearable={false}
                value={getValueSelectInput()}
                onChange={(e) => setValueSelectInput(e?.value)}
                options={[
                  {
                    label: "Non renseigné / Vide",
                    value: "Non renseigné / Vide",
                  },
                  ...getFieldSelectedOptions(),
                ]}
              />
            ) : isBirthDate ? (
              <div style={{ marginTop: "-5px" }}>
                <GenericTextInput
                  id="questionnaire-condition-value"
                  fieldTitle=""
                  placeholder="Âge (en années)"
                  value={valueInput}
                  onChange={(e) => {
                    if (
                      e?.target?.value?.length > 0 &&
                      !isPositiveNumber(e.target.value)
                    ) {
                      return;
                    }
                    setValueInput(e.target.value);
                  }}
                />
              </div>
            ) : (
              <div style={{ marginTop: "-5px" }}>
                <GenericTextInput
                  id="questionnaire-condition-value"
                  fieldTitle=""
                  placeholder="Valeur du champ"
                  value={valueInput}
                  onChange={(e) => setValueInput(e.target.value)}
                />
              </div>
            )}
          </Grid>
          <Grid item display="grid">
            <AddButton
              onClick={() => {
                const newCond: ConditionPDF = {
                  fieldId: fieldSelected,
                  type: typeCondition?.value
                    ? (typeCondition.value as any)
                    : "=",
                  value: canGetValueList ? valueSelectInput : valueInput,
                };
                if (!newCond?.fieldId) {
                  snackbarUse.error("Vous devez sélectionner un champ");
                  return;
                }
                if (!newCond?.type) {
                  snackbarUse.error(
                    "Vous devez renseigner le type de condition"
                  );
                  return;
                }
                if (!newCond?.value) {
                  snackbarUse.error("Vous devez renseigner la valeur du champ");
                  return;
                }
                props.addCondition(newCond);
              }}
              addId={"add-cond-button"}
              displayText={true}
              text="Ajouter"
            />
          </Grid>
          {props.conditions && props.conditions.length > 0 && (
            <Grid item display="grid" marginTop="10px">
              <div
                className="table-grid-questionnaire-message-condition"
                style={{ overflow: "auto" }}
              >
                <span className="table-grid-title">Champ</span>
                <span className="table-grid-title">Type</span>
                <span className="table-grid-title">Valeur</span>
                <span className="table-grid-title"></span>
                {props.conditions.map((condition, index) => {
                  return (
                    <>
                      <span
                        className="table-grid-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {addSansTitre(
                          getFieldTitleByFieldId(
                            props.questionnaireData,
                            condition.fieldId
                          )
                        )}
                      </span>
                      <span
                        className="table-grid-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {translateCondType(condition.type)}
                      </span>
                      <span
                        className="table-grid-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {getValueFromConditionValue(
                          condition.fieldId,
                          condition.value
                        )}
                      </span>
                      <Grid item display="grid" className="table-grid-text">
                        <Tooltip title="Supprimer">
                          <IconButton
                            onClick={() => {
                              props.deleteConditionByIndex(index);
                            }}
                            style={{
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            <ClearRounded
                              fontSize="small"
                              style={{ color: "#FF3100" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </>
                  );
                })}
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const StackedBarCreateOrEdit: React.FC<{
  stackedBar?: StackedBar;
  isEdit?: boolean;
  onSubmit: (newStackedBar: StackedBar) => void;
  onClose?: () => void;
}> = (props) => {
  const forms = useAppSelector((x) => x?.newQuestionnaireReducer?.list);
  const [stackedBar, setStackedBar] = React.useState<StackedBar>(undefined);

  React.useEffect(() => {
    if (props.isEdit) {
      setStackedBar(props.stackedBar);
    } else {
      setStackedBar(initStackedBar());
    }
  }, [props.isEdit, props.stackedBar]);

  const selectedForm =
    forms && stackedBar?.formId
      ? forms.find((x) => x?.id === stackedBar.formId)
      : undefined;
  const fieldOptions = selectedForm
    ? QuestionnaireUtils.getFieldListOptions(selectedForm)
    : undefined;
  const fieldSelectedInfos: IField =
    fieldOptions && stackedBar?.fieldId
      ? QuestionnaireUtils.getFieldSelectedInfos(
        selectedForm,
        stackedBar?.fieldId
      )
      : undefined;
  const fieldsLabelValue = selectedForm
    ? getFieldsLabelValue(selectedForm)
    : undefined;

  if (!stackedBar) {
    return <></>;
  }
  return (
    <GenericFromPopup
      addCloseSecurity={true}
      title={{
        leftPart: props.isEdit ? "Modifier" : "Créer",
        rightPart: "ma barre empilée",
      }}
      onClose={props.onClose}
    >
      {stackedBar && (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="20px"
        >
          <Grid item display="grid">
            {/* Choix du questionnaire */}
            <GenericSelect
              fieldTitle="Choix du questionnaire"
              maxMenuHeight="150px"
              isMandatory={true}
              value={
                stackedBar?.formId && selectedForm
                  ? (() => {
                    return {
                      value: selectedForm?.id,
                      label: selectedForm?.title,
                    };
                  })()
                  : undefined
              }
              id="stacked-bar-form"
              placeholder=""
              isClearable={false}
              options={forms
                ?.filter((x) => x?.title && x?.id)
                ?.map((x) => {
                  return {
                    label: x?.title,
                    value: x?.id,
                  };
                })}
              onChange={(e) => {
                setStackedBar((x) => {
                  return {
                    ...x,
                    formId: e?.value,
                    fieldId: undefined,
                    entitled: undefined,
                  };
                });
              }}
            />
          </Grid>
          {stackedBar?.formId && (
            <Grid item display="grid">
              {/* Choix du champ */}
              <GenericSelect
                fieldTitle="Choix du champ"
                placeholder=""
                maxMenuHeight="150px"
                id="stacked-bar-field"
                isMandatory={true}
                isClearable={false}
                options={fieldOptions}
                onChange={(e) => {
                  setStackedBar((x) => {
                    return { ...x, fieldId: e?.value, entitled: undefined };
                  });
                }}
                value={
                  !stackedBar?.fieldId || !fieldsLabelValue
                    ? null
                    : fieldsLabelValue.find(
                      (x) => x?.value == stackedBar?.fieldId
                    )
                }
              />
            </Grid>
          )}
          {stackedBar?.fieldId && (
            <Grid item display="grid">
              {/* Choix de l'intitulé */}
              <GenericTextInput
                fieldTitle="Intitulé"
                isMandatory={false}
                id="stacked-bar-entitled"
                placeholder={fieldSelectedInfos?.title}
                value={stackedBar?.entitled}
                onChange={(e) => {
                  setStackedBar((x) => {
                    return { ...x, entitled: e.target.value };
                  });
                }}
              />
            </Grid>
          )}
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noForm: "Aucun questionnaire n'a été selectionné.",
                noField: "Aucun champ n'a été selectionné.",
              }}
              errorSelector={{
                noForm: "#stacked-bar-form",
                noField: "#stacked-bar-field",
              }}
              invalidConditions={{
                noForm: !stackedBar.formId,
                noField: !stackedBar?.fieldId,
              }}
              onSendClick={() => {
                props.onSubmit(stackedBar);
              }}
              isLoading={false}
              textButton={props.isEdit ? "Modifier" : "Créer"}
            />
          </Grid>
        </Grid>
      )}
    </GenericFromPopup>
  );
};

const HandleOpt: React.FC<{
  titleCheckBox: string;
  option: OptionsStatsOfSendForm;
  statsOfSendForm: StatsOfSendForm;
  setStatsOfSendForm: React.Dispatch<React.SetStateAction<StatsOfSendForm>>;
}> = (props) => {
  const { option, setStatsOfSendForm, statsOfSendForm } = props;
  const [displayColorPopup, setDisplayColorPopup] =
    React.useState<boolean>(false);
  const refPopupColor = useOutsideClick(() => {
    setDisplayColorPopup(false);
  });

  return (
    <Grid container direction="column" wrap="nowrap" gap="10px">
      <Grid item display="grid">
        <OrangeCheckBox
          text={props.titleCheckBox}
          onClick={() => {
            setStatsOfSendForm((x) => {
              return {
                ...x,
                options: {
                  ...x.options,
                  [option]: {
                    ...x.options[option],
                    isActive: x?.options?.[option]?.isActive ? false : true,
                  },
                },
              };
            });
          }}
          value={statsOfSendForm?.options?.[option]?.isActive ? true : false}
        />
      </Grid>
      {statsOfSendForm?.options?.[option]?.isActive && (
        <Grid item display="grid">
          <Grid container direction="row" wrap="nowrap" gap="20px">
            <Grid item display="grid">
              <FullField title="Couleur" isMandatory={false}>
                <div style={{ position: "relative" }}>
                  <Tooltip title="Modifier">
                    <div
                      onClick={() => setDisplayColorPopup((y) => !y)}
                      style={{
                        width: 70,
                        height: 42,
                        borderRadius: "10px",
                        backgroundColor: statsOfSendForm.options[option].color,
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                  {displayColorPopup && (
                    <div
                      ref={refPopupColor}
                      style={{
                        position: "absolute",
                        zIndex: 100000,
                        top: 25,
                        left: 0,
                      }}
                    >
                      <SketchPicker
                        color={statsOfSendForm.options[option].color}
                        onChange={({ hex }) => {
                          setStatsOfSendForm((x) => {
                            return {
                              ...x,
                              options: {
                                ...x.options,
                                [option]: {
                                  ...x.options[option],
                                  color: hex,
                                },
                              },
                            };
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </FullField>
            </Grid>
            <Grid item display="grid">
              <GenericTextInput
                isMandatory={false}
                fieldTitle="Légende"
                id="option-legend"
                value={statsOfSendForm.options[option].legend}
                onChange={(e) => {
                  setStatsOfSendForm((x) => {
                    return {
                      ...x,
                      options: {
                        ...x.options,
                        [option]: {
                          ...x.options[option],
                          legend: e?.target?.value ? e.target.value : "",
                        },
                      },
                    };
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const FormStatsCreateOrEdit: React.FC<{
  statsOfSendForm?: StatsOfSendForm;
  isEdit?: boolean;
  forms?: Array<IForm>;
  onSubmit: (newStatsOfSendForm: StatsOfSendForm) => void;
  onClose?: () => void;
}> = (props) => {
  const [displayColorPopup, setDisplayColorPopup] =
    React.useState<boolean>(false);
  const [statsOfSendForm, setStatsOfSendForm] =
    React.useState<StatsOfSendForm>(undefined);

  const refPopupColor = useOutsideClick(() => {
    setDisplayColorPopup(false);
  });

  const selectedForm =
    props.forms && statsOfSendForm?.formId
      ? props.forms.find((x) => x?.id === statsOfSendForm.formId)
      : undefined;

  React.useEffect(() => {
    if (props.isEdit) {
      setStatsOfSendForm(props.statsOfSendForm);
    } else {
      setStatsOfSendForm(initStatsOfSendForm());
    }
  }, [props.isEdit, props.statsOfSendForm]);

  if (!statsOfSendForm) {
    return <></>;
  }
  return (
    <GenericFromPopup
      addCloseSecurity={true}
      title={{
        leftPart: props.isEdit ? "Modifier" : "Ajouter",
        rightPart: "une statistique",
      }}
      onClose={props.onClose}
      subtitle={undefined}
    >
      {statsOfSendForm && (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="20px"
        >
          <Grid item display="grid">
            <HandleOpt
              titleCheckBox="Afficher le nombre de réponses"
              option="responsesCount"
              setStatsOfSendForm={setStatsOfSendForm}
              statsOfSendForm={statsOfSendForm}
            />
          </Grid>
          <Grid item display="grid">
            <HandleOpt
              titleCheckBox="Afficher le nombre de programmations"
              option="scheduleCount"
              setStatsOfSendForm={setStatsOfSendForm}
              statsOfSendForm={statsOfSendForm}
            />
          </Grid>
          <Grid item display="grid">
            <HandleOpt
              titleCheckBox="Afficher le nombre de relances"
              option="relancesCount"
              setStatsOfSendForm={setStatsOfSendForm}
              statsOfSendForm={statsOfSendForm}
            />
          </Grid>
          <Grid item display="grid">
            <HandleOpt
              titleCheckBox="Afficher le total sans réponses après relances"
              option="noResponseAfterAllRelanceCount"
              setStatsOfSendForm={setStatsOfSendForm}
              statsOfSendForm={statsOfSendForm}
            />
          </Grid>
          <Grid item display="grid">
            <HandleOpt
              titleCheckBox="Afficher le total de réponses après relances"
              option="responseAfterRelanceCount"
              setStatsOfSendForm={setStatsOfSendForm}
              statsOfSendForm={statsOfSendForm}
            />
          </Grid>
          <Grid item display="grid" paddingTop="15px">
            <GenericSelect
              fieldTitle="Choix du questionnaire"
              maxMenuHeight="120px"
              isMandatory={true}
              value={
                statsOfSendForm?.formId && selectedForm
                  ? (() => {
                    return {
                      value: selectedForm?.id,
                      label: selectedForm?.title,
                    };
                  })()
                  : undefined
              }
              id="forms-stats-select"
              placeholder=""
              isClearable={false}
              options={props.forms
                ?.filter((x) => x?.title && x?.id)
                ?.map((x) => {
                  return {
                    label: x?.title,
                    value: x?.id,
                  };
                })}
              onChange={(e) => {
                setStatsOfSendForm((x) => {
                  return {
                    ...x,
                    formId: e?.value,
                  };
                });
              }}
            />
          </Grid>
          <Grid item display="grid" paddingTop="50px">
            <GenericSendButton
              errorMessages={{
                noOption: "Aucune case n'a été cochée",
                noForm: "Aucun questionnaire n'a été séléctionné",
              }}
              errorSelector={{
                noOption: "empty",
                noForm: "#forms-stats-select",
              }}
              invalidConditions={{
                noOption:
                  !statsOfSendForm?.options?.responseAfterRelanceCount
                    ?.isActive &&
                  !statsOfSendForm?.options?.noResponseAfterAllRelanceCount
                    ?.isActive &&
                  !statsOfSendForm?.options?.relancesCount?.isActive &&
                  !statsOfSendForm?.options?.responsesCount?.isActive &&
                  !statsOfSendForm?.options?.scheduleCount?.isActive,
                noForm: !statsOfSendForm?.formId,
              }}
              onSendClick={() => {
                props.onSubmit(statsOfSendForm);
              }}
              isLoading={false}
              textButton={props.isEdit ? "Modifier" : "Ajouter"}
            />
          </Grid>
        </Grid>
      )}
    </GenericFromPopup>
  );
};

const SectorCreateOrEdit: React.FC<{
  sector?: SecteurGraphique;
  isEdit?: boolean;
  questionnaireData?: Partial<IForm>;
  onSubmit: (newSector: SecteurGraphique) => void;
  onClose?: () => void;
}> = (props) => {
  const [sectorForm, setSectorForm] =
    React.useState<SecteurGraphique>(undefined);

  const [displayColorPopup, setDisplayColorPopup] =
    React.useState<boolean>(false);
  const refPopupColor = useOutsideClick(() => {
    setDisplayColorPopup(false);
  });

  React.useEffect(() => {
    if (props.isEdit) {
      setSectorForm(props.sector);
    } else {
      setSectorForm(initSector());
    }
  }, [props.isEdit, props.sector]);

  if (!sectorForm) {
    return <></>;
  }
  return (
    <GenericFromPopup
      addCloseSecurity={true}
      title={{
        leftPart: props.isEdit ? "Modifier" : "Créer",
        rightPart: "mon secteur",
      }}
      onClose={props.onClose}
      subtitle={props.questionnaireData?.title}
    >
      {sectorForm && (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          padding="20px"
          gap="20px"
        >
          <Grid item display="grid">
            <GenericTextInput
              isMandatory={true}
              fieldTitle="Légende"
              id="legend-sector"
              value={sectorForm.legend}
              onChange={(e) => {
                setSectorForm((x) => {
                  return { ...x, legend: e?.target?.value };
                });
              }}
            />
          </Grid>
          <Grid item display="grid">
            <FullField title="Couleur" isMandatory={true}>
              <div style={{ position: "relative" }}>
                <Tooltip title="Modifier">
                  <div
                    onClick={() => setDisplayColorPopup((y) => !y)}
                    style={{
                      width: 70,
                      height: 20,
                      backgroundColor: sectorForm.color,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
                {displayColorPopup && (
                  <div
                    ref={refPopupColor}
                    style={{
                      position: "absolute",
                      zIndex: 100000,
                      top: 25,
                      left: 0,
                    }}
                  >
                    <SketchPicker
                      color={sectorForm.color}
                      onChange={({ hex }) => {
                        setSectorForm((x) => {
                          return { ...x, color: hex };
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </FullField>
            {/*
              const handleColorChange = ({ hex }) => console.log(hex)

  return (
    <div>
      <SketchPicker
        color="#333"
        onChangeComplete={ handleColorChange }
      />
            */}
          </Grid>
          <Grid item display="grid">
            <MyConditionsHandler
              conditionType={sectorForm?.conditionType}
              conditions={sectorForm?.conditions}
              updateConditionType={(value) => {
                setSectorForm((state) => {
                  return {
                    ...state,
                    conditionType: value,
                  };
                });
              }}
              deleteConditionByIndex={(index) => {
                setSectorForm((state) => {
                  return {
                    ...state,
                    conditions: state.conditions.filter((x, i) => index !== i),
                  };
                });
              }}
              addCondition={(condition) => {
                setSectorForm((x) => {
                  return { ...x, conditions: [...x.conditions, condition] };
                });
              }}
              questionnaireData={props.questionnaireData}
            />
          </Grid>
          <Grid item display="grid">
            <GenericSendButton
              errorMessages={{
                noLegend: "La légende n'est pas renseignée.",
                noCondition: "Aucune condition n'a été ajoutée.",
              }}
              errorSelector={{
                noLegend: "#legend-sector",
                noCondition: "#add-cond-button",
              }}
              invalidConditions={{
                noLegend: !sectorForm.legend,
                noCondition:
                  !sectorForm?.conditions ||
                  sectorForm?.conditions?.length == 0,
              }}
              onSendClick={() => {
                props.onSubmit(sectorForm);
              }}
              isLoading={false}
              textButton={props.isEdit ? "Modifier" : "Créer"}
            />
          </Grid>
        </Grid>
      )}
    </GenericFromPopup>
  );
};

const CreateQuestionnairePDFContent: React.FC<any> = (props) => {
  const questionnaireSelector = useAppSelector(
    (x) => x.newQuestionnaireReducer
  );

  const [addFormStatsPopupOpen, setAddFormStatsPopupOpen] =
    React.useState<boolean>(false);

  const [openEditFormStats, setOpenEditFormStats] =
    React.useState<StatsOfSendForm>(undefined);

  const [openCreateStackedBar, setOpenCreateStackedBar] =
    React.useState<boolean>(false);
  const [openEditStackedBar, setOpenEditStackedBar] =
    React.useState<StackedBar>(undefined);

  const [openCreateSector, setOpenCreateSector] =
    React.useState<boolean>(false);
  const [openEditSector, setOpenEditSector] =
    React.useState<SecteurGraphique>(undefined);
  const [openEditCellData, setOpenEditCellData] =
    React.useState<CellData>(undefined);
  const initPdfQuestionnaire: PDFQuestionnaire = {
    title: ``,
    elements: [],
    _id: undefined,
  };
  const initPdfElementInPopup: PDFElement = {
    formId: undefined,
    type: "display_text_and_image",
    textAndImageContent: undefined,
    graphTitle: undefined,
    secteurs: undefined,
    nbArrayColumns: 3,
    nbArrayRows: 3,
    arrayData: initArrayData(),
  };
  const history = useHistory();
  const [forms, setForms] = React.useState<IForm[]>([]);
  const [pdfElementInPopup, setPdfElementInPopup] = React.useState<PDFElement>(
    initPdfElementInPopup
  );
  const [isSuccessCreatePopup, setIsSuccessCreatePopup] =
    React.useState<boolean>(false);
  const [editElementPopupOpen, setEditElementPopupOpen] =
    React.useState<PDFElement>(undefined);
  const [addElementPopupOpen, setAddElementPopupOpen] =
    React.useState<boolean>(false);
  const [pdfQuestionnaire, setPdfQuestionnaire] =
    React.useState<PDFQuestionnaire>(initPdfQuestionnaire);
  const { isEdit, isCreate } = props;
  const { id } = useParams<{ id: string }>();
  const [formsListIsLoading, setFormsListIsLoading] = React.useState(true);
  const [isEditDataLoading, setIsEditDataLoading] = React.useState(
    isCreate ? false : true
  );
  const snackbar = useOpenSnackbar();
  const formFounded =
    forms && pdfElementInPopup?.formId
      ? forms.find((x) => x.id === pdfElementInPopup.formId)
      : undefined;

  React.useEffect(() => {
    if (!isEdit || !id) {
      return;
    }
    setIsEditDataLoading(true);
    makeAPIRequest(`get`, `/forms/pdf/${id}`, null, `v3`)
      .then((res) => {
        if (res?.data) setPdfQuestionnaire(res?.data);
        else snackbar.error(`Impossible de récupérer ce PDF.`);
      })
      .catch((err) => {
        snackbar.error(`Impossible de récupérer ce PDF.`, err);
      })
      .finally(() => {
        setIsEditDataLoading(false);
      });
  }, [isEdit, id]);

  React.useEffect(() => {
    setFormsListIsLoading(true);
    makeAPIRequest("get", "/questions/forms/list", null, "v3")
      .then((res) => {
        if (res.data) setForms(res?.data);
      })
      .catch((err) => {
        snackbar.error(
          "Impossible de récupérer la liste des questionnaires.",
          err
        );
      })
      .finally(() => {
        setFormsListIsLoading(false);
      });
  }, []);

  const typesNeedFormInfos = [
    "display_text_and_image",
    "circular_diagram",
    "saisis_par_delegue",
    "variable",
    "stacked_bar_chart",
  ];

  const initVariableAndOverride = (override: Partial<VariableData> = {}) => {
    return {
      conditions: [],
      conditionType: "and",
      displayPercent: false,
      roundPercent: false,
      id: nanoid(),
      name: "",
      ...override,
    } as VariableData;
  };

  const getIconByType = (type?: string) => {
    if (!type) return null;

    const Icon = typeOptionsPDF?.find(
      (element) => element.value === type
    )?.icon;
    return <Icon style={{ color: "#0B243C" }} />;
  };

  const TypeOptionsStyle: React.FC<any> = (props) => {
    const OptionIcon = props.data.icon;
    const text = props.data.label;

    return (
      <Option {...props}>
        <Grid container direction="row" spacing="10px">
          <Grid item display="grid">
            <OptionIcon />
          </Grid>
          <Grid item display="grid">
            <span>{text}</span>
          </Grid>
        </Grid>
      </Option>
    );
  };

  if (formsListIsLoading || isEditDataLoading) {
    return (
      <Grid
        container
        direction="row"
        paddingTop="30px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {isSuccessCreatePopup && (
        <CancellablePopup
          firstTitle={isCreate ? "Créer" : "Modifier"}
          secondTitle="un PDF"
          message={`Le PDF '${pdfQuestionnaire.title}' a bien été ${isCreate ? "crée" : "modifié"
            }.`}
          cancelButtonText="Retour"
          onCancel={() => {
            history.push("/home?f=questionnaires&tab=pdf");
          }}
          confirmButtonText="Créer un nouveau PDF"
          onConfirm={() => {
            setIsSuccessCreatePopup(false);
            setPdfQuestionnaire(initPdfQuestionnaire);
            history.push("/create_pdf_questionnaire");
          }}
        />
      )}
      {openCreateStackedBar && (
        <StackedBarCreateOrEdit
          onSubmit={(newStackedBar: StackedBar) => {
            setPdfElementInPopup((x) => {
              return {
                ...x,
                stackedBars: !x?.stackedBars
                  ? [newStackedBar]
                  : [...x.stackedBars, newStackedBar],
              };
            });
            setOpenCreateStackedBar(false);
          }}
          stackedBar={undefined}
          isEdit={false}
          onClose={() => setOpenCreateStackedBar(false)}
        />
      )}
      {openEditStackedBar && (
        <StackedBarCreateOrEdit
          onSubmit={(newStackedBar: StackedBar) => {
            setPdfElementInPopup((x) => {
              return {
                ...x,
                stackedBars: x.stackedBars.map((y) => {
                  if (y.id === newStackedBar.id) {
                    return newStackedBar;
                  }
                  return y;
                }),
              };
            });
            setOpenEditStackedBar(undefined);
          }}
          stackedBar={openEditStackedBar}
          isEdit={true}
          onClose={() => setOpenEditStackedBar(undefined)}
        />
      )}
      {openEditFormStats && (
        <FormStatsCreateOrEdit
          forms={forms}
          statsOfSendForm={openEditFormStats}
          onSubmit={(newStatsOfSendForm) => {
            setPdfElementInPopup((x) => {
              return {
                ...x,
                statsOfSend: {
                  list: x.statsOfSend?.list?.map((y) => {
                    if (y.id === newStatsOfSendForm.id) {
                      return newStatsOfSendForm;
                    }
                    return y;
                  }),
                },
              };
            });
            setOpenEditFormStats(undefined);
          }}
          isEdit={true}
          onClose={() => setOpenEditFormStats(undefined)}
        />
      )}
      {addFormStatsPopupOpen && (
        <FormStatsCreateOrEdit
          forms={forms}
          statsOfSendForm={undefined}
          onSubmit={(newStatsOfSendForm) => {
            setPdfElementInPopup((x) => {
              return {
                ...x,
                statsOfSend: {
                  list:
                    x.statsOfSend?.list?.length > 0
                      ? [...x.statsOfSend.list, newStatsOfSendForm]
                      : [newStatsOfSendForm],
                },
              };
            });
            setAddFormStatsPopupOpen(false);
          }}
          isEdit={false}
          onClose={() => setAddFormStatsPopupOpen(false)}
        />
      )}
      {openCreateSector && (
        <SectorCreateOrEdit
          onSubmit={(newSector) => {
            setPdfElementInPopup((x) => {
              return {
                ...x,
                secteurs: !x?.secteurs
                  ? [newSector]
                  : [...x.secteurs, newSector],
              };
            });
            setOpenCreateSector(false);
          }}
          sector={undefined}
          isEdit={false}
          onClose={() => setOpenCreateSector(false)}
          questionnaireData={
            questionnaireSelector?.list?.length > 0 && pdfElementInPopup?.formId
              ? questionnaireSelector?.list?.find(
                (y) => y?.id == pdfElementInPopup.formId
              )
              : undefined
          }
        />
      )}
      {openEditSector && (
        <SectorCreateOrEdit
          onSubmit={(newSector) => {
            setPdfElementInPopup((x) => {
              return {
                ...x,
                secteurs: x.secteurs.map((y) => {
                  if (y.id === newSector.id) {
                    return newSector;
                  }
                  return y;
                }),
              };
            });
            setOpenEditSector(undefined);
          }}
          sector={openEditSector}
          isEdit={true}
          onClose={() => setOpenEditSector(undefined)}
          questionnaireData={
            questionnaireSelector?.list?.length > 0 && pdfElementInPopup?.formId
              ? questionnaireSelector?.list?.find(
                (y) => y?.id == pdfElementInPopup.formId
              )
              : undefined
          }
        />
      )}
      {openEditCellData && (
        <GenericFromPopup
          addCloseSecurity={true}
          title={{
            leftPart: "Modifier",
            rightPart: "une cellule",
          }}
          onClose={() => {
            setOpenEditCellData(undefined);
          }}
        >
          <Grid
            container
            direction={`column`}
            wrap="nowrap"
            gap={"20px"}
            padding="20px"
          >
            <Grid item display="grid">
              <GenericSelect
                fieldTitle="Type"
                isMandatory={true}
                value={typeOptionsCell?.find(
                  (x) => x.value === openEditCellData.type
                )}
                id="type-cell"
                placeholder=""
                isClearable={false}
                options={typeOptionsCell}
                onChange={(e) => {
                  setOpenEditCellData((x) => {
                    return { ...x, type: e?.value };
                  });
                }}
              />
            </Grid>
            <Grid item display={"grid"}>
              <GenericSelect
                fieldTitle="Choix du questionnaire"
                maxMenuHeight="150px"
                isMandatory={true}
                value={(() => {
                  if (!openEditCellData?.formId || !forms) {
                    return undefined;
                  }
                  const form = forms.find(
                    (x) => x.id === openEditCellData.formId
                  );
                  if (!form) {
                    return undefined;
                  }
                  return { value: form?.id, label: form?.title };
                })()}
                id="form-choice-cell"
                placeholder=""
                isClearable={true}
                options={forms
                  ?.filter((x) => x?.title && x?.id)
                  ?.map((x) => {
                    return {
                      label: x?.title,
                      value: x?.id,
                    };
                  })}
                onChange={(e) => {
                  setOpenEditCellData((x) => {
                    return {
                      ...x,
                      formId: e?.value,
                      conditions: [],
                    };
                  });
                }}
              />
            </Grid>
            {openEditCellData?.type === "text" ? (
              <>
                <Grid item display="grid">
                  <GenericLongText
                    fieldTitle="Contenu"
                    usableVariables={[
                      ...PDFVariables,
                      ...pdfQuestionnaire.elements
                        .filter(
                          (y) => y?.type === "variable" && y?.variable?.name
                        )
                        .map((x) => x.variable.name),
                    ]}
                    height="150px"
                    maxLength={1000}
                    id="content-cell"
                    value={openEditCellData?.textContent}
                    onChange={(e) => {
                      setOpenEditCellData((x) => {
                        return { ...x, textContent: e };
                      });
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item display="grid">
                  <MyConditionsHandler
                    conditionType={openEditCellData?.conditionType}
                    conditions={openEditCellData?.conditions}
                    questionnaireData={forms.find(
                      (x) => x?.id == openEditCellData?.formId
                    )}
                    updateConditionType={(value) => {
                      setOpenEditCellData((state) => {
                        return {
                          ...state,
                          conditionType: value,
                        };
                      });
                    }}
                    deleteConditionByIndex={(index) => {
                      setOpenEditCellData((state) => {
                        return {
                          ...state,
                          conditions: state.conditions
                            ? state.conditions.filter((x, i) => index !== i)
                            : [],
                        };
                      });
                    }}
                    addCondition={(condition) => {
                      setOpenEditCellData((state) => {
                        return {
                          ...state,
                          conditions: state.conditions
                            ? [...state.conditions, condition]
                            : [condition],
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <OrangeCheckBox
                    text="Afficher le pourcentage de réponses plutôt que le nombre de réponses"
                    onClick={() => {
                      setOpenEditCellData((x) => {
                        return {
                          ...x,
                          displayPercent: openEditCellData?.displayPercent
                            ? false
                            : true,
                        };
                      });
                    }}
                    value={openEditCellData?.displayPercent ? true : false}
                  />
                </Grid>
                {openEditCellData?.displayPercent && (
                  <Grid item display="grid">
                    <OrangeCheckBox
                      text="Arrondir à l'entier le plus proche"
                      onClick={() => {
                        setOpenEditCellData((x) => {
                          return {
                            ...x,
                            roundPercent: openEditCellData?.roundPercent
                              ? false
                              : true,
                          };
                        });
                      }}
                      value={openEditCellData?.roundPercent ? true : false}
                    />
                  </Grid>
                )}
                <Grid item display="grid">
                  <OrangeCheckBox
                    text="Choisir une période"
                    onClick={() => {
                      setOpenEditCellData((x) => {
                        return {
                          ...x,
                          onlyResponseInPeriod:
                            openEditCellData?.onlyResponseInPeriod
                              ? false
                              : true,
                        };
                      });
                    }}
                    value={
                      openEditCellData?.onlyResponseInPeriod ? true : false
                    }
                  />
                </Grid>
                {openEditCellData?.onlyResponseInPeriod && (
                  <Grid item display="grid">
                    <Grid
                      container
                      direction="row"
                      gap="10px"
                      alignItems={"center"}
                    >
                      <Grid item display="grid">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#0B243C",
                          }}
                        >
                          Du
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <input
                          id="start-date"
                          type="date"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #D3E0E0",
                            background: "#EDF2F280 0% 0% no-repeat padding-box",
                            borderRadius: "10px",
                            paddingRight: "20px",
                            paddingLeft: "20px",
                            color:
                              openEditCellData && openEditCellData?.startDate
                                ? "#0B243C"
                                : "#657273",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            height: "42px",
                          }}
                          value={
                            openEditCellData?.startDate
                              ? openEditCellData?.startDate
                              : undefined
                          }
                          onChange={(e) => {
                            if (e?.target?.value) {
                              setOpenEditCellData((x) => {
                                return {
                                  ...x,
                                  startDate: e.target.value,
                                };
                              });
                            } else {
                              setOpenEditCellData((x) => {
                                return {
                                  ...x,
                                  startDate: undefined,
                                };
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#0B243C",
                          }}
                        >
                          au
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <input
                          id="end-date"
                          type="date"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #D3E0E0",
                            background: "#EDF2F280 0% 0% no-repeat padding-box",
                            borderRadius: "10px",
                            paddingRight: "20px",
                            paddingLeft: "20px",
                            color:
                              openEditCellData && openEditCellData?.endDate
                                ? "#0B243C"
                                : "#657273",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            height: "42px",
                          }}
                          value={
                            openEditCellData?.endDate
                              ? openEditCellData?.endDate
                              : undefined
                          }
                          onChange={(e) => {
                            if (e?.target?.value) {
                              setOpenEditCellData((x) => {
                                return {
                                  ...x,
                                  endDate: e.target.value,
                                };
                              });
                            } else {
                              setOpenEditCellData((x) => {
                                return {
                                  ...x,
                                  endDate: undefined,
                                };
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#0B243C",
                          }}
                        >
                          inclus
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            <Grid item display={"grid"}>
              <GenericSendButton
                textButton={"Mettre à jour"}
                errorMessages={{
                  noType: "Aucun type n'est renseigné.",
                  emptyForm: "Aucun questionnaire n'a été sélectionné.",
                  noCondition: "Aucune condition n'a été ajoutée.",
                  emptyStartDate: "La date de début n'est pas renseignée.",
                  emptyEndDate: "La date de fin n'est pas renseignée.",
                  badDates: "Les dates de début et de fin sont inversées.",
                  "": "",
                }}
                errorSelector={{
                  noType: `#type-cell`,
                  emptyForm: `#form-choice-cell`,
                  noCondition: `#add-cond-button`,
                  emptyStartDate: "#start-date",
                  emptyEndDate: "#start-date",
                  badDates: "#start-date",
                  "": "",
                }}
                invalidConditions={{
                  noType: !openEditCellData?.type,
                  emptyForm: !openEditCellData?.formId,
                  noCondition:
                    openEditCellData?.type === "condition" &&
                    (!openEditCellData?.conditions ||
                      openEditCellData?.conditions?.length == 0),
                  emptyStartDate:
                    openEditCellData?.type === "condition" &&
                    openEditCellData?.onlyResponseInPeriod &&
                    !openEditCellData?.startDate,
                  emptyEndDate:
                    openEditCellData?.type === "condition" &&
                    openEditCellData?.onlyResponseInPeriod &&
                    !openEditCellData?.endDate,
                  badDates:
                    openEditCellData?.type === "condition" &&
                    openEditCellData?.onlyResponseInPeriod &&
                    openEditCellData?.startDate > openEditCellData?.endDate,
                }}
                onSendClick={() => {
                  setPdfElementInPopup((x) => {
                    if (!x?.arrayData) {
                      return x;
                    }
                    return {
                      ...x,
                      arrayData: x.arrayData.map((row) => {
                        return row.map((col) => {
                          return col.id == openEditCellData.id
                            ? openEditCellData
                            : col;
                        });
                      }),
                    };
                  });
                  setOpenEditCellData(undefined);
                }}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      {(addElementPopupOpen || editElementPopupOpen) && (
        <GenericFromPopup
          addCloseSecurity={true}
          title={{
            leftPart: editElementPopupOpen ? `Modifier` : "Ajouter",
            rightPart: "un élément",
          }}
          onClose={() => {
            setAddElementPopupOpen(false);
            setEditElementPopupOpen(undefined);
            setPdfElementInPopup(initPdfElementInPopup);
          }}
        >
          <Grid
            container
            direction={`column`}
            wrap="nowrap"
            gap={"20px"}
            padding="20px"
          >
            <Grid item display="grid">
              <GenericSelect
                fieldTitle="Type"
                maxMenuHeight="150px"
                isMandatory={true}
                value={typeOptionsPDF?.find(
                  (x) => x.value === pdfElementInPopup.type
                )}
                id="type-create-pdf-element"
                placeholder=""
                components={{ Option: TypeOptionsStyle }}
                isClearable={false}
                options={typeOptionsPDF}
                onChange={(e) => {
                  setPdfElementInPopup((x) => {
                    return { ...x, type: e?.value };
                  });
                }}
              />
            </Grid>
            {pdfElementInPopup.type &&
              typesNeedFormInfos.includes(pdfElementInPopup.type) && (
                <Grid item display="grid">
                  <GenericSelect
                    fieldTitle="Choix du questionnaire"
                    maxMenuHeight="150px"
                    isMandatory={true}
                    value={
                      formFounded
                        ? { value: formFounded.id, label: formFounded.title }
                        : undefined
                    }
                    id="form-create-pdf-element"
                    placeholder=""
                    isClearable={true}
                    options={forms
                      ?.filter((x) => x?.title && x?.id)
                      ?.map((x) => {
                        return {
                          label: x?.title,
                          value: x?.id,
                        };
                      })}
                    onChange={(e) => {
                      setPdfElementInPopup((x) => {
                        return {
                          ...x,
                          formId: e?.value,
                          secteurs: x.secteurs ? [] : undefined,
                          variable: x.variable
                            ? { ...x.variable, conditions: [] }
                            : undefined,
                        };
                      });
                    }}
                  />
                </Grid>
              )}

            {["array"].includes(pdfElementInPopup.type) && (
              <>
                <Grid item display="grid">
                  <Grid container direction="row" gap="20px">
                    <Grid item display="grid" minWidth="220px">
                      <GenericSelect
                        fieldTitle="Nombre de lignes"
                        isMandatory={true}
                        id="nb-rows-array"
                        isClearable={false}
                        options={RowsColumnsOptions}
                        placeholder=""
                        value={
                          pdfElementInPopup.nbArrayRows
                            ? {
                              label: pdfElementInPopup.nbArrayRows.toString(),
                              value: pdfElementInPopup.nbArrayRows,
                            }
                            : { label: "0", value: 0 }
                        }
                        onChange={(e) => {
                          setPdfElementInPopup((x) => {
                            return {
                              ...x,
                              nbArrayRows: e?.value ? parseInt(e.value) : 0,
                              arrayData: !x?.arrayData
                                ? initArrayData()
                                : fixCellsInTable(x.arrayData),
                            };
                          });
                        }}
                      />
                    </Grid>
                    <Grid item display="grid" minWidth="220px">
                      <GenericSelect
                        fieldTitle="Nombre de colonnes"
                        isMandatory={true}
                        id="nb-columns-array"
                        isClearable={false}
                        options={RowsColumnsOptions}
                        placeholder=""
                        value={
                          pdfElementInPopup.nbArrayColumns
                            ? {
                              label:
                                pdfElementInPopup.nbArrayColumns.toString(),
                              value: pdfElementInPopup.nbArrayColumns,
                            }
                            : { label: "0", value: 0 }
                        }
                        onChange={(e) => {
                          setPdfElementInPopup((x) => {
                            return {
                              ...x,
                              nbArrayColumns: e?.value ? parseInt(e.value) : 0,
                              arrayData: !x?.arrayData
                                ? initArrayData()
                                : fixCellsInTable(x.arrayData),
                            };
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {pdfElementInPopup?.arrayData && (
                  <Grid item display="grid">
                    <table
                      style={{
                        border: "2px solid #000",
                      }}
                    >
                      <tbody>
                        {pdfElementInPopup.arrayData
                          .slice(0, pdfElementInPopup.nbArrayRows)
                          .map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {row
                                .slice(0, pdfElementInPopup.nbArrayColumns)
                                .map((cell, cellIndex) => (
                                  <td
                                    key={cellIndex}
                                    style={{
                                      border: "2px solid #000",
                                      maxWidth: "200px",
                                      whiteSpace: "pre-line",
                                      wordBreak: "break-word",
                                      padding: "10px",
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      gap="10px"
                                      justifyContent={"center"}
                                    >
                                      {cell?.type === "text" ? (
                                        <Grid item display="grid">
                                          <span>
                                            {cell?.textContent
                                              ? cell.textContent
                                              : "Vide"}
                                          </span>
                                        </Grid>
                                      ) : (
                                        <Grid item display="grid">
                                          <span>Condition</span>
                                        </Grid>
                                      )}
                                      <Grid item display="grid">
                                        <ButtonIconWithTooltip
                                          Icon={
                                            <EditRounded
                                              fontSize="small"
                                              style={{ color: "#0B243C" }}
                                            />
                                          }
                                          onClick={() => {
                                            setOpenEditCellData(cell);
                                          }}
                                          tooltipText="Modifier"
                                        />
                                      </Grid>
                                    </Grid>
                                  </td>
                                ))}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Grid>
                )}
              </>
            )}

            {["variable"].includes(pdfElementInPopup.type) && (
              <Grid item display="grid">
                <GenericTextInput
                  fieldTitle="Nom de la variable"
                  isMandatory={true}
                  id="title-of-variable"
                  value={pdfElementInPopup?.variable?.name}
                  onChange={(e) => {
                    setPdfElementInPopup((x) => {
                      return {
                        ...x,
                        variable: pdfElementInPopup?.variable
                          ? {
                            ...pdfElementInPopup?.variable,
                            name: e?.target?.value,
                          }
                          : initVariableAndOverride({ name: e?.target?.value }),
                      };
                    });
                  }}
                />
              </Grid>
            )}

            {["variable"].includes(pdfElementInPopup.type) && (
              <Grid item display="grid">
                <MyConditionsHandler
                  conditionType={pdfElementInPopup?.variable?.conditionType}
                  conditions={pdfElementInPopup?.variable?.conditions}
                  updateConditionType={(value) => {
                    setPdfElementInPopup((state) => {
                      return {
                        ...state,
                        variable: state.variable
                          ? {
                            ...state.variable,
                            conditionType: value,
                          }
                          : initVariableAndOverride({
                            conditionType: value,
                          }),
                      };
                    });
                  }}
                  deleteConditionByIndex={(index) => {
                    setPdfElementInPopup((state) => {
                      return {
                        ...state,
                        variable: state.variable
                          ? {
                            ...state.variable,
                            conditions: state.variable.conditions
                              ? state.variable.conditions.filter(
                                (x, i) => index !== i
                              )
                              : [],
                          }
                          : initVariableAndOverride(),
                      };
                    });
                  }}
                  addCondition={(condition) => {
                    setPdfElementInPopup((state) => {
                      return {
                        ...state,
                        variable: state.variable
                          ? {
                            ...state.variable,
                            conditions: state.variable.conditions
                              ? [...state.variable.conditions, condition]
                              : [condition],
                          }
                          : initVariableAndOverride({
                            conditions: [condition],
                          }),
                      };
                    });
                  }}
                  questionnaireData={
                    questionnaireSelector?.list?.length > 0 &&
                      pdfElementInPopup?.formId
                      ? questionnaireSelector?.list?.find(
                        (y) => y?.id == pdfElementInPopup.formId
                      )
                      : undefined
                  }
                />
              </Grid>
            )}
            {["variable"].includes(pdfElementInPopup.type) && (
              <>
                <Grid item display="grid">
                  <OrangeCheckBox
                    text="Afficher le pourcentage de réponses plutôt que le nombre de réponses"
                    onClick={() => {
                      setPdfElementInPopup((x) => {
                        return {
                          ...x,
                          variable: pdfElementInPopup?.variable
                            ? {
                              ...pdfElementInPopup?.variable,
                              displayPercent: pdfElementInPopup?.variable
                                ?.displayPercent
                                ? false
                                : true,
                            }
                            : initVariableAndOverride({ displayPercent: true }),
                        };
                      });
                    }}
                    value={
                      pdfElementInPopup?.variable?.displayPercent ? true : false
                    }
                  />
                </Grid>
                {pdfElementInPopup?.variable?.displayPercent && (
                  <Grid item display="grid">
                    <OrangeCheckBox
                      text="Arrondir à l'entier le plus proche"
                      onClick={() => {
                        setPdfElementInPopup((x) => {
                          return {
                            ...x,
                            variable: pdfElementInPopup?.variable
                              ? {
                                ...pdfElementInPopup?.variable,
                                roundPercent: pdfElementInPopup?.variable
                                  ?.roundPercent
                                  ? false
                                  : true,
                              }
                              : initVariableAndOverride({ roundPercent: true }),
                          };
                        });
                      }}
                      value={
                        pdfElementInPopup?.variable?.roundPercent ? true : false
                      }
                    />
                  </Grid>
                )}
                <Grid item display="grid">
                  <OrangeCheckBox
                    text="Choisir une période"
                    onClick={() => {
                      setPdfElementInPopup((x) => {
                        return {
                          ...x,
                          variable: {
                            ...x.variable,
                            onlyResponseInPeriod: pdfElementInPopup?.variable
                              ?.onlyResponseInPeriod
                              ? false
                              : true,
                          },
                        };
                      });
                    }}
                    value={
                      pdfElementInPopup?.variable?.onlyResponseInPeriod
                        ? true
                        : false
                    }
                  />
                </Grid>
                {pdfElementInPopup?.variable?.onlyResponseInPeriod && (
                  <Grid item display="grid">
                    <Grid
                      container
                      direction="row"
                      gap="10px"
                      alignItems={"center"}
                    >
                      <Grid item display="grid">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#0B243C",
                          }}
                        >
                          Du
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <input
                          id="start-date"
                          type="date"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #D3E0E0",
                            background: "#EDF2F280 0% 0% no-repeat padding-box",
                            borderRadius: "10px",
                            paddingRight: "20px",
                            paddingLeft: "20px",
                            color:
                              pdfElementInPopup?.variable &&
                                pdfElementInPopup.variable?.startDate
                                ? "#0B243C"
                                : "#657273",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            height: "42px",
                          }}
                          value={
                            pdfElementInPopup?.variable?.startDate
                              ? pdfElementInPopup.variable.startDate
                              : undefined
                          }
                          onChange={(e) => {
                            setPdfElementInPopup((x) => {
                              return {
                                ...x,
                                variable: {
                                  ...x.variable,
                                  startDate: e?.target?.value
                                    ? e.target.value
                                    : undefined,
                                },
                              };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#0B243C",
                          }}
                        >
                          au
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <input
                          id="end-date"
                          type="date"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #D3E0E0",
                            background: "#EDF2F280 0% 0% no-repeat padding-box",
                            borderRadius: "10px",
                            paddingRight: "20px",
                            paddingLeft: "20px",
                            color:
                              pdfElementInPopup?.variable &&
                                pdfElementInPopup?.variable?.endDate
                                ? "#0B243C"
                                : "#657273",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            height: "42px",
                          }}
                          value={
                            pdfElementInPopup?.variable?.endDate
                              ? pdfElementInPopup?.variable?.endDate
                              : undefined
                          }
                          onChange={(e) => {
                            setPdfElementInPopup((x) => {
                              return {
                                ...x,
                                variable: {
                                  ...x.variable,
                                  endDate: e?.target?.value
                                    ? e.target.value
                                    : undefined,
                                },
                              };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#0B243C",
                          }}
                        >
                          inclus
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {pdfElementInPopup.type === "stacked_bar_chart" && (
              <>
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitle="Titre du graphique"
                    usableVariables={[
                      ...PDFVariables,
                      ...pdfQuestionnaire.elements
                        .filter(
                          (y) => y?.type === "variable" && y?.variable?.name
                        )
                        .map((x) => x.variable.name),
                    ]}
                    isMandatory={false}
                    id="title-of-graph"
                    value={pdfElementInPopup?.graphTitle}
                    onChange={(e) => {
                      setPdfElementInPopup((x) => {
                        return { ...x, graphTitle: e?.target?.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item display={"grid"}>
                  <AddButton
                    text={"Créer une barre empilée"}
                    displayText={true}
                    addId={"create-stacked-bar"}
                    onClick={() => {
                      setOpenCreateStackedBar(true);
                    }}
                  />
                </Grid>
                {pdfElementInPopup?.stackedBars &&
                  pdfElementInPopup?.stackedBars?.length > 0 && (
                    <Grid item display="grid">
                      <div
                        className="table-grid-stacked-bars"
                        style={{ overflow: "auto" }}
                      >
                        <span className="table-grid-title">Questionnaire</span>
                        <span className="table-grid-title">Intitulé</span>
                        <span className="table-grid-title">Gérer</span>
                        {pdfElementInPopup.stackedBars.map((stackedBar) => {
                          const thisSelectedForm =
                            forms && stackedBar?.formId
                              ? forms.find((x) => x?.id === stackedBar.formId)
                              : undefined;
                          const thisFieldOptions = thisSelectedForm
                            ? QuestionnaireUtils.getFieldListOptions(
                              thisSelectedForm
                            )
                            : undefined;
                          const thisFieldSelectedInfos: IField =
                            thisFieldOptions && stackedBar?.fieldId
                              ? QuestionnaireUtils.getFieldSelectedInfos(
                                thisSelectedForm,
                                stackedBar?.fieldId
                              )
                              : undefined;

                          /*
                          const thisFieldsLabelValue = thisSelectedForm
                            ? getFieldsLabelValue(thisSelectedForm)
                            : undefined;
                          */

                          return (
                            <>
                              <span
                                className="table-grid-text"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {thisSelectedForm?.title
                                  ? thisSelectedForm.title
                                  : "Sans titre"}
                              </span>
                              <span
                                className="table-grid-text"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {stackedBar?.entitled
                                  ? stackedBar.entitled
                                  : thisFieldSelectedInfos?.title
                                    ? thisFieldSelectedInfos.title
                                    : "Sans titre"}
                              </span>
                              <Grid
                                container
                                direction="row"
                                gap="10px"
                                wrap="nowrap"
                                className="table-grid-text"
                              >
                                <Grid item display="grid">
                                  <Tooltip title="Modifier">
                                    <IconButton
                                      onClick={() => {
                                        setOpenEditStackedBar(stackedBar);
                                      }}
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    >
                                      <EditRounded
                                        fontSize="small"
                                        style={{ color: "#0B243C" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item display="grid">
                                  <Tooltip title="Supprimer">
                                    <IconButton
                                      onClick={() => {
                                        setPdfElementInPopup((x) => {
                                          return {
                                            ...x,
                                            stackedBars: x.stackedBars.filter(
                                              (y) => y.id !== stackedBar.id
                                            ),
                                          };
                                        });
                                      }}
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    >
                                      <ClearRounded
                                        fontSize="small"
                                        style={{ color: "#FF3100" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                      </div>
                    </Grid>
                  )}
              </>
            )}

            {["circular_diagram"].includes(pdfElementInPopup.type) && (
              <>
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitle="Titre du graphique"
                    usableVariables={[
                      ...PDFVariables,
                      ...pdfQuestionnaire.elements
                        .filter(
                          (y) => y?.type === "variable" && y?.variable?.name
                        )
                        .map((x) => x.variable.name),
                    ]}
                    isMandatory={false}
                    id="title-of-graph"
                    value={pdfElementInPopup?.graphTitle}
                    onChange={(e) => {
                      setPdfElementInPopup((x) => {
                        return { ...x, graphTitle: e?.target?.value };
                      });
                    }}
                  />
                </Grid>
                {pdfElementInPopup.formId && (
                  <Grid item display="grid">
                    <AddButton
                      text={"Créer un secteur"}
                      displayText={true}
                      addId={"create-secteur"}
                      onClick={() => {
                        setOpenCreateSector(true);
                      }}
                    />
                  </Grid>
                )}

                {pdfElementInPopup?.secteurs &&
                  pdfElementInPopup?.secteurs?.length > 0 && (
                    <Grid item display="grid">
                      <div
                        className="table-grid-secteurs"
                        style={{ overflow: "auto" }}
                      >
                        <span className="table-grid-title">Couleur</span>
                        <span className="table-grid-title">Légende</span>
                        <span className="table-grid-title">Gérer</span>
                        {pdfElementInPopup.secteurs.map((secteur) => {
                          return (
                            <>
                              <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                className="table-grid-text"
                              >
                                <Grid item display="grid">
                                  <SquareRounded
                                    style={{
                                      fontSize: "25px",
                                      color: secteur.color,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <span
                                className="table-grid-text"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {secteur.legend}
                              </span>
                              <Grid
                                container
                                direction="row"
                                gap="10px"
                                wrap="nowrap"
                                className="table-grid-text"
                              >
                                <Grid item display="grid">
                                  <Tooltip title="Modifier">
                                    <IconButton
                                      onClick={() => {
                                        setOpenEditSector(secteur);
                                      }}
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    >
                                      <EditRounded
                                        fontSize="small"
                                        style={{ color: "#0B243C" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item display="grid">
                                  <Tooltip title="Supprimer">
                                    <IconButton
                                      onClick={() => {
                                        setPdfElementInPopup((x) => {
                                          return {
                                            ...x,
                                            secteurs: x.secteurs.filter(
                                              (y) => y.id !== secteur.id
                                            ),
                                          };
                                        });
                                      }}
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    >
                                      <ClearRounded
                                        fontSize="small"
                                        style={{ color: "#FF3100" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                      </div>
                    </Grid>
                  )}
              </>
            )}
            {["saisis_par_delegue", "statistique_envoi"].includes(
              pdfElementInPopup.type
            ) && (
                <>
                  <Grid item display="grid">
                    <GenericTextInput
                      fieldTitle="Titre du graphique"
                      usableVariables={[
                        ...PDFVariables,
                        ...pdfQuestionnaire.elements
                          .filter(
                            (y) => y?.type === "variable" && y?.variable?.name
                          )
                          .map((x) => x.variable.name),
                      ]}
                      isMandatory={false}
                      id="title-of-graph"
                      value={pdfElementInPopup?.graphTitle}
                      onChange={(e) => {
                        setPdfElementInPopup((x) => {
                          return { ...x, graphTitle: e?.target?.value };
                        });
                      }}
                    />
                  </Grid>
                </>
              )}

            {["statistique_envoi"].includes(pdfElementInPopup.type) && (
              <>
                <Grid container direction="column" wrap="nowrap" gap="15px">
                  <Grid item display="grid">
                    <MySimpleCreateButton
                      addId="button-add-questionnaire"
                      text="Ajouter un questionnaire"
                      onClick={() => {
                        setAddFormStatsPopupOpen(true);
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    {pdfElementInPopup?.statsOfSend?.list &&
                      pdfElementInPopup.statsOfSend.list.length > 0 ? (
                      <Grid container direction="row" gap="10px">
                        {pdfElementInPopup.statsOfSend.list
                          .filter((x) => x.formId != undefined)
                          .map((statsOfSend, index) => {
                            const formFounded = forms.find(
                              (x) => x?.id == statsOfSend?.formId
                            );
                            const title = formFounded?.title;

                            return (
                              <Grid item display="grid" key={index}>
                                <Grid
                                  container
                                  direction="row"
                                  wrap="nowrap"
                                  style={{
                                    border: "1px solid rgba(11, 36, 60)",
                                    backgroundColor: "rgba(247, 252, 252, 1)",
                                    boxShadow:
                                      "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                                  }}
                                  paddingLeft="7px"
                                  paddingRight="7px"
                                  borderRadius="10px"
                                >
                                  <Grid
                                    item
                                    display="grid"
                                    paddingLeft="5px"
                                    paddingRight="5px"
                                    alignItems="center"
                                  >
                                    <span
                                      style={{
                                        fontFamily: "Poppins",
                                        color: "#0B243C",
                                        fontSize: "16px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {statsOfSend.formId && title
                                        ? resizeText(title, 30)
                                        : statsOfSend.formId}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    display="grid"
                                    paddingLeft="5px"
                                    paddingRight="5px"
                                  >
                                    <div
                                      style={{
                                        height: "100%",
                                        borderLeft: "1px solid #0B243C",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item display="grid" alignItems="center">
                                    <Edit
                                      style={{
                                        color: "#0B243C",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setOpenEditFormStats(statsOfSend);
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    display="grid"
                                    paddingLeft="5px"
                                    paddingRight="5px"
                                  >
                                    <div
                                      style={{
                                        height: "100%",
                                        borderLeft: "1px solid #0B243C",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item display="grid" alignItems="center">
                                    <Delete
                                      style={{
                                        color: "#0B243C",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setPdfElementInPopup((x) => {
                                          return {
                                            ...x,
                                            statsOfSend: {
                                              list: x.statsOfSend.list.filter(
                                                (item, i) => i !== index
                                              ),
                                            },
                                          };
                                        });
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Grid>
                    ) : (
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          color: "#0B243C",
                          textAlign: "end",
                        }}
                      >{`Aucun questionnaire n'a été ajouté`}</span>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {[
              "circular_diagram",
              "stacked_bar_chart",
              "saisis_par_delegue",
            ].includes(pdfElementInPopup.type) && (
                <>
                  <Grid item display="grid">
                    <OrangeCheckBox
                      text="Choisir une période"
                      onClick={() => {
                        setPdfElementInPopup((x) => {
                          return {
                            ...x,
                            onlyResponseInPeriod:
                              pdfElementInPopup?.onlyResponseInPeriod
                                ? false
                                : true,
                          };
                        });
                      }}
                      value={
                        pdfElementInPopup?.onlyResponseInPeriod ? true : false
                      }
                    />
                  </Grid>
                  {pdfElementInPopup?.onlyResponseInPeriod && (
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        gap="10px"
                        alignItems={"center"}
                      >
                        <Grid item display="grid">
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              color: "#0B243C",
                            }}
                          >
                            Du
                          </span>
                        </Grid>
                        <Grid item display="grid">
                          <input
                            id="start-date"
                            type="date"
                            style={{
                              minWidth: "100%",
                              border: "1px solid #D3E0E0",
                              background: "#EDF2F280 0% 0% no-repeat padding-box",
                              borderRadius: "10px",
                              paddingRight: "20px",
                              paddingLeft: "20px",
                              color:
                                pdfElementInPopup && pdfElementInPopup?.startDate
                                  ? "#0B243C"
                                  : "#657273",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              height: "42px",
                            }}
                            value={
                              pdfElementInPopup?.startDate
                                ? pdfElementInPopup?.startDate
                                : undefined
                            }
                            onChange={(e) => {
                              if (e?.target?.value) {
                                setPdfElementInPopup((x) => {
                                  return {
                                    ...x,
                                    startDate: e.target.value,
                                  };
                                });
                              } else {
                                setPdfElementInPopup((x) => {
                                  return {
                                    ...x,
                                    startDate: undefined,
                                  };
                                });
                              }
                            }}
                          />
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              color: "#0B243C",
                            }}
                          >
                            au
                          </span>
                        </Grid>
                        <Grid item display="grid">
                          <input
                            id="end-date"
                            type="date"
                            style={{
                              minWidth: "100%",
                              border: "1px solid #D3E0E0",
                              background: "#EDF2F280 0% 0% no-repeat padding-box",
                              borderRadius: "10px",
                              paddingRight: "20px",
                              paddingLeft: "20px",
                              color:
                                pdfElementInPopup && pdfElementInPopup?.endDate
                                  ? "#0B243C"
                                  : "#657273",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              height: "42px",
                            }}
                            value={
                              pdfElementInPopup?.endDate
                                ? pdfElementInPopup?.endDate
                                : undefined
                            }
                            onChange={(e) => {
                              if (e?.target?.value) {
                                setPdfElementInPopup((x) => {
                                  return {
                                    ...x,
                                    endDate: e.target.value,
                                  };
                                });
                              } else {
                                setPdfElementInPopup((x) => {
                                  return {
                                    ...x,
                                    endDate: undefined,
                                  };
                                });
                              }
                            }}
                          />
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              color: "#0B243C",
                            }}
                          >
                            inclus
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            {pdfElementInPopup.type === "display_text_and_image" && (
              <Grid item display="grid">
                <FullField title="Contenu" isMandatory={false}>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid container direction="column" wrap="nowrap" gap="10px">
                      <Grid item display="grid">
                        <Grid container direction="column" wrap="nowrap">
                          <Grid item display="grid">
                            <span
                              style={{
                                color: "#0B243C",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                letterSpacing: "0px",
                                fontSize: "14px",
                              }}
                            >
                              Vous pouvez utiliser les variables ci-dessous :
                            </span>
                          </Grid>
                          <Grid item display="grid">
                            <span
                              style={{
                                color: "#0B243C",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                letterSpacing: "0px",
                                fontSize: "14px",
                              }}
                            >
                              {[
                                ...PDFVariables,
                                ...pdfQuestionnaire.elements
                                  .filter(
                                    (y) =>
                                      y?.type === "variable" &&
                                      y?.variable?.name
                                  )
                                  .map((x) => x.variable.name),
                              ]
                                .map((x) => `{${x}}`)
                                .join(" - ")}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item display="grid">
                        <MyEditor
                          defaultFontFamily="Poppins"
                          defaultFontSize="16px"
                          defaultColor="#0B243C"
                          initialContent={pdfElementInPopup.textAndImageContent}
                          onUpdate={(html) => {
                            setPdfElementInPopup((x) => {
                              return { ...x, textAndImageContent: html };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </FullField>
              </Grid>
            )}
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{
                  emptyForm: "Aucun questionnaire n'a été sélectionné.",
                  noSector: "Aucun secteur n'a été créé.",
                  notEnoughSectors: "Il faut au minimum deux secteurs.",
                  noVariableName: "Aucun nom de variable n'est renseigné",
                  noConditionVariable: "Aucune condition n'a été ajoutée",
                  noRowInArray: "Le nombre de lignes n'est pas renseigné",
                  noColumnInArray: "Le nombre de colonnes n'est pas renseigné",
                  noStackedBar: "Aucune barre empilée n'a été créée.",
                  emptyStartDate: "La date de début n'est pas renseignée.",
                  emptyEndDate: "La date de fin n'est pas renseignée.",
                  badDates: "Les dates de début et de fin sont inversées.",
                  "": "",
                }}
                errorSelector={{
                  emptyForm: "#form-create-pdf-element",
                  noSector: "#create-secteur",
                  notEnoughSectors: "#create-secteur",
                  noVariableName: "#title-of-variable",
                  noConditionVariable: "#condiiton-of-variable",
                  noRowInArray: "#nb-rows-array",
                  noColumnInArray: "#nb-columns-array",
                  noStackedBar: "#create-stacked-bar",
                  emptyStartDate: "#start-date",
                  emptyEndDate: "#start-date",
                  badDates: "#start-date",
                  "": "",
                }}
                invalidConditions={{
                  emptyForm:
                    pdfElementInPopup.type &&
                    typesNeedFormInfos.includes(pdfElementInPopup.type) &&
                    !pdfElementInPopup.formId,
                  noSector:
                    ["circular_diagram"].includes(pdfElementInPopup.type) &&
                    (!pdfElementInPopup?.secteurs ||
                      pdfElementInPopup?.secteurs?.length == 0),
                  notEnoughSectors:
                    ["circular_diagram"].includes(pdfElementInPopup.type) &&
                    pdfElementInPopup?.secteurs &&
                    pdfElementInPopup?.secteurs?.length < 2,
                  noVariableName:
                    pdfElementInPopup?.type === "variable" &&
                    !pdfElementInPopup?.variable?.name,
                  noConditionVariable:
                    pdfElementInPopup?.type === "variable" &&
                    (!pdfElementInPopup?.variable?.conditions ||
                      pdfElementInPopup?.variable?.conditions?.length == 0),
                  noRowInArray:
                    pdfElementInPopup?.type === "array" &&
                    (!pdfElementInPopup?.nbArrayRows ||
                      pdfElementInPopup?.nbArrayRows == 0),
                  noColumnInArray:
                    pdfElementInPopup?.type === "array" &&
                    (!pdfElementInPopup?.nbArrayColumns ||
                      pdfElementInPopup?.nbArrayColumns == 0),
                  noStackedBar:
                    pdfElementInPopup?.type === "stacked_bar_chart" &&
                    (!pdfElementInPopup?.stackedBars ||
                      pdfElementInPopup?.stackedBars?.length == 0),
                  emptyStartDate:
                    [
                      "stacked_bar_chart",
                      "circular_diagram",
                      "saisis_par_delegue",
                    ].includes(pdfElementInPopup?.type) &&
                    pdfElementInPopup?.onlyResponseInPeriod &&
                    !pdfElementInPopup?.startDate,
                  emptyEndDate:
                    [
                      "stacked_bar_chart",
                      "circular_diagram",
                      "saisis_par_delegue",
                    ].includes(pdfElementInPopup?.type) &&
                    pdfElementInPopup?.onlyResponseInPeriod &&
                    !pdfElementInPopup?.endDate,
                  badDates:
                    [
                      "stacked_bar_chart",
                      "circular_diagram",
                      "saisis_par_delegue",
                    ].includes(pdfElementInPopup?.type) &&
                    pdfElementInPopup?.onlyResponseInPeriod &&
                    pdfElementInPopup?.endDate < pdfElementInPopup?.startDate,
                }}
                onSendClick={
                  addElementPopupOpen
                    ? () => {
                      setPdfQuestionnaire((x) => {
                        return {
                          ...x,
                          elements: [
                            ...x.elements,
                            { ...pdfElementInPopup, _id: nanoid() },
                          ],
                        };
                      });
                      snackbar.success(`L'élément a bien été ajouté.`);
                      setPdfElementInPopup(initPdfElementInPopup);
                      setAddElementPopupOpen(false);
                      setEditElementPopupOpen(undefined);
                    }
                    : () => {
                      setPdfQuestionnaire((x) => {
                        return {
                          ...x,
                          elements: x.elements.map((x) => {
                            if (x._id === pdfElementInPopup._id) {
                              return pdfElementInPopup;
                            }
                            return x;
                          }),
                        };
                      });
                      snackbar.success(`L'élément a bien été modifié.`);
                      setPdfElementInPopup(initPdfElementInPopup);
                      setAddElementPopupOpen(false);
                      setEditElementPopupOpen(undefined);
                    }
                }
                textButton={addElementPopupOpen ? "Ajouter" : "Modifier"}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <Grid container direction="column" wrap="nowrap" display="flex">
        <Grid
          item
          display="grid"
          style={{
            paddingTop: "20px",
            paddingRight: "15px",
            paddingBottom: "10px",
          }}
        >
          <Grid container direction="column" spacing="10px" wrap="nowrap">
            <Grid item display="grid">
              <GenericTextInput
                fieldTitle="Titre du document"
                isMandatory={true}
                id="create-pdf-input-title"
                value={pdfQuestionnaire.title}
                onChange={(e) => {
                  setPdfQuestionnaire((x) => {
                    return { ...x, title: e.target.value };
                  });
                }}
              />
            </Grid>
            {/*<Grid item display="grid">
              <div
                style={{ borderTop: "2px solid #E6EBF0", borderRadius: "20px" }}
              />
              </Grid>*/}
            {/*<Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#657273",
                  textAlign: "end",
                }}
              >
                Contenu du PDF
              </span>
              </Grid>*/}
          </Grid>
        </Grid>
        <Grid item display="grid" marginTop="20px">
          <MySimpleCreateButton
            addId="button-add-element"
            text="Ajouter un élément"
            onClick={() => {
              setAddElementPopupOpen(true);
            }}
          />
        </Grid>
        {pdfQuestionnaire?.elements?.length == 0 && (
          <Grid item display="grid" marginTop="20px">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: `16px`,
                color: `#0B243C`,
                textAlign: `center`,
              }}
            >
              Le PDF est vide, commencez par ajouter un élément.
            </span>
          </Grid>
        )}
        {pdfQuestionnaire?.elements?.length > 0 && (
          <Grid item display="grid" marginTop="20px">
            <DragDropContext
              onDragEnd={(droppedItem) => {
                if (!droppedItem.destination) return;
                const itemsTmp = [...pdfQuestionnaire.elements];
                const [reorderedItem] = itemsTmp.splice(
                  droppedItem.source.index,
                  1
                );
                itemsTmp.splice(
                  droppedItem.destination.index,
                  0,
                  reorderedItem
                );
                setPdfQuestionnaire((x) => {
                  return { ...x, elements: itemsTmp };
                });
              }}
            >
              <Droppable droppableId="droppable-items">
                {(provided) => (
                  <Grid
                    container
                    direction={`column`}
                    wrap="nowrap"
                    spacing={`10px`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {pdfQuestionnaire.elements.map((element, index) => {
                      return (
                        <Draggable
                          key={element?._id}
                          draggableId={element?._id}
                          index={index}
                        >
                          {(provided) => (
                            <Grid
                              item
                              display="grid"
                              key={element?._id}
                              alignSelf={`flex-start`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                style={{
                                  border: "1px solid rgba(11, 36, 60)",
                                  backgroundColor: "rgba(247, 252, 252, 1)",
                                  boxShadow:
                                    "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                                }}
                                paddingLeft="5px"
                                paddingRight="5px"
                                borderRadius="10px"
                              >
                                <Grid
                                  item
                                  display="grid"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  {getIconByType(element?.type)}
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="5px"
                                  paddingRight="5px"
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      borderLeft: "1px solid #0B243C",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="2px"
                                  paddingRight="2px"
                                  alignItems="center"
                                  {...provided.dragHandleProps}
                                >
                                  <span
                                    style={{
                                      fontFamily: "Poppins",
                                      color: "#0B243C",
                                      fontSize: "16px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {element.type === "variable"
                                      ? `{${element.variable.name}}`
                                      : typeOptionsPDF.find(
                                        (x) => x.value === element.type
                                      ).label}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="5px"
                                  paddingRight="5px"
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      borderLeft: "1px solid #0B243C",
                                    }}
                                  />
                                </Grid>
                                <Grid item display="grid" alignItems="center">
                                  <Tooltip title="Modifier">
                                    <IconButton
                                      style={{ margin: "0", padding: "0" }}
                                      onClick={() => {
                                        setEditElementPopupOpen(element);
                                        setPdfElementInPopup(element);
                                      }}
                                    >
                                      <Edit
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  item
                                  display="grid"
                                  paddingLeft="5px"
                                  paddingRight="5px"
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      borderLeft: "1px solid #0B243C",
                                    }}
                                  />
                                </Grid>
                                <Grid item display="grid" alignItems="center">
                                  <Tooltip title="Supprimer">
                                    <IconButton
                                      style={{ margin: "0", padding: "0" }}
                                      onClick={() => {
                                        setPdfQuestionnaire((x) => {
                                          return {
                                            ...x,
                                            elements: x.elements.filter(
                                              (y) => y?._id !== element?._id
                                            ),
                                          };
                                        });
                                      }}
                                    >
                                      <Delete
                                        style={{
                                          color: "#0B243C",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        )}
        <Grid item display="grid" marginTop="20px">
          <OrangeCheckBox
            text="Envoyer le PDF par mail chaque semaine"
            onClick={() => {
              setPdfQuestionnaire((state) => {
                return {
                  ...state,
                  mailHebdoSend: {
                    ...state.mailHebdoSend,
                    isActive: state?.mailHebdoSend?.isActive ? false : true,
                    weekDay: state?.mailHebdoSend?.weekDay
                      ? state.mailHebdoSend.weekDay
                      : "monday",
                    hour: state?.mailHebdoSend?.hour
                      ? state.mailHebdoSend.hour
                      : 17,
                  },
                };
              });
            }}
            value={pdfQuestionnaire?.mailHebdoSend?.isActive}
          />
        </Grid>
        {pdfQuestionnaire?.mailHebdoSend?.isActive && (
          <>
            <Grid item display="grid" marginTop="10px">
              <Grid
                container
                direction="row"
                wrap="nowrap"
                gap="10px"
                alignItems="center"
              >
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      letterSpacing: "0px",
                      fontSize: "16px",
                      color: "#0B243C",
                    }}
                  >
                    Tous les
                  </span>
                </Grid>
                <Grid item display="grid">
                  <div style={{ width: "140px" }}>
                    <GenericSelect
                      fieldTitle=""
                      isMandatory={true}
                      options={weekDayOptions}
                      id="week-day-select"
                      placeholder=""
                      isClearable={false}
                      onChange={(e) => {
                        setPdfQuestionnaire((state) => {
                          return {
                            ...state,
                            mailHebdoSend: {
                              ...state.mailHebdoSend,
                              weekDay: e?.value,
                            },
                          };
                        });
                      }}
                      value={weekDayOptions.find(
                        (x) =>
                          x?.value == pdfQuestionnaire?.mailHebdoSend?.weekDay
                      )}
                    />
                  </div>
                </Grid>
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      letterSpacing: "0px",
                      fontSize: "16px",
                      color: "#0B243C",
                    }}
                  >
                    à
                  </span>
                </Grid>
                <Grid item display="grid">
                  <div style={{ width: "95px" }}>
                    <GenericSelect
                      fieldTitle=""
                      isMandatory={true}
                      options={hourOptions}
                      id="hour-select"
                      placeholder=""
                      isClearable={false}
                      onChange={(e) => {
                        setPdfQuestionnaire((state) => {
                          return {
                            ...state,
                            mailHebdoSend: {
                              ...state.mailHebdoSend,
                              hour: e?.value,
                            },
                          };
                        });
                      }}
                      value={hourOptions.find(
                        (x) => x?.value == pdfQuestionnaire?.mailHebdoSend?.hour
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid" marginTop="10px">
              <Grid container direction="column" wrap="nowrap" gap="10px">
                <Grid item display="grid">
                  <FullField isMandatory={true} title={`Destinataires`}>
                    <div
                      id="questionnaire-pdf-email-destinataires"
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        border: "1px solid transparent",
                      }}
                    >
                      <Creatable
                        noOptionsMessage={() =>
                          "Tapez l'adresse email du destinataire"
                        }
                        formatCreateLabel={(userInput) => `${userInput}`}
                        isMulti={true}
                        maxMenuHeight={120}
                        options={(() => {
                          const optionsTmp = [];
                          return optionsTmp;
                        })()}
                        value={
                          pdfQuestionnaire?.mailHebdoSend?.destinataires
                            ?.length > 0
                            ? pdfQuestionnaire.mailHebdoSend.destinataires.map(
                              (x) => {
                                return { label: x, value: x };
                              }
                            )
                            : []
                        }
                        placeholder="Liste des destinataires"
                        onChange={(e: any) => {
                          setPdfQuestionnaire((state) => {
                            return {
                              ...state,
                              mailHebdoSend: {
                                ...state.mailHebdoSend,
                                destinataires: e
                                  ?.filter((opt: any) =>
                                    emailValidator(opt?.label)
                                  )
                                  ?.map((x) => x?.value),
                              },
                            };
                          });
                        }}
                      />
                    </div>
                  </FullField>
                </Grid>
                <Grid item display="grid">
                  <FullField isMandatory={true} title={`Objet du mail`}>
                    <TextInput
                      id="questionnaire-pdf-subject"
                      onChange={(e) =>
                        setPdfQuestionnaire((state) => {
                          return {
                            ...state,
                            mailHebdoSend: {
                              ...state.mailHebdoSend,
                              subject: e?.target?.value,
                            },
                          };
                        })
                      }
                      value={pdfQuestionnaire?.mailHebdoSend?.subject}
                    />
                  </FullField>
                </Grid>
                <Grid item display="grid">
                  <FullField isMandatory={true} title={`Contenu du mail`}>
                    <Grid container direction="column" wrap="nowrap">
                      <Grid item display="grid" paddingTop="5px">
                        <MultilineInput
                          id="questionnaire-pdf-content"
                          maxLength={50000}
                          onChange={(e) =>
                            setPdfQuestionnaire((state) => {
                              return {
                                ...state,
                                mailHebdoSend: {
                                  ...state.mailHebdoSend,
                                  content: e,
                                },
                              };
                            })
                          }
                          value={pdfQuestionnaire?.mailHebdoSend?.content}
                          height={"80px"}
                        />
                      </Grid>
                    </Grid>
                  </FullField>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item display={`grid`}>
          <GenericSendButton
            textButton={isCreate ? "Créer" : "Mettre à jour"}
            errorMessages={{
              noTitle: "Aucun titre n'est renseigné.",
              noElement: "Aucun élément n'a été ajouté.",
              noDestinataire: "Aucun destinataire n'a été ajouté.",
              noSubject: "Aucun objet n'a été renseigné.",
              noContent: "Aucun contenu n'a été renseigné.",
              "": "",
            }}
            errorSelector={{
              noTitle: `#create-pdf-input-title`,
              noElement: "#button-add-element",
              noDestinataire: "#questionnaire-pdf-email-destinataires",
              noSubject: "#questionnaire-pdf-subject",
              noContent: "#questionnaire-pdf-content",
              "": "",
            }}
            invalidConditions={{
              noTitle: !pdfQuestionnaire.title,
              noElement: pdfQuestionnaire.elements.length == 0,
              noSubject:
                pdfQuestionnaire?.mailHebdoSend?.isActive &&
                !pdfQuestionnaire?.mailHebdoSend?.subject,
              noContent:
                pdfQuestionnaire?.mailHebdoSend?.isActive &&
                !pdfQuestionnaire?.mailHebdoSend?.content,
              noDestinataire:
                pdfQuestionnaire?.mailHebdoSend?.isActive &&
                (!pdfQuestionnaire?.mailHebdoSend?.destinataires ||
                  pdfQuestionnaire?.mailHebdoSend?.destinataires?.length == 0),
            }}
            onSendClick={
              isCreate
                ? () => {
                  makeAPIRequest(
                    `post`,
                    `/forms/pdf/create`,
                    pdfQuestionnaire,
                    `v3`
                  )
                    .then((res) => {
                      setIsSuccessCreatePopup(true);
                    })
                    .catch((err) => {
                      snackbar.error(`Impossible de créer ce PDF.`, err);
                    });
                }
                : () => {
                  makeAPIRequest(
                    `post`,
                    `/forms/pdf/${pdfQuestionnaire?._id}/update`,
                    pdfQuestionnaire,
                    `v3`
                  )
                    .then((res) => {
                      setIsSuccessCreatePopup(true);
                    })
                    .catch((err) => {
                      snackbar.error(
                        `Impossible de mettre à jour ce PDF.`,
                        err
                      );
                    });
                }
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

const CreateQuestionnairePDFBox: React.FC<any> = (props) => {
  const { isCreate, isEdit } = props;

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="1000px"
      width="95vw"
      minWidth="320px"
      style={{
        background: "rgb(247, 252, 252, 0.55)",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <BoxTitle first={isCreate ? "Créer" : "Modifier"} second="un PDF" />
          </Grid>
          <Grid item display="grid" paddingTop="15px">
            <CreateQuestionnairePDFContent {...props} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CreateOrEditPDFQuestionnaire: React.FC<any> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isCreate, isEdit } = props;

  React.useEffect(() => {
    dispatch(getQuestionnaires());
  }, [dispatch]);

  return (
    <>
      <FullPage>
        <Grid container direction="column" padding="10px" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/home?f=questionnaires&tab=pdf")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <CreateQuestionnairePDFBox {...props} />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default CreateOrEditPDFQuestionnaire;
