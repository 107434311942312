import * as React from 'react';
import UtilsClass from '../UtilsClass';

const useSpecialities = () => {
  const [utils, setUtils] = React.useState(null);
  const [specialitiesOptions, setSpecialitiesOptions] = React.useState([]);

  React.useEffect(() => {
    async function initUtils() {
      const newUtils = new UtilsClass();
      await newUtils.init();
      setUtils(newUtils);
    }
    initUtils();
  }, []);

  React.useEffect(() => {
    setSpecialitiesOptions(utils?.getSpecialitiesOptionsSorted());
  }, [utils]);

  return ([specialitiesOptions]);
};

export default useSpecialities;
