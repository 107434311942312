import { Grid } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import FullField from "../../../../Fields/FullField";
import MySelect from "../../../../Library/Select/MySelect";
import useAppSelector from "../../../../../redux/useAppSelector";

const DisplaySimpleCheckValidation = () => {
  const fieldCopy = useAppSelector(
    (state) => state.questionnaireReducer.fieldCopy
  );
  const dispatch = useDispatch();

  return (
    <Grid container direction="row" spacing='10px' wrap='nowrap'>
      <Grid item display="grid">
        <FullField title="Cases à cocher" isMandatory={true}>
          <MySelect
            noOptionsMessage={() => ''}
            maxMenuHeight={'120px'}
            options={[
              { value: "Au minimum", label: "Au minimum" },
              { value: "Au maximum", label: "Au maximum" },
              { value: "Exactement", label: "Exactement" },
            ]}
            value={{
              value: fieldCopy?.validation?.type,
              label: fieldCopy?.validation?.type,
            }}
            onChange={(e) => {
              const fieldCopyTmp = { ...fieldCopy };
              const validationTmp = { ...fieldCopyTmp.validation };

              validationTmp.type = e?.value;
              fieldCopyTmp.validation = validationTmp;
              dispatch(setFieldCopy(fieldCopyTmp));
            }}
            isClearable={false}
          />
        </FullField>
      </Grid>
      <Grid item display="grid" alignSelf='flex-end'>
        <MySelect
          noOptionsMessage={() => ''}
          maxMenuHeight={'120px'}
          options={
            (() => {
              const optionsTmp = [];

              for (let i = 0; i < fieldCopy?.option?.length; i++) {
                optionsTmp.push({label: i + 1, value: i + 1});
              }
              return (optionsTmp);
            })()
          }
          value={{label: fieldCopy?.validation?.number, value: fieldCopy?.validation?.number}}
          onChange={(e) => {
            const fieldCopyTmp = { ...fieldCopy };
            const validationTmp = { ...fieldCopyTmp.validation };

            validationTmp.number = e?.value;
            fieldCopyTmp.validation = validationTmp;
            dispatch(setFieldCopy(fieldCopyTmp));
          }}
          isClearable={false}
        />
      </Grid>
    </Grid>
  );
};

export default DisplaySimpleCheckValidation;
