import useAppSelector from "../../../../redux/useAppSelector";
import LastConseilComponent from "../../../LastConseilComponent";
import FilterContainer from "../../FilterContainer/FilterContainer";
import * as React from 'react';

const RecentsTabContent: React.FC<any> = (props) => {
  const activeTab = useAppSelector(state => state.tabsReducer.activeTab);
  return (
    <div className="phantom-gradient">
      <div className="phantom-gradient">
        {activeTab === 'recents' &&
          <FilterContainer
            tab='recents'
            filterKey={props.filterKey}
            clearFilters={props.clearFilters}
            specialities={props.specialites}
            handleSelectChange={props.handleSelectChange} />
        }
        <LastConseilComponent
          filterSpecialities=""
          filterConseil=""
          {...props} />
      </div>
    </div>
  );
}

export default RecentsTabContent;
