import FullField from "../../../Fields/FullField";
import * as React from "react";
import HandleErrors from "../../../Library/Errors/HandleErrors";
import { IField } from "../../../../types/FormTypes";
import { Grid } from "@mui/material";
import TextInput from "../../../Library/Inputs/TextInput";
import isValidPhoneNumber from "../../../../utils/isValidPhoneNumber";

const DisplayAttributPatient: React.FC<any> = (props) => {
  const INVALID_PHONE_ERROR = "Le numéro de téléphone est invalide, il doit contenir 10 chiffres et commencer par 06 ou 07.";
  const [isErrorPhone, setIsErrorPhone] = React.useState(false);
  const [errorMessagePhone, setErrorMessagePhone] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const field: IField = props.field;
  const {
    sectionIndex,
    justifyContent,
    fieldIndex,
    disableError,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  return (
    <>
      {["birth_date"].includes(field?.attributPatientType) && (
        <FullField
          isQuestionnaire={true}
          title={field?.title}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={isError && !disableError}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessage}
          >
            <input
              id={"field" + field?.id}
              //isError={isError && !disableError}
              type="date"
              style={{
                maxWidth: "200px",
                border: "1px solid #D3E0E0",
                background: "#EDF2F280 0% 0% no-repeat padding-box",
                borderRadius: "10px",
                paddingRight: "20px",
                paddingLeft: "20px",
                color:
                  field?.value && field?.value?.length > 0
                    ? "#0B243C"
                    : "#657273",
                fontFamily: "Poppins",
                fontSize: "16px",
                height: "42px",
              }}
              value={field?.value ? field?.value : ""}
              onChange={(e) => {
                const questionnaireDataTmp = { ...questionnaireData };

                questionnaireDataTmp.sections[sectionIndex].fields[
                  fieldRowIndex
                ][fieldIndex].value = e.target.value;
                setQuestionnaireData(questionnaireDataTmp);
              }}
              onFocus={(e) => {
                if (!field?.isMandatory) return;
                if (isError && !disableError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
              onBlur={(e) => {
                if (!field?.isMandatory) return;
                if (!e.target.value) {
                  if (!isError && !disableError) {
                    setErrorMessage("Ce champ est obligatoire.");
                    setIsError(true);
                  }
                } else {
                  if (isError && !disableError) {
                    setErrorMessage("");
                    setIsError(false);
                  }
                }
              }}
            />
          </HandleErrors>
        </FullField>
      )}
      {["phone"].includes(field?.attributPatientType) && (
        <FullField
          isQuestionnaire={true}
          title={field?.title}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={isErrorPhone}
            isMandatory={true}
            errorMessage={errorMessagePhone}
          >
            <Grid container direction="column" wrap="nowrap">
              <Grid item display="grid">
                <TextInput
                  id={"field" + field?.id + "phone-patient"}
                  isError={isErrorPhone}
                  onChange={(e) => {
                    const questionnaireDataTmp = { ...questionnaireData };

                    questionnaireDataTmp.sections[sectionIndex].fields[
                      fieldRowIndex
                    ][fieldIndex].value = e.target.value;
                    setQuestionnaireData(questionnaireDataTmp);
                  }}
                  onFocus={(e) => {
                    if (
                      isErrorPhone &&
                      errorMessagePhone === INVALID_PHONE_ERROR
                    ) {
                      setErrorMessagePhone("");
                      setIsErrorPhone(false);
                      return;
                    }
                    if (!field?.isMandatory) return;
                    if (isError && !disableError) {
                      setErrorMessagePhone("");
                      setIsErrorPhone(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (e?.target?.value) {
                      const isValidPhone = isValidPhoneNumber(e.target.value);
                      if (!isValidPhone) {
                        setErrorMessagePhone(INVALID_PHONE_ERROR);
                        setIsErrorPhone(true);
                      } else {
                        if (errorMessagePhone === INVALID_PHONE_ERROR) {
                          setErrorMessagePhone("");
                          setIsErrorPhone(false);
                        }
                      }
                    }
                    if (!field?.isMandatory) return;
                    if (!e.target.value) {
                      if (!isErrorPhone && !disableError) {
                        setErrorMessagePhone("Ce champ est obligatoire.");
                        setIsErrorPhone(true);
                      }
                    } else {
                      if (isErrorPhone && !disableError) {
                        setErrorMessagePhone("");
                        setIsErrorPhone(false);
                      }
                    }
                  }}
                  value={field?.value ? field?.value : ""}
                />
              </Grid>
            </Grid>
          </HandleErrors>
        </FullField>
      )}
      {["firstname", "lastname"].includes(field?.attributPatientType) && (
        <FullField
          isQuestionnaire={true}
          title={field?.title}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={isError && !disableError}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessage}
          >
            <Grid container direction="column" wrap="nowrap">
              <Grid item display="grid">
                <TextInput
                  id={"field" + field?.id}
                  isError={isError && !disableError}
                  onChange={(e) => {
                    const questionnaireDataTmp = { ...questionnaireData };

                    questionnaireDataTmp.sections[sectionIndex].fields[
                      fieldRowIndex
                    ][fieldIndex].value = e.target.value;
                    setQuestionnaireData(questionnaireDataTmp);
                  }}
                  onFocus={(e) => {
                    if (!field?.isMandatory) return;
                    if (isError && !disableError) {
                      setErrorMessage("");
                      setIsError(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (!field?.isMandatory) return;
                    if (!e.target.value) {
                      if (!isError && !disableError) {
                        setErrorMessage("Ce champ est obligatoire.");
                        setIsError(true);
                      }
                    } else {
                      if (isError && !disableError) {
                        setErrorMessage("");
                        setIsError(false);
                      }
                    }
                  }}
                  value={field?.value ? field?.value : ""}
                />
              </Grid>
            </Grid>
          </HandleErrors>
        </FullField>
      )}
    </>
  );
};

export default DisplayAttributPatient;
