import { Form, Formik, Field } from "formik";
import { ReactComponent as IconSection } from "../../../assets/IconSection.svg";
import * as React from "react";
import OrangeCheckBox from "../../Fields/CheckBox/OrangeCheckBox";
import { Grid, IconButton, Tooltip } from "@mui/material";
import FullField from "../../Fields/FullField";

const getActualYYYYDDMM = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

interface IProps {
  setNow: any;
  now: boolean;
  setIsCheck: any;
  isCheck: boolean;
  setNumberDays: any;
  numberDays: any;
  setDateTextLimit: any;
  dateTextLimit: any;
  hours: any;
  setHours: any;
  dateText: any;
  dateInput: any;
  setDateText: any;
  setdateInput: any;
  handleSumbit: any;
  setHoursText: any;
  hoursText: any;
  showError: boolean;
  isInRecap?: boolean;
  error: string;
  isCheckVisible: boolean;
}

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

const TriggerDateContainer = (props: IProps) => {
  const {
    setNow,
    now,
    setIsCheck,
    isCheck,
    setNumberDays,
    numberDays,
    setDateTextLimit,
    dateTextLimit,
    setHours,
    setdateInput,
    hoursText,
    setHoursText,
    dateText,
    dateInput,
    setDateText,
    handleSumbit,
    showError,
    error,
    hours,
    isCheckVisible,
    isInRecap,
  } = props;

  React.useEffect(() => {
    if (isInRecap === true) {
      if (!now && (!hoursText || !hours)) {
        setHoursText("10:00");
        setHours(10);
      }
      if (!now && (!dateInput || !dateText)) {
        const nowDate = getActualYYYYDDMM();
        setdateInput(nowDate);
        setDateText(nowDate);

      }
    }
  }, [now, hoursText, hours, isInRecap, dateInput, dateText]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name == "now") {
      setNow(true);
    }
    if (name == "notNow") {
      setNow(false);
      setDateText("");
      setHoursText("");
      setHours(undefined);
      setdateInput(undefined);
    }
    if (name == "hours_send") {
      if (newValue.split(":").length > 2) {
        newValue = newValue.slice(0, -1);
      }
      if (newValue.length === 2 && newValue[1] === ":") {
        newValue = "0" + newValue;
      }
      if (newValue[0] === ":") {
        newValue = "";
      }
      if (
        newValue.length > 0 &&
        !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":"].includes(
          newValue[newValue.length - 1]
        )
      ) {
        newValue = newValue.slice(0, -1);
      }
      if (newValue.length === 1 && !["0", "1", "2"].includes(newValue[0])) {
        newValue = "0" + newValue[0];
      }
      if (
        newValue.length === 2 &&
        !["0", "1"].includes(newValue[0]) &&
        ["4", "5", "6", "7", "8", "9"].includes(newValue[1])
      ) {
        newValue = newValue.slice(0, -1);
      }
      if (newValue.length === 2 && newValue.includes(":")) {
        newValue = "0" + newValue;
      }
      if (newValue.length === 3 && !newValue.includes(":")) {
        newValue = newValue.substr(0, 2) + ":" + newValue[2];
      }
      if (
        newValue.length === 4 &&
        !["0", "1", "2", "3", "4", "5"].includes(newValue[3])
      ) {
        newValue = newValue.substr(0, 3) + "0" + newValue[3];
      }
      if (newValue.length > 5) {
        newValue = newValue.substring(0, 5);
      }
      if (newValue.includes(":")) {
        const parts = newValue.split(":");
        setHours(Math.round(Number(parts[0]) + Number(parts[1]) / 60));
      } else {
        setHours(newValue);
      }
      setNow(false);
      setHoursText(newValue);
    }
    if (name == "date_send") {
      setNow(false);
      setDateText(value);
      setdateInput(value);
    } else if (name == "dateLimit_send") {
      setDateTextLimit(value);
    }
  };

  return (
    <Grid item>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={
          isInRecap === true
            ? {}
            : {
                paddingTop: "13px",
                paddingLeft: "19px",
                paddingRight: "19px",
                paddingBottom: "20px",
              }
        }
      >
        {isInRecap === true && (
          <Grid item display="grid">
            <FullField isMandatory={true} title="Date de déclenchement">
              <></>
            </FullField>
          </Grid>
        )}
        {isInRecap !== true && (
          <Grid item className="text-send" style={{ marginBottom: "16px" }}>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid item display="flex">
                <IconSection />
              </Grid>
              <Grid item display="flex">
                <span style={{ marginLeft: "11px" }}>
                  Date de déclenchement
                </span>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item className="send-container-conseil description-in-tabs">
          <Formik
            initialValues={{ isNow: now === true ? "yes" : "no" }}
            enableReinitialize
            onSubmit={handleSumbit}
          >
            <Form onChange={(e) => handleInputChange(e)}>
              <Grid
                item
                className="send_date_div"
                style={{
                  marginRight: "5px",
                  marginLeft: "0px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                <Grid container direction="row">
                  <Grid
                    item
                    display="flex"
                    className="item4"
                    style={{
                      marginRight: "9px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Field
                      name="isNow"
                      type="radio"
                      value="yes"
                      onClick={() => {
                        setNow((x) => !x);
                        setDateText("");
                        setHoursText("");
                        setHours(undefined);
                        setdateInput(undefined);
                      }}
                    />
                  </Grid>
                  <Grid item display="flex">
                    <span>Maintenant</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                className="send_date_div"
                style={{
                  marginRight: "5px",
                  marginLeft: "0px",
                  marginTop: "15px",
                  marginBottom: "0px",
                }}
              >
                <Grid container direction="row">
                  <Grid
                    item
                    display="flex"
                    className="item1"
                    style={{
                      marginRight: "9px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Field
                      name="isNow"
                      type="radio"
                      value="no"
                      onClick={() => setNow(!now)}
                    />
                  </Grid>
                  <Grid item display="flex" style={{ alignItems: "center" }}>
                    <span>Le</span>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    className="item2"
                    paddingRight="13px"
                    paddingLeft="13px"
                  >
                    <input
                      className="border-animation"
                      type="date"
                      name="date_send"
                      id="trigger-date-input"
                      style={{
                        background: "#EDF2F2 0% 0% no-repeat padding-box",
                        border: "1px solid #D3E0E0",
                        borderRadius: "3px",
                        opacity: 1,
                        width: "140px",
                        height: "32px",
                        textAlign: "center",
                        font: "normal normal normal 14px/21px Poppins",
                        color: "#657273",
                        borderColor:
                          showError && error === "InvalidDateAndNeeded"
                            ? "#FF3100"
                            : null,
                      }}
                      min={"03/07/2022"}
                      value={dateText}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item display="flex" style={{ alignItems: "center" }}>
                    <span>à</span>
                  </Grid>
                  <Grid
                    item
                    className="item2"
                    display="flex"
                    paddingRight="13px"
                    paddingLeft="13px"
                  >
                    <input
                      className="border-animation"
                      type="time"
                      name="hours_send"
                      id="trigger-hours-input"
                      style={{
                        background: "#EDF2F2 0% 0% no-repeat padding-box",
                        border: "1px solid #D3E0E0",
                        borderRadius: "3px",
                        opacity: 1,
                        width: "140px",
                        height: "32px",
                        textAlign: "center",
                        font: "normal normal normal 14px/21px Poppins",
                        color: "#657273",
                        borderColor:
                          showError && error === "InvalidHoursAndNeeded"
                            ? "#FF3100"
                            : null,
                      }}
                      value={hoursText}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {isCheckVisible && (
                <Grid
                  item
                  display="flex"
                  style={{
                    marginRight: "4px",
                    marginLeft: "-2px",
                    marginTop: "15px",
                    marginBottom: "0px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    style={{
                      marginRight: "9px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid item display="flex" paddingRight="7px">
                      <OrangeCheckBox
                        value={isCheck}
                        onClick={() => {
                          setIsCheck((e) => !e);
                        }}
                      />
                    </Grid>
                    <>
                      <Grid item display="flex" alignItems="center">
                        Puis tous les
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        paddingRight="8px"
                        paddingLeft="8px"
                      >
                        <input
                          className="border-animation"
                          name="number_days"
                          id="false-days"
                          placeholder="1"
                          style={{
                            background: "#EDF2F2 0% 0% no-repeat padding-box",
                            border: "1px solid #D3E0E0",
                            borderRadius: "3px",
                            opacity: 1,
                            width: "35px",
                            height: "32px",
                            textAlign: "center",
                            font: "normal normal normal 14px/21px Poppins",
                            color: "#657273",
                            borderColor:
                              showError && error === "ZeroDays"
                                ? "#FF3100"
                                : null,
                          }}
                          value={numberDays}
                          onInput={(e) => {
                            if (e.currentTarget.value.length === 0) {
                              setNumberDays("");
                              return;
                            }
                            if (
                              !parseInt(
                                e.currentTarget.value[
                                  e.currentTarget.value.length - 1
                                ],
                                10
                              ) &&
                              e.currentTarget.value[
                                e.currentTarget.value.length - 1
                              ] != "0"
                            ) {
                              return;
                            }
                            setNumberDays(e.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item display="grid" alignItems="center">
                        <span>jours jusqu'au</span>
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        className="item2"
                        paddingLeft="25px"
                        paddingTop="5px"
                      >
                        <input
                          className="border-animation"
                          type="date"
                          name="dateLimit_send"
                          id="invalid-limit-date"
                          style={{
                            background: "#EDF2F2 0% 0% no-repeat padding-box",
                            border: "1px solid #D3E0E0",
                            borderRadius: "3px",
                            opacity: 1,
                            width: "140px",
                            height: "32px",
                            textAlign: "center",
                            font: "normal normal normal 14px/21px Poppins",
                            color: "#657273",
                            borderColor:
                              showError && error === "InvalidDateLimit"
                                ? "#FF3100"
                                : null,
                          }}
                          min={"03/07/2022"}
                          value={dateTextLimit}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              )}
            </Form>
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TriggerDateContainer;
