import React, {useEffect, useState} from 'react';
import {Link, RouteComponentProps} from "react-router-dom";
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect';

// import {useHistory,} from "react-router-dom";
import AuthService from "../services/auth.services";
import CancellablePopup from "./Library/Popups/CancellablePopup";
import { getActualTimeNumber } from './NavbarComponent';

interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

const GetProSanteConnect: React.FC<Props> = (props) => {
    const [stayOnPage, setStayOnPage] = useState<boolean>(false);

  const WebProSanteConnect: React.FC<Props> = (props) => {
    const path: string = props.location.pathname;
    const dataArray = props.location.pathname.slice(5).split("&");
    const token = dataArray[0].split("=")[1];
    const email = dataArray[1].split("=")[1];
    const user_nicename = dataArray[2].split("=")[1];
    const user_displayname = dataArray[3].split("=")[1];
    const user_id = dataArray[4].split("=")[1];
    const user = {
      token: token,
      user_display_name: user_displayname,
      user_email: email,
      user_id: user_id,
      user_nicename: user_nicename,
    };
    useEffect(() => {
      getData();
    });
    console.log("token", token);

    const getData = () => {
      localStorage.setItem("lastConnection", getActualTimeNumber().toString());
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("user_id", user_id);
      props.history.push("/home");
      window.location.reload();
    };

    return (
      <div>
        <h1>Connexion</h1>
      </div>
    );
  }

  const MobileProSanteConnect: React.FC<Props> = (props) => {
    const [showPopup, setShowPopup] = useState<boolean>(true);
    let path: string = props.location.pathname;
    path = path.startsWith("/") ? path.substring(1) : path;

    return (
      <>
        {showPopup && (
          <CancellablePopup
            firstTitle="CertiPair"
            secondTitle="Application"
            isLoading={false}
            messages={[
              "Voulez-vous aller sur l'application CertiPair ?",
            ]}
            confirmButtonText="Oui"
            cancelButtonText="Non"
            onCancel={() => {
              setStayOnPage(true)
              setShowPopup(false);
            }}
            onConfirm={() => window.location.href = "certipair://" + path}
          />
        )}
        {stayOnPage && (
          <WebProSanteConnect {...props} />
        )}
      </>
    );
  }

  return (
    <>
      {isMobile && !stayOnPage ? <MobileProSanteConnect {...props} /> : <WebProSanteConnect {...props} />}
    </>
  );
};

export default GetProSanteConnect;
