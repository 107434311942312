import FullField from "../FullField";
import * as React from 'react';
import TextInput from "../../Library/Inputs/TextInput";

interface IPatientFirstNameField {
  patientFirstName: string,
  onChange: any,
  itemBorder?: string,
}

const PatientFirstNameField = (props: IPatientFirstNameField) => {
  const {
    patientFirstName,
    onChange,
    itemBorder,
  } = props;

  return (
    <FullField title='Prénom' isMandatory={false}>
      <TextInput
        className='border-animation'
        id='patient-firstname-input'
        border={itemBorder}
        value={patientFirstName}
        onChange={(e) => onChange(e)} />
    </FullField>
  );
};

export default PatientFirstNameField;