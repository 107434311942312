import * as React from "react";
import { Grid } from "@mui/material";
import { ReactComponent as IconCreate } from "../../../../assets/IconCreate.svg";

const AddButton: React.FC<{
  onClick?: any;
  displayText?: any;
  text?: any;
  isActive?: any;
  addId?: any;
  disabled?: any;
  displayIconCreate?: any;
  smallVersion?: boolean;
}> = (props: any) => {
  const {
    onClick,
    displayText,
    text,
    isActive,
    addId,
    disabled,
    smallVersion,
    displayIconCreate,
  } = props;

  return (
    <button
      id={addId}
      style={{
        boxShadow: disabled ? "none" : "2px 6px 10px #0F213467",
        paddingTop: smallVersion ? "5px" : "7px",
        paddingBottom: smallVersion ? "5px" : "7px",
        paddingLeft: smallVersion ? "8px" : "10px",
        maxWidth: "330px",
        paddingRight: smallVersion ? "8px" : "10px",
        borderRadius: "18px",
        opacity: isActive ? 0.5 : 1,
        background: disabled
          ? "#8d9899"
          : "#0B243C 0% 0% no-repeat padding-box",
        letterSpacing: "0px",
        color: "#FFFFFF",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "12px",
        fontFamily: "Poppins",
        justifySelf: "flex-end",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      disabled={disabled}
      onClick={
        disabled
          ? () => {
              return;
            }
          : onClick
      }
    >
      <Grid item display="grid">
        <Grid
          container
          spacing="7px"
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {displayIconCreate && (
            <Grid item display="grid">
              <IconCreate
                style={{
                  width: smallVersion ? "11px" : undefined,
                  height: smallVersion ? "11px" : undefined,
                }}
              />
            </Grid>
          )}
          {displayText && (
            <Grid item display="grid">
              {text}
            </Grid>
          )}
        </Grid>
      </Grid>
    </button>
  );
};

AddButton.defaultProps = {
  isActive: false,
  disabled: false,
  displayIconCreate: true,
};

export default AddButton;
