import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import makeAPIRequest from "../utils/makeAPIRequest";
import BoxTitle from "../components/Library/Box/BoxTitle";
import Creatable from "react-select/creatable";
import useUsersList from "../utils/hooks/useUsersList";
import {
  InfoRounded,
  RestartAltRounded,
} from "@mui/icons-material";
import FormPopup from "../components/FormPopup/FormPopup";
import FormPopupHeader from "../components/FormPopup/FormPopupHeader";
import formatPhone from "../utils/format/formatPhone";
import { MoreOrLessButtons } from "./ChatbotLexPage";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/snackbarReducer";
import { useOpenSnackbar } from "./CreateOrEditChatbotLexPage";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import useAppSelector from "../redux/useAppSelector";

const LIMIT_ITEM_BY_REQUEST = 1000;

const reasonOptions = [
  {
    label: "Destinataire blacklisté",
    value: 3999,
  },
  {
    label: "Destinataire invalide",
    value: 3998,
  },
  {
    label: "Erreur autre",
    value: 3599,
  },
  {
    label: "Erreur non routable",
    value: 3560,
  },
  {
    label: "Erreur permanente roaming",
    value: 3527,
  },
  {
    label: "Erreur permanente portabilité",
    value: 3526,
  },
  {
    label: "Erreur permanente contenu",
    value: 3525,
  },
  {
    label: "Erreur permanente anti spam",
    value: 3524,
  },
  {
    label: "Erreur permanente téléphone",
    value: 3523,
  },
  {
    label: "Erreur permanente absence",
    value: 3522,
  },
  {
    label: "Erreur permanente opérateur",
    value: 3521,
  },
  {
    label: "Erreur temporaire portabilité",
    value: 3504,
  },
  {
    label: "Erreur temporaire téléphone",
    value: 3503,
  },
  {
    label: "Erreur temporaire absence",
    value: 3502,
  },
  {
    label: "Erreur temporaire opérateur",
    value: 3501,
  },
  {
    label: "Indéfini",
    value: 999,
  },
  {
    label: "Non lu",
    value: 101,
  },
  {
    label: "SMS introuvable",
    value: 61,
  },
  {
    label: "Non délivré",
    value: 50,
  },
  {
    label: "Message trop long",
    value: 38,
  },
  {
    label: "Message expiré",
    value: 37,
  },
  {
    label: "Erreur message",
    value: 36,
  },
  {
    label: "Erreur réception",
    value: 35,
  },
  {
    label: "Erreur routage",
    value: 34,
  },
  {
    label: "Crédit insuffisant",
    value: 33,
  },
  {
    label: "Rejeté",
    value: 22,
  },
  {
    label: "Non délivrable",
    value: 21,
  },
  {
    label: "Erreur interne",
    value: 2,
  },
];

export const formatDate = (
  date: string,
  separator: "line_return" | "space" | "à" = "line_return"
): string => {
  if (!date) {
    return "";
  }
  const splited = date.split("T");
  if (splited.length !== 2) {
    return "";
  }
  const YYYYDDMM = splited[0].split("-");
  if (YYYYDDMM.length !== 3) {
    return "";
  }
  const YYYY = YYYYDDMM[0];
  const MM = YYYYDDMM[1];
  const DD = YYYYDDMM[2];
  const HOUR_MIN_SEC = splited[1].split(":");
  if (HOUR_MIN_SEC.length < 3) {
    return "";
  }
  const HOUR = HOUR_MIN_SEC[0];
  const MIN = HOUR_MIN_SEC[1];
  const SEC = HOUR_MIN_SEC[2];
  if (SEC.length < 2) {
    return "";
  }
  const separatorConverter = {
    line_return: "\n",
    space: " ",
    à: " à ",
  };
  return `${DD}/${MM}/${YYYY}${
    separatorConverter?.[separator] ? separatorConverter[separator] : " "
  }${HOUR}:${MIN}:${SEC[0]}${SEC[1]}`;
};

export const DisplayTitlesInTable: React.FC<{ titles: string[] }> = (props) => {
  return (
    <>
      {props.titles.map((title, index) => {
        return (
          <span className="table-grid-title" key={index}>
            {title}
          </span>
        );
      })}
    </>
  );
};

interface SMSType {
  id: number;
  user_id: number;
  target: string;
  message_id: number | null;
  free_text: string;
  scheduled_date: string;
  sended_date: string;
  smsmode_status: number;
  smsmode_id: string;
  reason?: string;
  reason_details: string;
  from_resend?: 0 | 1;
  already_resend?: 0 | 1;
}

interface ContentType {
  id: number;
  content: string;
}

export const downloadCSV = (
  table: Array<Array<string>>,
  fileName = "export"
) => {
  let csv = "";
  for (let i = 0; i < table.length; i++) {
    for (let j = 0; j < table[i].length; j++) {
      csv += `"${table[i][j]}"`;
      if (j === table[i].length - 1) {
        csv += "\n";
      } else {
        csv += ";";
      }
    }
  }

  const universalBOM = "\uFEFF";
  const element = window.document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + csv)
  );
  element.setAttribute("download", `${fileName}`.replaceAll(" ", "-"));
  window.document.body.appendChild(element);
  element.click();
};

const AdminGestionIncidentsBox: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const snackbarUse = useOpenSnackbar();
  const { usersList } = useUsersList();
  const [isLoading, setIsLoading] = React.useState(false);
  const [usersSelected, setUsersSelected] = React.useState([]);
  const [reasonSelected, setReasonSelected] = React.useState<any[]>(undefined);
  const [forbiddenResend, setForbiddenResend] = React.useState<number[]>([]);
  const [allSMS, setAllSMS] = React.useState<SMSType[]>([]);
  const [allContents, setAllContents] = React.useState<ContentType[]>([]);
  const [maxItem, setMaxItem] = React.useState<number>(10);
  const [totalSMS, setTotalSMS] = React.useState<number>(0);
  const [openDetail, setOpenDetail] = React.useState<SMSType>(undefined);
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const [hideResendIncidents, setHideResendIncidents] =
    React.useState<boolean>(false);
  const [hideAlreadyResend, setHideAlreadyResend] =
    React.useState<boolean>(false);

  const allSMSFiltered = allSMS.filter((sms) => {
    if (hideResendIncidents) {
      if (sms?.from_resend == 1) {
        return false;
      }
    }
    if (hideAlreadyResend) {
      if (sms?.already_resend == 1) {
        return false;
      }
    }
    return true;
  });

  const errorCallback = () => {
    dispatch(
      openSnackbar({
        message: "Impossible de récupérer la liste des incidents",
        type: "error",
        duration: 3000,
      })
    );
    setIsLoading(false);
  };

  const successCallback = () => {
    setIsLoading(false);
  };

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest(
      "post",
      "/sms_failure/list",
      {
        users:
          usersSelected.length > 0
            ? usersSelected.map((u) => u.value)
            : undefined,
        reasons:
          reasonSelected?.length > 0
            ? reasonSelected.map((x) => x?.value)
            : undefined,
        limitResponse: LIMIT_ITEM_BY_REQUEST,
        offset: 0,
      },
      "v3"
    )
      .then((res: any) => {
        const x = res?.data?.result?.allSMS;
        const y = res?.data?.result?.allContents;
        const total = res?.data?.result?.total;
        if (Array.isArray(x) && Array.isArray(y)) {
          setAllSMS(x);
          setAllContents(y);
          setTotalSMS(total);
          successCallback();
        } else {
          errorCallback();
        }
      })
      .catch((err) => {
        errorCallback();
      });
  }, [usersSelected, reasonSelected]);

  const getUserEmailFromUserId = (user_id?: string | number): string => {
    if (!user_id || !usersList) {
      return "";
    }
    const userFounded = usersList?.find((user) => user?.user_id == user_id);
    if (userFounded) {
      return userFounded?.email;
    }
    return "";
  };

  return (
    <>
      {openDetail && (
        <FormPopup>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="50px"
          >
            <Grid item display="grid">
              <FormPopupHeader
                firstTitle={"Détails"}
                secondTitle={"incident"}
                onClose={() => {
                  setOpenDetail(undefined);
                }}
              />
            </Grid>
            <Grid item display="grid" paddingTop="25px">
              <Grid
                container
                direction="column"
                spacing="5px"
                wrap="nowrap"
                style={{
                  fontSize: "16px",
                  color: "#0B243C",
                  fontFamily: "Poppins",
                }}
              >
                {openDetail.user_id && (
                  <Grid item display="grid">
                    <span>
                      Utilisateur : {getUserEmailFromUserId(openDetail.user_id)}
                    </span>
                  </Grid>
                )}
                {openDetail.scheduled_date && (
                  <Grid item display="grid">
                    <span>
                      Date envoi :{" "}
                      {formatDate(openDetail.scheduled_date, "space")}
                    </span>
                  </Grid>
                )}
                {openDetail.sended_date && (
                  <Grid item display="grid">
                    <span>
                      Date erreur :{" "}
                      {formatDate(openDetail.sended_date, "space")}
                    </span>
                  </Grid>
                )}
                {openDetail.target && (
                  <Grid item display="grid">
                    <span>Destinataire : {formatPhone(openDetail.target)}</span>
                  </Grid>
                )}
                {openDetail.smsmode_id && (
                  <Grid item display="grid">
                    <span>ID smsmode : {openDetail.smsmode_id}</span>
                  </Grid>
                )}
                {allContents &&
                  !openDetail.free_text &&
                  openDetail.message_id && (
                    <Grid item display="grid">
                      <span>
                        Contenu :{" "}
                        {
                          allContents?.find((x) => x?.id == openDetail?.message_id)
                            ?.content ? allContents?.find((x) => x?.id == openDetail?.message_id)
                            ?.content : ""
                        }
                      </span>
                    </Grid>
                  )}
                {openDetail.free_text && (
                  <Grid item display="grid">
                    <span>Contenu du SMS : {openDetail.free_text}</span>
                  </Grid>
                )}
                {openDetail.smsmode_status !== undefined && (
                  <Grid item display="grid">
                    <span>{`Code d'erreur : ${openDetail.smsmode_status}`}</span>
                  </Grid>
                )}
                {openDetail.reason && (
                  <Grid item display="grid">
                    <span>Raison : {openDetail.reason}</span>
                  </Grid>
                )}
                {openDetail.reason_details && (
                  <Grid item display="grid">
                    <span>Plus de détails : {openDetail.reason_details}</span>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormPopup>
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        minWidth="320px"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid container display="flex" direction="column" wrap="nowrap">
            <Grid item display="grid">
              <BoxTitle first="Gestion" second="des incidents" />
            </Grid>
            <Grid item display="grid" paddingTop="30px">
              <Grid container direction="column" gap="5px" wrap="nowrap">
                <Grid item display="grid">
                  <span className="filter-by-text">Filtrer par :</span>
                </Grid>
                <Grid item xs display="grid">
                  <div style={{ width: "600px" }}>
                    <Creatable
                      noOptionsMessage={() => "Aucune option"}
                      formatCreateLabel={(userInput) => `...`}
                      isMulti={true}
                      options={(() => {
                        const optionsTmp = [];
                        for (const user of usersList) {
                          if (
                            user?.email &&
                            user?.token &&
                            user.email?.length > 0
                          ) {
                            optionsTmp.push({
                              label: user?.email,
                              value: user?.user_id,
                            });
                          }
                        }
                        return optionsTmp;
                      })()}
                      value={usersSelected}
                      placeholder="Utilisateurs"
                      onChange={(e) => {
                        setUsersSelected(
                          e?.filter((opt) =>
                            usersList?.find((user) => user?.email == opt?.label)
                          )
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs display="grid">
                  <div style={{ width: "600px" }}>
                    <Creatable
                      noOptionsMessage={() => "Aucune option"}
                      placeholder="Raisons"
                      formatCreateLabel={(userInput) => `...`}
                      isMulti={true}
                      options={reasonOptions}
                      value={reasonSelected}
                      onChange={(e) => {
                        setReasonSelected(Array.isArray(e) ? e : undefined);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid" marginTop="5px">
              <OrangeCheckBox
                onClick={() => {
                  setHideResendIncidents((x) => (x ? false : true));
                }}
                value={hideResendIncidents}
                text="Masquer les incidents issus d'un renvoi"
              />
            </Grid>
            <Grid item display="grid" marginTop="5px">
              <OrangeCheckBox
                onClick={() => {
                  setHideAlreadyResend((x) => (x ? false : true));
                }}
                value={hideAlreadyResend}
                text="Masquer les incidents déjà renvoyés"
              />
            </Grid>
            {allSMSFiltered?.length > 0 && (
              <Grid item display="grid" paddingTop="30px" alignSelf="flex-end">
                <button
                  style={{
                    boxShadow: "1px 3px 5px #0F213467",
                    paddingTop: "7px",
                    paddingBottom: "7px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    borderRadius: "18px",
                    opacity: 1,
                    background: "#0B243C 0% 0% no-repeat padding-box",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => {
                    if (
                      !allSMSFiltered ||
                      !Array.isArray(allSMSFiltered) ||
                      allSMSFiltered.length === 0
                    ) {
                      dispatch(
                        openSnackbar({
                          message: "Aucun incident trouvé",
                          type: "error",
                          duration: 3000,
                        })
                      );
                      return;
                    }
                    const table = [];
                    table.push([
                      "Tel",
                      "Utilisateur",
                      "Date d'envoi",
                      "Date message d'erreur",
                      "Raison de non réception",
                      "Détails",
                    ]);
                    for (const x of allSMSFiltered) {
                      table.push([
                        `=""${formatPhone(x.target)}""`,
                        `=""${getUserEmailFromUserId(x.user_id)}""`,
                        `=""${formatDate(x.scheduled_date, "space")}""`,
                        `=""${formatDate(x.sended_date, "space")}""`,
                        `=""${x.reason}""`,
                        `=""${x.reason_details ? x.reason_details : ""}""`,
                      ]);
                    }
                    downloadCSV(table, `incidents-${Date.now()}`);
                  }}
                >
                  {windowWidth > 1300
                    ? allSMSFiltered.length > 1
                      ? `Exporter les ${allSMSFiltered.length} incidents`
                      : `Exporter l'incident`
                    : "Exporter"}
                </button>
              </Grid>
            )}
            <Grid item display="grid" paddingTop="30px">
              {!isLoading ? (
                <Grid container direction="column" wrap="nowrap">
                  {allSMSFiltered?.length > 0 ? (
                    <>
                      <Grid item display="grid">
                        <div
                          className="table-grid-gestion-incidents"
                          style={{ overflow: "auto" }}
                        >
                          <DisplayTitlesInTable
                            titles={[
                              "Utilisateur",
                              "Destinataire",
                              "Date d'envoi",
                              "Date erreur",
                              "Raison",
                              "",
                            ]}
                          />
                          {Array.isArray(allSMSFiltered) &&
                            allSMSFiltered
                              .slice(0, maxItem)
                              .map((sms, index) => {
                                return (
                                  <>
                                    <span
                                      className="table-grid-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        whiteSpace: "pre-line",
                                        width: "120px",
                                        wordBreak: "break-all"
                                      }}
                                    >
                                      {getUserEmailFromUserId(sms?.user_id)}
                                    </span>
                                    <span
                                      className="table-grid-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {formatPhone(sms.target)}
                                    </span>
                                    <span
                                      className="table-grid-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {formatDate(sms.scheduled_date)}
                                    </span>
                                    <span
                                      className="table-grid-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {formatDate(sms.sended_date)}
                                    </span>
                                    <span
                                      className="table-grid-text"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        whiteSpace: "pre-line",
                                        width: "120px",
                                      }}
                                    >
                                      {sms.reason}
                                    </span>
                                    <div className="table-grid-text" style={{}}>
                                      <Grid
                                        container
                                        direction="column"
                                        wrap="nowrap"
                                        gap="5px"
                                      >
                                        <Grid item display="grid">
                                          <Tooltip title="Détails">
                                            <IconButton
                                              onClick={() => {
                                                setOpenDetail(sms);
                                              }}
                                              style={{
                                                margin: "auto",
                                              }}
                                            >
                                              <InfoRounded
                                                fontSize="small"
                                                style={{ color: "#0b243c" }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                        <Grid item display="grid">
                                          {!forbiddenResend.includes(
                                            sms.id
                                          ) && (
                                            <Tooltip
                                              title={
                                                sms?.already_resend == 1
                                                  ? "SMS déjà renvoyé"
                                                  : "Renvoyer immédiatement"
                                              }
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  if (
                                                    sms?.already_resend == 1
                                                  ) {
                                                    snackbarUse.error(
                                                      "Vous avez déjà renvoyé cet SMS."
                                                    );
                                                    return;
                                                  }
                                                  setForbiddenResend(
                                                    (state) => {
                                                      if (
                                                        !state.includes(sms.id)
                                                      ) {
                                                        return [
                                                          ...state,
                                                          sms.id,
                                                        ];
                                                      }
                                                      return state;
                                                    }
                                                  );
                                                  makeAPIRequest(
                                                    "get",
                                                    `/sms_failure/${sms.id}/resend`,
                                                    null,
                                                    "v3"
                                                  )
                                                    .then((res) => {
                                                      snackbarUse.success(
                                                        "Un nouvel envoi vient d'être programmé.",
                                                        res
                                                      );
                                                    })
                                                    .catch((err) => {
                                                      snackbarUse.error(
                                                        "Impossible de programmer cet envoi.",
                                                        err
                                                      );
                                                      setForbiddenResend(
                                                        (state) =>
                                                          state.filter(
                                                            (x) => x != sms.id
                                                          )
                                                      );
                                                    });
                                                }}
                                                style={{
                                                  margin: "auto",
                                                  cursor: sms?.already_resend
                                                    ? "not-allowed"
                                                    : "pointer",
                                                }}
                                              >
                                                <RestartAltRounded
                                                  fontSize="small"
                                                  style={{
                                                    color: sms?.already_resend
                                                      ? "#FF3100"
                                                      : "#0b243c",
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </>
                                );
                              })}
                        </div>
                      </Grid>
                      <Grid item display="grid" justifyContent="center">
                        <MoreOrLessButtons
                          items={allSMSFiltered}
                          maxItem={maxItem}
                          setMaxItem={setMaxItem}
                        />
                      </Grid>
                    </>
                  ) : (
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#0b243c",
                        fontFamily: "Poppins",
                      }}
                    >
                      Aucun incident trouvé
                    </span>
                  )}
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const AdminGestionIncidentsPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_gestion_incidents" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_gestion_incidents" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
          minWidth={700}
        >
          <AdminGestionIncidentsBox />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminGestionIncidentsPage;
