import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import "./login.css";
import AuthService from "../services/auth.services";
import Left from "../assets/LeftFleche.svg";
import React, { useState, useEffect, Component, useRef } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import CaptchaComponent from "./CaptchaComponent";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import spec from "../helpers/specialities";
import useGoogleAnalyticsAndUser from "../utils/hooks/useGoogleAnalyticsAndUser";
import useUtils from "../utils/hooks/useUtils";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { GenericTextInput } from "../pages/CreateOrEditChatbotLexPage";
import { GenericSelect, GenericSelectMulti } from "../pages/QuestionnairePage";
import OrangeCheckBox from "./Fields/CheckBox/OrangeCheckBox";
import { GenericSendButton } from "../pages/ChatbotLexPage";
import { emailValidator } from "./Questionnaire/SendResponsePopup";
import isValidPhoneNumber from "../utils/isValidPhoneNumber";
import FormPopupInfo from "./FormPopup/FormPopupInfo";
import InformationPopup from "./Library/Popups/InformationPopup";
import {
  MedimailSearchType,
  getMedimailSearch,
} from "./Questionnaire/SendResponseMedimailPopup";
import makeAPIRequest from "../utils/makeAPIRequest";
import { Dropdown } from "react-bootstrap";
import { CloseRounded } from "@mui/icons-material";

interface Props {
  history: any;
}

const Register: React.FC<Props> = (props) => {
  const initialValues = {
    civility: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    profession: "",
    nni_type: "",
    nni_id: "",
    signature: "",
    speciality_main: "",
    speciality_others: [],
    pathologies: "",
    user_login: "",
    user_pass: "",
    cgv: false,
    //recaptcha: false,
    message: "",
    errorMessage: "",
    tags: [],
    account_type: 0,
  };
  const [successRegister, setSuccessRegister] = React.useState<boolean>(false);
  const [signIsLoading, setSignIsLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState(initialValues);
  const [MSSanteAddress, setMSSanteAddress] = React.useState<string>("");
  const [utils, setUtils] = useUtils();
  const [userData, setUserData] = useState<any>({});
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [recaptcha, setRecaptcha] = useState(true);
  const [successful, setSuccessful] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [civilities, setCivilities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [patho, setPatho] = useState([]);
  const [professions, setProfessions] = useState([]);
  const options2 = [];
  const optionsPatho = [];
  const optionsCiv = [];

  const [firstDataLoaded, setFirstDataLoaded] = React.useState<boolean>(false);
  const [destOptions, setDestOptions] = React.useState<MedimailSearchType[]>(
    []
  );
  const [destLoading, setDestLoading] = React.useState<boolean>(false);
  const [searchList, setSearchStack] = React.useState<string[]>([]);
  const [lastChangeTime, setLastChangeTime] = React.useState<number>(
    Date.now()
  );
  const [timeNow, setTimeNow] = React.useState(Date.now());
  const initialSearchInput = {
    nom: "", //
    adresse: "",
    departement: "",
    mail: "",
    postalCode: "", //
    prenom: "", //
    profession: "", //
    region: "",
    rpps: "", //
    specialite: "", //
    tel: "",
    ville: "", //
  };
  const [searchInputs, setSearchInputs] =
    React.useState<MedimailSearchType>(initialSearchInput);
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const RPPSRef = useRef(null);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const professionRef = useRef(null);
  const specialityRef = useRef(null);
  const nniTypeRef = useRef(null);
  const allErrorRefs = [
    firstnameRef,
    lastnameRef,
    emailRef,
    phoneRef,
    RPPSRef,
    usernameRef,
    passwordRef,
    professionRef,
    specialityRef,
    nniTypeRef,
  ];

  React.useEffect(() => {
    const search = getMedimailSearch(searchInputs);
    if (!search || search === searchList?.[searchList.length - 1]) {
      setDestLoading(false);
      setDestOptions([]);
      return;
    }
    setDestLoading(true);
    setLastChangeTime(Date.now());
    setSearchStack((x) => {
      return [...x, search];
    });
  }, [searchInputs]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeNow(Date.now());
    }, 500);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (lastChangeTime + 1000 > timeNow) {
      return;
    }
    if (!searchList || searchList.length === 0) {
      setDestLoading(false);
      setDestOptions([]);
      return;
    }
    const search = searchList[searchList.length - 1];
    if (search === searchList?.[searchList.length - 2]) {
      return;
    }
    if (search) {
      setSearchStack((x) => {
        return [...x, search];
      });
      makeAPIRequest(
        "post",
        "/medimail/annuaire/public_search",
        {
          search: search,
        },
        "v3"
      )
        .then((res) => {
          setFirstDataLoaded(true);
          const data = res?.data;
          if (Array.isArray(data) && data.length > 0) {
            setDestOptions(data);
          } else {
            setDestOptions([]);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        })
        .finally(() => {
          setDestLoading(false);
        });
    } else {
      setDestOptions([]);
      setDestLoading(false);
    }
  }, [searchList, lastChangeTime, timeNow]);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setProfessions(utils?.getProfessionsOptionsSorted());
    setSpecialities(utils?.getSpecialitiesOptionsSorted());
  }, [utils]);

  const goBack = () => {
    setSuccessful(false);
    setErrorMessage("");
  };

  const load = async () => {
    await fetch(process.env.REACT_APP_API_URL + "/utils/language")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setCivilities(result.data.civilities);
          setPatho(result.data.patho);
        },
        (error) => {
          setIsLoaded(false);
          setError(error);
        }
      );
  };

  const validationSchema = () => {
    return Yup.object().shape({
      civility: Yup.string().required("Champ obligatoire !"),
      first_name: Yup.string().required("Champ obligatoire !"),
      last_name: Yup.string().required("Champ obligatoire !"),
      email: Yup.string().required("Champ obligatoire !"),
      phone: Yup.string().required("Champ obligatoire !"),
      profession: Yup.string().required("Champ obligatoire !"),
      nni_type: Yup.string().required("Champ obligatoire !"),
      nni_id: Yup.string()
        .required("Champ obligatoire !")
        .min(9, "Numéro incorrect")
        .max(13, "Numéro incorrect")
        .matches(/^\d+$/, "Numéro non valide"),
      speciality_main: Yup.string().required("Champ obligatoire !"),
      speciality_others: Yup.string().required("Champ obligatoire !"),
      pathologies: Yup.string().required("Champ obligatoire !"),
      user_login: Yup.string().required("Champ obligatoire !"),
      user_pass: Yup.string().required("Champ obligatoire !"),
      cgv: Yup.string().required("Champ obligatoire !"),
      //recaptcha: Yup.string().required("Champ obligatoire !"),
    });
  };

  const handleRegister = () => {
    setMessage("");
    setErrorMessage("");
    setSignIsLoading(true);
    AuthService.register(form)
      .then(
        (response: any) => {
          if (response.success == true) {
            setSuccessRegister(true);
            setForm(initialValues);
          } else {
            setMessage(response.data.message);
            setErrorMessage(localStorage.getItem("error"));
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          const convertParameter = {
            account_type: "Type de compte",
            first_name: "Prénom",
            last_name: "Nom",
            email: "Email",
            phone: "Téléphone",
            profession: "Profession",
            nni_type: "Type de numéro",
            speciality_main: "Spécialité principale",
            nni_id: "Numéro RPPS/ADELI",
            user_login: "Nom d'utilisateur",
            user_pass: "Mot de passe",
            cgv: "Conditions Générales de Ventes",
          };
          let message = "";
          const invalidParameters = error?.response?.data?.data?.params;
          if (!Array.isArray(invalidParameters)) {
            const keys = Object?.keys(invalidParameters);
            if (keys?.length >= 1) {
              if (keys[0] === "phone") {
                message = "Le numéro de téléphone est invalide.";
              } else if (keys[0] === "email") {
                message = "L'adresse e-mail est invalide.";
              } else {
                message = `${invalidParameters[keys[0]]}`;
              }
            }
          } else {
            if (invalidParameters?.length === 0) {
              message = "";
            } else if (invalidParameters?.length === 1) {
              message = `Le champ '${
                convertParameter[invalidParameters[0]]
              }' est manquant.`;
            } else {
              message = "Les champs ";
              for (let i = 0; i < invalidParameters?.length; i++) {
                if (i === 0) {
                  message += `'${convertParameter[invalidParameters[i]]}'`;
                } else if (i === invalidParameters?.length - 1) {
                  message += ` et '${convertParameter[invalidParameters[i]]}'`;
                } else {
                  message += `, '${convertParameter[invalidParameters[i]]}'`;
                }
              }
              message += " sont manquants.";
            }
          }
          setMessage(message);
          setErrorMessage(localStorage.getItem("error"));
        }
      )
      .finally(() => setSignIsLoading(false));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData({ ...userData, [name]: value });
  };

  const handleCaptcha = (value) => {
    setRecaptcha(true);
    setUserData({ ...userData, captcha: 1 });
  };

  const handleSpec = (e, i) => {
    const { value, label } = e;
    let specArray = [];

    if (i.name == "speciality_others") {
      e.forEach((element) => {
        specArray = [...specArray, element.value];
      });
      setUserData({ ...userData, [i.name]: specArray });
    } else setUserData({ ...userData, [i.name]: value });
  };

  const options3 = [
    {
      label: "RPPS",
      value: "RPPS",
    },
    {
      label: "ADELI",
      value: "ADELI",
    },
  ];

  options3.map((option3) => {
    options2.push({
      value: option3.value,
      label: option3.label,
    });
  });

  patho.map((option5) => {
    optionsPatho.push({
      value: option5.id,
      label: option5.name,
    });
  });

  civilities.map((option) => {
    optionsCiv.push({
      value: option.id,
      label: option.name,
    });
  });

  const resetErrors = () => {
    if (!allErrorRefs || allErrorRefs?.length == 0) {
      return;
    }
    for (const genericRef of allErrorRefs) {
      if (genericRef?.current && genericRef?.current?.setIsError) {
        genericRef.current.setIsError(false);
      }
    }
  };

  return (
    <>
      {successRegister && (
        <InformationPopup
          firstTitle="Création"
          secondTitle="de compte"
          confirmButtonText=""
          onConfirm={() => setSuccessRegister(false)}
          message="Votre demande de création de compte a bien été prise en compte, vous recevrez un e-mail après activation."
        ></InformationPopup>
      )}
      <div>
        {!successful && (
          <div className="login">
            <div className="box-registration">
              <Link to={"/"}>
                <button
                  className="back_button"
                  style={{
                    borderRadius: "255px",
                    height: "30px",
                    width: "fit-content",
                    lineHeight: "1px",
                    margin: "auto",
                    marginTop: "20px",
                  }}
                >
                  {"< Retour à l'accueil"}
                </button>
              </Link>
              <div className="log-box-title-register">S'enregistrer</div>
              <Grid
                container
                direction="column"
                wrap="nowrap"
                gap="10px"
                padding="0px 20px 40px 20px"
                maxWidth="500px"
              >
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitleInPlaceholderOnly={true}
                    fieldTitle="Adresse MSSanté"
                    isMandatory={false}
                    id="input-ms-sante-address"
                    value={MSSanteAddress}
                    onChange={(e) => {
                      const value = e?.target?.value ? e.target.value : "";
                      setMSSanteAddress(value);
                      setSearchInputs((x) => {
                        return { ...x, mail: value };
                      });
                    }}
                  />
                </Grid>
                {destOptions?.length === 0 &&
                  firstDataLoaded &&
                  !destLoading &&
                  MSSanteAddress && (
                    <Grid
                      item
                      display="grid"
                      marginBottom={"-20px"}
                      marginTop={"-5px"}
                    >
                      <span
                        style={{
                          textAlign: "end",
                          color: "#FF3100",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        {`Adresse MSSanté introuvable`}
                      </span>
                    </Grid>
                  )}
                {(destOptions?.length > 0 || destLoading) && (
                  <Grid item display="grid" paddingRight="20px">
                    <Dropdown.Menu
                      show
                      style={{
                        borderRadius: "0px 10px 10px 10px",
                        zIndex: 100,
                        padding: "0",
                        marginTop: "-5px",
                        marginLeft: "2px",
                        overflow: "none",
                        maxWidth: "400px",
                      }}
                    >
                      <ul
                        className="drowDownSearch"
                        style={{
                          zIndex: 100,
                          maxHeight: "300px",
                          overflow: "auto",
                          fontFamily: "Poppins",
                          color: "#0B243C",
                        }}
                      >
                        {!destLoading && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              padding: "5px 10px 5px 5px",
                            }}
                          >
                            <Tooltip title="Fermer">
                              <IconButton
                                style={{ margin: "0", padding: "0" }}
                                onClick={() => {
                                  setDestLoading(false);
                                  setDestOptions([]);
                                  setSearchStack([]);
                                  setFirstDataLoaded(false);
                                  setSearchInputs(initialSearchInput);
                                  setMSSanteAddress("");
                                }}
                              >
                                <CloseRounded
                                  fontSize="small"
                                  style={{ color: "#0B243C" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                        {destLoading && (
                          <li
                            className="PatientUnit"
                            style={{
                              height: "100%",
                              paddingBottom: "10px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "10px",
                              flexDirection: "column",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              style={{
                                color: "#657273",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </li>
                        )}
                        {!destLoading &&
                          destOptions
                            .filter((x) => x.mail && x.nom && x.prenom)
                            .map((destOption, index) => {
                              return (
                                <li
                                  key={index}
                                  className="PatientUnit"
                                  style={{
                                    height: "100%",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    flexDirection: "column",
                                    display: "flex",
                                  }}
                                  onClick={() => {
                                    setMSSanteAddress(destOption.mail);
                                    setDestLoading(false);
                                    resetErrors();
                                    setDestOptions([]);
                                    setSearchStack([]);
                                    setFirstDataLoaded(false);
                                    setSearchInputs(initialSearchInput);
                                    setForm((state) => {
                                      const firstName = destOption.prenom;
                                      const lastName = destOption.nom;
                                      const email = destOption.mail;
                                      const phone = destOption.tel;
                                      const RPPS = destOption.rpps;
                                      const convertProfession = {
                                        Médecin: "0",
                                        Infirmier: "1",
                                        "Assistant dentaire": "2",
                                        "Assistant social": "3",
                                        "Audio-Prothésiste": "4",
                                        Chiropracteur: "5",
                                        Diététicien: "6",
                                        Epithésiste: "7",
                                        Ergothérapeute: "8",
                                        "Manipulateur ERM": "10",
                                        Oculariste: "11",
                                        "Opticien-Lunetier": "12",
                                        "Orthopédiste-Orthésiste": "13",
                                        Orthophoniste: "14",
                                        Orthoprothésiste: "15",
                                        Orthoptiste: "16",
                                        Ostéopathe: "17",
                                        "Physicien médical": "18",
                                        "Podo-Orthésiste": "19",
                                        Psychologue: "20",
                                        Psychomotricien: "21",
                                        Psychothérapeute: "22",
                                        "Technicien de Laboratoire": "23",
                                        "Chirurgien-Dentiste": "25",
                                        "Sage-Femme": "26",
                                        Pharmacien: "27",
                                        "Masseur-Kinésithérapeute": "28",
                                        "Pédicure-Podologue": "29",
                                      };
                                      const convertProfessionToSpeciality = {
                                        "0": "12846",
                                        "1": "12836",
                                        "2": "13059",
                                        "4": "13057",
                                        "6": "13082",
                                        "7": "13079",
                                        "8": "13081",
                                        "10": "13084",
                                        "11": "13078",
                                        "12": "13058",
                                        "14": "12840",
                                        "15": "13075",
                                        "16": "12842",
                                        "18": "13060",
                                        "19": "13076",
                                        "21": "13083",
                                        "23": "13080",
                                        "25": "12833",
                                        "26": "12837",
                                        "27": "13040",
                                        "28": "12834",
                                      };
                                      const profession =
                                        convertProfession?.[
                                          destOption.profession
                                        ];
                                      const speFounded = destOption.specialite
                                        ? specialities
                                            ?.filter((x) => x.label)
                                            ?.find(
                                              (x) =>
                                                x.label
                                                  .replace("Médecin - ", "")
                                                  .replace(
                                                    "Chirurgien-Dentiste - ",
                                                    ""
                                                  )
                                                  .replace("Infirmier - ", "")
                                                  .toLowerCase() ==
                                                destOption.specialite.toLowerCase()
                                            )?.value
                                        : undefined;
                                      const specialityGeneral = profession
                                        ? convertProfessionToSpeciality?.[
                                            profession
                                          ]
                                        : undefined;
                                      return {
                                        ...state,
                                        first_name: firstName ? firstName : "",
                                        last_name: lastName ? lastName : "",
                                        email: email ? email : "",
                                        phone: phone ? phone : "",
                                        nni_id: RPPS ? RPPS : "",
                                        nni_type: RPPS
                                          ? RPPS?.length === 11
                                            ? "RPPS"
                                            : "ADELI"
                                          : "",
                                        profession: profession
                                          ? profession
                                          : "",
                                        speciality_main: speFounded
                                          ? speFounded
                                          : specialityGeneral
                                          ? specialityGeneral
                                          : "13091",
                                      };
                                    });
                                    return;
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="column"
                                    wrap="nowrap"
                                    gap="5px"
                                  >
                                    <Grid item display="grid">
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          color: "#0B243C",
                                          wordBreak: "break-all",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {destOption.nom} {destOption.prenom}
                                      </span>
                                    </Grid>
                                    <Grid item display="grid">
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          color: "#0B243C",
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {destOption.mail}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </li>
                              );
                            })}
                      </ul>
                    </Dropdown.Menu>
                  </Grid>
                )}
                <Grid item display="grid" marginTop="20px">
                  <GenericSelect
                    fieldTitle=""
                    isMandatory={false}
                    options={optionsCiv}
                    id="input-civility"
                    placeholder="Civilité"
                    isClearable={true}
                    value={
                      form.civility
                        ? optionsCiv.find((x) => x?.value == form.civility)
                        : undefined
                    }
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, civility: e?.value ? e.value : "" };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitleInPlaceholderOnly={true}
                    fieldTitle="Prénom"
                    isMandatory={true}
                    ref={firstnameRef}
                    id="input-first-name"
                    value={form.first_name}
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, first_name: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitleInPlaceholderOnly={true}
                    fieldTitle="Nom"
                    ref={lastnameRef}
                    isMandatory={true}
                    id="input-last-name"
                    value={form.last_name}
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, last_name: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitleInPlaceholderOnly={true}
                    fieldTitle="Email"
                    isMandatory={true}
                    ref={emailRef}
                    id="input-email"
                    value={form.email}
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, email: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitleInPlaceholderOnly={true}
                    fieldTitle="Téléphone"
                    ref={phoneRef}
                    isMandatory={true}
                    id="input-phone"
                    value={form.phone}
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, phone: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid" marginTop="20px">
                  <GenericSelect
                    fieldTitle=""
                    isMandatory={true}
                    options={professions}
                    id="input-profession"
                    ref={professionRef}
                    placeholder="Profession *"
                    isClearable={false}
                    value={
                      form.profession
                        ? professions.find((x) => x?.value == form.profession)
                        : undefined
                    }
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, profession: e?.value ? e.value : "" };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericSelect
                    fieldTitle=""
                    isMandatory={true}
                    options={options2}
                    ref={nniTypeRef}
                    id="input-nni-type"
                    placeholder="Type de numéro *"
                    isClearable={false}
                    value={
                      form.nni_type
                        ? options2.find((x) => x?.value == form.nni_type)
                        : undefined
                    }
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, nni_type: e?.value ? e.value : "" };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitleInPlaceholderOnly={true}
                    fieldTitle="Numéro RPPS/ADELI"
                    isMandatory={true}
                    id="input-nni-id"
                    ref={RPPSRef}
                    value={form.nni_id}
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, nni_id: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericSelect
                    fieldTitle=""
                    ref={specialityRef}
                    isMandatory={true}
                    options={
                      specialities
                        ? specialities?.filter((x) => x?.value && x?.label)
                        : []
                    }
                    id="input-specialities"
                    placeholder="Spécialité principale *"
                    isClearable={false}
                    value={
                      form.speciality_main
                        ? specialities.find(
                            (x) => x?.value == form.speciality_main
                          )
                        : undefined
                    }
                    onChange={(e) => {
                      setForm((x) => {
                        return {
                          ...x,
                          speciality_main: e?.value ? e.value : "",
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericSelectMulti
                    fieldTitle=""
                    isMandatory={true}
                    value={
                      form.speciality_others?.length > 0
                        ? form.speciality_others
                            .map((spe) => {
                              const foundedSpe = specialities.find(
                                (x) => x?.value === spe
                              );
                              return foundedSpe ? foundedSpe : undefined;
                            })
                            .filter((y) => y !== undefined)
                        : []
                    }
                    options={specialities}
                    placeholder="Autres spécialités"
                    isClearable={true}
                    id="input-specialities-others"
                    onChange={(e) => {
                      setForm((x) => {
                        return {
                          ...x,
                          speciality_others:
                            e?.length > 0 ? e.map((opt) => opt.value) : [],
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid" marginTop="20px">
                  <GenericTextInput
                    ref={usernameRef}
                    fieldTitleInPlaceholderOnly={true}
                    fieldTitle="Nom d'utilisateur"
                    isMandatory={true}
                    id="input-user-login"
                    value={form.user_login}
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, user_login: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericTextInput
                    fieldTitleInPlaceholderOnly={true}
                    fieldTitle="Mot de passe"
                    isMandatory={true}
                    type="password"
                    ref={passwordRef}
                    id="input-user-pass"
                    value={form.user_pass}
                    onChange={(e) => {
                      setForm((x) => {
                        return { ...x, user_pass: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      color: "#0B243C",
                    }}
                  >
                    {`Le mot de passe doit être composé d'au minimum
                    14 caractères comprenant des majuscules, des minuscules et
                    des chiffres.`}
                  </span>
                </Grid>
                <Grid item display="grid" marginTop="20px" alignSelf={"center"}>
                  <OrangeCheckBox
                    value={form.cgv}
                    addLinkText={
                      "https://www.certipair.fr/certipair-conditions-generales-dutilisation/"
                    }
                    textStyle={{
                      color: "rgb(255, 165, 0)",
                      fontWeight: "bold",
                    }}
                    text="Conditions Générales de Ventes"
                    onClick={() => {
                      setForm((x) => {
                        return { ...x, cgv: x.cgv ? false : true };
                      });
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GenericSendButton
                    textButton="S'enregistrer"
                    errorMessages={{
                      noFirstName: "Le prénom n'est pas renseigné",
                      noLastName: "Le nom n'est pas renseigné",
                      noEmail: "L'adresse e-mail n'est pas renseignée",
                      invalidEmail: "L'adresse e-mail est invalide",
                      noPhone: "Le numéro de téléphone n'est pas renseigné",
                      invalidPhone: "Le numéro de téléphone est invalide",
                      noProfession: "Aucune profession n'a été sélectionnée",
                      noNumeroType: "Aucun type de numéro n'a été sélectionné",
                      noNumeroId: "Le numéro RPPS/ADELI n'est pas renseigné",
                      noMainSpeciality:
                        "Aucune spécialité principale n'a été sélectionnée",
                      noUserLogin: "Le nom d'utilisateur n'est pas renseigné",
                      badUserLoginLength:
                        "Le nom d'utilisateur doit contenir au minimum 5 caractères",
                      noUserPass: "Le mot de passe n'est pas renseigné",
                      badPasswordLength:
                        "Le mot de passe doit contenir au minimum 14 caractères",
                      badPasswordMajuscule:
                        "Le mot de passe doit contenir au minimum une majuscule",
                      badPasswordChiffre:
                        "Le mot de passe doit contenir au minimum un chiffre",
                      badPasswordMinuscule:
                        "Le mot de passe doit contenir au minimum une minuscule",
                      noCgv: "Les CGV doivent être acceptées",
                    }}
                    errorSelector={{
                      noFirstName: "#input-first-name",
                      noLastName: "#input-last-name",
                      noEmail: "#input-email",
                      invalidEmail: "#input-email",
                      noPhone: "#input-phone",
                      invalidPhone: "#input-phone",
                      noProfession: "#input-profession",
                      noNumeroType: "#input-nni-type",
                      noNumeroId: "#input-nni-id",
                      noMainSpeciality: "#input-specialities",
                      noUserLogin: "#input-user-login",
                      badUserLoginLength: "#input-user-login",
                      noUserPass: "#input-user-pass",
                      badPasswordLength: "#input-user-pass",
                      badPasswordMajuscule: "#input-user-pass",
                      badPasswordChiffre: "#input-user-pass",
                      badPasswordMinuscule: "#input-user-pass",
                      noCgv: "#input-cgv",
                    }}
                    invalidConditions={{
                      noFirstName: !form.first_name,
                      noLastName: !form.last_name,
                      noEmail: !form.email,
                      invalidEmail: !emailValidator(form.email),
                      noPhone: !form.phone,
                      invalidPhone: !isValidPhoneNumber(form.phone, [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "9",
                      ]),
                      noProfession: !form.profession,
                      noNumeroType: !form.nni_type,
                      noNumeroId: !form.nni_id,
                      noMainSpeciality: !form.speciality_main,
                      noUserLogin: !form.user_login,
                      badUserLoginLength: form?.user_login?.length < 5,
                      noUserPass: !form.user_pass,
                      badPasswordLength: form?.user_pass?.length < 14,
                      badPasswordMajuscule: !/[A-Z]/.test(form.user_pass),
                      badPasswordChiffre: !/\d/.test(form.user_pass),
                      badPasswordMinuscule: !/[a-z]/.test(form.user_pass),
                      noCgv: !form.cgv,
                    }}
                    onSendClick={handleRegister}
                    isLoading={signIsLoading}
                  />
                </Grid>
                <Grid item display="grid">
                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                  {errorMessage && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}{" "}
                        <button
                          className="back_button"
                          onClick={() => goBack()}
                        >
                          Retour
                        </button>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        )}
        <div className="phantom"></div>
        <div className="login-footer">
          <a
            href="https://www.certipair.fr/contact/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Contact{" "}
          </a>
          -{" "}
          <a
            href="https://www.certipair.fr/mentions-legales/"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
        </div>
        <div className="phantom2"></div>
      </div>
    </>
  );
};

export default Register;
