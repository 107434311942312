import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useParams } from "react-router";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";

interface ResponseStat {
  question: string;
  responses: {
    response: string;
    total: number;
  }[];
}

interface PublicFormStats {
  user: {
    civility: "dr" | "mme" | "mr" | "pr" | "";
    firstname: string;
    lastname: string;
  };
  scheduled: {
    programmations: number;
    pending: number;
    completed: number;
  };
  form: {
    title: string;
  };
  responseStats: ResponseStat[];
}

const UnknownStats = () => {
  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      Les statistiques de ce questionnaire ne sont pas accessibles.
    </span>
  );
};

export const formatCivilityWithFirstname = (civility, lastname) => {
  if (!civility) return lastname;
  const result =
    civility[0].toUpperCase() +
    civility?.substring(1, civility?.length) +
    ". " +
    lastname;

  return result;
};

export const PublicFormStatsBox = (props: {
  isLoading: boolean;
  publicFormStats: PublicFormStats;
  noPadding?: boolean;
}) => {
  const scheduled = props.publicFormStats ? props.publicFormStats.scheduled.programmations : 0;
  const completed = props.publicFormStats ? props.publicFormStats.scheduled.completed : 0;
  const sended = props.publicFormStats ? props.publicFormStats.scheduled.pending + props.publicFormStats.scheduled.completed : 0;
  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      paddingLeft={props.noPadding ? 0 : "5%"}
      paddingTop={props.noPadding ? 0 : "5%"}
      paddingRight={props.noPadding ? 0 : "5%"}
      paddingBottom={props.noPadding ? 0 : "5%"}
    >
      {props.isLoading && (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!props.isLoading && (
        <Grid item display="grid">
          {props.publicFormStats ? (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              justifyContent={"center"}
            >
              <Grid item display="grid">
                <span
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontFamily: "Poppins",
                    color: "rgb(255, 165, 0)",
                    fontWeight: "bold",
                  }}
                >
                  {`${props.publicFormStats?.form?.title}`}
                </span>
              </Grid>
              <Grid item display="grid" marginTop="40px">
                <span
                  style={{
                    textAlign: "right",
                    fontSize: 16,
                    fontFamily: "Poppins",
                    fontStyle: "italic",
                    color: "#0B243C",
                  }}
                >
                  {`Utilisateur : ${formatCivilityWithFirstname(
                    props.publicFormStats.user?.civility,
                    props.publicFormStats.user.lastname
                  )}`}
                </span>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    textAlign: "right",
                    fontSize: 16,
                    fontFamily: "Poppins",
                    color: "#0B243C",
                    fontStyle: "italic",
                  }}
                >
                  {`${
                    scheduled
                  } programmation${
                    scheduled > 1
                      ? "s"
                      : ""
                  } (${sended} envoyé${
                    sended > 1 ? "s" : ""
                  }, dont ${completed} réponse${
                    completed > 1 ? "s" : ""
                  })`}
                </span>
              </Grid>
              <Grid item display="grid" marginTop="30px">
                <Grid
                  container
                  direction="row"
                  wrap="wrap"
                  rowGap="20px"
                  columnGap={"40px"}
                >
                  {props.publicFormStats.responseStats.length === 0 && (
                    <Grid item display="grid">
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: 16,
                          fontFamily: "Poppins",
                          color: "#0B243C",
                        }}
                      >
                        Aucune statistique n'a pu être extraite de ce questionnaire, car les données sont insuffisantes.
                      </span>
                    </Grid>
                  )}
                  {props.publicFormStats.responseStats.map(
                    (responseStat, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="5px"
                          >
                            <Grid item display="grid">
                              <span
                                style={{
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  color: "rgb(91, 185, 193)",
                                  fontWeight: 600,
                                }}
                              >
                                {responseStat.question}
                              </span>
                            </Grid>
                            <Grid item display="grid" marginLeft="30px">
                              <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                borderTop="1px solid rgb(91, 185, 193)"
                                borderLeft="1px solid rgb(91, 185, 193)"
                                borderRadius="12px"
                                width="fit-content"
                                padding="10px"
                              >
                                {responseStat.responses.map(
                                  (response, index) => {
                                    return (
                                      <Grid item display="grid">
                                        <span
                                          style={{
                                            textAlign: "left",
                                            fontSize: 16,
                                            fontFamily: "Poppins",
                                            color: "#0B243C",
                                          }}
                                        >
                                          {response.response} : {response.total}{" "}
                                          réponse{response.total > 1 ? "s" : ""}
                                        </span>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <UnknownStats />
          )}
        </Grid>
      )}
    </Grid>
  );
};

interface IParams {
  id: string;
}

const SharePublicFormStatsPage: React.FC = () => {
  const { id } = useParams<IParams>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [publicFormStats, setPublicFormStats] =
    React.useState<PublicFormStats>(undefined);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", `/questions/public_form_stats/${id}`, null, "v3")
      .then((res) => {
        setPublicFormStats(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="1000px"
        >
          <Grid item display="grid">
            <PublicFormStatsBox
              isLoading={isLoading}
              publicFormStats={publicFormStats}
            />
          </Grid>
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default SharePublicFormStatsPage;
