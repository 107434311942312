import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createAdviceReducer from './createAdviceReducer'
import createPatientReducer from './createPatientReducer'
import editAdviceReducer from './editAdviceReducer'
import editPatientReducer from './editPatientReducer'
import selectPatientReducer from './selectPatientReducer'
import sendAdviceReducer from './sendAdviceReducer'
import snackbarReducer from './snackbarReducer'
import tabsReducer from './tabsReducer'
import windowReducer from './windowReducer'
import componentSizeReducer from './componentSizeReducer'
import questionnaireReducer from './questionnaireReducer'
import shareQuestionnaireReducer from './shareQuestionnaireReducer'
import authorizationsReducer from './authorizationsReducer'
import tokensReducer from './tokensReducer'
import helpInstallationVitaleReducer from './helpInstallationVitaleReducer'
import scheduleChatbotReducer from './scheduleChatbotReducer'
import scheduleQuestionnaireReducer from './scheduleQuestionnaireReducer'
import groupsTokensReducer from './groupsTokensReducer'
import editFormScheduleReducer from './editFormScheduleReducer'
import ImportPatientReducer from './ImportPatientReducer'
import INSiReducer from './INSiReducer'
import notificationsReducer from './new/notificationsReducer'
import languageReducer from './new/languageReducer'
import graphAraigneeReducer from './new/graphAraigneeReducer'
import userReducer from './new/userReducer'
import formPdfReducer from './new/formPdfReducer'
import questionnaireInteractifReducer from './new/questionnaireInteractifReducer'
import newQuestionnaireReducer from './new/newQuestionnaireReducer'

export const Store = configureStore({
  reducer: {
    // New reducers
    languageReducer: languageReducer,
    notificationsReducer: notificationsReducer,
    graphAraigneeReducer: graphAraigneeReducer,
    userReducer: userReducer,
    formPdfReducer: formPdfReducer,
    questionnaireInteractifReducer: questionnaireInteractifReducer,
    newQuestionnaireReducer: newQuestionnaireReducer,

    // Old reducers
    sendAdviceReducer: sendAdviceReducer,
    tabsReducer: tabsReducer,
    editAdviceReducer: editAdviceReducer,
    createAdviceReducer: createAdviceReducer,
    editPatientReducer: editPatientReducer,
    createPatientReducer: createPatientReducer,
    selectPatientReducer: selectPatientReducer,
    windowReducer: windowReducer,
    componentSizeReducer: componentSizeReducer,
    snackbarReducer: snackbarReducer,
    questionnaireReducer: questionnaireReducer,
    shareQuestionnaireReducer: shareQuestionnaireReducer,
    authorizationsReducer: authorizationsReducer,
    tokensReducer: tokensReducer,
    helpInstallationVitaleReducer: helpInstallationVitaleReducer,
    scheduleChatbotReducer: scheduleChatbotReducer, 
    scheduleQuestionnaireReducer: scheduleQuestionnaireReducer,
    groupsTokensReducer: groupsTokensReducer,
    editFormScheduleReducer: editFormScheduleReducer,
    importPatientReducer: ImportPatientReducer,
    INSiReducer: INSiReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch