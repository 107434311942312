import * as React from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import FormPopup from "../../../FormPopup/FormPopup";
import { setFieldCopy } from "../../../../redux/questionnaireReducer";
import FormPopupHeader from "../../../FormPopup/FormPopupHeader";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import DisplayType from "./Fields/DisplayType";
import DisplayTitle from "./Fields/DisplayTitle";
import DisplayLongText from "./Fields/DisplayLongText";
import DisplaySimpleCheck from "./Fields/DisplaySimpleCheck";
import DisplayListeDeroulante from "./Fields/DisplayListeDeroulante";
import DisplayUniqueChoice from "./Fields/DisplayUniqueChoice";
import DisplayButtons from "./DisplayButtons";
import FullField from "../../../Fields/FullField";
import TextInput from "../../../Library/Inputs/TextInput";
import OrangeButton from "../../../Library/Buttons/OrangeButton/OrangeButton";
import GreyButton from "../../../Library/Buttons/GreyButton/GreyButton";
import AddButton from "../../../Library/Buttons/AddButton/AddButton";
import {
  ConditionDisplayField,
  IForm,
  ISection,
} from "../../../../types/FormTypes";
import { Delete, Edit } from "@mui/icons-material";
import {
  CreateOrEditCondition,
  initConditionDisplayField,
  initConditionDisplayFieldInfos,
} from "./QuestionnairePopupEdit";

const SectionPopupEdit: React.FC<{
  setIsSectionEditPopup: any;
  sectionInfos: {
    title: string;
    id: any;
    index: number;
    section: ISection;
  };
  questionnaireData: IForm;
  setQuestionnaireData: any;
}> = (props) => {
  const {
    setIsSectionEditPopup,
    sectionInfos,
    questionnaireData,
    setQuestionnaireData,
  } = props;
  const [newTitle, setNewTitle] = React.useState(sectionInfos?.title);
  const [newConditions, setNewConditions] = React.useState(
    sectionInfos?.section?.conditionsInfos
  );
  // Conditions
  const [createConditionOpen, setCreateConditionOpen] =
    React.useState<boolean>(false);
  const [editConditionOpen, setEditConditionOpen] =
    React.useState<ConditionDisplayField>(undefined);

  return (
    <>
      {createConditionOpen && (
        <CreateOrEditCondition
          isCreate={true}
          questionnaireData={questionnaireData}
          editInfos={initConditionDisplayField()}
          closePopup={() => setCreateConditionOpen(false)}
          pushNewCondition={(newCondition) => {
            setNewConditions((state) => {
              if (!state) {
                return {
                  conditionsList: [newCondition],
                };
              }
              return {
                conditionsList: [...state.conditionsList, newCondition],
              };
            });
          }}
        />
      )}
      {editConditionOpen && (
        <CreateOrEditCondition
          questionnaireData={questionnaireData}
          isCreate={false}
          editInfos={editConditionOpen}
          closePopup={() => setEditConditionOpen(undefined)}
          editThisCondition={(conditionToEdit) => {
            setNewConditions((state) => {
              return {
                conditionsList: state.conditionsList.map((state) => {
                  if (state.id == conditionToEdit.id) {
                    return (conditionToEdit);
                  }
                  return state;
                }),
              };
            });
          }}
        />
      )}
      <FormPopup xs={9}>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle={"Modifier"}
              secondTitle={"une section"}
              onClose={() => {
                setIsSectionEditPopup(null);
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              justifySelf="center"
              width="70%"
              spacing="10px"
            >
              <Grid item display="grid">
                <FullField title="Titre" isMandatory={false} spacing="1px">
                  <TextInput
                    value={newTitle}
                    onChange={(e) => {
                      setNewTitle(e.target.value);
                    }}
                  />
                </FullField>
              </Grid>
              <Grid item display="grid">
                <AddButton
                  onClick={() => {
                    setCreateConditionOpen(true);
                  }}
                  addId={"add-medecin-delegant-button"}
                  displayText={true}
                  text="Ajouter une condition d'affichage"
                />
              </Grid>
              <Grid item display="grid">
                {newConditions?.conditionsList &&
                newConditions?.conditionsList?.length > 0 ? (
                  <Grid container direction="row" gap="10px">
                    {newConditions.conditionsList.map((condition, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            style={{
                              border: "1px solid rgba(11, 36, 60)",
                              backgroundColor: "rgba(247, 252, 252, 1)",
                              boxShadow: "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                            }}
                            paddingLeft="7px"
                            paddingRight="7px"
                            borderRadius="10px"
                          >
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                              alignItems="center"
                            >
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  color: "#0B243C",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {condition?.title
                                  ? condition.title
                                  : "Sans titre"}
                              </span>
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Edit
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setEditConditionOpen(condition);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Delete
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setNewConditions((state) => {
                                    return {
                                      ...state,
                                      conditionsList:
                                        state.conditionsList.filter(
                                          (x) => x?.id !== condition.id
                                        ),
                                    };
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#0B243C",
                      textAlign: "end",
                    }}
                  >{`Aucune condition n'a été ajoutée`}</span>
                )}
              </Grid>
              <Grid item display="grid" marginTop="15px">
                <Grid
                  container
                  direction="row"
                  display="flex"
                  columnSpacing="15px"
                  rowSpacing="10px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid">
                    <GreyButton
                      animation={false}
                      enable={true}
                      text="Annuler"
                      onClick={() => {
                        setIsSectionEditPopup(null);
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <OrangeButton
                      animation={false}
                      enable={true}
                      type="submit"
                      text={"Modifier"}
                      onClick={() => {
                        const questionnaireDataTmp = {
                          ...questionnaireData,
                        };

                        questionnaireDataTmp.sections[
                          sectionInfos.index
                        ].title = newTitle;
                        questionnaireDataTmp.sections[
                          sectionInfos.index
                        ].conditionsInfos = newConditions;
                        setQuestionnaireData(questionnaireDataTmp);
                        setIsSectionEditPopup(null);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormPopup>
    </>
  );
};

export default SectionPopupEdit;
