import FullField from "../../../../Fields/FullField";
import * as React from "react";
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import { useDispatch } from "react-redux";
import Creatable from "react-select/creatable";
import HandleErrors from "../../../../Library/Errors/HandleErrors";
import addingErrorStyleToField from "../../../../../utils/addingErrorStyleToField";
import { IForm } from "../../../../../types/FormTypes";
import useAppSelector from "../../../../../redux/useAppSelector";

export const getFieldsLabelValue = (questionnaireData) => {
  const fieldsResult = [];
  for (const section of questionnaireData?.sections) {
    for (const fieldRow of section.fields) {
      for (const field of fieldRow) {
        if (field.id && field.title) {
          fieldsResult.push({ value: field.id, label: field.title });
        }
      }
    }
  }
  return fieldsResult;
};

export const GenericCreatabale = (props: {
  onChange: (e: any) => void;
  value: any[];
  options: any[];
  fieldTitle: string;
  id: string;
  isMandatory?: boolean;
  placeholder: string;
  noOptionMessage: string;
  maxMenuHeight?: number;
}) => {
  const {
    fieldTitle,
    value,
    onChange,
    options,
    isMandatory,
    id,
    placeholder,
    noOptionMessage,
    maxMenuHeight,
  } = props;
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  let style = {
    borderRadius: "10px",
    width: "100%",
    maxWidth: "800px",
    border: "1px solid transparent",
  };
  style = addingErrorStyleToField(style, isError);

  if (!isMandatory) {
    return (
      <FullField title={fieldTitle} isMandatory={isMandatory} spacing="1px">
        <div id={id} style={style}>
          <Creatable
            placeholder={placeholder}
            noOptionsMessage={() => `${noOptionMessage}`}
            formatCreateLabel={(userInput) => `...`}
            isMulti={true}
            value={value}
            maxMenuHeight={maxMenuHeight ? maxMenuHeight : undefined}
            options={options}
            onChange={onChange}
          />
        </div>
      </FullField>
    );
  }
  return (
    <FullField title={fieldTitle} isMandatory={isMandatory} spacing="1px">
      <HandleErrors
        isError={isError}
        isMandatory={true}
        errorMessage={errorMessage}
      >
        <div id={id} style={style}>
          <Creatable
            onFocus={(e) => {
              if (isError) {
                setErrorMessage("");
                setIsError(false);
              }
            }}
            onBlur={(e) => {
              if (!value || value?.length === 0) {
                if (!isError) {
                  setErrorMessage("Ce champ est obligatoire.");
                  setIsError(true);
                }
              } else {
                if (isError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }
            }}
            placeholder={placeholder}
            noOptionsMessage={() => `${noOptionMessage}`}
            formatCreateLabel={(userInput) => `...`}
            isMulti={true}
            value={value}
            maxMenuHeight={maxMenuHeight ? maxMenuHeight : undefined}
            options={options}
            onChange={onChange}
          />
        </div>
      </HandleErrors>
    </FullField>
  );
};

const DisplayAdditionAuto: React.FC<{ questionnaireData: Partial<IForm> }> = (props) => {
  const fieldCopy = useAppSelector(
    (state) => state.questionnaireReducer.fieldCopy
  );
  const { questionnaireData } = props;
  const dispatch = useDispatch();

  return (
    <GenericCreatabale
      id={"questionnaire-popup-addition-auto-options-input"}
      fieldTitle={"Champs à additionner"}
      isMandatory={true}
      placeholder={"Ajouter un champ"}
      noOptionMessage={"Aucun champ"}
      value={(() => {
        if (!fieldCopy?.option) return [];
        const options = [];
        const fieldsLabelValue = getFieldsLabelValue(questionnaireData)?.filter(
          (x) => x?.id !== fieldCopy?.id
        );
        for (const option of fieldCopy?.option) {
          const founded = fieldsLabelValue?.find((x) => x?.value == option);
          if (founded && founded?.label && founded?.value) {
            options.push(founded);
          }
        }
        return options;
      })()}
      options={(() => {
        const fieldsResult = [];
        for (const section of questionnaireData?.sections) {
          for (const fieldRow of section.fields) {
            for (const field of fieldRow) {
              if (field.id && field.title && field?.id !== fieldCopy?.id) {
                if (
                  !(fieldCopy?.option && fieldCopy?.option?.includes(field.id))
                ) {
                  fieldsResult.push({
                    value: field.id,
                    label: field.title,
                  });
                }
              }
            }
          }
        }
        return fieldsResult;
      })()}
      onChange={(e) => {
        const options = [];
        for (const result of e) {
          options.push(result?.value);
        }
        const fieldCopyTmp = { ...fieldCopy };
        fieldCopyTmp.option = options;
        dispatch(setFieldCopy(fieldCopyTmp));
      }}
    />
  );
};

export default DisplayAdditionAuto;
