import * as React from 'react';
import IFilterProps from './IFilterProps';
import { Grid } from '@mui/material';
import './FilterStyle.scss';
import SelectItemsRow from './SelectItems/SelectItemsRow';
import FilterByTextItem from './FilterByTextItem';
import ClearFilterItem from './ClearFilterItem';
import useAppSelector from '../../../redux/useAppSelector';

const FilterContainer = (props: IFilterProps) => {
  const componentRef = React.useRef();
  const windowWidth = useAppSelector(state => state.windowReducer.windowWidth);

  return (
    <Grid
      container
      columns={12}
      display='flex'
      rowSpacing='5px'
      direction={windowWidth > 600 ? 'row' : 'column'}
      justifyContent={'left'}
      alignItems='center'
      wrap={windowWidth > 600 ? undefined : "nowrap"}
      columnSpacing='14px'
      paddingTop='35px'
      paddingBottom='29px'
      ref={componentRef}>
      <Grid
        item
        display='grid'
        paddingBottom={'0px'}>
        <FilterByTextItem />
      </Grid>
      <Grid
        item
        xs
        display='grid'
        paddingLeft='0px'>
        <SelectItemsRow {...props} />
      </Grid>
      <Grid
        item
        display='grid'
        paddingLeft={'0px'}>
        <ClearFilterItem {...props} />
      </Grid>
    </Grid >
  );
};

export default FilterContainer;
