class SelectedAdvice {
  item?: string;

  constructor() {
    this.item = 'adviceSelected';
  }

  public getAll = () => {
    let values = [];
    const result = localStorage.getItem(this.item);

    if (result != null && result !== undefined) {
      values = JSON.parse(result);
    }
    return (values);
  };

  public clear = () => {
    localStorage.removeItem(this.item);
  }

  public isSelectedById = (id: string | number) => {
    const values = this.getAll();
    const index = values.findIndex(element => element.id == id)

    if (index != -1) {
      return (true);
    }
    return (false);
  }

  public deleteById = (id: string | number) => {
    const values = this.getAll();
    const indexToDelete = values.findIndex(element => element.id == id)

    if (indexToDelete != -1) {
      values.splice(indexToDelete, 1);
      localStorage.setItem(this.item, JSON.stringify(values));
    }
  };

  public add = (tutorial: object) => {
    const values = this.getAll();

    values.push(tutorial);
    localStorage.setItem("adviceSelected", JSON.stringify(values));
  };
}

export default SelectedAdvice;
