import { Grid } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import AdminGroupsBox from "../components/AdminGroups/AdminGroupsBox";
import makeAPIRequest from "../utils/makeAPIRequest";
import { updateSize } from "../redux/componentSizeReducer";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/snackbarReducer";
import useAppSelector from "../redux/useAppSelector";

const AdminGroupsPage: React.FC = (props: any) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const [usersList, setUsersList] = React.useState([]);
  const [groupSearched, setGroupSearched] = React.useState(null);
  const [groups, setGroups] = React.useState([]);
  const [groupsFiltered, setGroupsFiltered] = React.useState([]);
  const [groupsPerPage, setGroupsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [actualPage, setActualPage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    dispatch(updateSize("#admin-groups-box"));
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/users/list", null, "v3")
      .then((res) => {
        if (res?.data) {
          setUsersList(res.data);
        }
        makeAPIRequest("get", "/groups/list", null, "v3")
          .then((res) => {
            if (res?.data?.length > 0) setGroups(res?.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.error(err);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          openSnackbar({
            message: "Impossible de récupérer la liste des utilisateurs",
            type: "error",
            duration: 3000,
          })
        );
      });
  }, []);

  // Update groups filtered after groups change
  React.useEffect(() => {
    const groupsFilteredTmp = [...groups];

    setGroupsFiltered(groupsFilteredTmp);
    //setGroupsFiltered(groupsFilteredTmp?.filter((u) => groupSearched?.value != undefined ? u.account_type == groupSearched?.value : true))
  }, [groups, groupSearched]);

  // Update actual page after pages change
  React.useEffect(() => {
    if (actualPage === 0) return;
    if (actualPage > pages.length - 1) setActualPage(pages.length - 1);
    if (actualPage < 0) setActualPage(0);
  }, [actualPage, pages]);

  // Update pages after patient list filtered change
  React.useEffect(() => {
    const newPages = [];
    let index = 0;
    for (const group of groupsFiltered) {
      if (index % groupsPerPage === 0) {
        newPages.push([]);
      }
      const lastIndex = newPages.length - 1;
      if (newPages[lastIndex]) {
        newPages[lastIndex].push(group);
      }
      index++;
    }
    setPages(newPages);
  }, [groupsFiltered, groupsPerPage]);

  return (
    <FullPage>
      <Grid
        container
        id="admin-groups-box"
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_groups" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_groups" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
        >
          <AdminGroupsBox
            pages={pages}
            groupSearched={groupSearched}
            setGroupSearched={setGroupSearched}
            groups={groups}
            groupsFiltered={groupsFiltered}
            usersList={usersList}
            setGroups={setGroups}
            setPages={setPages}
            groupsPerPage={groupsPerPage}
            setGroupsPerPage={setGroupsPerPage}
            actualPage={actualPage}
            setActualPage={setActualPage}
            isLoading={isLoading}
            {...props}
          />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminGroupsPage;
