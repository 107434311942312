import { ClearRounded } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../../redux/snackbarReducer";
import FullField from "../../../Fields/FullField";
import AddButton from "../../../Library/Buttons/AddButton/AddButton";
import TextInput from "../../../Library/Inputs/TextInput";
import MySelect from "../../../Library/Select/MySelect";
import QuestionnaireUtils from "../../QuestionnaireUtils";

const DisplayConditionsList: React.FC<any> = (props) => {
  const { questionnaireData, setQuestionnaireData, stops } = props;

  return (
    <div
      className="table-grid-questionnaire-condition"
      style={{ overflow: "auto" }}
    >
      <span className="table-grid-title">Champ</span>
      <span className="table-grid-title">Valeur</span>
      <span className="table-grid-title"></span>
      {stops.map((stop, index) => {
        return (
          <>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre-line",
              }}
            >
              {stop?.field?.title ? stop?.field?.title : "Sans titre"}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre-line",
              }}
            >
              {stop?.valueEq ? stop?.valueEq : ""}
            </span>
            <Grid item display="grid" className="table-grid-text">
              <Tooltip title="Supprimer">
                <IconButton
                  onClick={() => {
                    const questionnaireDataTmp = {...questionnaireData};

                    for (let i = 0; i < questionnaireDataTmp?.sections?.length; i++) {
                      for (let j = 0; j < questionnaireDataTmp?.sections[i]?.fields?.length; j++) {
                        for (let k = 0; k < questionnaireDataTmp?.sections[i]?.fields[j]?.length; k++) {
                          if (questionnaireDataTmp?.sections[i]?.fields[j][k]?.id === stop?.field?.id) {
                            const index = questionnaireDataTmp?.sections[i]?.fields[j][k]?.stops?.findIndex((e) => e?.valueEq === stop?.valueEq);
                            questionnaireDataTmp.sections[i].fields[j][k].stops.splice(index, 1);
                            setQuestionnaireData(questionnaireDataTmp);
                            return;
                          }
                        }
                      }
                    }
                  }}
                  style={{
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <ClearRounded fontSize="small" style={{ color: "#FF3100" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        );
      })}
    </div>
  );
};

export default DisplayConditionsList;
