import React from "react";
import { Route, Redirect } from "react-router-dom";
import useQuery from "./utils/hooks/useQuery";
import makeAPIRequest from "./utils/makeAPIRequest";
import { getUser } from "./redux/new/userReducer";
import useAppDispatch from "./redux/useAppDispatch";
import { getActualTimeNumber } from "./components/NavbarComponent";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const tokenInQuery = useQuery().get("token");
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const exToken = localStorage.getItem("token");
    if (tokenInQuery && (tokenInQuery !== exToken || !localStorage.getItem("user"))) {
      localStorage.setItem("lastConnection", getActualTimeNumber().toString());
      localStorage.setItem("token", tokenInQuery);
      makeAPIRequest('get', "/user/0").then((response: any) => {
        console.log(response?.data);
        if (response.data) {
          const userData = {
            token: tokenInQuery,
            user_display_name: response.data?.data?.extra_user_meta?.[0]?.first_name + " " + response.data?.data?.extra_user_meta?.[0]?.last_name,
            user_email: response.data?.data?.extra_user_meta?.[0]?.email,
            user_id: response.data?.data?.extra_user_meta?.[0]?.id,
            user_nicename: response.data?.data?.user_meta?.nickname?.[0],
          };
          console.log(userData);
          console.log(JSON.stringify(userData));
          localStorage.setItem("user", JSON.stringify(userData))
          localStorage.setItem("user_id", response.data?.data?.extra_user_meta?.[0]?.id);
          setIsLoaded(true);
        }
      }).catch((err) => {
        setIsLoaded(true);
      })
    } else {
      setIsLoaded(true);
    }
  }, [tokenInQuery]);

  if (!isLoaded) {
    return (<></>)
  }
  return (
    <Route
      {...rest}
      render={(props: JSX.IntrinsicAttributes) => {
        const isAuth = localStorage.getItem("user");
        if (!isAuth) {
          return <Redirect to="/login" />;
        }

        return <Component {...props} {...rest} />;
      }}
    />
  );
};

export default PrivateRoute;
