import * as React from 'react';
import SquareCheckBoxField from "../CheckBox/SquareCheckBoxField";

interface IAdviceDisplayName {
  addName: boolean,
  onClick: any,
}

const AdviceDisplayName = (props: IAdviceDisplayName) => {
  const {
    addName,
    onClick,
  } = props;

  return (
    <SquareCheckBoxField text={"Afficher mon nom"} value={addName} onClick={onClick} />
  )
};

export default AdviceDisplayName;
