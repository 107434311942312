import * as React from 'react';
import { Grid } from "@mui/material";

interface IDoubleFieldRow {
  firstField: JSX.Element;
  secondField: JSX.Element;
  rowSpacing?: string | number;
}

const DoubleFieldRow = (props: IDoubleFieldRow) => {
  const {
    firstField,
    secondField,
    rowSpacing,
  } = props;

  return (
    <Grid container direction='row' flexWrap='nowrap' display='flex' justifyContent='space-between' rowSpacing={rowSpacing} columnSpacing='20px'>
      <Grid item xs display='grid' minWidth='170px'>
        {firstField}
      </Grid>
      <Grid item xs display='grid' minWidth='170px'>
        {secondField}
      </Grid>
    </Grid>
  );
};

DoubleFieldRow.default = {
  rowSpacing: '28px',
}

export default DoubleFieldRow;