import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import BoxTitle from "../components/Library/Box/BoxTitle";
import useUsersList from "../utils/hooks/useUsersList";
import MySelect from "../components/Library/Select/MySelect";
import Creatable from "react-select/creatable";
import { monthConverter } from "./AdminConsommationPage";
import makeAPIRequest from "../utils/makeAPIRequest";
import useAppSelector from "../redux/useAppSelector";
import { calcCostConseil } from "../components/MesConseilsBox/TabsContainer/AdminTab/AdminTabContent";
import { isPositiveFloat } from "../utils/isPositiveNumber";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import { IUser } from "../utils/UserClass";
import formatPhone from "../utils/format/formatPhone";
import { GenericSendButton } from "./ChatbotLexPage";
import { GenericCard } from "./QuestionnairePage";
import { fixDateInverse } from "../components/Questionnaire/Response/DisplayFormatedResponse";
import {
  CheckCircleRounded,
  PreviewRounded,
  ScheduleSendRounded,
  SendRounded,
} from "@mui/icons-material";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import {
  genericGetFromTimestamp,
  getDDMMYYYYFromTimestamp,
} from "../components/AdminGroups/CreateGroupPopup";

export interface FactureInvoicePlus {
  id: number;
  customer: Customer;
  customer_id: number;
  invoice_date: string;
  due_date: string;
  period_start: any;
  period_end: any;
  invoice_number: string;
  refund: boolean;
  reference: any;
  status: string;
  paid_status: string;
  title: any;
  notes: any;
  sub_total: number;
  tax: number;
  total: number;
  sended_date?: number;
  payment_method_id: any;
  lines: {
    item_id: any;
    is_title: boolean;
    title: string;
    name: string;
    replace_name: any;
    group_key: any;
    description: any;
    quantity: number;
    price: number;
    discount_type: string;
    discount: number;
    is_ttc: boolean;
    sub_total: number;
    tax: number;
    total: number;
    tax_type: any;
  }[];
}

interface Vente {
  title: string;
  quantity: number;
  price: number;
}

interface Facturation {
  _id?: string;
  title: string;
  ventes: Vente[];
  customer: Customer;
}

const getInfosFromStatsResult = (
  result: any
): {
  prix_achat_ht_du_pack: number;
  prix_de_vente_ht_du_pack: number;
  marge_percent: number;
  marge_ht: number;
  chiffres_affaires_ht: number;
  nb_ventes: number;
  type: string;
  title: string;
} => {
  const costHT = calcCostConseil(result) * 0.044;
  const margeHT = isPositiveFloat(result?.marge) ? parseFloat(result.marge) : 0;
  const venteHT = costHT + margeHT;
  const margePercent = ((venteHT - costHT) / venteHT) * 100;
  let nbVentes = 0;
  if (result?.type === "pack") {
    nbVentes = result?.pack_sended ? result.pack_sended : 0;
  } else if (result?.type === "simple") {
    nbVentes = result?.sms_sended ? result.sms_sended : 0;
  }
  let venteTotaleHT = nbVentes * venteHT;

  return {
    chiffres_affaires_ht: venteTotaleHT,
    marge_ht: margeHT,
    marge_percent: margePercent,
    prix_achat_ht_du_pack: costHT,
    prix_de_vente_ht_du_pack: venteHT,
    nb_ventes: nbVentes,
    type: result?.type,
    title: result?.title,
  };
};

interface Customer {
  _id?: string;
  customer_id?: number;
  user_id?: string;
  name?: string;
  company_name?: string;
  code?: string;
  account_code?: string;
  email?: string;
  phone?: string;
  crn?: string;
  vat?: string;
  billing_address_1?: string;
  billing_address_2?: string;
  billing_zip?: string;
  billing_city?: string;
}

export const StatisticsTableTitles: React.FC<{ isAdmin?: boolean }> = (
  props
) => {
  return (
    <>
      <span className="table-grid-title">Type</span>
      <span className="table-grid-title">Titre</span>
      <span className="table-grid-title">Packs envoyés</span>
      <span className="table-grid-title">SMS envoyés</span>
      <span className="table-grid-title">SMS programmés</span>
      {props.isAdmin && <span className="table-grid-title">Facturation</span>}
    </>
  );
};

const initCustomer = (user: IUser): Customer => {
  return {
    _id: undefined,
    account_code: user?.user_id ? user.user_id.toString() : "",
    billing_address_1: "",
    billing_address_2: "",
    billing_city: "",
    billing_zip: "",
    code: "C" + user?.user_id?.padStart(5, "0"),
    company_name: "",
    name: user?.first_name + " " + user?.last_name,
    crn: "",
    email: user?.email ? user.email : "",
    phone: user?.phone ? formatPhone(user.phone) : "",
    user_id: user?.user_id ? user.user_id.toString() : "",
    vat: "",
  };
};

const translateStatusFacture = (text: string) => {
  if (text === "DRAFT") {
    return "Brouillon";
  }
  if (text === "DONE") {
    return "Validé";
  }
  if (text === "UNPAID") {
    return "Non payé";
  }
  if (text === "PAID") {
    return "Payé";
  }
  return text;
};

export const DrawMyFacture: React.FC<{
  facture: FactureInvoicePlus;
  onFactureUpdate: (newFacture: FactureInvoicePlus) => void;
}> = (props) => {
  const { facture } = props;
  const snackbarOpenUse = useOpenSnackbar();
  const [viewFactureLoading, setViewFactureLoading] = React.useState(false);
  const [validateFactureLoading, setValidateFactureLoading] =
    React.useState(false);
  const [sendFactureLoading, setSendFactureLoading] = React.useState(false);

  return (
    <GenericCard
      leftButtons={[
        facture?.status === "DRAFT"
          ? {
              Icon: CheckCircleRounded,
              title: "Valider la facture",
              disable: validateFactureLoading,
              onClick: () => {
                setValidateFactureLoading(true);
                makeAPIRequest(
                  "get",
                  `/facturation/${facture?.id}/validate`,
                  null,
                  "v3"
                )
                  .then((res) => {
                    if (res?.data) {
                      props.onFactureUpdate({
                        customer: facture?.customer,
                        ...res.data,
                      });
                      snackbarOpenUse.success(
                        "La facture a bien été validée.",
                        res
                      );
                    }
                  })
                  .catch((err) => {
                    snackbarOpenUse.error(
                      "Impossible de valider cette facture.",
                      err
                    );
                  })
                  .finally(() => setValidateFactureLoading(false));
              },
              color: "rgb(11, 36, 60)",
            }
          : undefined,
        {
          Icon: PreviewRounded,
          title: "Voir la facture",
          disable: viewFactureLoading,
          onClick: () => {
            setViewFactureLoading(true);
            makeAPIRequest(
              "get",
              `/facturation/${facture?.id}/view_pdf`,
              null,
              "v3"
            )
              .then((res) => {
                window.open(res?.data);
              })
              .catch((err) => {
                snackbarOpenUse.error("Impossible de récupérer ce PDF.", err);
              })
              .finally(() => setViewFactureLoading(false));
          },
          color: "rgb(11, 36, 60)",
        },
        facture?.status === "DRAFT"
          ? undefined
          : {
              Icon: SendRounded,
              title: facture?.sended_date
                ? "Renvoyer par e-mail"
                : "Envoyer par e-mail",
              disable: sendFactureLoading,
              onClick: () => {
                setSendFactureLoading(true);
                makeAPIRequest(
                  "get",
                  `/facturation/${facture?.id}/send`,
                  null,
                  "v3"
                )
                  .then((res) => {
                    if (res?.data?.data) {
                      props.onFactureUpdate(res?.data?.data);
                    }
                    snackbarOpenUse.success(
                      "La facture a bien été envoyée.",
                      res
                    );
                  })
                  .catch((err) => {
                    snackbarOpenUse.error(
                      "Impossible d'envoyer la facture.",
                      err
                    );
                  })
                  .finally(() => setSendFactureLoading(false));
              },
              color: "rgb(11, 36, 60)",
            },
      ].filter((x) => x)}
      rightPin={
        facture?.sended_date
          ? "Envoyé"
          : translateStatusFacture(facture?.status)
      }
      textPin={translateStatusFacture(facture?.paid_status)}
      Header={
        <Grid
          container
          direction="column"
          wrap="nowrap"
          paddingBottom="10px"
          style={{
            fontSize: "16px",
            color: "#0B243C",
            fontFamily: "Poppins",
            textAlign: "left",
          }}
        >
          <Grid item display="grid">
            <Grid
              container
              direction="row"
              gap="20px"
              justifyContent={"space-between"}
            >
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid">
                    <span>Montant HT : {facture?.total}€</span>
                  </Grid>
                  <Grid item display="grid">
                    <span>
                      Créé le :{" "}
                      {fixDateInverse(facture?.invoice_date).replaceAll(
                        "-",
                        "/"
                      )}
                    </span>
                  </Grid>
                  <Grid item display="grid">
                    <span>
                      Date d'échéance :{" "}
                      {fixDateInverse(facture?.due_date).replaceAll("-", "/")}
                    </span>
                  </Grid>
                  {facture?.sended_date && (
                    <Grid item display="grid">
                      <span>
                        Envoyé le :{" "}
                        {genericGetFromTimestamp(
                          facture.sended_date,
                          "dd/mm/yyyy à hh:min"
                        )}
                      </span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item display="grid">
                {facture?.customer && (
                  <Grid container direction="column" wrap="nowrap">
                    {facture.customer?.name && (
                      <Grid item display="grid">
                        <span style={{ textAlign: "right" }}>
                          {facture?.customer?.name}
                        </span>
                      </Grid>
                    )}
                    {facture.customer?.email && (
                      <Grid item display="grid">
                        <span style={{ textAlign: "right" }}>
                          {facture?.customer?.email}
                        </span>
                      </Grid>
                    )}
                    {facture.customer?.phone && (
                      <Grid item display="grid">
                        <span style={{ textAlign: "right" }}>
                          {facture?.customer?.phone}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="10px"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item display="grid">
          <span
            style={{
              fontSize: "18px",
              textAlign: "center",
              fontWeight: 900,
              color: "#0B243C",
            }}
          >
            {facture?.lines
              ?.find((x) => x?.is_title === true)
              ?.title?.replaceAll("<h2>", "")
              ?.replaceAll("</h2>", "")}
          </span>
        </Grid>
      </Grid>
    </GenericCard>
  );
};

const AdminStatisticsBox: React.FC<any> = (props) => {
  const { isLoading, usersList } = props;
  const [userSelected, setUserSelected] = React.useState(null);
  const [periods, setPeriods] = React.useState([]);
  const [statsResults, setStatsResults] = React.useState([]);
  const userSelectedInfos = usersList?.find(
    (x) => x?.user_id == userSelected?.value
  );
  const [addOthersAccount, setAddOthersAccount] = React.useState<boolean>(true);
  const [sendedConseilsAdminLoading, setSendedConseilsAdminLoading] =
    React.useState(false);
  const [actualFactureLoading, setActualFactureLoading] = React.useState(false);
  const [createFactureLoading, setCreateFactureLoading] = React.useState(false);
  const [customerLoading, setCustomerLoading] = React.useState(false);
  const [displayFacturationAddress, setDisplayFacturationAddress] =
    React.useState(false);
  const [isCustomerCreate, setIsCustomerCreate] = React.useState(false);
  const [buttonCustomerLoading, setButtonCustomerLoading] =
    React.useState(false);
  const [actualFacture, setActualFacture] =
    React.useState<FactureInvoicePlus>(undefined);
  const [customer, setCustomer] = React.useState<Customer>(undefined);
  const snackbarOpenUse = useOpenSnackbar();
  const abonnementMensuel = userSelectedInfos?.abonnement_mensuel
    ? userSelectedInfos.abonnement_mensuel
    : 0;
  let CA_HT = abonnementMensuel;

  statsResults.forEach((statResult) => {
    if (!(statResult?.sousCompte && !addOthersAccount) || addOthersAccount) {
      statResult?.result?.forEach((result, index) => {
        const costHT = calcCostConseil(result) * 0.044;
        const margeHT = isPositiveFloat(result?.marge)
          ? parseFloat(result.marge)
          : 0;
        const venteHT = costHT + margeHT;
        let nbVentes = 0;
        if (result?.type === "pack") {
          nbVentes = result?.pack_sended ? result.pack_sended : 0;
        } else if (result?.type === "simple") {
          nbVentes = result?.sms_sended ? result.sms_sended : 0;
        }
        CA_HT += nbVentes * venteHT;
      });
    }
  });

  React.useEffect(() => {
    if (!userSelected?.value) {
      return;
    }
    setCustomerLoading(true);
    makeAPIRequest("get", `/customer/${userSelected.value}`, undefined, "v3")
      .then((res) => {
        if (res?.data) {
          setIsCustomerCreate(false);
          setCustomer(res?.data);
        } else {
          setIsCustomerCreate(true);
          setCustomer(initCustomer(userSelectedInfos));
        }
      })
      .catch((err) => {
        snackbarOpenUse.error(
          "Impossible de récupérer les informations de ce client",
          err
        );
      })
      .finally(() => setCustomerLoading(false));
  }, [userSelected]);

  React.useEffect(() => {
    if (!customer) {
      return;
    }
    if (periods?.length !== 1) {
      setActualFacture(undefined);
    } else {
      console.log(periods?.[0]);
      setActualFactureLoading(true);
      makeAPIRequest(
        "get",
        `/facturation/${periods?.[0]?.label}/${customer?.customer_id}`,
        null,
        "v3"
      )
        .then((res) => {
          setActualFacture(res?.data);
        })
        .catch((err) => {
          snackbarOpenUse.error(
            "Impossible de récupérer la facture de ce mois-ci.",
            err
          );
        })
        .finally(() => setActualFactureLoading(false));
    }
  }, [periods, customer]);

  React.useEffect(() => {
    if (periods.length === 0 || !userSelected) {
      setStatsResults([]);
      return;
    }
    const updateStatsResult = async () => {
      const tmpResult = [];
      for (const period of periods) {
        const tmpPeriodResult = {
          period: period?.label,
          result: [],
          sousCompte: false,
        };
        const monthTmp = period.value.split("-")[0];
        const yearTmp = period.value.split("-")[1];
        setSendedConseilsAdminLoading(true);
        const res = await makeAPIRequest(
          "post",
          "/conseils/sended_conseils_admin",
          {
            user_id: userSelected?.value,
            month: monthTmp,
            year: yearTmp,
          },
          "v2"
        );
        setSendedConseilsAdminLoading(false);
        if (res) {
          if (
            res?.data?.data?.sended_conseils &&
            typeof res.data.data.sended_conseils === "object"
          ) {
            const obj = res.data.data.sended_conseils;
            for (const key of Object.keys(obj)) {
              if (key && obj[key]?.title) {
                tmpPeriodResult.result.push(obj[key]);
              }
            }
            if (tmpPeriodResult.result.length > 0) {
              tmpResult.push(tmpPeriodResult);
            }
          }
          if (
            res?.data?.data?.others_sended_conseils?.length > 0 &&
            periods?.length === 1
          ) {
            const allUsersSends = res.data.data.others_sended_conseils;
            for (const userSend of allUsersSends) {
              const tmpSend = {
                result: [],
                period: userSend?.name,
                sousCompte: true,
              };
              const obj = userSend?.result;
              if (obj) {
                for (const key of Object.keys(obj)) {
                  if (key && obj[key]?.title) {
                    tmpSend.result.push(obj[key]);
                  }
                }
              }
              tmpResult.push(tmpSend);
            }
          }
        }
      }
      setStatsResults(tmpResult.filter((x) => x?.result?.length > 0));
    };
    updateStatsResult();
  }, [userSelected, periods]);

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        minWidth="320px"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid container display="flex" direction="column" wrap="nowrap">
            <Grid item display="grid">
              <BoxTitle first="Statistiques" second="conseils" />
            </Grid>
            <Grid item display="grid" paddingTop="30px">
              {!isLoading ? (
                <>
                  <Grid container direction="column" wrap="nowrap">
                    <Grid item display="grid">
                      <div
                        style={{
                          maxWidth: "500px",
                        }}
                      >
                        <MySelect
                          id="period-type"
                          isClearable={false}
                          placeholder="Sélectionner un utilisateur"
                          options={usersList.map((u) => {
                            return {
                              label: u?.email,
                              value: u?.user_id,
                            };
                          })}
                          onChange={(e) => {
                            setUserSelected(e);
                          }}
                          value={userSelected ? userSelected : null}
                        />
                      </div>
                    </Grid>
                    {userSelected && (
                      <Grid item display="grid" paddingTop="10px">
                        <div
                          style={{
                            maxWidth: "500px",
                          }}
                        >
                          <Creatable
                            noOptionsMessage={() => "Aucune option"}
                            formatCreateLabel={(userInput) => `...`}
                            isMulti={true}
                            options={(() => {
                              const periodsTmp = [];
                              const now = new Date();

                              for (let i = 4; i > -12; i--) {
                                const tmpDate = new Date(
                                  now.getFullYear(),
                                  now.getMonth() + 1 + i,
                                  0
                                );
                                const montInteger = tmpDate.getMonth() + 1;
                                periodsTmp.push({
                                  value: `${montInteger
                                    .toString()
                                    .padStart(
                                      2,
                                      "0"
                                    )}-${tmpDate.getFullYear()}`,
                                  label: `${
                                    monthConverter[montInteger]
                                  } ${tmpDate.getFullYear()}`,
                                });
                              }

                              return periodsTmp;
                            })()}
                            value={periods}
                            placeholder="Sélectionner des périodes"
                            onChange={(e: any) => {
                              setPeriods(e);
                            }}
                          />
                        </div>
                      </Grid>
                    )}
                    {userSelectedInfos &&
                      periods?.length > 0 &&
                      !sendedConseilsAdminLoading && (
                        <Grid
                          item
                          display="grid"
                          paddingTop="30px"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            whiteSpace: "pre-line",
                            color: "#0B243C",
                          }}
                        >
                          <span>
                            {`Abonnement mensuel fixe HT : ${abonnementMensuel.toFixed(
                              2
                            )}€\nChiffres d'affaires HT : ${CA_HT.toFixed(2)}€`}
                          </span>
                        </Grid>
                      )}
                    <Grid item display="grid" paddingTop="30px">
                      <Grid
                        container
                        direction="column"
                        spacing="30px"
                        wrap="nowrap"
                      >
                        {sendedConseilsAdminLoading && periods?.length > 0 && (
                          <Grid item display="grid">
                            <Grid
                              container
                              direction="row"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CircularProgress />
                            </Grid>
                          </Grid>
                        )}
                        {!sendedConseilsAdminLoading &&
                          periods?.length > 0 &&
                          statsResults
                            .filter((x) => {
                              if (!addOthersAccount && x?.sousCompte) {
                                return false;
                              }
                              return true;
                            })
                            .map((statResult, index) => {
                              return (
                                <Grid item display="grid" key={index}>
                                  <Grid
                                    container
                                    direction="column"
                                    spacing="25px"
                                    wrap="nowrap"
                                  >
                                    <Grid
                                      item
                                      display="grid"
                                      alignSelf="flex-end"
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "16px",
                                          color: "#0B243C",
                                        }}
                                      >
                                        {statResult?.period}
                                      </span>
                                    </Grid>
                                    <Grid item display="grid">
                                      <div
                                        key={index}
                                        className="table-others-statistics-grid"
                                        style={{
                                          overflow: "auto",
                                          gridTemplateColumns:
                                            "auto auto auto auto auto auto",
                                        }}
                                      >
                                        <StatisticsTableTitles isAdmin={true} />
                                        {statResult?.result?.map(
                                          (result, index) => {
                                            const all =
                                              getInfosFromStatsResult(result);
                                            const costHT =
                                              all.prix_achat_ht_du_pack;
                                            const margeHT = all.marge_ht;
                                            const venteHT =
                                              all.prix_de_vente_ht_du_pack;
                                            const margePercent =
                                              all.marge_percent;
                                            let venteTotaleHT =
                                              all.chiffres_affaires_ht;
                                            return (
                                              <>
                                                <span
                                                  className="table-grid-text"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: "pre-line",
                                                  }}
                                                >
                                                  {result?.type === "simple"
                                                    ? "Conseil unique"
                                                    : "Pack"}
                                                </span>
                                                <span
                                                  className="table-grid-text"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: "pre-line",
                                                  }}
                                                >
                                                  {result?.title}
                                                </span>
                                                <span
                                                  className="table-grid-text"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: "pre-line",
                                                  }}
                                                >
                                                  {result?.type === "pack"
                                                    ? result.pack_sended
                                                    : ""}
                                                </span>
                                                <span
                                                  className="table-grid-text"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: "pre-line",
                                                  }}
                                                >
                                                  {result?.sms_sended}
                                                  {result?.sended_phones
                                                    ?.length == 0
                                                    ? ""
                                                    : ` (${
                                                        result?.sended_phones
                                                          ?.length
                                                      } patient${
                                                        result?.sended_phones
                                                          ?.length > 1
                                                          ? "s"
                                                          : ""
                                                      })`}
                                                </span>
                                                <span
                                                  className="table-grid-text"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: "pre-line",
                                                  }}
                                                >
                                                  {result?.sms_scheduled}
                                                  {result?.schedule_phones
                                                    ?.length == 0
                                                    ? ""
                                                    : ` (${
                                                        result?.schedule_phones
                                                          ?.length
                                                      } patient${
                                                        result?.schedule_phones
                                                          ?.length > 1
                                                          ? "s"
                                                          : ""
                                                      })`}
                                                </span>
                                                <span
                                                  className="table-grid-text"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: "pre-line",
                                                  }}
                                                >
                                                  {`Prix d'achat HT du pack : ${costHT.toFixed(
                                                    3
                                                  )}€\n` +
                                                    `Prix de vente HT du pack : ${venteHT.toFixed(
                                                      3
                                                    )}€\n` +
                                                    `Marge HT : ${margeHT.toFixed(
                                                      2
                                                    )}€ (${margePercent.toFixed(
                                                      2
                                                    )}%)\n` +
                                                    `Chiffres d'affaires HT : ${venteTotaleHT.toFixed(
                                                      3
                                                    )}€\n`}
                                                </span>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })}
                        {statsResults.find((x) => x?.sousCompte) &&
                          !sendedConseilsAdminLoading && (
                            <Grid item display="grid" alignSelf={"flex-end"}>
                              <OrangeCheckBox
                                value={addOthersAccount}
                                text="Inclure les sous-comptes"
                                onClick={() => setAddOthersAccount((y) => !y)}
                              />
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>

            {userSelected ? (
              <>
                {customerLoading || sendedConseilsAdminLoading ? (
                  <>
                    {sendedConseilsAdminLoading ? (
                      <></>
                    ) : (
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="row"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CircularProgress />
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid item display="grid" paddingTop="20px">
                    <Grid container direction="column" wrap="nowrap" gap="10px">
                      {actualFacture && (
                        <Grid item display="grid" paddingBottom="20px">
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="10px"
                          >
                            <Grid item display="grid">
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  color: "#0B243C",
                                }}
                              >
                                Une facture a déjà été créée pour ce mois-ci,
                                elle est disponible ci-dessous.
                              </span>
                            </Grid>
                            <Grid item display="grid">
                              <DrawMyFacture
                                facture={actualFacture}
                                onFactureUpdate={(newFacture) =>
                                  setActualFacture(newFacture)
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {!isCustomerCreate &&
                        userSelectedInfos &&
                        periods?.length === 1 &&
                        !actualFactureLoading &&
                        !actualFacture &&
                        !sendedConseilsAdminLoading && (
                          <Grid
                            item
                            display="grid"
                            paddingTop="20px"
                            paddingBottom={"20px"}
                          >
                            <GenericSendButton
                              textButton={`Créer la facture du mois de ${periods?.[0]?.label?.toLowerCase()}`}
                              disablePaddingTop={true}
                              errorMessages={{}}
                              errorSelector={{}}
                              invalidConditions={{}}
                              isLoading={createFactureLoading}
                              onSendClick={() => {
                                let ventes: Vente[] = [];
                                if (abonnementMensuel) {
                                  ventes.push({
                                    price: abonnementMensuel,
                                    quantity: 1,
                                    title: "Abonnement mensuel fixe",
                                  });
                                }
                                statsResults?.forEach((statResult) => {
                                  const sousCompte = statResult?.sousCompte;
                                  if (sousCompte && !addOthersAccount) {
                                    return;
                                  }
                                  const result = statResult?.result;
                                  if (sousCompte) {
                                    const name = statResult?.period;
                                    ventes.push({
                                      price: undefined,
                                      quantity: undefined,
                                      title: name,
                                    });
                                  }
                                  result.forEach((x) => {
                                    const allInfos = getInfosFromStatsResult(x);
                                    ventes.push({
                                      price: allInfos.prix_de_vente_ht_du_pack,
                                      quantity: allInfos.nb_ventes,
                                      title:
                                        allInfos?.type === "pack"
                                          ? "Pack «" + allInfos.title + "»"
                                          : "Conseil «" + allInfos.title + "»",
                                    });
                                  });
                                });
                                statsResults?.[0]?.result?.forEach((x) => {
                                  const allInfos = getInfosFromStatsResult(x);
                                  ventes.push({
                                    price: allInfos.prix_de_vente_ht_du_pack,
                                    quantity: allInfos.nb_ventes,
                                    title:
                                      allInfos?.type === "pack"
                                        ? "Pack «" + allInfos.title + "»"
                                        : "Conseil «" + allInfos.title + "»",
                                  });
                                });
                                const facturation: Facturation = {
                                  customer: customer,
                                  ventes: ventes,
                                  title:
                                    "Facture mensuelle à la consommation - " +
                                    periods?.[0]?.label,
                                };
                                setCreateFactureLoading(true);
                                makeAPIRequest(
                                  "post",
                                  "/facturation/create",
                                  facturation,
                                  "v3"
                                )
                                  .then((res) => {
                                    snackbarOpenUse.success(
                                      "La facture a bien été créée",
                                      res
                                    );
                                    setActualFacture(res?.data?.data);
                                  })
                                  .catch((err) => {
                                    snackbarOpenUse.error(
                                      "Impossible de créer cette facture.",
                                      err
                                    );
                                  })
                                  .finally(() =>
                                    setCreateFactureLoading(false)
                                  );
                              }}
                            />
                          </Grid>
                        )}
                      {periods?.length === 1 &&
                        !actualFacture &&
                        !sendedConseilsAdminLoading && (
                          <>
                            <Grid item display="grid">
                              <span
                                style={{
                                  fontSize: "24px",
                                  color: "#0B243C",
                                  fontFamily: "Poppins",
                                  textAlign: "center",
                                  fontWeight: 700,
                                }}
                              >{`Adresse de facturation`}</span>
                            </Grid>
                            <Grid item display="grid" paddingTop="10px">
                              <Grid
                                container
                                direction="row"
                                gap="5px"
                                justifyContent={"center"}
                              >
                                <Grid item display="grid">
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      color: "#0B243C",
                                      fontFamily: "Poppins",
                                      textAlign: "left",
                                    }}
                                  >
                                    {isCustomerCreate
                                      ? `Pour créer vos factures, il faut obligatoirement créer une adresse de facturation pour ce client.`
                                      : `L'adresse de facturation de ce client a bien été renseignée, vous pouvez créer votre facture.`}
                                  </span>
                                </Grid>
                                {!isCustomerCreate && (
                                  <Grid item display="grid">
                                    <span
                                      onClick={() =>
                                        setDisplayFacturationAddress((x) => !x)
                                      }
                                      className="delete_advice_button"
                                      style={{
                                        color: "#0B243C",
                                        fontSize: "16px",
                                        fontFamily: "Poppins",
                                        textAlign: "left",
                                      }}
                                    >
                                      {!displayFacturationAddress
                                        ? `Ou afficher l'adresse de facturation`
                                        : `Ou cacher l'adresse de facturation`}
                                    </span>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )}
                      {(isCustomerCreate ||
                        (!isCustomerCreate && displayFacturationAddress)) &&
                        !actualFacture &&
                        periods?.length === 1 && (
                          <>
                            <Grid item display="grid">
                              <GenericTextInput
                                fieldTitleInPlaceholderOnly={true}
                                fieldTitle="Nom"
                                isMandatory={false}
                                id="input-name"
                                value={customer?.name}
                                onChange={(e) => {
                                  setCustomer((x) => {
                                    return { ...x, name: e.target.value };
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item display="grid">
                              <GenericTextInput
                                fieldTitleInPlaceholderOnly={true}
                                fieldTitle="Nom de la société"
                                isMandatory={false}
                                id="input-company-name"
                                value={customer?.company_name}
                                onChange={(e) => {
                                  setCustomer((x) => {
                                    return {
                                      ...x,
                                      company_name: e.target.value,
                                    };
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs display="grid">
                              <Grid container direction="row" gap="10px">
                                <Grid item xs display="grid">
                                  <GenericTextInput
                                    fieldTitleInPlaceholderOnly={true}
                                    fieldTitle="Code"
                                    isMandatory={false}
                                    id="input-code"
                                    value={customer?.code}
                                    onChange={(e) => {
                                      setCustomer((x) => {
                                        return { ...x, code: e.target.value };
                                      });
                                    }}
                                  />
                                </Grid>

                                <Grid item xs display="grid">
                                  <GenericTextInput
                                    fieldTitleInPlaceholderOnly={true}
                                    fieldTitle="Code du compte"
                                    isMandatory={false}
                                    id="input-account-code"
                                    value={customer?.account_code}
                                    onChange={(e) => {
                                      setCustomer((x) => {
                                        return {
                                          ...x,
                                          account_code: e.target.value,
                                        };
                                      });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs display="grid">
                              <Grid container direction="row" gap="10px">
                                <Grid item xs display="grid">
                                  <GenericTextInput
                                    fieldTitleInPlaceholderOnly={true}
                                    fieldTitle="Email"
                                    isMandatory={false}
                                    id="input-email"
                                    value={customer?.email}
                                    onChange={(e) => {
                                      setCustomer((x) => {
                                        return { ...x, email: e.target.value };
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs display="grid">
                                  <GenericTextInput
                                    fieldTitleInPlaceholderOnly={true}
                                    fieldTitle="Numéro de téléphone"
                                    isMandatory={false}
                                    id="input-phone"
                                    value={customer?.phone}
                                    onChange={(e) => {
                                      setCustomer((x) => {
                                        return { ...x, phone: e.target.value };
                                      });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs display="grid">
                              <Grid container direction="row" gap="10px">
                                <Grid item xs display="grid">
                                  <GenericTextInput
                                    fieldTitleInPlaceholderOnly={true}
                                    fieldTitle="CRN"
                                    isMandatory={false}
                                    id="input-crn"
                                    value={customer?.crn}
                                    onChange={(e) => {
                                      setCustomer((x) => {
                                        return { ...x, crn: e.target.value };
                                      });
                                    }}
                                  />
                                </Grid>

                                <Grid item xs display="grid">
                                  <GenericTextInput
                                    fieldTitleInPlaceholderOnly={true}
                                    fieldTitle="Numéro T.V.A"
                                    isMandatory={false}
                                    id="input-vat"
                                    value={customer?.vat}
                                    onChange={(e) => {
                                      setCustomer((x) => {
                                        return { ...x, vat: e.target.value };
                                      });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item display="grid">
                              <GenericTextInput
                                fieldTitleInPlaceholderOnly={true}
                                fieldTitle="Adresse de facturation"
                                isMandatory={false}
                                id="input-billing-adrdress-1"
                                value={customer?.billing_address_1}
                                onChange={(e) => {
                                  setCustomer((x) => {
                                    return {
                                      ...x,
                                      billing_address_1: e.target.value,
                                    };
                                  });
                                }}
                              />
                            </Grid>

                            <Grid item display="grid">
                              <GenericTextInput
                                fieldTitleInPlaceholderOnly={true}
                                fieldTitle="Complément d'adresse"
                                isMandatory={false}
                                id="input-billing-adrdress-2"
                                value={customer?.billing_address_2}
                                onChange={(e) => {
                                  setCustomer((x) => {
                                    return {
                                      ...x,
                                      billing_address_2: e.target.value,
                                    };
                                  });
                                }}
                              />
                            </Grid>

                            <Grid item xs display="grid">
                              <Grid container direction="row" gap="10px">
                                <Grid item xs display="grid">
                                  <GenericTextInput
                                    fieldTitleInPlaceholderOnly={true}
                                    fieldTitle="Code postal"
                                    isMandatory={false}
                                    id="input-billing-zip"
                                    value={customer?.billing_zip}
                                    onChange={(e) => {
                                      setCustomer((x) => {
                                        return {
                                          ...x,
                                          billing_zip: e.target.value,
                                        };
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item xs display="grid">
                                  <GenericTextInput
                                    fieldTitleInPlaceholderOnly={true}
                                    fieldTitle="Ville"
                                    isMandatory={false}
                                    id="input-billing-city"
                                    value={customer?.billing_city}
                                    onChange={(e) => {
                                      setCustomer((x) => {
                                        return {
                                          ...x,
                                          billing_city: e.target.value,
                                        };
                                      });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item display="grid">
                              <GenericSendButton
                                errorMessages={{}}
                                errorSelector={{}}
                                invalidConditions={{}}
                                onSendClick={() => {
                                  setButtonCustomerLoading(true);
                                  if (isCustomerCreate) {
                                    makeAPIRequest(
                                      "post",
                                      "/customers/create",
                                      customer,
                                      "v3"
                                    )
                                      .then((res) => {
                                        snackbarOpenUse.success(
                                          "Les informations de facturation de ce client ont bien été créés",
                                          res
                                        );
                                        setCustomer(res?.data?.data);
                                        setIsCustomerCreate(false);
                                      })
                                      .catch((err) => {
                                        snackbarOpenUse.error(
                                          "Les informations de facturation de ce client n'ont pas pu être créés",
                                          err
                                        );
                                      })
                                      .finally(() =>
                                        setButtonCustomerLoading(false)
                                      );
                                  } else {
                                    makeAPIRequest(
                                      "post",
                                      `/customers/${userSelected?.value}/update`,
                                      customer,
                                      "v3"
                                    )
                                      .then((res) => {
                                        snackbarOpenUse.success(
                                          "Les informations de facturation de ce client ont bien été mises à jour",
                                          res
                                        );
                                        setCustomer(res?.data?.data);
                                      })
                                      .catch((err) => {
                                        snackbarOpenUse.error(
                                          "Les informations de facturation de ce client n'ont pas pu être mises à jour",
                                          err
                                        );
                                      })
                                      .finally(() =>
                                        setButtonCustomerLoading(false)
                                      );
                                  }
                                }}
                                isLoading={buttonCustomerLoading}
                                textButton={
                                  isCustomerCreate ? "Créer" : "Mettre à jour"
                                }
                              />
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const AdminOthersStatisticsPage: React.FC<any> = (props) => {
  const { usersList } = useUsersList();
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_others_statistics" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_others_statistics" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={"1200px"}
          minWidth={1200}
        >
          <AdminStatisticsBox usersList={usersList} isLoading={isLoading} />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminOthersStatisticsPage;
