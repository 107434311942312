import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import OrangeButton from "../../components/Library/Buttons/OrangeButton/OrangeButton";
import FormPopupHeader from "../../components/FormPopup/FormPopupHeader";
import FormPopup from "../../components/FormPopup/FormPopup";
import FullField from "../../components/Fields/FullField";
import Creatable from "react-select/creatable";
import ErrorHelp from "../../components/Library/Errors/ErrorHelp";
import makeAPIRequest from "../../utils/makeAPIRequest";
import { openSnackbar } from "../../redux/snackbarReducer";
import MySelect from "../../components/Library/Select/MySelect";
import TextInput from "../../components/Library/Inputs/TextInput";
import isPositiveNumber from "../../utils/isPositiveNumber";
import getOnlyValuesFromArray from "../../utils/getOnlyValuesFromArray";
import IAlert from "./IAlert";

const errorMessages = {
  emptyUsersList: "Aucun destinataire n'a été ajouté",
  emptyAlertType: "Aucun type d'alerte n'a été selectionné",
  badLimit: "La limite à ne pas dépasser est invalide",
  badPeriod: "La période est invalide",
  "": "",
};

const errorSelector = {
  emptyUsersList: "#create-alert-list-members",
  emptyAlertType: "#type-alert",
  badLimit: "#limit-input",
  badPeriod: "#period-input",
  "": "",
};

const calcSeconds = (period, periodType) => {
  const periodNumber = parseInt(period);
  const periodTypeToSeconds = {
    "seconde(s)": 1,
    "minute(s)": 60,
    "heure(s)": 3600,
    "jour(s)": 86400,
    mois: 2592000,
  };
  const result = periodTypeToSeconds[periodType] * periodNumber;

  return result;
};

const CreateAlertPopup: React.FC<any> = (props) => {
  const { editInfos, setEditInfos, setCreateAlertIsOpen, alerts, setAlerts } = props;
  const [users, setUsers] = React.useState([]);
  const [alertType, setAlertType] = React.useState(null);
  const [error, setError] = React.useState("");
  const [period, setPeriod] = React.useState("5");
  const [eventLimit, setEventLimit] = React.useState("10");
  const [periodType, setPeriodType] = React.useState("minute(s)");
  const [reactions, setReactions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [usersList, setUsersList] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const infos: IAlert = editInfos;

    if (infos) {
      const usersTmp = [];
      for (const user of usersList) {
        if (user?.email && user.email?.length > 0) {
          if (infos.destinataires?.includes(user?.user_id)) {
            usersTmp.push({
              label: user?.email,
              value: user?.user_id,
            });
          }
        }
      }
      const reactionsTmp = [];
      for (const reaction of infos.reactions) {
        reactionsTmp.push({
          label: reaction,
          value: reaction,
        });
      }
      setUsers(usersTmp);
      setEventLimit(infos.limit.toString());
      setPeriod(infos.period.toString());
      setPeriodType("seconde(s)");
      setReactions(reactionsTmp);
      setAlertType(infos.alertType);
    }
  }, [editInfos, usersList]);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/users/list", null, "v3")
      .then((res) => {
        if (res?.data) {
          setUsersList(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        dispatch(
          openSnackbar({
            message: "Impossible de récupérer la liste des utilisateurs",
            type: "error",
            duration: 3000,
          })
        );
        setIsLoading(false);
      });
  }, []);

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyUsersList: !users || users?.length === 0,
      emptyAlertType: !alertType,
      badLimit: !eventLimit || !isPositiveNumber(eventLimit),
      badPeriod: !period || !isPositiveNumber(period),
    };
    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="50px"
      >
        <FormPopupHeader
          firstTitle={editInfos ? "Modifier" : "Créer"}
          secondTitle={"une alerte"}
          onClose={() => {
            setCreateAlertIsOpen(false);
            setEditInfos(null);
          }}
        />
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          display="flex"
          width="70%"
          justifySelf="center"
          direction="column"
          wrap="nowrap"
          paddingBottom="50px"
        >
          {isLoading && (
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingTop="15px"
            >
              <CircularProgress />
            </Grid>
          )}
          {!isLoading && (
            <>
              <Grid item display="grid" paddingTop="15px">
                <FullField isMandatory={true} title="Destinataires">
                  <div
                    id="create-alert-list-members"
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid transparent",
                    }}
                  >
                    <Creatable
                      noOptionsMessage={() => "Aucune option"}
                      formatCreateLabel={(userInput) => `...`}
                      isMulti={true}
                      maxMenuHeight={120}
                      options={(() => {
                        const optionsTmp = [];
                        for (const user of usersList) {
                          if (
                            user?.email &&
                            user?.account_type == "4" &&
                            user?.token &&
                            user.email?.length > 0
                          ) {
                            optionsTmp.push({
                              label: user?.email,
                              value: user?.user_id,
                            });
                          }
                        }
                        return optionsTmp;
                      })()}
                      value={users}
                      placeholder="Liste des adresses"
                      onChange={(e) => {
                        setUsers(
                          e?.filter((opt) =>
                            usersList?.find((user) => user?.email == opt?.label)
                          )
                        );
                      }}
                    />
                  </div>
                </FullField>
              </Grid>

              <Grid item display="grid" paddingTop="15px">
                <FullField title="Type d'alerte" isMandatory={true}>
                  <div style={{ width: "100%", padding: "0", margin: "0" }}>
                    <MySelect
                      maxMenuHeight="140px"
                      id="type-alert"
                      isClearable={false}
                      options={[
                        {
                          value: "Création de conseil",
                          label: "Création de conseil",
                        },
                        {
                          value: "Création de questionnaire",
                          label: "Création de questionnaire",
                        },
                        {
                          value: "Création de questionnaire interactif",
                          label: "Création de questionnaire interactif",
                        },
                        {
                          value: "Afficher plus de conseils",
                          label: "Afficher plus de conseils",
                        },
                      ]}
                      onChange={(e) => {
                        setAlertType(e?.value);
                      }}
                      value={{ label: alertType, value: alertType }}
                    />
                  </div>
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing="15px"
                >
                  <Grid item display="grid">
                    <FullField
                      title="Limite à ne pas dépasser"
                      isMandatory={true}
                    >
                      <div style={{ maxWidth: "80px" }}>
                        <TextInput
                          id="limit-input"
                          value={eventLimit}
                          onChange={(e) => {
                            setEventLimit(e.target.value);
                          }}
                        />
                      </div>
                    </FullField>
                  </Grid>
                  <Grid item display="grid">
                    <FullField title="Période" isMandatory={true}>
                      <Grid
                        container
                        direction="row"
                        spacing="5px"
                        wrap="nowrap"
                      >
                        <Grid item display="grid">
                          <div style={{ maxWidth: "80px" }}>
                            <TextInput
                              id="period-input"
                              value={period}
                              onChange={(e) => {
                                setPeriod(e.target.value);
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item display="grid" width="160px">
                          <MySelect
                            id="period-type"
                            isClearable={false}
                            maxMenuHeight="140px"
                            options={[
                              {
                                value: "seconde(s)",
                                label: "seconde(s)",
                              },
                              {
                                value: "minute(s)",
                                label: "minute(s)",
                              },
                              {
                                value: "heure(s)",
                                label: "heure(s)",
                              },
                              {
                                value: "jour(s)",
                                label: "jour(s)",
                              },
                              {
                                value: "mois",
                                label: "mois",
                              },
                            ]}
                            onChange={(e) => {
                              setPeriodType(e?.value);
                            }}
                            value={{ label: periodType, value: periodType }}
                          />
                        </Grid>
                      </Grid>
                    </FullField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item display="grid" paddingTop="15px">
                <FullField isMandatory={false} title="Réactions">
                  <div
                    id="reactions-list"
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid transparent",
                    }}
                  >
                    <Creatable
                      noOptionsMessage={() => "Aucune option"}
                      formatCreateLabel={(reactionInput) => `...`}
                      isMulti={true}
                      maxMenuHeight={120}
                      options={[]
                        /*[
                        {
                          label: "Expiration du compte",
                          value: "Expiration du compte",
                        },
                      ]
                    */}
                      value={reactions}
                      placeholder="Liste des réactions"
                      onChange={(e) => {
                        setReactions(e as any);
                      }}
                    />
                  </div>
                </FullField>
              </Grid>
              <Grid item display="grid">
                <Grid container direction="column" paddingTop="50px" wrap="nowrap">
                  {error?.length > 0 && (
                    <Grid item display="grid">
                      <ErrorHelp
                        errorMessages={errorMessages}
                        errorSelector={errorSelector}
                        error={error}
                      />
                    </Grid>
                  )}
                  <Grid item display="grid" justifyContent="center">
                    {isValidSubmit() ? (
                      <OrangeButton
                        animation={false}
                        enable={true}
                        type="submit"
                        text={editInfos ? "Modifier" : "Créer"}
                        onClick={
                          editInfos
                            ? () => {
                                let toSend = {
                                  alertType: alertType,
                                  limit: parseInt(eventLimit),
                                  period: calcSeconds(period, periodType),
                                  reactions: getOnlyValuesFromArray(reactions),
                                  destinataires: getOnlyValuesFromArray(users),
                                };
                                makeAPIRequest(
                                  "post",
                                  `/alert/${editInfos._id}/update`,
                                  toSend,
                                  "v3"
                                )
                                  .then((res) => {
                                    console.log(res);
                                    toSend['_id'] = editInfos?._id;
                                    const alertsTmp = [...alerts];
                                    const foundedIndex = alertsTmp.findIndex((e) => e?._id === editInfos?._id);
                                    if (foundedIndex !== -1) {
                                      alertsTmp[foundedIndex] = toSend;
                                      setAlerts(alertsTmp);
                                    }
                                    dispatch(
                                      openSnackbar({
                                        message: "L'alerte a bien mise à jour",
                                        duration: 3000,
                                        type: "success",
                                      })
                                    );
                                    setCreateAlertIsOpen(false);
                                    setEditInfos(null);
                                  })
                                  .catch((err) => {
                                    dispatch(
                                      openSnackbar({
                                        message:
                                          "L'alerte n'a pas pu être mise à jour",
                                        duration: 3000,
                                        type: "error",
                                      })
                                    );
                                  });
                              }
                            : () => {
                                let toSend = {
                                  alertType: alertType,
                                  limit: parseInt(eventLimit),
                                  period: calcSeconds(period, periodType),
                                  reactions: getOnlyValuesFromArray(reactions),
                                  destinataires: getOnlyValuesFromArray(users),
                                };
                                makeAPIRequest(
                                  "post",
                                  "/alert/create",
                                  toSend,
                                  "v3"
                                )
                                  .then((res) => {
                                    toSend['_id'] = res?.data?.id;
                                    const alertsTmp = [...alerts];
                                    alertsTmp.push(toSend);
                                    setAlerts(alertsTmp);
                                    dispatch(
                                      openSnackbar({
                                        message: "L'alerte a bien été créée",
                                        duration: 3000,
                                        type: "success",
                                      })
                                    );
                                    setCreateAlertIsOpen(false);
                                  })
                                  .catch((err) => {
                                    dispatch(
                                      openSnackbar({
                                        message:
                                          "L'alerte n'a pas pu être créée",
                                        duration: 3000,
                                        type: "error",
                                      })
                                    );
                                  });
                              }
                        }
                      />
                    ) : (
                      <OrangeButton
                        animation={false}
                        enable={false}
                        type="submit"
                        text={editInfos ? "Modifier" : "Créer"}
                        onClick={() => {
                          return null;
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </FormPopup>
  );
};

export default CreateAlertPopup;
