import { createSlice } from '@reduxjs/toolkit';

export const scheduleChatbot = createSlice({
  name: 'scheduleChatbot',
  initialState: {
    title: '',
    chatbotId: '',
    isOpen: false,
  },
  reducers: {
    openSchedule: (state, action) => {
      const { chatbotId, title } = action.payload;

      state.isOpen = true;
      state.chatbotId = chatbotId;
      state.title = title;
    },
    closeSchedule: (state) => {
      state.isOpen = false;
      state.chatbotId = '';
      state.title = '';
    },
  }
})

export const { openSchedule, closeSchedule } = scheduleChatbot.actions

export default scheduleChatbot.reducer;
