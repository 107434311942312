import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const GenericStackedBar: React.FC<{
  labels: string[];
  datasets: Array<{ label: string; data: number[]; backgroundColor: string }>;
  title?: string;
  titleSize?: number;
  displayLegend?: boolean;
}> = (props) => {
  const isEmptyData =
    !props.datasets ||
    props.datasets.length === 0 ||
    props.datasets.every((x) => x.data.length === 0);

  const data = {
    labels: isEmptyData ? ["Aucune donnée"] : props.labels,
    datasets: props.datasets,
  };
  const options = {
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
    animation: {
      animateRotate: false,
      animateScale: false,
    },
    legend: {
      display: props.displayLegend,
    },
    title: {
      display: props?.title ? true : false,
      text: props?.title,
      fontSize: props.titleSize,
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (isEmptyData) {
            return "";
          }
          const dataIndex = ctx.dataIndex;
          let total = 0;
          const datasets = ctx.chart.data.datasets;
          for (const dataset of datasets) {
            total += dataset.data[dataIndex];
          }
          const percentage = ((value / total) * 100).toFixed(1) + "%";
          if (percentage === "0.0%") {
            return "";
          }
          return percentage;
        },
        display: true,
        color: "white",
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  return (
    <>
      {/* @ts-expect-error Server Component */}
      <Bar data={data} plugins={[ChartDataLabels]} options={options} />
    </>
  );
};

GenericStackedBar.defaultProps = {
  title: "",
  displayLegend: true,
  titleSize: 20,
};

export default GenericStackedBar;
