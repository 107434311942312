import { Grid } from "@mui/material"
import ConfigPerPage from "./ConfigPerPage"
import PageNavigation from "./PageNavigation"
import React from 'react';

interface IBottomNavigation {
  perPage: number;
  setPerPage: any;
  actualPage: number;
  setActualPage: any;
  pages: Array<any>;
  text: string;
}

const BottomNavigation = (props: IBottomNavigation) => {
  const {
    text,
    perPage,
    setPerPage,
    actualPage,
    setActualPage,
    pages
  } = props;

  return (
    <Grid
    container
    direction="row"
    spacing="10px"
    alignItems="center"
    justifyContent="space-between"
  >
    <Grid item display="grid">
      <ConfigPerPage
        perPage={perPage}
        setPerPage={setPerPage} />
    </Grid>
    <Grid item display="grid">
      <PageNavigation
        actualPage={actualPage}
        setActualPage={setActualPage}
        pages={pages}
        text={text} />
    </Grid>
  </Grid>
  )
};

export default BottomNavigation;
