import { Grid } from "@mui/material";
import * as React from "react";
import BoxTitle from "../components/Library/Box/BoxTitle";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import useAppSelector from "../redux/useAppSelector";
import {
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import { monthConverter } from "./AdminConsommationPage";
import { GenericSelectMulti } from "./QuestionnairePage";
import makeAPIRequest from "../utils/makeAPIRequest";
import { StatisticsTableTitles } from "./AdminOthersStatisticsPage";

const StatistiquesConseils: React.FC<any> = (props) => {
  const snackbarOpenUse = useOpenSnackbar();
  const [statsResults, setStatsResults] = React.useState([]);
  const tmpDate = new Date();
  const montInteger = tmpDate.getMonth() + 1;
  const value = `${montInteger
    .toString()
    .padStart(
      2,
      "0"
    )}-${tmpDate.getFullYear()}`;
  const [periods, setPeriods] = React.useState([{
    value: value,
    label: `${monthConverter[montInteger]
      } ${tmpDate.getFullYear()}`
  }]);

  React.useEffect(() => {
    if (periods.length === 0) {
      return;
    }
    const updateStatsResult = async () => {
      const tmpResult = [];
      for (const period of periods) {
        const tmpPeriodResult = {
          period: period?.label,
          result: [],
        };
        const monthTmp = period.value.split("-")[0];
        const yearTmp = period.value.split("-")[1];
        const res = await makeAPIRequest(
          "post",
          "/conseils/sended_conseils_stats",
          {
            month: monthTmp,
            year: yearTmp,
          },
          "v2"
        );
        if (res) {
          if (
            res?.data?.data?.sended_conseils &&
            typeof res.data.data.sended_conseils === "object"
          ) {
            const obj = res.data.data.sended_conseils;
            for (const key of Object.keys(obj)) {
              if (key && obj[key]?.title) {
                tmpPeriodResult.result.push(obj[key]);
              }
            }
            if (tmpPeriodResult.result.length > 0) {
              tmpResult.push(tmpPeriodResult);
            }
          }
        }
      }
      setStatsResults(tmpResult);
    };
    updateStatsResult();
  }, [periods]);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="1360px"
      minWidth="600px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <BoxTitle first="Statistiques" second="conseils" />
      </Grid>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="8%"
        paddingRight="8%"
      >
        <Grid container direction="column" wrap="nowrap" gap="10px">
          <Grid item display="grid">
            <div
              style={{
                maxWidth: "500px",
              }}
            >
              <GenericSelectMulti id="multi-period" fieldTitle="Période" options={(() => {
                const periodsTmp = [];
                const now = new Date();

                for (let i = 4; i > -12; i--) {
                  const tmpDate = new Date(
                    now.getFullYear(),
                    now.getMonth() + 1 + i,
                    0
                  );
                  const montInteger = tmpDate.getMonth() + 1;
                  periodsTmp.push({
                    value: `${montInteger
                      .toString()
                      .padStart(
                        2,
                        "0"
                      )}-${tmpDate.getFullYear()}`,
                    label: `${monthConverter[montInteger]
                      } ${tmpDate.getFullYear()}`,
                  });
                }

                return periodsTmp;
              })()}
                value={periods}
                placeholder="Sélectionner"
                onChange={(e: any) => {
                  setPeriods(e);
                }} />
            </div>
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" spacing="30px" wrap="nowrap">
              {statsResults.map((statResult, index) => {
                return (
                  <Grid item display="grid" key={index}>
                    <Grid container direction="column" spacing="25px" wrap="nowrap">
                      <Grid item display="grid" alignSelf="flex-end">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#0B243C",
                          }}
                        >
                          {statResult?.period}
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <div
                          key={index}
                          className="table-others-statistics-grid"
                          style={{ overflow: "auto" }}
                        >
                          <StatisticsTableTitles />
                          {statResult?.result?.map(
                            (result, index) => {
                              return (
                                <>
                                  <span
                                    className="table-grid-text"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {result?.type === "simple"
                                      ? "Conseil unique"
                                      : "Pack"}
                                  </span>
                                  <span
                                    className="table-grid-text"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {result?.title}
                                  </span>
                                  <span
                                    className="table-grid-text"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {result?.type === "pack"
                                      ? result.pack_sended
                                      : ""}
                                  </span>
                                  <span
                                    className="table-grid-text"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {result?.sms_sended}{result?.sended_phones?.length == 0 ? '' : ` (${result?.sended_phones?.length} patient${result?.sended_phones?.length > 1 ? 's' : ''})`}
                                  </span>
                                  <span
                                    className="table-grid-text"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {result?.sms_scheduled}{result?.schedule_phones?.length == 0 ? '' : ` (${result?.sended_phones?.length} patient${result?.schedule_phones?.length > 1 ? 's' : ''})`}
                                  </span>
                                </>
                              );
                            }
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StatistiquesConseilsPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="statistiques_conseils" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="statistiques_conseils" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
        >
          <StatistiquesConseils {...props} />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default StatistiquesConseilsPage;
