import { Grid } from "@mui/material";
import * as React from "react";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import FullField from "../../../Fields/FullField";
import MultilineInput from "../../../Library/Inputs/MultilineInput";
import TextInput from "../../../Library/Inputs/TextInput";
import { emailValidator } from "../../SendResponsePopup";
import Creatable from "react-select/creatable";
import AddButton from "../../../Library/Buttons/AddButton/AddButton";
import { ConditionDisplayField, IForm } from "../../../../types/FormTypes";
import { Delete, Edit } from "@mui/icons-material";
import {
  CreateOrEditCondition,
  initConditionDisplayField,
  initConditionDisplayFieldInfos,
} from "../QuestionnairePopupEdit/QuestionnairePopupEdit";

const DisplaySendEmailAuto: React.FC<any> = (props) => {
  const { setQuestionnaireData } = props;
  const questionnaireData: IForm = props?.questionnaireData;
  const [createConditionOpen, setCreateConditionOpen] =
    React.useState<boolean>(false);
  const [editConditionOpen, setEditConditionOpen] =
    React.useState<ConditionDisplayField>(undefined);
  const conditionsInfos =
    questionnaireData?.sendEmailAfterResponseConditionsInfos;

  return (
    <>
      {createConditionOpen && (
        <CreateOrEditCondition
          isCreate={true}
          questionnaireData={questionnaireData}
          editInfos={initConditionDisplayField()}
          closePopup={() => setCreateConditionOpen(false)}
          pushNewCondition={(newCondition) => {
            const questionnaireDataTmp: IForm = {
              ...questionnaireData,
            };
            if (!questionnaireDataTmp?.sendEmailAfterResponseConditionsInfos) {
              questionnaireDataTmp.sendEmailAfterResponseConditionsInfos = initConditionDisplayFieldInfos();
            } else {
              questionnaireDataTmp.sendEmailAfterResponseConditionsInfos = {
                ...questionnaireDataTmp.sendEmailAfterResponseConditionsInfos,
              };
            }
            questionnaireDataTmp.sendEmailAfterResponseConditionsInfos.conditionsList = [
              ...questionnaireDataTmp.sendEmailAfterResponseConditionsInfos.conditionsList,
              newCondition,
            ];
            setQuestionnaireData(questionnaireDataTmp);
          }}
        />
      )}
      {editConditionOpen && (
        <CreateOrEditCondition
          questionnaireData={questionnaireData}
          isCreate={false}
          editInfos={editConditionOpen}
          closePopup={() => setEditConditionOpen(undefined)}
          editThisCondition={(conditionToEdit) => {
            const questionnaireDataTmp: IForm = {
              ...questionnaireData,
            };
            if (!questionnaireDataTmp?.sendEmailAfterResponseConditionsInfos) {
              questionnaireDataTmp.sendEmailAfterResponseConditionsInfos = initConditionDisplayFieldInfos();
            } else {
              questionnaireDataTmp.sendEmailAfterResponseConditionsInfos = {
                ...questionnaireDataTmp.sendEmailAfterResponseConditionsInfos,
              };
            }
            questionnaireDataTmp.sendEmailAfterResponseConditionsInfos.conditionsList = [
              ...questionnaireDataTmp.sendEmailAfterResponseConditionsInfos.conditionsList,
            ].map((condition) => {
              if (condition?.id === conditionToEdit?.id) {
                return conditionToEdit;
              }
              return condition;
            });
            setQuestionnaireData(questionnaireDataTmp);
          }}
        />
      )}
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid">
          <OrangeCheckBox
            value={questionnaireData?.sendEmailAfterResponse}
            onClick={() => {
              const questionnaireDataTmp = { ...questionnaireData };
              questionnaireDataTmp.sendEmailAfterResponse =
                !questionnaireDataTmp.sendEmailAfterResponse;
              setQuestionnaireData(questionnaireDataTmp);
            }}
            text="Recevoir un e-mail à chaque nouvelle réponse"
          />
        </Grid>
        {questionnaireData?.sendEmailAfterResponse && (
          <>
            <Grid
              item
              display="grid"
              paddingTop="10px"
              justifySelf={"flex-end"}
            >
              <AddButton
                onClick={() => {
                  setCreateConditionOpen(true);
                }}
                addId={"add-condition-affichage-button"}
                displayText={true}
                text="Ajouter une condition d'envoi"
              />
            </Grid>
            <>
              {conditionsInfos?.conditionsList &&
              conditionsInfos?.conditionsList?.length > 0 ? (
                <Grid item display="grid" paddingTop="10px">
                  <Grid container direction="row" gap="10px">
                    {conditionsInfos.conditionsList.map((condition, index) => {
                      return (
                        <Grid item display="grid" key={index}>
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            style={{
                              border: "1px solid rgba(11, 36, 60)",
                              backgroundColor: "rgba(247, 252, 252, 1)",
                              boxShadow: "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                            }}
                            paddingLeft="7px"
                            paddingRight="7px"
                            borderRadius="10px"
                          >
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                              alignItems="center"
                            >
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  color: "#0B243C",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {condition?.title
                                  ? condition.title
                                  : "Sans titre"}
                              </span>
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Edit
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setEditConditionOpen(condition);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              display="grid"
                              paddingLeft="5px"
                              paddingRight="5px"
                            >
                              <div
                                style={{
                                  height: "100%",
                                  borderLeft: "1px solid #0B243C",
                                }}
                              />
                            </Grid>
                            <Grid item display="grid" alignItems="center">
                              <Delete
                                style={{
                                  color: "#0B243C",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const questionnaireDataTmp: IForm = {
                                    ...questionnaireData,
                                  };
                                  if (
                                    !questionnaireDataTmp?.sendEmailAfterResponseConditionsInfos
                                  ) {
                                    questionnaireDataTmp.sendEmailAfterResponseConditionsInfos =
                                      initConditionDisplayFieldInfos();
                                  } else {
                                    questionnaireDataTmp.sendEmailAfterResponseConditionsInfos =
                                      {
                                        ...questionnaireDataTmp.sendEmailAfterResponseConditionsInfos,
                                      };
                                  }
                                  questionnaireDataTmp.sendEmailAfterResponseConditionsInfos.conditionsList =
                                    [
                                      ...questionnaireDataTmp
                                        .sendEmailAfterResponseConditionsInfos
                                        .conditionsList,
                                    ].filter((x) => x.id !== condition.id);
                                  setQuestionnaireData(questionnaireDataTmp);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              ) : (
                <Grid item display="grid" paddingTop="10px">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#0B243C",
                      textAlign: "end",
                    }}
                  >{`Aucune condition n'a été ajoutée`}</span>
                </Grid>
              )}
            </>
            <Grid item display="grid" paddingTop="15px">
              <FullField isMandatory={true} title={`Destinataires`}>
                <div
                  id="questionnaire-email-destinataires"
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid transparent",
                  }}
                >
                  <Creatable
                    noOptionsMessage={() =>
                      "Tapez l'adresse email du destinataire"
                    }
                    formatCreateLabel={(userInput) => `${userInput}`}
                    isMulti={true}
                    maxMenuHeight={120}
                    options={(() => {
                      const optionsTmp = [];
                      return optionsTmp;
                    })()}
                    value={
                      questionnaireData?.emailDestinataires?.length > 0
                        ? questionnaireData.emailDestinataires.map((x) => {
                            return { label: x, value: x };
                          })
                        : []
                    }
                    placeholder="Liste des destinataires"
                    onChange={(e: any) => {
                      const questionnaireDataTmp = { ...questionnaireData };
                      questionnaireDataTmp.emailDestinataires = e
                        ?.filter((opt: any) => emailValidator(opt?.label))
                        ?.map((x) => x?.value);
                      setQuestionnaireData(questionnaireDataTmp);
                    }}
                  />
                </div>
              </FullField>
            </Grid>
            <Grid item display="grid" paddingTop="10px">
              <FullField isMandatory={false} title="Objet">
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid">
                    <span
                      style={{
                        color: "#0B243C",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        letterSpacing: "0px",
                        fontSize: "14px",
                      }}
                    >
                      Vous pouvez utiliser les variables ci-dessous :
                    </span>
                  </Grid>
                  <Grid item display="grid">
                    <span
                      style={{
                        color: "#0B243C",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        letterSpacing: "0px",
                        fontSize: "14px",
                      }}
                    >
                      {
                        "{nom_questionnaire} - {id_patient} - {groupe_patient} - {date_reponse}"
                      }
                    </span>
                  </Grid>
                </Grid>
              </FullField>
            </Grid>
            <Grid item display="grid" paddingTop="5px">
              <TextInput
                value={questionnaireData?.emailObject}
                onChange={(e) => {
                  const questionnaireDataTmp = { ...questionnaireData };
                  questionnaireDataTmp.emailObject = e?.target?.value;
                  setQuestionnaireData(questionnaireDataTmp);
                }}
              />
            </Grid>
            <Grid item display="grid" paddingTop="10px">
              <FullField isMandatory={false} title="Contenu">
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid">
                    <span
                      style={{
                        color: "#0B243C",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        letterSpacing: "0px",
                        fontSize: "14px",
                      }}
                    >
                      Vous pouvez utiliser les variables ci-dessous :
                    </span>
                  </Grid>
                  <Grid item display="grid">
                    <span
                      style={{
                        color: "#0B243C",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        letterSpacing: "0px",
                        fontSize: "14px",
                      }}
                    >
                      {
                        "{reponses_questionnaire} - {id_patient} - {groupe_patient} - {date_reponse} - {nom_questionnaire} - {url_reponse}"
                      }
                    </span>
                  </Grid>
                </Grid>
              </FullField>
            </Grid>
            <Grid item display="grid" paddingTop="5px">
              <MultilineInput
                height="150px"
                maxLength={500}
                whiteSpace="pre-line"
                value={questionnaireData?.emailMessage}
                onChange={(e) => {
                  const questionnaireDataTmp = { ...questionnaireData };
                  questionnaireDataTmp.emailMessage = e;
                  setQuestionnaireData(questionnaireDataTmp);
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DisplaySendEmailAuto;
