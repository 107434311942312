
import * as React from "react";
import { Grid } from "@mui/material";
import IPatientData from "../../types/Patient";

const getPatientGroupName = (
  patient: IPatientData,
  patientGroupsList: any[]
) => {
  const groupId = patient?.group;
  const foundedGroup = patientGroupsList?.find((x) => x?.id == groupId);
  return foundedGroup?.group_name ? foundedGroup?.group_name : "";
};

const ExportPatientsButton: React.FC<any> = (props) => {
  const { patientList, patientGroupsList } = props;

  return (
    <button
      style={{
        boxShadow: "2px 6px 10px #0F213467",
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: "20px",
        maxWidth: "330px",
        maxHeight: "32px",
        paddingRight: "20px",
        borderRadius: "18px",
        opacity: 1,
        background: "#0B243C 0% 0% no-repeat padding-box",
        letterSpacing: "0px",
        color: "#FFFFFF",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "12px",
        fontFamily: "Poppins",
        justifySelf: "flex-end",
      }}
      onClick={() => {
        if (
          !patientList ||
          patientList?.length == 0 ||
          !Array.isArray(patientList)
        ) {
          return;
        }
        const lines = [];

        const fixDob = (beforeDob: string) => {
          if (!beforeDob) {
            return "";
          }
          if (beforeDob.includes("T")) {
            const splited = beforeDob?.split("T")?.[0]?.split("-");
            if (splited.length !== 3) {
              return "";
            }
            return `${splited[2]}-${splited[1]}-${splited[0]}`;
          } else {
            const splited = beforeDob?.split("-");
            if (splited.length !== 3) {
              return "";
            }
            return `${splited[2]}-${splited[1]}-${splited[0]}`;
          }
        };

        const fixSexe = (beforeSexe: string) => {
          if (!beforeSexe) {
            return "";
          }
          if (beforeSexe === "male") {
            return "Homme";
          }
          if (beforeSexe === "female") {
            return "Femme";
          }
          return "";
        };

        lines.push([
          "ID (*)",
          "Nom",
          "Prenom",
          "Tel patient (*)",
          "INS",
          "Email",
          "Genre",
          "Date de naissance",
          "Lieu de naissance",
          "Groupe",
          "Numéro patient",
        ]);
        for (const p of patientList) {
          lines.push([
            `=""${p?.id ? p.id : ""}""`,
            `=""${p?.lastname ? p.lastname : ""}""`,
            `=""${p?.firstname ? p.firstname : ""}""`,
            `=""${p?.phone ? p.phone : ""}""`,
            `=""${p?.INS ? p.INS : "0000000000000"}""`,
            `=""${p?.email ? p.email : ""}""`,
            `=""${p?.sexe ? fixSexe(p.sexe) : ""}""`,
            `=""${p?.dob ? fixDob(p.dob) : ""}""`,
            `=""${p?.city_INSEE ? p.city_INSEE : ""}""`,
            `=""${getPatientGroupName(p, patientGroupsList)}""`,
            `=""${p?.numero_patient ? p.numero_patient : ""}""`,
          ]);
        }

        let csv = "";
        for (let i = 0; i < lines.length; i++) {
          for (let j = 0; j < lines[i].length; j++) {
            csv += `"${lines[i][j]}"`;
            if (j === lines[i].length - 1) {
              csv += "\n";
            } else {
              csv += ";";
            }
          }
        }

        const universalBOM = "\uFEFF";
        const element = window.document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/csv; charset=utf-8," +
            encodeURIComponent(universalBOM + csv)
        );
        element.setAttribute(
          "download",
          `patients-${Date.now()}`.replaceAll(" ", "-")
        );
        window.document.body.appendChild(element);
        element.click();

        return csv;
      }}
    >
      <Grid item display="grid">
        <Grid
          container
          spacing="7px"
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item display="grid">
            <span>Exporter mes patients</span>
          </Grid>
        </Grid>
      </Grid>
    </button>
  );
};

export default ExportPatientsButton;
