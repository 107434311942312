import * as React from "react";

const AlertsListTableTitles = () => {
  return (
    <>
      <span className="table-grid-title">Type</span>
      <span className="table-grid-title">Limite</span>
      <span className="table-grid-title">Période</span>
      <span className="table-grid-title">Destinataires</span>
      <span className="table-grid-title">Réactions</span>
      <span className="table-grid-title"></span>
    </>
  );
};

export default AlertsListTableTitles;
