export const convertOrangeDiffusionState = (status: string) => {
  if (!status) { return (''); }
  const converter = {
    'WAITING': `Diffusion en préparation pour l'envoi`,
    'ONGOING': `Diffusion en cours`,
    'COMPLETED': `Diffusion terminée`,
    'CANCELING': `Diffusion en cours d'annulation`,
    'CANCELED': `Diffusion annulée`,
  }
  if (converter?.[status]) {
    return (converter[status]);
  }
  return ('');
}

const convertSmsModeStatus = (status: number) => {
  if (!status) return ('');
  const converter = {
    2: 'Erreur interne',
    11: 'Reçu',
    13: 'Délivré opérateur',
    14: 'Délivré',
    15: 'Partiellement reçu',
    21: 'Non deliverable',
    22: 'Rejeté',
    33: 'Non envoyé',
    34: 'Erreur routage',
    35: 'Erreur réception',
    37: 'Message expiré',
    38: 'Message trop long',
    50: 'Non déliveré',
    100: 'Lu',
    101: 'Non lu',
    3501: 'Erreur opérateur',
    3502: 'Erreur temporaire absence',
    3521: 'Erreur opérateur',
    3503: 'Erreur téléphone',
    3523: 'Erreur téléphone',
    3524: 'Erreur anti spam',
    3998: 'Destinataire invalide',
    3999: 'Bloqué par STOP SMS',
  }
  if (!Object.keys(converter).includes(status.toString())) return ('Erreur inconnue');
  return (converter[status]);
}

export default convertSmsModeStatus;
