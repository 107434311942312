import { Grid } from '@mui/material';
import * as React from 'react';
import addingErrorStyleToField from '../../../utils/addingErrorStyleToField';

interface ITextInput {
  onChange: any,
  maxWidth?: string | number,
  value?: string | number,
  type?: string,
  onBlur?: any,
  isError?: boolean,
  RightIcon?: JSX.Element,
}

const TextInput = (props: ITextInput | any) => {
  const {
    maxWidth,
    onChange,
    value,
    isError,
    type,
    onBlur,
    RightIcon,
    disabled,
  } = props;

  let style = {
    margin: '0px 0px 0px 0px', width: '100%', padding: props?.padding,
    maxWidth: maxWidth, background: RightIcon ? 'none' : props?.background,
    minWidth: props?.minWidth,
    fontFamily: 'Poppins', color: '#0B243C', fontSize: '16px', letterSpacing: '0px',
    borderRadius: props?.borderRadius, outline: RightIcon ? 'none' : null,
    border: RightIcon ? 'none' : props?.border, textAlign: props?.textAlign, height: props?.height,
    cursor: disabled ? "not-allowed" : undefined,
  }
  style = addingErrorStyleToField(style, isError);

  if (RightIcon) {
    return (
      <Grid container direction='row' wrap='nowrap' justifyContent='space-between' alignItems='center' paddingRight='10px'
        style={{
          width: props?.width ? props?.width : '100%', borderRadius: '15px', maxWidth: maxWidth, border: '1px solid #DDD',
          background: '#edf2f280 0% 0% no-repeat padding-box',
        }}>
        <Grid item display='grid' xs>
          <input
            id={props?.id}
            onChange={(e) => onChange(e)}
            value={value}
            onBlur={onBlur}
            onFocus={props?.onFocus}
            placeholder={props?.placeholder}
            readOnly={(props?.readOnly || disabled) ? true : false}
            type={type === 'number' || type === 'password' ? type : "text"}
            className={'input_modals' + `${type === 'number' ? ' number-input-no-apparence' : ''}` + `${props?.className ? ` ${props?.className}` : ''}`}
            style={style} />
        </Grid>
        <Grid item display='grid'>
          {RightIcon}
        </Grid>
      </Grid>
    )
  }
  return (
    <div style={{ width: props?.width ? props?.width : '100%', maxWidth: maxWidth }}>
      <input
        id={props?.id}
        onChange={(e) => onChange(e)}
        value={value}
        onBlur={onBlur}
        onFocus={props?.onFocus}
        readOnly={(props?.readOnly || disabled) ? true : false}
        placeholder={props?.placeholder}
        type={type === 'number' || type === 'password' ? type : "text"}
        className={'input_modals' + `${type === 'number' ? ' number-input-no-apparence' : ''}` + `${props?.className ? ` ${props?.className}` : ''}`}
        style={style} />
    </div>
  )
};

export default TextInput;