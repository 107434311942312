import FullField from "../../../../Fields/FullField";
import * as React from "react";
import MySelect from "../../../../Library/Select/MySelect";
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import { useDispatch } from "react-redux";
import useAppSelector from "../../../../../redux/useAppSelector";

const DisplayAlertType: React.FC<any> = (props) => {
  const fieldCopy = useAppSelector(
    (state) => state.questionnaireReducer.fieldCopy
  );
  const dispatch = useDispatch();

  const typeAlertOptions = [
    {
      value: '',
      label: "Aucun",
    },
    {
      value: "one_match",
      label: "Contient l'un des mots-clés",
    },
    {
      value: "all_match",
      label: "Contient plusieurs mots-clés",
    },
  ];

  const getLabel = () => {
    const result = typeAlertOptions?.find(
      (element) => element?.value === fieldCopy?.alertType
    )?.label;

    if (result && result?.length > 0) {
      return result;
    }
    return 'Aucun';
  };
  return (
    <FullField title="Type d'alerte" isMandatory={false} spacing="1px">
      <MySelect
        isClearable={false}
        maxMenuHeight={"200px"}
        id="questionnaire-popup-type-alert-input"
        value={{
          value: fieldCopy?.alertType,
          label: getLabel(),
        }}
        options={typeAlertOptions}
        onChange={(e) => {
          const fieldCopyTmp = { ...fieldCopy };
          fieldCopyTmp.alertType = e?.value;
          console.log(fieldCopyTmp.alertType);
          dispatch(setFieldCopy(fieldCopyTmp));
        }}
      />
    </FullField>
  );
};

export default DisplayAlertType;
