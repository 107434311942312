const formatDestinataireTarget = (target: string) => {
  if (!target) return ('');
  let newTarget = target.replace('0033', '0');

  if (!newTarget.includes(' ') && newTarget?.length === 10) {
    let tmp = '';
    for (const digit of newTarget) {
      tmp += digit;
      if (tmp?.length % 3 === 2) tmp += ' '
    }
    newTarget = tmp;
  }
  return (newTarget);
}

export default formatDestinataireTarget;