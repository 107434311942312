import * as React from "react";

const DisplayTitle: React.FC<any> = (props) => {
  const { questionnaireData } = props;

  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "20px",
        color: "#0B243C",
        fontWeight: "bold",
      }}
    >
      {questionnaireData?.visibleTitle
        ? questionnaireData?.visibleTitle
        : questionnaireData?.title}
    </span>
  );
};

export default DisplayTitle;
