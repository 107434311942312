import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import "./GreenButton.scss";

interface IGreenButtonProps {
  text?: string;
  onClick?: any;
  enable?: boolean;
  type?: any;
  animation?: boolean;
  style?: any;
  isLoading?: boolean;
}

const GreenButton = (props: IGreenButtonProps) => {
  if (props?.isLoading) {
    return (
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <button
      disabled={!props.enable}
      className={`green-button${
        props.enable ? " enable-green-button" : " disable-green-button"
      }${props?.animation ? " button-animation" : ""}`}
      onClick={props.onClick}
      type={props.type}
      style={props?.style}
    >
      {props.text}
    </button>
  );
};

GreenButton.defaultProps = {
  text: "Default",
  isLoading: false,
  enable: true,
  onClick: () => {
    console.error(
      "You forgot the 'onClick' attribute in the 'GreenButton' component"
    );
  },
};

export default GreenButton;
