const resizeText = (text: string, maxLength: number) => {
  const title = text;
  const MAX_SIZE = maxLength;

  if (title?.length <= MAX_SIZE)
    return (title);

  let result = title.substring(0, MAX_SIZE);

  if (result.includes(' ')) {
    const splited = result.split(' ');
    let newResult = '';

    for (let i = 0; i < splited?.length - 1; i++) {
      newResult += splited[i];
      if (i != splited?.length - 2) {
        newResult += ' ';
      }
    }
    newResult += '...';
    return (newResult);
  } else {
    result += '...';
  }

  return (result);
};

export default resizeText;