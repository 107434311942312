import FullField from "../FullField";
import * as React from 'react';
import TextInput from "../../Library/Inputs/TextInput";

interface IPatientCityINSEEField {
  patientCityINSEE: string,
  onChange: any,
}

const PatientCityINSEEField = (props: IPatientCityINSEEField) => {
  const {
    patientCityINSEE,
    onChange,
  } = props;

  return (
    <FullField title='Lieu de naissance' isMandatory={false}>
      <TextInput
        value={patientCityINSEE}
        onChange={(e) => onChange(e)} />
    </FullField>
  );
};

export default PatientCityINSEEField;