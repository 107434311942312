import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import * as React from "react";
import FullPage from "./FullPage";
import { Grid } from "@mui/material";
import { Tab, Tabs } from "react-bootstrap";
import { GenericSelect } from "./QuestionnairePage";
import RequestTokenProd from "../assets/RequestToken.png";
import RequestTokenStaging from "../assets/RequestTokenStaging.png";
import RequestAccountsProd from "../assets/RequestAccounts.png";
import RequestAccountsStaging from "../assets/RequestAccountsStaging.png";
import RequestAccountTokenProd from "../assets/RequestAccountToken.png";
import RequestAccountTokenStaging from "../assets/RequestAccountTokenStaging.png";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";
import { ButtonIconWithTooltip } from "../components/Questionnaire/PDF/CreateOrEditPDFQuestionnaire";

const BigStep: React.FC<{
  title: string;
  closed?: boolean;
}> = (props) => {
  const [isClosed, setIsClosed] = React.useState(props.closed ? true : false);
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <Grid container direction="row" wrap="nowrap" gap="5px">
          <Grid item display="grid">
            <ButtonIconWithTooltip
              tooltipText={isClosed ? "Ouvrir" : "Fermer"}
              onClick={() => {
                setIsClosed((x) => !x);
              }}
              Icon={
                !isClosed ? (
                  <ArrowDropDownOutlined
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#0B243C",
                    }}
                  />
                ) : (
                  <ArrowDropUpOutlined
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#0B243C",
                    }}
                  />
                )
              }
            />
          </Grid>
          <Grid item display="grid">
            <span style={{ fontWeight: "bold" }}>{`${props.title}`}</span>
          </Grid>
        </Grid>
      </Grid>
      {!isClosed && (
        <Grid item display="grid" marginLeft="50px" marginTop="10px">
          {props.children}
        </Grid>
      )}
    </Grid>
  );
};

const Step: React.FC<{
  number: number;
  title: string;
  closed?: boolean;
}> = (props) => {
  const [isClosed, setIsClosed] = React.useState(props.closed ? true : false);
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <Grid container direction="row" wrap="nowrap" gap="5px">
          <Grid item display="grid">
            <ButtonIconWithTooltip
              tooltipText={isClosed ? "Ouvrir" : "Fermer"}
              onClick={() => {
                setIsClosed((x) => !x);
              }}
              Icon={
                !isClosed ? (
                  <ArrowDropDownOutlined
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#0B243C",
                    }}
                  />
                ) : (
                  <ArrowDropUpOutlined
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#0B243C",
                    }}
                  />
                )
              }
            />
          </Grid>
          <Grid item display="grid">
            <span>
              {`${props.number}${props.number === 1 ? "ère" : "ème"} étape : ${
                props.title
              }`}
            </span>
          </Grid>
        </Grid>
      </Grid>
      {!isClosed && (
        <Grid item display="grid" marginLeft="50px" marginTop="10px">
          {props.children}
        </Grid>
      )}
    </Grid>
  );
};

const SwaggerDocumentationPage = () => {
  const isLoggedIn = !!localStorage.getItem("token");
  const [environment, setEnvironment] = React.useState<string>("Production");
  const [actualTab, setActualTab] = React.useState<
    "documentation-api" | "documentation-token"
  >("documentation-api");

  React.useEffect(() => {
    setTimeout(() => {
      if (!isLoggedIn) return;
      const allElements = document.querySelectorAll(
        ".swagger-ui .authorization__btn"
      );
      if (allElements) {
        for (const element of allElements) {
          element.setAttribute(
            "onClick",
            `setTimeout(() => {document.querySelector('.swagger-ui .auth-container input[type="password"], .swagger-ui .auth-container input[type="text"]').setAttribute("value", "${localStorage.getItem(
              "token"
            )}");}, 10);`
          );
        }
      }
    }, 1500);
  }, [isLoggedIn]);

  React.useEffect(() => {
    setTimeout(() => {
      if (!isLoggedIn) return;
      const authorizeButton = document?.querySelector(
        ".swagger-ui .auth-wrapper .authorize"
      );
      if (!authorizeButton) return;
      authorizeButton.setAttribute(
        "onClick",
        `setTimeout(() => {document.querySelector('.swagger-ui .auth-container input[type="password"], .swagger-ui .auth-container input[type="text"]').setAttribute("value", "${localStorage.getItem(
          "token"
        )}");}, 10);`
      );
    }, 1500);
  }, [isLoggedIn]);

  return (
    <FullPage isLoggedIn={isLoggedIn}>
      <Grid container direction="column" wrap="nowrap" gap="10px">
        <Grid item display="grid">
          <Tabs activeKey={actualTab} className="mb-3 background-tabs">
            <Tab
              style={{ padding: "0 0 0 0" }}
              eventKey="documentation-api"
              title={
                <div
                  onClick={() => {
                    setActualTab("documentation-api");
                  }}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span>API V2</span>
                  </div>
                </div>
              }
            />
            <Tab
              style={{ padding: "0 0 0 0" }}
              eventKey="documentation-token"
              title={
                <div
                  onClick={() => {
                    setActualTab("documentation-token");
                  }}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span>Authentification</span>
                  </div>
                </div>
              }
            />
          </Tabs>
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            direction="column"
            wrap="nowrap"
            style={{
              width: "80vw",
              minWidth: "850px",
              padding: "0px 20px 20px 20px",
            }}
          >
            <Grid item display="grid">
              {actualTab === "documentation-api" ? (
                <SwaggerUI url="/documentations/swagger.yaml" />
              ) : (
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    color: "#0B243C",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <Grid item display="grid" style={{ width: "200px" }}>
                    <GenericSelect
                      fieldTitle="Environnement"
                      options={[
                        {
                          label: "Production",
                          value: "Production",
                        },
                        {
                          label: "Staging",
                          value: "Staging",
                        },
                      ]}
                      id="input-environment"
                      placeholder="Environnement"
                      isClearable={false}
                      value={{ label: environment, value: environment }}
                      onChange={(e) => {
                        setEnvironment(e?.value ? e.value : "");
                      }}
                    />
                  </Grid>
                  <Grid item display="grid" marginTop="20px">
                    <BigStep
                      closed={false}
                      title="Comment obtenir le token d'un compte auquel vous avez accès à partir de vos identifiants ?"
                    >
                      <Grid
                        container
                        direction="column"
                        gap="15px"
                        wrap="nowrap"
                      >
                        <Grid item display="grid">
                          <Step
                            number={1}
                            title="Récupérer le token de votre compte à partir de vos identifiants"
                            closed={true}
                          >
                            <Grid
                              container
                              direction="column"
                              wrap="nowrap"
                              gap="5px"
                            >
                              <Grid item display="grid">
                                <span>
                                  {`Vous devez effectuer une requête POST vers https://${
                                    environment === "Production"
                                      ? "prod2"
                                      : "staging2"
                                  }-app.certipair.fr/wp-json/jwt-auth/v1/token` +
                                    `\nLe corps de la requête doit être au format JSON, le schéma étant {username: string, password: string}`}
                                </span>
                              </Grid>
                              <Grid item display="grid">
                                <img
                                  style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                  }}
                                  src={
                                    environment === "Production"
                                      ? RequestTokenProd
                                      : RequestTokenStaging
                                  }
                                  alt="example"
                                />
                              </Grid>
                              <Grid item display="grid">
                                <span>{`Vous venez d'obtenir un token valide pour une durée de 8 heures, sauvegardez-le pour l'étape 2.`}</span>
                              </Grid>
                            </Grid>
                          </Step>
                        </Grid>
                        <Grid item display="grid">
                          <Step
                            number={2}
                            title="Récupérer la liste des comptes auxquels vous avez accés"
                            closed={true}
                          >
                            <Grid
                              container
                              direction="column"
                              wrap="nowrap"
                              gap="5px"
                            >
                              <Grid item display="grid">
                                <span>
                                  {`Vous devez effectuer une requête GET vers https://${
                                    environment === "Production"
                                      ? "prod2"
                                      : "staging2"
                                  }-node.certipair.fr/wp-json/certipair/v3/accessible_accounts/list` +
                                    `\nL'en-tête de la requête doit contenir votre token de type 'Bearer' : { Authorization: "Bearer TOKEN" }`}
                                </span>
                              </Grid>
                              <Grid item display="grid">
                                <img
                                  style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                  }}
                                  src={
                                    environment === "Production"
                                      ? RequestAccountsProd
                                      : RequestAccountsStaging
                                  }
                                  alt="example"
                                />
                              </Grid>
                              <Grid item display="grid">
                                <span>{`Vous venez d'obtenir la liste des comptes auxquels vous avez accès, sauvegardez l'ID du compte auquel vous voulez accéder pour l'étape 3.`}</span>
                              </Grid>
                            </Grid>
                          </Step>
                        </Grid>
                        <Grid item display="grid">
                          <Step
                            number={3}
                            title="Récupérer le token d'un compte"
                            closed={true}
                          >
                            <Grid
                              container
                              direction="column"
                              wrap="nowrap"
                              gap="5px"
                            >
                              <Grid item display="grid">
                                <span>
                                  {`Vous devez effectuer une requête GET vers https://${
                                    environment === "Production"
                                      ? "prod2"
                                      : "staging2"
                                  }-node.certipair.fr/wp-json/certipair/v3/accessible_accounts/{ID}/token` +
                                    `\nDans l'URL de la requête, il faut remplacez {ID} par l'ID du compte.\nL'en-tête de la requête doit contenir votre token de type 'Bearer' : { Authorization: "Bearer TOKEN" }`}
                                </span>
                              </Grid>
                              <Grid item display="grid">
                                <img
                                  style={{
                                    height: "300px",
                                    borderRadius: "15px",
                                  }}
                                  src={
                                    environment === "Production"
                                      ? RequestAccountTokenProd
                                      : RequestAccountTokenStaging
                                  }
                                  alt="example"
                                />
                              </Grid>
                              <Grid item display="grid">
                                <span>{`Vous venez d'obtenir le token, vous pouvez l'utiliser jusqu'à expiration.`}</span>
                              </Grid>
                            </Grid>
                          </Step>
                        </Grid>
                      </Grid>
                    </BigStep>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default SwaggerDocumentationPage;
