import * as React from 'react';
import { CircularProgress, Grid } from "@mui/material";
import AlertsListTableTitles from './AlertsListTableTitles';
import './alertsTable.scss';
import AlertsListTableContent from './AlertsListTableContent';

const AlertsListContent: React.FC<any> = (props) => {
  const {
    alertsFiltered,
    isLoading,
  } = props;
  if (!alertsFiltered || alertsFiltered?.length === 0) return (null);
  return (
    <>
      <div className="table-alerts-grid" style={{ overflow: 'auto' }}>
        <AlertsListTableTitles />
        {!isLoading &&
          <AlertsListTableContent {...props} />
        }
      </div>
      {isLoading &&
        <Grid container direction='row' display='flex' justifyContent='center' alignItems='center' paddingTop='15px'>
          <CircularProgress />
        </Grid>
      }
    </>
  );
}

export default AlertsListContent;