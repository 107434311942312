import FullField from "../../../Fields/FullField";
import * as React from "react";
import Creatable from "react-select/creatable";
import useAppSelector from "../../../../redux/useAppSelector";

const DisplaySpecialities: React.FC<any> = (props) => {
  const {
    questionnaireData,
    setQuestionnaireData,
  } = props;
  const specialities = useAppSelector((x) => x.languageReducer.specialities);

  return (
    <FullField
      isQuestionnaire={true}
      title={"Spécialité(s)"}
      isMandatory={false}
    >
      <div
        style={{
          zIndex: 2,
        }}
      >
        <Creatable
          noOptionsMessage={() => "Aucune option"}
          formatCreateLabel={(input) => `...`}
          isMulti={true}
          options={specialities}
          value={questionnaireData.specialities ? questionnaireData.specialities.map((x: any) => {
            const obj = specialities.find((y) => y?.value === x);
            if (obj && obj?.label) {
              return obj;
            }
          }) : []}
          placeholder="Liste des spécialités"
          onChange={(e) => {
            if (!e || !Array.isArray(e)) {
              return;
            }
            const questionnaireDataTmp = { ...questionnaireData };

            questionnaireDataTmp.specialities = e.map((x) => x?.value);
            setQuestionnaireData(questionnaireDataTmp);
          }}
        />
      </div>
    </FullField>
  );
};

export default DisplaySpecialities;
