import { Grid } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import { updateSize } from "../redux/componentSizeReducer";
import { useDispatch } from "react-redux";
import AdminAlertsBox from "../components/AdminAlerts/AdminAlertsBox";
import useAppSelector from "../redux/useAppSelector";

const AdminAlertsPage: React.FC = (props: any) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(updateSize("#admin-alerts-box"));
  }, []);

  return (
    <FullPage>
      <Grid
        container
        id="admin-alerts-box"
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_alerts" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_alerts" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
        >
          <AdminAlertsBox {...props} />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminAlertsPage;
