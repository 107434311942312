import { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./login.css";
import PSC from "../assets/PSC.png";
import logo from "../assets/logo-blanc.png";
import AuthService from "../services/auth.services";
import React, {useState,useEffect} from 'react';
import http from "../http-common";
import useModal from "./UseModal";
import ResetPassword from "./ResetPassword";
import makeAPIRequest from "../utils/makeAPIRequest";


interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

const Login: React.FC<Props> = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const { isShowing, toggle } = useModal();
  const [loading, setLoading] = useState(false);

  const validationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required("Champ obligatoire !"),
      password: Yup.string().required("Champ obligatoire !"),
    });
  }

  const handleLogin = (username,password) => {
    setMessage("");
    setLoading(true);
        
    AuthService.login(username, password).then(
      () => {
        props.history.push("/home");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const testPro = () => {
    const header = {
      ClientID:"certipair-bas"
    }
    makeAPIRequest('get', "https://staging-app.certipair.fr/wp-admin/admin-ajax.php?action=openid-connect-authorize").then((res) => {
      console.log(res);
    })  
  }
  const handleChange = (e) => {
    if(e.target.name == "password")
      setPassword(e.target.value);
    else
      setUsername(e.target.value);
  }

    const initialValues = {
      username: "",
      password: "",
    };

    return (
      <div>
        <div className="login">
          <div className="box-register-login">
          <div className="box-register">
            <img className="logo-login" src={logo} alt="" />
            <p>
            Certipair, La solution digitale d’informations personnalisées qui aide vos patients à appliquer vos recommandations en dehors des temps de consultation
            </p>
              <button className="register-button" onClick={() => { props.history.push('/register') }}>Créer un compte</button>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={() => validationSchema()}
            onSubmit={() => handleLogin(username,password)}
          >
            <Form onChange={(e) => handleChange(e)}>
              <div className="box-login">
                {message && (
                  <div className="modal_error">
                    <div className="alert alert-danger" role="alert">
                      {message === 'not validated' ? "La demande de création de votre compte est toujours en attente." : "Identifiant ou mot de passe incorrect"}
                    </div>
                  </div>
                )}
                <div className="log-box-title">Se connecter</div>
                <Field
                  id="identifiant-input"
                  name="username"
                  type="text"
                  placeholder="Identifiant ou email"
                  className="input-login"
                />
              {/*<ErrorMessage
                  name="username"
                  component="div"
                  className="alert alert-danger"
                />*/}
                <Field
                  name="password"
                  type="password"
                  id="password-input"
                  className="input-login"
                  placeholder="Mot de passe"
                />
                {/*<ErrorMessage
                  name="password"
                  component="div"
                  className="alert alert-danger"
              />*/}
                <p >
                  J’ai oublié mon mot de passe <a onClick={toggle} style={{cursor:"pointer"}}>Réinitialiser</a>
                </p>
                <ResetPassword isShowing={isShowing} hide={toggle}/>
                <button
                  type="submit"
                  className="login-button"
                  id="login-button"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>
                <div className="psc-box" style={{cursor:"pointer"}}>
              
                  <a 
                  href={process.env.REACT_APP_PRO_SANTE_CONNECT}
                  onClick={testPro}
                  >
                    {console.log(process.env.REACT_APP_PRO_SANTE_CONNECT)}
                  <img src={PSC} alt="" />
                  </a>
                </div>
              </div>
            </Form>
          </Formik>
          </div>
        </div>
        <div className="phantom"></div>
        <div className="login-footer"><a href="https://www.certipair.fr/contact/" target="_blank" rel="noreferrer">Contact</a> - <a href="https://www.certipair.fr/mentions-legales/" target="_blank" rel="noreferrer">Mentions légales</a></div>
        <div className="phantom2"></div>
      </div>
    );
  }
  export default Login;
