import { Grid } from "@mui/material";
import * as React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from "react-redux";
import { closeSnackbar } from "../../redux/snackbarReducer";
import MySnackbar from "../Snackbars/MySnackbar";
import useAppSelector from "../../redux/useAppSelector";



const FormPopup: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const snackbarReducer = useAppSelector(state => state.snackbarReducer);
  return (
    ReactDOM.createPortal(
      <>
        <div className="modal-overlay" style={{ zIndex: 25000 }}>
          <MySnackbar
            isOpen={snackbarReducer?.isOpen}
            message={snackbarReducer?.message}
            duration={snackbarReducer?.duration}
            type={snackbarReducer?.type as any}
            onClose={() => dispatch(closeSnackbar(null))} />
          <div className="modal-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid
              container
              wrap='nowrap'
              overflow='auto'
              direction='column'
              maxHeight='80vh'
              minHeight={props?.minHeight ? props.minHeight : undefined}
              gridTemplateColumns={'repeat(1, 1fr)'}
              xs={props?.xs}
              display='grid'
              style={{
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: '24px',
              }}>
              <div style={{ overflow: 'auto' }}>
                {props?.children}
              </div>
            </Grid>
          </div>
        </div>
      </>,
      document.body)
  );
};

FormPopup.defaultProps = {
  xs: 7,
};

export default FormPopup;