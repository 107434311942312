import * as React from 'react'
import './GreyButton.scss';

interface IGreyButtonProps {
  text?: string,
  onClick?: any,
  enable?: boolean,
  type?: any,
  animation?: boolean,
}

const GreyButton = (props: IGreyButtonProps) => {
  return (
    <button
      disabled={!props.enable}
      className={`grey-button${props.enable ? ' enable-grey-button' : ' disable-grey-button'}${props?.animation ? ' button-animation': ''}`}
      onClick={props.onClick}
      type={props.type}>
      {props.text}
    </button>
  )
}

GreyButton.defaultProps = {
  text: 'Default',
  enable: true,
  onClick: () => {
    console.error("You forgot the 'onClick' attribute in the 'GreyButton' component");
  },
};

export default GreyButton;