import FullField from "../FullField";
import Select from "react-select";
import * as React from 'react';
import MySelect from "../../Library/Select/MySelect";

interface IOption {
  label?: string,
  value?: string,
}

interface IAdviceSpecialityField {
  specialitiesOptions?: any[],
  value?: IOption,
  onChange: any,
  itemBorder?: string,
}

const AdviceSpecialityField = (props: IAdviceSpecialityField) => {
  const {
    specialitiesOptions,
    value,
    onChange,
    itemBorder,
  } = props;

  return (
    <FullField title='Spécialité' isMandatory={true}>
      <div style={{ width: '100%', padding: '0', margin: '0' }}>
        <MySelect
          className='border-animation'
          border={itemBorder}
          id='advice-speciality-input'
          placeholder=''
          value={value}
          options={specialitiesOptions}
          onChange={(e) => onChange(e)}
        />
      </div>
    </FullField>
  );
};

export default AdviceSpecialityField;