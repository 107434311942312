import * as React from "react";
import { CircularProgress, Grid } from "@mui/material";
import MesPatientTableTitles from "./MesPatientTableTitles";
import MesPatientTableContent from "./MesPatientTableContent";
import useAppSelector from "../../redux/useAppSelector";

const MesPatientsContent: React.FC<any> = (props) => {
  const certificateINSi = useAppSelector((state) => state.INSiReducer.certificate);

  if (props?.isLoading) {
    return (
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop="15px"
        paddingBottom='150px'
      >
        <CircularProgress />
      </Grid>
    );
  } else if (props?.patientListFiltered?.length == 0) {
    return (
      <span
        style={{ fontFamily: "Poppins", fontSize: "15px", color: "#0B243C", paddingBottom: '150px' }}
      >
        Aucun patient trouvé
      </span>
    );
  } else {
    return (
      <>
        <div className={certificateINSi ? "table-grid-insi" : "table-grid"} style={{ overflow: "auto", paddingBottom: '150px' }}>
          <MesPatientTableTitles {...props} />
          {!props?.isLoading && <MesPatientTableContent {...props} />}
        </div>
      </>
    );
  }
};

export default MesPatientsContent;
