import * as React from 'react';
import { useDispatch } from 'react-redux';
import { deleteSearchTab, setActiveSearchTab } from '../../../../redux/tabsReducer';

const SearchTabButton: React.FC<any> = (props) => {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '50px',
      }}>
      {" "}
      <span
        onClick={() => {
          dispatch(setActiveSearchTab(props?.index));
        }} style={{
          paddingLeft: '20px',
          marginRight: '10px',
          lineHeight: '50px',
        }}>
        « {props?.tab?.title} » ({props?.tab?.advices?.length})
      </span>
      <span style={{
        opacity: 1,
        fontSize: '25px',
        transform: 'rotate(45deg)',
        marginTop: '2px',
        textAlign: 'center',
        fontWeight: 'normal',
        marginRight: '16px',
      }} onClick={() => {
        dispatch(deleteSearchTab(props?.index));
      }}>
        +
      </span>
    </div>
  );
};

export default SearchTabButton;
