import FullField from "../FullField";
import * as React from 'react';
import TextInput from "../../Library/Inputs/TextInput";

interface IPatientEmailField {
  patientEmail: string,
  onChange: any,
}

const PatientEmailField = (props: IPatientEmailField) => {
  const {
    patientEmail,
    onChange,
  } = props;

  return (
    <FullField title='Adresse Email' isMandatory={false}>
      <TextInput
        value={patientEmail}
        onChange={(e) => onChange(e)} />
    </FullField>
  );
};

export default PatientEmailField;