import FullField from "../../../Fields/FullField";
import * as React from "react";
import { Grid } from "@mui/material";
import { ButtonIconWithTooltip } from "../../PDF/CreateOrEditPDFQuestionnaire";
import {
  StarOutlineOutlined,
  StarOutlined,
  StarRounded,
} from "@mui/icons-material";

const DisplayEchelleDeNotation: React.FC<any> = (props) => {
  const {
    field,
    sectionIndex,
    justifyContent,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  const Etoiles: React.FC = () => {
    return (
      <Grid container direction="row" gap="5px">
        {[
          ...Array(parseInt(field.option[1]) - parseInt(field.option[0]) + 1),
        ].map((e, index) => {
          return (
            <Grid item key={index} display="grid">
              <ButtonIconWithTooltip
                Icon={
                  !field?.value || field.value < index + parseInt(field.option[0]) ? (
                    <StarOutlineOutlined
                      style={{
                        color: "#0B243C",
                        fontSize: "24px",
                      }}
                    />
                  ) : (
                    <StarRounded
                      style={{
                        color: "#0B243C",
                        fontSize: "24px",
                      }}
                    />
                  )
                }
                tooltipText=""
                onClick={() => {
                  const questionnaireDataTmp = { ...questionnaireData };

                  questionnaireDataTmp.sections[sectionIndex].fields[
                    fieldRowIndex
                  ][fieldIndex].value = index + parseInt(field.option[0]);
                  setQuestionnaireData(questionnaireDataTmp);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };
  const Ronds: React.FC = () => {
    return (
      <Grid container direction="row" gap="15px">
        {[
          ...Array(parseInt(field.option[1]) - parseInt(field.option[0]) + 1),
        ].map((e, index) => {
          return (
            <Grid item key={index} display="grid">
              <Grid
                container
                direction="column"
                wrap="nowrap"
                alignItems="center"
                gap="3px"
              >
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#0B243C",
                    }}
                  >
                    {index + parseInt(field.option[0])}
                  </span>
                </Grid>
                <Grid item display="grid">
                  <input
                    type="radio"
                    className="checkbox_type"
                    checked={field.value == index + parseInt(field.option[0])}
                    onChange={() => {
                      return null;
                    }}
                    onClick={() => {
                      const questionnaireDataTmp = { ...questionnaireData };

                      questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex].value = index + parseInt(field.option[0]);
                      setQuestionnaireData(questionnaireDataTmp);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid
      container
      id={"field" + field?.id}
      border="0px solid transparent"
      style={{
        width: "fit-content",
      }}
      borderRadius="10px"
    >
      <FullField
        isQuestionnaire={true}
        title={field?.title}
        isMandatory={field?.isMandatory}
        hideAsterics={field?.hideAsterics ? true : false}
        justifyContent={justifyContent}
      >
        {field?.displayStarsNotation ? <Etoiles /> : <Ronds />}
      </FullField>
    </Grid>
  );
};

export default DisplayEchelleDeNotation;
