import FullField from "../FullField";
import * as React from 'react';

interface IPatientBirthdayField {
  patientBirthday: string,
  onChange: any,
}

const PatientBirthdayField = (props: IPatientBirthdayField) => {
  const {
    patientBirthday,
    onChange,
  } = props;

  return (
    <FullField title='Date de naissance' isMandatory={false}>
      <input
        type="date"
        style={{
          minWidth: '100%',
          border: '1px solid #D3E0E0',
          background: '#EDF2F280 0% 0% no-repeat padding-box',
          borderRadius: '10px',
          paddingRight: '20px',
          paddingLeft: '20px',
          color: patientBirthday && patientBirthday?.length > 0 ? '#0B243C' : '#657273',
          fontFamily: 'Poppins',
          fontSize: '16px',
          height: '42px',
        }}
        value={patientBirthday}
        onChange={(e) => onChange(e.target.value)}
      />
    </FullField>
  );
};

export default PatientBirthdayField;