import FullPage from "./FullPage";
import * as React from "react";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import CreateOrEditPatient from "../components/FormPopup/CreateOrEditPatient";
import EventEmitter from "../services/event";
import {
  ChevronLeftOutlined,
  ClearRounded,
  EditRounded,
} from "@mui/icons-material";
import CancellablePopup from "../components/Library/Popups/CancellablePopup";
import InformationPopup from "../components/Library/Popups/InformationPopup";
import { useHistory } from "react-router";
import PatientListBox from "../components/PatientList/PatientListBox";
import FormPopup from "../components/FormPopup/FormPopup";
import FormPopupHeader from "../components/FormPopup/FormPopupHeader";
import FullField from "../components/Fields/FullField";
import ImportPatientTable from "../components/PatientList/Popup/ImportPatientTable";
import OrangeButton from "../components/Library/Buttons/OrangeButton/OrangeButton";
import { closeImportPatient } from "../redux/ImportPatientReducer";
import { openSnackbar } from "../redux/snackbarReducer";
import makeAPIRequest from "../utils/makeAPIRequest";
import TextInput from "../components/Library/Inputs/TextInput";
import AddButton from "../components/Library/Buttons/AddButton/AddButton";
import useAppSelector from "../redux/useAppSelector";

const patientIsSelected = (patient, patientSelected) => {
  if (patientSelected.includes(patient?.id)) return true;
  return false;
};

const PatientListPage = () => {
  const [patientsPerPage, setPatientsPerPage] = React.useState(10);
  const [actualPage, setActualPage] = React.useState(0);
  const [isDeletePopup, setIsDeletePopup] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  const [newGroupName, setNewGroupName] = React.useState("");
  const [sortingType, setSortingType] = React.useState(null);
  const [isPatientsGroupOpen, setIsPatientsGroupOpen] = React.useState(false);
  const [isValidateImportLoading, setIsValidateImportLoading] =
    React.useState(false);
  const [isSuccessDeletePopup, setIsSuccessDeletePopup] = React.useState(false);
  const [patientToDelete, setPatientToDelete] = React.useState(null);
  const [patientList, setPatientList] = React.useState([]);
  const [patientGroupsList, setPatientGroupsList] = React.useState([]);
  const [groupName, setGroupName] = React.useState("");
  const [createGroupLoading, setCreateGroupLoading] = React.useState(false);
  const [openEditGroup, setOpenEditGroup] = React.useState(null);
  const [popupDeleteLoading, setPopupDeleteLoading] =
    React.useState<boolean>(false);
  const [isGroupUpdateLoading, setIsGroupUpdateLoading] = React.useState(false);
  const [packInProgressFilter, setPackInProgressFilter] =
    React.useState(undefined);
  const [phoneTypeFilter, setPhoneTypeFilter] = React.useState(undefined);
  const [groupSearched, setGroupSearched] = React.useState({
    value: null,
    label: null,
  });
  const [patientSearched, setPatientSearched] = React.useState({
    value: null,
    label: null,
  });
  const [patientListFiltered, setPatientListFiltered] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();
  const patientSelected = useAppSelector(
    (state) => state.selectPatientReducer.patientSelected
  );
  const isEditPatientOpen = useAppSelector(
    (state) => state.editPatientReducer.isPatientOpen
  );
  const isCreatePatientOpen = useAppSelector(
    (state) => state.createPatientReducer.isPatientOpen
  );
  const isImportPatient = useAppSelector(
    (state) => state.importPatientReducer.isFinish
  );
  const resultUpdatePatient = useAppSelector(
    (state) => state.importPatientReducer.patientUpdateList
  );
  const resultImportPatient = useAppSelector(
    (state) => state.importPatientReducer.result
  );
  const errorImportPatient = useAppSelector(
    (state) => state.importPatientReducer.error
  );
  const history = useHistory();

  const nbImported = resultImportPatient?.filter(
    (e) => e?.isValidated === true
  )?.length;

  React.useEffect(() => {
    makeAPIRequest("get", `/patient/groups`)
      .then((res: any) => {
        setPatientGroupsList(res?.data?.data?.groups);
      })
      .catch((err) => {
        setPatientGroupsList([]);
      });
  }, []);

  React.useEffect(() => {
    const listenerPatientAdded = EventEmitter.addListener("PatientAdded", () =>
      onRefresh(true)
    );

    onRefresh();

    return () => {
      listenerPatientAdded.remove();
    };
  }, []);

  // Update pages after patient list filtered change
  React.useEffect(() => {
    const newPages = [];
    let index = 0;
    for (const patient of patientListFiltered) {
      if (index % patientsPerPage === 0) {
        newPages.push([]);
      }
      const lastIndex = newPages.length - 1;
      if (newPages[lastIndex]) {
        newPages[lastIndex].push(patient);
      }
      index++;
    }
    setPages(newPages);
  }, [patientListFiltered, patientsPerPage]);

  // Update actual page after pages change
  React.useEffect(() => {
    if (actualPage === 0) return;
    if (actualPage > pages.length - 1) setActualPage(pages.length - 1);
    if (actualPage < 0) setActualPage(0);
  }, [actualPage, pages]);

  // Update patient list filtered when patient list or patient searched update
  React.useEffect(() => {
    if (
      !patientSearched?.value &&
      !groupSearched?.value &&
      !packInProgressFilter?.value &&
      !phoneTypeFilter?.value
    ) {
      setPatientListFiltered(patientList);
      return;
    }
    let newPatientListFiltered = [...patientList];

    if (patientSearched?.value) {
      newPatientListFiltered = newPatientListFiltered?.filter(
        (patient) => patient?.id == patientSearched?.value
      );
    }
    if (groupSearched?.value) {
      newPatientListFiltered = newPatientListFiltered?.filter(
        (patient) => patient?.group == groupSearched?.value
      );
    }
    if (phoneTypeFilter?.value === "only_without_smartphone") {
      newPatientListFiltered = newPatientListFiltered?.filter(
        (patient) => patient?.no_smartphone == 1
      );
    }
    if (phoneTypeFilter?.value === "only_with_smartphone") {
      newPatientListFiltered = newPatientListFiltered?.filter(
        (patient) => patient?.no_smartphone == 0
      );
    }
    if (phoneTypeFilter?.value === "only_with_helper_phone") {
      newPatientListFiltered = newPatientListFiltered?.filter(
        (patient) => patient?.is_helper_phone == 1
      );
    }
    if (packInProgressFilter?.value === "only_pack") {
      newPatientListFiltered = newPatientListFiltered?.filter(
        (patient) => patient?.packInProgress?.length > 0
      );
    }
    if (packInProgressFilter?.value === "no_pack") {
      newPatientListFiltered = newPatientListFiltered?.filter(
        (patient) => patient?.packInProgress?.length == 0
      );
    }

    setPatientListFiltered(newPatientListFiltered);
  }, [patientList, patientSearched, groupSearched, packInProgressFilter, phoneTypeFilter]);

  // Update patient list filtered when sorting type change
  const executeSorting = () => {
    if (!sortingType) {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.id;
          const second = elementB?.id;
          return second - first;
        }),
      ]);
    }
    if (sortingType === "groupe-croissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const firstGroup = patientGroupsList.find(
            (x) => x?.id == elementA?.group
          )?.group_name;
          const secondGroup = patientGroupsList.find(
            (x) => x?.id == elementB?.group
          )?.group_name;

          const first = firstGroup ? firstGroup?.toUpperCase() : "";
          const second = secondGroup ? secondGroup?.toUpperCase() : "";
          if (!first) {
            return 1;
          }
          if (!second) {
            return -1;
          }
          return first < second ? -1 : first > second ? 1 : 0;
        }),
      ]);
    }
    if (sortingType === "groupe-decroissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const firstGroup = patientGroupsList.find(
            (x) => x?.id == elementA?.group
          )?.group_name;
          const secondGroup = patientGroupsList.find(
            (x) => x?.id == elementB?.group
          )?.group_name;

          const first = firstGroup?.toUpperCase();
          const second = secondGroup?.toUpperCase();
          return first < second ? 1 : first > second ? -1 : 0;
        }),
      ]);
    }
    if (sortingType === "checkbox-croissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = patientIsSelected(elementA, patientSelected);
          const second = patientIsSelected(elementB, patientSelected);
          return first < second ? 1 : first > second ? -1 : 0;
        }),
      ]);
    }
    if (sortingType === "checkbox-decroissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = patientIsSelected(elementA, patientSelected);
          const second = patientIsSelected(elementB, patientSelected);
          return first < second ? -1 : first > second ? 1 : 0;
        }),
      ]);
    }
    if (sortingType === "id-decroissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = parseInt(elementA?.id);
          const second = parseInt(elementB?.id);
          return first < second ? 1 : first > second ? -1 : 0;
        }),
      ]);
    }
    if (sortingType === "id-croissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = parseInt(elementA?.id);
          const second = parseInt(elementB?.id);
          return first < second ? -1 : first > second ? 1 : 0;
        }),
      ]);
    }
    if (sortingType === "nom-croissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.lastname?.toUpperCase();
          const second = elementB?.lastname?.toUpperCase();
          return first < second ? 1 : first > second ? -1 : 0;
        }),
      ]);
    }
    if (sortingType === "nom-decroissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.lastname?.toUpperCase();
          const second = elementB?.lastname?.toUpperCase();
          return first < second ? 1 : first > second ? -1 : 0;
        }),
      ]);
    }
    if (sortingType === "prenom-croissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.firstname?.toUpperCase();
          const second = elementB?.firstname?.toUpperCase();
          return first < second ? -1 : first > second ? 1 : 0;
        }),
      ]);
    }
    if (sortingType === "prenom-decroissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.firstname?.toUpperCase();
          const second = elementB?.firstname?.toUpperCase();
          return first < second ? 1 : first > second ? -1 : 0;
        }),
      ]);
    }
    if (sortingType === "pack-croissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.packInProgress?.[0]?.notSended
            ? elementA.packInProgress[0].notSended
            : 999;
          const second = elementB?.packInProgress?.[0]?.notSended
            ? elementB.packInProgress[0].notSended
            : 999;
          return first - second;
        }),
      ]);
    }
    if (sortingType === "pack-decroissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.packInProgress?.[0]?.notSended
            ? elementA.packInProgress[0].notSended
            : -1;
          const second = elementB?.packInProgress?.[0]?.notSended
            ? elementB.packInProgress[0].notSended
            : -1;
          return second - first;
        }),
      ]);
    }
    if (sortingType === "ins-croissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.ins_numero_identifiant;
          const second = elementB?.ins_numero_identifiant;
          return first < second ? 1 : first > second ? -1 : 0;
        }),
      ]);
    }
    if (sortingType === "ins-decroissant") {
      setPatientList((p) => [
        ...p.sort((elementA, elementB) => {
          const first = elementA?.ins_numero_identifiant;
          const second = elementB?.ins_numero_identifiant;
          return first < second ? -1 : first > second ? 1 : 0;
        }),
      ]);
    }
  };

  React.useEffect(() => {
    executeSorting();
  }, [sortingType]);

  const onRefresh = (loading: boolean = true) => {
    if (loading) {
      setIsLoading(true);
    }
    makeAPIRequest("get", `/patients/get_all`, null, "v3").then((res: any) => {
      if (res?.data?.data?.search_patients_results?.length > 0) {
        setPatientList(res?.data?.data?.search_patients_results);
        executeSorting();
      } else {
        setPatientList([]);
      }
      if (loading) {
        setIsLoading(false);
      }
    });
  };

  const deletePatient = () => {
    setPopupDeleteLoading(true);
    makeAPIRequest("delete", `/patient/${patientToDelete?.id}/delete`).then(
      (res) => {
        if (res.data?.success) {
          makeAPIRequest("get", `/patients/get_all`, null, "v3")
            .then((res: any) => {
              setPatientList(res?.data?.data?.search_patients_results);
              executeSorting();
              dispatch(
                openSnackbar({
                  message: `Le patient ${patientToDelete?.lastname} a bien été supprimé`,
                  duration: 3000,
                  type: "success",
                })
              );
              setIsDeletePopup(false);
              setPopupDeleteLoading(false);
            })
            .catch((err) => {
              dispatch(
                openSnackbar({
                  message: `Impossible de charger la liste des patients`,
                  duration: 3000,
                  type: "error",
                })
              );
              setIsDeletePopup(false);
              setPopupDeleteLoading(false);
            });
        } else {
          dispatch(
            openSnackbar({
              message: `Le patient ${patientToDelete?.lastname} n'a pas pu être supprimé`,
              duration: 3000,
              type: "error",
            })
          );
          setPopupDeleteLoading(false);
        }
      }
    );
  };

  const updatePatients = async () => {
    setIsValidateImportLoading(true);
    if (resultUpdatePatient?.length == 0) {
      return;
    }
    let toSends = [];

    for (const patient of resultUpdatePatient) {
      const patientCopy: any = patient;
      let toSend: any = {
        firstname: patient?.first_name,
        lastname: patient?.last_name,
        phone: patient?.phone,
        INS:
          patient?.ins && patient?.ins !== "0" ? patient?.ins : "0000000000000",
      };

      toSend["email"] = patientCopy?.email ? patientCopy?.email : "";
      toSend["sexe"] = patientCopy?.sexe ? patientCopy?.sexe : "";
      toSend["city_INSEE"] = patientCopy?.birth_place
        ? patientCopy?.birth_place
        : "";
      toSend["numero_patient"] = patientCopy?.numero_patient
        ? patientCopy?.numero_patient
        : "";
      toSend["disable_forms_private_links"] =
        patientCopy?.disable_forms_private_links
          ? patientCopy?.disable_forms_private_links
          : 0;
      toSend["is_helper_phone"] = patientCopy?.is_helper_phone
        ? patientCopy?.is_helper_phone
        : 0;
      toSend["no_smartphone"] = patientCopy?.no_smartphone
        ? patientCopy?.no_smartphone
        : 0;
      toSend["helper_name"] = patientCopy?.helper_name
        ? patientCopy?.helper_name
        : "";
      toSend["comment"] = patientCopy?.comment
        ? patientCopy?.comment
        : "";
      toSend["dob"] = patientCopy?.birth_date ? patientCopy?.birth_date : null;
      if (patientCopy?.id) toSend["id"] = patientCopy?.id;
      if (patientCopy?.group_id) toSend["group_id"] = patientCopy?.group_id;

      toSends.push(toSend);
    }
    await makeAPIRequest(
      "post",
      `/patients/update`,
      {
        patients: toSends,
      },
      "v2"
    )
      .then((res) => {
        setIsValidateImportLoading(false);
        dispatch(
          openSnackbar({
            message: "Les patients ont bien été mis à jour.",
            type: "success",
            duration: 3000,
          })
        );
      })
      .catch((err) => {
        setIsValidateImportLoading(false);
        dispatch(
          openSnackbar({
            message: "La mise à jour des patients a échouée.",
            type: "error",
            duration: 3000,
          })
        );
      });
  };

  const addNewPatient = async () => {
    await updatePatients();
    const filteredResultImportPatient = resultImportPatient?.filter(
      (e) => e?.isValidated === true
    );

    let toSends = [];
    if (filteredResultImportPatient?.length > 0) {
      setIsValidateImportLoading(true);
      for (const patient of filteredResultImportPatient) {
        const patientCopy: any = patient;
        let toSend: any = {
          firstname: patient?.first_name,
          lastname: patient?.last_name,
          phone: patient?.phone,
          INS: patient?.ins ? patient?.ins : "0000000000000",
        };

        if (patientCopy?.birth_date) toSend["dob"] = patientCopy?.birth_date;
        if (patientCopy?.email) toSend["email"] = patientCopy?.email;
        if (patientCopy?.sexe) toSend["sexe"] = patientCopy?.sexe;
        if (patientCopy?.numero_patient)
          toSend["numero_patient"] = patientCopy?.numero_patient;
        if (patientCopy?.disable_forms_private_links !== undefined)
          toSend["disable_forms_private_links"] =
            patientCopy?.disable_forms_private_links;
        if (patientCopy?.is_helper_phone !== undefined)
          toSend["is_helper_phone"] = patientCopy?.is_helper_phone;
        if (patientCopy?.no_smartphone !== undefined)
          toSend["no_smartphone"] = patientCopy?.no_smartphone;
        if (patientCopy?.helper_name !== undefined)
          toSend["helper_name"] = patientCopy?.helper_name;
        if (patientCopy?.comment !== undefined)
          toSend["comment"] = patientCopy?.comment;
        if (patientCopy?.birth_place)
          toSend["city_INSEE"] = patientCopy?.birth_place;
        if (patientCopy?.group_id) {
          toSend["group_id"] = patientCopy.group_id;
        }

        toSends.push(toSend);
      }
      await makeAPIRequest(
        "post",
        `/patients/create`,
        {
          patients: toSends,
        },
        "v2"
      )
        .then((res) => {
          makeAPIRequest("get", `/patients/get_all`, null, "v3")
            .then((res: any) => {
              setPatientList(res?.data?.data?.search_patients_results);
              executeSorting();
              setIsValidateImportLoading(false);
              dispatch(closeImportPatient());
              dispatch(
                openSnackbar({
                  message: "Les patients ont bien été importés.",
                  type: "success",
                  duration: 3000,
                })
              );
            })
            .catch(() => {
              dispatch(
                openSnackbar({
                  message: "Impossible de récupérer la liste des patients.",
                  type: "error",
                  duration: 3000,
                })
              );
            });
        })
        .catch((err) => {
          setIsValidateImportLoading(false);
          dispatch(
            openSnackbar({
              message: "L'importation des patients a échouée.",
              type: "error",
              duration: 3000,
            })
          );
        });
    } else {
      if (resultUpdatePatient?.length != 0) {
        makeAPIRequest("get", `/patients/get_all`, null, "v3")
          .then((res: any) => {
            setPatientList(res?.data?.data?.search_patients_results);
            executeSorting();
            dispatch(closeImportPatient());
          })
          .catch(() => {
            dispatch(
              openSnackbar({
                message: "Impossible de récupérer la liste des patients.",
                type: "error",
                duration: 3000,
              })
            );
          });
      }
    }
  };
  return (
    <>
      {openEditGroup && (
        <FormPopup xs={8}>
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle="Modifier"
              secondTitle="un groupe"
              onClose={() => {
                setNewGroupName("");
                setOpenEditGroup(null);
              }}
            />
          </Grid>
          <Grid item display="grid" paddingBottom="50px">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              display="flex"
              width="70%"
              justifySelf="center"
            >
              <Grid container direction="column" wrap="nowrap">
                <Grid item display="grid">
                  <FullField isMandatory={true} title="Nom du groupe">
                    <TextInput
                      onChange={(e) => {
                        setNewGroupName(e.target.value);
                      }}
                      value={newGroupName}
                    />
                  </FullField>
                </Grid>
                <Grid
                  item
                  display="grid"
                  justifyContent="center"
                  paddingTop="30px"
                >
                  <OrangeButton
                    animation={false}
                    enable={true}
                    isLoading={isGroupUpdateLoading}
                    type="submit"
                    text={"Mettre à jour"}
                    onClick={() => {
                      if (!newGroupName) {
                        dispatch(
                          openSnackbar({
                            message: "Le nom du groupe ne peut pas être vide",
                            duration: 3000,
                            type: "error",
                          })
                        );
                        return;
                      }
                      setIsGroupUpdateLoading(true);
                      makeAPIRequest(
                        "post",
                        "/patients_group/update",
                        {
                          group_name: newGroupName,
                          group_id: openEditGroup,
                        },
                        "v2"
                      )
                        .then((res) => {
                          if (res?.data?.success === true) {
                            makeAPIRequest("get", `/patient/groups`)
                              .then((res: any) => {
                                setPatientGroupsList(res?.data?.data?.groups);
                                setNewGroupName("");
                                setOpenEditGroup(null);
                                dispatch(
                                  openSnackbar({
                                    message: "Le groupe a bien été mis à jour",
                                    duration: 3000,
                                    type: "success",
                                  })
                                );
                                setIsGroupUpdateLoading(false);
                              })
                              .catch(() => {
                                setNewGroupName("");
                                setOpenEditGroup(null);
                                setIsGroupUpdateLoading(false);
                              });
                          } else {
                            dispatch(
                              openSnackbar({
                                message:
                                  "Impossible de mettre à jour le groupe",
                                duration: 3000,
                                type: "error",
                              })
                            );
                            setIsGroupUpdateLoading(false);
                          }
                        })
                        .catch(() => {
                          dispatch(
                            openSnackbar({
                              message: "Impossible de mettre à jour le groupe",
                              duration: 3000,
                              type: "error",
                            })
                          );
                          setIsGroupUpdateLoading(false);
                        });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormPopup>
      )}
      {isPatientsGroupOpen && (
        <FormPopup xs={8}>
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle="Gérer"
              secondTitle="mes groupes"
              onClose={() => setIsPatientsGroupOpen(false)}
            />
          </Grid>
          <Grid item display="grid" paddingBottom="50px">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              display="flex"
              width="70%"
              justifySelf="center"
            >
              <Grid container direction="column" wrap="nowrap">
                <Grid item display="grid">
                  <Grid container direction="row" wrap="nowrap" spacing="10px">
                    <Grid item display="grid" xs>
                      <TextInput
                        placeholder="Nom du groupe"
                        onChange={(e) => {
                          setGroupName(e.target.value);
                        }}
                        value={groupName}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <AddButton
                        disabled={createGroupLoading}
                        onClick={() => {
                          if (!groupName) {
                            dispatch(
                              openSnackbar({
                                message:
                                  "Le nom du groupe ne peut pas être vide",
                                type: "error",
                                duration: 3000,
                              })
                            );
                            return;
                          }
                          setCreateGroupLoading(true);
                          makeAPIRequest("post", "/patient/create_group", {
                            group_name: groupName,
                          })
                            .then((res) => {
                              makeAPIRequest("get", `/patient/groups`)
                                .then((res: any) => {
                                  setPatientGroupsList(res?.data?.data?.groups);
                                  dispatch(
                                    openSnackbar({
                                      message: "Le groupe a bien été créé",
                                      type: "success",
                                      duration: 3000,
                                    })
                                  );
                                  setCreateGroupLoading(false);
                                })
                                .catch((err) => {
                                  dispatch(
                                    openSnackbar({
                                      message: "Le groupe a bien été créé",
                                      type: "success",
                                      duration: 3000,
                                    })
                                  );
                                  setCreateGroupLoading(false);
                                });
                            })
                            .catch((err) => {
                              dispatch(
                                openSnackbar({
                                  message: "Impossible de créer ce groupe",
                                  type: "error",
                                  duration: 3000,
                                })
                              );
                              setCreateGroupLoading(false);
                            });
                        }}
                        displayText={true}
                        text="Créer"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display="grid" paddingTop="20px">
                  <span
                    style={{
                      color: "#0B243C",
                      fontSize: "16px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {patientGroupsList?.length
                      ? patientGroupsList?.length
                      : "Aucun"}{" "}
                    groupe
                    {patientGroupsList?.length > 1 ? "s" : ""} trouvé
                    {patientGroupsList?.length > 1 ? "s" : ""}
                  </span>
                </Grid>
                <Grid item display="grid" paddingTop="10px">
                  <Grid container direction="column" wrap="nowrap">
                    <Grid
                      item
                      display="grid"
                      style={{
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="space-between"
                        paddingLeft="10px"
                        paddingRight="10px"
                      >
                        <Grid item display="grid">
                          <span
                            style={{
                              color: "#8D9899",
                              fontSize: "16px",
                              fontFamily: "Poppins",
                            }}
                          >
                            ID
                          </span>
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              color: "#8D9899",
                              fontSize: "16px",
                              fontFamily: "Poppins",
                            }}
                          >
                            Nom
                          </span>
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              color: "#8D9899",
                              fontSize: "16px",
                              fontFamily: "Poppins",
                            }}
                          >
                            Gérer
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    {patientGroupsList.map((g, index) => {
                      return (
                        <Grid
                          item
                          display="grid"
                          key={g?.id}
                          style={{
                            borderTop:
                              index === 0 ? "1px solid #8D9899" : undefined,
                            borderBottom:
                              index !== patientGroupsList.length - 1
                                ? "1px solid #E6EBF0"
                                : undefined,
                            paddingTop: "12px",
                            paddingBottom: "12px",
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            justifyContent="space-between"
                            paddingLeft="10px"
                            paddingRight="10px"
                          >
                            <Grid item display="grid">
                              <span
                                style={{
                                  color: "#4D473D",
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {g?.id ? g?.id : ""}
                              </span>
                            </Grid>
                            <Grid item display="grid">
                              <span
                                style={{
                                  color: "#4D473D",
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {g?.group_name ? g?.group_name : ""}
                              </span>
                            </Grid>
                            <Grid item display="grid">
                              <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                spacing="10px"
                              >
                                <Grid item display="grid">
                                  <Tooltip title="Modifier">
                                    <IconButton
                                      style={{ margin: "0", padding: "0" }}
                                      onClick={() => {
                                        setNewGroupName(g?.group_name);
                                        setOpenEditGroup(g?.id);
                                      }}
                                    >
                                      <EditRounded
                                        fontSize="small"
                                        style={{ color: "#0B243C" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item display="grid">
                                  <Tooltip title="Supprimer">
                                    <IconButton
                                      onClick={() => {
                                        makeAPIRequest(
                                          "delete",
                                          `/patient/delete_group/${g?.id}`,
                                          null,
                                          "v2"
                                        )
                                          .then((res) => {
                                            if (res?.data?.success === true) {
                                              setPatientGroupsList((x) =>
                                                x.filter(
                                                  (element) =>
                                                    element?.id != g?.id
                                                )
                                              );
                                              dispatch(
                                                openSnackbar({
                                                  message:
                                                    "Le groupe a bien été supprimé",
                                                  duration: 3000,
                                                  type: "success",
                                                })
                                              );
                                            } else {
                                              dispatch(
                                                openSnackbar({
                                                  message:
                                                    "Impossible de supprimer ce groupe",
                                                  duration: 3000,
                                                  type: "error",
                                                })
                                              );
                                            }
                                          })
                                          .catch(() => {
                                            dispatch(
                                              openSnackbar({
                                                message:
                                                  "Impossible de supprimer ce groupe",
                                                duration: 3000,
                                                type: "error",
                                              })
                                            );
                                          });
                                      }}
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    >
                                      <ClearRounded
                                        fontSize="small"
                                        style={{ color: "#FF3100" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormPopup>
      )}
      {isImportPatient && (
        <FormPopup xs={8}>
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle="Importer"
              secondTitle="des patients"
              onClose={() => dispatch(closeImportPatient())}
            />
          </Grid>
          <Grid item display="grid" paddingBottom="50px">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              display="flex"
              width="70%"
              justifySelf="center"
            >
              <Grid container direction="column" wrap="nowrap">
                {resultImportPatient?.length > 0 ||
                  resultUpdatePatient?.length > 0 ? (
                  <>
                    {(nbImported > 0 || resultUpdatePatient?.length > 0) && (
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          paddingBottom="5px"
                          display="flex"
                          alignItems="flex-end"
                        >
                          {!isValidateImportLoading ? (
                            <Grid item display="grid">
                              <OrangeButton
                                animation={true}
                                enable={true}
                                style={{
                                  fontSize: "14px",
                                  height: "35px",
                                  padding: "2px 15px 2px 15px",
                                  borderRadius: "15px",
                                }}
                                type="submit"
                                text={
                                  resultUpdatePatient?.length == 0
                                    ? nbImported > 1
                                      ? `Importer mes ${nbImported} patients`
                                      : "Importer mon patient"
                                    : "Valider"
                                }
                                onClick={async () => {
                                  await addNewPatient();
                                }}
                              />
                            </Grid>
                          ) : (
                            <Grid
                              item
                              paddingTop="5px"
                              width="70px"
                              justifyContent="center"
                              display="grid"
                            >
                              <CircularProgress
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {resultImportPatient?.length > 0 && (
                      <Grid item display="grid" paddingTop="10px">
                        <span className="text_champs">
                          {resultImportPatient?.length > 1
                            ? `${resultImportPatient?.length} patients peuvent être importés :`
                            : "Un seul patient peut être importé :"}
                        </span>
                      </Grid>
                    )}
                    {resultImportPatient?.length > 0 && (
                      <Grid item display="grid" paddingTop="15px">
                        <ImportPatientTable
                          data={resultImportPatient}
                          error={false}
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  resultUpdatePatient?.length == 0 && (
                    <Grid item display="grid" paddingTop="5px">
                      <span
                        style={{
                          color: "#0B243C",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                        }}
                      >
                        Aucun patient ne peut être importé
                      </span>
                    </Grid>
                  )
                )}
              </Grid>
              {resultUpdatePatient?.length > 0 && (
                <Grid item display="grid" paddingTop="20px">
                  <span className="text_champs">
                    {resultUpdatePatient?.length > 1
                      ? `${resultUpdatePatient?.length} patients peuvent être mis à jour :`
                      : "Un seul patient peut être mis à jour :"}
                  </span>
                </Grid>
              )}
              {resultUpdatePatient?.length > 0 && (
                <Grid item display="grid" paddingTop="15px">
                  <ImportPatientTable
                    data={resultUpdatePatient}
                    isUpdate={true}
                    error={false}
                  />
                </Grid>
              )}
              {errorImportPatient?.length > 0 && (
                <>
                  <Grid item display="grid" paddingTop="20px">
                    <span className="text_champs">
                      {errorImportPatient.length > 1
                        ? `${errorImportPatient.length} patients ne peuvent pas être importés :`
                        : "Ce patient ne peut pas être importé"}
                    </span>
                  </Grid>
                  <Grid item display="grid" paddingTop="20px">
                    <ImportPatientTable
                      data={errorImportPatient}
                      error={true}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </FormPopup>
      )}
      {isCreatePatientOpen && (
        <CreateOrEditPatient
          isCreate={true}
          isEdit={false}
          patientList={patientList}
          setPatientList={setPatientList}
          executeSorting={executeSorting}
          patientGroupsList={patientGroupsList}
        />
      )}
      {isEditPatientOpen && (
        <CreateOrEditPatient
          isCreate={false}
          isEdit={true}
          patientList={patientList}
          setPatientList={setPatientList}
          executeSorting={executeSorting}
          patientGroupsList={patientGroupsList}
        />
      )}
      {isSuccessDeletePopup && (
        <InformationPopup
          firstTitle="Mes"
          secondTitle="Patients"
          message={`Le patient ${patientToDelete?.lastname} a bien été supprimé.`}
          confirmButtonText="OK"
          onConfirm={() => {
            setIsSuccessDeletePopup(false);
            setPatientToDelete(null);
          }}
        />
      )}
      {isDeletePopup && patientToDelete && (
        <CancellablePopup
          firstTitle="Mes"
          secondTitle="Patients"
          isLoading={popupDeleteLoading}
          messages={[
            "Voulez-vous vraiment supprimer ce patient ?",
            patientToDelete?.lastname ? `Nom du patient : ${patientToDelete?.lastname}` : `Numéro du patient : ${patientToDelete?.phone}`,
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => setIsDeletePopup(false)}
          onConfirm={() => deletePatient()}
        />
      )}
      <FullPage>
        <Grid container direction="column" width="80vw" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <PatientListBox
              pages={pages}
              setPages={setPages}
              patientsPerPage={patientsPerPage}
              setPatientsPerPage={setPatientsPerPage}
              actualPage={actualPage}
              setActualPage={setActualPage}
              patientSearched={patientSearched}
              setPatientSearched={setPatientSearched}
              groupSearched={groupSearched}
              setGroupSearched={setGroupSearched}
              packInProgressFilter={packInProgressFilter}
              setPackInProgressFilter={setPackInProgressFilter}
              phoneTypeFilter={phoneTypeFilter}
              setPhoneTypeFilter={setPhoneTypeFilter}
              patientList={patientList}
              setPatientList={setPatientList}
              patientListFiltered={patientListFiltered}
              patientSelected={patientSelected}
              setIsDeletePopup={setIsDeletePopup}
              setPatientToDelete={setPatientToDelete}
              isLoading={isLoading}
              sortingType={sortingType}
              setSortingType={setSortingType}
              isPatientsGroupOpen={isPatientsGroupOpen}
              setIsPatientsGroupOpen={setIsPatientsGroupOpen}
              patientGroupsList={patientGroupsList}
            />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default PatientListPage;
