import * as React from "react";
import MySelect from "../../../Library/Select/MySelect";
import "../FilterStyle.scss";
import IFilterProps from "../IFilterProps";

const AttachmentSelectItem = (props: IFilterProps) => {
  const name = "attchment";

  const options = [
    {
      label: "Sans pièce jointe",
      value: "none",
    },
    {
      label: "Photo uniquement",
      value: "photo",
    },
    {
      label: "Vidéo uniquement",
      value: "vidéo",
    },
  ];

  const getOpt = (key: any) => {
    const founded = options.find((x) => x?.value === key);
    if (founded) {
      console.log(founded);
      return founded;
    }
    return null;
  };
  return (
    <div style={{ minWidth: "200px" }}>
      <MySelect
        name={name}
        value={
          props.filterKey?.attchment ? getOpt(props.filterKey.attchment) : null
        }
        id="piece_jointe-select"
        placeholder="Pièce jointe"
        onChange={(e) => {
          props.handleSelectChange({
            target: {
              value: e?.value ? e?.value : null,
              name: name,
            },
          });
        }}
        options={options}
      />
    </div>
  );

  /* // Ancien select
  return (
    <select
      name={name}
      value={props.filterKey?.attchment ? props.filterKey.attchment : ''}
      id="piece_jointe-select"
      className="select-options"
      onChange={(e) => props.handleSelectChange(e)}>
      <option value="" style={{ display: 'none' }} disabled>Pièce jointe</option>
      <option value="none">Sans pièce jointe</option>
      <option value="photo">Photo uniquement</option>
      <option value="vidéo">Vidéo uniquement</option>
    </select>
  );
  */
};

export default AttachmentSelectItem;
