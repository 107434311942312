import * as React from 'react';
import { Grid, IconButton, Tooltip } from "@mui/material";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material"


const ScheduledNavigation: React.FC<any> = (props) => {
  const {
    actualPage,
    setActualPage,
    pages,
  } = props;

  return (
    <Grid container direction='row' alignItems='center' wrap='nowrap' spacing='30px'>
      <Grid item display='grid'>
        <span style={{
          color: '#8D9899',
          letterSpacing: '0px',
          fontSize: '16px',
          fontFamily: 'Poppins',
          whiteSpace: 'pre',
        }}>
          {actualPage + 1} / {pages?.length}  Pages
        </span>
      </Grid>
      <Grid item display='grid'>
        <Grid container direction='row' alignItems='center' wrap='nowrap' spacing='36px'>
          <Grid item display='grid'>
            <Tooltip title='Précédent'>
              <span>
                <IconButton
                  disabled={actualPage === 0}
                  style={{ padding: '0', margin: '0' }}
                  onClick={() => {
                    if (actualPage > 0) setActualPage(actualPage - 1);
                  }}>
                  <ChevronLeftRounded
                    style={{
                      color: '#8D9899',
                      opacity: actualPage === 0 ? 0.3 : 1,
                    }}
                    fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item display='grid'>
            <Tooltip title='Suivant'>
              <span>
                <IconButton
                  disabled={pages?.length - 1 === actualPage}
                  style={{ padding: '0', margin: '0' }}
                  onClick={() => {
                    if (actualPage < pages?.length - 1) setActualPage(actualPage + 1);
                  }}>
                  <ChevronRightRounded
                    style={{
                      color: '#8D9899',
                      opacity: pages?.length - 1 === actualPage ? 0.3 : 1,
                    }}
                    fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ScheduledNavigation;