import axios from "axios";
import forceDisconnectUser from "./forceDisconnectUser";
import { getActualTimeNumber } from "../components/NavbarComponent";

const makeAPIRequest = (
  type: string,
  route: string,
  data = null,
  version = "v2",
  overrideToken = undefined,
) => {
  let promise = null;

  const token = overrideToken ? overrideToken : localStorage.getItem("token");
  const lastConnection = localStorage.getItem("lastConnection");

  if (token && (!lastConnection || (Number(lastConnection) + 8 * 60 * 60 * 1000) < getActualTimeNumber())) {
    forceDisconnectUser()
  }
  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  const http_v3 = axios.create({
    baseURL: process.env.REACT_APP_API_URL_V3,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  if (version === "v3") {
    promise = new Promise((resolve, reject) => {
      if (!["get", "post", "delete"].includes(type))
        reject(new Error(`Invalid request type : ${type}`));
      data
        ? http_v3?.[type](route, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            })
        : http_v3?.[type](route)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  }
  if (version === "v2") {
    promise = new Promise((resolve, reject) => {
      if (!["get", "post", "delete"].includes(type))
        reject(new Error(`Invalid request type : ${type}`));
      data
        ? http?.[type](route, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              if (
                [
                  "empty_username",
                  "jwt_auth_invalid_token",
                  "jwt_auth_bad_iss",
                  "empty_password",
                  "invalid_username",
                  "incorrect_password",
                ].includes(err?.response?.data?.code)
              ) {
                forceDisconnectUser();
              }
              reject(err);
            })
        : http?.[type](route)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              if (
                [
                  "empty_username",
                  "jwt_auth_invalid_token",
                  "jwt_auth_bad_iss",
                  "empty_password",
                  "invalid_username",
                  "incorrect_password",
                ].includes(err?.response?.data?.code) &&
                route !== "/utils/language"
              ) {
                forceDisconnectUser();
              }
              reject(err);
            });
    });
  }
  return promise;
};

export default makeAPIRequest;
