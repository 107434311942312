import FullField from "../../../../Fields/FullField";
import * as React from 'react';
import { setFieldCopy } from "../../../../../redux/questionnaireReducer";
import { useDispatch } from "react-redux";
import Creatable from 'react-select/creatable';
import addingErrorStyleToField from "../../../../../utils/addingErrorStyleToField";
import HandleErrors from "../../../../Library/Errors/HandleErrors";
import useAppSelector from "../../../../../redux/useAppSelector";

const DisplayAlertChoice: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const fieldCopy = useAppSelector(state => state.questionnaireReducer.fieldCopy);
  const dispatch = useDispatch();

  let style = { borderRadius: '10px', width: '100%', border: '1px solid transparent' };
  style = addingErrorStyleToField(style, isError);

  return (
    <FullField title='Mot-clés' isMandatory={true} spacing='1px'>
      <HandleErrors
        isError={isError}
        isMandatory={true}
        errorMessage={errorMessage}>
        <div id='questionnaire-popup-alert-choice-options-input' style={style}>
          <Creatable
            placeholder='Ajouter un mot-clé'
            noOptionsMessage={() => "Tapez un mot-clé"}
            formatCreateLabel={userInput => `Ajouter '${userInput}'`}
            isMulti={true}
            onFocus={(e) => {
              if (isError) {
                setErrorMessage('');
                setIsError(false);
              }
            }}
            onBlur={(e) => {
              if (!fieldCopy?.alertWords || fieldCopy?.alertWords?.length === 0) {
                if (!isError) {
                  setErrorMessage('Ce champ est obligatoire.');
                  setIsError(true);
                }
              } else {
                if (isError) {
                  setErrorMessage('');
                  setIsError(false);
                }
              }
            }}
            value={(() => {
              if (!fieldCopy?.alertWords) return ([]);
              const wordsTab = [];
              for (const option of fieldCopy?.alertWords) {
                wordsTab.push({ value: option, label: option })
              }
              return (wordsTab);
            })()}
            onChange={(e) => {
              const wordsTab = [];
              for (const result of e) {
                wordsTab.push(result?.label);
              }
              const fieldCopyTmp = { ...fieldCopy };
              fieldCopyTmp.alertWords = wordsTab;
              dispatch(setFieldCopy(fieldCopyTmp));
            }}
          />
        </div>
      </HandleErrors>
    </FullField>
  );
};

export default DisplayAlertChoice;