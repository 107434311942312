import * as React from 'react';

interface IBoxTitle {
  first: string,
  second: string,
  fontSize?: string,
}

const BoxTitle = (props: IBoxTitle) => {
  const {
    first,
    second,
    fontSize,
  } = props;
  return (
    <span style={{
      textAlign: 'left',
      font: 'normal normal bold 26px/32px Poppins',
      fontSize: fontSize,
      letterSpacing: '0px',
      color: '#5BB9C1',
    }}>{first} <span style={{
      textAlign: 'left',
      letterSpacing: '0px',
      font: 'normal normal bold 26px/32px Poppins',
      fontSize: fontSize,
      color: '#0B243C',
    }}>{second}</span></span>
  );
};

BoxTitle.defaultProps = {
  fontSize: '26px',
};

export default BoxTitle;