import FullPage from "./FullPage";
import * as React from "react";
import { CircularProgress, Grid } from "@mui/material";
import BoxTitle from "../components/Library/Box/BoxTitle";
import { ChevronLeftOutlined } from "@mui/icons-material";
import { useHistory, useParams } from "react-router";
import DisplayTitle from "../components/Chatbot/DisplayTitle";
import initChatbot from "../components/Chatbot/initObjects/initChatbot";
import AddButton from "../components/Library/Buttons/AddButton/AddButton";
import DisplayBeforeMessage from "../components/Chatbot/Fields/DisplayBeforeMessage";
import DisplayAfterMessage from "../components/Chatbot/Fields/DisplayAfterMessage";
import DisplayQuestions from "../components/Chatbot/DisplayQuestions";
import OrangeButton from "../components/Library/Buttons/OrangeButton/OrangeButton";
import ErrorHelp from "../components/Library/Errors/ErrorHelp";
import makeAPIRequest from "../utils/makeAPIRequest";
import { openSnackbar } from "../redux/snackbarReducer";
import { useDispatch } from "react-redux";
import CancellablePopup from "../components/Library/Popups/CancellablePopup";
import StartChatbot from "../components/Chatbot/StartChatbot";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import { nanoid } from "nanoid";
import { DisplayRelanceSMSQuestionnaireInteractif } from "../components/Questionnaire/EditQuestionnaire/Fields/DisplayRelanceSMS";

const errorMessages = {
  emptyTitle: "Le titre n'est pas renseigné.",
  noQuestion: "Aucune question n'a été ajoutée.",
  noSMSRelanceInterval: "L'intervalle des relances n'est pas renseigné",
  noSMSRelanceDuration: "La durée des relances n'est pas renseignée",
  badDuration: "La durée doit être supérieure ou égale à l'intervalle",
  noSMSRelanceContent: "Le contenu du SMS des relances n'est pas renseigné",
  "": "",
};

const errorSelector = {
  emptyTitle: "#chatbot-title-input",
  noQuestion: "#chatbot-question-add",
  noSMSRelanceInterval: "#interval-sms-relance-input",
  noSMSRelanceDuration: "#duration-sms-relance-input",
  badDuration: "#duration-sms-relance-input",
  noSMSRelanceContent: "#content-sms-relance-input",
  "": "",
};

const CreateChatbotContent: React.FC<any> = (props) => {
  const { isEdit, isCreate } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [chatbotData, setChatbotData] = React.useState(initChatbot());
  const [error, setError] = React.useState("");
  const [isSuccessCreatePopup, setIsSuccessCreatePopup] = React.useState(false);
  const dispatch = useDispatch();
  const [isCreateLoading, setIsCreateLoading] = React.useState(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  React.useEffect(() => {
    if (isCreate) return;
    setIsLoading(true);
    makeAPIRequest("get", `/chatbot/${id}`, null, "v3")
      .then((res) => {
        setChatbotData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [isCreate, isEdit]);

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyTitle: !chatbotData?.title,
      noQuestion:
        !chatbotData?.questions || chatbotData?.questions?.length === 0,
      noSMSRelanceInterval:
        chatbotData?.sendSMSRelance && !chatbotData?.dayIntervalSMSRelance,
      noSMSRelanceDuration:
        chatbotData?.sendSMSRelance && !chatbotData?.durationSMSRelance,
      badDuration:
        chatbotData?.sendSMSRelance &&
        parseInt(chatbotData?.dayIntervalSMSRelance) >
          parseInt(chatbotData?.durationSMSRelance),
      noSMSRelanceContent:
        chatbotData?.sendSMSRelance && !chatbotData?.contentSMSRelance,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  if (isLoading) {
    return (
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop="30px"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      {isSuccessCreatePopup && (
        <CancellablePopup
          firstTitle={isCreate ? "Créer" : "Modifier"}
          secondTitle="un questionnaire interactif"
          message={`Le questionnaire interactif '${
            chatbotData?.title
          }' a bien été ${isCreate ? "crée" : "modifié"}.`}
          cancelButtonText="Retour"
          onCancel={() => {
            history.push("/home?f=questionnaires_interactifs");
          }}
          confirmButtonText="Créer un nouveau questionnaire interactif"
          onConfirm={() => {
            setIsSuccessCreatePopup(false);
            setChatbotData(initChatbot());
            history.push("/create_questionnaire_interactif");
          }}
        />
      )}
      <Grid container direction="column" wrap="nowrap" display="flex">
        <Grid item display="grid" paddingTop="10px">
          <DisplayTitle
            chatbotData={chatbotData}
            setChatbotData={setChatbotData}
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <DisplayBeforeMessage
            chatbotData={chatbotData}
            setChatbotData={setChatbotData}
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <DisplayAfterMessage
            chatbotData={chatbotData}
            setChatbotData={setChatbotData}
          />
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <OrangeCheckBox
            value={chatbotData?.alwaysSendByNumber ? true : false}
            onClick={() =>
              setChatbotData((x) => {
                return {
                  ...x,
                  alwaysSendByNumber: chatbotData?.alwaysSendByNumber
                    ? false
                    : true,
                };
              })
            }
            text="Tout envoyer via un numéro à 5 chiffres"
          />
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <DisplayRelanceSMSQuestionnaireInteractif
            chatbotData={chatbotData}
            setChatbotData={setChatbotData}
          />
        </Grid>
        <Grid item display="grid" paddingTop="20px">
          <AddButton
            addId="chatbot-question-add"
            displayText={true}
            text="Ajouter une question"
            onClick={() => {
              const chatbotDataTmp = { ...chatbotData };

              chatbotDataTmp.questions.push({ text: "", id: nanoid() });
              setChatbotData(chatbotDataTmp);
            }}
          />
        </Grid>
        <DisplayQuestions
          chatbotData={chatbotData as any}
          setChatbotData={setChatbotData}
        />
        <Grid item display="grid" paddingTop="35px">
          {error?.length > 0 && (
            <Grid item display="grid">
              <ErrorHelp
                errorMessages={errorMessages}
                errorSelector={errorSelector}
                error={error}
              />
            </Grid>
          )}
          {isCreateLoading && (
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          {!isCreateLoading && (
            <Grid item display="grid" justifyContent="center">
              {isValidSubmit() ? (
                <OrangeButton
                  animation={true}
                  enable={true}
                  type="submit"
                  text={isCreate ? "Créer" : "Mettre à jour"}
                  onClick={
                    isCreate
                      ? () => {
                          setIsCreateLoading(true);
                          makeAPIRequest(
                            "post",
                            "/chatbot/create",
                            chatbotData,
                            "v3"
                          )
                            .then((res) => {
                              setIsSuccessCreatePopup(true);
                              setIsCreateLoading(false);
                            })
                            .catch((err) => {
                              setIsCreateLoading(false);
                              dispatch(
                                openSnackbar({
                                  message: err?.response?.data?.error
                                    ? err?.response?.data?.error
                                    : `La création du questionnaire interactif '${chatbotData?.title}' a échouée`,
                                  type: "error",
                                  duration: 5000,
                                })
                              );
                            });
                        }
                      : () => {
                          setIsCreateLoading(true);
                          makeAPIRequest(
                            "post",
                            "/chatbot/update",
                            chatbotData,
                            "v3"
                          )
                            .then((res) => {
                              setIsSuccessCreatePopup(true);
                              setIsCreateLoading(false);
                            })
                            .catch((err) => {
                              setIsCreateLoading(false);
                              dispatch(
                                openSnackbar({
                                  message: `La mise à jour du questionnaire interactif '${chatbotData?.title}' a échouée`,
                                  type: "error",
                                  duration: 3000,
                                })
                              );
                            });
                        }
                  }
                />
              ) : (
                <OrangeButton
                  animation={true}
                  enable={false}
                  type="submit"
                  text={isCreate ? "Créer" : "Mettre à jour"}
                  onClick={() => {
                    return null;
                  }}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const CreateChatbotBox: React.FC<any> = (props) => {
  const { isCreate, isEdit, isLoading } = props;

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="80vw"
      minWidth="320px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <BoxTitle
              first={isCreate ? "Créer" : "Modifier"}
              second="un questionnaire interactif"
            />
          </Grid>
          <Grid item display="grid" paddingTop="15px">
            <CreateChatbotContent {...props} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CreateOrEditChatbotPage: React.FC<any> = (props) => {
  const history = useHistory();
  const { isCreate, isEdit } = props;

  return (
    <>
      <FullPage>
        <Grid container direction="column" padding="10px" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px" width="80vw">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/home?f=questionnaires_interactifs")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <CreateChatbotBox {...props} />
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default CreateOrEditChatbotPage;
