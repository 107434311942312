import { nanoid } from "nanoid";
import { IField } from "../../../../types/FormTypes";

const initField = (initConsentText = true): IField => {
  return ({
    title: '',
    type: 'text',
    isMandatory: false,
    hideAsterics: false,
    value: null,
    option: null,
    maxLength: 500,
    alertType: '',
    alertWords: [],
    id: nanoid(8),
    medecinsTraitantInfos: {
      actions: [],
      consentText: initConsentText ? `Les données personnelles présentes dans ce formulaire sont collectées par la {STRUCTURE}, agissant en tant que Responsable de traitement afin de vous accompagner dans le {OBJECTIF}. Elles seront accessibles par le personnel habilité de la {STRUCTURE}, ainsi que par les médecins généralistes exerçant sur le territoire de la {STRUCTURE}. Les données seront conservées par la {STRUCTURE} pendant une durée d'un an à compter de sa date de réception. Les médecins généralistes auxquels la {STRUCTURE} aura communiqué vos données s’engagent à les détruire dans les 3 mois suivant leur réception. Conformément à l'article L1111-8 du Code de la Santé Publique, vos données de santé sont hébergées par un hébergeur certifié Hébergeur Données de Santé (HDS). Vous disposez d'un droit d'accès, de rectification, de suppression, de limitation du traitement de données, ainsi que la possibilité de retirer votre consentement à tout moment. Pour toute information ou pour exercer vos droits, veuillez contacter notre DPO à {EMAIL_DU_DPO}. Si vous estimez que vos droits Informatique et Libertés ne sont pas respectés, vous avez le droit d'introduire une réclamation auprès de la CNIL.` : '',
      medecinTraitantsList: [],
    },
    stops: [],
  });
}

export default initField;
