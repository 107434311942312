import * as React from 'react';
import { Grid } from '@mui/material';
import ICheckBox from './ICheckBox';

const CheckBoxField = (props: ICheckBox) => {
  const {
    text,
    value,
    onClick
  } = props;

  return (
    <Grid container direction='row' wrap='nowrap' display='flex' columnSpacing='5px'>
      <Grid item display='grid'>
        <input
          type="radio"
          className="checkbox_type"
          checked={value}
          onChange={() => { return (null); }}
          onClick={onClick} />
      </Grid>
      <Grid item display='grid'>
        <span className="checkbox_text">
          {text}
        </span>
      </Grid>
    </Grid>
  );
};

export default CheckBoxField;