import { Grid } from "@mui/material";
import * as React from "react";
import ChatbotContent from "./ChatbotContent";
import StartChatbot from "./StartChatbot";
import useAppSelector from "../../redux/useAppSelector";

const ChatbotBox: React.FC<any> = (props) => {
  const { chatbotsAccess } = props;
  const scheduleChatbotReducer = useAppSelector(
    (state) => state.scheduleChatbotReducer
  );

  return (
    <>
      {scheduleChatbotReducer?.isOpen && <StartChatbot />}
      <Grid container display="flex" direction="column" wrap="nowrap">
        <Grid item display="grid" paddingTop="40px">
          <ChatbotContent />
        </Grid>
      </Grid>
    </>
  );
};

export default ChatbotBox;
