import { createSlice } from '@reduxjs/toolkit';

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState: {
    activeTab: 'recents',
    searchTabs: [],
  },
  reducers: {
    updateFilters: (state, action) => {
      const { index, filters } = action.payload;

      if (state.searchTabs?.[index]) state.searchTabs[index].filters = filters;
    },
    updateAdvices: (state, action) => {
      const { index, advices, total } = action.payload;

      if (state.searchTabs?.[index]) {
        state.searchTabs[index].total = total;
        state.searchTabs[index].advices = advices;
      }
    },
    addSearchTab: (state, action) => {
      const { title, advices, filters } = action.payload;
      state.searchTabs.push({
        title: title ? title : '',
        advices: advices ? advices : [],
        filters: filters ? filters : { spec: null, conseil: null, attchment: null },
      });
      state.activeTab = `searched${state.searchTabs.length - 1}`;
    },
    deleteSearchTab: (state, action) => {
      const index = action.payload;
      const lastTab = `searched${state.searchTabs.length - 1}`;
      const actualIndex = parseInt(state.activeTab.substring(8, state.activeTab.length));

      // If no tab left, go to favorite tab
      if (state.activeTab === `searched${index}`) {
        if (state.searchTabs.length === 1) state.activeTab = 'favourite';
        else if (lastTab === state.activeTab) {
          state.activeTab = `searched${index - 1}`;
        }
      } else {
        if (index < actualIndex) {
          state.activeTab = `searched${actualIndex - 1}`;
        }
      }
      // Delete tab with index
      state.searchTabs.splice(index, 1);
    },
    setActiveSearchTab: (state, action) => {
      state.activeTab = `searched${action.payload}`;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    }
  }
})

export const { addSearchTab, updateFilters, setActiveSearchTab, updateAdvices, setActiveTab, deleteSearchTab } = tabsSlice.actions

export default tabsSlice.reducer