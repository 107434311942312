import { nanoid } from "nanoid";

const initSection = (title: string) => {
  return ({
    title: title,
    fields: [],
    id: nanoid(8),
  });
}

export default initSection;