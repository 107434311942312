import FullField from "../FullField";
import * as React from 'react';
import TextInput from "../../Library/Inputs/TextInput";

interface IPatientLastNameField {
  patientLastName: string,
  onChange: any,
  itemBorder?: string,
}

const PatientLastNameField = (props: IPatientLastNameField) => {
  const {
    patientLastName,
    onChange,
    itemBorder,
  } = props;

  return (
    <FullField title='Nom' isMandatory={false}>
      <TextInput
        className='border-animation'
        id='patient-lastname-input'
        border={itemBorder}
        value={patientLastName}
        onChange={(e) => onChange(e)} />
    </FullField>
  );
};

export default PatientLastNameField;