import { Grid } from '@mui/material';
import FullField from '../FullField';
import * as React from 'react';
import ICheckBox from './ICheckBox';
import CheckBoxField from './CheckBoxField';

interface IGroupCheckBoxField {
  listCheckbox?: Array<ICheckBox>,
  title?: string,
  isMandatory?: boolean,
  direction?: string,
}

const GroupCheckBoxField = (props: IGroupCheckBoxField) => {
  const {
    listCheckbox,
    isMandatory,
    direction,
    title,
  } = props;

  return (
    <FullField title={title} isMandatory={isMandatory}>
      <Grid container direction={direction as any} display='flex' rowSpacing='10px' columnSpacing='30px' paddingTop='5px'>
        {listCheckbox.map((element, index) => (
          <Grid item key={index} display='grid'>
            <CheckBoxField text={element.text} value={element.value} onClick={element.onClick} />
          </Grid>
        ))}
      </Grid>
    </FullField>
  );
};

GroupCheckBoxField.defaultProps = {
  title: '',
  direction: 'row',
  isMandatory: false,
  listCheckbox: [],
}

export default GroupCheckBoxField;