import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";
import useQuery from "../utils/hooks/useQuery";

export const ValidationBox = (props: { noPadding?: boolean }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const key = useQuery().get("key");
  const conseil_id = useQuery().get("conseil_id");

  React.useEffect(() => {
    if (!key || !conseil_id) { return; }
    setIsLoading(true);
    makeAPIRequest("post", "/conseil/validate_conseil", {
      key: key,
      conseil_id: conseil_id,
    }, 'v3')
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false))
      .finally(() => setIsLoading(false));
  }, [key, conseil_id]);

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      paddingLeft={props.noPadding ? 0 : "5%"}
      paddingTop={props.noPadding ? 0 : "5%"}
      paddingRight={props.noPadding ? 0 : "5%"}
      paddingBottom={props.noPadding ? 0 : "5%"}
    >
      {isLoading ? (
        <Grid item display="grid" paddingTop="20px">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid item display="grid">
          <Grid
            container
            direction="column"
            wrap="nowrap"
            justifyContent={"center"}
            gap="40px"
          >
            {!success && (
              <span
                style={{
                  textAlign: "center",
                  fontSize: "min(max(13px, 1.055vw), 16px)",
                  fontFamily: "Poppins",
                  color: "#0B243C",
                }}
              >
                {`Une erreur est survenue, le conseil n'as pas pu être validé.`}
              </span>
            )}
            {key && success && (
              <Grid item display="grid">
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "min(max(13px, 1.055vw), 16px)",
                    fontFamily: "Poppins",
                    color: "#0B243C",
                  }}
                >
                  {`Le conseil a bien été validé.`}
                </span>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ConseilValidationPage: React.FC = () => {
  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="700px"
        >
          <Grid item display="grid">
            <ValidationBox />
          </Grid>
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default ConseilValidationPage;
