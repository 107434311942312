import { Help } from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import * as React from "react";
import { transformUrlToAbsolute } from "../../../pages/LinksPage";

interface IContentAdviceCardContainerProps {
  url?: string;
  attachment?: string;
  content?: string;
  questionnaire_interactif_id?: string;
  questionnaire_interactif_title?: string;
  questionnaire_interactif_nano_id?: string;
  is_form_url_owner?: boolean;
  is_questionnaire_interactif_owner?: boolean;
  display_help_not_owner?: boolean;
}

const ContentAdviceCardContainer = (
  props: IContentAdviceCardContainerProps
) => {
  const {
    url,
    attachment,
    content,
    questionnaire_interactif_id,
    questionnaire_interactif_title,
    questionnaire_interactif_nano_id,
    is_questionnaire_interactif_owner,
    is_form_url_owner,
    display_help_not_owner,
  } = props;

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      height="100%"
      justifyContent="center"
    >
      <Grid
        item
        display="grid"
        className="content"
        paddingLeft="10px"
        paddingRight="10px"
        justifyContent="flex-start"
      >
        <span className="content">
          {" "}
          {!content || content === "" ? "aucun" : content}
        </span>
      </Grid>
      {url && url?.length > 0 && (!attachment || attachment === "") && (
        <Grid item display="grid" paddingTop="5px">
          <Grid container direction="row" gap="5px" alignItems={"center"}>
            <Grid item display="grid">
              <p className="content" style={{ fontSize: "13px", wordBreak: "break-all" }}>
                Lien web :{" "}
                <a href={transformUrlToAbsolute(url)} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </p>
            </Grid>
            {display_help_not_owner && is_form_url_owner === false && (
              <Grid item display="grid">
                <Tooltip
                  title={`Ce questionnaire ne vous appartient pas, vous ne pouvez pas l'utiliser. Pour le dupliquer, sélectionnez le conseil.`}
                >
                  <Help
                    style={{
                      height: "16px",
                      width: "16px",
                      cursor: "help",
                      color: "#FF3100",
                    }}
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {questionnaire_interactif_title && questionnaire_interactif_id && (
        <Grid item display="grid" marginTop="5px">
          <Grid
            container
            direction="row"
            alignItems={"center"}
            gap="5px"
            style={{ margin: "0", padding: "0", fontSize: "13px" }}
          >
            <Grid item display="grid">
              Questionnaire interactif :
            </Grid>
            {questionnaire_interactif_nano_id &&
            is_questionnaire_interactif_owner === true ? (
              <Grid item display="grid">
                <a
                  href={`/#/chatbot/${questionnaire_interactif_nano_id}/reponses`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {questionnaire_interactif_title}
                </a>
              </Grid>
            ) : (
              <Grid item display="grid">
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  gap="5px"
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    {questionnaire_interactif_title}
                  </Grid>
                  {display_help_not_owner && (
                    <Grid item display="grid">
                      <Tooltip
                        title={`Ce questionnaire interactif ne vous appartient pas, vous ne pouvez pas l'utiliser. Pour le dupliquer, sélectionnez le conseil.`}
                      >
                        <Help
                          style={{
                            height: "16px",
                            width: "16px",
                            cursor: "help",
                            color: "#FF3100",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

ContentAdviceCardContainer.default = {
  url: null,
  attachment: null,
  content: null,
};

export default ContentAdviceCardContainer;
