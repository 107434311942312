import FullField from "../../../Fields/FullField";
import * as React from "react";
import MySelect from "../../../Library/Select/MySelect";
import HandleErrors from "../../../Library/Errors/HandleErrors";
import { IField, IForm } from "../../../../types/FormTypes";
import { Grid } from "@mui/material";

const DisplayProtocole: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const field: IField = props.field;
  const {
    sectionIndex,
    justifyContent,
    fieldIndex,
    disableError,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  interface IOption {
    label?: string;
    value?: string;
  }

  const optionsNature = ((): IOption[] => {
    const options: IOption[] = [];
    if (!field?.protocoleInfos?.protocolesList) {
      return options;
    }
    if (field?.protocoleInfos?.protocolesList?.length == 0) {
      return options;
    }

    for (const protocole of field?.protocoleInfos.protocolesList) {
      if (protocole?.nature && protocole?.id) {
        options.push({
          value: protocole.id,
          label: `${protocole.nature}`,
        });
      }
    }
    return options;
  })();

  /*
  const optionsDate = ((): IOption[] => {
    const options: IOption[] = [];
    if (!field?.protocoleInfos?.protocolesList) {
      return options;
    }
    if (field?.protocoleInfos?.protocolesList?.length == 0) {
      return options;
    }

    for (const protocole of field?.protocoleInfos.protocolesList) {
      if (protocole?.dateDeclarationARS && protocole?.id) {
        options.push({
          value: protocole.id,
          label: `${protocole.dateDeclarationARS}`,
        });
      }
    }
    return options;
  })();
  */

  /*
  const optionDateSelected = optionsDate
    ? optionsDate.find((x) => x?.value === field?.value)
    : undefined;
*/

  const optionNatureSelected = optionsNature
    ? optionsNature.find((x) => x?.value === field?.value)
    : undefined;

  return (
    <Grid container direction="column" wrap="nowrap" gap="10px">
      <Grid item display="grid">
        <FullField
          isQuestionnaire={true}
          title={"Nature du protocole concerné"}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={isError && !disableError}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessage}
          >
            <MySelect
              id={"field" + field?.id}
              isError={isError && !disableError}
              value={optionNatureSelected ? optionNatureSelected : undefined}
              options={optionsNature}
              placeholder=""
              onChange={(e) => {
                const questionnaireDataTmp: IForm = { ...questionnaireData };

                questionnaireDataTmp.sections[sectionIndex].fields[
                  fieldRowIndex
                ][fieldIndex].value = e?.value;

                // Reset all date declaration
                for (const sectionTmp of questionnaireDataTmp.sections) {
                  for (const fieldsRowsTmp of sectionTmp.fields) {
                    for (const f of fieldsRowsTmp) {
                      if (f?.type === "structure") {
                        f.value = [f?.value?.[0], f?.value?.[1], undefined]
                      }
                    }
                  }
                }

                setQuestionnaireData(questionnaireDataTmp);
              }}
              onFocus={(e) => {
                if (!field?.isMandatory) return;
                if (isError && !disableError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
              onBlur={(e) => {
                if (!field?.isMandatory) return;
                if (
                  !questionnaireData.sections[sectionIndex].fields[
                    fieldRowIndex
                  ][fieldIndex].value
                ) {
                  if (!isError && !disableError) {
                    setErrorMessage("Ce champ est obligatoire.");
                    setIsError(true);
                  }
                } else {
                  if (isError && !disableError) {
                    setErrorMessage("");
                    setIsError(false);
                  }
                }
              }}
            />
          </HandleErrors>
        </FullField>
      </Grid>
      {/*<Grid item display="grid">
        <FullField
          isQuestionnaire={true}
          title={"Date de déclaration à l'ARS du protocole concerné ce jour"}
          isMandatory={field?.isMandatory}
          justifyContent={justifyContent}
          hideAsterics={field?.hideAsterics ? true : false}
        >
          <HandleErrors
            isError={isError && !disableError}
            isMandatory={field?.isMandatory}
            errorMessage={errorMessage}
          >
            <MySelect
              id={"field" + field?.id}
              isError={isError && !disableError}
              value={optionDateSelected ? optionDateSelected : undefined}
              options={optionsDate}
              placeholder=""
              onChange={(e) => {
                const questionnaireDataTmp = { ...questionnaireData };

                questionnaireDataTmp.sections[sectionIndex].fields[
                  fieldRowIndex
                ][fieldIndex].value = e?.value;
                setQuestionnaireData(questionnaireDataTmp);
              }}
              onFocus={(e) => {
                if (!field?.isMandatory) return;
                if (isError && !disableError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
              onBlur={(e) => {
                if (!field?.isMandatory) return;
                if (
                  !questionnaireData.sections[sectionIndex].fields[
                    fieldRowIndex
                  ][fieldIndex].value
                ) {
                  if (!isError && !disableError) {
                    setErrorMessage("Ce champ est obligatoire.");
                    setIsError(true);
                  }
                } else {
                  if (isError && !disableError) {
                    setErrorMessage("");
                    setIsError(false);
                  }
                }
              }}
            />
          </HandleErrors>
        </FullField>
            </Grid>*/}
    </Grid>
  );
};

export default DisplayProtocole;
