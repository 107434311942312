import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import BoxTitle from "../components/Library/Box/BoxTitle";
import makeAPIRequest from "../utils/makeAPIRequest";
import useAppSelector from "../redux/useAppSelector";
import { useOpenSnackbar } from "./CreateOrEditChatbotLexPage";

interface ChiffresCles {
  patients_total: number;
  utilisateurs_actifs: number; // 30 derniers jours
  utilisateurs_inactifs: number; // 30 derniers jours
  sms_envoyes_au_total: number;
  sms_recu: number;
  sms_erreur_operateur_ou_tel_patient: number;
  sms_bloque_par_stop: number;
  sms_status_inconnu: number;
  messages_dans_base: number;
  packs_valide_dans_base: number;
  packs_non_valide_dans_base: number;
  packs_cree_par_ia_dans_base: number;
  conseils_uniques_valide_dans_base: number;
  conseils_uniques_non_valide_dans_base: number;
  conseils_uniques_cree_par_ia_dans_base: number;
  questionnaires_au_total: number;
  reponses_questionnaires_total: number;
  taux_reponses_programmation_questionnaire: number;
  questionnaire_interactifs_crees_total: number;
  questionnaire_interactifs_envoyes: number;
  questionnaire_interactifs_finalise: number;
  questionnaire_interactifs_min_une_reponse: number;
}

const ChiffresClesBox: React.FC = () => {
  const [chiffresCles, setChiffresCles] = React.useState<ChiffresCles>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const snackbarOpenUse = useOpenSnackbar();

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/chiffres/all", null, "v3")
      .then((res) => {
        setChiffresCles(res?.data);
      })
      .catch((err) => {
        snackbarOpenUse.error("Impossible de récupérer les chiffres clés", err);
      })
      .finally(() => setIsLoading(false))
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        minWidth="320px"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid container display="flex" direction="column" wrap="nowrap">
            <Grid item display="grid">
              <BoxTitle first="Chiffres" second="clés" />
            </Grid>
            <Grid item display="grid" paddingTop="30px" style={{
              fontSize: "16px",
              color: "#0B243C",
              whiteSpace: 'pre-wrap',
              fontFamily: 'Poppins',
            }}>
              {!isLoading ? (
                <>
                  {chiffresCles ? (
                    <Grid container direction="column" wrap="nowrap" gap="10px">
                      <Grid item display="grid">
                        <span>{`Patients au total : ${chiffresCles.patients_total}`}</span>
                      </Grid>
                      <Grid item display="grid" marginTop="10px">
                        <span>{`Utilisateurs actifs (30 derniers jours) : ${chiffresCles.utilisateurs_actifs}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Utilisateurs inactifs (30 derniers jours) : ${chiffresCles.utilisateurs_inactifs}`}</span>
                      </Grid>
                      <Grid item display="grid" marginTop="10px">
                        <span>{`SMS envoyés : ${chiffresCles.sms_envoyes_au_total}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`SMS reçus avec succès : ${chiffresCles.sms_recu}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`SMS échoués (erreur opérateur, téléphone) : ${chiffresCles.sms_erreur_operateur_ou_tel_patient}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`SMS bloqués par STOP : ${chiffresCles.sms_bloque_par_stop}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`SMS sans statut : ${chiffresCles.sms_status_inconnu}`}</span>
                      </Grid>

                      <Grid item display="grid" marginTop="10px">
                        <span>{`Messages dans la base : ${chiffresCles.messages_dans_base}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Packs validés : ${chiffresCles.packs_valide_dans_base}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Packs en attente de validation : ${chiffresCles.packs_non_valide_dans_base}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Packs créés par IA : ${chiffresCles.packs_cree_par_ia_dans_base}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Conseils uniques validés  : ${chiffresCles.conseils_uniques_valide_dans_base}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Conseils uniques en attente de validation  : ${chiffresCles.conseils_uniques_non_valide_dans_base}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Conseils uniques créés par IA  : ${chiffresCles.conseils_uniques_cree_par_ia_dans_base}`}</span>
                      </Grid>

                      <Grid item display="grid" marginTop="10px">
                        <span>{`Questionnaires au total : ${chiffresCles.questionnaires_au_total}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Réponses aux questionnaires : ${chiffresCles.reponses_questionnaires_total}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Taux de réponses aux questionnaires : ${chiffresCles.taux_reponses_programmation_questionnaire.toFixed(2)}%`}</span>
                      </Grid>

                      <Grid item display="grid" marginTop="10px">
                        <span>{`Questionnaires interactifs au total : ${chiffresCles.questionnaire_interactifs_crees_total}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Questionnaires interactifs envoyés  : ${chiffresCles.questionnaire_interactifs_envoyes}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Questionnaires interactifs finalisés  : ${chiffresCles.questionnaire_interactifs_finalise}`}</span>
                      </Grid>
                      <Grid item display="grid">
                        <span>{`Questionnaires interactifs avec minimum une réponse  : ${chiffresCles.questionnaire_interactifs_min_une_reponse}`}</span>
                      </Grid>
                    </Grid>
                  ) : (
                    <span>Il n'y a aucun chiffre clé à afficher.</span>
                  )}
                </>
              ) : (
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const AdminChiffresClesPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="chiffres_cles" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="chiffres_cles" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
          minWidth={700}
        >
          <ChiffresClesBox />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminChiffresClesPage;
