import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import React from "react";
import draftToHtml from "draftjs-to-html";
import "./EditorStyle.scss";
import htmlToDraft from "html-to-draftjs";

const htmlToDraftBlocks = (html) => {
  const blocksFromHtml = htmlToDraft(html ? html : '');
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

interface IMyEditor {
  defaultFontFamily?: string;
  defaultFontSize?: string | number;
  defaultColor?: string;
  initialContent?: string;
  onUpdate: (html: string) => void;
}

const MyEditor = (props: IMyEditor) => {
  const blocksFromHTML = htmlToDraftBlocks(props.initialContent);
  const [editorState, setEditorState] = useState(() => blocksFromHTML);

  React.useEffect(() => {
    if (props.initialContent === '') {
      setEditorState(EditorState.createEmpty());
    }
  }, [props.initialContent])
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadImageCallback = async (file) => {
    const imageObject = {
      file: await toBase64(file),
    };

    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.file } });
    });
  };

  React.useEffect(() => {
    props?.onUpdate(
      draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
        {
          trigger: "#",
          separator: " ",
        },
        true,
        (entity, text) => {
          if (entity.type === "IMAGE") {
            let textAlign = "none";
            if (entity.data.alignment) {
              if (entity.data.alignment === "none") {
                textAlign = "center";
              } else {
                textAlign = entity.data.alignment;
              }
            }
            return (
              '<p style="text-align:' +
              textAlign +
              ';"><img src="' +
              entity.data.src +
              '" alt="' +
              entity.data.alt +
              '" style="height: ' +
              entity.data.height +
              ";width: " +
              entity.data.width +
              '"/></p>'
            );
          }
        }
      )
    );
  }, [editorState]);

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  return (
    <div>
      <Editor
        id="myEditor"
        localization={{ locale: "fr" }}
        toolbar={{
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
          },
          fontFamily: {
            options: [
              "Poppins",
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
            ],
            className: undefined,
          },
          fontSize: {
            options: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          colorPicker: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: [
              "#0B243C",
              "#23595D",
              "#5BB9C1",
              "#4AC6D6",
              "#ffa500",
              "#657273",
              "rgb(97,189,109)",
              "rgb(26,188,156)",
              "rgb(84,172,210)",
              "rgb(44,130,201)",
              "rgb(147,101,184)",
              "rgb(71,85,119)",
              "rgb(204,204,204)",
              "rgb(65,168,95)",
              "rgb(0,168,133)",
              "rgb(61,142,185)",
              "rgb(41,105,176)",
              "rgb(85,57,130)",
              "rgb(40,50,78)",
              "rgb(0,0,0)",
              "rgb(247,218,100)",
              "rgb(251,160,38)",
              "rgb(235,107,86)",
              "rgb(226,80,65)",
              "rgb(163,143,132)",
              "rgb(239,239,239)",
              "rgb(255,255,255)",
              "rgb(250,197,28)",
              "rgb(243,121,52)",
              "rgb(209,72,65)",
              "rgb(184,49,47)",
              "rgb(124,112,107)",
              "rgb(209,213,216)",
            ],
          },
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: uploadImageCallback,
            previewImage: true,
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "50%",
            },
          },
          options: [
            "inline",
            "colorPicker",
            "fontFamily",
            "fontSize",
            "link",
            "image",
            "textAlign",
            "history",
          ],
        }}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        wrapperStyle={{
          borderRadius: "20px",
          zIndex: 0,
          border: "1px solid #d3e0e0",
        }}
        editorStyle={{
          height: "150px",
          lineHeight: '85%',
          marginTop: "-6px",
          fontSize: props.defaultFontSize,
          color: props.defaultColor,
          fontFamily: props.defaultFontFamily,
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      />
    </div>
  );
};

MyEditor.defaultProps = {
  defaultFontFamily: "Poppins",
  defaultFontSize: "16px",
  defaultColor: "#0B243C",
};

export default MyEditor;
