const isImage = (fileName) => {
  const str = fileName;
  const formats = ['png', 'jpg', 'jpeg', 'svg', 'ico', 'gif', 'apng'];
  if (!str || str?.length === 0) return (false);
  for (const format of formats) {
    if (str?.endsWith(format))
      return (true);
  }
  return (false);
}

export default isImage;