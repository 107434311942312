import FullField from "../FullField";
import * as React from 'react';
import TextInput from "../../Library/Inputs/TextInput";

interface IAdviceTitleField {
  adviceTitle: string,
  onChange: any,
  itemBorder?: string,
}

const AdviceTitleField = (props: IAdviceTitleField) => {
  const {
    adviceTitle,
    onChange,
    itemBorder,
  } = props;

  return (
    <FullField title='Titre' isMandatory={true}>
      <TextInput
        className='border-animation'
        id='advice-title-input'
        border={itemBorder}
        value={adviceTitle}
        onChange={(e) => onChange(e)} />
    </FullField>
  );
};

export default AdviceTitleField;